import React, { useState } from 'react';
import { Button, Icon, message, Table } from 'antd';
import { PaginationConfig, SorterResult, SortOrder } from 'antd/lib/table';
import copy from 'copy-to-clipboard';
import { Link, RouteComponentProps } from 'react-router-dom';
import withSizes from 'react-sizes';
import { PatientGroups } from '../../../../../redux/domains/groups/groups.type';
import { PatientAdministrationUser } from '../../../../../redux/domains/patient-administration/patient-administration.type';
import { TogglePatient } from './TogglePatient';
import { alphabetic } from '../../../../../utils/sort/alphabetic';
import { pathEditPatient } from '../../../edit/path';
import { pathPatientProfileLink } from '../../../data/components/tabs/path';
import { pathEditPatientGroup } from '../../../edit-group/path';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { PatientGroupLink } from '../../../../../components/patient/PatientGroupLink';
import { ReasonsForChangeStatus } from '../../../../../conditions/condition.type';
import { TogglePatientData } from '../PatientAdministrationListWrapperPage';

const appConfig = __APP_CONFIG__;

const tableMeta = {
    externalId: {
        key: 'externalId',
        index: 'externalId',
        width: 50
    },
    personalId: {
        key: 'social',
        index: 'meta.personal_id',
        width: 150
    },
    familyName: {
        key: 'family_name',
        index: 'meta.family_name',
        width: 150
    },
    firstName: {
        key: 'name',
        index: 'meta.name',
        width: 150
    },
    memberOf: {
        index: 'groups',
        width: 250
    },
    status: {
        key: 'status',
        index: 'status',
        width: 120
    },
    activeSwitch: {
        key: 'toggle',
        index: 'status',
        width: 80
    },
    editButton: {
        key: 'edit',
        index: 'edit',
        width: 80
    },
    profileButton: {
        key: 'actions',
        index: 'actions',
        width: 80
    }
};

const sortBy = (
    sortedResponse: SortedType,
    key: string
): boolean | SortOrder => {
    return sortedResponse.columnKey === key && sortedResponse.order;
};

const buildId = () => {
    function linkIcon(id: string) {
        return (
            <Icon
                type="link"
                title={formatMessage(Strings.common.general.clickToCopy)}
                onClick={() => {
                    copy(id);
                    message.success(
                        formatMessage(Strings.common.general.copied)
                    );
                }}
            />
        );
    }

    return {
        title: <div>Id</div>,
        width: tableMeta.externalId.width,
        dataIndex: tableMeta.externalId.index,
        key: tableMeta.externalId.key,
        render: linkIcon
    };
};

const buildSSN = (sortedResponse: SortedType) => {
    return {
        title: formatMessage(Strings.patient.meta.general.social),
        dataIndex: tableMeta.personalId.index,
        key: tableMeta.personalId.key,
        sorter: (a: PatientAdministrationUser, b: PatientAdministrationUser) =>
            alphabetic(a.meta.personal_id, b.meta.personal_id),
        sortOrder: sortBy(sortedResponse, tableMeta.personalId.key),
        width: tableMeta.personalId.width
    };
};

const buildLastName = (sortedResponse: SortedType) => {
    return {
        title: formatMessage(Strings.ui.label.general.lastName),
        dataIndex: tableMeta.familyName.index,
        key: tableMeta.familyName.key,
        sorter: (a: PatientAdministrationUser, b: PatientAdministrationUser) =>
            alphabetic(a.meta.family_name, b.meta.family_name),
        sortOrder: sortBy(sortedResponse, tableMeta.familyName.key),
        width: tableMeta.familyName.width
    };
};

const buildFirstName = (sortedResponse: SortedType) => {
    return {
        title: formatMessage(Strings.ui.label.general.firstName),
        dataIndex: tableMeta.firstName.index,
        key: tableMeta.firstName.key,
        sorter: (a: PatientAdministrationUser, b: PatientAdministrationUser) =>
            alphabetic(a.meta.name, b.meta.name),
        sortOrder: sortBy(sortedResponse, tableMeta.firstName.key),
        width: tableMeta.firstName.width
    };
};

const buildMemberOf = (
    sortedResponse: SortedType,
    groups: PatientGroups,
    columnFilter: ColumnFilterType
) => {
    return {
        title: formatMessage(Strings.common.general.memberOf),
        dataIndex: tableMeta.memberOf.index,
        filters: groups.allIds.map((id: string) => {
            const { name } = groups.byId[id];
            return {
                text: name,
                value: id
            };
        }),
        filteredValue: columnFilter.groups || null,
        onFilter: (id: string, patient: PatientAdministrationUser) => {
            return patient.groups.includes(id);
        },
        render: (groupIds: string[], row: PatientAdministrationUser) => {
            return groupIds.map((id: string) => {
                if (groups.byId[id]) {
                    return (
                        <span key={id}>
                            <Link
                                to={{
                                    pathname: `${pathEditPatientGroup}/${row.externalId}`
                                }}
                            >
                                <Icon
                                    type="retweet"
                                    style={{
                                        fontSize: '20px',
                                        verticalAlign: 'bottom',
                                        marginRight: '10px'
                                    }}
                                />
                            </Link>

                            <PatientGroupLink
                                key={groups.byId[id].id}
                                patientGroupId={groups.byId[id].id}
                            />

                            <br />
                        </span>
                    );
                }
                return null;
            });
        },
        key: tableMeta.memberOf.index,
        width: tableMeta.memberOf.width,
        sorter: (a: PatientAdministrationUser, b: PatientAdministrationUser) =>
            alphabetic(
                a.groups.map((group) => groups.byId[group]?.name || '').join(),
                b.groups.map((group) => groups.byId[group]?.name || '').join()
            ),
        sortOrder: sortBy(sortedResponse, tableMeta.memberOf.index)
    };
};

const buildStatus = (sortedResponse: SortedType) => {
    return {
        title: formatMessage(Strings.common.general.status),
        dataIndex: tableMeta.status.index,
        key: tableMeta.status.key,
        render: (status: string) =>
            formatMessage(Strings.common.general.statusValue, {
                status
            }),
        sorter: (a: PatientAdministrationUser, b: PatientAdministrationUser) =>
            alphabetic(a.status, b.status),
        sortOrder: sortBy(sortedResponse, tableMeta.status.key),
        width: tableMeta.status.width
    };
};

const buildActiveSwitch = (
    togglePatient: (
        externalId: string,
        data: TogglePatientData
    ) => Promise<void>,
    statusChangeReasons: () => ReasonsForChangeStatus,
    savePendingRegisterPatientId: () => void,
    savePendingPatientActivation: (
        externalId: string,
        reason: string,
        note?: string
    ) => void,
    routeProps: RouteComponentProps
) => {
    return {
        title: titleDiv,
        dataIndex: tableMeta.activeSwitch.index,
        key: tableMeta.activeSwitch.key,
        render: activeSwitch,
        width: tableMeta.activeSwitch.width
    };

    function titleDiv() {
        return (
            <div
                style={{
                    width: tableMeta.activeSwitch.width
                }}
            />
        );
    }

    function activeSwitch(status: string, row: PatientAdministrationUser) {
        return (
            <div
                style={{
                    width: tableMeta.activeSwitch.width,
                    textAlign: 'center'
                }}
            >
                <TogglePatient
                    status={status}
                    patientMeta={row.meta}
                    externalId={row.externalId}
                    togglePatient={togglePatient}
                    reasons={statusChangeReasons}
                    savePendingRegisterPatientId={savePendingRegisterPatientId}
                    savePendingPatientActivation={savePendingPatientActivation}
                    routeProps={routeProps}
                />
            </div>
        );
    }
};

const buildEdit = () => {
    return {
        title: titleDiv,
        dataIndex: tableMeta.editButton.index,
        key: tableMeta.editButton.key,
        render: editButton,
        width: tableMeta.editButton.width
    };

    function titleDiv() {
        return (
            <div
                style={{
                    width: tableMeta.editButton.width,
                    textAlign: 'center'
                }}
            />
        );
    }

    function editButton(_: undefined, row: PatientAdministrationUser) {
        return (
            <div
                style={{
                    width: tableMeta.editButton.width,
                    textAlign: 'center'
                }}
            >
                <Link
                    to={{
                        pathname: `${pathEditPatient}/${row.externalId}`,
                        state: { id: row.externalId }
                    }}
                >
                    <Button type="primary" size="small">
                        {formatMessage(Strings.common.general.edit)}
                    </Button>
                </Link>
            </div>
        );
    }
};

const buildProfile = () => {
    return {
        title: titleDiv,
        dataIndex: tableMeta.profileButton.index,
        key: tableMeta.profileButton.key,
        render: profileButton,
        width: tableMeta.profileButton.width
    };

    function titleDiv() {
        return (
            <div
                style={{
                    width: tableMeta.profileButton.width,
                    textAlign: 'center'
                }}
            />
        );
    }

    function profileButton(_: undefined, row: PatientAdministrationUser) {
        return (
            <div
                style={{
                    width: tableMeta.profileButton.width,
                    textAlign: 'center'
                }}
            >
                <Link
                    to={{
                        pathname: pathPatientProfileLink(
                            row.groups[0],
                            row.externalId
                        )
                    }}
                >
                    <Button type="primary" size="small">
                        {formatMessage(Strings.common.general.patientData)}
                    </Button>
                </Link>
            </div>
        );
    }
};

const tableColumns = (
    sortedResponse: SortedType,
    groups: PatientGroups,
    columnFilter: ColumnFilterType,
    togglePatient: (
        externalId: string,
        data: TogglePatientData
    ) => Promise<void>,
    statusChangeReasons: () => ReasonsForChangeStatus,
    savePendingRegisterPatientId: () => void,
    savePendingPatientActivation: (
        externalId: string,
        reason: string,
        note?: string
    ) => void,
    routeProps: RouteComponentProps
) => {
    const columns = [
        buildSSN(sortedResponse),
        buildLastName(sortedResponse),
        buildFirstName(sortedResponse),
        buildMemberOf(sortedResponse, groups, columnFilter),
        buildStatus(sortedResponse),
        buildActiveSwitch(
            togglePatient,
            statusChangeReasons,
            savePendingRegisterPatientId,
            savePendingPatientActivation,
            routeProps
        ),
        buildEdit(),
        buildProfile()
    ];

    if (appConfig.STAGE === 'dev' || appConfig.STAGE === 'test') {
        // @ts-ignore
        columns.unshift(buildId());
    }

    return columns;
};

type Props = {
    loading: boolean;
    patientsList: PatientAdministrationUser[];
    groups: PatientGroups;
    routeProps: RouteComponentProps;
    togglePatient: (
        externalId: string,
        data: TogglePatientData
    ) => Promise<void>;
    statusChangeReasons: () => ReasonsForChangeStatus;
    savePendingRegisterPatientId: () => void;
    savePendingPatientActivation: (
        externalId: string,
        reason: string,
        note?: string
    ) => void;
    height?: number;
};

type SortedType = { columnKey: string; order: SortOrder };

const initSorter = {
    columnKey: tableMeta.familyName.key,
    field: tableMeta.familyName.index,
    order: 'ascend' as SortOrder
};

type ColumnFilterType = Partial<
    Record<keyof PatientAdministrationUser, string[]>
>;
const initFilter = {
    groups: undefined
};

const enhanceWithSizes = withSizes((size) => {
    return {
        height: size.height
    } as Props;
});

export const PatientAdministrationTable = enhanceWithSizes((props) => {
    const { loading, patientsList, groups } = props;

    const [sorterResult, setSorterResult] = useState<SortedType>(initSorter);

    const [colFilter, setColFilter] = useState<ColumnFilterType>(initFilter);

    const handleChange = (
        _: PaginationConfig,
        columnFilter: ColumnFilterType,
        columnSorterResult: SorterResult<PatientAdministrationUser>
    ) => {
        setColFilter(columnFilter);
        setSorterResult(columnSorterResult);
    };

    return (
        <Table
            loading={loading}
            pagination={false}
            columns={tableColumns(
                sorterResult,
                groups,
                colFilter,
                props.togglePatient,
                props.statusChangeReasons,
                props.savePendingRegisterPatientId,
                props.savePendingPatientActivation,
                props.routeProps
            )}
            dataSource={patientsList}
            rowKey={(patient: PatientAdministrationUser) => patient.externalId}
            scroll={{ y: props.height! - 250, x: 'max-content' }}
            onChange={handleChange}
        />
    );
});
