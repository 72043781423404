import { defineMessages } from 'react-intl';

const general = defineMessages({
    editNotAvailable: {
        id: 'medic.screen.patient.limits.edit_not_available',
        defaultMessage: 'Not possible to edit {indicator}'
    },
    limitsAndRemidnersAreNotSaved: {
        id: 'medic.screen.patient.limits_and_remidners_are_not_saved',
        defaultMessage:
            'The limits and reminders are not saved. Are you sure you want to cancel?'
    },
    limitsAreNotSaved: {
        id: 'medic.screen.patient.limits.not_saved',
        defaultMessage:
            'The limits are not saved. Are you sure you want to cancel?'
    },
    remindersAreNotSaved: {
        id: 'medic.screen.patient.reminders.not_saved',
        defaultMessage:
            'The reminders are not saved. Are you sure you want to cancel?'
    }
});

export const patient = {
    general
};
