import React, { FormEvent, useState } from 'react';
import { cloneDeep } from 'lodash';
import { InputFields } from '../../../../../components/form/inputs/types';
import { TextField } from '../../../../../components/form/inputs/TextField';
import {
    StyledButtonContainer,
    StyledButtonTableCell,
    StyledForm
} from './styled';
import { AppNeutralButton } from '../../../../../components/ui/buttons/AppNeutralButton';
import { AppPrimaryButton } from '../../../../../components/ui/buttons/AppPrimaryButton';
import { isFormValid } from './validation';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../locale/format/format-message';

type Props = {
    clearTable: () => void;
    initialFields: InputFields;
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

export const SearchForm = (props: Props): JSX.Element => {
    const getInitialFields = () => cloneDeep(props.initialFields);
    const [fields, setFields] = useState<InputFields>(getInitialFields());
    const [isValid, setIsValid] = useState<boolean>(isFormValid(fields));
    const [isTouched, setIsTouched] = useState<boolean>(false);

    return (
        <StyledForm onChange={onChange} onSubmit={props.onSubmit}>
            {Object.keys(fields).map((key) => (
                <TextField key={key} field={fields[key]} />
            ))}
            <StyledButtonContainer>
                <StyledButtonTableCell />
                <StyledButtonTableCell>
                    <AppNeutralButton
                        onClick={onReset}
                        htmlType="reset"
                        disabled={!isTouched}
                    >
                        {formatMessage(Strings.common.general.clear)}
                    </AppNeutralButton>
                    <AppPrimaryButton htmlType="submit" disabled={!isValid}>
                        {formatMessage(Strings.common.general.search)}
                    </AppPrimaryButton>
                </StyledButtonTableCell>
            </StyledButtonContainer>
        </StyledForm>
    );

    function onChange(event: React.FormEvent<HTMLFormElement>) {
        setIsValid(isFormValid(fields));
        setIsTouched(Object.keys(fields).some((key) => fields[key].value));
    }

    function onReset() {
        props.clearTable();
        setIsValid(false);
        setIsTouched(false);
        setFields(getInitialFields());
    }
};
