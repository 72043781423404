import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card } from 'antd';
import { ContentLayout } from '../../../components/content/ContentLayout';
import { routePatientOverview } from '../../patient/overview/route';
import { messages } from './messages';
import { usePageTitle } from '../../../hooks/use-page-title';
import { titleEducationFormPassed } from './title';
import { formatMessage } from '../../../locale/format/format-message';

export const EducationFormPassed = (): JSX.Element => {
    usePageTitle(titleEducationFormPassed);

    return (
        <ContentLayout>
            <Card>
                <div>
                    <h2 style={{ marginBottom: 20 }}>
                        {formatMessage(messages.educationFormPassed.header)}
                    </h2>
                    <p>
                        {formatMessage(
                            messages.educationFormPassed.information
                        )}
                    </p>
                </div>
                <NavLink
                    to={{
                        pathname: routePatientOverview.link
                    }}
                >
                    <Button>
                        {formatMessage(messages.educationFormPassed.button)}
                    </Button>
                </NavLink>
            </Card>
        </ContentLayout>
    );
};
