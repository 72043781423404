import { RouteItemType } from '../../../utils/route/switch-routes';
import { LoginEmailPage } from './LoginEmailPage';
import { pathLoginEmail } from './path';
import { titleLoginEmailPage } from './title';

export const routeLoginEmail: RouteItemType = {
    name: titleLoginEmailPage,
    link: pathLoginEmail,
    icon: '',
    exact: true,
    component: LoginEmailPage
};
