import { Indicator } from '../../../../types/indicator/indicator.type';
import { rules } from './oxygen-saturation-rules';

import icon from './images/oxygenation.svg';
import { messagesOxygenSaturation } from './messages/messages';

export const parameterOxygenSaturation = {
    code: 'OXYGEN-SATURATION'
};

export const oxygenSaturation: Indicator = {
    code: parameterOxygenSaturation.code,
    icon,
    messages: messagesOxygenSaturation,
    rules
};
