import { CLEAR_FILTER } from '../priority/priority.constants';
import { toggleUser } from '../user/user.api';
import {
    FETCH_SELECTED_PATIENT,
    FILTER_PATIENTS,
    TOGGLE_PATIENT
} from './patient-administration.constants';

export const filterPatients = (searchTerm: string) => ({
    type: FILTER_PATIENTS,
    payload: searchTerm
});

export const resetFilter = () => (dispatch: any) => {
    return dispatch({
        type: CLEAR_FILTER
    });
};

export const togglePatient =
    (externalId: string, data: any) => (dispatch: any) =>
        dispatch({
            type: TOGGLE_PATIENT,
            payload: toggleUser(externalId, data)
        });

export const setSelectedPatient = (externalId: string) => (dispatch: any) => {
    return dispatch({
        type: FETCH_SELECTED_PATIENT,
        payload: externalId
    });
};
