import { Strings } from '../../../locale/messagesDescriptors';
import { formatMessage } from '../../../locale/format/format-message';
import { colors } from '../../../theme/colors/commonColors';
import { ChartType } from '../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartHeatmapRules<boolean, boolean> = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        { value: true, color: colors.chart.heatMap.purple10 },
        { value: false, color: colors.chart.heatMap.purple80 }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.fish.choices.yes.id,
            text: formatMessage(Strings.indicators.fish.choices.yes),
            color: colors.chart.heatMap.purple10
        },
        {
            textId: Strings.indicators.fish.choices.no.id,
            text: formatMessage(Strings.indicators.fish.choices.no),
            color: colors.chart.heatMap.purple80
        }
    ]
};
