import { createAsyncThunk } from '@reduxjs/toolkit';
import { getStatistics } from './statistics.api';

import {
    FETCH_STATISTICS_FOR_GROUP,
    RESET_STATISTICS
} from './statistics.constants';
import { initialState } from './statistics.initial-state';

export const fetchStatistics = createAsyncThunk(
    FETCH_STATISTICS_FOR_GROUP,
    async (params: {
        id: string | null;
        from: number | null;
        to: number | null;
    }) => {
        const response = await getStatistics({
            id: params.id,
            from: params.from,
            to: params.to
        });
        return response;
    }
);

export const resetStatistics = createAsyncThunk(
    RESET_STATISTICS,
    () => initialState
);
