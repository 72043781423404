import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import {
    ModifiedDataType,
    Patients
} from '../../../redux/domains/statistics/statistics.type';
import base from '../../../theme/colors/base';
import { statisticsTitle } from './pie-enums';

export const getData = (statistics: Patients, type: string) => {
    let data: ModifiedDataType[] = [];

    switch (type) {
        case statisticsTitle.report:
            data = [
                {
                    name: formatMessage(Strings.statistics.charts.reporting),
                    value: statistics.count - statistics.nonReporting,
                    color: base.purple.secondary
                },
                {
                    name: formatMessage(Strings.statistics.charts.nonReporting),
                    value: statistics.nonReporting,
                    color: base.purple.tertiary
                }
            ];

            break;
        case statisticsTitle.gender:
            data = [
                {
                    name: formatMessage(Strings.statistics.charts.female),
                    value: statistics.female,
                    color: base.purple.secondary
                },
                {
                    name: formatMessage(Strings.statistics.charts.male),
                    value: statistics.male,
                    color: base.purple.tertiary
                }
            ];

            break;
        case statisticsTitle.vas:
            data = [
                {
                    name: formatMessage(Strings.statistics.charts.vasRed),
                    value: statistics.vas.red,
                    color: base.red.primary
                },
                {
                    name: formatMessage(Strings.statistics.charts.vasYellow),
                    value: statistics.vas.yellow,
                    color: base.yellow.primary
                },
                {
                    name: formatMessage(Strings.statistics.charts.vasGreen),
                    value: statistics.vas.green,
                    color: base.green.tertiary
                }
            ];

            break;
        case statisticsTitle.physact:
            data = [
                {
                    name: formatMessage(Strings.statistics.charts.reported),
                    value: statistics?.physact.reported,
                    color: base.purple.secondary
                },
                {
                    name: formatMessage(Strings.statistics.charts.notReported),
                    value: statistics?.physact.notReported,
                    color: base.purple.tertiary
                },
                {
                    name: formatMessage(Strings.statistics.charts.deactivated),
                    value: statistics?.physact.inactivated,
                    color: base.gray.primaryExtraBright
                }
            ];

            break;
        case statisticsTitle.smoke:
            data = [
                {
                    name: formatMessage(Strings.statistics.charts.reported),
                    value: statistics?.smoke.reported,
                    color: base.purple.secondary
                },
                {
                    name: formatMessage(Strings.statistics.charts.notReported),
                    value: statistics?.smoke.notReported,
                    color: base.purple.tertiary
                },
                {
                    name: formatMessage(Strings.statistics.charts.deactivated),
                    value: statistics?.smoke.inactivated,
                    color: base.gray.primaryExtraBright
                }
            ];

            break;
    }

    return data;
};
