import React, { PropsWithChildren } from 'react';
import { PrimitiveType } from 'intl-messageformat';
import { formatMessage } from './format-message';
import { MessageDescriptor } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import remarkSubSuper from 'remark-sub-super';

function LinkRenderer(props: PropsWithChildren<{ href: string }>) {
    return (
        <a href={props.href} target="_blank" rel="noopener noreferrer">
            {props.children}
        </a>
    );
}

type Paragraph = 'p' | 'div';

export const Markdown = (
    props: ReactMarkdown.ReactMarkdownProps & {
        paragraph?: Paragraph;
    }
): JSX.Element => (
    <ReactMarkdown
        source={props.source}
        plugins={[remarkSubSuper]}
        renderers={{
            link: LinkRenderer,
            sub: 'sub',
            sup: 'sup',
            paragraph: props.paragraph || 'p'
        }}
    />
);

export const MarkdownFromDescriptor = (props: {
    messageDescriptor: MessageDescriptor;
    values?: Record<string, PrimitiveType> | undefined;
    paragraph?: Paragraph;
}): JSX.Element => (
    <Markdown
        source={formatMessage(props.messageDescriptor, props.values)}
        paragraph={props.paragraph}
    />
);
