import { colors } from '../../../theme/colors/commonColors';
import { ChartType } from '../../../types/indicator/chart.type';
import { IndicatorPropertyRules } from '../../../types/indicator/indicator-rules.type';
import { BMIProperties } from './bmi-properties';

export const rules: IndicatorPropertyRules<BMIProperties> = {
    step: 0.1,
    chartTypes: [ChartType.Spline],
    lineColor: {
        name: colors.chart.graph.black.name,
        default: colors.chart.graph.black.primary,
        bmi: colors.chart.graph.black.primary,
        weight: colors.chart.graph.orange.primary,
        length: colors.chart.graph.black.primary
    },
    decimals: {
        bmi: 1,
        weight: 1,
        length: undefined
    }
};
