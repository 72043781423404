import { defineMessages } from 'react-intl';

const label = defineMessages({
    email: {
        id: 'labels.label.email',
        defaultMessage: 'E-mail'
    },
    password: {
        id: 'labels.label.password',
        defaultMessage: 'Password'
    },
    familyName: {
        id: 'labels.label.family-name',
        defaultMessage: 'Last Name'
    },
    firstName: {
        id: 'labels.label.first-name',
        defaultMessage: 'First Name'
    },
    medicGroup: {
        id: 'labels.label.medic-group',
        defaultMessage: 'Care unit'
    },
    medicID: {
        id: 'labels.label.medic-id',
        defaultMessage: 'Caregiver-id'
    },
    phoneNumber: {
        id: 'labels.label.phone-number',
        defaultMessage: 'Phone number'
    },
    role: {
        id: 'labels.label.role',
        defaultMessage: 'Role'
    },
    status: {
        id: 'labels.label.status',
        defaultMessage: 'Status'
    },
    title: {
        id: 'labels.label.title',
        defaultMessage: 'Title'
    },
    access: {
        id: 'labels.label.access',
        defaultMessage: 'Access to groups'
    },
    loginMethod: {
        id: 'labels.label.login-method',
        defaultMessage: 'Signin method'
    }
});

export const labels = { label };
