import { Indicator } from '../../../../types/indicator/indicator.type';
import { rules } from './active-motion-rules';

import icon from './images/active-motion.svg';
import { messagesActiveMotion } from './messages/messages';

export const parameterActiveMotion = {
    code: 'ACTIVE-MOTION-HS'
};

export const activeMotion: Indicator = {
    code: parameterActiveMotion.code,
    icon,
    messages: messagesActiveMotion,
    rules
};
