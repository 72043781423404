import React, { ChangeEvent, useRef } from 'react';
import throttle from 'lodash/throttle';
import CustomSearchBar from '../../../../components/shared/search-bar/SearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { filterPriorityListsBySearchTerm } from '../../../../redux/domains/priority/priority.actions';
import { getOverviewFilter } from '../../../../redux/domains/ui/ui.selectors';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';

export const PriorityTableSearchBar = (): JSX.Element => {
    const dispatch = useDispatch();
    const previousSearchState = useSelector(getOverviewFilter);
    const searchInput = useRef<any>();
    const throttledSearch = throttle(
        (value) => dispatch(filterPriorityListsBySearchTerm(value)),
        300
    );

    return (
        <CustomSearchBar
            placeholder={formatMessage(Strings.form.general.searchPlaceholder)}
            onChange={onSearchChange}
            onEmitEmpty={onEmitEmpty}
            innerRef={(node: any) => (searchInput.current = node)}
            value={previousSearchState.searchTerm}
        />
    );

    function onSearchChange(e: ChangeEvent<HTMLInputElement>) {
        throttledSearch(e.target.value);
    }

    function onEmitEmpty() {
        searchInput.current.focus();
        dispatch(filterPriorityListsBySearchTerm(''));
    }
};
