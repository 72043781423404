import { combineReducers } from 'redux';
import { keyBy } from 'lodash';
import {
    FETCH_PATIENT_DATA_CONVERSATIONS_FULFILLED,
    FETCH_PATIENT_DATA_GROUPS_FULFILLED,
    FETCH_PATIENT_DATA_MEDIC_GROUPS_FULFILLED,
    FETCH_PATIENT_DATA_MESSAGES_FULFILLED,
    RESET_PATIENT_PROFILE_CONVERSATIONS,
    SET_PATIENT_DATA_CONVERSATION_ID
} from './patient-data.constants';
import { PatientData, PatientConversation } from './patient-data.types';
import { ChatConversation } from '../chat/chat.types';

const initialState: PatientData = {
    patientGroups: null,
    medicGroups: [],
    conversations: {
        patientConversations: null,
        currentMessages: null,
        selectedConversationId: null
    }
};

function patientGroups(state = initialState.patientGroups, action: any) {
    const { payload, type } = action;

    switch (type) {
        case FETCH_PATIENT_DATA_GROUPS_FULFILLED:
            return keyBy(payload, 'id');
        default:
            return state;
    }
}

function medicGroups(state = initialState.medicGroups, action: any) {
    const { payload, type } = action;

    switch (type) {
        case FETCH_PATIENT_DATA_MEDIC_GROUPS_FULFILLED:
            return payload;
        default:
            return state;
    }
}

function conversationsByMedicGroupId(conversations: ChatConversation[]) {
    const patientConversations = conversations
        .map(({ id, tags, medicGroupName }) => ({
            id,
            medicGroupId: tags.find((tag) => tag.key === 'groupId')?.value,
            medicGroupName
        }))
        .filter(
            (conversation): conversation is PatientConversation =>
                conversation.medicGroupId != null
        );
    return keyBy(patientConversations, 'medicGroupId');
}

function conversations(
    state = initialState.conversations,
    action: { type: string; payload: any }
) {
    switch (action.type) {
        case FETCH_PATIENT_DATA_CONVERSATIONS_FULFILLED:
            return {
                ...state,
                patientConversations: conversationsByMedicGroupId(
                    action.payload
                )
            };
        case FETCH_PATIENT_DATA_MESSAGES_FULFILLED:
            return {
                ...state,
                currentMessages: action.payload
            };
        case SET_PATIENT_DATA_CONVERSATION_ID:
            return {
                ...state,
                selectedConversationId: action.payload
            };
        case RESET_PATIENT_PROFILE_CONVERSATIONS:
            return initialState.conversations;
        default:
            return state;
    }
}

export const patientData = combineReducers({
    patientGroups,
    medicGroups,
    conversations
});
