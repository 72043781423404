import { combineReducers } from 'redux';
import { keyBy } from 'lodash';
import * as actionTypes from './chat.constants';
import {
    Chat,
    ByConversationId,
    ChatConversation,
    Message
} from './chat.types';

const initialState: Chat = {
    bySubjectId: {},
    activeConversation: ''
};

const parseConversation = ({
    id,
    tags,
    status,
    participants,
    medicGroupName
}: ChatConversation) => ({
    id,
    tags,
    status,
    participants,
    medicGroupName
});

const addConversationEntry = (
    state: ByConversationId,
    action: {
        payload: {
            data: ChatConversation;
        };
    }
): ByConversationId => {
    const conversation = parseConversation(action.payload.data);
    return {
        ...state,
        [conversation.id]: conversation
    };
};

const addMessageToConversation = (
    state: ByConversationId,
    action: {
        payload: {
            message: Message;
            conversationId: string;
        };
    }
): ByConversationId => {
    const { message, conversationId } = action.payload;

    const oldMessages = state[conversationId].messages || [];

    return {
        ...state,
        [conversationId]: {
            ...state[conversationId],
            messages: [...oldMessages, message]
        }
    };
};

const updateConversationMessages = (
    state: ByConversationId,
    action: { payload: { data: Message[]; conversationId: string } }
): ByConversationId => {
    const { data: messages, conversationId } = action.payload;

    return {
        ...state,
        [conversationId]: {
            ...state[conversationId],
            messages
        }
    };
};

const updateConversationStatus = (
    state: ByConversationId,
    action: any
): ByConversationId => {
    const { conversationId, status } = action.payload;

    return {
        ...state,
        [conversationId]: {
            ...state[conversationId],
            status
        }
    };
};

const byConversationId = (
    state: ByConversationId | null = null,
    action: any
) => {
    if (state) {
        switch (action.type) {
            case actionTypes.FETCH_MESSAGES_FULFILLED:
                return updateConversationMessages(state, action);
            case actionTypes.POST_MESSAGE_FULFILLED:
                return addMessageToConversation(state, action);
            case actionTypes.CREATE_CONVERSATION_FULFILLED:
                return addConversationEntry(state, action);
            case actionTypes.UPDATE_CONVERSATION_STATUS_FULFILLED:
            case actionTypes.CLEAR_CONVERSATION_STATUS:
                return updateConversationStatus(state, action);
        }
    }
    return state;
};

const updateConversations = combineReducers({
    byConversationId
});

const bySubjectId = (state = initialState.bySubjectId, action: any) => {
    const { payload, type } = action;
    const externalId = payload?.externalId;

    switch (type) {
        case actionTypes.FETCH_CONVERSATION_FULFILLED: {
            return {
                ...state,
                [action.meta.externalId]: {
                    byConversationId: keyBy(payload, 'id')
                }
            };
        }

        default:
            if (typeof externalId === 'string') {
                const conversations = updateConversations(
                    state[externalId],
                    action
                );
                if (
                    conversations.byConversationId &&
                    conversations !== state[externalId]
                ) {
                    return {
                        ...state,
                        [externalId]: conversations
                    };
                }
            }

            return state;
    }
};

const activeConversation = (
    state = initialState.activeConversation,
    action: any
) => {
    const { type, payload } = action;
    if (type === actionTypes.SET_CURRENT_ACTIVE_CONVERSATION) {
        return payload;
    } else {
        return state;
    }
};

const chatReducer = combineReducers({
    bySubjectId,
    activeConversation
});

export default chatReducer;
