import { defineMessages } from 'react-intl';

const createPatient = defineMessages({
    createPatient: {
        id: 'medic.screen.patientAdministration.createPatient.title',
        defaultMessage: 'Register patient'
    },
    dialogUnconfirmedTitle: {
        id: 'medic.screen.patientAdministration.createPatient.dialog.unconfirmed.title',
        defaultMessage: 'N.B.'
    },
    dialogUnconfirmedBody: {
        id: 'medic.screen.patientAdministration.createPatient.dialog.unconfirmed.body',
        defaultMessage:
            'The registration has not been completed for this patient. Do you want to resume the registration process?'
    },
    dialogInactiveTitle: {
        id: 'medic.screen.patientAdministration.createPatient.dialog.inactive.title',
        defaultMessage: 'N.B.'
    },
    dialogInactiveBody: {
        id: 'medic.screen.patientAdministration.createPatient.dialog.inactive.body',
        defaultMessage:
            'To activate the patient the limits needs to be approved. Do you want to proceed?'
    }
});

const step1 = defineMessages({
    title: {
        id: 'medic.screen.patientAdministration.createPatient.step1.title',
        defaultMessage: 'Register patient'
    },
    dialogTitle: {
        id: 'medic.screen.patientAdministration.createPatient.step1.dialog.title',
        defaultMessage: 'Register patient'
    },
    dialogContent: {
        id: 'medic.screen.patientAdministration.createPatient.step1.dialog.content',
        defaultMessage:
            'You are about to register a new patient. Do you want to continue?'
    }
});

const step2 = defineMessages({
    title: {
        id: 'medic.screen.patientAdministration.createPatient.step2.title',
        defaultMessage: 'Initial values'
    },
    dialogTitle: {
        id: 'medic.screen.patientAdministration.createPatient.step2.dialog.title',
        defaultMessage: 'Submit observations'
    },
    dialogContent: {
        id: 'medic.screen.patientAdministration.createPatient.step2.dialog.content',
        defaultMessage:
            'You will now save initial values.  \nDo you want to continue?'
    },
    noInitialObservations: {
        id: 'medic.screen.patientAdministration.createPatient.step2.dialog.no-initial-observations',
        defaultMessage: 'No initial values. Proceed to the next step.'
    }
});

const step3 = defineMessages({
    title: {
        id: 'medic.screen.patientAdministration.createPatient.step3.title',
        defaultMessage: 'Confirm limits'
    },
    acceptLimits: {
        id: 'medic.screen.patientAdministration.createPatient.step3.acceptLimits',
        defaultMessage: 'I accept the current limits'
    }
});

export const patientAdministration = {
    createPatient,
    step1,
    step2,
    step3
};
