import { ColorType } from 'highcharts';
import { formatMessageById } from '../../../../locale/format/format-message';
import { IndicatorCode } from '../../../../types/indicator/indicator.type';
import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import {
    PointWithComment,
    ToolTipPoint,
    UserOptionsWithCode
} from './tooltip.type';
import { PUMP_SPEED_LVAD } from '../../../../conditions/indicator-meta';
import moment from 'moment';

export function tooltipFormatterSingleProperty(
    chartIndicators: Record<IndicatorCode, IndicatorWithSettings>,
    indicatorCode: string | undefined,
    points: PointWithComment[]
): string {
    let tooltipString = '';

    points.map((point) => {
        if (point.x) {
            const timestamp =
                typeof point.x === 'string' ? parseInt(point.x) : point.x;
            const date = formatDate(timestamp);
            tooltipString += date;
        }
        if (point.series.userOptions.color) {
            const splineIndicator = formatSplineColorIndicator(
                point.series.userOptions.color
            );
            tooltipString += splineIndicator;
        }
        if (point.series.name) {
            const name = formatName(point.series.name);
            tooltipString += name;
        }
        if (
            point.y &&
            indicatorCode &&
            chartIndicators[indicatorCode].settings.rules.step
        ) {
            const steps = getSteps(chartIndicators[indicatorCode]);
            let numberOfDecimals = 0;
            if (steps?.toString().includes('.')) {
                numberOfDecimals = countDecimals(steps);
            }
            const locale = moment.locale();
            const value = formatValue(point.y, numberOfDecimals, locale);
            tooltipString += value;
        }
        if (indicatorCode) {
            if (indicatorCode === PUMP_SPEED_LVAD.code) {
                tooltipString += ` ${formatMessageById(
                    `indicator.${indicatorCode.toLowerCase()}.unit-x1000`
                )}`;
            } else {
                tooltipString += ` ${formatMessageById(
                    `indicator.${indicatorCode.toLowerCase()}.unit`,
                    { value: point.y }
                )}`;
            }
        }
        if (point.comment) {
            const comment = formatComment(point.comment);
            tooltipString += comment;
        }
    });

    return tooltipString;
}

export function tooltipFormatterMultiProperty(
    chartIndicators: Record<IndicatorCode, IndicatorWithSettings>,
    points: ToolTipPoint[]
): string {
    let dateIsRendered = false;
    let tooltipString = '';

    points.map((point) => {
        if (dateIsRendered) {
            tooltipString += `<br>`;
        }
        if (point.x && !dateIsRendered) {
            const timestamp =
                typeof point.x === 'string' ? parseInt(point.x) : point.x;
            const date = formatDate(timestamp);

            tooltipString += date;
            dateIsRendered = true;
        }
        if (point.series.userOptions.color) {
            const splineIndicator = formatSplineColorIndicator(
                point.series.userOptions.color
            );
            tooltipString += splineIndicator;
        }
        if (point.series.name) {
            const name = formatName(point.series.name);
            tooltipString += name;
        }
        const userOptions: UserOptionsWithCode = point.series.userOptions;
        const indicatorCode = userOptions.indicatorCode;
        if (
            point.y &&
            indicatorCode &&
            chartIndicators[indicatorCode].settings.rules.step
        ) {
            const steps = getSteps(chartIndicators[indicatorCode]);
            let numberOfDecimals = 0;
            if (steps?.toString().includes('.')) {
                numberOfDecimals = countDecimals(steps);
            }
            const locale = moment.locale();
            const value = formatValue(point.y, numberOfDecimals, locale);
            tooltipString += value;
        }
        if (point.series.userOptions.tooltip?.valueSuffix) {
            tooltipString += `${point.series.userOptions.tooltip?.valueSuffix}`;
        }

        if (point.point.comment) {
            const comment = formatComment(point.point.comment);
            tooltipString += comment;
        }
    });
    return tooltipString;
}

export function formatDate(date: number) {
    return `<b>${moment(date).format('llll')}</b><br><br>`;
}

export function formatSplineColorIndicator(color: ColorType) {
    const colorToString = color.toString();
    return `<span style="color: ${colorToString}">\u25CF </span>`;
}

export function getSteps(currentIndicator: IndicatorWithSettings) {
    let decimals = currentIndicator.settings.rules.step;
    if (
        currentIndicator.settings.rules.divideBy &&
        currentIndicator.settings.rules.step
    ) {
        decimals =
            currentIndicator.settings.rules.step /
            currentIndicator.settings.rules.divideBy;
    }

    return decimals;
}

export function formatValue(value: number, decimals: number, locale: string) {
    return `<b>${value.toLocaleString(locale, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    })}</b>`;
}

export function countDecimals(steps: number) {
    return steps?.toString().split('.')[1].length;
}

export function formatName(name: string) {
    return `${name}: `;
}

export function formatComment(comment: string) {
    return `<br><i>"${comment}"</i>`;
}
