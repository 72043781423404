import { Indicator } from '../../../types/indicator/indicator.type';

import icon from './images/prnmed.svg';
import { messagesPrnmed } from './messages/messages';
import { rules } from './prnmed-rules';

export const parameterPrnmed = {
    code: 'PRNMED'
};

export const prnmed: Indicator = {
    code: parameterPrnmed.code,
    icon,
    messages: messagesPrnmed,
    rules
};
