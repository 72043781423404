import React, { useEffect } from 'react';
import jwt from 'jsonwebtoken';
import jwkToPem from 'jwk-to-pem';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { CrossSpinner } from '../../../../../components/ui/loading/loading';
import {
    authenticateWithTokens,
    checkAuthentication
} from '../../../../../redux/domains/authentication/authentication.actions';
import { getLifePodJWKs } from '../../../../../redux/domains/authentication/authentication.api';
import { createErrorSelector } from '../../../../../redux/domains/requests/requests.selectors';
import { getAuthenticatedUser } from '../../../../../redux/domains/user/user.selectors';
import {
    AuthTokens as Props,
    Token
} from '../../../../../utils/token/token-service';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';

const errorSelector = createErrorSelector(['CHECK_AUTHENTICATION']);

const isDecodedToken = (decoded: string | object | Token): decoded is Token =>
    typeof decoded !== 'string' &&
    decoded != null &&
    'exp' in decoded &&
    typeof decoded.exp === 'number' &&
    'sub' in decoded &&
    typeof decoded.sub === 'string';

const verifyToken = (idToken: string) =>
    checkAuthentication(
        getLifePodJWKs().then(({ data: jwk }) => {
            const decoded = idToken && jwt.verify(idToken, jwkToPem(jwk));
            if (isDecodedToken(decoded)) {
                return decoded;
            }
            throw new Error('Unexpected token provided');
        })
    );

const VerifyToken = ({ idToken, refreshToken }: Props) => {
    const dispatch = useDispatch();

    const error = useSelector(errorSelector);
    const user = useSelector(getAuthenticatedUser);

    useEffect(() => {
        dispatch(verifyToken(idToken));
        if (idToken) {
            dispatch(authenticateWithTokens({ idToken, refreshToken }));
        }
    }, [dispatch, idToken, refreshToken]);

    if (user.externalId) {
        return (
            <Redirect
                to={{
                    pathname: '/'
                }}
            />
        );
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <CrossSpinner
            iconOnly={true}
            card={false}
            text={formatMessage(Strings.authText.main.loggingIn)}
        />
    );
};

export default VerifyToken;
