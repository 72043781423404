import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkAuthentication } from '../redux/domains/authentication/authentication.actions';
import { authStorageKeys } from '../utils/storage/auth/auth-storage-keys';

export const useCheckAuthentication = (): void => {
    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(checkAuthentication()).catch((error: any) => {
            if (typeof error !== 'string') {
                console.warn(error);
            }
        });

        window.addEventListener('storage', syncLogin);

        return function () {
            window.removeEventListener('storage', syncLogin);
        };

        function syncLogin(event: StorageEvent) {
            if (event.key === authStorageKeys.idToken && event.newValue) {
                dispatch(checkAuthentication());
            }
        }
    }, [dispatch]);
};
