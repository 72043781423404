export type OrderBy = -1 | 0 | 1;

export const alphabetic = (a = '', b = ''): OrderBy => {
    a = a.toLowerCase();
    b = b.toLowerCase();

    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }

    return 0;
};
