import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux/redux-state.type';
import { getData } from '../../../components/charts/pie/getModifiedData';
import { PieChart } from '../../../components/charts/pie/PieChart';
import {
    ModifiedDataType,
    Patients
} from '../../../redux/domains/statistics/statistics.type';
import {
    StyledCondition,
    StyledConditionContent,
    StyledExtraConditions,
    StyledH3
} from '../styles/conditions-styles';
import { statisticsTitle } from '../../../components/charts/pie/pie-enums';
import { formatMessage } from '../../../locale/format/format-message';
import { PieChartVAS } from '../../../components/charts/pie/PieChartVAS';
import { Strings } from '../../../locale/messagesDescriptors';
import { checkIfHasValues } from './checkIfHasValues';
import { StyledEmptyValueDiv } from '../styles/statistics-page-styles';

export function ConditionsContent(props: { patients: Patients }): JSX.Element {
    const patients = props.patients;

    const indicators = useSelector(
        (state: ReduxState) => state.indicators.byId
    );

    const [smokeExist, setSmokeExist] = useState(false);
    const [physactExist, setPhysactExist] = useState(false);

    const vasData = getData(patients, statisticsTitle.vas);
    const smokeData = getData(patients, statisticsTitle.smoke);
    const physactData = getData(patients, statisticsTitle.physact);

    const vasValueHasValues = checkIfHasValues(vasData);
    const physactValueHasValues = checkIfHasValues(physactData);
    const smokeValueHasValues = checkIfHasValues(smokeData);

    useEffect(() => {
        Object.values(indicators).map((indicator) => {
            if (
                indicator.code.toLowerCase().includes(statisticsTitle.physact)
            ) {
                setPhysactExist(true);
            }
            if (indicator.code.toLowerCase().includes(statisticsTitle.smoke)) {
                setSmokeExist(true);
            }
        });
    });

    return (
        <StyledConditionContent>
            <div>
                <StyledCondition
                    className="styledCondition"
                    style={{ borderRight: '1px dotted grey' }}
                >
                    <StyledH3>
                        {formatMessage(Strings.statistics.charts.vas)}
                    </StyledH3>
                    {vasValueHasValues ? (
                        <>
                            <div>
                                <PieChartVAS data={vasData} />
                            </div>
                            <p>
                                {formatMessage(
                                    Strings.statistics.charts.vasDesc
                                )}
                            </p>
                        </>
                    ) : (
                        <StyledEmptyValueDiv>
                            <p>
                                {formatMessage(
                                    Strings.statistics.charts.vasEmpty
                                )}
                            </p>
                        </StyledEmptyValueDiv>
                    )}
                </StyledCondition>
            </div>
            <StyledExtraConditions>
                {physactExist && (
                    <StyledCondition>
                        <StyledH3>
                            {formatMessage(Strings.statistics.charts.physact)}
                        </StyledH3>
                        {physactValueHasValues ? (
                            <PieChart data={physactData} />
                        ) : (
                            <StyledEmptyValueDiv>
                                <p>
                                    {formatMessage(
                                        Strings.statistics.general.noPatients
                                    )}
                                </p>
                            </StyledEmptyValueDiv>
                        )}
                    </StyledCondition>
                )}

                {smokeExist && (
                    <StyledCondition>
                        <StyledH3>
                            {formatMessage(Strings.statistics.charts.smoke)}
                        </StyledH3>
                        {smokeValueHasValues ? (
                            <PieChart data={smokeData} />
                        ) : (
                            <StyledEmptyValueDiv>
                                <p>
                                    {formatMessage(
                                        Strings.statistics.general.noPatients
                                    )}
                                </p>
                            </StyledEmptyValueDiv>
                        )}
                    </StyledCondition>
                )}
            </StyledExtraConditions>
        </StyledConditionContent>
    );
}
