import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    CLEAR_SELECTECED_PRIO_PATIENT,
    SET_APP_PAGE_TITLE,
    SET_PENDING_PATIENT,
    SET_SELECTECED_PRIO_PATIENT
} from './app-state.constants';

export const setAppPageTitle = createAsyncThunk(
    SET_APP_PAGE_TITLE,
    (title: string) => title
);

export const setSelectedPrioPatient = createAsyncThunk(
    SET_SELECTECED_PRIO_PATIENT,
    (ssn: string | null) => ssn
);

export const clearSelectedPrioPatient = createAsyncThunk(
    CLEAR_SELECTECED_PRIO_PATIENT,
    () => null
);

export const setPendingPatient = createAsyncThunk(
    SET_PENDING_PATIENT,
    (ssn: string | null) => ssn
);
