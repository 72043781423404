import {
    IndicatorBoundariesLimits,
    CombinedConfiguration
} from '../../../../indicators/indicator.type';

export function getConfigurationFromConditionIndicatorConfiguration(
    indicatorConfiguration: CombinedConfiguration
): IndicatorBoundariesLimits {
    return (
        // @ts-ignore
        indicatorConfiguration.sum ||
        // @ts-ignore
        indicatorConfiguration.bmi ||
        indicatorConfiguration
    );
}
