import { formatMessageById } from '../../../../../../../locale/format/format-message';
import { formatNumber } from '../../../../../../../locale/format/format-number';
import { Strings } from '../../../../../../../locale/messagesDescriptors';
import { IndicatorWithSettings } from '../../../../../../../redux/domains/indicators/indicator.type';

export const getQuestionnaireFormValues = (
    indicator: IndicatorWithSettings,
    keys: string[]
) => {
    const { form, code } = indicator;

    const formColumns = form.item
        .filter((item: any) => keys.includes(item.code))
        .map((item: any) => {
            const { code: itemCode } = item;
            const localeId = `${indicator.code.toLowerCase()}.${itemCode}`;

            const titleText = formatMessageById(
                `${Strings.indicators.rootId}${localeId}.title`
            );

            return {
                key: item.id,
                title: titleText,
                align: 'left',
                width: 150,
                dataIndex: `value[${itemCode}]`,
                render: (text: number | boolean | Record<string, number>) => {
                    const indicatorCode = indicator.code.toLowerCase();

                    if (typeof text === 'undefined') {
                        return '-';
                    }

                    if (typeof text === 'boolean') {
                        const boolValue = text.toString();
                        const localeId = `${indicatorCode}.${itemCode}.choices.${boolValue}`;

                        const returnString = formatMessageById(
                            `${Strings.indicators.rootId}${localeId}`
                        );

                        return returnString;
                    }

                    if (typeof text === 'number') {
                        return text;
                    }
                    const value = Object.values(text);
                    const answerId = value[0];
                    const localeId = `${indicatorCode}.${itemCode}.choices.${answerId}`;
                    const returnString = formatMessageById(
                        `${Strings.indicators.rootId}${localeId}`
                    );

                    return returnString;
                }
            };
        });

    const title = `${formatMessageById(
        `${Strings.indicators.rootId}${code.toLowerCase()}.sum.title`
    )}`;

    return [
        {
            key: 'score',
            title: title,
            dataIndex: 'value.sum',
            render: (sum: number) => formatNumber(Math.round(sum)),
            width: 150
        },
        ...formColumns
    ];
};
