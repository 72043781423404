import { defineMessages } from 'react-intl';

const registerMedic = defineMessages({
    add: {
        id: 'register-medic.add',
        defaultMessage: 'Register new co-worker'
    },
    coworker: {
        id: 'register-medic.co-worker',
        defaultMessage: 'Co-worker'
    },
    adminRights: {
        id: 'register-medic.admin',
        defaultMessage: 'Admin rights'
    }
});

export const messages = {
    registerMedic
};
