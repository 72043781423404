export type ProxyMetaType = {
    practitioner_name: string;
    practitioner_family_name: string;
    name: string;
    family_name: string;
};

export const getPractitionerName = (proxyMeta: ProxyMetaType) => {
    const { practitioner_name, practitioner_family_name } = proxyMeta;
    let { name, family_name } = proxyMeta;

    name = practitioner_name || name;
    family_name = practitioner_family_name || family_name;

    return `${name} ${family_name}`;
};
