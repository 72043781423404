import { Indicator } from '../../../types/indicator/indicator.type';

import icon from './images/swelling.svg';
import { messagesSwelling } from './messages/messages';
import { SwellingProperties } from './swelling-properties';
import { rules } from './swelling-rules';

export const parameterSwelling = {
    code: 'SWELLING',
    properties: SwellingProperties
};

export const swelling: Indicator = {
    code: parameterSwelling.code,
    icon,
    messages: messagesSwelling,
    rules
};
