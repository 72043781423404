import React from 'react';

export function DrawRect(props: {
    color: string;
    rectSize?: string;
    svgStyle?: React.CSSProperties;
}): JSX.Element {
    const rectSize = props.rectSize || '14';
    const svgStyle = props.svgStyle || { marginRight: '5px' };

    return (
        <svg style={svgStyle} width={rectSize} height={rectSize}>
            <rect
                width={rectSize}
                height={rectSize}
                style={{ fill: props.color }}
            />
        </svg>
    );
}
