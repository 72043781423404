import indicatorRules from '../../../utils/create-condition-settings/indicator-rules';
import { indicatorItem } from '../../../utils/create-condition-settings/items';
import { reasonsStatusChange } from '../../../utils/create-condition-settings/reasons-status-change';
import { ConditionRules } from '../../condition.type';
import { IndicatorMeta } from '../../indicator-meta';

export const createHypertensionCapioConditionConfiguration =
    (): ConditionRules => {
        return {
            initialObservationIndicators: [IndicatorMeta.BODY_WEIGHT.code],
            priorityTable: {
                hidden: [
                    IndicatorMeta.FISH.code,
                    IndicatorMeta.VEGETABLES.code,
                    IndicatorMeta.CANDY.code,
                    IndicatorMeta.WAIST_CIRCUMFERENCE.code
                ],
                order: [
                    IndicatorMeta.BLOOD_PRESSURE.code,
                    IndicatorMeta.PULSE.code,
                    IndicatorMeta.BODY_WEIGHT.code,
                    IndicatorMeta.VAS.code,
                    IndicatorMeta.PHYSACT.code,
                    IndicatorMeta.SMOKE.code
                ]
            },
            indicatorSettings: {
                preventFromEditLimits: [
                    IndicatorMeta.PHYSACT.code,
                    IndicatorMeta.FISH.code,
                    IndicatorMeta.CANDY.code,
                    IndicatorMeta.SMOKE.code
                ],
                preventFromEditReminders: [],
                preventFromToggle: [IndicatorMeta.VAS.code]
            },
            reasons: reasonsStatusChange(),
            indicators: {
                [IndicatorMeta.BLOOD_PRESSURE.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.BLOOD_PRESSURE.code]
                ),
                [IndicatorMeta.PULSE.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.PULSE.code]
                ),
                [IndicatorMeta.BODY_WEIGHT.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.BODY_WEIGHT.code]
                ),
                [IndicatorMeta.VAS.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.VAS.code]
                ),
                [IndicatorMeta.PHYSACT.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.PHYSACT.code]
                ),
                [IndicatorMeta.SMOKE.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.SMOKE.code]
                ),
                [IndicatorMeta.FISH.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.FISH.code]
                ),
                [IndicatorMeta.VEGETABLES.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.VEGETABLES.code]
                ),
                [IndicatorMeta.CANDY.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.CANDY.code]
                ),
                [IndicatorMeta.WAIST_CIRCUMFERENCE.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.WAIST_CIRCUMFERENCE.code]
                )
            }
        };
    };
