import { interactiveYellow } from '../colors/colors';
import { MotdMessage } from '../../redux/domains/motd/motd.types';
import brightProfile from '../colors/profile/profile';
import { mainBGColor } from '../colors/app-colors';
import { ColorType, ProfileType } from './profile.types';
import { layout } from '../layout/layout';
import { hexToRGB } from '../utils/theme-utils';

export enum ThemeEnum {
    DARK = 'DARK',
    LIGHT = 'LIGHT'
}

const ui: ProfileType['ui'] = {
    iconGray: '#847a7a'
};

const navigation: ProfileType['navigation'] = {
    fontWeight: '400'
};

const chat: ProfileType['chat'] = {
    emergency: {
        sticky: {
            buttonColor: '#ff0000',
            textColor: '#f7f7f7',
            background: '#464646'
        }
    }
};

const motdColorForTypes: MotdMessage = {
    warning: '#faad14',
    info: '#1890ff'
};

const motd: ProfileType['motd'] = {
    motdColorForTypes: motdColorForTypes,
    text: {
        header: '#f7f7f7',
        body: '#464646'
    }
};

const spacing = {
    xSmall: 5,
    small: 10,
    medium: 15,
    large: 20,
    xLarge: 25
};

const color: ColorType = {
    lightBlue: 'aliceblue',
    info: '#479dea',
    red: 'red',
    selected: '#5b64c2',
    text: '#666666',
    hover: '#828bcf',
    deselected: 'lightgray',
    disabled: '#8F8F8F'
};

export const profileLight: ProfileType = {
    conversation: {
        bgColor: '#f6f6f6',
        message: {
            patient: {
                bgColor: '#8B67CE',
                textColor: '#fff'
            },
            otherMedic: {
                textColor: '#323232',
                bgColor: '#E9E9E9'
            },
            activeMedic: {
                textColor: '#fff',
                bgColor: '#2c8eff'
            }
        }
    },
    util: {
        hexToRGB
    },
    layout: layout,
    primary: {
        text: '#000',
        color: '#5b64c2'
    },
    secondary: {
        interactive: interactiveYellow
    },
    main: '#ddd',
    text: '#000',
    backgroundColor: 'white',
    contentBackground: mainBGColor,
    navigation,
    ui,
    chat,
    motd: motd,
    profile: brightProfile,
    spacing: spacing,
    color
};

export const profiles = {
    light: profileLight,
    main: profileLight
};

export const getTheme = (themeMode = 'light'): ProfileType => {
    if (themeMode === 'light') {
        return profiles.light;
    }
    return profiles.light;
};

export const profileDark: ProfileType = profileLight;
