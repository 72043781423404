import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import {
    savePendingPatientActivation,
    savePendingRegisterPatientId
} from '../../../../redux/domains/active-patient/active-patient.actions';
import { setSelectedCondition } from '../../../../redux/domains/conditions/conditions.actions';
import { getPatientGroupsForCurrentSelectedMedicGroup } from '../../../../redux/domains/groups/groups.selectors';
import {
    filterPatients,
    resetFilter,
    togglePatient
} from '../../../../redux/domains/patient-administration/patient-administration.actions';
import { getFilteredPatients } from '../../../../redux/domains/patient-administration/patient-administration.selectors';
import { getReasonsStatusChangeSettings } from '../../../../redux/domains/settings/settings.selectors';
import PatientAdministrationList from './components/PatientAdministrationList';

export interface TogglePatientData {
    status: string;
    reason: string;
    note?: string;
}

export const PatientAdministrationListWrapperPage = (): JSX.Element => {
    const dispatch: any = useDispatch();
    const routeHistory = useHistory();

    const patientAdminLoading = useReduxSelector(
        (state) => state.ui.patientAdministration.loading
    );

    const groups = useReduxSelector(
        getPatientGroupsForCurrentSelectedMedicGroup
    );

    const patientsList = useReduxSelector(getFilteredPatients);

    const statusChangeReasons = useReduxSelector(
        getReasonsStatusChangeSettings
    );

    useEffect(() => {
        dispatch(resetFilter());
        dispatch(setSelectedCondition(''));
    }, [dispatch]);

    return (
        <PatientAdministrationList
            searchTerm={undefined}
            routeProps={{ history: routeHistory }}
            patientsList={patientsList}
            togglePatient={(externalId: string, data: TogglePatientData) =>
                dispatch(togglePatient(externalId, data))
            }
            loading={patientAdminLoading}
            groups={groups}
            statusChangeReasons={statusChangeReasons}
            filterPatients={(searchTerm: string) =>
                dispatch(filterPatients(searchTerm))
            }
            savePendingRegisterPatientId={(externalId: string) =>
                dispatch(savePendingRegisterPatientId(externalId))
            }
            savePendingPatientActivation={(
                externalId: string,
                reason?: string,
                note?: string
            ) =>
                dispatch(savePendingPatientActivation(externalId, reason, note))
            }
        />
    );
};
