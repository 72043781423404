import { styled } from '../../../../../theme/styled';

const StyledRowSpan = styled.span`
    &:not(:first-child) {
        padding-top: 5px;
    }
`;

export const StyledLabelSpan = styled(StyledRowSpan)`
    font-weight: bold;
`;

export const StyledValueSpan = styled(StyledRowSpan)`
    padding-left: 10px;
    min-height: 23px;
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const StyledLabels = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: bold;
    text-align: right;
`;

export const StyledValues = styled.div`
    display: flex;
    flex-direction: column;
`;
