import { RouteItemType } from '../../../utils/route/switch-routes';
import { pathPatientAdministrationSwitch } from './path';
import { PatientAdministrationPage } from './PatientAdministrationSwitch';
import { titlePatientAdministrationSwitch } from './title';

export const routePatientAdministrationSwitch: RouteItemType = {
    name: titlePatientAdministrationSwitch,
    link: pathPatientAdministrationSwitch,
    icon: 'user-add',
    component: PatientAdministrationPage
};
