import { colors } from '../../../theme/colors/commonColors';
import { ChartType } from '../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../types/indicator/indicator-rules.type';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

export const rules: IndicatorChartHeatmapRules = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        {
            value: 1,
            color: colors.chart.heatMap.purple10
        },
        {
            value: 2,
            color: colors.chart.heatMap.purple30
        },
        {
            value: 3,
            color: colors.chart.heatMap.purple50
        },
        {
            value: 4,
            color: colors.chart.heatMap.purple80
        }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.dizziness.choices.none.id,
            text: formatMessage(Strings.indicators.dizziness.choices.none),
            color: colors.chart.heatMap.purple10
        },
        {
            textId: Strings.indicators.dizziness.choices.light.id,
            text: formatMessage(Strings.indicators.dizziness.choices.light),
            color: colors.chart.heatMap.purple30
        },
        {
            textId: Strings.indicators.dizziness.choices.moderate.id,
            text: formatMessage(Strings.indicators.dizziness.choices.moderate),
            color: colors.chart.heatMap.purple50
        },
        {
            textId: Strings.indicators.dizziness.choices.severe.id,
            text: formatMessage(Strings.indicators.dizziness.choices.severe),
            color: colors.chart.heatMap.purple80
        }
    ],
    boundariesConfiguration: {
        type: 'radio'
    },
    choices: [
        {
            localeId: Strings.indicators.dizziness.choices.none.id,
            text: formatMessage(Strings.indicators.dizziness.choices.none),
            value: 1
        },
        {
            localeId: Strings.indicators.dizziness.choices.light.id,
            text: formatMessage(Strings.indicators.dizziness.choices.light),
            value: 2
        },
        {
            localeId: Strings.indicators.dizziness.choices.moderate.id,
            text: formatMessage(Strings.indicators.dizziness.choices.moderate),
            value: 3
        },
        {
            localeId: Strings.indicators.dizziness.choices.severe.id,
            text: formatMessage(Strings.indicators.dizziness.choices.severe),
            value: 4
        }
    ]
};
