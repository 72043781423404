import icon from './images/physact.svg';
import { Indicator } from '../../../types/indicator/indicator.type';
import { messagesPhysact } from './messages/messages';
import { rules } from './physact-rules';
import { PhysactProperties } from './physact-properties';

export const parameterPhysact = {
    code: 'PHYSACT',
    properties: PhysactProperties
};

export const physact: Indicator = {
    code: parameterPhysact.code,
    icon,
    messages: messagesPhysact,
    rules
};
