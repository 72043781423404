import { RouteItemType } from '../../../../../utils/route/switch-routes';
import { pathOauth2Tokens } from './path';
import { TokensWrapper } from './TokensWrapper';

export const routeOauthTokens: RouteItemType = {
    name: '',
    exact: true,
    link: pathOauth2Tokens,
    icon: '',
    component: TokensWrapper
};
