import { Indicator } from '../../../types/indicator/indicator.type';

import icon from './images/missing.svg';
import { messagesMissing } from './messages/messages';
import { rules } from './missing-rules';

export const missing: Indicator = {
    code: 'MISSING',
    icon,
    messages: messagesMissing,
    rules
};
