import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/pump-pulse-index.svg';
import { messagesPumpPulseIndexLvad } from './messages/messages';
import { rules } from './pump-pulse-index-lvad-rules';

export const parameterPumpPulseIndexLVAD = {
    code: 'PUMP-PULSE-INDEX-LVAD'
};

export const pumpPulseIndex: Indicator = {
    code: parameterPumpPulseIndexLVAD.code,
    icon,
    messages: messagesPumpPulseIndexLvad,
    rules
};
