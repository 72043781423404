import {
    FETCH_NOTIFICATIONS_FOR_AUTH_USER,
    UPDATE_NOTIFICATION_STATUS
} from './notifications.constants';
import { getNotificationsForAuthUser, updateStatus } from './notifications.api';
import { ReduxState } from '../../redux-state.type';
import { Dispatch } from 'redux';
import { getSelectedMedicGroupId } from '../groups/groups.selectors';

export const fetchNotificationsBySelectedMedicId =
    () => (dispatch: any, getState: () => ReduxState) => {
        const selectedGroupId = getSelectedMedicGroupId(getState());
        if (!selectedGroupId) {
            throw new Error('No medic group is selected');
        }
        const params = {
            tags: 'sent',
            groupIds: selectedGroupId
        };
        const { activeConversation } = getState().chat;

        return dispatch({
            type: FETCH_NOTIFICATIONS_FOR_AUTH_USER,
            payload: getNotificationsForAuthUser(params, activeConversation)
        });
    };

export const updateNotificationsStatus =
    (externalId: string, action: string, ids: string[]) => (dispatch: any) =>
        dispatch({
            type: UPDATE_NOTIFICATION_STATUS,
            payload: updateStatus(externalId, { action, ids })
        });

export const confirmValueComments =
    (externalId: string) =>
    (dispatch: Dispatch<any>, getState: () => ReduxState) => {
        const patientNotifications =
            getState().notifications.bySubjectId[externalId];

        if (patientNotifications) {
            const { byId } = patientNotifications;
            if (byId) {
                const valueCommentIds = Object.keys(byId)
                    .filter(
                        (key) => byId[key].notificationType === 'value_comment'
                    )
                    .map((key) => byId[key].id);
                if (valueCommentIds.length)
                    dispatch(
                        updateNotificationsStatus(
                            externalId,
                            'confirmed',
                            valueCommentIds
                        )
                    );
            }
        }
    };
