import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        id: 'medic.trend.title',
        defaultMessage: 'Reported values'
    },
    line: {
        id: 'medic.trend.line',
        defaultMessage: 'Trend line'
    }
});

const totalForDay = defineMessages({
    title: {
        id: 'medic.trend.total-for-day.title',
        defaultMessage: 'Day total'
    }
});

export const trend = {
    general,
    totalForDay
};
