import {
    FETCH_MISSED_REPORTS,
    FETCH_REPORT_REMINDERS,
    UPDATE_REPORT_REMINDERS
} from './reminders.constants';
import {
    getMissedReports,
    getReportReminders,
    updateReminders
} from './reminders.api';

export const fetchMissedReports = (
    patientGroupId: string,
    conditionId: string
) => ({
    type: FETCH_MISSED_REPORTS,
    payload: getMissedReports(patientGroupId, conditionId)
});

export const fetchReportReminders =
    (subjectIds: string[], conditionId: string) => (dispatch: any) =>
        dispatch({
            type: FETCH_REPORT_REMINDERS,
            payload: getReportReminders(subjectIds.join(','), conditionId)
        });

export const updateReportReminders =
    (
        reminder: any,
        indicatorId: string,
        externalId: string,
        reminderId: string
    ) =>
    (dispatch: any) =>
        dispatch({
            type: UPDATE_REPORT_REMINDERS,
            payload: updateReminders(
                reminder,
                indicatorId,
                externalId,
                reminderId
            )
        });
