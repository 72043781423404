import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MedicExternalId } from '../../../redux/domains/user/user.type';
import { ContentCardLayout } from '../../../components/content/ContentLayout';
import { CrossSpinner } from '../../../components/ui/loading/loading';
import { usePageTitle } from '../../../hooks/use-page-title';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { ProfileMetaView } from './components/ProfileView';
import { getGroups } from '../../../redux/domains/groups/groups.api';
import { MedicGroupsById } from '../../../redux/domains/groups/groups.type';
import { ProfileEdit } from './components/ProfileEdit';
import { titleProfile } from './title';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { keyBy } from 'lodash';
import { MedicGroupsEdit } from '../../../components/medic/details/MedicGroupsEdit';
import { getAdminGroupsForCurrentUser } from '../../../redux/domains/groups/groups.selectors';
import { MedicGroups } from '../../../components/medic/details/MedicGroups';

export const ProfilePage = (): JSX.Element => {
    usePageTitle(titleProfile);

    const routeParams = useParams<{ medicId: MedicExternalId }>();
    const userId = useReduxSelector((state) => state.user.externalId);
    const medicId = routeParams.medicId || userId;
    const viewAsAdmin = !!routeParams.medicId;

    const medicMeta = useReduxSelector(
        (state) => state.groups.medicUsers[medicId].meta
    );

    const [editable, setEditable] = useState(false);
    const [isLoadingGroups, setIsLoadingGroups] = useState(false);
    const [groupsById, setGroupsById] = useState<MedicGroupsById>();

    const medicGroups = useReduxSelector(
        (state) => state.groups.medicUsers[medicId].medicGroups || []
    );
    const adminGroups = useReduxSelector(
        (state) => state.groups.medicUsers[medicId].adminGroups || []
    );

    const groupIds = useMemo(
        () => [...new Set(medicGroups.concat(adminGroups))],
        [medicGroups, adminGroups]
    );

    const hasGroups = useMemo(
        () =>
            groupIds.every((value) =>
                Object.keys(groupsById || {}).includes(value)
            ),
        [groupIds, groupsById]
    );

    useEffect(() => {
        if (!hasGroups && !isLoadingGroups) {
            setIsLoadingGroups(true);
            setEditable(false);
            void getGroups<MedicGroupsById>(groupIds).then((data) => {
                setGroupsById(keyBy(data, 'id'));
                setIsLoadingGroups(false);
            });
        }
    }, [hasGroups, groupIds, isLoadingGroups]);

    if (!hasGroups || isLoadingGroups) {
        return (
            <ContentCardLayout>
                <CrossSpinner
                    text={formatMessage(
                        Strings.events.general.fetchingInformation
                    )}
                />
            </ContentCardLayout>
        );
    }

    return (
        <ContentCardLayout>
            <>
                {!editable && (
                    <>
                        <ProfileMetaView
                            meta={medicMeta}
                            setEditable={setEditable}
                        />
                        {viewAsAdmin ? (
                            <MedicGroupsEdit
                                groupsById={groupsById || {}}
                                medicGroups={medicGroups}
                                adminGroups={adminGroups}
                                medicId={medicId}
                            />
                        ) : (
                            <MedicGroups
                                adminGroups={adminGroups}
                                medicGroups={medicGroups}
                            />
                        )}
                    </>
                )}

                {editable && <ProfileEdit setEditable={setEditable} />}
            </>
        </ContentCardLayout>
    );
};
