import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createAuthRequest } from '../../../redux/domains/authentication/authentication.actions';
import { AppNeutralButton } from '../../../components/ui/buttons/AppNeutralButton';
import { LoginWrapper } from './components/LoginWrapper';
import { Icon } from 'antd';
import { eidLogin } from '../../../redux/domains/authentication/authentication.api';
import { EidAuthMethods } from '../../../redux/domains/authentication/authentication.enums';
import { styled } from '../../../theme/styled';
import { routeLoginEmail } from '../login-email/route';
import { titleLoginMethods } from './title';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { LoginMethods } from '../../../constants/login-options';

const { MEDIC_AUTH_METHODS, SAML_URL } = __APP_CONFIG__;

const LoginMethodButton = (props: { name: string; onClick: () => void }) => {
    return (
        <StyledLoginMethodButton block onClick={props.onClick}>
            {props.name}
            <Icon type="caret-right" />
        </StyledLoginMethodButton>
    );
};

export const LoginPage = (): JSX.Element => {
    const dispatch = useDispatch<any>();
    const routeHistory = useHistory();
    const authMethods = {
        email: (
            <LoginMethodButton
                key={LoginMethods.email}
                name={routeLoginEmail.name}
                onClick={() => routeHistory.push(routeLoginEmail.link)}
            />
        ),
        siths: (
            <LoginMethodButton
                key={LoginMethods.siths}
                name={formatMessage(Strings.authMethods.general.siths)}
                onClick={() =>
                    SAML_URL
                        ? dispatch(createAuthRequest())
                        : eidLogin(EidAuthMethods.Siths)
                }
            />
        ),
        bankid: (
            <LoginMethodButton
                key={LoginMethods.bankid}
                name={formatMessage(Strings.authMethods.general.bankid)}
                onClick={() => eidLogin(EidAuthMethods.BankID, true)}
            />
        )
    };

    return (
        <LoginWrapper title={titleLoginMethods}>
            {MEDIC_AUTH_METHODS.map(
                (authMethod) =>
                    authMethods[authMethod as keyof typeof authMethods]
            )}
        </LoginWrapper>
    );
};

const StyledLoginMethodButton = styled(AppNeutralButton)`
    && {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 50px;
        margin-bottom: 10px;
        font-size: 16px;
        color: black;

        :last-child {
            margin-bottom: 0;
        }
    }
`;
