import { ChartType } from '../../../types/indicator/chart.type';
import {
    Choice,
    HeatmapLegend
} from '../../../types/indicator/heatmap-piece.type';
import { ChartOptions } from '../../../types/indicator/indicator-rules.type';
import { IndicatorCode } from '../../../types/indicator/indicator.type';

export function isHeatmapRules(
    rules: HeatmapRules | ChartRules
): rules is HeatmapRules {
    return (rules as HeatmapRules).heatmapPieces !== undefined;
}

export function isChartRules(
    rules: HeatmapRules | ChartRules
): rules is ChartRules {
    return (rules as ChartRules).lineColor !== undefined;
}

export type DecimalRule = Record<IndicatorCode, number> | undefined;
export type CommonRules = {
    hideChart?: boolean;
    step?: number;
    chartTypes?: ChartType[];
    decimals?: DecimalRule;
    divideBy?: number;
    boundariesConfiguration?: {
        disabledProperties?: string[];
        type: 'radio';
    };
    choices?: ChoicesMapping;
};

export type ChoicesMapping = Record<string, Choice[]>;

export type Settings = {
    rules: ChartRules | HeatmapRules;
};

export type ChartRules = CommonRules & {
    lineColor: {
        name: string;
        default: string;
        sum?: string;
    };
    shouldNotRenderPlotbands: boolean;
    chartOptions: ChartOptions;
};

export type HeatmapRules = CommonRules & {
    heatmapPieces: HeatmapPiece[];
    heatmapLegendItems: HeatmapLegend[];
};

type HeatmapPiece = {
    color: string;
    value?: number;
};
