import styled from 'styled-components';
import { Switch } from 'antd';

const StyledSwitch = styled(Switch)`
    && {
        margin-right: 1rem;
    }
`;

export { StyledSwitch };
