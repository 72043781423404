import { HTTPClient } from '../../../services/http-client/http-client';
import { RequestUpdateUserStatus, UpdateMedic } from './user.api.type';
import { ExternalId } from './user.type';
import {
    PutMedicGroupsInterface,
    PostBasicMedicInterface,
    PostSithsMedicInterface
} from './user.api.interface';
import { errorHandler } from '../../../services/http-client/handle-error';
import { MedicExternalId } from './user.type';
import { GroupId } from '../groups/groups.type';

export const getUser = async (externalId: ExternalId) => {
    return HTTPClient.get(`users/${externalId}`);
};

export const putMedic = async (externalId: ExternalId, data: UpdateMedic) =>
    HTTPClient.put(`users/${externalId}`, data);

export const putMedicGroups = async (params: PutMedicGroupsInterface) => {
    const { medicId, groupId, data } = params;

    return HTTPClient.put(`users/${medicId}/groups/${groupId}`, data)
        .then(() => {
            return params;
        })
        .catch((error) => {
            console.error('error in putMedicGroups', error);
        });
};

export const postBasicMedic = async (params: PostBasicMedicInterface) => {
    const { data } = params;

    return HTTPClient.post(`users`, data);
};

export const postSithsMedic = async (params: PostSithsMedicInterface) => {
    const { data } = params;

    return HTTPClient.post(`users`, data);
};

export const toggleUser = (
    externalId: ExternalId,
    data: RequestUpdateUserStatus
) => HTTPClient.put(`users/${externalId}/status`, data);

export const deleteUserFromGroup = (params: {
    groupId: GroupId;
    medicId: MedicExternalId;
}) => {
    const { groupId, medicId } = params;

    return HTTPClient.delete(`users/${medicId}/groups/${groupId}`)
        .then(() => {
            return params;
        })
        .catch((error) => errorHandler(error, 'Error DELETE users from group'));
};
