import { defineMessages } from 'react-intl';

const general = defineMessages({
    manual: {
        id: 'education.manuals',
        defaultMessage: 'Manuals'
    }
});

const manual = defineMessages({
    medic: {
        id: 'manual.medic.title',
        defaultMessage: 'Caregiver Interface'
    },
    patient: {
        id: 'manual.patient.title',
        defaultMessage: 'Patient Interface'
    }
});

export const messagesEducation = {
    general,
    manual
};
