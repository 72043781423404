import React from 'react';
import { ActivePatient } from '../../../../../redux/domains/active-patient/active-patient.type';
import { EmailLink } from '../../../../../components/patient/header/EmailLink';
import { filterHiddenIndicators } from './filter-hidden-indicators';
import { getLessColumns } from '../../../overview/components/priority-table/less-columns';
import { IndicatorWithSettings } from '../../../../../redux/domains/indicators/indicator.type';
import { PatientDataGroups } from './PatientDataGroups';
import { PatientGroup } from '../../../../../redux/domains/groups/groups.type';
import { PatientTabs } from '../tabs/tabs';
import { PhoneLink } from '../../../../../components/patient/header/PhoneLink';
import { sortVisibleIndicators } from './sort-visible-indicators';
import {
    StyledContactIcon,
    StyledDivider,
    StyledHeader,
    StyledHeaderWrapper,
    StyledPatientInformation,
    StyledPersonalId,
    StyledTableWrapper,
    StyledUserMeta
} from '../../../../../components/patient/header/styled';
import { Table } from 'antd';
import { useReduxSelector } from '../../../../../hooks/use-redux-selector';
import { getPatientName } from './get-patient-name';

type Props = {
    patient: ActivePatient;
    patientGroups: Record<string, PatientGroup>;
    selectedPatientGroupId: string;
    tab: PatientTabs;
    indicatorsWithSettings: IndicatorWithSettings[];
};

export const PatientDataHeader = (props: Props) => {
    const {
        patient,
        patientGroups,
        selectedPatientGroupId,
        tab,
        indicatorsWithSettings
    } = props;
    const { meta } = patient;
    const patientName = getPatientName(meta.name, meta.family_name);

    const filteredPatient = useReduxSelector(
        (state) => state.priorityByGroupReducer
    );

    const selectedPatientCodeId = useReduxSelector(
        (state) => state.selectedPatient.conditionIds[0]
    );

    const code = useReduxSelector(
        (state) => state.conditions.byId[selectedPatientCodeId].code
    );

    const priorityTableSettings = useReduxSelector(
        (state) => state.settings[code].priorityTable
    );

    const { hidden, order } = priorityTableSettings;

    const unsortedVisibleIndicators = filterHiddenIndicators(
        indicatorsWithSettings,
        hidden
    );

    const visibleIndicators = sortVisibleIndicators(
        unsortedVisibleIndicators,
        order
    );
    const columns = getLessColumns(visibleIndicators);

    return (
        <StyledTableWrapper>
            <StyledHeaderWrapper>
                <StyledPatientInformation>
                    <StyledHeader>{patientName}</StyledHeader>
                    <StyledUserMeta>
                        <StyledPersonalId>{meta.personal_id}</StyledPersonalId>
                    </StyledUserMeta>
                    <StyledUserMeta>
                        <StyledContactIcon type="phone" />
                        <PhoneLink phone={meta.phone_number} />
                    </StyledUserMeta>
                    <StyledUserMeta>
                        <StyledContactIcon type="mail" />
                        <EmailLink email={meta.email} />
                    </StyledUserMeta>
                </StyledPatientInformation>
                <div>
                    {filteredPatient &&
                        Object.keys(filteredPatient).length > 0 && (
                            <Table
                                columns={columns}
                                dataSource={filteredPatient}
                                rowKey={patient.externalId}
                                pagination={false}
                                style={{
                                    width: 'auto',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            />
                        )}
                </div>
                <div />
            </StyledHeaderWrapper>
            <StyledDivider />
            <PatientDataGroups
                patient={patient}
                patientGroups={patientGroups}
                selectedPatientGroupId={selectedPatientGroupId}
                tab={tab}
            />
        </StyledTableWrapper>
    );
};
