import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PatientExternalId } from '../../../../redux/domains/user/user.type';
import { updatePatientGroup } from '../../../../redux/domains/active-patient/active-patient.actions';
import { fetchGroups } from '../../../../redux/domains/groups/groups.actions';
import { PatientGroupSelectField } from './PatientGroupSelectField';
import {
    StyledButtonWrapper,
    StyledLeftButtonWrapper,
    StyledNeutralButton,
    StyledPrimaryButton
} from '../../edit/styled-form';
import { PatientGroupId } from '../../../../redux/domains/groups/groups.type';
import { UpdatePatientGroupRequest } from '../../../../redux/domains/active-patient/active-patient.api.types';
import { routePatient } from '../../data/route';
import { PatientTabs } from '../../data/components/tabs/tabs';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';

export const EditPatientGroup = (props: {
    patientId: PatientExternalId;
    initialGroupId: PatientGroupId;
}): JSX.Element => {
    const dispatch: any = useDispatch();
    const routeHistory = useHistory();

    const initialGroupId = props.initialGroupId;
    const patientId = props.patientId;

    const [currentGroupId, setCurrentGroupId] =
        useState<PatientGroupId>(initialGroupId);

    const [loading, setLoading] = useState(false);
    const isSubmitDisabled = initialGroupId === currentGroupId;

    return (
        <form style={{ width: '350px' }}>
            <PatientGroupSelectField
                onChange={onGroupChange}
                currentPatientGroupId={currentGroupId}
            />

            <StyledButtonWrapper>
                <StyledLeftButtonWrapper>
                    <StyledNeutralButton onClick={handleBack}>
                        {formatMessage(Strings.navigation.general.back)}
                    </StyledNeutralButton>
                </StyledLeftButtonWrapper>
                <StyledNeutralButton
                    disabled={isSubmitDisabled}
                    onClick={() => handleReset(initialGroupId)}
                >
                    {formatMessage(Strings.common.general.reset)}
                </StyledNeutralButton>
                <StyledPrimaryButton
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={isSubmitDisabled}
                >
                    {formatMessage(Strings.ui.buttons.general.save)}
                </StyledPrimaryButton>
            </StyledButtonWrapper>
        </form>
    );

    function onGroupChange(groupId: PatientGroupId) {
        setCurrentGroupId(groupId);
    }

    function handleBack(): void {
        routeHistory.goBack();
    }

    function handleReset(groupId: PatientGroupId): void {
        setCurrentGroupId(groupId);
    }

    function handleSucess(): void {
        setLoading(false);
        setCurrentGroupId(currentGroupId);
        routeHistory.push(
            routePatient.linkToTab({
                patientGroupId: currentGroupId,
                patientId: patientId,
                tab: PatientTabs.limits
            })
        );
    }

    function handleError(): void {
        console.warn('Unable to update Patient Group');
        setLoading(false);
    }

    async function handleSubmit(): Promise<void> {
        setLoading(true);

        const data: UpdatePatientGroupRequest = {
            userIds: [patientId],
            destinationGroupId: currentGroupId
        };

        await dispatch(updatePatientGroup(initialGroupId, data))
            .then(() => dispatch(fetchGroups()))
            .then(() => handleSucess())
            .catch(() => handleError());
    }
};
