import { ChartType } from '../../../../types/indicator/chart.type';
import { colors } from '../../../../theme/colors/commonColors';
import { IndicatorChartGraphRules } from '../../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartGraphRules = {
    chartTypes: [ChartType.Spline],
    lineColor: {
        name: colors.chart.graph.red.name,
        default: colors.chart.graph.red.primary
    },
    chartOptions: {
        fixedYAxisMin: 0,
        tickInterval: 2
    }
};
