import React from 'react';
import ReactECharts from 'echarts-for-react';
import { getOption } from './getOption';
import { ModifiedDataType } from '../../../redux/domains/statistics/statistics.type';

export function PieChart(props: { data: ModifiedDataType[] }): JSX.Element {
    return (
        <ReactECharts
            style={{
                width: '450px'
            }}
            option={getOption(props)}
            notMerge={true}
            lazyUpdate={true}
        />
    );
}
