import { defineMessages } from 'react-intl';

const educationForm = defineMessages({
    title: {
        id: 'form-education.title',
        defaultMessage: 'Kunskapstest för vårdpersonalen'
    },
    subtitle: {
        id: 'form-education.subtitle',
        defaultMessage: 'LifePod – kunskapstest för vårdpersonalen'
    },
    about: {
        id: 'form-education.about',
        defaultMessage:
            'Detta är ett kort test för vårdpersonal som ska använda LifePod för att följa patienter på distans. Testet görs innan man får tillgång till LifePod med riktiga patienter.'
    },
    info: {
        id: 'form-education.info',
        defaultMessage:
            'Vi måste säkerställa att personalen som använder LifePod har kännedom om de viktigaste funktionerna i systemet innan man arbetar i det. Därför ber vi dig att besvara några frågor om LifePod så att både du och vi vet att du har koll på systemet och dess funktioner. Det tar bara några minuter och varje fråga innehåller ett korrekt svarsalternativ. Det krävs alla rätt för att bli godkänd.'
    },
    gdpr: {
        id: 'form-education.gdpr',
        defaultMessage:
            'Dina svar behandlas i enlighet med GDPR och används enbart av oss på Cross Technology Solutions för att säkerställa att systemet används korrekt. Tack och lycka till!'
    },
    submit: {
        id: 'form-education.submit-button',
        defaultMessage: 'Skicka in'
    },
    failedInformation: {
        id: 'form-education.failed.information',
        defaultMessage:
            'Du svarade fel på följande frågor. Tryck på knappen för att komma tillbaka till Utbildningsmodulen.'
    },
    failedButton: {
        id: 'form-education.failed.back-button',
        defaultMessage: 'Tillbaka till Utbildningsmodulen'
    },
    errorHeader: {
        id: 'form-education.error.header',
        defaultMessage: 'Något gick tyvärr fel'
    },
    errorInformation: {
        id: 'form-education.error.information',
        defaultMessage:
            'Du svarade rätt på samtliga frågor, men resultatet kunde inte sparas. Var god försök igen. Om felet kvarstår var god kontakta supporten.'
    }
});

const questions = defineMessages({
    question1: {
        id: 'form-education.questions.question-1',
        defaultMessage: '1) Hur är patienterna sorterade i patientöversikten?'
    },
    question2: {
        id: 'form-education.questions.question-2',
        defaultMessage:
            '2) Vad betyder det när patienten har en röd ruta i patientöversikten?'
    },
    question3: {
        id: 'form-education.questions.question-3',
        defaultMessage: '3) Vad innebär nollställning?'
    },
    question4: {
        id: 'form-education.questions.question-4',
        defaultMessage: '4) Vad händer när du markerar en patient som granskad?'
    },
    question5: {
        id: 'form-education.questions.question-5',
        defaultMessage: '5) Hur bestäms gränserna för de olika parametrarna?'
    },
    question6: {
        id: 'form-education.questions.question-6',
        defaultMessage: '6) Hur nollställer du en patient?'
    },
    question7: {
        id: 'form-education.questions.question-7',
        defaultMessage:
            '7) Hur ändrar du en patients gränser för en viss parameter?'
    },
    question8: {
        id: 'form-education.questions.question-8',
        defaultMessage:
            '8) Hur kan jag se de individuella svaren på alla frågor i en enkät patienten svarat på?'
    },
    question9: {
        id: 'form-education.questions.question-9',
        defaultMessage:
            '9) Var kan du se hur patientens värden har utvecklat sig över en längre tid?'
    },
    question10: {
        id: 'form-education.questions.question-10',
        defaultMessage:
            '10) Hur skriver du ett direktmeddelande till en patient?'
    },
    question11: {
        id: 'form-education.questions.question-11',
        defaultMessage: '11) Vad betyder ett utropstecken i patientöversikten?'
    },
    question12: {
        id: 'form-education.questions.question-12',
        defaultMessage:
            '12) Vad betyder ett rött kuvert bredvid patientens namn i patientöversikten?'
    },
    question13: {
        id: 'form-education.questions.question-13',
        defaultMessage:
            '13) Var kan du hitta viktig information från Cross Technology Solutions om LifePod?'
    },
    question14: {
        id: 'form-education.questions.question-14',
        defaultMessage:
            '14) Varför är det viktigt att logga ut när du arbetat färdigt?'
    },
    question15: {
        id: 'form-education.questions.question-15',
        defaultMessage:
            '15) Har du tagit del av instruktionsfilmerna om LifePod?'
    },
    question16: {
        id: 'form-education.questions.question-16',
        defaultMessage: '16) Har du tagit del av manualen för LifePod?'
    },
    question17: {
        id: 'form-education.questions.question-17',
        defaultMessage:
            '17) Har du tillräckligt med information för att arbeta i LifePod?'
    }
});

const answers = defineMessages({
    answer1: {
        id: 'form-education.answers.answer-1',
        defaultMessage:
            '{value, select, 0 {Bokstavsordning på efternamn.} 1 {Personnummer (äldst först).} 2 {Efter medicinsk prioritering som kan ändra sig hela tiden. De sjukaste patienterna längst upp och patienter som för tillfället mår bättre längre ner i patientlistan.}}'
    },
    answer2: {
        id: 'form-education.answers.answer-2',
        defaultMessage:
            '{value, select, 0 {Patienten har larmat ambulans.} 1 {Patienten har rapporterat ett värde som ligger utanför sin kritiska gräns för den parametern.} 2 {Patienten har inte rapporterat den parametern på flera dagar.}}'
    },
    answer3: {
        id: 'form-education.answers.answer-3',
        defaultMessage:
            '{value, select, 0 {Den medicinska prioriteten för en patient nollställs och patienten hamnar längst ner i prioritetslistan. Den nya prioriteten beräknas på värden rapporterade fr.o.m. denna tidpunkt.} 1 {Patienten raderas från listan.} 2 {Att man bockar av att man tittat till patientens värden.}}'
    },
    answer4: {
        id: 'form-education.answers.answer-4',
        defaultMessage:
            '{value, select, 0 {Patienten får en grön markering i patientöversikten och patienten får en avisering i LifePod om att jag har granskat den.} 1 {Patienten får en grön markering i patientöversikten och mina kollegor får ett SMS om att jag har gjort en granskning.} 2 {Patienten får en grön markering i patientöversikten och hamnar längst ner i listan.}}'
    },
    answer5: {
        id: 'form-education.answers.answer-5',
        defaultMessage:
            '{value, select, 0 {Gränserna sätts likadant till alla patienter och går inte att ändra.} 1 {Gränserna för de flesta parametrar kan sättas individuellt av vårdpersonalen utifrån varje patients förutsättningar.} 2 {Patienten kan själv bestämma gränserna.}}'
    },
    answer6: {
        id: 'form-education.answers.answer-6',
        defaultMessage:
            '{value, select, 0 {Genom att dra i reglaget vid patientens namn under Patientadministration.} 1 {Genom att öppna Åtgärdspanelen för den patienten, klicka på Nollställ, välja anledning till nollställning och klicka på Spara.} 2 {Genom att avaktivera patientens alla parametrar.}}'
    },
    answer7: {
        id: 'form-education.answers.answer-7',
        defaultMessage:
            '{value, select, 0 {Det gör jag genom att klicka på ett patientnamn och sedan välja fliken Gränser där jag kan välja vilken parameter jag vill redigera.} 1 {Det går inte att ändra en patients gränser.} 2 {Det gör jag i Åtgärdspanelen.}}'
    },
    answer8: {
        id: 'form-education.answers.answer-8',
        defaultMessage:
            '{value, select, 0 {Det går inte att se de individuella svaren på en enkät.} 1 {På patientens historiksida väljer jag enkäten och klickar på Detaljer.} 2 {Jag ser de individuella svaren när jag tittar på enkätens graf eller datumdiagram.}}'
    },
    answer9: {
        id: 'form-education.answers.answer-9',
        defaultMessage:
            '{value, select, 0 {Det gör patienten själv, vårdpersonalen tittar inte på det.} 1 {Genom att klicka på patientens namn kan jag se grafer/datumdiagram för alla parametrar.} 2 {Under Patientadministration.}}'
    },
    answer10: {
        id: 'form-education.answers.answer-10',
        defaultMessage:
            '{value, select, 0 {Det går inte, enbart telefon eller mejl kan användas.} 1 {Jag klickar på kuvertet som finns bredvid patientens namn i patientöversikten och öppnar meddelandefunktionen.} 2 {Enbart telefonsamtal eller pappersbrev finns tillgängliga för kommunikation med patienter.}}'
    },
    answer11: {
        id: 'form-education.answers.answer-11',
        defaultMessage:
            '{value, select, 0 {Att patienten har gjort en viktig kommentar till sitt rapporterade värde.} 1 {Att patienten inte har rapporterat enligt överenskommelse.} 2 {Att patienten har rapporterat ett extraordinärt värde.}}'
    },
    answer12: {
        id: 'form-education.answers.answer-12',
        defaultMessage:
            '{value, select, 0 {Patienten har en bokad tid idag.} 1 {Det betyder att patientens blodtrycksvärden avviker från det normala spannet.} 2 {Det finns ett oläst meddelande från den patienten som har skickats via meddelandefunktionen.}}'
    },
    answer13: {
        id: 'form-education.answers.answer-13',
        defaultMessage:
            '{value, select, 0 {När det finns ett nytt meddelande dyker det upp i LifePod och efter det kan jag aldrig läsa det igen.} 1 {Det skickas aldrig ut någon viktig information.} 2 {När det finns ett nytt meddelande dyker det upp i LifePod och sedan kan jag hitta det igen under Viktig information i menyn uppe till höger.}}'
    },
    answer14: {
        id: 'form-education.answers.answer-14',
        defaultMessage:
            '{value, select, 0 {För att ingen obehörig ska komma åt patientuppgifter och utföra åtgärder i LifePod.} 1 {Annars tror patienterna att jag är online.} 2 {Det är inte viktigt och det gör jag aldrig.}}'
    },
    answer15: {
        id: 'form-education.answers.answer-15',
        defaultMessage: '{value, select, 0 {Ja} 1 {Nej}}'
    },
    answer16: {
        id: 'form-education.answers.answer-16',
        defaultMessage: '{value, select, 0 {Ja} 1 {Nej}}'
    },
    answer17: {
        id: 'form-education.answers.answer-17',
        defaultMessage: '{value, select, 0 {Ja} 1 {Nej}}'
    }
});

export const messages = {
    educationForm,
    questions,
    answers
};
