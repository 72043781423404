import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/pump-speed.svg';
import { messagesPumpSpeedLvad } from './messages/messages';
import { rules } from './pump-speed-lvad-rules';

export const parameterPumpSpeedLVAD = {
    code: 'PUMP-SPEED-LVAD'
};

export const pumpSpeed: Indicator = {
    code: parameterPumpSpeedLVAD.code,
    icon,
    messages: messagesPumpSpeedLvad,
    rules
};
