import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Ability to perform vocational activities',
        id: 'indicator.vocational-activity-hs.title'
    }
});

export const messagesVocationalActivity = {
    general
};
