import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useReduxSelector } from '../hooks/use-redux-selector';
import { Loading } from '../components/ui/loading/loading';
import { useDispatch } from 'react-redux';
import {
    checkAuthentication,
    authenticateWithEId
} from '../redux/domains/authentication/authentication.actions';
import { AppSetup } from './AppSetup';
import { routeLogin } from './auth/login/route';
import { ReduxState } from '../redux/redux-state.type';
import { EidAuthMethods } from '../redux/domains/authentication/authentication.enums';

// returns true on initial state (before check authentication request is pending)
// but false when state has been cleared following LOGOUT_USER_FULFILLED
const loadingSelector = (state: ReduxState) => {
    const loading = state.requests.loading;

    return (
        loading.CHECK_AUTHENTICATION !== false && loading.LOGOUT_USER !== false
    );
};

export const AppAuth = (): JSX.Element => {
    const dispatch = useDispatch<any>();

    const isLoading = useReduxSelector(loadingSelector);

    const isAuthenticated = useReduxSelector(
        (state) => state.authentication.isAuthenticated
    );

    const params = new URLSearchParams(useLocation().search);
    const grandidsession = params.get('grandidsession');
    const authMethod = params.get('authmethod');

    useEffect(() => {
        if (grandidsession && authMethod) {
            if (!isAuthMethod(authMethod)) {
                throw Error('Invalid auth method (' + authMethod + ')');
            }

            dispatch(authenticateWithEId(grandidsession, authMethod)).then(() =>
                dispatchCheckAuthentication()
            );

            return;
        }

        dispatchCheckAuthentication();
    }, [dispatch, grandidsession, authMethod]);

    if (!isAuthenticated) {
        if (isLoading) {
            return <Loading />;
        }

        return (
            <Redirect
                to={{
                    pathname: routeLogin.link
                }}
            />
        );
    }

    return <AppSetup />;

    function dispatchCheckAuthentication() {
        dispatch(checkAuthentication()).catch((error: any) =>
            typeof error === 'string'
                ? console.log(error)
                : console.error(error)
        );
    }
};

function isAuthMethod(authMethod: string): authMethod is EidAuthMethods {
    return Object.values(EidAuthMethods).includes(authMethod as EidAuthMethods);
}
