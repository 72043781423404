import styled from 'styled-components';

export const StyledForm = styled.form`
    width: 100%;
    margin: 20px 0 30px 0;
    padding-bottom: 20px;
    overflow: auto;
`;

export const StyledButtonContainer = styled.div`
    display: table-row;
`;

export const StyledButtonTableCell = styled.div`
    display: table-cell;
    padding-top: 25px;
    text-align: right;

    button:last-of-type {
        margin-left: 10px;
    }
`;
