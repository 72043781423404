import { Indicator } from '../../../types/indicator/indicator.type';

import icon from './images/smoke.svg';
import { messagesSmoke } from './messages/messages';
import { rules } from './smoke-rules';

export const parameterSmoke = {
    code: 'SMOKE'
};

export const smoke: Indicator = {
    code: parameterSmoke.code,
    icon,
    messages: messagesSmoke,
    rules
};
