import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Exercises',
        id: 'indicator.exercises-hs.title'
    },
    unit: {
        id: 'indicator.exercises-hs.unit-label',
        defaultMessage: 'times per day'
    },
    withValue: {
        id: 'indicator.exercises-hs.unit',
        defaultMessage: '{value, plural, one {time} other {times}} per day'
    }
});

export const messagesExercises = {
    general
};
