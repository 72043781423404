import React from 'react';
import { Formik, Form } from 'formik';
import { useReduxDispatch } from '../../../../../../../hooks/use-redux-dispatch';
import { updateReportReminders } from '../../../../../../../redux/domains/reminders/reminders.actions';

type Props = {
    children: React.ReactElement | React.ReactElement[];
    externalId: string;
    reportReminder: any;
    name: string;
    indicatorId: string;
    onDirtyChange?: (name: string, dirty: boolean) => void;
    onSubmit?: (values: any) => void | null;
};

const UpdateRemindersForm = ({
    children,
    externalId,
    indicatorId,
    reportReminder,
    name,
    onDirtyChange,
    onSubmit
}: Props) => {
    const dispatch = useReduxDispatch();

    const dispatchDirtyness = (dirty: boolean) => {
        onDirtyChange && onDirtyChange(name, dirty);
    };

    const defaultSubmit = (values: any, { setSubmitting, resetForm }: any) => {
        const data = {
            subjectId: externalId,
            indicatorReportConfigurations: [values]
        };
        dispatch(
            updateReportReminders(data, indicatorId, externalId, values.id)
        )
            .then(() => {
                setSubmitting(false);
                resetForm();
            })
            .catch(() => {
                setSubmitting(false);
                resetForm();
            });
    };

    return (
        <Formik
            initialValues={reportReminder}
            onSubmit={onSubmit || defaultSubmit}
            enableReinitialize={true}
        >
            {(props) => {
                onDirtyChange && dispatchDirtyness(props.dirty);

                return (
                    <Form>
                        {React.Children.map(children, (child) =>
                            React.cloneElement(child, { ...props })
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default React.memo(UpdateRemindersForm);
