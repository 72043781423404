import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Swelling',
        id: 'indicator.swelling-hs.title'
    }
});

export const messagesSwellingHs = {
    general
};
