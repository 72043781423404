import { RouteItemType } from '../../utils/route/switch-routes';
import { pathStatistics } from './path';
import { StatisticsPage } from './StatisticsPage';
import { titleStatistics } from './title';

export const routeStatistics: RouteItemType = {
    name: titleStatistics,
    link: pathStatistics,
    icon: 'bar-chart',
    component: StatisticsPage
};
