import { formatPatients } from './priority.util';
import { HTTPClient } from '../../../services/http-client/http-client';
import {
    PriorityPatientsObject,
    PriorityPatientWithIndicators
} from './priority.type';

export const getPriorityList = (groupId: string) =>
    HTTPClient.get(`priority/${groupId}`).then((patients: any) => ({
        groupId,
        patients: formatPatients(patients)
    }));

export const getPriorityListById = (params: {
    patientGroupId: string | null;
    externalId: string | null;
}) =>
    HTTPClient.get(`priority/${params.patientGroupId ?? ''}`).then(
        (patients: PriorityPatientsObject) => {
            return Object.values(patients).filter(
                (patient: PriorityPatientWithIndicators) =>
                    patient.meta.externalId === params.externalId
            );
        }
    );
