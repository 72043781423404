import styled from 'styled-components';
import { Icon } from 'antd';

export const StyledFormDescription = styled.p``;

export const StyledError = styled.p`
    margin-top: 20px;
    color: red;
`;

export const StyledIcon = styled(Icon)`
    margin-top: 10px;
`;

export const StyledModalContent = styled.div`
    margin-left: 50px;
    white-space: pre-line;
`;
