import { Col } from 'antd';
import React from 'react';
import { formatMessage } from '../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../locale/messagesDescriptors';
import { profiles } from '../../../../../../theme/profile/profiles';
import {
    StyledActionPanelBox,
    StyledActionTabHeader
} from '../../styledActionTabs';
import { HistoryPanelContent } from './history-panel/HistoryPanelContent';

export const RightColumn = (props: {
    fetchingEvents: any;
    logEvents: any;
}): JSX.Element => {
    return (
        <Col span={15}>
            <StyledActionPanelBox>
                <StyledActionTabHeader
                    borderBottom={`1px solid ${profiles.main.profile.layout.background}`}
                >
                    {formatMessage(Strings.common.general.history)}
                </StyledActionTabHeader>
                <HistoryPanelContent
                    fetchingEvents={props.fetchingEvents}
                    logEvents={props.logEvents}
                />
            </StyledActionPanelBox>
        </Col>
    );
};
