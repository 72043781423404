import React, { useState } from 'react';
import { ActivatePatientModal } from './ActivatePatientModal';
import { BaseUserMeta } from '../../../../../redux/domains/user/user.type';
import { ConfirmLimitsModal } from './ConfirmLimitsModal';
import { DeactivatePatientModal } from './DeactivatePatientModal';
import { ReasonsForChangeStatus } from '../../../../../conditions/condition.type';
import { RouteComponentProps } from 'react-router-dom';
import { setSelectedPatient } from '../../../../../redux/domains/patient-administration/patient-administration.actions';
import { shouldBeChecked } from './toggle-patient';
import { Switch } from 'antd';
import { TogglePatientData } from '../PatientAdministrationListWrapperPage';
import { useDispatch } from 'react-redux';
interface TogglePatientProps {
    status: string;
    patientMeta: BaseUserMeta;
    externalId: string;
    togglePatient: (
        externalId: string,
        data: TogglePatientData
    ) => Promise<void>;
    reasons: () => ReasonsForChangeStatus;
    savePendingRegisterPatientId: (externalId: string) => void;
    savePendingPatientActivation: (
        externalId: string,
        reason: string,
        note?: string
    ) => void;
    routeProps: RouteComponentProps;
}

export const TogglePatient = ({
    status,
    patientMeta,
    externalId,
    togglePatient,
    reasons,
    savePendingRegisterPatientId,
    savePendingPatientActivation,
    routeProps
}: TogglePatientProps) => {
    const [checked, setChecked] = useState(shouldBeChecked(status));
    const [loading, setIsLoading] = useState(false);
    const [showActivationReason, setShowActivationReason] = useState(false);
    const [showConfirmLimitsModal, setShowConfirmLimitsModal] = useState(false);
    const [showDeactivationReason, setShowDeactivationReason] = useState(false);

    const dispatch = useDispatch();

    const [activate, deactivate] = Object.values(reasons) as [
        string[],
        string[]
    ];

    const onChange = (checkedStatus: boolean) => {
        setChecked(checkedStatus);
        dispatch(setSelectedPatient(externalId));

        switch (status) {
            case 'unconfirmed':
                setShowActivationReason(false);
                setShowConfirmLimitsModal(true);
                setShowDeactivationReason(false);
                break;
            case 'active':
                setShowActivationReason(false);
                setShowConfirmLimitsModal(false);
                setShowDeactivationReason(true);
                break;
            default:
                setShowActivationReason(true);
                setShowConfirmLimitsModal(false);
                setShowDeactivationReason(false);
                break;
        }
    };

    return (
        <>
            <Switch checked={checked} loading={loading} onChange={onChange} />
            {(() => {
                switch (status) {
                    case 'unconfirmed':
                        return (
                            <ConfirmLimitsModal
                                externalId={externalId}
                                routeProps={routeProps}
                                savePendingRegisterPatientId={
                                    savePendingRegisterPatientId
                                }
                                toggleButton={setChecked}
                                toggleVisibility={setShowConfirmLimitsModal}
                                visibility={showConfirmLimitsModal}
                            />
                        );
                    case 'active':
                        return (
                            <DeactivatePatientModal
                                externalId={externalId}
                                patientMeta={patientMeta}
                                reasons={deactivate}
                                setIsLoading={setIsLoading}
                                toggleButton={setChecked}
                                togglePatient={togglePatient}
                                toggleVisibility={setShowDeactivationReason}
                                visibility={showDeactivationReason}
                            />
                        );
                    default:
                        return (
                            <ActivatePatientModal
                                externalId={externalId}
                                patientMeta={patientMeta}
                                reasons={activate}
                                routeProps={routeProps}
                                savePendingPatientActivation={
                                    savePendingPatientActivation
                                }
                                setIsLoading={setIsLoading}
                                toggleButton={setChecked}
                                toggleVisibility={setShowActivationReason}
                                visibility={showActivationReason}
                            />
                        );
                }
            })()}
        </>
    );
};
