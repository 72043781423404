import { Token } from '../../../utils/token/token-service';
import { formatPersonalId } from '../../utils';
import {
    CHECK_AUTHENTICATION_FULFILLED,
    CHECK_AUTHENTICATION_REJECTED,
    LOGIN_USER_REJECTED,
    LOGOUT_USER_FULFILLED
} from '../authentication/authentication.constants';
import { GroupsNormalizedResponse } from '../groups/groups.api.type';
import {
    FETCH_GROUPS_FULFILLED,
    INIT_GROUPS_FULFILLED
} from '../groups/groups.constants';
import {
    FETCH_MEDIC_FULFILLED,
    UPDATE_MEDIC_FULFILLED
} from '../medic/medic.constants';
import { MedicUser } from '../medic/medic.type';

const initialState: MedicUser = {
    externalId: '',
    meta: {
        name: '',
        family_name: '',
        username: '',
        email: '',
        phone_number: '',
        personal_id: '',
        medicID: '',
        medicTitle: ''
    },
    role: [],
    medicEducated: false,
    isFetched: false
};

export default function userReducer(
    state = initialState,
    action: any
): MedicUser {
    switch (action.type) {
        case CHECK_AUTHENTICATION_FULFILLED: {
            const {
                username,
                email,
                phone_number,
                personal_id,
                sub: externalId,
                name,
                family_name
            }: Token = action.payload;

            return {
                externalId,
                meta: {
                    name,
                    family_name,
                    username,
                    email,
                    phone_number,
                    personal_id: formatPersonalId(personal_id)
                },
                role: [],
                medicEducated: state.medicEducated,
                isFetched: state.isFetched
            };
        }
        case FETCH_MEDIC_FULFILLED: {
            return {
                ...state,
                medicEducated: !!action.payload.medicEducated,
                isFetched: true
            };
        }
        case INIT_GROUPS_FULFILLED: {
            const { medicUsers }: GroupsNormalizedResponse = action.payload;
            const medicUser = state.externalId
                ? medicUsers[state.externalId]
                : null;

            return medicUser
                ? {
                      ...state,
                      meta: { ...state.meta, ...medicUser.meta }
                  }
                : state;
        }
        case FETCH_GROUPS_FULFILLED: {
            const { medicUsers }: GroupsNormalizedResponse = action.payload;
            const medicUser = state.externalId
                ? medicUsers[state.externalId]
                : null;

            return medicUser
                ? {
                      ...state,
                      meta: { ...state.meta, ...medicUser.meta }
                  }
                : state;
        }
        case UPDATE_MEDIC_FULFILLED: {
            if (action.payload.externalId === state.externalId) {
                return {
                    ...state,
                    meta: { ...state.meta, ...action.payload.meta },
                    medicEducated: action.payload.medicEducated
                };
            }
            return state;
        }
        case LOGIN_USER_REJECTED:
        case CHECK_AUTHENTICATION_REJECTED:
        case LOGOUT_USER_FULFILLED:
            return initialState;
        default:
            return state;
    }
}
