import { colors } from '../../../theme/colors/commonColors';

import { ChartType } from '../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../types/indicator/indicator-rules.type';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

export const rules: IndicatorChartHeatmapRules = {
    step: 1,
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        {
            color: colors.chart.heatMap.purple10,
            min: 6,
            max: 6
        },
        {
            color: colors.chart.heatMap.purple10,
            min: 7,
            max: 8
        },
        {
            color: colors.chart.heatMap.purple20,
            min: 9,
            max: 10
        },
        {
            color: colors.chart.heatMap.purple30,
            min: 11,
            max: 12
        },
        {
            color: colors.chart.heatMap.purple40,
            min: 13,
            max: 14
        },
        {
            color: colors.chart.heatMap.purple50,
            min: 15,
            max: 16
        },
        {
            color: colors.chart.heatMap.purple60,
            min: 17,
            max: 18
        },
        {
            value: 19,
            color: colors.chart.heatMap.purple70
        },
        {
            value: 20,
            color: colors.chart.heatMap.purple80
        }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.physact.heatmapLegends.none.id,
            text: formatMessage(
                Strings.indicators.physact.heatmapLegends.none,
                { range: '(6)' }
            ),
            color: colors.chart.heatMap.purple10,
            textValues: {
                range: '(6)'
            }
        },
        {
            textId: Strings.indicators.physact.heatmapLegends.extremelyEasy.id,
            text: formatMessage(
                Strings.indicators.physact.heatmapLegends.extremelyEasy,
                { range: '(7 - 8)' }
            ),
            color: colors.chart.heatMap.purple10,
            textValues: {
                range: '(7 - 8)'
            }
        },
        {
            textId: Strings.indicators.physact.heatmapLegends.veryEasy.id,
            text: formatMessage(
                Strings.indicators.physact.heatmapLegends.veryEasy,
                { range: '(9 - 10)' }
            ),
            color: colors.chart.heatMap.purple20,
            textValues: {
                range: '(9 - 10)'
            }
        },
        {
            textId: Strings.indicators.physact.heatmapLegends.easy.id,
            text: formatMessage(
                Strings.indicators.physact.heatmapLegends.easy,
                { range: '(11 - 12)' }
            ),
            color: colors.chart.heatMap.purple30,
            textValues: {
                range: '(11 - 12)'
            }
        },
        {
            textId: Strings.indicators.physact.heatmapLegends.somewhat.id,
            text: formatMessage(
                Strings.indicators.physact.heatmapLegends.somewhat,
                { range: '(13 - 14)' }
            ),
            color: colors.chart.heatMap.purple40,
            textValues: {
                range: '(13 - 14)'
            }
        },
        {
            textId: Strings.indicators.physact.heatmapLegends.hard.id,
            text: formatMessage(
                Strings.indicators.physact.heatmapLegends.hard,
                { range: '(15 - 16)' }
            ),
            color: colors.chart.heatMap.purple50,
            textValues: {
                range: '(15 - 16)'
            }
        },
        {
            textId: Strings.indicators.physact.heatmapLegends.veryHard.id,
            text: formatMessage(
                Strings.indicators.physact.heatmapLegends.veryHard,
                { range: '(17 - 18)' }
            ),
            color: colors.chart.heatMap.purple60,
            textValues: {
                range: '(17 - 18)'
            }
        },
        {
            textId: Strings.indicators.physact.heatmapLegends.extremelyHard.id,
            text: formatMessage(
                Strings.indicators.physact.heatmapLegends.extremelyHard,
                { range: '(19)' }
            ),
            color: colors.chart.heatMap.purple70,
            textValues: {
                range: '(19)'
            }
        },
        {
            textId: Strings.indicators.physact.heatmapLegends.maximum.id,
            text: formatMessage(
                Strings.indicators.physact.heatmapLegends.maximum,
                { range: '(20)' }
            ),
            color: colors.chart.heatMap.purple80,
            textValues: {
                range: '(20)'
            }
        }
    ]
};
