export const FETCH_MEDIC = 'FETCH_MEDIC';
export const FETCH_MEDIC_PENDING = 'FETCH_MEDIC_PENDING';
export const FETCH_MEDIC_FULFILLED = 'FETCH_MEDIC_FULFILLED';
export const FETCH_MEDIC_REJECTED = 'FETCH_MEDIC_REJECTED';

export const CREATE_MEDIC = 'CREATE_MEDIC';

export const UPDATE_MEDIC = 'UPDATE_MEDIC';
export const UPDATE_MEDIC_PENDING = 'UPDATE_MEDIC_PENDING';
export const UPDATE_MEDIC_FULFILLED = 'UPDATE_MEDIC_FULFILLED';
export const UPDATE_MEDIC_REJECTED = 'UPDATE_MEDIC_REJECTED';

export const CREATE_MEDIC_GROUPS = 'CREATE_MEDIC_GROUPS';

export const UPDATE_MEDIC_GROUPS = 'UPDATE_MEDIC_GROUPS';
export const UPDATE_MEDIC_GROUPS_FULFILLED = 'UPDATE_MEDIC_GROUPS_FULFILLED';

export const TOGGLE_MEDIC = 'TOGGLE_MEDIC';
export const TOGGLE_MEDIC_PENDING = 'TOGGLE_MEDIC_PENDING';
export const TOGGLE_MEDIC_FULFILLED = 'TOGGLE_MEDIC_FULFILLED';
export const TOGGLE_MEDIC_REJECTED = 'TOGGLE_MEDIC_REJECTED';
