import { defineMessages } from 'react-intl';

const general = defineMessages({
    viewedBy: {
        id: 'shared.chat.viewedBy',
        defaultMessage: 'Viewed by {name} {formattedDate}'
    },
    empty: {
        id: 'shared.chat.empty',
        defaultMessage: 'Oops! Nothing here...'
    },
    beginConversation: {
        id: 'shared.chat.conversation.empty',
        defaultMessage: 'Write a message to start a conversation.'
    },
    newConversation: {
        id: 'shared.chat.newConversation',
        defaultMessage: 'Write a message to start a conversation.'
    },
    send: {
        id: 'shared.chat.conversation.send',
        defaultMessage: 'Send'
    },
    groupDevider: {
        id: 'shared.chat.group-list.divider',
        defaultMessage: 'Or'
    },
    chooseClinic: {
        id: 'shared.chat.group-list.header',
        defaultMessage: 'Choose clinic'
    }
});

export const chat = {
    general
};
