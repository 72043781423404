import React, { Dispatch, SetStateAction } from 'react';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Modal } from 'antd';
import { pathCreatePatientStep2 } from '../../../create/steps/step-2/path';
import { RouteComponentProps } from 'react-router-dom';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { StyledIcon } from './StyledPatientModals';

interface ConfirmLimitsModalProps {
    externalId: string;
    routeProps: RouteComponentProps;
    savePendingRegisterPatientId: (externalId: string) => void;
    toggleButton: Dispatch<SetStateAction<boolean>>;
    toggleVisibility: Dispatch<SetStateAction<boolean>>;
    visibility: boolean;
}

export const ConfirmLimitsModal = ({
    externalId,
    routeProps,
    savePendingRegisterPatientId,
    toggleButton,
    toggleVisibility,
    visibility
}: ConfirmLimitsModalProps) => {
    const handleOk = () => {
        toggleVisibility(false);
        savePendingRegisterPatientId(externalId);
        routeProps.history.push(pathCreatePatientStep2);
    };

    const handleCancel = () => {
        toggleVisibility(false);
        toggleButton(false);
    };

    return (
        <Modal
            title={
                <>
                    <StyledIcon type="question-circle" />{' '}
                    {formatMessage(
                        Strings.screen.patientAdministration.createPatient
                            .dialogUnconfirmedTitle
                    )}
                </>
            }
            visible={visibility}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            {formatMessage(
                Strings.screen.patientAdministration.createPatient
                    .dialogUnconfirmedBody
            )}
        </Modal>
    );
};
