import { FormikType } from './formik-type';
import { FormikErrors } from 'formik';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

const isFieldDefined = (name: string | null): boolean => {
    if (name == null) {
        return false;
    }

    return name.trim().length > 0;
};

const isPhoneNumberFormatCorrect = (phoneNumber: string | null): boolean => {
    const phoneNumberRegex = /^\+46[0-9]{9}$/i;

    return !!phoneNumber && phoneNumberRegex.test(phoneNumber);
};

const validatePhoneNumber = (
    phoneNumber: string,
    errors: FormikErrors<FormikType>
) => {
    if (phoneNumber.length > 0 && !isPhoneNumberFormatCorrect(phoneNumber)) {
        errors.phoneNumber = formatMessage(
            Strings.editPatient.fields.phoneNumber.error
        );
    }

    return errors;
};

export const validate = async (values: FormikType) => {
    const errors: FormikErrors<FormikType> = {};

    if (!isFieldDefined(values.firstName)) {
        errors.firstName = formatMessage(
            Strings.editPatient.fields.firstName.error
        );
    }
    if (!isFieldDefined(values.familyName)) {
        errors.familyName = formatMessage(
            Strings.editPatient.fields.familyName.error
        );
    }

    if (values.phoneNumber && values.phoneNumber.startsWith('0')) {
        values.phoneNumber = '+46' + values.phoneNumber.substring(1);
    }
    validatePhoneNumber(values.phoneNumber, errors);

    return errors;
};
