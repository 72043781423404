import { defineMessages } from 'react-intl';

const general = defineMessages({
    firstName: {
        id: 'shared.ui.label.firstName',
        defaultMessage: 'First name'
    },
    lastName: {
        id: 'shared.ui.label.lastName',
        defaultMessage: 'Last name'
    },
    socialSecurityNumber: {
        id: 'shared.ui.label.socialSecurityNumber',
        defaultMessage: 'Personal identification number'
    },
    password: {
        id: 'shared.ui.label.password',
        defaultMessage: 'Password'
    },
    phone: {
        id: 'shared.ui.label.phone',
        defaultMessage: 'Mobile number'
    },
    groups: {
        id: 'shared.ui.label.groups',
        defaultMessage: 'Group'
    },
    clinic: {
        id: 'shared.ui.label.clinic',
        defaultMessage: 'Clinic'
    },
    sendMessageTitle: {
        id: 'shared.ui.label.sendMessageTitle',
        defaultMessage: 'Send message'
    },
    sum: {
        id: 'shared.ui.label.sum',
        defaultMessage: 'Sum'
    },
    authenticationMethod: {
        id: 'shared.ui.label.authenticationMethod',
        defaultMessage: 'Authentication method'
    }
});

export const label = {
    general
};
