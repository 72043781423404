import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Ability to perform daily activities',
        id: 'indicator.daily-activity-hs.title'
    }
});

export const messagesDailyActivity = {
    general
};
