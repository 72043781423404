import React, { useEffect } from 'react';
import store from '../redux/store/configureStore';
import { hot } from 'react-hot-loader/root';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProfileProvider } from '../theme/theme-profile.provider';
import { setHighchartsLocale } from '../locale/setup/highcharts/setup-highcharts-locale';
import { routeException } from '../components/exception/route';
import { LocaleProvider } from '../locale/LocaleProvider';
import { HTTPClient } from '../services/http-client/http-client';
import { createHashHistory } from 'history';
import { authStorageKeys } from '../utils/storage/auth/auth-storage-keys';
import { removeTokensFromLocalStorage } from '../utils/storage/auth/remove-tokens-from-local-storage';
import { routeLogin } from './auth/login/route';
import { routeOAuth2 } from './auth/oauth2/route';
import { routeSaml } from './auth/saml/route';
import { routeLoginEmail } from './auth/login-email/route';
import { routeLogout } from './auth/logout/route';
import { SwitchRoutes, RouteItemType } from '../utils/route/switch-routes';
import { routeAuth } from './route';
import { routeSso } from './auth/sso/route';
import '../styles/shared.css';
import { formatMessage } from '../locale/format/format-message';
import { Strings } from '../locale/messagesDescriptors';
import { HighchartsProvider } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';

const appAuthRoutes: RouteItemType[] = [
    routeOAuth2,
    routeSaml,
    routeException,
    routeLogin,
    routeLoginEmail,
    routeLogout,
    routeSso,
    routeAuth
];

HTTPClient.setupInterceptors(store);

export const App = hot(() => {
    useEffect(didMount, []);

    return (
        <HighchartsProvider Highcharts={Highcharts}>
            <LocaleProvider>
                <ReduxProvider store={store}>
                    <ThemeProfileProvider>
                        <Router history={createHashHistory()}>
                            <SwitchRoutes routes={appAuthRoutes} />
                        </Router>
                    </ThemeProfileProvider>
                </ReduxProvider>
            </LocaleProvider>
        </HighchartsProvider>
    );
});

function didMount() {
    setHighchartsLocale(
        formatMessage(Strings.other.general.noData),
        formatMessage(Strings.graph.general.resetZoom)
    );
    window.addEventListener('beforeunload', onBeforeUnload);

    return willUnmount;
}

function willUnmount() {
    window.removeEventListener('beforeunload', onBeforeUnload);
}

function onBeforeUnload() {
    const idToken = window.localStorage.getItem(authStorageKeys.idToken);
    const refreshToken = window.localStorage.getItem(
        authStorageKeys.refreshToken
    );

    if (idToken && refreshToken) {
        window.sessionStorage.setItem(authStorageKeys.idToken, idToken);
        window.sessionStorage.setItem(
            authStorageKeys.refreshToken,
            refreshToken
        );
        removeTokensFromLocalStorage();
    }
}
