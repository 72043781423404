import { RouteItemType } from '../../../../../utils/route/switch-routes';
import { pathCreatePatientStep3 } from './path';
import { Step3Wrapper } from './Step3Wrapper';

export const routeCreatePatientStep3: RouteItemType = {
    name: '',
    link: pathCreatePatientStep3,
    icon: 'user-add',
    component: Step3Wrapper,
    exact: true
};
