import { Col } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useReduxSelector } from '../../../../../../hooks/use-redux-selector';
import { setActionPanelLatest } from '../../../../../../redux/domains/action-panel/action-panel.actions';
import { StyledActionPanelBox } from '../../styledActionTabs';
import { FeedbackPanelItem } from './general-panel/feedback/FeedbackPanelItem';
import { ResetPanelItem } from './general-panel/reset/ResetPanelItem';
import { WatchedOverPanelItem } from './general-panel/watchover/WatchedOverPanelItem';

export const LeftColumn = (props: {
    latestWatchover: any;
    latestReset: any;
    isMedicAdmin: any;
    settings: any;
    onRegisterWatchover: any;
    onResetPriority: any;
}) => {
    const dispatch = useDispatch();

    const latestReset = useReduxSelector(
        (state) => state.actionPanel.latest.reset
    );

    const latestWatchover = useReduxSelector(
        (state) => state.actionPanel.latest.watchover
    );

    useEffect(() => {
        if (latestReset == null) {
            dispatch(
                setActionPanelLatest({
                    latestDateForReset: props.latestReset.dtCreated || null,
                    latestDateForWatchover: latestWatchover
                })
            );
        }
    }, [dispatch, latestReset, latestWatchover, props.latestReset.dtCreated]);

    useEffect(() => {
        if (latestWatchover == null) {
            dispatch(
                setActionPanelLatest({
                    latestDateForReset: latestReset,
                    latestDateForWatchover:
                        props.latestWatchover.dtCreated || null
                })
            );
        }
    }, [
        dispatch,
        latestReset,
        latestWatchover,
        props.latestWatchover.dtCreated
    ]);

    useEffect(() => {
        return () => {
            dispatch(
                setActionPanelLatest({
                    latestDateForReset: null,
                    latestDateForWatchover: null
                })
            );
        };
    }, []);

    return (
        <Col span={9}>
            <StyledActionPanelBox style={{ overflow: 'auto' }}>
                <WatchedOverPanelItem
                    latestDateForWatchover={latestWatchover || 0}
                    latestWatchover={props.latestWatchover}
                    registerWatchover={props.onRegisterWatchover}
                    disabled={props.isMedicAdmin}
                />
                <ResetPanelItem
                    latestDateForReset={latestReset || 0}
                    resetPriority={props.onResetPriority}
                    latestReset={props.latestReset}
                    reset_reasons={props.settings.reasons.reset_priority}
                    disabled={props.isMedicAdmin}
                />
                <FeedbackPanelItem />
            </StyledActionPanelBox>
        </Col>
    );
};
