import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';
import colors from '../../../../theme/colors/commonColors';
import { ChartType } from '../../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartHeatmapRules = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        { value: 0, color: colors.chart.heatMap.purple10 },
        { value: 1, color: colors.chart.heatMap.purple20 },
        { value: 2, color: colors.chart.heatMap.purple30 },
        { value: 3, color: colors.chart.heatMap.purple40 },
        { value: 4, color: colors.chart.heatMap.purple50 },
        { value: 5, color: colors.chart.heatMap.purple60 }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.respiratoryProblems.status.noProblems.id,
            text: formatMessage(
                Strings.indicators.respiratoryProblems.status.noProblems
            ),
            color: colors.chart.heatMap.purple10
        },
        {
            textId: Strings.indicators.respiratoryProblems.status.legend.id,
            text: formatMessage(
                Strings.indicators.respiratoryProblems.status.legend,
                { range: '(5 - 9)' }
            ),
            color: colors.chart.heatMap.purple20,
            textValues: {
                range: '(5-9)'
            }
        },
        {
            textId: Strings.indicators.respiratoryProblems.status.legend.id,
            text: formatMessage(
                Strings.indicators.respiratoryProblems.status.legend,
                { range: '(10)' }
            ),
            color: colors.chart.heatMap.purple30,
            textValues: {
                range: '(10)'
            }
        },
        {
            textId: Strings.indicators.respiratoryProblems.status.legend.id,
            text: formatMessage(
                Strings.indicators.respiratoryProblems.status.legend,
                { range: '(11 - 20)' }
            ),
            color: colors.chart.heatMap.purple40,
            textValues: {
                range: '(11-20)'
            }
        },
        {
            textId: Strings.indicators.respiratoryProblems.status.legend.id,
            text: formatMessage(
                Strings.indicators.respiratoryProblems.status.legend,
                { range: '(21 - 24)' }
            ),
            color: colors.chart.heatMap.purple50,
            textValues: {
                range: '(21-24)'
            }
        },
        {
            textId: Strings.indicators.respiratoryProblems.status.legend.id,
            text: formatMessage(
                Strings.indicators.respiratoryProblems.status.legend,
                { range: '(25 -40)' }
            ),
            color: colors.chart.heatMap.purple60,
            textValues: {
                range: '(25-40)'
            }
        }
    ]
};
