import React from 'react';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';
import { AppNeutralButton } from '../../../../components/ui/buttons/AppNeutralButton';
import { AppPrimaryButton } from '../../../../components/ui/buttons/AppPrimaryButton';

export const CreateMedicModalFooter = (props: {
    onCancel: () => void;
    onSubmit: () => Promise<void>;
    afterSubmit: () => void;
    loading: boolean;
    disabled: boolean;
}) => {
    const { onCancel, loading, disabled, onSubmit, afterSubmit } = props;

    return (
        <>
            <AppNeutralButton onClick={onCancel}>
                {formatMessage(Strings.common.general.cancel)}
            </AppNeutralButton>
            <AppPrimaryButton
                loading={loading}
                disabled={disabled}
                onClick={() => onSubmit().then(afterSubmit)}
            >
                {formatMessage(Strings.common.general.save)}
            </AppPrimaryButton>
        </>
    );
};
