import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Painkiller',
        id: 'indicator.medication-hs.title'
    }
});

export const messagesMedicationHs = {
    general
};
