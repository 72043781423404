import React, { PropsWithChildren } from 'react';
import { Drawer as AntdDrawer, Collapse, Empty, Alert } from 'antd';
import { styled } from '../../../../../../../../theme/styled';
import { limits } from './message-descriptors';
import { formatMessage } from '../../../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../../../locale/messagesDescriptors';

const { Panel } = Collapse;

const customPanelStyle = {
    backgroundColor: '#f9f9f9',
    borderRadius: 4,
    marginBottom: 24,
    border: 0,
    overflow: 'hidden'
};

type Props = PropsWithChildren<{
    title: React.ReactNode;
    visible: boolean;
    onClose: () => void;
    indicatorTitle: string;
    isReadonly: boolean;
}>;

export const EditIndicatorDrawer = (props: Props) => {
    return (
        <AntdDrawer
            title={props.title}
            visible={props.visible}
            onClose={props.onClose}
            width={640}
            maskClosable={false}
            destroyOnClose={true}
        >
            {props.isReadonly ? (
                <StyledAlert
                    message={formatMessage(limits.notAllowed.info.text)}
                    type="info"
                />
            ) : null}

            {props.children ? (
                <Collapse
                    bordered={false}
                    defaultActiveKey={['limits', 'reminders']}
                >
                    {React.Children.toArray(props.children).map(
                        (child: any) => (
                            <Panel
                                header={child.props.header}
                                key={child.props.panelKey}
                                style={customPanelStyle}
                            >
                                {child}
                            </Panel>
                        )
                    )}
                </Collapse>
            ) : (
                <Empty
                    style={{ marginTop: '2rem' }}
                    description={formatMessage(
                        Strings.screen.patient.general.editNotAvailable,
                        {
                            indicator: props.indicatorTitle
                        }
                    )}
                />
            )}
        </AntdDrawer>
    );
};

export default EditIndicatorDrawer;

const StyledAlert = styled(Alert)`
    && {
        margin-bottom: 20px;
    }
`;
