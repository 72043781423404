import cloneDeep from 'lodash/cloneDeep';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import fpReduce from 'lodash/fp/reduce';
import reduce from 'lodash/reduce';
import { formatPersonalId } from '../../utils';
import { ConditionConfiguration } from '../conditions/conditions.type';
import { IndicatorConfigurationResponse } from '../indicators/indicator.type';
import { BaseUserMeta } from '../user/user.type';
import { GetPatientResponse } from './active-patient.api.types';

export type NormalizedPatientType = {
    externalId: string;
    meta: BaseUserMeta;
    conditionIds: string[];
    groupIds: string[];
    conditionConfiguration: ConditionConfiguration;
};

export const normalizePatient = (
    data: GetPatientResponse
): NormalizedPatientType => {
    const copyOfUser = cloneDeep(data);

    const { externalId, meta, group: groupIds, conditions } = copyOfUser;

    const conditionIds = conditions.map((condition: any) => condition.id);

    const conditionConfiguration = reduce(
        conditions,
        (acc, curr) => {
            const indicators = flow(
                map((indicatorConfig: IndicatorConfigurationResponse) => {
                    const { indicatorId, configuration } = indicatorConfig;
                    return {
                        [indicatorId]: {
                            indicatorId,
                            configuration
                        }
                    };
                }),
                fpReduce((acc, res) => {
                    return {
                        ...acc,
                        ...res
                    };
                }, {})
            )(curr.indicatorConfigurations);

            return {
                ...acc,
                [curr.id]: {
                    ...indicators
                }
            };
        },
        {}
    );

    const patient = Object.assign(
        {},
        {
            externalId,
            meta: {
                ...meta,
                personal_id:
                    meta != null ? formatPersonalId(meta.personal_id) : ''
            },
            conditionIds,
            groupIds,
            conditionConfiguration
        }
    );

    return patient;
};
