import React from 'react';
import { List, Skeleton } from 'antd';
import { HistoryItem } from './HistoryItem';
import { ProxyMetaType } from './history-items/utils/getPracticionerName';
import styled from 'styled-components';
import { useReduxSelector } from '../../../../../../../hooks/use-redux-selector';
import { PatientAdministrationUser } from '../../../../../../../redux/domains/patient-administration/patient-administration.type';

type Props = { fetchingEvents: any; logEvents: any };

export type EventType = {
    type: 'watchover' | 'reset_priority' | 'user_status_change' | 'feedback';
    eventId: string;
    action: {
        note: string;
        reason: string;
    };
    dtCreated: number;
    proxyMeta: ProxyMetaType;
    status: string;
    reason: string;
    note: string;
};

export type HistoryItemProps = {
    event: EventType;
    patient?: PatientAdministrationUser;
};

export const HistoryPanelContent = (props: Props): JSX.Element => {
    const { fetchingEvents, logEvents } = props;
    const patientId = useReduxSelector(
        (state) => state.actionPanel.patientExternalId
    );
    const patient = useReduxSelector(
        (state) => state.patientAdministration.byId[patientId]
    );

    return (
        <StyledHistoryLog>
            {fetchingEvents ? (
                <Skeleton active paragraph={{ rows: 6 }} />
            ) : (
                <StyledHistoryList
                    itemLayout="vertical"
                    dataSource={logEvents}
                    size="large"
                    className="event-list"
                    renderItem={(event: EventType | any) => (
                        <HistoryItem
                            key={event.eventId}
                            event={event}
                            patient={patient}
                        />
                    )}
                />
            )}
        </StyledHistoryLog>
    );
};

const StyledHistoryList = styled(List)`
    .ant-list-item-meta {
        margin: 0;
    }
    .ant-list-item-meta-title {
        margin: 0;
    }

    .ant-list-item-content {
        margin: 0;
    }
`;

const StyledHistoryLog = styled.div`
    max-height: 420px;
    overflow: auto;
    margin-top: -8px;
`;
