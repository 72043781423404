import { colors } from '../../../theme/colors/commonColors';
import { ChartType } from '../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../types/indicator/indicator-rules.type';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

export const rules: IndicatorChartHeatmapRules<boolean, boolean> = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        {
            value: false,
            color: colors.chart.heatMap.purple10
        },
        {
            value: true,
            color: colors.chart.heatMap.purple80
        }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.candy.choices.no.id,
            text: formatMessage(Strings.indicators.candy.choices.no),
            color: colors.chart.heatMap.purple10
        },
        {
            textId: Strings.indicators.candy.choices.yes.id,
            text: formatMessage(Strings.indicators.candy.choices.yes),
            color: colors.chart.heatMap.purple80
        }
    ]
};
