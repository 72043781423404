import { useEffect } from 'react';
import { useReduxSelector } from '../use-redux-selector';
import {
    setSelectedMedicGroup,
    setSelectedPatientGroup
} from '../../redux/domains/groups/groups.actions';
import { useReduxDispatch } from '../use-redux-dispatch';

export function useSetSelectedGroups(): void {
    const dispatch = useReduxDispatch();
    const groupsLoaded = useReduxSelector((state) => state.groups.loaded);

    useEffect(() => {
        if (groupsLoaded) {
            dispatch(setSelectedMedicGroup());
            dispatch(setSelectedPatientGroup());
        }
    }, [dispatch, groupsLoaded]);
}
