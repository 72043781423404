import { normalize, schema } from 'normalizr';
import { formatUser } from '../../utils';
import { GroupsNormalizedResponse } from './groups.api.type';

export const normalizeGroups = (data: any): GroupsNormalizedResponse => {
    const schemaOptions = {
        idAttribute: 'externalId',
        processStrategy: formatUser
    };

    const patientsSchema = new schema.Entity('patients', {}, schemaOptions);

    const medicsSchema = new schema.Entity('medicUsers', {}, schemaOptions);

    const patientGroupsSchema = new schema.Entity('patientGroups', {
        users: [patientsSchema]
    });

    const medicGroupsSchema = new schema.Entity('medicGroups', {
        users: [medicsSchema],
        manages: [patientGroupsSchema]
    });

    const normalizedData = normalize(data, [medicGroupsSchema]);
    const {
        medicGroups = {},
        patientGroups = {},
        patients = {},
        medicUsers = {}
    } = normalizedData.entities;

    return {
        medicGroups,
        patientGroups,
        patients,
        medicUsers
    };
};
