import Highcharts from 'highcharts';

import moment from 'moment';

export const setHighchartsLocale = (
    noDataText: string,
    resetZoomText: string
) => {
    const noData = noDataText ? noDataText : 'noData';
    const resetZoom = resetZoomText ? resetZoomText : 'resetZoom';

    Highcharts.setOptions({
        lang: {
            shortMonths: moment.localeData().monthsShort(),
            weekdays: moment.localeData().weekdays(),
            noData,
            resetZoom,
            decimalPoint: moment.locale() === 'sv' ? ',' : '.'
        }
    });
};
