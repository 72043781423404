import styled from 'styled-components';
import { getRGBA } from '../../../../../../theme/colors/functions';

export const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-right: 10px;
`;

export const StyledColumnRight = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const StyledRowTitle = styled.div`
    font-weight: bold;
`;

export const StyledRowWrapper = styled.div`
    color: black;
    display: flex;
    flex-direction: row;
    width: 400px;
    justify-content: space-between;
    background-color: ${(props) => getRGBA(props.theme.primary.color, 0.1)};
    margin-bottom: ${(props) => props.theme.spacing.small}px;
    padding: 10px;
`;
