import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import {
    formatMessage,
    formatMessageById
} from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';
import { IndicatorCode } from '../../../../types/indicator/indicator.type';
import _ from 'lodash';
import { parameterPumpSpeedLVAD } from '../../../../conditions/left-ventricular-assist-device/indicators/pump-speed/pump-speed';
import { parameterExercises } from '../../../../conditions/hand-surgery/indicators/exercises/exercises';
import { parameterOrthosisDayHs } from '../../../../conditions/hand-surgery/indicators/orthosis-day/orthosis-day';
import { capitalizeFirstLetter } from '../../../../utils/string/capitalize-first-letter';

export function getYAxisTitle(params: {
    code: IndicatorCode | undefined;
    indicators: IndicatorWithSettings[];
}): string {
    const indicators = _.keyBy(
        params.indicators,
        (indicator) => indicator.code
    );

    const _code = params.code;

    if (!_code) {
        return formatMessage(Strings.chart.general.multipleUnits);
    }

    const unit = indicators[_code].data.unitOfMeasure;
    const code = indicators[_code].code;

    return getUniqueYAxisTitle({ code, unit });
}

function getUniqueYAxisTitle(params: {
    code: IndicatorCode;
    unit: string | null;
}): string {
    const code = params.code;

    if (code === parameterPumpSpeedLVAD.code) {
        return formatMessage(
            Strings.indicators.pumpSpeedLvad.general.unitX1000
        );
    }

    if (code === parameterExercises.code) {
        return capitalizeFirstLetter(
            formatMessage(Strings.indicators.exercisesHs.general.unit)
        );
    }

    if (code === parameterOrthosisDayHs.code) {
        return capitalizeFirstLetter(
            formatMessage(Strings.indicators.orthosisDayHs.general.unit, {
                value: 0
            })
        );
    }

    if (!params.unit) {
        return '';
    }

    return formatMessageById(
        `${Strings.indicators.rootId}${code.toLowerCase()}.unit`
    );
}
