// GROUPS
export const FETCH_PATIENT_DATA_GROUPS = 'FETCH_PATIENT_DATA_GROUPS';
export const FETCH_PATIENT_DATA_GROUPS_PENDING =
    'FETCH_PATIENT_DATA_GROUPS_PENDING';
export const FETCH_PATIENT_DATA_GROUPS_FULFILLED =
    'FETCH_PATIENT_DATA_GROUPS_FULFILLED';
export const FETCH_PATIENT_DATA_GROUPS_REJECTED =
    'FETCH_PATIENT_DATA_GROUPS_REJECTED';

export const FETCH_PATIENT_DATA_MEDIC_GROUPS =
    'FETCH_PATIENT_DATA_MEDIC_GROUPS';
export const FETCH_PATIENT_DATA_MEDIC_GROUPS_PENDING =
    'FETCH_PATIENT_DATA_MEDIC_GROUPS_PENDING';
export const FETCH_PATIENT_DATA_MEDIC_GROUPS_FULFILLED =
    'FETCH_PATIENT_DATA_MEDIC_GROUPS_FULFILLED';
export const FETCH_PATIENT_DATA_MEDIC_GROUPS_REJECTED =
    'FETCH_PATIENT_DATA_MEDIC_GROUPS_REJECTED';

// Conversations
export const FETCH_PATIENT_DATA_CONVERSATIONS =
    'FETCH_PATIENT_DATA_CONVERSATIONS';
export const FETCH_PATIENT_DATA_CONVERSATIONS_PENDING =
    'FETCH_PATIENT_DATA_CONVERSATIONS_PENDING';
export const FETCH_PATIENT_DATA_CONVERSATIONS_FULFILLED =
    'FETCH_PATIENT_DATA_CONVERSATIONS_FULFILLED';
export const FETCH_PATIENT_DATA_CONVERSATIONS_REJECTED =
    'FETCH_PATIENT_DATA_CONVERSATIONS_REJECTED';

// ConversationMessages
export const FETCH_PATIENT_DATA_MESSAGES = 'FETCH_PATIENT_DATA_MESSAGES';
export const FETCH_PATIENT_DATA_MESSAGES_PENDING =
    'FETCH_PATIENT_DATA_MESSAGES_PENDING';
export const FETCH_PATIENT_DATA_MESSAGES_FULFILLED =
    'FETCH_PATIENT_DATA_MESSAGES_FULFILLED';
export const FETCH_PATIENT_DATA_MESSAGES_REJECTED =
    'FETCH_PATIENT_DATA_MESSAGES_REJECTED';
// Reset
export const RESET_PATIENT_PROFILE_CONVERSATIONS =
    'RESET_PATIENT_PROFILE_CONVERSATIONS';

// ConversationMessages
export const SET_PATIENT_DATA_CONVERSATION_ID =
    'SET_PATIENT_DATA_CONVERSATION_ID';
export const SET_PATIENT_DATA_CONVERSATION_ID_PENDING =
    'SET_PATIENT_DATA_CONVERSATION_ID_PENDING';
export const SET_PATIENT_DATA_CONVERSATION_ID_FULFILLED =
    'SET_PATIENT_DATA_CONVERSATION_ID_FULFILLED';
export const SET_PATIENT_DATA_CONVERSATION_ID_REJECTED =
    'SET_PATIENT_DATA_CONVERSATION_ID_REJECTED';
