import { BaseUser } from '../user/user.type';
import { combineReducers } from 'redux';
import {
    CREATE_PATIENT_FULFILLED,
    UPDATE_PATIENT_FULFILLED
} from '../active-patient/active-patient.constants';
import {
    FETCH_GROUPS_FULFILLED,
    INIT_GROUPS_FULFILLED
} from '../groups/groups.constants';
import { BaseUserResponse } from '../user/user.api.type';
import {
    FETCH_SELECTED_PATIENT,
    TOGGLE_PATIENT_FULFILLED
} from './patient-administration.constants';
import { PatientAdministrationState } from './patient-administration.type';

const initialState: PatientAdministrationState = {
    byId: {},
    allIds: [],
    selectedPatient: ''
};

interface FetchSelectedPatientAction {
    type: 'FETCH_SELECTED_PATIENT';
    payload: BaseUser;
}

function selectedPatient(
    state = initialState.selectedPatient,
    action: FetchSelectedPatientAction
) {
    const { payload, type } = action;

    switch (type) {
        case FETCH_SELECTED_PATIENT: {
            return payload;
        }
        default:
            return state;
    }
}

function byId(state = initialState.byId, action: any) {
    const { payload, type } = action;

    switch (type) {
        case INIT_GROUPS_FULFILLED: {
            const { patients } = payload;
            return { ...state, ...patients };
        }
        case FETCH_GROUPS_FULFILLED: {
            const { patients } = payload;
            return { ...state, ...patients };
        }
        case CREATE_PATIENT_FULFILLED: {
            const {
                externalId,
                meta,
                group: groups,
                status
            }: BaseUserResponse = payload;
            return {
                ...state,
                [externalId]: { externalId, meta, groups, status }
            };
        }
        case UPDATE_PATIENT_FULFILLED: {
            const { externalId, meta, groupIds: groups } = payload;

            return {
                ...state,
                [externalId]: { ...state[externalId], externalId, meta, groups }
            };
        }
        case TOGGLE_PATIENT_FULFILLED: {
            const { externalId, status } = payload;
            return { ...state, [externalId]: { ...state[externalId], status } };
        }
        default:
            return state;
    }
}

function allIds(state = initialState.allIds, action: any) {
    const { payload, type } = action;

    switch (type) {
        case INIT_GROUPS_FULFILLED: {
            const { patients } = payload;
            return Object.keys(patients);
        }
        case FETCH_GROUPS_FULFILLED: {
            const { patients } = payload;
            return Object.keys(patients);
        }
        case CREATE_PATIENT_FULFILLED:
            return [...state, payload.externalId];
        default:
            return state;
    }
}

const patientsReducer = combineReducers({
    byId,
    allIds,
    selectedPatient
});

export default patientsReducer;
