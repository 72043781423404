import { Indicator } from '../../../../types/indicator/indicator.type';
import { rules } from './grip-hs-rules';

import icon from './images/grip.svg';
import { messagesGrip } from './messages/messages';

export const parameterGripHs = {
    code: 'GRIP-HS'
};

export const grip: Indicator = {
    code: parameterGripHs.code,
    icon,
    messages: messagesGrip,
    rules
};
