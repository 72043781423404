import decode from 'jwt-decode';
import { BaseUserMeta } from '../../redux/domains/user/user.type';
import { publicAxios } from '../../services/http-client/public-http-client';
import { authStorageKeys } from '../storage/auth/auth-storage-keys';

export type Token = BaseUserMeta & {
    exp: number;
    sub: string;
};

export type AuthTokens = {
    idToken: string;
    refreshToken?: string;
};

const refreshTokens = async (params: { refreshToken: string }) => {
    console.log('refreshTokens');
    return await publicAxios
        .post('authentication/refresh', params)
        .then(({ data }) => data)
        .catch((error) => {
            console.error('Error in postRefreshToken\n', error);
            throw error;
        });
};

export const isTokenValid = (token: string) => {
    const currentTime = Date.now() / 1000;

    return currentTime < decode<Token>(token).exp;
};

export const validateTokens = () => {
    return new Promise<string>((resolve) => {
        const idToken = window.localStorage.getItem(authStorageKeys.idToken);
        resolve(idToken && isTokenValid(idToken) ? idToken : updateTokens());
    })
        .catch((error: any) => {
            window.sessionStorage.clear();
            throw error;
        })
        .then((idToken) => decode<Token>(idToken));
};

export const updateTokens = () => {
    return new Promise<AuthTokens>((resolve, reject) => {
        const refreshToken =
            window.localStorage.getItem(authStorageKeys.refreshToken) ||
            window.sessionStorage.getItem(authStorageKeys.refreshToken);
        if (refreshToken && isTokenValid(refreshToken)) {
            resolve(refreshTokens({ refreshToken }));
        } else {
            reject('Could not validate refreshToken');
        }
    }).then(
        ({
            idToken,
            refreshToken = window.sessionStorage.getItem(
                authStorageKeys.refreshToken
            )
        }) => {
            window.localStorage.setItem(authStorageKeys.idToken, idToken);
            if (refreshToken) {
                window.localStorage.setItem(
                    authStorageKeys.refreshToken,
                    refreshToken
                );
            }
            return idToken;
        }
    );
};
