import React, { PureComponent } from 'react';
import { Button, Drawer, Select, Table } from 'antd';

import { getColumns } from './column-values/getColumns';
import FormDetails from './utils/FormDetails';
import {
    formatMessage,
    formatMessageById
} from '../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../locale/messagesDescriptors';

const { Option } = Select;

type Props = {
    indicator: any;
    indicatorValues: any;
};

type State = {
    selectedColumns: any;
    selectedRow: any;
    showDetails: any;
    columns: any;
};

class HistoryTable extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            columns: [],
            selectedColumns: [],
            selectedRow: {},
            showDetails: false
        };
    }

    onSelectChange = (values: any) =>
        this.setState({ selectedColumns: values });

    onSelectAll = () => {
        const { indicator } = this.props;
        const selectedColumns = indicator.form.item
            .filter((item: any) => item.type !== 'text')
            .map((item: any) => item.code);

        this.setState({ selectedColumns });
    };

    onDetailsClick = (row: any) => {
        this.setState({ showDetails: true, selectedRow: row });
    };

    render() {
        const { indicator, indicatorValues = [] } = this.props;
        const { selectedColumns, selectedRow } = this.state;

        const columns = getColumns(
            indicator,
            selectedColumns,
            this.onDetailsClick
        );

        let maxWidth = 600;
        const maxHeight = window.innerHeight - 500;
        if (indicator.form) {
            const numberOfColumns = columns.length - 4; // subtract 4 for the date, total score, comment and details columns.
            maxWidth = numberOfColumns > 0 ? numberOfColumns * 150 + 500 : 600;
        }

        return (
            <div style={{ paddingLeft: 50 }}>
                {indicator.form && indicator.form.type !== 'combined' && (
                    <div style={{ marginBottom: 15, display: 'flex' }}>
                        <Select
                            mode="multiple"
                            style={{ flex: 1 }}
                            onChange={this.onSelectChange}
                            value={selectedColumns}
                            placeholder={formatMessage(
                                Strings.field.general.showColumns
                            )}
                            optionFilterProp="title"
                        >
                            {indicator.form.item
                                .filter((item: any) => item.type !== 'text')
                                .map((item: any) => {
                                    const localeId = `${indicator.code.toLowerCase()}.${
                                        item.code
                                    }`;

                                    return (
                                        <Option
                                            value={item.code}
                                            key={item.code}
                                        >
                                            {formatMessageById(
                                                `${Strings.indicators.rootId}${localeId}.title`
                                            )}
                                        </Option>
                                    );
                                })}
                        </Select>
                        <Button
                            type="primary"
                            style={{ marginLeft: 10, justifySelf: 'right' }}
                            onClick={this.onSelectAll}
                        >
                            {formatMessage(Strings.ui.buttons.general.all)}
                        </Button>
                        <Button
                            type="danger"
                            style={{ marginLeft: 10, justifySelf: 'right' }}
                            onClick={() =>
                                this.setState({ selectedColumns: [] })
                            }
                            disabled={!selectedColumns}
                        >
                            {formatMessage(Strings.ui.buttons.general.clear)}
                        </Button>
                    </div>
                )}
                <Table
                    columns={columns as any}
                    dataSource={[...indicatorValues].reverse()}
                    rowKey={(value: any) => value.id}
                    pagination={false}
                    size="middle"
                    scroll={{ x: maxWidth, y: maxHeight }}
                />
                <Drawer
                    visible={this.state.showDetails}
                    onClose={() =>
                        this.setState({ showDetails: false, selectedRow: {} })
                    }
                    width={640}
                >
                    <FormDetails
                        indicator={indicator}
                        selectedRow={selectedRow}
                    />
                </Drawer>
            </div>
        );
    }
}

export default HistoryTable;
