import React, { useEffect, useState } from 'react';
import { useReduxDispatch } from '../../../hooks/use-redux-dispatch';
import {
    clearRegisterFormFields,
    saveRegisterFormFields
} from '../../../redux/domains/forms/forms.actions';
import { usePageTitle } from '../../../hooks/use-page-title';

import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { setPendingPatient } from '../../../redux/domains/app-state/app-state.actions';
import {
    fetchPatientMeta,
    resetPatientMeta
} from '../../../redux/domains/patient-meta/patient-meta.actions';
import { CrossSpinner } from '../../../components/ui/loading/loading';
import {
    CreatePatientStepsWrapper,
    CreatePatientWithChildren
} from './CreatePatientWrapper';

export const CreatePatientPage = (): JSX.Element => {
    usePageTitle(formatMessage(Strings.ui.buttons.general.createPatient));

    const pendingSsn = useReduxSelector(
        (state) => state.appState.pendingPatientSsn
    );

    if (pendingSsn) {
        return <CreatePatientFromSSO />;
    }

    return <CreatePatientStandardPage />;
};

const CreatePatientStandardPage = (): JSX.Element => {
    return (
        <CreatePatientWithChildren>
            <CreatePatientStepsWrapper />
        </CreatePatientWithChildren>
    );
};

const CreatePatientFromSSO = (): JSX.Element => {
    const dispatch: any = useReduxDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const pendingSsn = useReduxSelector(
        (state) => state.appState.pendingPatientSsn
    );

    const newPatientInfo = useReduxSelector((state) => state.patientMeta);

    useEffect(() => {
        if (newPatientInfo) {
            dispatch(
                saveRegisterFormFields({
                    nSsid: newPatientInfo.ssn,
                    nFirstName: newPatientInfo.firstName,
                    nLastName: newPatientInfo.lastName,
                    nEmail: newPatientInfo.email,
                    nPhone: newPatientInfo.phoneNumber
                })
            );
        }
        return;
    }, [dispatch, newPatientInfo]);

    useEffect(() => {
        return () => {
            dispatch(clearRegisterFormFields());
            dispatch(setPendingPatient(null));
            dispatch(resetPatientMeta());
        };
    }, [dispatch]);

    useEffect(() => {
        if (pendingSsn && !isLoading) {
            setIsLoading(true);

            dispatch(fetchPatientMeta(pendingSsn)).finally(() =>
                setIsLoading(false)
            );
        }
    }, [dispatch, pendingSsn]);

    return (
        <CreatePatientWithChildren>
            {isLoading ? <CrossSpinner /> : <CreatePatientStepsWrapper />}
        </CreatePatientWithChildren>
    );
};
