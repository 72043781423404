import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, msDelay: number): void => {
    const savedCallback = useRef(callback);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = () => savedCallback.current();

        const id = setInterval(tick, msDelay);

        return () => clearInterval(id);
    }, [msDelay]);
};
