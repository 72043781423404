import { Indicator } from '../../../types/indicator/indicator.type';
import icon from './images/vas.svg';
import { messagesVas } from './messages/messages';
import { rules } from './vas-rules';

export const parameterVAS = {
    code: 'VAS'
};

export const vas: Indicator = {
    code: parameterVAS.code,
    icon,
    messages: messagesVas,
    rules
};
