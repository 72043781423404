import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import Actions from './actions/Actions';
import { IndicatorIcon } from '../../../../../components/ui/icons/indicator-icon/IndicatorIcon';
import { IndicatorTrend } from './indicator-trend/TrendPopover';
import PriorityTableIndicator from './PriorityTableIndicator';
import {
    PriorityPatient,
    Watchover
} from '../../../../../redux/domains/priority/priority.type';
import { AntdToolTipContentWrapper } from '../../../../../components/ui/tooltip/AntdToolTipContentWrapper';
import { IndicatorWithSettings } from '../../../../../redux/domains/indicators/indicator.type';
import { pathPatientProfileLink } from '../../../data/components/tabs/path';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';

type PriorityColumn = ColumnProps<PriorityPatient>;

export function getColumns(
    selectedPatientGroupId: string,
    indicators: IndicatorWithSettings[],
    isMedicAdmin: boolean
): PriorityColumn[] {
    const infoColumns: PriorityColumn[] = [
        {
            title: formatMessage(Strings.patient.meta.general.social),
            key: 'social',
            dataIndex: 'meta.personal_id',
            width: 150,
            render(text: string, row) {
                return {
                    children: text,
                    props: {
                        'data-test-id': row.meta.externalId
                    }
                };
            }
        },
        {
            title: formatMessage(Strings.patient.meta.general.name),
            key: 'name',
            dataIndex: 'meta',
            render(text: PriorityPatient['meta']) {
                return (
                    <Link
                        to={{
                            pathname: pathPatientProfileLink(
                                selectedPatientGroupId,
                                text.externalId
                            )
                        }}
                    >
                        {`${text.name} ${text.family_name}`}
                    </Link>
                );
            },
            width: 150
        },
        {
            title: formatMessage(Strings.ui.table.general.actions),
            dataIndex: 'actions',
            render(text, row) {
                return <Actions patient={row} isMedicAdmin={isMedicAdmin} />;
            },
            className: 'table-actions',
            width: 120
        },
        {
            title: formatMessage(Strings.ui.table.general.watchedOver),
            dataIndex: 'watchover',
            render: (text: Watchover | undefined) => {
                if (!text)
                    return (
                        <span
                            style={{
                                color: 'lightgray',
                                fontWeight: 600,
                                fontSize: 12
                            }}
                        >
                            <div>
                                {formatMessage(
                                    Strings.ui.table.tableData.watchedOver
                                )}
                            </div>
                        </span>
                    );

                const { practitioner_name, practitioner_family_name } = text;
                const practitioner = `${practitioner_name} ${practitioner_family_name}`;
                const children = (
                    <div>
                        <div>
                            {formatMessage(Strings.common.general.by)}
                            <span style={{ marginLeft: 2 }}>
                                {practitioner}
                            </span>
                        </div>
                        <div
                            style={{
                                fontSize: 12,
                                color: 'rgb(90, 99, 194)'
                            }}
                        >
                            {moment(text.dtCreated).fromNow()}
                        </div>
                    </div>
                );
                return {
                    children,
                    props: {
                        className: text.status
                    }
                };
            },
            width: 185
        }
    ];

    const indicatorColumns: PriorityColumn[] = indicators.map((indicator) => {
        return {
            title: (
                <Tooltip
                    title={indicator.title}
                    overlayClassName="indicator-header-tooltip"
                >
                    <AntdToolTipContentWrapper>
                        <IndicatorIcon size={46} code={indicator.code} />
                    </AntdToolTipContentWrapper>
                </Tooltip>
            ),
            dataIndex: `results.${indicator.id}`,
            className: 'table-head-center',
            render: renderPopover,
            width: 80
        };

        function renderPopover(result: any, row: PriorityPatient) {
            const {
                meta: { name = '', family_name = '', externalId = '' } = {}
            } = row;

            const fullName = ` - ${name} ${family_name}`;

            return (
                <IndicatorTrend
                    indicator={indicator}
                    subjectId={externalId}
                    patientName={fullName}
                >
                    <PriorityTableIndicator
                        indicator={indicator}
                        result={result}
                        row={row}
                        showEffects={true}
                    />
                </IndicatorTrend>
            );
        }
    });

    // Add empty column to fill up remaining space
    indicatorColumns.push({
        title: '',
        dataIndex: ''
    });

    return infoColumns.concat(indicatorColumns);
}
