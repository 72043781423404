import { Indicator } from '../../../../types/indicator/indicator.type';

//import icon from './images/urine-leakage.svg';
import icon from '../../../shared-indicators/missing/images/missing.svg';
import { messagesUrineLeakage } from './messages/messages';
import { rules } from './urine-leakage-rules';

export const parameterUrineLeakage = {
    code: 'URINATION'
};

export const urineLeakage: Indicator = {
    code: parameterUrineLeakage.code,
    icon,
    messages: messagesUrineLeakage,
    rules
};
