import { prnmed as sharedPrnmed } from '../../../shared-indicators/prnmed/prnmed';
import { messagesPrnmedAf } from './messages/messages';
import { PrnmedAFProperties } from './prnmed-af-properties';
import { rules } from './prnmed-af-rules';

export const parameterPrnmedAf = {
    code: 'PRNMED-AF',
    properties: PrnmedAFProperties
};

export const prnmed: typeof sharedPrnmed = {
    ...sharedPrnmed,
    code: parameterPrnmedAf.code,
    messages: messagesPrnmedAf,
    rules
};
