import React, { useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import addNoDataModule from 'highcharts/modules/no-data-to-display';

import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import { DataPoint } from '../chart-types';
import { ChartDateButtons } from '../date-buttons/ChartDateButtons';
import { Duration } from '../duration';

import { ChartOptions } from '../../../../types/indicator/indicator-rules.type';
import { useTrendGraphOptions } from './use-graph-options';

addNoDataModule(Highcharts);

export const Graph = (props: {
    data: DataPoint[];
    indicator: IndicatorWithSettings;
    containerStyle?: React.CSSProperties;
    chartOptions?: ChartOptions;
}): JSX.Element => {
    const [duration, setDuration] = useState<Duration>(Duration.Week);

    const options: Highcharts.Options = useTrendGraphOptions({
        indicator: props.indicator,
        data: props.data,
        duration,
        chartOptions: props.chartOptions
    });

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                containerProps={{
                    style: props.containerStyle || { width: 250, height: 250 }
                }}
            />
            <ChartDateButtons setDuration={setDuration} />
        </>
    );
};
