import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        id: 'medic-profile.title',
        defaultMessage: 'Profile'
    }
});

const fields = defineMessages({
    sithsNumber: {
        id: 'medic.form.field.siths-number',
        defaultMessage: 'SITHS number'
    }
});

export const medicProfile = {
    general,
    fields
};
