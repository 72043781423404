import _ from 'lodash';
import { PlotbandLimit } from '../../../../../../components/charts/graph/types/plotband.type';
import { IndicatorBoundariesLimits } from '../../../../indicators/indicator.type';
import { getBoundaryColor } from './plot-band-config-colors';

export function createBoundaries(
    boundaries: IndicatorBoundariesLimits,
    defaultFrom: number,
    compare: (a: number, b: number) => number
): PlotbandLimit[] {
    let from: number;

    return _.reduce(
        boundaries,
        (
            result: PlotbandLimit[],
            value: number | null | undefined,
            key: string
        ) => {
            if (value != null) {
                if (from != null) {
                    // @ts-ignore
                    result = result.concat({
                        to: value,
                        from: from,
                        color: getBoundaryColor(key),
                        label: key
                    });
                    from = value;
                } else {
                    from = compare(value, defaultFrom);
                }
            }
            return result;
        },
        []
    );
}
