import { errorHandler } from '../../../services/http-client/handle-error';
import { HTTPClient } from '../../../services/http-client/http-client';
import { GetGroupsInterface } from './groups.api.interface';
import { normalizeGroups } from './groups.utils';

export const getGroups = <T>(groupIds: string[]): Promise<T> =>
    HTTPClient.get(`groups/${groupIds.join(',')}`);

export const getGroupsForUsers = (params: GetGroupsInterface) =>
    HTTPClient.get('groups/users', { params })
        .then(normalizeGroups)
        .catch((error) => errorHandler(error, 'Error fetching groups'));
