import { ReportValue } from '../../../../types/indicators/Indicator';
import {
    ACTIVE_MOTION_HS,
    APPETITE,
    BLOOD_PRESSURE,
    BLOOD_PRESSURE_LVAD,
    BLOOD_SUGAR,
    BMI,
    BODY_WEIGHT,
    BOWEL_DYSFUNCTION,
    BREATHLESSNESS,
    CAT,
    DAILY_ACTIVITY_HS,
    DIZZINESS,
    EHRA,
    EXERCISES_HS,
    MEDICATION_CI,
    MEDICATION_HS,
    MMRC,
    PAIN_AFTER_ACTIVITY_HS,
    PAIN_AT_REST_HS,
    PAIN_CI,
    PAIN_DURING_ACTIVITY_HS,
    PHLEGM,
    PHYSACT,
    PULSE,
    RESPIRATORY_PROBLEMS,
    SLEEP_HS,
    SWELLING,
    SWELLING_HS,
    TIREDNESS,
    WEIGHT_PD
} from '../../../../conditions/indicator-meta';
import { IndicatorCode } from '../../../../types/indicator/indicator.type';
import { DecimalRule } from '../../../../redux/domains/settings/settings.type';
import { TrendData } from '../utils/trend-data.type';
import {
    isCatValue,
    isNumber,
    isMultipropertyValue,
    isRespiratoryProblemValue,
    isBowelDysfunctionValue
} from './data-guard';
import { IndicatorId } from '../../../../redux/domains/indicators/indicator.type';
import { Strings } from '../../../../locale/messagesDescriptors';
import {
    formatMessage,
    formatMessageById
} from '../../../../locale/format/format-message';
import { formatNumber } from '../../../../locale/format/format-number';
import { parameterVAS } from '../../../../conditions/shared-indicators/vas/vas';

type LatestValue = ReportValue<number | Record<string, number>>;

export const getDefaultTrendData = (props: {
    latestValue: ReportValue<number | Record<string, number>>;
    code: IndicatorCode;
    decimals?: DecimalRule;
}): TrendData[] => {
    const indicatorCode = props.code;
    const decimals = props.decimals || undefined;
    const value = props.latestValue;

    const indicatorRootTextId = `${
        Strings.indicators.rootId
    }${indicatorCode.toLowerCase()}`;

    switch (indicatorCode) {
        case ACTIVE_MOTION_HS.code:
        case BLOOD_PRESSURE_LVAD.code:
        case BLOOD_SUGAR.code:
        case BODY_WEIGHT.code:
        case DAILY_ACTIVITY_HS.code:
        case EXERCISES_HS.code:
        case MEDICATION_HS.code:
        case PAIN_AFTER_ACTIVITY_HS.code:
        case PAIN_AT_REST_HS.code:
        case PAIN_DURING_ACTIVITY_HS.code:
        case PULSE.code:
        case SWELLING_HS.code:
        case parameterVAS.code:
        case WEIGHT_PD.code:
            if (isNumber(value)) {
                return [
                    {
                        value:
                            formatMessageById(`${indicatorRootTextId}.value`, {
                                value
                            }) || formatNumber(value),
                        unit: formatMessageById(`${indicatorRootTextId}.unit`, {
                            value
                        })
                    }
                ];
            }
            throw new Error('value is not a number');
        case APPETITE.code:
        case BREATHLESSNESS.code:
        case DIZZINESS.code:
        case EHRA.code:
        case MMRC.code:
        case SLEEP_HS.code:
        case TIREDNESS.code:
            return [
                {
                    value: formatMessageById(
                        `${indicatorRootTextId}.choices.${value}`
                    )
                }
            ];
        case BLOOD_PRESSURE.code:
        case BMI.code:
        case MEDICATION_CI.code:
        case PAIN_CI.code:
        case PHLEGM.code:
        case PHYSACT.code:
        case SWELLING.code:
            return multipropertyValues({
                latestValue: value,
                indicatorRootTextId: indicatorRootTextId,
                decimals
            });

        case CAT.code:
            if (isCatValue(value)) {
                return [{ value: value.sum }];
            }
            throw new Error('value is not a cat value');

        case BOWEL_DYSFUNCTION.code:
            if (isBowelDysfunctionValue(value)) {
                let choicesText = '';
                let showScore = true;

                if (!value.sum) {
                    choicesText = formatMessage(
                        Strings.indicators.nbd.choices.none
                    );
                    showScore = false;
                } else if (value.sum >= 14) {
                    choicesText = formatMessage(
                        Strings.indicators.nbd.choicesNoScore.severe
                    );
                } else if (value.sum >= 10) {
                    choicesText = formatMessage(
                        Strings.indicators.nbd.choicesNoScore.moderate
                    );
                } else if (value.sum >= 7) {
                    choicesText = formatMessage(
                        Strings.indicators.nbd.choicesNoScore.minor
                    );
                } else if (value.sum >= 0) {
                    choicesText = formatMessage(
                        Strings.indicators.nbd.choicesNoScore.veryMinor
                    );
                }

                const score = formatMessage(
                    Strings.indicators.nbd.general.score,
                    {
                        score: value.sum
                    }
                );

                if (showScore) {
                    return [
                        {
                            value: `${choicesText} (${score})`
                        }
                    ];
                }

                return [
                    {
                        value: choicesText
                    }
                ];
            }
            throw new Error('value is not a bowel dysfunction value');

        case RESPIRATORY_PROBLEMS.code:
            if (isRespiratoryProblemValue(value)) {
                return [
                    {
                        value: formatMessage(
                            Strings.indicators.respiratoryProblems.status
                                .legend,
                            {
                                range: value.frequency
                            }
                        )
                    }
                ];
            }
            throw new Error('value is not a respiratory problem value');
        default:
            return [];
    }
};

export function multipropertyValues(params: {
    latestValue: LatestValue;
    indicatorRootTextId: string;
    decimals?: DecimalRule;
}): {
    title: string;
    value: string;
    unit: string;
}[] {
    const value = params.latestValue;

    if (isMultipropertyValue(value)) {
        return Object.keys(value).map((key) =>
            multipropertyValue({
                value,
                indicatorRootTextId: params.indicatorRootTextId,
                decimals: params.decimals,
                key
            })
        );
    }
    throw new Error('value is not a multiproperty value');
}

function multipropertyValue(params: {
    value: Record<string, number>;
    indicatorRootTextId: IndicatorId;
    decimals?: DecimalRule;
    key: string;
}) {
    const indicatorRootTextId = params.indicatorRootTextId;
    const decimals = params.decimals;
    const key = params.key;

    const reportedValue = params.value[key];
    const titleText = formatMessageById(`${indicatorRootTextId}.${key}.title`);
    const valueText = formatMessageById(
        `${indicatorRootTextId}.${key}.choices.${reportedValue}`
    );
    const unitText = formatMessageById(`${indicatorRootTextId}.${key}.unit`);

    if (decimals && decimals[key]) {
        return {
            title: titleText,
            value: valueText
                ? valueText
                : formatNumber(reportedValue, {
                      style: 'decimal',
                      minimumFractionDigits: decimals[key],
                      maximumFractionDigits: decimals[key]
                  }),
            unit: unitText
        };
    }
    return {
        title: titleText,
        value: valueText ? valueText : formatNumber(reportedValue),
        unit: unitText
    };
}
