import styled from 'styled-components';

const StyledTitle = styled.span`
    flex-grow: 1;
    margin-left: 1rem;
    color: #000;
    font-size: 1.1rem;
    font-weight: 400;
`;

export { StyledTitle };
