import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PatientGroup } from '../../../../../../redux/domains/groups/groups.type';
import { CrossSpinner } from '../../../../../../components/ui/loading/loading';
import { useReduxSelector } from '../../../../../../hooks/use-redux-selector';
import { ActivePatient } from '../../../../../../redux/domains/active-patient/active-patient.type';
import {
    fetchPatientDataConversations,
    fetchPatientDataMessages,
    resetPatientDataConversations,
    setConversationId
} from '../../../../../../redux/domains/patient-data/patient-data.actions';
import {
    FETCH_PATIENT_DATA_CONVERSATIONS,
    FETCH_PATIENT_DATA_MESSAGES
} from '../../../../../../redux/domains/patient-data/patient-data.constants';
import {
    getConversationsForPatientGroup,
    getDefaultConversationId,
    getSelectedConversationForPatientGroup,
    getSortedMessages
} from '../../../../../../redux/domains/patient-data/patient-data.selectors';
import { createLoadingSelector } from '../../../../../../redux/domains/requests/requests.selectors';
import { styled } from '../../../../../../theme/styled';
import { tabs } from '../messages-descriptors';
import { ConversationSection } from './ConversationsSection';
import { MessageSection } from './MessageSection';
import { StyledVerticalDivider } from '../../../../../../components/ui/dividers/StyledVerticalDivider';
import { formatMessage } from '../../../../../../locale/format/format-message';

const conversationsLoadingSelector = createLoadingSelector([
    FETCH_PATIENT_DATA_CONVERSATIONS
]);

const messagesLoadingSelector = createLoadingSelector([
    FETCH_PATIENT_DATA_MESSAGES
]);

const headerHeight = 400;

export const ConversationTab = (props: {
    patient: ActivePatient;
    patientGroup: PatientGroup;
}): JSX.Element => {
    const dispatch = useDispatch();
    const windowHeight = window.innerHeight;

    const patient = props.patient;
    const patientGroup = props.patientGroup;

    const isConversationsLoading = useReduxSelector(
        conversationsLoadingSelector
    );
    const isMessagesLoading = useReduxSelector(messagesLoadingSelector);
    const patientConversations = useReduxSelector((state) =>
        getConversationsForPatientGroup(state, patientGroup)
    );
    const selectedConversationId = useSelectedConversationId(patientGroup);
    const conversationMessages = useReduxSelector(getSortedMessages);

    useEffect(() => {
        dispatch(fetchPatientDataConversations(patient.externalId));
        return () => {
            dispatch(resetPatientDataConversations());
        };
    }, [dispatch, patient.externalId]);

    useEffect(() => {
        if (selectedConversationId) {
            dispatch(fetchPatientDataMessages(selectedConversationId));
        }
    }, [dispatch, selectedConversationId]);

    return (
        <>
            <StyledTitle>
                {formatMessage(tabs.conversation.section.conversations.title)}
            </StyledTitle>

            <StyledRow $height={windowHeight - headerHeight}>
                <StyledSection>{renderConversations()}</StyledSection>
                <StyledVerticalDivider />
                <StyledMessages>{renderMessages()}</StyledMessages>
            </StyledRow>
        </>
    );

    function renderConversations() {
        if (isConversationsLoading) {
            return (
                <StyledLoadingWrapper>
                    <CrossSpinner />
                </StyledLoadingWrapper>
            );
        }

        return patientConversations.length > 0 ? (
            <ConversationSection
                patientConversations={patientConversations}
                selectedConversationId={selectedConversationId}
            />
        ) : (
            <div>
                {formatMessage(
                    tabs.conversation.section.conversations.noConversations
                )}
            </div>
        );
    }

    function renderMessages() {
        if (isConversationsLoading || isMessagesLoading) {
            return (
                <StyledLoadingWrapper>
                    <CrossSpinner />
                </StyledLoadingWrapper>
            );
        }

        return patientConversations.length > 0 &&
            (!selectedConversationId || conversationMessages.length > 0) ? (
            <MessageSection messages={conversationMessages} patient={patient} />
        ) : (
            <div>
                {formatMessage(tabs.conversation.section.messages.noMessages)}
            </div>
        );
    }
};

function useSelectedConversationId(patientGroup: PatientGroup) {
    const dispatch = useDispatch();
    const selectedConversationId = useReduxSelector(
        (state) =>
            getSelectedConversationForPatientGroup(state, patientGroup)?.id
    );
    const defaultConversationId = useReduxSelector(
        (state) =>
            selectedConversationId ||
            getDefaultConversationId(state, patientGroup)
    );

    useEffect(() => {
        if (!selectedConversationId && defaultConversationId) {
            dispatch(setConversationId(defaultConversationId));
        }
    }, [dispatch, selectedConversationId, defaultConversationId]);

    return selectedConversationId;
}

const StyledLoadingWrapper = styled.div`
    margin: auto;
    margin-top: 50px;
`;

const StyledRow = styled.div<{ $height: number }>`
    display: flex;
    flex-direction: row;
    justify-content: start;

    height: ${({ $height }) => $height}px;
`;

const StyledSection = styled.div`
    margin-top: 0px;
    width: 300px;
`;

const StyledMessages = styled.div`
    margin-top: 0px;
    width: 500px;
    height: 100%;
`;

const StyledTitle = styled.span`
    font-weight: bold;
`;
