import React, { useEffect } from 'react';
import styled from 'styled-components';

import CrossCard from '../../../components/shared/styled/CrossCard';
import { CrossSpinner } from '../../../components/ui/loading/loading';
import { logo } from '../../../static/images/logos/logo';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { LogoutCompleted } from './componens/LogoutCompleted';
import { StyledAuth } from '../../../styled/styled-auth';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../redux/domains/authentication/authentication.actions';
import { StyledLogoutTitle } from './styled/styled-logout';
import { authStorageKeys } from '../../../utils/storage/auth/auth-storage-keys';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

export const Logout = () => {
    const isAuthenticated = useReduxSelector(
        (state) => state.authentication.isAuthenticated
    );
    const dispatch = useDispatch<any>();

    useEffect(() => {
        if (isAuthenticated) {
            const startLogout = async () => {
                const response = await dispatch(logoutUser());
                const { value: { redirectUrl = null } = {} } = response;

                if (redirectUrl) {
                    window.location.href = redirectUrl;
                }
                // Sync logout to any other browser tab via storage event
                window.localStorage.setItem(
                    authStorageKeys.logout,
                    Date.now().toString()
                );
            };
            startLogout();
        }
    }, [dispatch, isAuthenticated]);

    return (
        <StyledAuth>
            <CrossCard
                style={{
                    textAlign: 'center',
                    padding: 36
                }}
            >
                <StyledLogoWrapper src={logo} alt="Logo" />
                {isAuthenticated ? (
                    <div>
                        <StyledLogoutTitle>
                            {formatMessage(Strings.authText.main.logoutPending)}
                        </StyledLogoutTitle>
                        <CrossSpinner />
                    </div>
                ) : (
                    <LogoutCompleted />
                )}
            </CrossCard>
        </StyledAuth>
    );
};

const StyledLogoWrapper = styled.img`
    margin: auto;
    width: 120px;
    margin-bottom: 20px;
`;
