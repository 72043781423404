import moment from 'moment';

// Missing types declaration, "in some circumstances a partial cast to the any type is necessary because of missing declarations."
// https://github.com/highcharts/highcharts-angular/issues/107
// https://github.com/highcharts/highcharts-declarations-beta/

export const getXAxisExtremes = (
    duration: moment.Duration
): { startDate: number; endDate: number } => {
    return {
        startDate: moment().subtract(duration).startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf()
    };
};

export const daysInMilliseconds = (days: number): number => {
    return 1000 * 3600 * 24 * days;
};
