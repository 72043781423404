import React from 'react';
import { Checkbox } from 'antd';
import { StyledTable } from '../../../../components/ui/table/Table';
import {
    CareUnit,
    PatientAccess,
    Admin
} from '../../../../components/medic/details/table-columns';
import { alphabetic } from '../../../../utils/sort/alphabetic';
import {
    MedicGroupId,
    MedicGroupsById
} from '../../../../redux/domains/groups/groups.type';

export const GroupSelectorTable = (props: {
    adminGroupsForCurrentUser: string[];
    allMedicGroups: MedicGroupsById;
    setPendingBasicGroups: React.Dispatch<React.SetStateAction<string[]>>;
    pendingBasicGroups: string[];
    setPendingAdminGroups: React.Dispatch<React.SetStateAction<string[]>>;
    pendingAdminGroups: string[];
    waitingOnDispatch: boolean;
}) => {
    const {
        adminGroupsForCurrentUser,
        allMedicGroups,
        setPendingBasicGroups,
        pendingBasicGroups,
        setPendingAdminGroups,
        pendingAdminGroups,
        waitingOnDispatch
    } = props;

    return (
        <StyledTable
            style={{ maxWidth: 1000, marginBottom: 20 }}
            $clickable={false}
            columns={[CareUnit, PatientAccess, Admin]}
            dataSource={adminGroupsForCurrentUser
                .map((adminId: MedicGroupId, index) => {
                    return {
                        id: adminId,
                        careUnit: allMedicGroups[adminId].name,
                        patientAccess: (
                            <Checkbox
                                key={index}
                                style={{ textAlign: 'center' }}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        setPendingBasicGroups([
                                            ...pendingBasicGroups,
                                            adminId
                                        ]);
                                    } else {
                                        setPendingBasicGroups(
                                            pendingBasicGroups.filter(
                                                (groupId) => groupId !== adminId
                                            )
                                        );
                                    }
                                }}
                            />
                        ),
                        admin: (
                            <Checkbox
                                key={index}
                                style={{ textAlign: 'center' }}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        setPendingAdminGroups([
                                            ...pendingAdminGroups,
                                            adminId
                                        ]);
                                    } else {
                                        setPendingAdminGroups(
                                            pendingAdminGroups.filter(
                                                (groupId) => groupId !== adminId
                                            )
                                        );
                                    }
                                }}
                            />
                        )
                    };
                })
                .sort((a, b) => alphabetic(a.careUnit, b.careUnit))}
            pagination={false}
            loading={waitingOnDispatch}
        />
    );
};
