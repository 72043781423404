import React, { Component } from 'react';
import { Badge, Icon, Tooltip } from 'antd';
import { connect } from 'react-redux';

import { getUnreadMessages } from '../../../../../../redux/domains/notifications/notifications.selectors';
import { openActionPanel } from '../../../../../../redux/domains/action-panel/action-panel.actions';
import { formatMessage } from '../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../locale/messagesDescriptors';

type MessageNotifierProps = {
    unreadMessagesCount: number;
    disabled: boolean;
    name: string;
    externalId: string;
    openActionPanel: typeof openActionPanel;
};

class MessageNotifier extends Component<MessageNotifierProps> {
    render() {
        const { unreadMessagesCount } = this.props;

        const theme = unreadMessagesCount > 0 ? 'twoTone' : 'outlined';
        const twoToneColor = 'red';

        if (this.props.disabled) {
            return <Icon type="mail" className="actionpanel-icon disabled" />;
        }

        return (
            <Tooltip
                title={
                    unreadMessagesCount > 0
                        ? formatMessage(
                              Strings.notification.general.unreadMessage,
                              {
                                  unreadMessagesCount
                              }
                          )
                        : null
                }
            >
                <Badge count={unreadMessagesCount} dot>
                    <Icon
                        type="mail"
                        className="actionpanel-icon message"
                        theme={theme}
                        twoToneColor={twoToneColor}
                        onClick={() =>
                            this.props.openActionPanel(
                                this.props.externalId,
                                'messages'
                            )
                        }
                    />
                </Badge>
            </Tooltip>
        );
    }
}

const mapStateToProps = (state: any, props: any) => {
    return {
        unreadMessagesCount: getUnreadMessages(state, props).length
    };
};

export default connect(mapStateToProps, {
    openActionPanel
})(MessageNotifier);
