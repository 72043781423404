import React, { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { getRGBA } from '../../../../../../theme/colors/functions';
import {
    StyledColumn,
    StyledColumnRight,
    StyledRowTitle
} from './styled-column';

type Props = {
    path: string;
    icon: JSX.Element;
    title?: string;
    target?: string;
};

export const ColumnLinkItem = (
    props: PropsWithChildren<Props>
): JSX.Element => (
    <StyledNavLink to={{ pathname: props.path }}>
        <StyledColumn>
            <StyledRowTitle>{props.title}</StyledRowTitle>
            <div>{props.children}</div>
        </StyledColumn>
        <StyledColumnRight>{props.icon}</StyledColumnRight>
    </StyledNavLink>
);

const StyledNavLink = styled(NavLink)`
    color: black;
    display: flex;
    flex-direction: row;
    width: 400px;
    justify-content: space-between;
    background-color: ${(props) => getRGBA(props.theme.primary.color, 0.1)};
    margin-bottom: ${(props) => props.theme.spacing.small}px;
    padding: 10px;
    cursor: pointer;
`;
