import { defineMessages } from 'react-intl';

const email = defineMessages({
    hint: {
        id: 'medic.edit-patient.field.email.hint',
        defaultMessage: 'Enter the patient’s e-mail'
    },
    error: {
        id: 'medic.edit-patient.field.email.error',
        defaultMessage: 'Cannot be empty'
    }
});

const firstName = defineMessages({
    label: {
        id: 'medic.edit-patient.field.first-name.label',
        defaultMessage: 'First name'
    },
    hint: {
        id: 'medic.edit-patient.field.first-name.hint',
        defaultMessage: 'Patient’s name'
    },
    error: {
        id: 'medic.edit-patient.field.first-name.error',
        defaultMessage: 'Enter the patient’s name'
    }
});

const familyName = defineMessages({
    label: {
        id: 'medic.edit-patient.field.family-name.label',
        defaultMessage: 'Last Name'
    },
    hint: {
        id: 'medic.edit-patient.field.family-name.hint',
        defaultMessage: 'Patient’s Last Name'
    },
    error: {
        id: 'medic.edit-patient.field.family-name.error',
        defaultMessage: 'Enter the patient’s Last Name'
    }
});

const ssn = defineMessages({
    label: {
        id: 'medic.edit-patient.field.ssn.label',
        defaultMessage: 'Social Security number'
    },
    hint: {
        id: 'medic.edit-patient.field.ssn.hint',
        defaultMessage: 'Patient’s SSN'
    },
    error: {
        id: 'medic.edit-patient.field.ssn.error',
        defaultMessage: 'Provide valid social security number'
    }
});

const phoneNumber = defineMessages({
    label: {
        id: 'medic.edit-patient.field.phone-number.label',
        defaultMessage: 'Mobile phone number'
    },
    hint: {
        id: 'medic.edit-patient.field.phone-number.hint',
        defaultMessage: 'Patient’s mobile phone nbr'
    },
    error: {
        id: 'medic.edit-patient.field.phone-number.error',
        defaultMessage: 'Enter the patient’s mobile phone number'
    }
});

export const editPatientFields = {
    email,
    firstName,
    familyName,
    phoneNumber,
    ssn
};
