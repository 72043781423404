import { messagesActiveMotion } from '../../../conditions/hand-surgery/indicators/active-motion/messages/messages';
import { messagesAppetite } from '../../../conditions/shared-indicators/appetite/messages/messages';
import { messagesBloodPressure } from '../../../conditions/shared-indicators/blood-pressure/messages/messages';
import { messagesBloodPressureLvad } from '../../../conditions/left-ventricular-assist-device/indicators/blood-pressure-average/mesages/messages';
import { messagesBloodSugar } from '../../../conditions/shared-indicators/blood-sugar/messages/messages';
import { messagesBmi } from '../../../conditions/shared-indicators/bmi/messages/messages';
import { messagesBodyLength } from '../../../conditions/shared-indicators/body-length/messages/messages';
import { messagesBodyWeight } from '../../../conditions/shared-indicators/body-weight/messages/messages';
import { messagesBreathlessness } from '../../../conditions/shared-indicators/breathlessness/messages/messages';
import { messagesCandy } from '../../../conditions/shared-indicators/candy/messages/messages';
import { messagesCat } from '../../../conditions/chronic-obstructive-pulmonary-disease/indicators/cat/messages/messages';
import { messagesDailyActivity } from '../../../conditions/hand-surgery/indicators/daily-activity/messages/messages';
import { messagesDizziness } from '../../../conditions/shared-indicators/dizziness/messages/messages';
import { messagesEhfscbs } from '../../../conditions/heart-failure/indicators/ehfscbs/messages/messages';
import { messagesEhra } from '../../../conditions/atrial-fibrillation/indicators/ehra/messages/messages';
import { messagesEq5d3l } from '../../../conditions/shared-indicators/eq5d3l/messages/messages';
import { messagesExercises } from '../../../conditions/hand-surgery/indicators/exercises/messages/messages';
import { messagesFish } from '../../../conditions/shared-indicators/fish/messages/messages';
import { messagesGrip } from '../../../conditions/hand-surgery/indicators/grip/messages/messages';
import { messagesMedicationCi } from '../../../conditions/intermittent-claudication/indicators/medication/messages/messages';
import { messagesMedicationHs } from '../../../conditions/hand-surgery/indicators/medication/messages/messages';
import { messagesMmrc } from '../../../conditions/shared-indicators/mmrc/messages/messages';
import { messagesOrthosisDay } from '../../../conditions/hand-surgery/indicators/orthosis-day/messages/messages';
import { messagesOrthosisNight } from '../../../conditions/hand-surgery/indicators/orthosis-night/messages/messages';
import { messagesPainAfterActivity } from '../../../conditions/hand-surgery/indicators/pain/messages/after-activity';
import { messagesPainAtRest } from '../../../conditions/hand-surgery/indicators/pain/messages/at-rest';
import { messagesPainDuringActivity } from '../../../conditions/hand-surgery/indicators/pain/messages/during-activity';
import { messagesPainCi } from '../../../conditions/shared-indicators/pain/messages/messages';
import { messagesPhlegm } from '../../../conditions/shared-indicators/phlegm/messages/messages';
import { messagesPhysact } from '../../../conditions/shared-indicators/physact/messages/messages';
import { messagesPrnmed } from '../../../conditions/shared-indicators/prnmed/messages/messages';
import { messagesPrnmedAf } from '../../../conditions/atrial-fibrillation/indicators/prnmed/messages/messages';
import { messagesPrnmedCi } from '../../../conditions/intermittent-claudication/indicators/prnmed/messages/messages';
import { messagesPrnmedCopd } from '../../../conditions/chronic-obstructive-pulmonary-disease/indicators/prnmed/messages/messages';
import { messagesPrnmedLvad } from '../../../conditions/left-ventricular-assist-device/indicators/prnmed/messages/messages';
import { messagesPulse } from '../../../conditions/shared-indicators/pulse/messages/messages';
import { messagesPumpFlowLvad } from '../../../conditions/left-ventricular-assist-device/indicators/pump-flow/messages/messages';
import { messagesPumpPowerLvad } from '../../../conditions/left-ventricular-assist-device/indicators/pump-power/messages/messages';
import { messagesPumpPulseIndexLvad } from '../../../conditions/left-ventricular-assist-device/indicators/pump-pulse-index/messages/messages';
import { messagesPumpSelfTestLvad } from '../../../conditions/left-ventricular-assist-device/indicators/pump-self-test/messages/messages';
import { messagesPumpSpeedLvad } from '../../../conditions/left-ventricular-assist-device/indicators/pump-speed/messages/messages';
import { messagesSleepHs } from '../../../conditions/hand-surgery/indicators/sleep/messages/messages';
import { messagesSmoke } from '../../../conditions/shared-indicators/smoke/messages/messages';
import { messagesSwelling } from '../../../conditions/shared-indicators/swelling/messages/messages';
import { messagesSwellingHs } from '../../../conditions/hand-surgery/indicators/swelling-hs/messages/messages';
import { messagesTiredness } from '../../../conditions/shared-indicators/tiredness/messages/messages';
import { messagesVas } from '../../../conditions/shared-indicators/vas/messages/messages';
import { messagesVegetables } from '../../../conditions/shared-indicators/vegetables/messages/messages';
import { messagesVocationalActivity } from '../../../conditions/hand-surgery/indicators/vocational-activity/messages/messages';
import { messagesWaistCircumference } from '../../../conditions/shared-indicators/waist-circumference/messages/messages';
import { messagesWeightPd } from '../../../conditions/shared-indicators/weight-pd/messages/messages';
import { messagesSleepSCI } from '../../../conditions/spinal-cord-injury/indicators/sleep-sci/messages/messages';
import { messagesOxygenSaturation } from '../../../conditions/spinal-cord-injury/indicators/oxygen-saturation/messages/messages';
import { messagesRespiratoryProblems } from '../../../conditions/spinal-cord-injury/indicators/respiratory-problems/messages/messages';
import { messagesBowelDysfunction } from '../../../conditions/spinal-cord-injury/indicators/bowel-dysfunction/messages/messages';

export const indicators = {
    rootId: 'indicator.',
    activeMotionHs: messagesActiveMotion,
    appetite: messagesAppetite,
    bloodPressure: messagesBloodPressure,
    bloodPressureLvad: messagesBloodPressureLvad,
    bloodSugar: messagesBloodSugar,
    bmi: messagesBmi,
    bodyLength: messagesBodyLength,
    bodyWeight: messagesBodyWeight,
    breathlessness: messagesBreathlessness,
    candy: messagesCandy,
    cat: messagesCat,
    dailyActivityHs: messagesDailyActivity,
    dizziness: messagesDizziness,
    ehfscbs: messagesEhfscbs,
    ehra: messagesEhra,
    eq5d3l: messagesEq5d3l,
    exercisesHs: messagesExercises,
    fish: messagesFish,
    gripHs: messagesGrip,
    medicationCi: messagesMedicationCi,
    medicationHs: messagesMedicationHs,
    mmrc: messagesMmrc,
    nbd: messagesBowelDysfunction,
    orthosisDayHs: messagesOrthosisDay,
    orthosisNightHs: messagesOrthosisNight,
    oxgenSaturation: messagesOxygenSaturation,
    painAfterActivity: messagesPainAfterActivity,
    painAtRest: messagesPainAtRest,
    painDuringActivity: messagesPainDuringActivity,
    painCi: messagesPainCi,
    phlegm: messagesPhlegm,
    physact: messagesPhysact,
    prnmed: messagesPrnmed,
    prnmedAf: messagesPrnmedAf,
    prnmedCi: messagesPrnmedCi,
    prnmedCopd: messagesPrnmedCopd,
    prnmedLvad: messagesPrnmedLvad,
    pulse: messagesPulse,
    pumpFlowLvad: messagesPumpFlowLvad,
    pumpPowerLvad: messagesPumpPowerLvad,
    pumpPulseIndexLvad: messagesPumpPulseIndexLvad,
    pumpSelfTestLvad: messagesPumpSelfTestLvad,
    pumpSpeedLvad: messagesPumpSpeedLvad,
    respiratoryProblems: messagesRespiratoryProblems,
    sleepHs: messagesSleepHs,
    sleepSci: messagesSleepSCI,
    smoke: messagesSmoke,
    swelling: messagesSwelling,
    swellingHs: messagesSwellingHs,
    tiredness: messagesTiredness,
    vas: messagesVas,
    vegetables: messagesVegetables,
    vocationalActivityHs: messagesVocationalActivity,
    waistCircumference: messagesWaistCircumference,
    weightPd: messagesWeightPd
};
