import {
    useHistory as useRouterHistory,
    useLocation as useRouterLocation,
    useRouteMatch as useRouterMatch
} from 'react-router-dom';
import { RouteHistory, RouteLocation, RouteMatch } from '../types/route-types';

export const useHistory = () => useRouterHistory() as RouteHistory;
export const useLocation = () => useRouterLocation() as RouteLocation;
export const useRouteMatch = () => useRouterMatch() as RouteMatch;
