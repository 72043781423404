import React, { useRef } from 'react';
import { useInactiveTabDetector } from '../../hooks/use-inactive-tab-detector';
import IdleTimer from 'react-idle-timer';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { LogoutMessage } from '../../pages/auth/logout/componens/LogoutCompleted';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import { routeLogout } from '../../pages/auth/logout/route';
import { Strings } from '../../locale/messagesDescriptors';
import { formatMessage } from '../../locale/format/format-message';

const timeInMilliseconds = parseInt(__APP_CONFIG__.IDLE_TIMER);

const TIME_UNTIL_IDLE = timeInMilliseconds * 60 * 10;

export const IdleCounter = React.memo(() => {
    const { hidden } = useInactiveTabDetector();
    const intervalRef = useRef<any | null>(null);
    const timerRef = useRef<any | null>(null);
    const modalRef = useRef<any | null>(null);
    const secondsToGo = useRef(30);
    const idleTimerRef = useRef<IdleTimer | null>(null);
    const routeHistory = useHistory<LogoutMessage>();
    const videoMeeting = useReduxSelector((state) => state.videoMeeting);

    const showCountdownModal = () => {
        modalRef.current = Modal.confirm({
            title: formatMessage(Strings.authText.main.idleTitle),
            content: formatMessage(Strings.authText.main.idleCountdown, {
                secondsToGo: secondsToGo.current
            }),
            icon: 'warning',
            onOk: () => {
                clearInterval(intervalRef.current);
                clearTimeout(timerRef.current);
                secondsToGo.current = 30;
            },
            okText: formatMessage(Strings.authText.main.idleOkText),
            onCancel: () => {
                clearInterval(intervalRef.current);
                clearTimeout(timerRef.current);
                secondsToGo.current = 30;
                routeHistory.push(routeLogout.link);
            },
            cancelText: formatMessage(Strings.authText.main.logOut)
        });
    };

    const startCountDown = () => {
        intervalRef.current = setInterval(() => {
            secondsToGo.current -= 1;
            modalRef.current.update({
                content: formatMessage(Strings.authText.main.idleCountdown, {
                    secondsToGo: secondsToGo.current
                })
            });
        }, 1000);

        timerRef.current = setTimeout(() => {
            clearInterval(intervalRef.current);
            Modal.destroyAll();
            routeHistory.push({
                pathname: routeLogout.link,
                state: {
                    message: formatMessage(
                        Strings.authText.main.loggedOutDueToInactivity
                    )
                }
            });
        }, secondsToGo.current * 1000);
    };

    const onIdle = () => {
        if (idleTimerRef.current && videoMeeting.session) {
            // This works, but should be done in a more generic way
            idleTimerRef.current.reset();
        } else if (hidden) {
            routeHistory.push({
                pathname: routeLogout.link,
                state: {
                    message: formatMessage(
                        Strings.authText.main.loggedOutDueToInactivity
                    )
                }
            });
        } else {
            showCountdownModal();
            startCountDown();
        }
    };

    return (
        <div>
            <IdleTimer
                ref={idleTimerRef}
                onIdle={onIdle}
                timeout={TIME_UNTIL_IDLE}
            />
        </div>
    );
});
