import { RouteItemType } from '../../../utils/route/switch-routes';
import { MedicOverviewPage } from './MedicOverviewPage';
import { pathMedicOverview, pathMedicOverviewGroupId } from './path';
import { titleMedicOverview } from './title';

export const routeMedicOverview: RouteItemType = {
    name: titleMedicOverview,
    link: pathMedicOverview,
    links: [pathMedicOverviewGroupId, pathMedicOverview],
    icon: 'team',
    component: MedicOverviewPage
};
