import { defineMessages } from 'react-intl';

export const header = defineMessages({
    title: {
        id: 'patient-data.tabs.conversations.tab.title',
        defaultMessage: 'Message history'
    }
});

export const conversationsSection = defineMessages({
    title: {
        id: 'patient-data.tabs.conversation.section.title',
        defaultMessage: 'Conversation groups'
    },
    noConversations: {
        id: 'patient-data.tabs.conversation.section.conversations.no-conversation',
        defaultMessage: 'No conversations'
    }
});

export const messagesSection = defineMessages({
    noMessages: {
        id: 'patient-data.tabs.conversation.section.messages.no-messages',
        defaultMessage: 'No messages'
    }
});

export const conversation = {
    header,
    section: {
        conversations: conversationsSection,
        messages: messagesSection
    }
};
