import React from 'react';
import { List } from 'antd';
import { Watchover } from './history-items/Watchover';
import { ResetPriorityItem } from './history-items/ResetPriorityItem';
import { UserStatusChange } from './history-items/UserStatusChange';
import { HistoryItemProps } from './HistoryPanelContent';
import { Feedback } from './history-items/Feedback';

export const HistoryItem = ({
    event,
    patient
}: HistoryItemProps): JSX.Element => {
    const { type, eventId } = event;
    const itemByType = {
        watchover: <Watchover event={event} />,
        reset_priority: <ResetPriorityItem event={event} />,
        user_status_change: <UserStatusChange event={event} />,
        feedback: <Feedback event={event} patient={patient} />
    };

    return <List.Item key={eventId}>{itemByType[type]}</List.Item>;
};
