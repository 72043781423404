import React, { Component } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import sortBy from 'lodash/sortBy';
import {
    getFilteredPriorityPatients,
    getLastUpdated
} from '../../../../../redux/domains/priority/priority.selectors';
import { fetchWatchoversForGroup } from '../../../../../redux/domains/watchovers/watchovers.actions';
import { fetchMissedReports } from '../../../../../redux/domains/reminders/reminders.actions';
import withSizes from 'react-sizes';
import { getIndicatorsForConditionWithSettings } from '../../../../../redux/domains/indicators/indicators.selectors';
import { isMedicAdmin } from '../../../../../redux/domains/groups/groups.selectors';
import { getCondition } from '../../../../../redux/domains/conditions/conditions.selectors';
import { FETCH_MISSED_REPORTS } from '../../../../../redux/domains/reminders/reminders.constants';
import { createLoadingSelector } from '../../../../../redux/domains/requests/requests.selectors';
import { getColumns } from './columns';
import PriorityTableHeader from './PriorityTableHeader';
import { getPriorityTableSettings } from '../../../../../redux/domains/settings/settings.selectors';
import { PriorityTableSearchBar } from '../PriorityTableSearchBar';
import PatientFilter from './PatientFilter';
import { fetchPriorityList } from '../../../../../redux/domains/priority/priority.actions';
import { ReduxState } from '../../../../../redux/redux-state.type';
import { testSelectors } from '../../../../../constants/test-id';
import { PriorityPatient } from '../../../../../redux/domains/priority/priority.type';
import { styled } from '../../../../../theme/styled';
import { IndicatorWithSettings } from '../../../../../redux/domains/indicators/indicator.type';

type Props = {
    className?: string;
    indicators: IndicatorWithSettings[];
    settings: { order: string[]; hidden: string[] };
    groupId: string;
    conditionId: string;
    fetchMissedReports: typeof fetchMissedReports;
    lastUpdated: number;
    unreadMessagesFilter: Function;
    searchFilter: any;
    isMedicAdmin: boolean;
    patients: PriorityPatient[];
    height?: number;
    loading: boolean;
    fetchPriorityList: () => Promise<any>;
    fetchWatchoversForGroup: () => Promise<any>;
    ssn: string | null;
};

class PriorityTable extends Component<Props> {
    getVisibleIndicators = () => {
        const { indicators, settings } = this.props;
        const { order, hidden } = settings;

        const visibleIndicators = indicators.filter(
            (indicator: any) => !hidden.includes(indicator.code)
        );

        if (!order.length) return visibleIndicators;

        return sortBy(visibleIndicators, (indicator) => {
            return order.indexOf(indicator.code);
        });
    };

    calculateBreakWidth = () => {
        const { indicators, settings } = this.props;
        const margin = 30;

        const visibleIndicators = indicators.filter(
            (indicator: any) => !settings.hidden.includes(indicator.code)
        );

        return visibleIndicators.length * 80 + 605 + margin;
    };

    onRefreshTable = () => {
        this.props.fetchPriorityList();
        this.props.fetchWatchoversForGroup();
        this.props.fetchMissedReports(
            this.props.groupId,
            this.props.conditionId
        );
    };

    renderTableTitle = () => {
        const { lastUpdated } = this.props;

        return (
            <TableTitle>
                <PriorityTableHeader
                    refreshTable={this.onRefreshTable}
                    lastUpdated={lastUpdated}
                />
                <PatientFilter
                    filterPatients={this.props.unreadMessagesFilter}
                />
                <PriorityTableSearchBar />
            </TableTitle>
        );
    };

    render() {
        const { groupId, isMedicAdmin, height = 320, ssn } = this.props;
        const visibleIndicators = this.getVisibleIndicators();
        const columns = getColumns(groupId, visibleIndicators, isMedicAdmin);

        return (
            <Table
                className={this.props.className}
                data-test-id={testSelectors.page.patientPrioTable}
                title={this.renderTableTitle}
                columns={columns}
                dataSource={this.props.patients}
                rowKey={(patient) => patient.meta.externalId}
                rowClassName={(record) => {
                    if (record.meta.personal_id?.replace('-', '') === ssn) {
                        return Style.active;
                    }
                    return '';
                }}
                pagination={false}
                scroll={{
                    x: this.calculateBreakWidth(),
                    y: height - 320
                }}
                loading={this.props.loading}
            />
        );
    }
}

const TableTitle = styled.div`
    display: flex;
    padding-left: 0.5rem;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: ${(props) =>
            props.theme.layout.mediaQuerySize.width.laptop}) {
        flex-direction: column;
        justify-content: unset;

        > div:not(:last-child) {
            margin-bottom: 20px;
        }
    }
`;

enum Style {
    active = 'active'
}

const StyledPriorityTable = styled(PriorityTable)`
    margin-bottom: 0;

    && .ant-table-header {
        overflow: auto;
    }

    && .ant-table-body {
        overflow: auto !important;
    }

    tbody {
        tr {
            td {
                font-size: 14px;
                padding: 8px 16px;
                height: 60px;

                &.highlighted {
                    background-color: #cfffc5 !important;
                }
            }
        }
        tr.active {
            td {
                border-top: dashed 2px black;
                border-bottom: dashed 2px black;
            }
            td:first-child {
                border-left: dashed 2px black;
            }
            td:last-child {
                border-right: dashed 2px black;
            }
        }
    }

    .ant-table-title {
        padding-top: 0;
    }
`;

const missedReportsLoading = createLoadingSelector([FETCH_MISSED_REPORTS]);

const makeMapStateToProps = (
    state: ReduxState,
    props: { groupId: string; conditionId: string }
) => ({
    patients: getFilteredPriorityPatients(state),
    indicators: getIndicatorsForConditionWithSettings(state),
    condition: getCondition(state, props),
    lastUpdated: getLastUpdated(state, props),
    loading:
        state.ui.overview.priorityListLoading ||
        state.ui.overview.conditionsLoading ||
        missedReportsLoading(state),
    settings: getPriorityTableSettings(state),
    isMedicAdmin: isMedicAdmin(state)
});

const StyledPriorityTableWithSizes = withSizes<{ height?: number }, Props>(
    ({ height }) => ({
        height
    })
)(StyledPriorityTable);

export default connect(makeMapStateToProps, {
    fetchMissedReports,
    fetchPriorityList,
    fetchWatchoversForGroup
})(StyledPriorityTableWithSizes);
