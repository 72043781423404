import { defineMessages } from 'react-intl';

const fields = defineMessages({
    ssn: {
        id: 'profile.fields.ssn',
        defaultMessage: 'Personal identification number'
    },
    medicId: {
        id: 'profile.fields.medic-id',
        defaultMessage: 'Caregiver ID'
    }
});

const basic = defineMessages({
    add: {
        id: 'medic.basic.groups.add',
        defaultMessage:
            'Do you want to give the co-worker patient access to this group?'
    },
    remove: {
        id: 'medic.basic.groups.remove',
        defaultMessage:
            'Do you want to remove the patient access from the co-worker in this group?'
    }
});

const admin = defineMessages({
    add: {
        id: 'medic.admin.groups.add',
        defaultMessage:
            'Do you want to give the co-worker administration rights for this group?'
    },
    remove: {
        id: 'medic.admin.groups.remove',
        defaultMessage:
            'Do you want to remove the administration rights for the co-worker for this group?'
    }
});

const patient = defineMessages({
    access: {
        id: 'medic.patient.group.medic-access',
        defaultMessage: 'Patient access'
    },
    admin: {
        id: 'medic.patient.group.admin-access',
        defaultMessage: 'Administrator'
    }
});

export const details = {
    fields,
    medic: {
        admin,
        basic
    },
    patient
};
