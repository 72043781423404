import React from 'react';
import styled from 'styled-components';
import { TrendData } from './trend-data.type';

export const DataItem = (
    props: TrendData & {
        addMargin: boolean;
    }
): JSX.Element => {
    const title = props.title;
    const value = props.value;
    const unit = props.unit;

    return (
        <DataWrapper addMargin={props.addMargin}>
            {title != null && <Title>{title}</Title>}
            {value != null && <Value>{value}</Value>}
            {unit != null && <Unit>{unit}</Unit>}
        </DataWrapper>
    );
};

const Title = styled.h4``;

const Value = styled.span`
    font-weight: bold;
    margin-right: 5px;
    color: #4266ff;
    display: inline-block;

    &:first-letter {
        text-transform: uppercase;
    }
`;

const Unit = styled.span``;

const DataWrapper = styled.div<{ addMargin: boolean }>`
    margin-top: ${(props) => (props.addMargin ? '15px' : 0)};
    margin-bottom: ${(props) => (props.addMargin ? '15px' : 0)};
`;
