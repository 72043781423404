import { createSelector } from 'reselect';
import get from 'lodash/get';
import { getSelectedConditionCode } from '../conditions/conditions.selectors';
import { ReduxState } from '../../redux-state.type';
import { ConditionRules } from '../../../conditions/condition.type';

export type ConditionSettings = {
    [key: string]: ConditionRules;
};

const getSettings = (state: ReduxState) => state.settings;

export const priorityTableSettings = (
    settings: ConditionSettings,
    conditionCode: string | null
) => {
    if (settings && conditionCode) {
        return settings[conditionCode].priorityTable;
    } else {
        return settings.default.priorityTable;
    }
};

const getReasonsSettingsForCondition = (
    settings: ConditionSettings,
    conditionCode?: string | null
) => {
    if (conditionCode) {
        return settings[conditionCode]?.reasons?.status_change;
    } else {
        return settings.default.reasons?.status_change;
    }
};

export const getPriorityTableSettings = createSelector(
    [getSettings, getSelectedConditionCode],
    priorityTableSettings
);

export const getReasonsStatusChangeSettings = createSelector(
    [getSettings, getSelectedConditionCode],
    getReasonsSettingsForCondition
);

export const getSettingsByPath = (path: string, conditionCode?: string) =>
    createSelector(
        [
            getSettings,
            conditionCode ? () => conditionCode : getSelectedConditionCode,
            () => path
        ],
        getMergedSettings
    );

const getMergedSettings = (
    settings: ConditionSettings,
    conditionCode: string | null,
    path: string
): any => {
    return conditionCode
        ? get(settings[conditionCode], path, settings[conditionCode])
        : get(settings.default, path, settings.default);
};
