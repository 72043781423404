import base from './base';
import heatMap from './chart/heat-map';
import graph from './chart/graph';
import ui from './ui';
import theme from './profile';
import { getRGBA } from './functions';

const colors = {
    base,
    chart: {
        heatMap,
        graph
    },
    ui
};

// leaving for backwards compatibility after renaming to colors
const color = {
    base,
    graph
};

export {
    colors as default,
    color,
    colors,
    theme,
    getRGBA,
    base,
    heatMap,
    graph,
    ui
};
