const hex2rgb = (hex: string) => {
    const long = /^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i.exec(hex);
    if (long) {
        return long.slice(1, 4).map(function (x: string) {
            return parseInt(x, 16);
        });
    }

    const short = /^#([0-9a-f])([0-9a-f])([0-9a-f])$/i.exec(hex);
    if (short) {
        return short.slice(1, 4).map(function (x: string) {
            return 0x11 * parseInt(x, 16);
        });
    }
    return null;
};

export const getRGBA = (hex: string, alpha: number) => {
    if (alpha != null) {
        return `rgba(${hex2rgb(hex)}, ${alpha})`;
    }
    return `rgba(${hex2rgb(hex)}, 1)`;
};
