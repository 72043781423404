import React, { useEffect, useRef } from 'react';
import { Condition } from '../../../../../redux/domains/conditions/conditions.type';
import { HeatmapContainer } from '../../../../../components/charts/heatmap/HeatmapContainer';
import { IndicatorWithSettings } from '../../../../../redux/domains/indicators/indicator.type';
import { GraphWithRedux } from '../../../../../components/charts/graph/GraphWithRedux';
import { ActivePatient } from '../../../../../redux/domains/active-patient/active-patient.type';
import { useDispatch, useSelector } from 'react-redux';
import { useSettings } from '../../../../../hooks/use-settings';
import { splitChartIndicators } from './split-chart-indicators';
import {
    graphInitializeSettings,
    graphReset
} from '../../../../../redux/domains/chart/graph/chart.graph.actions';
import {
    getChartDateFilters,
    getValuesByIndicator
} from '../../../../../redux/domains/values/values.selectors';
import {
    setSelectedDate,
    sharedReset
} from '../../../../../redux/domains/chart/shared/chart.shared.actions';
import { AppNeutralButton } from '../../../../../components/ui/buttons/AppNeutralButton';
import { useReduxSelector } from '../../../../../hooks/use-redux-selector';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { fetchPriorityListByGroupId } from '../../../../../redux/domains/priority/priority.actions';
import { fetchMissedReports } from '../../../../../redux/domains/reminders/reminders.actions';

export const ChartsTab = (props: {
    patient: ActivePatient;
    condition: Condition;
    indicatorsWithSettings: IndicatorWithSettings[];
}) => {
    const dispatch: any = useDispatch();
    const mountRef = useRef(false);

    const condition = props.condition;
    const conditionId = props.condition.id;
    const conditionConfiguration = props.patient.conditionConfiguration;
    const indicatorsWithSettings = props.indicatorsWithSettings;

    const settingsMapping = useSettings('indicators', condition.code);

    const charts = splitChartIndicators({
        indicators: indicatorsWithSettings,
        settingsMapping: settingsMapping
    });

    const graphIndicators = charts.graph;
    const heatmapIndicators = charts.heatmap;

    const valuesByIndicator = useSelector(getValuesByIndicator);
    const selectedDate = useReduxSelector(
        (state) => state.charts.shared.selectedDate
    );

    const selectedPatientConditionCode: string = useReduxSelector(
        (state) => state.selectedPatient.groupIds[0]
    );

    const externalId: string = useReduxSelector(
        (state) => state.selectedPatient.externalId
    );

    const xAxisRange = useSelector(getChartDateFilters);

    useEffect(() => {
        if (!mountRef.current) {
            dispatch(
                graphInitializeSettings({
                    condition: condition,
                    conditionConfiguration: conditionConfiguration,
                    indicatorSettingsByCode: settingsMapping,
                    indicatorsByCode: graphIndicators,
                    values: valuesByIndicator
                })
            ).then(() => (mountRef.current = true));
        }
    }, [
        dispatch,
        condition,
        conditionConfiguration,
        graphIndicators,
        settingsMapping,
        valuesByIndicator
    ]);

    useEffect(() => {
        dispatch(fetchMissedReports(selectedPatientConditionCode, conditionId));
        dispatch(
            fetchPriorityListByGroupId({
                patientGroupId: selectedPatientConditionCode,
                externalId: externalId
            })
        );
        return () => {
            dispatch(graphReset());
            dispatch(sharedReset());
            dispatch(
                fetchPriorityListByGroupId({
                    patientGroupId: null,
                    externalId: null
                })
            );
        };
    }, [conditionId, dispatch, externalId, selectedPatientConditionCode]);

    return (
        <>
            {selectedDate ? (
                <AppNeutralButton
                    onClick={() => {
                        dispatch(setSelectedDate(null));
                    }}
                    style={{ marginLeft: '22px' }}
                >
                    {formatMessage(Strings.graph.general.removeTimeMarker)}
                </AppNeutralButton>
            ) : null}

            <GraphWithRedux
                settingsMapping={settingsMapping}
                chartIndicatorsWithSettings={graphIndicators}
                xAxisDateRange={xAxisRange}
            />
            <HeatmapContainer
                heatmapIndicators={heatmapIndicators}
                indicatorConfigurations={conditionConfiguration[conditionId]}
                dateRange={xAxisRange}
                settingsMapping={settingsMapping}
            />
        </>
    );
};
