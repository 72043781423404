import { RouteItemType } from '../../../../../utils/route/switch-routes';
import { pathCreatePatientStep2 } from './path';
import { Step2Wrapper } from './Step2Wrapper';

export const routeCreatePatientStep2: RouteItemType = {
    name: '',
    link: pathCreatePatientStep2,
    icon: 'user-add',
    component: Step2Wrapper,
    exact: true
};
