export enum UserStatus {
    Uncomfirmed = 'uncomfirmed',
    Active = 'active',
    Inactive = 'inactive'
}

export type UserMeta = {
    email: string;
    family_name: string;
    name: string;
    personal_id?: string;
    phone_number: string;
};

export type Username = string;

export type BaseUserMeta = UserMeta & {
    username: Username;
};

export type MedicID = string;
export type ExternalId = string;
export type MedicExternalId = ExternalId;
export type PatientExternalId = ExternalId;

export type BaseUser = {
    externalId: ExternalId;
    meta: BaseUserMeta;
};
