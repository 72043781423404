import React from 'react';
import { Col, Row } from 'antd';
import { CustomImage } from '../../../components/image/custom-image/CustomImage';
import { ContentCardLayout } from '../../../components/content/ContentLayout';

import { appLogos } from '../../../constants/app-logos';
import {
    RouteItemType,
    SwitchRoutes
} from '../../../utils/route/switch-routes';
import { routeSamlConsume } from './pages/consume/route-saml-consume';

const samlRoutes: RouteItemType[] = [routeSamlConsume];

export default function SAMLPage(): JSX.Element {
    return (
        <SAMLPageContent>
            <SwitchRoutes routes={samlRoutes} />
        </SAMLPageContent>
    );
}

const SAMLPageContent = ({ children }: any) => (
    <Row
        type="flex"
        justify="center"
        style={{
            height: '30%',
            paddingTop: 200
        }}
    >
        <Col>
            <ContentCardLayout>
                <div className="oauth-container">
                    <div className="oauth-logo-container">
                        <CustomImage
                            src={appLogos.standardLogo.src}
                            alt={appLogos.standardLogo.alt}
                            style={{ width: 100, height: 100 }}
                        />
                    </div>
                    <div className="oauth-crontent-container">
                        <div className="title">
                            <h2>LifePod</h2>
                            <h5>by Cross Technology Solutions AB</h5>
                            <hr />
                        </div>
                        {children}
                    </div>
                </div>
            </ContentCardLayout>
        </Col>
    </Row>
);
