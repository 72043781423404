import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/calendar';
import 'echarts/lib/chart/heatmap';
import {
    BOWEL_DYSFUNCTION,
    MEDICATION_CI,
    PHLEGM,
    PHYSACT,
    PRNMED,
    PRNMED_CI,
    PRNMED_COPD,
    RESPIRATORY_PROBLEMS,
    SMOKE,
    SWELLING
} from '../../../../../../conditions/indicator-meta';
import { IndicatorWithSettings } from '../../../../../../redux/domains/indicators/indicator.type';
import { DataPoint } from '../../../chart-types';
import { sumByDay } from '../../../../../../conditions/shared-indicators/smoke/utils/sum-by-day';

export function getValuesForIndicator(props: {
    indicator: IndicatorWithSettings;
    values: DataPoint[];
}): DataPoint[] {
    const getValuesByProperty = (
        property: string,
        getValue = (obj: any) => obj[property]
    ) =>
        props.values
            .map(([date, obj]): DataPoint => [date, obj && getValue(obj)])
            .filter(([, value]) => value != null);

    switch (props.indicator.code) {
        case MEDICATION_CI.code: {
            const customValidator = (obj: any) => {
                const { anticoagulant } = obj;
                const antiLipoproteins = obj['anti-lipoproteins'];
                if (anticoagulant === 0 || antiLipoproteins === 0) {
                    return 0;
                }
                if (anticoagulant === -1 && antiLipoproteins === -1) {
                    return -1;
                }
                return 1;
            };
            return getValuesByProperty(
                MEDICATION_CI.properties.anticoagulant,
                customValidator
            );
        }
        case PHYSACT.code: {
            const customValidator = (obj: any) => {
                const { duration, intensity } = obj;
                return intensity;
            };
            return getValuesByProperty(
                PHYSACT.properties.intensity,
                customValidator
            );
        }
        case SWELLING.code:
            return getValuesByProperty(SWELLING.properties.degree);
        case PRNMED.code:
        case PRNMED_CI.code:
        case PRNMED_COPD.code:
            return getValuesByProperty(PRNMED_COPD.properties.dose);
        case PHLEGM.code: {
            const customValidator = (obj: any) => {
                const { color, blood } = obj;
                if (blood) return 5;
                switch (color) {
                    case 3:
                        return 4;
                    case 2:
                        return 3;
                    case 1:
                        return 2;
                    default:
                        return 1;
                }
            };
            return getValuesByProperty(
                PHLEGM.properties.status,
                customValidator
            );
        }
        case RESPIRATORY_PROBLEMS.code: {
            const customValidator = (obj: any) => {
                const { frequency } = obj;

                switch (true) {
                    case frequency >= 25:
                        return 5;
                    case frequency > 20 && frequency < 25:
                        return 4;
                    case frequency > 10 && frequency < 20:
                        return 3;
                    case frequency === 10:
                        return 2;
                    case frequency >= 5 && frequency < 10:
                        return 1;
                    default:
                        return 0;
                }
            };
            return getValuesByProperty(
                RESPIRATORY_PROBLEMS.properties.status,
                customValidator
            );
        }

        case BOWEL_DYSFUNCTION.code: {
            const customValidator = (sum: number) => {
                switch (true) {
                    case sum >= 14:
                        return 5;
                    case sum >= 10:
                        return 4;
                    case sum >= 7:
                        return 3;
                    case sum >= 0:
                        return 2;
                    default:
                        return 1;
                }
            };
            return getValuesByProperty(
                BOWEL_DYSFUNCTION.properties.status,
                customValidator
            );
        }

        case SMOKE.code: {
            return sumByDay(props.values);
        }
        default:
            return props.values;
    }
}
