import React from 'react';
import { Markdown } from '../../../locale/format/format-markdown';
import { formatMessage } from '../../../locale/format/format-message';
import { LoginWrapper } from '../login/components/LoginWrapper';
import { messagesSso } from './messages';

export const SsoError = (): JSX.Element => {
    return (
        <LoginWrapper title={formatMessage(messagesSso.error.title)}>
            <p>{formatMessage(messagesSso.error.info)}</p>
            <Markdown source={formatMessage(messagesSso.error.support)} />
        </LoginWrapper>
    );
};
