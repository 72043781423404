import React, { PropsWithChildren } from 'react';
import SVG from 'react-inlinesvg';
import CrossCard from '../../../../components/shared/styled/CrossCard';
import { StyledAuth } from '../../../../styled/styled-auth';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { Redirect } from 'react-router-dom';
import { DemoBanner } from '../../../../components/banner/DemoBanner';
import { appLogos } from '../../../../constants/app-logos';
import { useCheckAuthentication } from '../../../../hooks/use-check-authentication';

export const LoginWrapper = (
    props: PropsWithChildren<{ title: string }>
): JSX.Element => {
    const { STAGE } = __APP_CONFIG__;
    const isAuthenticated = useReduxSelector(
        (state) => state.authentication.isAuthenticated
    );

    useCheckAuthentication();

    if (isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: '/'
                }}
            />
        );
    }

    return (
        <StyledAuth>
            <CrossCard
                title={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <h1 style={{ fontSize: 20 }}>{props.title}</h1>{' '}
                        <SVG
                            src={appLogos.standardLogoSvg.src}
                            style={{ width: 25, height: 25 }}
                        />
                    </div>
                }
                style={{
                    width: 400
                }}
            >
                {props.children}
            </CrossCard>
            {STAGE === 'demo' && <DemoBanner />}
        </StyledAuth>
    );
};
