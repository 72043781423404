import { Indicator } from '../../../../types/indicator/indicator.type';
import { CATProperties } from './cat-properties';
import { rules } from './cat-rules';

import icon from './images/cat.svg';
import { messagesCat } from './messages/messages';

export const parameterCat = {
    code: 'CAT',
    properties: CATProperties
};

export const cat: Indicator = {
    code: parameterCat.code,
    icon,
    messages: messagesCat,
    rules
};
