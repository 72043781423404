import React from 'react';
import { List } from 'antd';
import { useReduxSelector } from '../../../../../../../hooks/use-redux-selector';
import {
    Condition,
    IndicatorConfigurations
} from '../../../../../../../redux/domains/conditions/conditions.type';
import { ReportReminder } from '../../../../../../../redux/domains/reminders/reminders.type';
import IndicatorListItem from './IndicatorListItem';
import { IndicatorWithSettings } from '../../../../../../../redux/domains/indicators/indicator.type';
import { useSettings } from '../../../../../../../hooks/use-settings';

type Props = {
    condition?: Condition | null;
    indicators: IndicatorWithSettings[];
    indicatorConfigurations: IndicatorConfigurations;
    reportReminders: Record<string, ReportReminder>;
    isReadonly?: boolean;
};

const IndicatorList = (props: Props) => {
    const {
        condition,
        indicators,
        indicatorConfigurations,
        reportReminders,
        isReadonly
    } = props;
    const settingsMapping = useSettings('indicatorSettings', condition?.code);

    const patient = useReduxSelector((state) => state.selectedPatient);

    if (!patient) {
        return <></>;
    }

    return (
        <List
            dataSource={indicators}
            renderItem={(indicator: any) => {
                return (
                    <IndicatorListItem
                        patient={patient}
                        indicator={indicator}
                        indicatorsSettings={settingsMapping}
                        indicatorConfiguration={
                            indicatorConfigurations[indicator.id]
                        }
                        reportReminder={reportReminders[indicator.id]}
                        conditionId={condition?.id || patient.conditionIds[0]}
                        isReadonly={isReadonly}
                    />
                );
            }}
        />
    );
};

export default IndicatorList;
