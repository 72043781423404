import { defineMessages, MessageDescriptor } from 'react-intl';

const unknown = defineMessages({
    message: {
        id: 'error.unknown.something-wrong',
        defaultMessage: 'Something went wrong.'
    },
    description: {
        id: 'error.unknown.something-wrong-2',
        defaultMessage: 'Please try again later'
    }
});

const general = defineMessages({
    message: {
        id: 'error.general.message',
        defaultMessage: 'Something went wrong'
    },
    description: {
        id: 'error.general.description',
        defaultMessage: 'Try again later or please contact support'
    }
});

export const httpErrors: Record<string, Record<string, MessageDescriptor>> = {
    e400: defineMessages({
        message: {
            id: 'http.error.400.message',
            defaultMessage: 'Bad request'
        },
        description: {
            id: 'http.error.400.description',
            defaultMessage: 'Try again later'
        }
    }),
    e403: defineMessages({
        message: {
            id: 'http.error.403.message',
            defaultMessage: 'Forbidden action'
        },
        description: {
            id: 'http.error.403.description',
            defaultMessage:
                "You don't have permission to perform this operation"
        }
    }),
    e409: defineMessages({
        message: {
            id: 'http.error.409.message',
            defaultMessage: 'This patient is already registered in LifePod'
        },
        description: {
            id: 'http.error.409.description',
            defaultMessage:
                'Verify that the personal identification number is correct. If the patient has LifePod for another health condition, please contact LifePod Support.'
        }
    }),
    e409medic: defineMessages({
        message: {
            id: 'http.error.409.medic.message',
            defaultMessage: 'This co-worker is already registered in LifePod'
        },
        description: {
            id: 'http.error.409.medic.description',
            defaultMessage:
                'Verify that the information you provided is correct. If the co-worker is or has been working with LifePod at another care unit within this healthcare provider, please contact LifePod Support.'
        }
    }),
    e413: defineMessages({
        message: {
            id: 'http.error.413.message',
            defaultMessage: 'Payload too large'
        },
        description: {
            id: 'http.error.413.description',
            defaultMessage: 'To many results'
        }
    })
};

export const errorMessages = {
    unknown,
    general
};
