export const waitFor = (ms: number) => new Promise((r) => setTimeout(r, ms));

export async function asyncForEach<T>(
    array: T[],
    callback: (itemInList: T, index: number, array: T[]) => Promise<void>
) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}
