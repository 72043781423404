import { Card } from 'antd';
import React from 'react';
import { ContentLayout } from '../../../components/content/ContentLayout';
import { usePageTitle } from '../../../hooks/use-page-title';
import { Markdown } from '../../../locale/format/format-markdown';
import { formatMessage } from '../../../locale/format/format-message';
import { messages } from './messages';
import { titleUneducatedStart } from './title';

export const UneducatedStart = (): JSX.Element => {
    usePageTitle(titleUneducatedStart);

    return (
        <ContentLayout>
            <Card>
                <h2 style={{ marginBottom: 20 }}>
                    {formatMessage(messages.uneducatedStart.subtitle)}
                </h2>
                <div style={{ maxWidth: 600 }}>
                    <Markdown
                        source={formatMessage(messages.uneducatedStart.ce)}
                    />
                    <Markdown
                        source={formatMessage(
                            messages.uneducatedStart.menuInfo
                        )}
                    />
                    <Markdown
                        source={formatMessage(
                            messages.uneducatedStart.testInfo
                        )}
                    />
                    <Markdown
                        source={formatMessage(messages.uneducatedStart.support)}
                    />
                    <Markdown
                        source={formatMessage(messages.uneducatedStart.welcome)}
                    />
                </div>
            </Card>
        </ContentLayout>
    );
};
