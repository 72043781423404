import { publicAxios } from '../../../services/http-client/public-http-client';
import { HTTPClient } from '../../../services/http-client/http-client';
import { createHashHistory } from 'history';
import { checkUrl } from '../../../utils/url/check-url';
import { getHost } from '../../../utils/url/get-host';
import { EidAuthMethods } from './authentication.enums';
import { AuthTokens } from '../../../utils/token/token-service';
import { Roles } from '../user/user.api.type';

const appConfig = __APP_CONFIG__;

const host = checkUrl(getHost());
const clientId = appConfig.OAUTH2_CLIENT_ID;

type LoginResponseType = {
    data: AuthTokens;
};

export const postLoginUser = (params: { username: string; password: string }) =>
    publicAxios
        .post('authentication/login', {
            ...params,
            role: Roles.medic
        })
        .then((response: LoginResponseType) => response.data);

export const postLogoutUser = (idToken: string | null) =>
    publicAxios
        .post(`authentication/logout`, { idToken })
        .then(({ data }) => data);

export const generateSingleSignOnUrl = (payload: any) =>
    HTTPClient.post('authentication/login/SMART', {
        ...payload,
        action: 'launch',
        clientId: clientId,
        redirectUrl: `${host}#/oauth2/tokens`
    })
        .then((data) => {
            window.location.href = data.redirectUri;
        })
        .catch((err) => {
            console.log('error status', err.response.status);
            console.log('error message', err.response.data.message);
        });

export const loginWithIdentityProvider = (payload: any) =>
    HTTPClient.post('authentication/login/SMART', {
        ...payload,
        action: 'tokens'
    })
        .then((res) => res)
        .catch((err) => {
            if (err.response) {
                const { status, code, message } = err.response.data;
                const errorState = {
                    status,
                    code,
                    message
                };
                const location = {
                    pathname: `/exception/${status}`,
                    state: errorState
                };
                createHashHistory().push(location);
                return err;
            } else {
                console.log('no error response');
                return err;
            }
        });

export const getLifePodJWKs = () =>
    publicAxios.get(
        `${appConfig.API_ROOT_URL}authentication/.well-known/jwks.json`
    );

export const generateAuthRequest = () => {
    const redirectUrl = `${checkUrl(getHost())}#/saml/consume`;

    return publicAxios
        .get(`${appConfig.SAML_URL}`, {
            params: {
                redirectUrl,
                authMethod: 'siths'
            }
        })
        .then(({ data }) => {
            window.location.href = data.authUrl;
        })
        .catch((err) => {
            console.log('error status', err.response.status);
            console.log('error message', err.response.data.message);
        });
};

export const eidLogin = (authMethod: EidAuthMethods, qr?: true) => {
    const callbackUrl =
        window.location.href.split('#')[0] + '#/?authmethod=' + authMethod;

    return publicAxios
        .post('authentication/eid/login', {
            callbackUrl,
            authMethod,
            qr
        })
        .then(({ data }) => {
            window.location.href = data.redirectUrl;
        });
};

export const eidGetSession = (sessionId: string, authMethod: EidAuthMethods) =>
    publicAxios
        .get(
            `authentication/eid/session/${sessionId}/authmethod/${authMethod}/role/medic`
        )
        .then((response: LoginResponseType) => response.data);

export const oAuthLogin = (sessionId: string) =>
    publicAxios
        .get(`authentication/oauth/login/${sessionId}`)
        .then((response: LoginResponseType) => response.data);
