import { extractPartsFromSSN } from './extract-parts-from-ssn';
import { isValidDate } from './is-valid-date';
import { createLuhnChecksum } from './create-luhn-checksum';

export const isValidSwedishSSN = (
    ssn: number | string,
    checkForDash?: boolean
) => {
    if (typeof ssn !== 'number' && typeof ssn !== 'string') {
        return false;
    }

    if (checkForDash) {
        if (`${ssn}`.includes('-')) {
            return false;
        }
    }

    const ssnAsString = `${ssn}`; //Stringify

    //Get parts from ssn
    const parts = extractPartsFromSSN(ssnAsString);

    if (!parts) return false;

    //Calculate luhn checksum
    const luhnSsn = parts.year + parts.month + parts.day + parts.num;
    const check = createLuhnChecksum(luhnSsn);

    //Check that there is a check part in ssn and that it matches the result from the luhn checksum
    const validSsn = !!parts.check && check === +parts.check;

    //Validate dates
    const validDate = isValidDate(
        parts.century,
        parts.year,
        parts.month,
        parts.day
    );

    return validSsn && validDate;
};
