import React from 'react';
import { Divider } from 'antd';
import MessageNotifier from './MessageNotifier';
import { ActionPanelWrapper } from '../../../../../../components/action-panel/ActionPanelWrapper';
import { PriorityPatient } from '../../../../../../redux/domains/priority/priority.type';

type Props = {
    patient: PriorityPatient;
    isMedicAdmin: boolean;
};

export default function Actions(props: Props) {
    const { name, family_name, externalId } = props.patient.meta;
    const fullname = `${name} ${family_name}`;

    return (
        <span style={{ textAlign: 'center' }}>
            <MessageNotifier
                name={fullname}
                externalId={externalId}
                disabled={props.isMedicAdmin}
            />
            <Divider type="vertical" />
            <ActionPanelWrapper name={fullname} externalId={externalId} />
        </span>
    );
}
