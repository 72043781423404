import React from 'react';
import { NormalizedPatientType } from '../../../../redux/domains/active-patient/active-patient.utils';
import { PhoneLink } from '../../../../components/patient/header/PhoneLink';
import { EmailLink } from '../../../../components/patient/header/EmailLink';
import { Divider } from 'antd';
import {
    StyledContactIcon,
    StyledUserMeta,
    StyledHeader,
    StyledPersonalId
} from '../../../../components/patient/header/styled';

export const EditPatientGroupInformation = (props: {
    patient: NormalizedPatientType;
}): JSX.Element => {
    const patient = props.patient;

    return (
        <div>
            <StyledHeader>
                {patient.meta.name} {patient.meta.family_name}
            </StyledHeader>
            <StyledUserMeta>
                <StyledPersonalId>{patient.meta.personal_id}</StyledPersonalId>
            </StyledUserMeta>
            <StyledUserMeta>
                <StyledContactIcon type="phone" />
                <PhoneLink phone={patient.meta.phone_number} />
            </StyledUserMeta>
            <StyledUserMeta>
                <StyledContactIcon type="mail" />
                <EmailLink email={patient.meta.email} />
            </StyledUserMeta>
            <Divider style={{ margin: '8px 0', height: 1 }} />
        </div>
    );
};
