import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Modal } from 'antd';
import {
    getMedicsInGroup,
    isCurrentUserAdminInGroup
} from '../../../../redux/domains/groups/groups.selectors';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import {
    email,
    familyName,
    firstName,
    medicTitle,
    phoneNumber,
    getRoles,
    status,
    getSwitchStatus
} from './columns';
import { StyledTable } from '../../../../components/ui/table/Table';
import { MedicUser } from '../../../../redux/domains/medic/medic.type';
import { MedicGroupId } from '../../../../redux/domains/groups/groups.type';
import {
    MedicExternalId,
    UserStatus
} from '../../../../redux/domains/user/user.type';
import { toggleMedic } from '../../../../redux/domains/medic/medic.actions';
import { getActivePatientsByMedicGroupId } from '../../../../redux/domains/patient-administration/patient-administration.selectors';
import { medicGroupListId } from './MedicGroupList';
import { routeProfile } from '../../profile/route';
import { medicOverview } from '../messages';
import { formatMessage } from '../../../../locale/format/format-message';

export const MedicList = (): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();
    const routeParams = useParams<{ medicGroupId: MedicGroupId }>();
    const selectedMedicGroup = routeParams.medicGroupId;
    const hasGroups = useReduxSelector(
        (state) => Object.keys(state.groups.medicGroups.byId).length >= 0
    );
    const medics = useReduxSelector((state) =>
        selectedMedicGroup
            ? getMedicsInGroup(state, { medicGroupId: selectedMedicGroup })
            : []
    );
    const isAdmin = useReduxSelector((state) =>
        isCurrentUserAdminInGroup(state, { medicGroupId: selectedMedicGroup })
    );
    const medicUsers = useReduxSelector((state) => state.groups.medicUsers);
    const hasActivePatients = useReduxSelector(
        (state) =>
            Object.keys(
                getActivePatientsByMedicGroupId(state, {
                    medicGroupId: selectedMedicGroup
                })
            ).length > 0
    );
    const isOnlyActiveMedic = useMemo(
        () =>
            Object.keys(
                medics.filter((medic) => medic.status === UserStatus.Active)
            ).length === 1,
        [medics]
    );
    const changeStatus = (medicId: MedicExternalId, checked: boolean) => {
        if (isOnlyActiveMedic && !hasActivePatients && !checked) {
            Modal.error({
                title: formatMessage(
                    medicOverview.error.deactivationNotAllowedTitle
                ),
                content: formatMessage(
                    medicOverview.error.deactivationNotAllowedContent
                )
            });
            return;
        }
        dispatch(toggleMedic(medicId, checked));
    };

    const columns = [
        familyName,
        firstName,
        medicTitle,
        getRoles(selectedMedicGroup),
        status,
        email,
        phoneNumber
    ];

    if (isAdmin) {
        columns.splice(5, 0, getSwitchStatus(medicUsers, changeStatus));
    }

    return (
        <div style={{ width: getWidth(hasGroups) }}>
            <StyledTable
                $clickable={isAdmin}
                columns={columns}
                dataSource={medics}
                rowKey={(medic: MedicUser) => medic.externalId}
                pagination={false}
                loading={false}
                scroll={{
                    x: 'max-content'
                }}
                onRow={(record: MedicUser) => {
                    return {
                        onClick: () => onRowClick(record)
                    };
                }}
            />
        </div>
    );

    function onRowClick(record: MedicUser) {
        if (isAdmin) {
            history.push(`${routeProfile.medicLink(record.externalId)}`);
        }
    }
};

function getWidth(hasGroups: boolean) {
    return !hasGroups
        ? '100%'
        : `calc(100% - ${
              window.document.getElementById(medicGroupListId)?.offsetWidth || 0
          }px)`;
}
