import { css, Keyframes, keyframes } from 'styled-components';

export const keyLeft = (from: string, to: string): Keyframes => {
    return keyframes`
    from {
        left: ${from};
    }
    to {
        left: ${to};
    }
`;
};

export const calcWidthBySubtract = (subtract: string) => {
    return `calc(100% - ${subtract})`;
};

export const keyWidthSubtract = (from: string, to: string): Keyframes => {
    return keyframes`
    from {
        width: ${from};
    }
    to {
        width: ${to};
    }
`;
};

export const keyOpacity = (from: string, to: string): Keyframes => {
    return keyframes`
    from {
        opacity: ${from};
    }
    to {
        opacity: ${to};
    }
`;
};

export const animationCss = (
    key: Keyframes,
    sec = '0.2',
    transition = 'ease'
) =>
    css`
        animation: ${key} ${sec}s ${transition};
    `;
