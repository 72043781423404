import { MedicGroupsById } from '../../redux/domains/groups/groups.type';

export function sortMedicGroups(medicGroups: MedicGroupsById) {
    if (Object.keys(medicGroups).length === 0) {
        throw new Error('No medic groups fetched');
    }

    const sortedMedicGroups = Object.values(medicGroups).sort((a, b) =>
        a.name.localeCompare(b.name)
    );

    return sortedMedicGroups;
}
