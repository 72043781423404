import React from 'react';
import { Redirect } from 'react-router-dom';
import {
    RouteItemType,
    SwitchRoutes
} from '../../../utils/route/switch-routes';
import { pathPatientAdministration } from './administration/path';
import { routePatientAdministration } from './administration/route';
import { pathPatientAdministrationSwitch } from './path';
import { routeCreatePatient } from '../create/route';

const administrationRoutes: RouteItemType[] = [
    routeCreatePatient,
    routePatientAdministration
];

export const PatientAdministrationPage = (): JSX.Element => {
    return (
        <SwitchRoutes routes={administrationRoutes}>
            <Redirect
                from={pathPatientAdministrationSwitch}
                to={pathPatientAdministration}
            />
        </SwitchRoutes>
    );
};
