import { createSelector } from 'reselect';
import { flow, map, filter } from 'lodash/fp';
import { orderBy } from 'lodash';

const eventsById = (state: any) => state.events.byId;
const allEventIds = (state: any) => state.events.allIds;

export const getWatchoverEvents = createSelector(
    [eventsById, allEventIds],
    (events, ids) => {
        return flow(
            map((id: string) => events[id]),
            filter((event) => event.type === 'watchover')
        )(ids);
    }
);

export const getResetEvents = createSelector(
    [eventsById, allEventIds],
    (events, ids) => {
        return flow(
            map((id: string) => events[id]),
            filter((event) => event.type === 'reset_priority')
        )(ids);
    }
);

export const getUserStatusChangeEvents = createSelector(
    [eventsById, allEventIds],
    (events, ids) => {
        return flow(
            map((id: string) => events[id]),
            filter((event) => event.type === 'user_status_change')
        )(ids);
    }
);

function getLatestEvent(events: any[]) {
    return events.reduce((prev, curr) => {
        return prev.dtCreated > curr.dtCreated ? prev : curr;
    }, {});
}

export const getFeedbackEvents = createSelector(
    [eventsById, allEventIds],
    (events, ids) => {
        return flow(
            map((id: string) => events[id]),
            filter((event) => event.type === 'feedback')
        )(ids);
    }
);

export const getLatestReset = createSelector([getResetEvents], (events) =>
    getLatestEvent(events)
);

export const getLatestEncourage = createSelector(
    [getFeedbackEvents],
    (events) => getLatestEvent(events)
);

export const getLogEvents = createSelector(
    [
        getWatchoverEvents,
        getResetEvents,
        getUserStatusChangeEvents,
        getFeedbackEvents
    ],
    (watchoverEvents, resetEvents, userStatusChangeEvents, feedbackEvents) => {
        const mergedArray = watchoverEvents
            .concat(resetEvents)
            .concat(userStatusChangeEvents)
            .concat(feedbackEvents);
        return orderBy(mergedArray, ['dtCreated'], ['desc']);
    }
);
