import { Indicator } from '../../../types/indicator/indicator.type';
import { rules } from './body-length-rules';

import icon from './images/body-length.svg';
import { messagesBodyLength } from './messages/messages';

export const parameterBodyLength = {
    code: 'BODY-LENGTH'
};

export const bodyLength: Indicator = {
    code: parameterBodyLength.code,
    icon,
    messages: messagesBodyLength,
    rules
};
