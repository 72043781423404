import React, { PropsWithChildren } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Steps } from 'antd';
import { useLocation } from '../../../hooks/use-router';
import { pathCreatePatientStep1 } from './steps/step-1/path';
import { createSteps } from './steps';
import { pathCreatePatient } from './path';
import {
    RouteItemType,
    SwitchRoutes
} from '../../../utils/route/switch-routes';
import { routeCreatePatientStep1 } from './steps/step-1/route';
import { routeCreatePatientStep2 } from './steps/step-2/route';
import { routeCreatePatientStep3 } from './steps/step-3/route';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { ContentCardLayout } from '../../../components/content/ContentLayout';

const createStepsRoutes: RouteItemType[] = [
    routeCreatePatientStep1,
    routeCreatePatientStep2,
    routeCreatePatientStep3
];

export const CreatePatientStepsWrapper = () => {
    const routeLocation = useLocation();

    return (
        <div>
            <Steps current={getCurrentStep()} style={{ marginBottom: 50 }}>
                <Steps.Step
                    title={formatMessage(
                        Strings.screen.patientAdministration.step1.title
                    )}
                />
                <Steps.Step
                    title={formatMessage(
                        Strings.screen.patientAdministration.step2.title
                    )}
                />
                <Steps.Step
                    title={formatMessage(
                        Strings.screen.patientAdministration.step3.title
                    )}
                />
            </Steps>
            <SwitchRoutes routes={createStepsRoutes}>
                <Redirect
                    exact
                    from={pathCreatePatient}
                    to={pathCreatePatientStep1}
                />
                <Route>{() => <p>Not found</p>}</Route>
            </SwitchRoutes>
        </div>
    );

    function getCurrentStep() {
        return createSteps[routeLocation.pathname] || 0;
    }
};

export const CreatePatientWithChildren = (
    props: PropsWithChildren<unknown>
): JSX.Element => {
    return (
        <ContentCardLayout>
            <div
                style={{
                    marginTop: '10px',
                    paddingLeft: '20%',
                    paddingRight: '20%'
                }}
            >
                {props.children}
            </div>
        </ContentCardLayout>
    );
};
