import { Tooltip } from 'antd';
import React from 'react';
import { HeatmapLegend } from '../../../types/indicator/heatmap-piece.type';
import { DrawRect } from '../../draw/DrawRect';

export const HeatmapLegends = (props: {
    legends: HeatmapLegend[];
    style?: React.CSSProperties;
}): JSX.Element => {
    const style = props.style || { textAlign: 'right' };

    return <div style={style}>{legendTooltip(props)}</div>;
};

function legendTooltip(props: { legends: HeatmapLegend[] }): JSX.Element[] {
    return props.legends.map((item, idx: number) => {
        return (
            <Tooltip key={idx} title={item.text}>
                <div style={{ display: 'inline-block' }}>
                    <DrawRect color={item.color} />
                </div>
            </Tooltip>
        );
    });
}
