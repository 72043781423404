import {
    MessageOfTheDay,
    MotdMessage
} from '../../../redux/domains/motd/motd.types';
import { profiles } from '../../../theme/profile/profiles';

export const noMotds = (motds: MessageOfTheDay[]) => {
    return motds.length === 0;
};

export const hasMotds = (motds: MessageOfTheDay[]) => {
    return motds.length > 0;
};

export const motdColorForType = (type: MotdMessage) => {
    try {
        return profiles.main.motd.motdColorForTypes[type];
    } catch (e) {
        console.warn(`Could not get color for type: ${type}`);
        return profiles.main.motd.motdColorForTypes.info;
    }
};

export const getIconType = (type: MotdMessage) => {
    if (type === 'info') {
        return 'info-circle';
    }
    if (type === 'warning') {
        return 'warning';
    }
    return '';
};
