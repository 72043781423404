import React from 'react';
import { IndicatorIconWithBorder } from '../../../../../../../../components/ui/icons/indicator-icon/IndicatorIcon';
import { Strings } from '../../../../../../../../locale/messagesDescriptors';
import { formatMessageById } from '../../../../../../../../locale/format/format-message';

const TitleWithIcon = ({ indicatorCode }: { indicatorCode: string }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <IndicatorIconWithBorder code={indicatorCode} size={36} />
        <span style={{ marginLeft: 10 }}>
            {formatMessageById(
                `${
                    Strings.indicators.rootId
                }${indicatorCode.toLowerCase()}.title`
            )}
        </span>
    </div>
);

export default TitleWithIcon;
