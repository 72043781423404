import * as actionTypes from './reminders.constants';
import { Reminders } from './reminders.type';

const initialState: Reminders = {
    missedReports: {},
    reportReminders: {}
};

function updateReportReminderEntry(state: Reminders, payload: any) {
    const { data, externalId, indicatorId, reminderId } = payload;
    return {
        ...state,
        reportReminders: {
            ...state.reportReminders,
            [externalId]: {
                ...state.reportReminders[externalId],
                [indicatorId]: state.reportReminders[externalId][
                    indicatorId
                ].map((reminder: any) => {
                    if (reminder.id === reminderId) {
                        return {
                            ...reminder,
                            ...data
                        };
                    }
                    return reminder;
                })
            }
        }
    };
}

export default function reminderReducer(state = initialState, action: any) {
    const { payload, type } = action;

    switch (type) {
        case actionTypes.FETCH_MISSED_REPORTS_FULFILLED:
            return { ...state, missedReports: payload };
        case actionTypes.FETCH_REPORT_REMINDERS_FULFILLED:
            return {
                ...state,
                reportReminders: { ...state.reportReminders, ...payload }
            };
        case actionTypes.UPDATE_REPORT_REMINDERS_FULFILLED:
            return updateReportReminderEntry(state, payload);
        default:
            return state;
    }
}
