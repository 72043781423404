import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Extra medication',
        id: 'indicator.prnmed.title'
    },
    values: {
        defaultMessage:
            '{dose, plural, one {{dose} dose} other {{dose} doses}} {medication}',
        id: 'indicator.prnmed.values'
    },
    legendNo: {
        defaultMessage: 'No doses',
        id: 'indicator.prnmed.legend.no'
    },
    legendYes: {
        defaultMessage: 'One or more doses',
        id: 'indicator.prnmed.legend.yes'
    }
});

const dose = defineMessages({
    title: {
        defaultMessage: 'Dose',
        id: 'indicator.prnmed.dose.title'
    },
    description: {
        defaultMessage: 'Number of doses exceeding prescription',
        id: 'indicator.prnmed.dose.description'
    },
    unit: {
        id: 'indicator.prnmed.dose.unit',
        defaultMessage: '{value, plural, one {dose} other {doses}}'
    }
});

const medication = defineMessages({
    title: {
        defaultMessage: 'Medication',
        id: 'indicator.prnmed.medication.title'
    },
    description: {
        defaultMessage: 'Type of medicine',
        id: 'indicator.prnmed.medication.description'
    },
    diuretic: {
        defaultMessage: 'diuretic',
        id: 'indicator.prnmed.medication.choices.diuretic'
    },
    nitroglycerine: {
        defaultMessage: 'nitroglycerine',
        id: 'indicator.prnmed.medication.choices.nitroglycerine'
    }
});

const choices = defineMessages({
    diuretic: {
        defaultMessage: 'Diuretic',
        id: 'indicator.prnmed.choices.diuretic'
    },
    nitroglycerine: {
        defaultMessage: 'Nitroglycerine',
        id: 'indicator.prnmed.choices.nitroglycerine'
    }
});

export const messagesPrnmed = {
    general,
    dose,
    medication,
    choices
};
