import { keyBy, sortBy } from 'lodash';
import { createSelector, OutputSelector } from 'reselect';
import { getSelectedCondition } from '../conditions/conditions.selectors';
import { Condition } from '../conditions/conditions.type';
import { getSettingsByPath } from '../settings/settings.selectors';
import {
    IndicatorsById,
    IndicatorWithSettings,
    NormalizedIndicators
} from './indicator.type';
import { ReduxState } from '../../redux-state.type';
import { Settings } from '../settings/settings.type';
import { formatMessageById } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

type State = {
    indicators: NormalizedIndicators;
};

export const getIndicators = (state: State): IndicatorsById =>
    state.indicators.byId;

export const getIndicatorsByCode = createSelector(getIndicators, (indicators) =>
    keyBy(indicators, 'code')
);

const formatCode = (code: string) => code.split('-').join('_');

type IndicatorsForConditionReselectType = OutputSelector<
    ReduxState,
    IndicatorWithSettings[],
    (
        res1: IndicatorsById,
        res2: Condition,
        res3: Settings
    ) => IndicatorWithSettings[]
>;

export const makeGetIndicatorsForConditionWithSettings = (
    condition?: Condition
): IndicatorsForConditionReselectType => {
    const getMergedIndicatorSettings = getSettingsByPath(
        'indicators',
        condition?.code
    );
    return createSelector(
        [
            getIndicators,
            condition ? () => condition : getSelectedCondition,
            getMergedIndicatorSettings
        ],
        (indicators, condition, mergedIndicatorSettings) => {
            if (!condition) return [];

            return sortBy(
                condition.indicators.map((id) => {
                    const indicator = indicators[id];
                    const { code } = indicator;
                    const title = formatMessageById(
                        `${
                            Strings.indicators.rootId
                        }${code.toLowerCase()}.title`
                    );
                    return {
                        ...indicator,
                        title: title,
                        transformedCode: formatCode(code),
                        settings: mergedIndicatorSettings[code]
                    };
                }),
                'title'
            );
        }
    );
};

// get indicators for current condition when conditionId is not sent as props to component.
export const getIndicatorsForConditionWithSettings =
    makeGetIndicatorsForConditionWithSettings();
