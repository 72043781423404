import { Indicator } from '../../../../types/indicator/indicator.type';
import { rules } from './blood-pressure-lvad-rules';

import icon from './images/blood-pressure-average.svg';
import { messagesBloodPressureLvad } from './mesages/messages';

export const parameterBloodPressureLVAD = {
    code: 'BLOOD-PRESSURE-LVAD'
};

export const bloodPressureAverage: Indicator = {
    code: parameterBloodPressureLVAD.code,
    icon,
    messages: messagesBloodPressureLvad,
    rules
};
