import { defineMessages } from 'react-intl';

const activate = defineMessages({
    initialActivation: {
        defaultMessage: 'Initial activation',
        id: 'status-change.activate.initial_activation'
    },
    noReason: {
        defaultMessage: 'No Reason',
        id: 'status-change.activate.no_reason'
    },
    patientContinues: {
        defaultMessage: 'Patient continues',
        id: 'status-change.activate.patient_continues'
    },
    vacationBack: {
        defaultMessage: 'Back from vacation',
        id: 'status-change.activate.vacation_back'
    },
    hospitalDischarged: {
        defaultMessage: 'Discharged from hospital',
        id: 'status-change.activate.hospital_discharged'
    },
    movedFromOtherCaregiver: {
        defaultMessage: 'Moved from other caregiver',
        id: 'status-change.activate.moved_from_other_caregiver'
    },
    other: {
        defaultMessage: 'Other',
        id: 'status-change.activate.other'
    }
});

const deactivate = defineMessages({
    diagnosisChanged: {
        defaultMessage: 'Diagnosis changed',
        id: 'status-change.deactivate.diagnosis_changed'
    },
    deceased: {
        defaultMessage: 'Deceased',
        id: 'status-change.deactivate.deceased'
    },
    patientDeclines: {
        defaultMessage: 'Patient declines',
        id: 'status-change.deactivate.patient_declines'
    },
    studyCompleted: {
        defaultMessage: 'Study completed',
        id: 'status-change.deactivate.study_completed'
    },
    tooIll: {
        defaultMessage: 'Too ill',
        id: 'status-change.deactivate.too_ill'
    },
    vacation: {
        defaultMessage: 'Vacation',
        id: 'status-change.deactivate.vacation'
    },
    hospitalization: {
        defaultMessage: 'Hospitalization',
        id: 'status-change.deactivate.hospitalization'
    },
    movedToOtherCaregiver: {
        defaultMessage: 'Moved to other caregiver',
        id: 'status-change.deactivate.moved_to_other_caregiver'
    },
    treatmentFinished: {
        defaultMessage: 'Treatment finished',
        id: 'status-change.deactivate.treatment_finished'
    },
    other: {
        defaultMessage: 'Other',
        id: 'status-change.deactivate.other'
    }
});

export const statusChange = { activate, deactivate };
