import React, { useState } from 'react';
import { StyledTable } from '../../ui/table/Table';
import { Strings } from '../../../locale/messagesDescriptors';
import {
    MedicGroupId,
    MedicGroupsById
} from '../../../redux/domains/groups/groups.type';
import { MedicExternalId } from '../../../redux/domains/user/user.type';
import { alphabetic } from '../../../utils/sort/alphabetic';
import { formatMessage } from '../../../locale/format/format-message';
import { Checkbox, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { updateMedicGroups } from '../../../redux/domains/medic/medic.actions';
import { deleteUserFromGroupAction } from '../../../redux/domains/groups/groups.actions';
import { details } from './message-descriptors';
import { Roles } from '../../../redux/domains/user/user.api.type';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { getAdminGroupsForCurrentUser } from '../../../redux/domains/groups/groups.selectors';
import { CareUnit, PatientAccess, Admin } from './table-columns';
import { getUpdatedPendingGroups } from './get-updated-pending-groups';

export type GroupRowType = {
    id: MedicGroupId;
    careUnit: string;
    roles: string;
    access: JSX.Element;
    admin: JSX.Element;
};

export const MedicGroupsEdit = (props: {
    groupsById: MedicGroupsById;
    medicGroups: MedicGroupId[];
    adminGroups: MedicGroupId[];
    medicId: MedicExternalId;
}) => {
    const dispatch = useDispatch();

    const { adminGroups, medicGroups, medicId } = props;

    const medicIdForCurrentUser = useReduxSelector(
        (state) => state.user.externalId
    );
    const adminGroupsForCurrentUser = useReduxSelector(
        getAdminGroupsForCurrentUser
    );

    const alllGroupsById = useReduxSelector(
        (state) => state.groups.medicGroups.byId
    );

    const [pendingBasicGroups, setPendingBasicGroups] =
        useState<string[]>(medicGroups);
    const [pendingAdminGroups, setPendingAdminGroups] =
        useState<string[]>(adminGroups);

    return (
        <StyledTable
            style={{ marginBottom: 20 }}
            $clickable={false}
            columns={[CareUnit, PatientAccess, Admin]}
            dataSource={adminGroupsForCurrentUser
                .map((groupId: MedicGroupId) => {
                    const disabled = medicIdForCurrentUser === medicId;
                    const checked = pendingBasicGroups.includes(groupId);
                    const careUnit = `${alllGroupsById[groupId]?.name}`;

                    return {
                        id: groupId,
                        key: groupId,
                        careUnit: careUnit,
                        patientAccess: (
                            <Checkbox
                                disabled={disabled}
                                checked={checked}
                                style={{ textAlign: 'center' }}
                                onChange={(event) => {
                                    const checked = event.target.checked;
                                    const modalTitle = getModalTitle(groupId);

                                    showModalConfirm(modalTitle, () => {
                                        setPendingBasicGroups(
                                            getUpdatedPendingGroups({
                                                checked,
                                                pendingGroups:
                                                    pendingBasicGroups,
                                                groupId
                                            })
                                        );

                                        const accessRoles = [];

                                        if (checked) {
                                            accessRoles.push(Roles.medic);
                                        }

                                        if (
                                            pendingAdminGroups.includes(groupId)
                                        ) {
                                            accessRoles.push(Roles.admin);
                                        }

                                        dispatchAccessRoles({
                                            id: groupId,
                                            accessRoles
                                        });
                                    });
                                }}
                            />
                        ),
                        admin: (
                            <Checkbox
                                disabled={disabled}
                                checked={pendingAdminGroups.includes(groupId)}
                                style={{ textAlign: 'center' }}
                                onChange={(event) => {
                                    const checked = event.target.checked;

                                    const modalTitle =
                                        pendingAdminGroups.includes(groupId)
                                            ? formatMessage(
                                                  details.medic.admin.remove
                                              )
                                            : formatMessage(
                                                  details.medic.admin.add
                                              );

                                    showModalConfirm(modalTitle, () => {
                                        setPendingAdminGroups(
                                            getUpdatedPendingGroups({
                                                checked,
                                                pendingGroups:
                                                    pendingAdminGroups,
                                                groupId
                                            })
                                        );

                                        const accessRoles = [];

                                        if (
                                            pendingBasicGroups.includes(groupId)
                                        ) {
                                            accessRoles.push(Roles.medic);
                                        }
                                        if (checked) {
                                            accessRoles.push(Roles.admin);
                                        }

                                        dispatchAccessRoles({
                                            id: groupId,
                                            accessRoles
                                        });
                                    });
                                }}
                            />
                        )
                    };
                })
                .sort((a, b) => alphabetic(a.careUnit, b.careUnit))}
            pagination={false}
            loading={false}
        />
    );

    function getModalTitle(groupId: string) {
        if (pendingBasicGroups.includes(groupId)) {
            return formatMessage(details.medic.basic.remove);
        }
        return formatMessage(details.medic.basic.add);
    }

    function showModalConfirm(title: string, onOk: () => void) {
        Modal.confirm({
            title,
            okText: formatMessage(Strings.common.general.yes),
            cancelText: formatMessage(Strings.common.general.no),
            onOk
        });
    }

    function dispatchAccessRoles(params: {
        id: string;
        accessRoles: string[];
    }) {
        const { id, accessRoles } = params;

        const post = {
            medicId: medicId,
            groupId: id,
            data: { roles: accessRoles }
        };

        if (!accessRoles.length) {
            dispatch(
                deleteUserFromGroupAction({
                    groupId: id,
                    medicId
                })
            );
        } else {
            dispatch(updateMedicGroups(post));
        }
    }
};
