import { Indicator } from '../../../../types/indicator/indicator.type';
import { rules } from './daily-activity-hs-rules';

import icon from './images/daily-activity.svg';
import { messagesDailyActivity } from './messages/messages';

export const parameterDailyActivity = {
    code: 'DAILY-ACTIVITY-HS'
};

export const dailyActivity: Indicator = {
    code: parameterDailyActivity.code,
    icon,
    messages: messagesDailyActivity,
    rules
};
