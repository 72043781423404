import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Body Temperature',
        id: 'indicator.body-temp.title'
    },
    value: {
        id: 'indicator.body-temp.value',
        defaultMessage: '{value, number, ::.0}'
    },
    unit: {
        id: 'indicator.body-temp.unit',
        defaultMessage: '°C'
    }
});

export const messagesBodyTemp = {
    general
};
