import {
    FETCH_PATIENT_DATA_CONVERSATIONS,
    FETCH_PATIENT_DATA_GROUPS,
    FETCH_PATIENT_DATA_MEDIC_GROUPS,
    FETCH_PATIENT_DATA_MESSAGES,
    RESET_PATIENT_PROFILE_CONVERSATIONS,
    SET_PATIENT_DATA_CONVERSATION_ID
} from './patient-data.constants';
import { getGroups } from '../groups/groups.api';
import { getConversations, getMessages } from '../chat/chat.api';
import { MedicGroupsById, PatientGroupsById } from '../groups/groups.type';

export const fetchPatientDataGroups = (groupIds: string[]) => ({
    type: FETCH_PATIENT_DATA_GROUPS,
    payload: getGroups<PatientGroupsById>(groupIds)
});

export const fetchPatientDataMedicGroups = (groupIds: string[]) => ({
    type: FETCH_PATIENT_DATA_MEDIC_GROUPS,
    payload: getGroups<MedicGroupsById>(groupIds)
});

export const resetPatientDataConversations = (): { type: string } => ({
    type: RESET_PATIENT_PROFILE_CONVERSATIONS
});

export const setConversationId = (selectedConversationId: string) => {
    return {
        type: SET_PATIENT_DATA_CONVERSATION_ID,
        payload: selectedConversationId
    };
};

export const fetchPatientDataConversations =
    (patientId: string) => (dispatch: any) => {
        return dispatch({
            type: FETCH_PATIENT_DATA_CONVERSATIONS,
            payload: getConversations(patientId)
        });
    };

export const fetchPatientDataMessages =
    (conversationId: string) => (dispatch: any) =>
        dispatch({
            type: FETCH_PATIENT_DATA_MESSAGES,
            payload: getMessages(conversationId)
        });
