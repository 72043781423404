import React from 'react';
import { Form, Icon, Input, Radio, Select, Tooltip } from 'antd';
import { PatientGroup } from '../../../../../../redux/domains/groups/groups.type';
import { RegisterPatientValues } from '../../../../../../redux/domains/forms/forms.type';
import { validateSSID } from '../step-1-support';
import { styled } from '../../../../../../theme/styled';
import { formatMessage } from '../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../locale/messagesDescriptors';

const authMethods: Record<string, string> = {
    email: formatMessage(Strings.labels.label.email),
    bankid: formatMessage(Strings.authMethods.general.bankid),
    siths: formatMessage(Strings.authMethods.general.siths)
};

const styles: Record<string, React.CSSProperties> = {
    formItem: {
        marginBottom: 12
    },
    radioGroup: {
        width: '100%'
    },
    radioButton: {
        width: '50%',
        textAlign: 'center'
    }
};

const { PATIENT_AUTH_METHODS, PRESELECTED_PATIENT_AUTH_METHOD } =
    __APP_CONFIG__;

export const buildSSIDField = (
    fieldDecorator: any,
    savedFormValues: RegisterPatientValues
) => {
    const fieldRules = [
        {
            required: true,
            message: formatMessage(
                Strings.form.general.requiredSocialSecurityNumber
            )
        },
        {
            validator: validateSSID
        }
    ];

    return (
        <StyledFormItem
            hasFeedback
            label={formatMessage(Strings.ui.label.general.socialSecurityNumber)}
            style={styles.formItem}
        >
            {fieldDecorator('nSsid', {
                initialValue: savedFormValues.nSsid,
                rules: fieldRules,
                validateTrigger: 'onBlur'
            })(
                <Input
                    style={{ width: '100%' }}
                    placeholder={formatMessage(
                        Strings.form.general.pinPlaceholder
                    )}
                    autoComplete="new-ssid"
                    autoFocus
                />
            )}
        </StyledFormItem>
    );
};

export const buildFirstNameField = (
    fieldDecorator: any,
    savedFormValues: RegisterPatientValues
) => {
    const fieldRules = [
        {
            required: true,
            message: formatMessage(Strings.form.general.requiredFirstName)
        },
        {
            whitespace: true,
            message: formatMessage(Strings.form.general.requiredFirstName)
        }
    ];

    return (
        <StyledFormItem
            hasFeedback
            label={formatMessage(Strings.ui.label.general.firstName)}
            style={styles.formItem}
        >
            {fieldDecorator('nFirstName', {
                initialValue: savedFormValues.nFirstName,
                rules: fieldRules
            })(
                <Input
                    style={{ width: '100%' }}
                    placeholder={formatMessage(Strings.form.placeholder.name)}
                    autoComplete="new-first-name"
                />
            )}
        </StyledFormItem>
    );
};

export const buildLastNameField = (
    fieldDecorator: any,
    savedFormValues: RegisterPatientValues
) => {
    const fieldRules = [
        {
            required: true,
            message: formatMessage(Strings.form.general.requiredLastName)
        },
        {
            whitespace: true,
            message: formatMessage(Strings.form.general.requiredLastName)
        }
    ];

    return (
        <StyledFormItem
            hasFeedback
            label={formatMessage(Strings.ui.label.general.lastName)}
            style={styles.formItem}
        >
            {fieldDecorator('nLastName', {
                initialValue: savedFormValues.nLastName,
                rules: fieldRules
            })(
                <Input
                    style={{ width: '100%' }}
                    placeholder={formatMessage(
                        Strings.form.placeholder.lastName
                    )}
                    autoComplete="new-last-name"
                />
            )}
        </StyledFormItem>
    );
};

export const buildEmailField = (
    fieldDecorator: any,
    savedFormValues: RegisterPatientValues
) => {
    const fieldRules = [
        {
            type: 'email',
            message: formatMessage(Strings.form.general.validEmail),
            required: true
        },
        {
            message: formatMessage(Strings.form.general.requiredEmail)
        }
    ];

    return (
        <StyledFormItem
            hasFeedback
            label={
                <span>
                    {formatMessage(Strings.labels.label.email)}
                    &nbsp;
                    <Tooltip
                        title={formatMessage(
                            Strings.form.tooltip.emailRegistration
                        )}
                    >
                        <Icon type="question-circle" />
                    </Tooltip>
                </span>
            }
            style={styles.formItem}
        >
            {fieldDecorator('nEmail', {
                initialValue: savedFormValues.nEmail,
                rules: fieldRules,
                validateTrigger: 'onBlur'
            })(
                <Input
                    placeholder={formatMessage(Strings.form.placeholder.email)}
                    autoComplete="new-email"
                />
            )}
        </StyledFormItem>
    );
};

export const buildPhoneField = (
    fieldDecorator: any,
    savedFormValues: RegisterPatientValues,
    countryCallingCode: any
) => {
    const fieldRules = [
        {
            message: formatMessage(Strings.form.general.requiredPhone)
        },
        {
            len: 9,
            message: formatMessage(Strings.form.general.validPhoneLength)
        }
    ];

    return (
        <StyledFormItem
            hasFeedback
            label={
                <span>
                    {formatMessage(Strings.ui.label.general.phone)}
                    &nbsp;
                    <Tooltip
                        title={formatMessage(Strings.form.tooltip.smsLink)}
                    >
                        <Icon type="question-circle" />
                    </Tooltip>
                </span>
            }
            style={styles.formItem}
        >
            {fieldDecorator('nPhone', {
                initialValue: savedFormValues.nPhone,
                rules: fieldRules
            })(
                <Input
                    type="tel"
                    style={{ width: '100%' }}
                    addonBefore={countryCallingCode}
                    autoComplete="new-phone"
                />
            )}
        </StyledFormItem>
    );
};

export const buildGroupField = (
    fieldDecorator: any,
    savedFormValues: RegisterPatientValues,
    selectableGroups: PatientGroup[]
) => {
    const fieldRules = [
        {
            required: true,
            message: formatMessage(Strings.form.general.requiredGroup)
        }
    ];
    const options = selectableGroups.map((group) => (
        <Select.Option key={group.id} value={group.id}>
            {group.name}
        </Select.Option>
    ));

    return (
        <StyledFormItem
            label={formatMessage(Strings.ui.label.general.groups)}
            style={styles.formItem}
        >
            {fieldDecorator('nGroups', {
                initialValue: savedFormValues.nGroups,
                rules: fieldRules
            })(
                <Select
                    placeholder={formatMessage(
                        Strings.form.general.selectGroupPlaceholder
                    )}
                    defaultActiveFirstOption={options.length === 1}
                >
                    {options}
                </Select>
            )}
        </StyledFormItem>
    );
};

export const buildAuthField = (
    fieldDecorator: any,
    savedFormValues: RegisterPatientValues
) => {
    const fieldRules = [
        {
            required: true
        }
    ];

    return (
        <StyledFormItem
            label={formatMessage(Strings.ui.label.general.authenticationMethod)}
            style={styles.formItem}
        >
            {fieldDecorator('nAuthMethod', {
                rules: fieldRules,
                initialValue:
                    savedFormValues.nAuthMethod ||
                    PRESELECTED_PATIENT_AUTH_METHOD ||
                    PATIENT_AUTH_METHODS[0]
            })(
                <Radio.Group buttonStyle="solid" style={styles.radioGroup}>
                    {PATIENT_AUTH_METHODS.map((method) => (
                        <Radio.Button
                            key={method}
                            value={method}
                            tabIndex={0}
                            style={styles.radioButton}
                        >
                            {authMethods[method]}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            )}
        </StyledFormItem>
    );
};

export const buildPasswordField = (fieldDecorator: any) => {
    const fieldRules = [
        {
            required: true,
            message: formatMessage(Strings.form.general.requiredPassword)
        }
    ];

    return (
        <StyledFormItem
            hasFeedback
            label={formatMessage(Strings.ui.label.general.password)}
        >
            {fieldDecorator('nPassword', {
                rules: fieldRules
            })(
                <Input
                    type="text"
                    style={{ width: '100%' }}
                    autoComplete="new-password"
                />
            )}
        </StyledFormItem>
    );
};

const StyledFormItem = styled(Form.Item)`
    & .ant-form-item-label {
        line-height: 30px;
    }
`;
