import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { CLEAR_LATEST_VALUES } from './values.constants';
import { Values } from './values.type';
import {
    fetchLatestValues,
    fetchValues,
    setDateFilter
} from './values.actions';

const initialState: Values = {
    params: {
        subjectId: ''
    },
    indicators: {},
    latestReports: [],
    filters: {}
};

const valueReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fetchValues.pending, (state, action) => {
            if (state.params.subjectId !== action.meta.arg.subjectId)
                state.params = initialState.params;
        })
        .addCase(fetchValues.fulfilled, (state, { payload, meta }) => {
            state.params = meta.arg;
            state.indicators = keyBy(payload, 'indicatorId');
        })
        .addCase(fetchLatestValues.fulfilled, (state, { payload }) => {
            state.latestReports = payload.length ? payload[0].values : [];
        })
        .addCase(CLEAR_LATEST_VALUES, (state) => {
            state.latestReports = [];
        })
        .addCase(setDateFilter, (state, { payload }) => {
            state.filters = payload;
        });
});

export default valueReducer;
