import React, { useMemo, useState } from 'react';
import { Button } from 'antd';
import { saveAs } from 'file-saver';
import { matches } from 'lodash';
import moment from 'moment';
import { toCsv } from 'react-csv-downloader';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { conditions } from '../../../../../../../conditions';
import { useReduxSelector } from '../../../../../../../hooks/use-redux-selector';
import {
    action,
    meta as metaMessages
} from '../../../../../../../locale/messagesDescriptors/export';
import { postAction } from '../../../../../../../redux/domains/actions/actions.api';
import { Actions } from '../../../../../../../redux/domains/actions/actions.enums';
import { getPatient } from '../../../../../../../redux/domains/active-patient/active-patient.selectors';
import { getIndicatorsByCode } from '../../../../../../../redux/domains/indicators/indicators.selectors';
import { getConditionsByMedicGroup } from '../../../../../../../redux/domains/patient-data/patient-data.selectors';
import {
    getDateFilters,
    getFilteredValues,
    getValuesParams
} from '../../../../../../../redux/domains/values/values.selectors';
import { useMedicGroupsForPatient } from './medic-groups';
import { getColumns, getDatas, getMeta } from './export-indicator';
import { Reasons } from '../../../../../../../types/reasons.enum';
import { formatMessage } from '../../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../../locale/messagesDescriptors';

type Props = {
    conditionCode: string;
};

const getValuesLoading = createSelector(
    [getValuesParams, getDateFilters],
    (params, { from = params.from }) => !from || from < (params.from || 0)
);

const getValuesDateRange = createSelector(
    [getValuesParams, getDateFilters],
    (params, { from = params.from, to = params.to }) => [
        from,
        to && Math.min(params.to || to, to)
    ]
);

function useValuesToExport(conditionCode: string) {
    const indicatorsByCode = useSelector(getIndicatorsByCode);
    const values = useSelector(getFilteredValues);

    return useMemo(
        function () {
            const condition = conditions[conditionCode];
            const indicators = condition.indicators
                .map(function ({ code, messages }) {
                    const indicator = indicatorsByCode[code];
                    return {
                        ...indicator,
                        messages: messages || {
                            general: {
                                title: {
                                    id: `indicator.${code.toLowerCase()}.title`
                                }
                            }
                        },
                        values: values[indicator.id]
                    };
                })
                .filter(({ values }) => values && values.length);

            return { ...condition, indicators };
        },
        [conditionCode, indicatorsByCode, values]
    );
}

const getPatientMeta = createSelector(getPatient, (patient) => patient.meta);

const getPatientId = createSelector(
    getPatient,
    (patient) => patient.externalId
);

export default function ExportButton(props: Props): JSX.Element {
    const [loading, setLoading] = useState(false);
    const valuesLoading = useSelector(getValuesLoading);
    const [fromDate, toDate] = useSelector(getValuesDateRange);
    const condition = useValuesToExport(props.conditionCode);
    const patient = useSelector(getPatientMeta);
    const patientId = useSelector(getPatientId);
    const medicId = useReduxSelector((state) => state.user.externalId);
    const clinics = useMedicGroupsForPatient();
    const conditionsByGroup = useSelector(getConditionsByMedicGroup);

    async function downloadExportedValues() {
        if (!condition) {
            return;
        }
        const isConditionToExport = matches({ code: condition.code });
        const clinicsForCondition = clinics.filter((clinic) =>
            conditionsByGroup[clinic.id].some(isConditionToExport)
        );

        const formattedDateRange = formatMessage(metaMessages.dateRange, {
            from: moment(fromDate).format('YYYY-MM-DD'),
            to: moment(toDate).format('YYYY-MM-DD')
        });

        await postAction({
            proxyId: medicId,
            externalId: patientId,
            action: Actions.PATIENT_DATA_EXPORTED,
            reason: Reasons.OTHER,
            note: `${condition.name} ${formattedDateRange}`
        });

        const filename =
            [
                Strings.constants.lifepodDetails.name,
                patient.personal_id,
                ...formattedDateRange.split(' ')
            ].join('_') + '.txt';

        const conditionMeta = [
            formatMessage(metaMessages.condition),
            condition.nameLong
        ];

        const separator = '\t';
        const nl = '\r\n';

        const csvs = await Promise.all(
            condition.indicators.map(async (indicator) => [
                '',
                getMeta(indicator).join(separator),
                (await toCsv({
                    columns: getColumns(indicator),
                    datas: getDatas(indicator),
                    separator
                })) as string
            ])
        );

        const bomCode = '\ufeff';
        const txt = [
            ...clinicsForCondition.map((clinic) => [
                formatMessage(metaMessages.careUnit),
                clinic.name
            ]),
            conditionMeta
        ]
            .map((meta) => meta.join(separator))
            .concat(...csvs)
            .join(nl);

        const blob = new Blob([`${bomCode}${txt}`], {
            type: 'text/plain;charset=utf-8'
        });
        saveAs(blob, filename);
    }

    return (
        <Button
            icon="download"
            disabled={!condition || condition.indicators.length == 0}
            loading={valuesLoading || clinics.length == 0 || loading}
            onClick={async () => {
                setLoading(true);
                await downloadExportedValues();
                setLoading(false);
            }}
        >
            {formatMessage(action.title)}
        </Button>
    );
}
