import React, { useState } from 'react';
import { message, Button } from 'antd';
import moment from 'moment';

import { GeneralPanelWrapper } from '../GeneralPanelWrapper';
import { watchover } from './message-descriptors';
import { Strings } from '../../../../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../../../../locale/format/format-message';
import { useDispatch } from 'react-redux';
import { setActionPanelLatest } from '../../../../../../../../redux/domains/action-panel/action-panel.actions';

export const WatchedOverPanelItem = (props: {
    latestDateForWatchover: number;
    disabled: boolean;
    latestWatchover: {
        dtCreated: number;
        practitioner_name: string;
        practitioner_family_name: string;
    };
    registerWatchover: () => Promise<void>;
}): JSX.Element => {
    const disabled = props.disabled;
    const latestWatchover = props.latestWatchover;

    const dispatch = useDispatch();

    const { practitioner_name, practitioner_family_name } = latestWatchover;

    const [isLoadingWatchover, setIsLoadingWatchover] = useState(false);

    const fullName = practitioner_name
        ? formatMessage(Strings.common.general.mostRecentlyBy, {
              name: `${practitioner_name} ${practitioner_family_name}`
          })
        : null;
    const fromNow = props.latestDateForWatchover
        ? moment(props.latestDateForWatchover).fromNow()
        : formatMessage(watchover.general.notCheckedYet);

    return (
        <GeneralPanelWrapper
            title={formatMessage(watchover.general.title)}
            practitioner={fullName}
            data={fromNow}
        >
            <Button
                type="primary"
                style={{ width: '100%' }}
                onClick={handleClick}
                disabled={disabled}
                loading={isLoadingWatchover}
            >
                {formatMessage(watchover.general.title)}
            </Button>
        </GeneralPanelWrapper>
    );

    function handleClick() {
        setIsLoadingWatchover(true);

        const hideMessage = message.loading(
            formatMessage(Strings.events.general.register),
            0
        );
        props
            .registerWatchover()
            .then(hideMessage)
            .then(() =>
                message.success(
                    formatMessage(watchover.general.registeredWatchover),
                    2
                )
            )
            .then(() => {
                setIsLoadingWatchover(false);
                dispatch(
                    setActionPanelLatest({
                        latestDateForReset: null,
                        latestDateForWatchover: null
                    })
                );
            })
            .catch(() => message.destroy());
    }
};
