import React from 'react';
import styled, { keyframes } from 'styled-components';

// Based of https://github.com/tobiasahlin/SpinKit/blob/master/spinkit.css

const SKFold = styled.div<{
    size: number;
    transform: number;
    border_color: string;
}>`
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
    position: relative;
    transform: rotateZ(${(props) => props.transform}deg);
`;

const animateSKFold = () => {
    return keyframes`
    0%, 10% {
    transform: perspective(140px) rotateX(90deg);
    opacity: 0;
  } 25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    transform: perspective(0px) rotateY(0deg);
    opacity: 0;
  }
`;
};

const SKFoldCube = styled.div<{
    rotate: number;
    time: number;
    delay: number;
    color: string;
}>`
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.05) rotateZ(${(props) => props.rotate}deg);
    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.color};
        animation: ${animateSKFold()} ${(props) => props.time}s infinite
            ease-in-out both;
        transform-origin: 100% 100%;
        animation-delay: ${(props) => props.delay}s;
    }
`;

export const CubeWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const CubeFold = (props: { size: number | null; color: string }) => {
    const t = 1.6;
    const d1 = 0.5;
    const d2 = 0.4;
    const d3 = 0.3;
    const d4 = 0.2;
    const size = props.size != null ? props.size : 40;

    return (
        <CubeWrapper>
            <SKFold size={size} transform={45} border_color={props.color}>
                <SKFoldCube
                    rotate={0}
                    time={t}
                    delay={d1}
                    color={props.color}
                />
                <SKFoldCube
                    rotate={90}
                    time={t}
                    delay={d2}
                    color={props.color}
                />
                <SKFoldCube
                    rotate={180}
                    time={t}
                    delay={d3}
                    color={props.color}
                />
                <SKFoldCube
                    rotate={270}
                    time={t}
                    delay={d4}
                    color={props.color}
                />
            </SKFold>
        </CubeWrapper>
    );
};
