import { FETCH_SETTINGS } from './settings.constants';
import { createConditionSettings } from '../../../utils/create-condition-settings/create-condition-settings';
import { Dispatch } from 'redux';

const createSettings = () =>
    new Promise((resolve, reject) => {
        try {
            const settings = createConditionSettings();
            resolve(settings);
        } catch (e) {
            reject(e);
        }
    });

export const fetchSettings = () => (dispatch: Dispatch<any>) =>
    dispatch({
        type: FETCH_SETTINGS,
        payload: createSettings()
    });
