import { formatMessageById } from '../../../../../../../locale/format/format-message';
import { formatNumber } from '../../../../../../../locale/format/format-number';
import { Strings } from '../../../../../../../locale/messagesDescriptors';
import { Property } from '../../../../../../../redux/domains/indicators/indicator.type';
import { capitalizeFirstLetter } from '../../../../../../../utils/string/capitalize-first-letter';

export const getLocaleId = (
    codeToLowerCase: string,
    property: string,
    suffix = ''
) => `${codeToLowerCase}.${property}${suffix}`;

export const getFormattedTitle = (codeToLowerCase: string, property: string) =>
    formatMessageById(
        `${Strings.indicators.rootId}${getLocaleId(
            codeToLowerCase,
            property
        )}.title`
    );

export const getFormattedUnit = (
    codeToLowerCase: string,
    property: string
): string => {
    const unit = formatMessageById(
        `${Strings.indicators.rootId}${getLocaleId(
            codeToLowerCase,
            property
        )}.unit`
    );

    if (unit.includes('{')) {
        return '';
    }
    return unit;
};

export const getFormattedValueWithUnit = (
    value: number,
    unit: string,
    codeToLowerCase: string,
    property: string,
    decimals: Record<string, number> | null
) => {
    if (decimals && decimals[property]) {
        const valueWithDecimalPlaces = formatMessageById(
            `indicator.${codeToLowerCase}.value`,
            { value }
        );
        return `${valueWithDecimalPlaces} ${unit}`;
    }

    const formattedValue = formatNumber(value);
    return `${formattedValue} ${unit}`;
};

export const getFormattedMessage = (
    localeId: string,
    values: string
): string => {
    const message = formatMessageById(
        `${Strings.indicators.rootId}${localeId}`,
        {
            values
        }
    );
    return capitalizeFirstLetter(message);
};

export const getCombinedFormValues = (
    properties: Record<string, Property>,
    code: string,
    decimals: Record<string, number> | null
) => {
    const codeToLowerCase = code.toLowerCase();

    return Object.keys(properties).map((property) => ({
        key: property,
        title: getFormattedTitle(codeToLowerCase, property),
        dataIndex: `value[${property}]`,
        render: (value: string | number | boolean) => {
            return getCellData(value, codeToLowerCase, property, decimals);
        },
        width: 150
    }));
};

export const getCellData = (
    value: string | number | boolean,
    code: string,
    property: string,
    decimals: Record<string, number> | null
) => {
    const localeId = getLocaleId(code, property, '.choices.' + value);
    const unit = getFormattedUnit(code, property);
    const message = formatMessageById(
        `${Strings.indicators.rootId}${localeId}`
    );

    if (typeof value === 'number') {
        return message
            ? getFormattedMessage(localeId, unit)
            : getFormattedValueWithUnit(value, unit, code, property, decimals);
    }

    return typeof value === 'boolean'
        ? getFormattedMessage(localeId, value.toString())
        : getFormattedMessage(localeId, value);
};
