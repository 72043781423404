import { ResponseError } from './response-error';

export const errorHandler = (error: ResponseError, customMessage?: string) => {
    if (customMessage) {
        console.warn('error', customMessage);
    }

    const { response, message } = error;

    if (response && response.data) {
        const { status, message: reason } = response.data;

        throw {
            status,
            message,
            reason
        };
    }

    throw {
        message
    };
};
