import { Button, Card } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentLayout } from '../../components/content/ContentLayout';
import { usePageTitle } from '../../hooks/use-page-title';
import {
    fetchStatistics,
    resetStatistics
} from '../../redux/domains/statistics/statistics.actions';
import { setDateFilter } from '../../redux/domains/values/values.actions';
import { ReduxState } from '../../redux/redux-state.type';
import { DateFilter } from '../patient/data/components/tabs/history/export-data/DateFilter';
import { ConditionsContent } from './components/ConditionsContent';
import { convertDate, getPastMonth } from './components/convertDate';
import { PatientsContent } from './components/PatientsContent';
import { titleStatistics } from './title';
import './export-statistics.css';
import { formatMessage } from '../../locale/format/format-message';
import { CoworkerContent } from './components/CoworkerContent';
import { Strings } from '../../locale/messagesDescriptors';
import {
    StyledDateWrapper,
    StyledSelectedDateTitle
} from './styles/patient-styles';
import {
    StyledContent,
    StyledPatientCount
} from './styles/statistics-page-styles';

export function StatisticsPage() {
    usePageTitle(titleStatistics);

    const dispatch = useDispatch();

    const initialDateRange = useMemo(getPastMonth, []);

    const [newDate, setNewDate] = useState(
        convertDate({
            from: initialDateRange.from,
            to: initialDateRange.to
        })
    );
    const statistics = useSelector((state: ReduxState) => state.statistics);
    const groupId = useSelector(
        (state: ReduxState) => state.groups.selectedMedicGroupId
    );

    useEffect(() => {
        dispatch(
            fetchStatistics({
                id: groupId,
                from: parseInt(newDate.from),
                to: parseInt(newDate.to)
            })
        );
        return () => {
            dispatch(resetStatistics());
        };
    }, [dispatch, groupId, newDate.from, newDate.to]);

    return (
        <div>
            <ContentLayout>
                <Card>
                    {statistics && (
                        <>
                            <StyledPatientCount>
                                {formatMessage(
                                    Strings.statistics.general.currentAmount
                                )}
                                {statistics.numberOfPatientsSinceStart}
                            </StyledPatientCount>

                            <StyledDateWrapper>
                                <StyledSelectedDateTitle>
                                    <h3>
                                        {formatMessage(
                                            Strings.statistics.page
                                                .selectedPeriod
                                        )}
                                    </h3>
                                </StyledSelectedDateTitle>
                                <DateFilter
                                    defaultValue={initialDateRange}
                                    onDateChange={(params) => {
                                        if (params.to && params.from) {
                                            setNewDate(
                                                convertDate({
                                                    from: params.from,
                                                    to: params.to
                                                })
                                            );
                                        }
                                        dispatch(setDateFilter(params));
                                    }}
                                >
                                    <Button
                                        icon="printer"
                                        onClick={() => print()}
                                    >
                                        {formatMessage(
                                            Strings.statistics.general.printPDF
                                        )}
                                    </Button>
                                </DateFilter>
                            </StyledDateWrapper>

                            <StyledContent>
                                <PatientsContent
                                    patients={statistics.patients}
                                />
                                <ConditionsContent
                                    patients={statistics.patients}
                                />
                                <CoworkerContent
                                    medics={statistics.medics}
                                    count={statistics.patients.count}
                                />
                            </StyledContent>
                        </>
                    )}
                </Card>
            </ContentLayout>
        </div>
    );
}
