import { styled } from '../../theme/styled';

export const OuterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    > div {
        margin-right: 20px;

        :last-child {
            margin-right: 0;
        }
    }
`;

export const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    > div {
        margin-bottom: 20px;

        :last-child {
            margin-bottom: 0;
        }
    }
`;
