import React from 'react';
import { Icon, Tooltip } from 'antd';
import {
    Field,
    FieldType
} from '../../../../../../components/ui/field/formik/FormikField';
import { labelWithNonEditableText } from '../../../../../../utils/label/label-with-non-editable-text';
import { formatMessage } from '../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../locale/messagesDescriptors';

export const EmailField = (props: FieldType): JSX.Element => {
    const { value } = props;

    return (
        <Field
            id="email"
            value={value}
            label={
                <span>
                    {labelWithNonEditableText(
                        formatMessage(Strings.labels.label.email)
                    )}
                    <Tooltip
                        title={formatMessage(
                            Strings.form.tooltip.emailEditPrevention
                        )}
                    >
                        <Icon type="question-circle" />
                    </Tooltip>
                </span>
            }
            required
        />
    );
};
