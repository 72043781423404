export enum Reasons {
    PATIENT_ILL = 'patient_ill',
    PATIENT_DEAD = 'patient_dead',
    PATIENT_WELL = 'patient_well',
    PATIENT_RESIGNED = 'patient_resigned',
    PATIENT_MOVED = 'patient_moved',
    CHANGE_MEDICATION = 'changed_medication',
    CHANGE_MEDICATION_DIZZY = 'changed_medication_dizzy',
    CHANGE_MEDICATION_SWOLLEN = 'changed_medication_swollen',
    CHANGE_MEDICATION_OTHER = 'changed_medication_other',
    CHANGE_LIMITS = 'changed_limits',
    CONFIRMED_LIMITS = 'confirmed_limits',
    INPUT_NEEDED = 'input_needed',
    TEST = 'test',
    OTHER = 'other',
    NO_REASON = 'no_reason'
}
