import { combineReducers } from 'redux';
import { cloneDeep } from 'lodash';
import * as actionTypes from './notifications.constants';

type BySubjectId = {
    [id: string]: any;
};

type NotifiationsState = {
    bySubjectId: BySubjectId;
};

const initialState: NotifiationsState = {
    bySubjectId: {}
};

const removeNotificationEntries = (state: BySubjectId, payload: any) => {
    const { externalId, idsToRemove } = payload;

    const prunedIds = state[externalId].allIds.filter(
        (id: string) => !idsToRemove.includes(id)
    );

    const copyOfState = cloneDeep(state[externalId].byId);

    idsToRemove.forEach((id: string) => delete copyOfState[id]);

    return {
        ...state,
        [externalId]: {
            ...state[externalId],
            byId: {
                ...copyOfState
            },
            allIds: prunedIds
        }
    };
};

function bySubjectId(state = initialState.bySubjectId, action: any) {
    const { payload, type } = action;

    switch (type) {
        case actionTypes.FETCH_NOTIFICATIONS_FOR_AUTH_USER_FULFILLED: {
            return payload;
        }
        case actionTypes.UPDATE_NOTIFICATION_STATUS_FULFILLED:
            return removeNotificationEntries(state, payload);
        default:
            return state;
    }
}

const notificationsReducer = combineReducers({
    bySubjectId
});

export default notificationsReducer;
