import { createReducer } from '@reduxjs/toolkit';
import { fetchStatistics, resetStatistics } from './statistics.actions';
import { initialState } from './statistics.initial-state';
import { Statistics } from './statistics.type';

const statisticsReducer = createReducer<Statistics>(initialState, (builder) => {
    builder
        .addCase(fetchStatistics.fulfilled, (state, { payload }) => {
            return (state = payload);
        })
        .addCase(resetStatistics.fulfilled, (state) => {
            state.groupId = initialState.groupId;
            state.numberOfPatientsSinceStart =
                initialState.numberOfPatientsSinceStart;
            state.period = initialState.period;
            state.patients = initialState.patients;
            state.medics = initialState.medics;
        });
});

export default statisticsReducer;
