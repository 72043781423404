import { HTTPClient } from '../../../services/http-client/http-client';
import { ChatConversation, Message } from './chat.types';

export const getConversations = (
    externalId: string
): Promise<ChatConversation[]> =>
    HTTPClient.get(`chat/conversations`, {
        params: {
            participantIds: externalId
        }
    }).then((data) => data[externalId]);

export const getMessages = (conversationId: string): Promise<Message[]> =>
    HTTPClient.get(`chat/conversations/${conversationId}/messages`);

export const addConversation = (data: any, externalId: string) =>
    HTTPClient.post('chat/conversations', data).then((data) => {
        const { id: conversationId } = data;
        return { data, externalId, conversationId };
    });

export const postMessage = (
    externalId: string,
    conversationId: string,
    body: {
        content: string;
    }
) =>
    HTTPClient.post(`chat/conversations/${conversationId}/messages`, body).then(
        (message) => {
            return { externalId, conversationId, message };
        }
    );

export const updateStatus = (conversationId: string, externalId: string) =>
    HTTPClient.put(`chat/conversations/${conversationId}/status`, {
        status: 'opened'
    }).then((data) => {
        const { status } = data;
        return {
            externalId,
            conversationId,
            status
        };
    });
