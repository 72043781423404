import { ChartType } from '../../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../../types/indicator/indicator-rules.type';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';
import colors from '../../../../theme/colors/commonColors';

export const rules: IndicatorChartHeatmapRules = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        { value: 1, color: colors.chart.heatMap.purple10 },
        { value: 2, color: colors.chart.heatMap.purple40 },
        { value: 3, color: colors.chart.heatMap.purple80 }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.sleepSci.choices.none.id,
            text: formatMessage(Strings.indicators.sleepSci.choices.none),
            color: colors.chart.heatMap.purple10
        },
        {
            textId: Strings.indicators.sleepSci.choices.some.id,
            text: formatMessage(Strings.indicators.sleepSci.choices.some),
            color: colors.chart.heatMap.purple40
        },

        {
            textId: Strings.indicators.sleepSci.choices.much.id,
            text: formatMessage(Strings.indicators.sleepSci.choices.much),
            color: colors.chart.heatMap.purple80
        }
    ]
};
