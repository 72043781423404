import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Blood Pressure LVAD',
        id: 'indicator.blood-pressure-lvad.title'
    },
    unit: {
        id: 'indicator.blood-pressure-lvad.unit',
        defaultMessage: 'mmHg'
    }
});

export const messagesBloodPressureLvad = {
    general
};
