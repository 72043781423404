import { RouteItemType } from '../../utils/route/switch-routes';
import Exception from './Exception';
import { pathException } from './path';

export const routeException: RouteItemType = {
    name: '',
    link: pathException,
    icon: '',
    component: Exception
};
