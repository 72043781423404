import React, { FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StyledTable } from '../../../../components/ui/table/Table';
import { defaultValidationTexts } from '../../../../components/form/inputs/default-validation-texts';
import { validation } from '../../../../components/form/inputs/message-descriptors';
import {
    FieldType,
    InputFields
} from '../../../../components/form/inputs/types';
import { validatePartialSsn } from '../../../../components/form/inputs/validation';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { getPatients } from '../../../../redux/domains/patient-search/patient-search.api';
import { PatientSearchUser } from '../../../../redux/domains/patient-search/patient-search.type';
import { PatientSearchResponse } from '../../../../redux/domains/patient-search/PatientSearchResponse';
import {
    FamilyName,
    FirstName,
    GetMemberOf,
    SortableSsn,
    Status
} from './columns';
import { patientSearch } from './message-descriptors';
import { SearchForm } from './search-form/SearchForm';
import { StyledError, StyledFormDescription } from './styled';
import { PatientSearchState } from './types';
import { alphabetic } from '../../../../utils/sort/alphabetic';
import { pathPatientProfileLink } from '../../data/components/tabs/path';
import { Strings } from '../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../locale/format/format-message';

const ssn = 'ssn';
const firstName = 'firstName';
const familyName = 'familyName';

const initialState: PatientSearchState = {
    loading: false,
    totalNumberOfHits: 0,
    patients: []
};

const initialFields: InputFields = {
    ssn: {
        fieldType: FieldType.Input,
        name: ssn,
        label: formatMessage(Strings.patient.meta.general.social),
        value: '',
        isValid: true,
        isTouched: false,
        minLength: 4,
        maxLength: 12,
        pattern: '^[a-zA-Z0-9åäöÅÄÖ-]+$',
        validationMessage: null,
        validationMessages: {
            ...defaultValidationTexts,
            tooShort: formatMessage(validation.shorterThanDigits, {
                minLength: 4
            }),
            tooLong: formatMessage(validation.longerThanDigits, {
                maxLength: 12
            })
        },
        validate: function () {
            return validatePartialSsn(this);
        }
    },
    firstName: {
        fieldType: FieldType.Input,
        name: firstName,
        label: formatMessage(Strings.labels.label.firstName),
        value: '',
        isValid: true,
        isTouched: false,
        minLength: 2,
        validationMessage: null,
        validationMessages: {
            ...defaultValidationTexts,
            tooShort: formatMessage(validation.shorterThanText, {
                minLength: 2
            })
        }
    },
    familyName: {
        fieldType: FieldType.Input,
        name: familyName,
        label: formatMessage(Strings.labels.label.familyName),
        value: '',
        placeholder: '',
        isValid: true,
        isTouched: false,
        minLength: 2,
        validationMessage: null,
        validationMessages: {
            ...defaultValidationTexts,
            tooShort: formatMessage(validation.shorterThanText, {
                minLength: 2
            })
        }
    }
};

export const GroupsSearchTab = (): JSX.Element => {
    const history = useHistory();
    const [state, setState] = useState<PatientSearchState>(initialState);
    const [error, setError] = useState<string>('');
    const patientGroups = useReduxSelector(
        (state) => state.groups.patientGroups
    );
    const selectedPatientGroupId = useReduxSelector(
        (state) => state.groups.selectedPatientGroupId
    );
    const columns = [
        SortableSsn,
        FamilyName,
        FirstName,
        GetMemberOf(patientGroups),
        Status
    ];

    return (
        <>
            <StyledFormDescription>
                {formatMessage(patientSearch.general.groupSearchDescription)}
            </StyledFormDescription>
            <SearchForm
                initialFields={initialFields}
                onSubmit={onSubmit}
                clearTable={() => {
                    setState({
                        ...initialState,
                        loading: false
                    });
                }}
            />
            {error && <StyledError>{error}</StyledError>}
            {!error && (
                <StyledTable
                    $clickable={true}
                    columns={columns}
                    dataSource={state.patients}
                    rowKey={(patient: PatientSearchUser) =>
                        patient.meta.personal_id
                    }
                    pagination={false}
                    loading={state.loading}
                    scroll={{
                        x: 'max-content'
                    }}
                    onRow={(record: {
                        externalId: string;
                        groups: string[];
                    }) => {
                        return {
                            onClick: () => onRowClick(record)
                        };
                    }}
                />
            )}
        </>
    );

    function onRowClick(record: { externalId: string; groups: string[] }) {
        history.push(
            pathPatientProfileLink(
                record.groups.find(
                    (groupId) => groupId === selectedPatientGroupId
                ) || record.groups[0],
                record.externalId
            )
        );
    }

    async function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setError('');
        setState({
            ...initialState,
            loading: true
        });
        const params = {
            ssn: event.currentTarget[ssn].value,
            firstName: event.currentTarget[firstName].value,
            lastName: event.currentTarget[familyName].value
        };
        try {
            const result = await getPatients(params);
            if (hasTooManyResults(result)) {
                setState(initialState);
                setError(formatMessage(patientSearch.general.tooManyResults));
            } else {
                result.users.sort((a, b) =>
                    alphabetic(a.meta.family_name, b.meta.family_name)
                );
                setState({
                    loading: false,
                    totalNumberOfHits: result.totalNumberOfHits,
                    patients: result.users
                });
            }
        } catch (error) {
            setState(initialState);
            setError(formatMessage(Strings.error.general.message));
        }
    }
};

function hasTooManyResults(result: PatientSearchResponse) {
    return result.totalNumberOfHits > result.users.length;
}
