import React from 'react';
import { Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { IndicatorIcon } from '../../../../../components/ui/icons/indicator-icon/IndicatorIcon';
import PriorityTableIndicator from './PriorityTableIndicator';
import { PriorityPatient } from '../../../../../redux/domains/priority/priority.type';
import { AntdToolTipContentWrapper } from '../../../../../components/ui/tooltip/AntdToolTipContentWrapper';
import { IndicatorWithSettings } from '../../../../../redux/domains/indicators/indicator.type';

type PriorityColumn = ColumnProps<PriorityPatient>;

export function getLessColumns(
    indicators: IndicatorWithSettings[]
): PriorityColumn[] {
    const indicatorColumns: PriorityColumn[] = indicators.map((indicator) => {
        return {
            title: (
                <Tooltip
                    title={indicator.title}
                    overlayClassName="indicator-header-tooltip"
                >
                    <AntdToolTipContentWrapper>
                        <IndicatorIcon size={46} code={indicator.code} />
                    </AntdToolTipContentWrapper>
                </Tooltip>
            ),
            dataIndex: `results.${indicator.id}`,
            className: 'table-head-center',
            render: renderPopover,
            width: 80
        };

        function renderPopover(result: any, row: PriorityPatient) {
            return (
                <PriorityTableIndicator
                    indicator={indicator}
                    result={result}
                    row={row}
                    showEffects={false}
                />
            );
        }
    });

    return indicatorColumns;
}
