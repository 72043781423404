import styled from 'styled-components';

export const StyledProgressWrapper = styled.div`
    width: 500px;
    padding-top: 2em;
`;

export const StyledActionWrapper = styled.div`
    border-top: 1px dotted grey;
    display: flex;
`;

export const StyledActions = styled.div`
    width: 50%;
    border-left: 1px dotted grey;
    padding: 2em;
    text-align: left;
`;

export const StyledH4 = styled.h4`
    text-align: center;
    padding-bottom: 2em;
`;

export const StyledH4LeftAligned = styled.h4`
    padding-bottom: 2em;
`;

export const StyledP = styled.p`
    margin-top: 20px;
    margin-bottom: 0px;
`;

export const StyledCoWorkerContent = styled.div`
    border-top: 1px dotted grey;
    padding-top: 2em;
    padding-bottom: 2em;
`;

export const StyledCoWorkerInfo = styled.div`
    padding-bottom: 3em;
    flex-direction: column;
    display: inline-block;
    text-align: left;
`;
