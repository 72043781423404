interface NavigatorInterface extends Navigator {
    userLanguage?: string;
}

export const getLanguageCode = (): string => {
    const _navigator: NavigatorInterface = navigator;

    return (
        (_navigator.languages && _navigator.languages[0]) ||
        _navigator.language ||
        _navigator.userLanguage ||
        'en'
    );
};
