import {
    formatMessageById,
    hasMessageId
} from '../../../../locale/format/format-message';
import { DateAndNumberTuple, SeriesData } from '../chart-types';

export const getSingleValueSeriesData = (
    data: DateAndNumberTuple[],
    code: string
): SeriesData[] => {
    let unitLabel = formatMessageById(`indicator.${code}.unit-label`);
    if (!hasMessageId(`indicator.${code}.unit-label`)) {
        unitLabel = formatMessageById(`indicator.${code}.unit`);
    }

    return [
        {
            data: data.map(([date, value]) => {
                const unit = formatMessageById(`indicator.${code}.unit`, {
                    value
                });
                const formattedValue = formatMessageById(
                    `indicator.${code}.value`,
                    {
                        value
                    }
                );
                return {
                    x: date,
                    y: value,
                    unit,
                    customUnit: formattedValue && `${formattedValue} ${unit}`
                };
            }),
            indicator: {
                name: formatMessageById(`indicator.${code}.title`),
                unit: unitLabel
            }
        }
    ];
};
