import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import { Row, Col, Input, InputNumber, Form, Button } from 'antd';
import { formatMessage } from '../../../../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../../../../locale/messagesDescriptors';

const InputGroup = Input.Group;
const FormItem = Form.Item;

const multipliers = {
    days: 8.64 * 10 ** 7 // 24 hours in ms
};

const ReminderInputGroup = (props: any) => {
    const {
        isReadonly,
        values,
        setFieldValue,
        isSubmitting,
        dirty,
        handleReset
    } = props;

    return (
        <>
            <Row gutter={8}>
                <Col span={12}>
                    <StyledFormItem
                        label={
                            <Label>
                                {formatMessage(
                                    Strings.reminders.general
                                        .timeToFirstReminder
                                )}
                            </Label>
                        }
                        colon={false}
                    >
                        <CustomReminderInput
                            name="missedReportInterval"
                            {...props}
                        />
                    </StyledFormItem>
                </Col>
                <Col span={12}>
                    <StyledFormItem
                        label={
                            <Label>
                                {formatMessage(
                                    Strings.reminders.general.interval
                                )}
                            </Label>
                        }
                        colon={false}
                    >
                        <CustomReminderInput
                            name="notificationInterval"
                            {...props}
                        />
                    </StyledFormItem>
                </Col>
            </Row>
            <>
                {!isReadonly && (
                    <StyledButtonContainer>
                        <Button
                            onClick={handleReset}
                            disabled={!dirty}
                            style={{ float: 'left' }}
                        >
                            {formatMessage(Strings.common.general.reset)}
                        </Button>
                        <Button
                            type={values.active ? 'dashed' : 'default'}
                            onClick={() =>
                                setFieldValue('active', !values.active, true)
                            }
                            style={{ marginLeft: 10 }}
                        >
                            {values.active
                                ? formatMessage(
                                      Strings.common.general.deactivate
                                  )
                                : formatMessage(
                                      Strings.common.general.activate
                                  )}
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: 10 }}
                            loading={isSubmitting}
                            disabled={!dirty}
                        >
                            {formatMessage(Strings.common.general.save)}
                        </Button>
                    </StyledButtonContainer>
                )}
            </>
        </>
    );
};

export default ReminderInputGroup;

const CustomReminderInput = ({ name, values, setFieldValue, ...rest }: any) => {
    const value = values[name];

    const onValueChange = (value: number) => {
        if (typeof value !== 'number') return;

        setFieldValue(name, value * multipliers.days, true);
    };
    return (
        <InputGroup compact>
            <StyledLabel htmlFor={name}>
                {formatMessage(
                    Strings.date.general.days,
                    { days: 2 } // force it to 2 to make it plural
                )}
            </StyledLabel>
            <Field name={name}>
                {({ field }: any) => (
                    <InputNumber
                        {...field}
                        min={1}
                        disabled={!values.active}
                        style={{ width: '50%' }}
                        value={value / multipliers.days}
                        onChange={onValueChange}
                    />
                )}
            </Field>
        </InputGroup>
    );
};

const StyledButtonContainer = styled.div`
    text-align: right;
    border-top: 1px solid #ccc;
    padding-top: 10px;
`;

const StyledLabel = styled.label`
    &&& {
        display: block;
        margin-bottom: 5px;
        text-transform: capitalize;
    }
`;

const StyledFormItem = styled(FormItem)`
    && {
        background-color: #eeefee;
        padding: 2rem;
        border-radius: 0.4rem;
    }
`;

const Label = styled.div`
    padding-bottom: 2rem;
    max-width: 15rem;
    height: 4rem;
    white-space: pre-wrap;
    text-align: left;
    word-wrap: break-word;
    line-height: 180%;
`;
