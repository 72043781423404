import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import styled from 'styled-components';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';

type Props = {
    refreshTable: () => void;
    lastUpdated: number;
};

const PriorityTableHeader = ({ refreshTable, lastUpdated }: Props) => (
    <StyledActionRow>
        <StyledButton
            shape="circle"
            style={{ fontSize: 16 }}
            icon="sync"
            onClick={refreshTable}
            title={formatMessage(
                Strings.ui.buttons.general.refreshPriorityTitle
            )}
        />

        <StyledDivider />

        <span>
            {formatMessage(Strings.screen.patientOverview.general.updated)}
        </span>
        <StyledDate>
            {moment(lastUpdated).format('YYYY-MM-DD HH:mm')}
        </StyledDate>
    </StyledActionRow>
);

export default PriorityTableHeader;

const StyledDivider = styled.div`
    width: 0.5rem;
`;

const StyledDate = styled.span`
    margin-left: 5px;
    margin-right: 5px;
`;

const StyledActionRow = styled.div`
    display: flex;
    align-items: center;
`;

const StyledButton = styled<any>(Button)`
    && {
        .anticon-sync {
            margin-top: -3px;
        }
    }
`;
