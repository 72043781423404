import * as actionTypes from './events.constants';
import { Events } from './event.type';

const initialState: Events = {
    byId: {},
    allIds: []
};

export default function eventsReducer(state = initialState, action: any) {
    const { payload, type } = action;

    switch (type) {
        case actionTypes.FETCH_EVENTS_PENDING:
            return { ...state, byId: {}, allIds: [] };
        case actionTypes.FETCH_EVENTS_FULFILLED: {
            const { events } = payload;
            return { ...state, ...events };
        }
        case actionTypes.FETCH_EVENTS_REJECTED:
            return { ...state, byId: {}, allIds: [] };
        default:
            return state;
    }
}
