import moment from 'moment';
import { subtractFromDate } from '../../../../utils/date/subtract-from-date';
import { DataPoint } from '../chart-types';

export function getTrendValueRange(params: {
    subtract: moment.Duration;
    trends: DataPoint[];
    now: moment.Moment;
}): boolean {
    return params.trends.some(
        ([date]) =>
            date >=
            subtractFromDate({
                subtract: params.subtract,
                date: params.now
            })
    );
}
