import { defineMessages } from 'react-intl';

export const placeholders = defineMessages({
    ssn: {
        id: 'form.placeholder.ssn',
        defaultMessage: 'YYYYMMDDNNNN'
    },
    chooseCaregiver: {
        id: 'form.placeholder.choose-caregiver',
        defaultMessage: 'Choose Caregiver'
    },
    chooseLoginMethod: {
        id: 'form.placeholder.choose-login-method',
        defaultMessage: 'Choose login method'
    }
});

export const validation = defineMessages({
    required: {
        id: 'form.validation.required',
        defaultMessage: 'Cannot be empty'
    },
    defaultLength: {
        id: 'form.validation.default-length',
        defaultMessage: 'Wrong text length'
    },
    patternMismatch: {
        id: 'form.validation.pattern-mismatch',
        defaultMessage: 'Wrong format'
    },
    shorterThanDigits: {
        id: 'form.validation.shorter-than-digit',
        defaultMessage: 'Cannot be shorter than {minLength} digits'
    },
    shorterThanText: {
        id: 'form.validation.shorter-than-text',
        defaultMessage: 'Cannot be shorter than {minLength} characters'
    },
    longerThanDigits: {
        id: 'form.validation.longer-than-digit',
        defaultMessage: 'Cannot be longer than {maxLength} digits'
    },
    longerThanText: {
        id: 'form.validation.longer-than-text',
        defaultMessage: 'Cannot be longer than {maxLength} characters'
    },
    invalidSsn: {
        id: 'form.validation.invalid-ssn',
        defaultMessage: 'The format must be YYYYMMDDNNNN'
    },
    invalidEmail: {
        id: 'form.validation.invalid-email',
        defaultMessage: 'Wrong e-mail format'
    },
    invalidPhoneNumber: {
        id: 'form.validation.invalid-phone-number',
        defaultMessage: 'Wrong phone number format'
    }
});
