import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/pain-after-activity.svg';
import { messagesPainAfterActivity } from './messages/after-activity';
import { rules } from './pain-after-activity-hs-rules';

export const parameterPainAfterActivityHs = {
    code: 'PAIN-AFTER-ACTIVITY-HS'
};

export const painAfterActivity: Indicator = {
    code: parameterPainAfterActivityHs.code,
    icon,
    messages: messagesPainAfterActivity,
    rules
};
