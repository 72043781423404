import * as actionTypes from './action-panel.constants';
import { ActionPanelPayloadType } from './action-panel.reducer';

export const openActionPanel = (
    patientExternalId: string,
    defaultTab?: 'messages'
): { type: string; payload: ActionPanelPayloadType } => {
    return {
        type: actionTypes.OPEN_ACTION_PANEL,
        payload: {
            patientExternalId: patientExternalId,
            defaultTab
        }
    };
};

export const closeActionPanel = () => {
    return {
        type: actionTypes.CLOSE_ACTION_PANEL
    };
};

export const setActionPanelLatest = (params: {
    latestDateForReset: null | number;
    latestDateForWatchover: null | number;
}) => {
    return {
        type: actionTypes.SET_LATEST_ACTION_PANEL,
        payload: {
            reset: params.latestDateForReset,
            watchover: params.latestDateForWatchover
        }
    };
};
