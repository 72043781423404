import React from 'react';
import moment from 'moment';
import { List } from 'antd';
import styled from 'styled-components';
import { useReduxSelector } from '../../../../../hooks/use-redux-selector';
import { ActionPanelProps } from '../../actionPanelProps';
import { Notification } from '../../../../../redux/domains/notifications/notifications.type';
import { IndicatorIconWithBorder } from '../../../../ui/icons/indicator-icon/IndicatorIcon';
import {
    StyledActionPanelFullSizeBox,
    StyledActionTabHeader
} from '../styledActionTabs';
import { Strings } from '../../../../../locale/messagesDescriptors';
import {
    formatMessage,
    formatMessageById
} from '../../../../../locale/format/format-message';

export const ValueCommentsTab = (props: ActionPanelProps) => {
    const notificationsState = useReduxSelector((state) => state.notifications);
    const notifications =
        notificationsState.bySubjectId[props.externalId]?.byId || {};
    const valueComments = Object.keys(notifications)
        .filter(
            (key) => notifications[key].notificationType === 'value_comment'
        )
        .map((key) => notifications[key]);

    return (
        <StyledActionPanelFullSizeBox>
            {!!valueComments.length && (
                <StyledValueCommentHeader>
                    {formatMessage(Strings.common.general.commentsHeader)}
                </StyledValueCommentHeader>
            )}
            <StyledList
                dataSource={valueComments}
                renderItem={(valueComment: Notification) => {
                    return (
                        <List.Item>
                            <StyledMeta
                                title={
                                    valueComment.indicatorCode
                                        ? formatMessageById(
                                              `indicator.${valueComment.indicatorCode.toLowerCase()}.title`
                                          )
                                        : formatMessage(
                                              Strings.common.general
                                                  .unknownIndicator
                                          )
                                }
                                avatar={
                                    <IndicatorIconWithBorder
                                        size={40}
                                        code={
                                            valueComment.indicatorCode ||
                                            'unknown'
                                        }
                                    />
                                }
                                description={moment(
                                    valueComment.sentDate
                                ).format('YYYY-MM-DD HH:mm')}
                            />
                            <StyledComment>
                                {valueComment.title || ''}
                            </StyledComment>
                        </List.Item>
                    );
                }}
            />
        </StyledActionPanelFullSizeBox>
    );
};

const StyledValueCommentHeader = styled<any>(StyledActionTabHeader)`
    text-transform: none;
`;

const StyledList = styled<any>(List)`
    height: calc(100% - 35px);
    overflow-y: auto;
`;

const StyledMeta = styled(List.Item.Meta)`
    .ant-list-item-meta-title {
        white-space: nowrap;
    }

    .ant-list-item-meta-description {
        white-space: nowrap;
    }
`;

const StyledComment = styled.span`
    display: table-cell;
    margin: 0 20px;
`;
