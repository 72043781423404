import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Extra medication',
        id: 'indicator.prnmed_copd.title'
    },
    values: {
        defaultMessage:
            '{dose, plural, one {{dose} dose} other {{dose} doses}} {medication}',
        id: 'indicator.prnmed_copd.values'
    },
    legendNo: {
        defaultMessage: 'No doses',
        id: 'indicator.prnmed_copd.legend.no'
    },
    legendYes: {
        defaultMessage: 'One or more doses',
        id: 'indicator.prnmed_copd.legend.yes'
    }
});

const dose = defineMessages({
    title: {
        defaultMessage: 'Dose',
        id: 'indicator.prnmed_copd.dose.title'
    },
    description: {
        defaultMessage: 'Number of doses exceeding prescription',
        id: 'indicator.prnmed_copd.dose.description'
    },
    unit: {
        id: 'indicator.prnmed_copd.dose.unit',
        defaultMessage: '{value, plural, one {dose} other {doses}}'
    }
});

const medication = defineMessages({
    title: {
        defaultMessage: 'Medication',
        id: 'indicator.prnmed_copd.medication.title'
    },

    description: {
        defaultMessage: 'Type of medicine',
        id: 'indicator.prnmed_copd.medication.description'
    },
    antibiotic: {
        defaultMessage: 'antibiotics',
        id: 'indicator.prnmed_copd.medication.choices.antibiotic'
    },
    cortisone: {
        defaultMessage: 'cortisone',
        id: 'indicator.prnmed_copd.medication.choices.cortisone'
    },
    beta2Stimulator: {
        defaultMessage: 'bronchodilator',
        id: 'indicator.prnmed_copd.medication.choices.bronchodilator'
    }
});

const choices = defineMessages({
    antibiotic: {
        defaultMessage: 'Antibiotics',
        id: 'indicator.prnmed_copd.choices.antibiotic'
    },
    cortisone: {
        defaultMessage: 'Cortisone',
        id: 'indicator.prnmed_copd.choices.cortisone'
    },
    beta2Stimulator: {
        defaultMessage: 'Bronchodilator',
        id: 'indicator.prnmed_copd.choices.bronchodilator'
    }
});

export const messagesPrnmedCopd = {
    general,
    dose,
    medication,
    choices
};
