import { Modal } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { formatMessage } from '../../locale/format/format-message';
import { Strings } from '../../locale/messagesDescriptors';
import { httpErrors } from '../../locale/messagesDescriptors/errors';

export const displayError = (error: AxiosError) => {
    const { response } = error;
    if (response && response.status) {
        const maybeMessage = isMedicDuplicationError(response)
            ? httpErrors[`e${response.status}medic`]
            : httpErrors[`e${response.status}`];

        const errorMessages = maybeMessage
            ? maybeMessage
            : Strings.error.general;

        Modal.error({
            title: formatMessage(errorMessages.message),
            content: formatMessage(errorMessages.description)
        });
    }
};

/**
 * This method is a fix for LC-3797 to check if the duplication error occurs when getting a duplicate error when registering a patient or medic/admin.
 * @see https://cross-solutions.atlassian.net/browse/LC-3797
 * @todo Replace with better handling when the agnostic backend services are being developed and will return an internal error code
 * @param response
 * @returns true if the response is for a NON patient role otherwise false
 */
const isMedicDuplicationError = (response: AxiosResponse<any>): boolean => {
    try {
        const USERS_URL = 'users';
        const { url, data } = response.config;
        const roles: string[] = JSON.parse(data).roles;

        return url === USERS_URL && !roles.includes('patient');
    } catch {
        return false;
    }
};
