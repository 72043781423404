import { resetPriority } from '../../constants/reset-priority';
import { activate, deactivate } from '../../constants/status-change';
import indicatorRules from '../../utils/create-condition-settings/indicator-rules';
import { indicatorItem } from '../../utils/create-condition-settings/items';
import { ConditionRules } from '../condition.type';
import { IndicatorMeta } from '../indicator-meta';

export const createHeartFailureConfiguration = (): ConditionRules => {
    return {
        initialObservationIndicators: [IndicatorMeta.BODY_WEIGHT.code],
        priorityTable: {
            hidden: [
                IndicatorMeta.FISH.code,
                IndicatorMeta.VEGETABLES.code,
                IndicatorMeta.CANDY.code,
                IndicatorMeta.EHFSCBS.code,
                IndicatorMeta.EQ5D3L.code,
                IndicatorMeta.PHYSACT.code,
                IndicatorMeta.VAS.code,
                IndicatorMeta.WAIST_CIRCUMFERENCE.code
            ],
            order: [
                IndicatorMeta.DIZZINESS.code,
                IndicatorMeta.SWELLING.code,
                IndicatorMeta.BODY_WEIGHT.code,
                IndicatorMeta.BREATHLESSNESS.code,
                IndicatorMeta.TIREDNESS.code,
                IndicatorMeta.BLOOD_PRESSURE.code,
                IndicatorMeta.PULSE.code,
                IndicatorMeta.PRNMED.code
            ]
        },
        reasons: {
            status_change: {
                activate: [
                    activate.PATIENT_CONTINUES,
                    activate.VACATION_BACK,
                    activate.HOSPITAL_DISCHARGED,
                    activate.MOVED_FROM_OTHER_CAREGIVER,
                    activate.OTHER
                ],
                deactivate: [
                    deactivate.DIAGNOSIS_CHANGED,
                    deactivate.DECEASED,
                    deactivate.PATIENT_DECLINES,
                    deactivate.STUDY_COMPLETED,
                    deactivate.TOO_ILL,
                    deactivate.VACATION,
                    deactivate.HOSPITALIZATION,
                    deactivate.MOVED_TO_OTHER_CAREGIVER,
                    deactivate.TREATMENT_FINISHED,
                    deactivate.OTHER
                ]
            },
            reset_priority: [
                resetPriority.CHANGED_LIMITS,
                resetPriority.CHANGED_MEDICATION_DIZZY,
                resetPriority.CHANGED_MEDICATION_SWOLLEN,
                resetPriority.CHANGED_MEDICATION_OTHER,
                resetPriority.OTHER
            ]
        },
        indicatorSettings: {
            preventFromEditLimits: [
                IndicatorMeta.PRNMED.code,
                IndicatorMeta.VAS.code,
                IndicatorMeta.EHFSCBS.code,
                IndicatorMeta.EQ5D3L.code,
                IndicatorMeta.PHYSACT.code
            ],
            preventFromEditReminders: [IndicatorMeta.PRNMED.code],
            preventFromToggle: [IndicatorMeta.VAS.code]
        },
        indicators: {
            [IndicatorMeta.PRNMED.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PRNMED.code]
            ),
            [IndicatorMeta.DIZZINESS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.DIZZINESS.code]
            ),
            [IndicatorMeta.SWELLING.code]: indicatorItem(
                indicatorRules[IndicatorMeta.SWELLING.code]
            ),
            [IndicatorMeta.BODY_WEIGHT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BODY_WEIGHT.code]
            ),
            [IndicatorMeta.BLOOD_PRESSURE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BLOOD_PRESSURE.code]
            ),
            [IndicatorMeta.PULSE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PULSE.code]
            ),
            [IndicatorMeta.BREATHLESSNESS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BREATHLESSNESS.code]
            ),
            [IndicatorMeta.TIREDNESS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.TIREDNESS.code]
            ),
            [IndicatorMeta.WAIST_CIRCUMFERENCE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.WAIST_CIRCUMFERENCE.code]
            ),
            [IndicatorMeta.VAS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.VAS.code]
            ),
            [IndicatorMeta.PHYSACT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PHYSACT.code]
            ),
            [IndicatorMeta.EHFSCBS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.EHFSCBS.code]
            ),
            [IndicatorMeta.EQ5D3L.code]: indicatorItem(
                indicatorRules[IndicatorMeta.EQ5D3L.code]
            )
        }
    };
};
