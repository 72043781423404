import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './root-reducer';
import promiseMiddleware from 'redux-promise-middleware';

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware().prepend(promiseMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
