import { RouteItemType } from '../../../utils/route/switch-routes';
import { pathPatientDataPage } from './path';
import { PatientDataPage } from './PatientDataPage';
import { titlePatientDataPage } from './title';

type TabTo = {
    patientGroupId: string;
    patientId: string;
    tab: string;
};

export const routePatient: RouteItemType & {
    linkToTab: (params: TabTo) => string;
} = {
    name: titlePatientDataPage,
    link: `${pathPatientDataPage}/:patientGroupId/:externalId/:tab?/:indicatorId?`,
    linkToTab: (params: TabTo) =>
        `${pathPatientDataPage}/${params.patientGroupId}/${params.patientId}/${params.tab}`,
    icon: '',
    component: PatientDataPage
};
