import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Use of orthosis during the day',
        id: 'indicator.orthosis-day-hs.title'
    },
    unit: {
        defaultMessage: '{value, plural, one {hour} other {hours}}',
        id: 'indicator.orthosis-day-hs.unit'
    }
});

export const messagesOrthosisDay = {
    general
};
