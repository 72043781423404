import React, { Dispatch, SetStateAction, useState } from 'react';
import { BaseUserMeta } from '../../../../../redux/domains/user/user.type';
import {
    formatMessage,
    formatMessageById
} from '../../../../../locale/format/format-message';
import { Modal, Select } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { Strings } from '../../../../../locale/messagesDescriptors';
import {
    StyledIcon,
    StyledLimitsText,
    StyledPatientMeta,
    StyledSelectWrapper,
    StyledTextBoxWrapper
} from './StyledPatientModals';
import TextArea from 'antd/lib/input/TextArea';
import { pathCreatePatientStep3 } from '../../../create/steps/step-3/path';

const { Option } = Select;

interface ActivatePatientModalProps {
    externalId: string;
    patientMeta: BaseUserMeta;
    reasons: string[];
    routeProps: RouteComponentProps;
    savePendingPatientActivation: (
        externalId: string,
        reason: string,
        note?: string
    ) => void;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    toggleButton: Dispatch<SetStateAction<boolean>>;
    toggleVisibility: Dispatch<SetStateAction<boolean>>;
    visibility: boolean;
}

export const ActivatePatientModal = ({
    externalId,
    patientMeta,
    reasons,
    routeProps,
    savePendingPatientActivation,
    setIsLoading,
    toggleButton,
    toggleVisibility,
    visibility
}: ActivatePatientModalProps) => {
    const [chosenReason, setChosenReason] = useState('');
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [note, setNote] = useState('');
    const [showTextBox, setShowTextBox] = useState(false);

    const handleOk = () => {
        toggleVisibility(false);
        setIsLoading(true);

        note.length > 0
            ? savePendingPatientActivation(externalId, chosenReason, note)
            : savePendingPatientActivation(externalId, chosenReason);

        routeProps.history.push(pathCreatePatientStep3);
    };

    const handleCancel = () => {
        toggleVisibility(false);
        toggleButton(false);
    };

    const handleChange = (value: string) => {
        setChosenReason(value);
        setShowTextBox(value === 'other');
        setIsFormInvalid(value === 'other');
    };

    const handleTextBoxChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { value } = event.target;

        setNote(value);
        setIsFormInvalid(value.length === 0);
    };

    return (
        <Modal
            title={
                <>
                    <StyledIcon type="question-circle" />{' '}
                    {formatMessage(Strings.common.patient.activatePatient)}
                </>
            }
            visible={visibility}
            onOk={handleOk}
            okButtonProps={{ disabled: isFormInvalid }}
            onCancel={handleCancel}
        >
            <StyledPatientMeta>
                {patientMeta.name} {patientMeta.family_name}
            </StyledPatientMeta>

            <StyledPatientMeta>
                {patientMeta.personal_id || ''}
            </StyledPatientMeta>

            <StyledSelectWrapper>
                <p>{formatMessage(Strings.common.general.reason)}:</p>

                <Select onChange={handleChange}>
                    {reasons.map((reason: string) => {
                        const definedMessage = formatMessageById(
                            `status-change.activate.${reason}`
                        );

                        return (
                            <Option key={reason} value={reason}>
                                {definedMessage}
                            </Option>
                        );
                    })}
                </Select>
            </StyledSelectWrapper>

            {showTextBox && (
                <StyledTextBoxWrapper>
                    <p>{formatMessage(Strings.common.general.note)}</p>
                    <TextArea rows={4} onChange={handleTextBoxChange} />
                </StyledTextBoxWrapper>
            )}

            <StyledLimitsText>
                {formatMessage(
                    Strings.screen.patientAdministration.createPatient
                        .dialogInactiveBody
                )}
            </StyledLimitsText>
        </Modal>
    );
};
