import { parameterActiveMotion } from './hand-surgery/indicators/active-motion/active-motion';
import { parameterAppetite } from './shared-indicators/appetite/appetite';
import { parameterBloodPressure } from './shared-indicators/blood-pressure/blood-pressure';
import { parameterBloodPressureLVAD } from './left-ventricular-assist-device/indicators/blood-pressure-average/blood-pressure-average';
import { parameterBloodSugar } from './shared-indicators/blood-sugar/blood-sugar';
import { parameterBMI } from './shared-indicators/bmi/bmi';
import { parameterBodyLength } from './shared-indicators/body-length/body-length';
import { parameterBodyTemp } from './shared-indicators/body-temp/body-temp';
import { parameterBodyWeight } from './shared-indicators/body-weight/body-weight';
import { parameterBreathlessness } from './shared-indicators/breathlessness/breathlessness';
import { parameterCandy } from './shared-indicators/candy/candy';
import { parameterCat } from './chronic-obstructive-pulmonary-disease/indicators/cat/cat';
import { parameterDailyActivity } from './hand-surgery/indicators/daily-activity/daily-activity';
import { parameterDizziness } from './shared-indicators/dizziness/dizziness';
import { parameterEhfscbs } from './heart-failure/indicators/ehfscbs/ehfscbs';
import { parameterEhra } from './atrial-fibrillation/indicators/ehra/ehra';
import { parameterEq5d3l } from './shared-indicators/eq5d3l/eq5d3l';
import { parameterExercises } from './hand-surgery/indicators/exercises/exercises';
import { parameterFish } from './shared-indicators/fish/fish';
import { parameterForm } from './shared-indicators/form/form';
import { parameterGripHs } from './hand-surgery/indicators/grip/grip';
import { parameterMedicationCi } from './intermittent-claudication/indicators/medication/medication';
import { parameterMedicationHs } from './hand-surgery/indicators/medication/medication';
import { parameterMmrc } from './shared-indicators/mmrc/mmrc';
import { parameterOrthosisDayHs } from './hand-surgery/indicators/orthosis-day/orthosis-day';
import { parameterOrthosisNightHs } from './hand-surgery/indicators/orthosis-night/orthosis-night';
import { parameterPainAfterActivityHs } from './hand-surgery/indicators/pain/pain-after-activity';
import { parameterPainAtRestHs } from './hand-surgery/indicators/pain/pain-at-rest';
import { parameterPainCi } from './shared-indicators/pain/pain';
import { parameterPainDuringActivityHs } from './hand-surgery/indicators/pain/pain-during-activity';
import { parameterPhlegm } from './shared-indicators/phlegm/phlegm';
import { parameterPhysact } from './shared-indicators/physact/physact';
import { parameterPrnmed } from './shared-indicators/prnmed/prnmed';
import { parameterPrnmedAf } from './atrial-fibrillation/indicators/prnmed/prnmed';
import { parameterPrnmedCi } from './intermittent-claudication/indicators/prnmed/prnmed';
import { parameterPrnmedCopd } from './chronic-obstructive-pulmonary-disease/indicators/prnmed/prnmed';
import { parameterPrnmedLvad } from './left-ventricular-assist-device/indicators/prnmed/prnmed';
import { parameterPulse } from './shared-indicators/pulse/pulse';
import { parameterPumpFlowLvad } from './left-ventricular-assist-device/indicators/pump-flow/pump-flow';
import { parameterPumpPowerLVAD } from './left-ventricular-assist-device/indicators/pump-power/pump-power';
import { parameterPumpPulseIndexLVAD } from './left-ventricular-assist-device/indicators/pump-pulse-index/pump-pulse-index';
import { parameterPumpSelfTestLVAD } from './left-ventricular-assist-device/indicators/pump-self-test/pump-self-test';
import { parameterPumpSpeedLVAD } from './left-ventricular-assist-device/indicators/pump-speed/pump-speed';
import { parameterSleepHS } from './hand-surgery/indicators/sleep/sleep';
import { parameterSmoke } from './shared-indicators/smoke/smoke';
import { parameterSwelling } from './shared-indicators/swelling/swelling';
import { parameterSwellingHs } from './hand-surgery/indicators/swelling-hs/swelling-hs';
import { parameterTiredness } from './shared-indicators/tiredness/tiredness';
import { parameterVAS } from './shared-indicators/vas/vas';
import { parameterVegetables } from './shared-indicators/vegetables/vegetables';
import { parameterVocationalActivityHS } from './hand-surgery/indicators/vocational-activity/vocational-activity';
import { parameterWaistCircumference } from './shared-indicators/waist-circumference/waist-circumference';
import { parameterWeightPD } from './shared-indicators/weight-pd/weight-pd';
import { parameterBowelDysfunction } from './spinal-cord-injury/indicators/bowel-dysfunction/bowel-dysfunction';
import { parameterLiquidMeasurement } from './spinal-cord-injury/indicators/liquid-measurement/liquid-measurement';
import { parameterOxygenSaturation } from './spinal-cord-injury/indicators/oxygen-saturation/oxygen-saturation';
import { parameterRespiratoryProblems } from './spinal-cord-injury/indicators/respiratory-problems/respiratory-problems';
import { parameterSleepSCI } from './spinal-cord-injury/indicators/sleep-sci/sleep-sci';
import { parameterUrineLeakage } from './spinal-cord-injury/indicators/urine-leakage/urine-leakage';
import { IndicatorMetaType } from '../redux/domains/indicators/indicator.type';

export const BOWEL_DYSFUNCTION = parameterBowelDysfunction;
export const LIQUID_MEASUREMENT = parameterLiquidMeasurement;
export const OXYGEN_SATURATION = parameterOxygenSaturation;
export const RESPIRATORY_PROBLEMS = parameterRespiratoryProblems;
export const SLEEP_SCI = parameterSleepSCI;
export const URINE_LEAKAGE = parameterUrineLeakage;

export const ACTIVE_MOTION_HS = parameterActiveMotion;
export const APPETITE = parameterAppetite;
export const BLOOD_PRESSURE = parameterBloodPressure;
export const BLOOD_PRESSURE_LVAD = parameterBloodPressureLVAD;
export const BLOOD_SUGAR = parameterBloodSugar;
export const BMI = parameterBMI;
export const BODY_LENGTH = parameterBodyLength;
export const BODY_TEMP = parameterBodyTemp;
export const BODY_WEIGHT = parameterBodyWeight;
export const BREATHLESSNESS = parameterBreathlessness;
export const CANDY = parameterCandy;
export const CAT = parameterCat;
export const DAILY_ACTIVITY_HS = parameterDailyActivity;
export const DIZZINESS = parameterDizziness;
export const EHFSCBS = parameterEhfscbs;
export const EHRA = parameterEhra;
export const EQ5D3L = parameterEq5d3l;
export const EXERCISES_HS = parameterExercises;
export const FISH = parameterFish;
export const FORM = parameterForm;
export const GRIP_HS = parameterGripHs;
export const MEDICATION_CI = parameterMedicationCi;
export const MEDICATION_HS = parameterMedicationHs;

export const MEDICINE = {
    code: 'MEDICINE'
};

export const MMRC = parameterMmrc;
export const ORTHOSIS_DAY_HS = parameterOrthosisDayHs;
export const ORTHOSIS_NIGHT_HS = parameterOrthosisNightHs;
export const PAIN_AFTER_ACTIVITY_HS = parameterPainAfterActivityHs;
export const PAIN_AT_REST_HS = parameterPainAtRestHs;
export const PAIN_CI = parameterPainCi;
export const PAIN_DURING_ACTIVITY_HS = parameterPainDuringActivityHs;
export const PHLEGM = parameterPhlegm;
export const PHYSACT = parameterPhysact;
export const PRNMED = parameterPrnmed;
export const PRNMED_AF = parameterPrnmedAf;
export const PRNMED_CI = parameterPrnmedCi;
export const PRNMED_COPD = parameterPrnmedCopd;
export const PRNMED_LVAD = parameterPrnmedLvad;
export const PULSE = parameterPulse;
export const PUMP_FLOW_LVAD = parameterPumpFlowLvad;
export const PUMP_POWER_LVAD = parameterPumpPowerLVAD;
export const PUMP_PULSE_INDEX_LVAD = parameterPumpPulseIndexLVAD;
export const PUMP_SELF_TEST_LVAD = parameterPumpSelfTestLVAD;
export const PUMP_SPEED_LVAD = parameterPumpSpeedLVAD;
export const SLEEP_HS = parameterSleepHS;
export const SMOKE = parameterSmoke;
export const SWELLING = parameterSwelling;
export const SWELLING_HS = parameterSwellingHs;
export const TIREDNESS = parameterTiredness;
export const VAS = parameterVAS;
export const VEGETABLES = parameterVegetables;
export const VOCATIONAL_ACTIVITY_HS = parameterVocationalActivityHS;
export const WAIST_CIRCUMFERENCE = parameterWaistCircumference;
export const WEIGHT_PD = parameterWeightPD;

export const IndicatorMeta: Record<string, IndicatorMetaType> = {
    ACTIVE_MOTION_HS,
    APPETITE,
    BLOOD_PRESSURE_LVAD,
    BLOOD_PRESSURE,
    BLOOD_SUGAR,
    BMI,
    BODY_LENGTH,
    BODY_TEMP,
    BODY_WEIGHT,
    BREATHLESSNESS,
    CANDY,
    CAT,
    DAILY_ACTIVITY_HS,
    DIZZINESS,
    EHFSCBS,
    EHRA,
    EQ5D3L,
    EXERCISES_HS,
    FISH,
    FORM,
    GRIP_HS,
    MEDICATION_CI,
    MEDICATION_HS,
    MEDICINE,
    MMRC,
    ORTHOSIS_DAY_HS,
    ORTHOSIS_NIGHT_HS,
    PAIN_AFTER_ACTIVITY_HS,
    PAIN_AT_REST_HS,
    PAIN_CI,
    PAIN_DURING_ACTIVITY_HS,
    PHLEGM,
    PHYSACT,
    PRNMED_AF,
    PRNMED_CI,
    PRNMED_COPD,
    PRNMED_LVAD,
    PRNMED,
    PULSE,
    PUMP_FLOW_LVAD,
    PUMP_POWER_LVAD,
    PUMP_PULSE_INDEX_LVAD,
    PUMP_SELF_TEST_LVAD,
    PUMP_SPEED_LVAD,
    SLEEP_HS,
    SMOKE,
    SWELLING_HS,
    SWELLING,
    TIREDNESS,
    VAS,
    VEGETABLES,
    VOCATIONAL_ACTIVITY_HS,
    WAIST_CIRCUMFERENCE,
    WEIGHT_PD,
    BOWEL_DYSFUNCTION,
    LIQUID_MEASUREMENT,
    OXYGEN_SATURATION,
    RESPIRATORY_PROBLEMS,
    SLEEP_SCI,
    URINE_LEAKAGE
};
