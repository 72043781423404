import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/respiratory-problems.svg';
import { messagesRespiratoryProblems } from './messages/messages';
import { RespiratoryProblemsProperties } from './respiratory-problems-properties';
import { rules } from './respiratory-problems-rules';

export const parameterRespiratoryProblems = {
    code: 'RESPIRATORY-PROBLEMS',
    properties: RespiratoryProblemsProperties
};

export const respiratoryProblems: Indicator = {
    code: parameterRespiratoryProblems.code,
    icon,
    messages: messagesRespiratoryProblems,
    rules
};
