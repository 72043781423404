import React from 'react';
import { EnvelopeIconSVG, encodeSvg } from '../../../ui/icons/EnvelopeIconSVG';
import {
    isChartRules,
    Settings
} from '../../../../redux/domains/settings/settings.type';
import { CombinedGraphColor } from '../../../../utils/create-condition-settings/indicator-rules/defaults/colors/graph-color.type';
import defaultColorObj from '../../../../utils/create-condition-settings/indicator-rules/defaults/colors/default';
import { GraphMarker } from '../types/graph-value.type';

export const getLineColor = (settings?: Settings): CombinedGraphColor => {
    if (settings) {
        if (isChartRules(settings.rules)) {
            return settings.rules.lineColor;
        }
    }
    return defaultColorObj;
};

export const getMarkerWithSymbol = (
    comment: string | boolean | undefined,
    lineColorObj: CombinedGraphColor
): GraphMarker => {
    const marker: { enabled: boolean; symbol: string | undefined } = {
        enabled: true,
        symbol: undefined
    };

    let color = '#000000';
    if (lineColorObj.default && typeof lineColorObj.default === 'string') {
        color = lineColorObj.default;
    }

    if (comment) {
        marker.symbol =
            'url(' + encodeSvg(<EnvelopeIconSVG fill={color} />) + ')';
    }

    return marker;
};
