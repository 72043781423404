import { defaultValidationTexts } from '../../../../../components/form/inputs/default-validation-texts';
import { validation } from '../../../../../components/form/inputs/message-descriptors';
import {
    FieldType,
    InputFields,
    InputType
} from '../../../../../components/form/inputs/types';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../locale/format/format-message';
import { FieldNamesBasic } from '../../field-names';

export const getBasicUserMedicFields = (): InputFields => {
    return {
        [FieldNamesBasic.firstName]: {
            fieldType: FieldType.Input,
            name: FieldNamesBasic.firstName,
            label: formatMessage(Strings.labels.label.firstName),
            value: '',
            isValid: true,
            isTouched: false,
            validationMessage: null,
            minLength: 2,
            required: true,
            indicateRequired: true,
            validationMessages: {
                ...defaultValidationTexts,
                tooShort: formatMessage(validation.shorterThanText, {
                    minLength: 2
                })
            }
        },
        [FieldNamesBasic.lastName]: {
            fieldType: FieldType.Input,
            name: FieldNamesBasic.lastName,
            label: formatMessage(Strings.labels.label.familyName),
            value: '',
            isValid: true,
            isTouched: false,
            validationMessage: null,
            minLength: 2,
            required: true,
            indicateRequired: true,
            validationMessages: {
                ...defaultValidationTexts,
                tooShort: formatMessage(validation.shorterThanText, {
                    minLength: 2
                })
            }
        },
        [FieldNamesBasic.email]: {
            fieldType: FieldType.Input,
            name: FieldNamesBasic.email,
            label: formatMessage(Strings.labels.label.email),
            value: '',
            type: InputType.Email,
            isValid: true,
            isTouched: false,
            validationMessage: null,
            required: true,
            indicateRequired: true,
            pattern:
                "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$",
            validationMessages: {
                ...defaultValidationTexts,
                custom: formatMessage(validation.invalidEmail)
            }
        },
        [FieldNamesBasic.password]: {
            fieldType: FieldType.Input,
            name: FieldNamesBasic.password,
            label: formatMessage(Strings.labels.label.password),
            value: '',
            type: InputType.Text,
            isValid: true,
            isTouched: false,
            validationMessage: null,
            required: true,
            indicateRequired: true,
            validationMessages: {
                ...defaultValidationTexts
            }
        },
        [FieldNamesBasic.phoneNumber]: {
            fieldType: FieldType.Input,
            name: FieldNamesBasic.phoneNumber,
            label: formatMessage(Strings.labels.label.phoneNumber),
            value: '',
            type: InputType.Tel,
            isValid: true,
            isTouched: false,
            required: false,
            indicateRequired: false,
            pattern: '^[+0][0-9-]+$',
            validationMessage: null,
            validationMessages: {
                ...defaultValidationTexts,
                custom: formatMessage(validation.invalidPhoneNumber)
            }
        },
        [FieldNamesBasic.medicTitle]: {
            fieldType: FieldType.Input,
            name: FieldNamesBasic.medicTitle,
            label: formatMessage(Strings.labels.label.title),
            value: '',
            required: false,
            isValid: true,
            isTouched: false,
            validationMessage: null,
            validationMessages: defaultValidationTexts
        }
    };
};
