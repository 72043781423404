import Input from 'antd/lib/input';
import styled, { css } from 'styled-components';
import { AppNeutralButton } from '../../../components/ui/buttons/AppNeutralButton';
import { AppPrimaryButton } from '../../../components/ui/buttons/AppPrimaryButton';

export const StyledForm = styled.form`
    width: 350px;
`;

export const FieldWrapper = styled.div`
    display: block;
    height: 85px;
`;

export const StyledLabel = styled.label<{ required?: boolean }>`
    display: block;
    margin: 11px 0 7px 0;
    font-size: 13px;
    ${(props) =>
        props.required &&
        css`
            ::before {
                content: '*';
                color: #f5222d;
                font-family: SimSun, sans-serif;
                margin-right: 4px;
            }
        `}
`;

export const StyledInput = styled(Input)`
    && {
        display: block;
        width: 100%;
        height: 37px;
    }
`;

export const StyledInputError = styled.p`
    color: red;
    margin-bottom: 0;
`;

export const StyledPrimaryButton = styled(AppPrimaryButton)`
    margin: 10px;
`;

export const StyledNeutralButton = styled(AppNeutralButton)`
    margin: 10px;
`;

export const StyledButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    button:first-of-type {
        margin-left: 0;
    }

    button:last-of-type {
        margin-right: 0;
    }
`;

export const StyledLeftButtonWrapper = styled.div`
    flex-grow: 1;
`;
