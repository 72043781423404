export const formatPersonalId = (personalId?: string) => {
    if (personalId == null) return '';

    personalId = personalId.replace('-', '');

    const length = personalId.length,
        position = length - 4;

    return personalId.length === 10 || personalId.length === 12
        ? personalId.substring(0, position) +
              '-' +
              personalId.substring(position, length)
        : personalId;
};
