import { Card } from 'antd';
import styled from 'styled-components';
import { getTheme } from '../../../../theme/profile/profiles';

const CrossCard = styled(Card)`
    border-color: ${getTheme().profile.bright};
    border-top-color: ${getTheme().profile.layout.primary};
    border-top-width: 2px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
`;

export default CrossCard;
