import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import styled from 'styled-components';
import { Radio } from 'antd';
import {
    formatMessage,
    formatMessageById
} from '../../../../../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../../../../../locale/messagesDescriptors';
import { ChoicesMapping } from '../../../../../../../../../../redux/domains/settings/settings.type';
import { Choice } from '../../../../../../../../../../types/indicator/heatmap-piece.type';

const getInitialConfig = (configuration: any, choices: Choice[]) => {
    const { upper_yellow: initialUpperYellow, upper_red: initialUpperRed } =
        configuration;

    return choices.map((choice: Choice, i: number) => {
        if (initialUpperRed !== null && i >= initialUpperRed) {
            return 2;
        } else if (initialUpperYellow !== null && i >= initialUpperYellow) {
            return 1;
        } else {
            return 0;
        }
    });
};

const RadioFormGroup = ({
    setFieldValue,
    prefix,
    choices,
    configuration
}: any) => {
    const [matrix, setMatrix] = React.useState(() =>
        getInitialConfig(configuration, choices)
    );

    const updateFormValues = (matrix: number[]) => {
        const upperYellow = matrix.includes(1)
            ? matrix.findIndex((num) => num === 1)
            : null;

        const upperRed = matrix.includes(2)
            ? matrix.findIndex((num) => num === 2)
            : null;

        const upperYellowFieldKey = prefix
            ? `configuration.${prefix}.upper_yellow`
            : 'configuration.upper_yellow';
        const upperRedFieldKey = prefix
            ? `configuration.${prefix}.upper_red`
            : 'configuration.upper_red';
        setFieldValue(upperYellowFieldKey, upperYellow);
        setFieldValue(upperRedFieldKey, upperRed);
    };

    const onChange = (e: any, rowIndex: number) => {
        const value = e.target.value;
        const copyOfMatrix = matrix.slice();
        copyOfMatrix[rowIndex] = value;

        for (let i = rowIndex - 1; i >= 0 && matrix[i] > value; i--) {
            copyOfMatrix[i] = value;
        }

        for (
            let i = rowIndex + 1;
            i <= matrix.length && matrix[i] < value;
            i++
        ) {
            copyOfMatrix[i] = value;
        }

        updateFormValues(copyOfMatrix);
        setMatrix(copyOfMatrix);
    };

    // Reset matrix to default values when form resets
    useDeepCompareEffect(() => {
        setMatrix(getInitialConfig(configuration, choices));
    }, [configuration]);

    const getRowStyle = (i: number) => {
        switch (matrix[i]) {
            case 0:
                return '#2dcc39';
            case 1:
                return '#efea17';
            case 2:
                return '#e8462c';
            default:
                return '#cccccc';
        }
    };

    return (
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <Header>
                        {formatMessage(Strings.ui.table.general.actions)}
                    </Header>
                    <Header>OK</Header>
                    <Header>
                        {formatMessage(
                            Strings.indicatorConfig.general.upperYellow
                        )}
                    </Header>
                    <Header>
                        {formatMessage(
                            Strings.indicatorConfig.general.upperRed
                        )}
                    </Header>
                </tr>
            </thead>
            <tbody>
                {choices.map((choice: any, i: number) => {
                    return (
                        <Row
                            key={choice.localeId}
                            style={{ background: getRowStyle(i) }}
                        >
                            <Label>
                                <span>
                                    {formatMessageById(choice.localeId)}
                                </span>
                            </Label>
                            <Cell>
                                <StyledRadio
                                    type="radio"
                                    onChange={(e: any) => onChange(e, i)}
                                    name="ok"
                                    value={0}
                                    checked={matrix[i] === 0}
                                />
                            </Cell>
                            <Cell>
                                <StyledRadio
                                    type="radio"
                                    onChange={(e: any) => onChange(e, i)}
                                    name={
                                        prefix
                                            ? `${prefix}.upper_yellow`
                                            : 'upper_yellow'
                                    }
                                    checked={matrix[i] === 1}
                                    value={1}
                                />
                            </Cell>
                            <Cell>
                                <StyledRadio
                                    type="radio"
                                    onChange={(e: any) => onChange(e, i)}
                                    name={
                                        prefix
                                            ? `${prefix}.upper_red`
                                            : 'upper_red'
                                    }
                                    checked={matrix[i] === 2}
                                    value={2}
                                />
                            </Cell>
                        </Row>
                    );
                })}
            </tbody>
        </table>
    );
};

const Header = styled.th`
    padding: 4px 8px;
`;

const Label = styled.td`
    padding: 16px 8px;
    font-weight: 700;
`;

const Cell = styled.td`
    width: 110px;
    padding: 16px 8px;
`;

const Row = styled.tr`
    transition: all 0.3s ease;
    &:not(:last-child) {
        border-bottom: 1px solid #fffa;
    }
`;

const StyledRadio = styled(Radio)`
    .ant-radio-inner {
        border-radius: 0px;

        &::after {
            top: 2px;
            left: 2px;
            width: 10px;
            height: 10px;
            border-radius: 0px;
        }
    }
`;
export default RadioFormGroup;
