import { RouteItemType } from '../../../utils/route/switch-routes';
import { pathUneducatedStart } from './path';
import { titleUneducatedStart } from './title';
import { UneducatedStart } from './UneducatedStart';

export const routeUneducatedStart: RouteItemType = {
    name: titleUneducatedStart,
    link: pathUneducatedStart,
    icon: 'info-circle',
    component: UneducatedStart
};
