import React from 'react';
import { FormikFunction } from '../../../../pages/patient/edit/formik-type';
import {
    FieldWrapper,
    StyledLabel,
    StyledInput,
    StyledInputError
} from '../../../../pages/patient/edit/styled-form';

export type FieldType = {
    id?: string;
    type?: string;
    value: string;
    label?: string | React.ReactNode;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    error?: string;
    handleChange?: FormikFunction;
    handleBlur?: FormikFunction;
};

export const Field = (props: FieldType): JSX.Element => {
    const {
        id,
        type,
        value,
        label,
        placeholder,
        readOnly,
        required,
        error,
        handleChange,
        handleBlur
    } = props;

    return (
        <FieldWrapper>
            {label && (
                <StyledLabel htmlFor={id} required={required}>
                    {label}
                </StyledLabel>
            )}
            <StyledInput
                id={id}
                placeholder={placeholder}
                type={type ? type : 'text'}
                value={value}
                readOnly={readOnly}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {error && <StyledInputError>{error}</StyledInputError>}
        </FieldWrapper>
    );
};
