import { defineMessages } from 'react-intl';

const general = defineMessages({
    today: {
        id: 'shared.date.today',
        defaultMessage: 'Today'
    },
    thisMonth: {
        id: 'shared.date.thisMonth',
        defaultMessage: 'This Month'
    },
    lastMonth: {
        id: 'shared.date.lastMonth',
        defaultMessage: 'Last Month'
    },
    weekShort: {
        id: 'shared.date.weekShort',
        defaultMessage: '{weeks} w.'
    },
    monthShort: {
        id: 'shared.date.monthShort',
        defaultMessage: '{months} mo.'
    },
    hours: {
        id: 'shared.date.hours',
        defaultMessage: '{hours, plural, one { hour } other { hours }}'
    },
    days: {
        id: 'shared.date.days',
        defaultMessage: '{days, plural, one { day } other { days }}'
    }
});

export const date = {
    general
};
