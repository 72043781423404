import { useEffect, useState } from 'react';

type ExtendedDocument = Document & Record<string, any>;

export const useInactiveTabDetector = (): { hidden: boolean } => {
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        let hidden = '';
        let visibilityChangeEvent = '';
        const extendedDocument: ExtendedDocument = document;
        if (typeof document.hidden !== 'undefined') {
            hidden = 'hidden';
            visibilityChangeEvent = 'visibilitychange';
        } else {
            const prefixes = ['webkit', 'moz', 'ms'];
            for (let i = 0; i < prefixes.length; i++) {
                const prefix = prefixes[i];

                if (
                    typeof extendedDocument[`${prefix}Hidden`] !== 'undefined'
                ) {
                    hidden = `${prefix}Hidden`;
                    visibilityChangeEvent = `${prefix}visibilitychange`;
                    break;
                }
            }
        }

        const handleVisibilityChange = () => {
            setHidden(extendedDocument[hidden]);
        };

        window.addEventListener(visibilityChangeEvent, handleVisibilityChange);

        return () =>
            window.removeEventListener(
                visibilityChangeEvent,
                handleVisibilityChange
            );
    }, []);

    return { hidden };
};
