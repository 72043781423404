import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Vegetables',
        id: 'indicator.vegetables.title'
    },
    legendNo: {
        defaultMessage: 'Have not eaten vegetables',
        id: 'indicator.vegetables.legend.no'
    },
    legendYes: {
        defaultMessage: 'Have eaten vegetables',
        id: 'indicator.vegetables.legend.yes'
    },
    unit: {
        id: 'indicator.vegetables.unit',
        defaultMessage: 'g'
    }
});

export const messagesVegetables = {
    general
};
