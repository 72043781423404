import { defineMessages } from 'react-intl';

export const header = defineMessages({
    text: {
        id: 'video-meeting.action-panel.header.text',
        defaultMessage: 'Video meeting'
    }
});

export const messages = defineMessages({
    text: {
        id: 'video-meeting.action-panel.messages.text',
        defaultMessage: 'Video meeting'
    }
});
