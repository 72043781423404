import { defineMessages } from 'react-intl';

const general = defineMessages({
    reportMin: {
        id: 'App.Indicator.Form.Label.report_min',
        defaultMessage: 'Report minimum'
    },
    reportMax: {
        id: 'App.Indicator.Form.Label.report_max',
        defaultMessage: 'Report maximum'
    },
    lowerYellow: {
        id: 'App.Indicator.Form.Label.lower_yellow',
        defaultMessage: 'Low'
    },
    lowerRed: {
        id: 'App.Indicator.Form.Label.lower_red',
        defaultMessage: 'Low (Critical)'
    },
    upperYellow: {
        id: 'App.Indicator.Form.Label.upper_yellow',
        defaultMessage: 'High'
    },
    upperRed: {
        id: 'App.Indicator.Form.Label.upper_red',
        defaultMessage: 'High (Critical)'
    },
    inUse: {
        id: 'App.Indicator.Form.Label.in_use',
        defaultMessage: 'Activated'
    },
    type: {
        id: 'App.Indicator.Form.Label.type',
        defaultMessage: 'Type'
    }
});

export const indicatorConfig = {
    general,
    rootId: 'App.Indicator.Form.Label.'
};
