import { IndicatorWithSettings } from '../../../../../redux/domains/indicators/indicator.type';

export function filterHiddenIndicators(
    indicatorsWithSettings: IndicatorWithSettings[],
    hidden: string[]
) {
    return indicatorsWithSettings.filter(
        (indicator: IndicatorWithSettings) => !hidden.includes(indicator.code)
    );
}
