import { ConditionRules } from '../../../conditions/condition.type';
import { FETCH_SETTINGS_FULFILLED } from './settings.constants';

const initialState = {};

export interface FetchSettingsFulfilledAction {
    type: 'FETCH_SETTINGS_FULFILLED';
    payload: ConditionRules;
}

export default function settingsReducer(
    state = initialState,
    action: FetchSettingsFulfilledAction | undefined = undefined
) {
    if (action === undefined) {
        return state;
    }

    const { payload } = action;

    if (action.type === FETCH_SETTINGS_FULFILLED) {
        return { ...state, ...payload };
    }

    return state;
}
