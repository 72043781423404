import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContentCardLayout } from '../../../components/content/ContentLayout';
import { CrossSpinner } from '../../../components/ui/loading/loading';
import { usePageTitle } from '../../../hooks/use-page-title';
import { getPatient } from '../../../redux/domains/active-patient/active-patient.api';
import { NormalizedPatientType } from '../../../redux/domains/active-patient/active-patient.utils';
import { titleEditPatient } from './title';
import { EditPatientGroup } from './components/GroupEdit';
import { EditPatientGroupInformation } from './components/EditPatientGroupInformation';
import { useSelector } from 'react-redux';
import { getPatientGroupId } from './utils/get-patient-group-id';
import { getPatientGroupsForCurrentSelectedMedicGroup } from '../../../redux/domains/groups/groups.selectors';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

export const EditPatientGroupPage = (): JSX.Element => {
    usePageTitle(titleEditPatient);

    const patientId = useParams<{ id: string }>().id;

    const groups = useSelector(getPatientGroupsForCurrentSelectedMedicGroup);

    const [currentPatient, setCurrentPatient] =
        useState<NormalizedPatientType>();

    const selectedGroupId = useMemo(
        () => getPatientGroupId(groups, patientId)?.id,
        [groups, patientId]
    );

    useEffect(() => {
        void getPatient(patientId).then((patient) => {
            setCurrentPatient(patient);
        });
    }, [patientId]);

    if (currentPatient) {
        if (selectedGroupId) {
            return (
                <ContentCardLayout>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <EditPatientGroupInformation patient={currentPatient} />
                        <EditPatientGroup
                            patientId={patientId}
                            initialGroupId={selectedGroupId}
                        />
                    </div>
                </ContentCardLayout>
            );
        }
    }

    return (
        <ContentCardLayout>
            <CrossSpinner
                text={formatMessage(Strings.events.general.fetchingInformation)}
            />
        </ContentCardLayout>
    );
};
