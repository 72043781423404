import { keyBy } from 'lodash';
import { createSelector } from 'reselect';
import {
    MedicGroupId,
    MedicGroupsState,
    MedicUser,
    PatientGroup,
    PatientGroupId,
    PatientGroups
} from './groups.type';
import { ReduxState } from '../../redux-state.type';
import { GroupTag } from './groups.type';

const user = (state: ReduxState) => state.user;
const medicGroups = (state: ReduxState) => state.groups.medicGroups;
const patientGroups = (state: ReduxState) => state.groups.patientGroups;
const medicUsers = (state: ReduxState) => state.groups.medicUsers;

export const getSelectedPatientGroupId = (state: ReduxState): PatientGroupId =>
    state.groups.selectedPatientGroupId;

export const getSelectedMedicGroupId = (state: ReduxState): MedicGroupId =>
    state.groups.selectedMedicGroupId;

export const getSelectedPatientGroup = createSelector(
    [getSelectedPatientGroupId, patientGroups],
    (id, groups) => (id ? groups.byId[id] : null)
);

const getSelectedMedicGroup = createSelector(
    [getSelectedMedicGroupId, medicGroups],
    (id, groups) => (id ? groups.byId[id] : null)
);

export const getMedicsInGroup = (
    state: ReduxState,
    props: { medicGroupId: MedicGroupId }
): MedicUser[] =>
    medicGroups(state).byId[props.medicGroupId].users.map(
        (id) => medicUsers(state)[id]
    );

export const isCurrentUserMedicInSelectedGroup = (state: ReduxState): boolean =>
    state.groups.selectedMedicGroupId
        ? isCurrentUserMedicInGroup(state, {
              medicGroupId: state.groups.selectedMedicGroupId
          })
        : false;

export const isCurrentUserMedicInGroup = (
    state: ReduxState,
    props: { medicGroupId: MedicGroupId }
): boolean =>
    getMedicGroupsForCurrentUser(state).includes(props.medicGroupId) || false;

export const getMedicGroupsForCurrentUser = createSelector(
    [medicUsers, user],
    (medicUsers, user) => medicUsers[user.externalId].medicGroups || []
);

export const isCurrentUserAdminInSelectedGroup = (state: ReduxState): boolean =>
    state.groups.selectedMedicGroupId
        ? isCurrentUserAdminInGroup(state, {
              medicGroupId: state.groups.selectedMedicGroupId
          })
        : false;

export const isCurrentUserAdminInGroup = (
    state: ReduxState,
    props: { medicGroupId: MedicGroupId }
): boolean =>
    getAdminGroupsForCurrentUser(state).includes(props.medicGroupId) || false;

export const getAdminGroupsForCurrentUser = createSelector(
    [medicUsers, user],
    (medicUsers, user) => medicUsers[user.externalId].adminGroups || []
);

export const isMedicAdmin = createSelector(
    [getSelectedMedicGroup],
    (group): boolean =>
        group != null && group.tags.includes(GroupTag.MedicAdmin)
);

export const getSelectablePatientGroups = createSelector(
    [getSelectedMedicGroup, (state) => patientGroups(state).byId],
    (medicGroup, patientGroupsById) =>
        medicGroup ? medicGroup.manages.map((id) => patientGroupsById[id]) : []
);

export const getPatientGroupsForCurrentSelectedMedicGroup = createSelector(
    [getSelectablePatientGroups],
    (patientGroups): PatientGroups => ({
        byId: keyBy(patientGroups, 'id'),
        allIds: patientGroups.map((patientGroup) => patientGroup.id)
    })
);

export function getDefaultMedicGroupId(
    { groups: { selectedMedicGroupId, medicGroups } }: ReduxState,
    { managedBy }: PatientGroup
): string | undefined {
    // 1. choose the selectedMedicGroupId
    // 2. choose any medicGroupId where Active medic is included
    return (
        managedBy.find(
            (medicGroupId) => medicGroupId === selectedMedicGroupId
        ) ||
        managedBy.find((medicGroupId) =>
            medicGroups.allIds.includes(medicGroupId)
        )
    );
}

export const getConditionIdsFromGroups = createSelector(
    [medicGroups, patientGroups],
    (medicGroups: MedicGroupsState, patientGroups: PatientGroups) => {
        const patientConditionIds: string[] = [];

        Object.values(medicGroups.byId).forEach((medicGroup) => {
            medicGroup.manages.forEach((managedId) =>
                patientConditionIds.push(
                    patientGroups.byId[managedId].condition
                )
            );
        });

        return [...new Set(patientConditionIds)];
    }
);
