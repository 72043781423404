import { ReasonsForChange } from '../../conditions/condition.type';
import { resetPriority } from '../../constants/reset-priority';
import { activate, deactivate } from '../../constants/status-change';

export const reasonsStatusChange = (): ReasonsForChange => {
    return {
        status_change: {
            activate: [
                activate.PATIENT_CONTINUES,
                activate.VACATION_BACK,
                activate.HOSPITAL_DISCHARGED,
                activate.MOVED_FROM_OTHER_CAREGIVER,
                activate.OTHER
            ],
            deactivate: [
                deactivate.DIAGNOSIS_CHANGED,
                deactivate.DECEASED,
                deactivate.PATIENT_DECLINES,
                deactivate.STUDY_COMPLETED,
                deactivate.TOO_ILL,
                deactivate.VACATION,
                deactivate.HOSPITALIZATION,
                deactivate.MOVED_TO_OTHER_CAREGIVER,
                deactivate.TREATMENT_FINISHED,
                deactivate.OTHER
            ]
        },
        reset_priority: [
            resetPriority.CHANGED_LIMITS,
            resetPriority.CHANGED_MEDICATION,
            resetPriority.OTHER
        ]
    };
};
