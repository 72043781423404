import { Statistics } from './statistics.type';

export const initialState: Statistics = {
    groupId: '',
    numberOfPatientsSinceStart: 0,
    period: {
        from: '',
        to: ''
    },
    patients: {
        count: 0,
        nonReporting: 0,
        male: 0,
        female: 0,
        registered: 0,
        inactivated: 0,
        monthsInLifepod: [
            {
                months: 0,
                count: 0
            }
        ],
        vas: {
            red: 0,
            green: 0,
            yellow: 0
        },
        physact: {
            reported: 0,
            notReported: 0,
            inactivated: 0
        },
        smoke: {
            reported: 0,
            notReported: 0,
            inactivated: 0
        }
    },
    medics: {
        count: 0,
        loggedIn: 0,
        sessions: 0,
        actions: {
            feedbacks: {
                count: 0,
                patients: 0
            },
            watchovers: {
                count: 0,
                patients: 0
            },
            messages: {
                count: 0,
                patients: 0
            },
            changedLimits: {
                count: 0,
                patients: 0
            },
            resetPrio: {
                count: 0,
                patients: 0
            }
        }
    }
};
