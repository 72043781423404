import React from 'react';
import {
    Field,
    FieldType
} from '../../../../../../components/ui/field/formik/FormikField';
import { Strings } from '../../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../../locale/format/format-message';

export const FirstNameField = (props: FieldType): JSX.Element => {
    const { value, error, handleChange, handleBlur } = props;

    return (
        <Field
            id="firstName"
            value={value}
            label={formatMessage(Strings.editPatient.fields.firstName.label)}
            placeholder={formatMessage(
                Strings.editPatient.fields.firstName.hint
            )}
            error={error}
            required
            handleChange={handleChange}
            handleBlur={handleBlur}
        />
    );
};
