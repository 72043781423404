import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';
import colors from '../../../../theme/colors/commonColors';
import { ChartType } from '../../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartHeatmapRules = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        { value: 1, color: colors.chart.heatMap.purple10 },
        { value: 2, color: colors.chart.heatMap.purple20 },
        { value: 3, color: colors.chart.heatMap.purple40 },
        { value: 4, color: colors.chart.heatMap.purple60 },
        { value: 5, color: colors.chart.heatMap.purple80 }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.nbd.choices.none.id,
            text: formatMessage(Strings.indicators.nbd.choices.none),
            color: colors.chart.heatMap.purple10
        },
        {
            textId: Strings.indicators.nbd.choices.veryMinor.id,
            text: formatMessage(Strings.indicators.nbd.choices.veryMinor),
            color: colors.chart.heatMap.purple20
        },
        {
            textId: Strings.indicators.nbd.choices.minor.id,
            text: formatMessage(Strings.indicators.nbd.choices.minor),
            color: colors.chart.heatMap.purple40
        },
        {
            textId: Strings.indicators.nbd.choices.moderate.id,
            text: formatMessage(Strings.indicators.nbd.choices.moderate),
            color: colors.chart.heatMap.purple60
        },
        {
            textId: Strings.indicators.nbd.choices.severe.id,
            text: formatMessage(Strings.indicators.nbd.choices.severe),
            color: colors.chart.heatMap.purple80
        }
    ]
};
