import { defineMessages } from 'react-intl';

const main = defineMessages({
    logIn: {
        id: 'auth.log_in',
        defaultMessage: 'Log in'
    },
    logOut: {
        id: 'auth.log_out',
        defaultMessage: 'Log out'
    },
    resolved: {
        id: 'auth.resolved',
        defaultMessage: 'Resolved url'
    },
    resolving: {
        id: 'auth.resolving',
        defaultMessage: 'Resolving launch url...'
    },
    redirect: {
        id: 'auth.redirect',
        defaultMessage: 'Redirecting to LifePod'
    },
    loggingIn: {
        id: 'auth.logging_in',
        defaultMessage: 'Logging into LifePod...'
    },
    samlGeneralError: {
        id: 'auth.saml.error.general',
        defaultMessage: 'Something went wrong. Please contact {email}'
    },
    logoutPending: {
        id: 'auth.logout.pending',
        defaultMessage: 'One moment! Logging out...'
    },
    logoutCompleted: {
        id: 'auth.logout.completed',
        defaultMessage: 'You are logged out!'
    },
    closeBrowser: {
        id: 'auth.logout.close-browser',
        defaultMessage: 'You can now close the browser'
    },
    loginAgain: {
        id: 'auth.login.again',
        defaultMessage: 'Log in again'
    },
    idleCountdown: {
        id: 'auth.logout.idle.countdown',
        defaultMessage:
            'You will be logged out after {secondsToGo} {secondsToGo, plural, one {second} other {seconds}}'
    },
    idleTitle: {
        id: 'auth.logout.idle.title',
        defaultMessage: 'Hello, are you there?'
    },
    loggedOutDueToInactivity: {
        id: 'auth.logout.message.inactivity',
        defaultMessage: 'You have been logged out due to inactivity'
    },
    idleOkText: {
        id: 'auth.logout.idle.button.ok',
        defaultMessage: 'Continue being logged in'
    },
    notAuthorised: {
        id: 'shared.login.notAuthorised',
        defaultMessage: 'Could not log in - invalid username or password'
    }
});

export const authText = {
    main,
    rootId: 'auth.'
};
