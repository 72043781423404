import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/pump-power.svg';
import { messagesPumpPowerLvad } from './messages/messages';
import { rules } from './pump-power-lvad-rules';

export const parameterPumpPowerLVAD = {
    code: 'PUMP-POWER-LVAD'
};

export const pumpPower: Indicator = {
    code: parameterPumpPowerLVAD.code,
    icon,
    messages: messagesPumpPowerLvad,
    rules
};
