import { Divider, Icon } from 'antd';
import { styled } from '../../../theme/styled';

export const StyledContactIcon = styled(Icon)`
    margin-right: 5px;
`;

export const StyledWrapper = styled.div`
    display: flex;
    margin-bottom: 15px;
`;

export const StyledHeader = styled.h1`
    white-space: nowrap;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    color: ${(props) => props.theme.profile.base.primary};
    margin: 10;
    font-size: 2em;
`;

export const StyledTitle = styled.h1`
    white-space: nowrap;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    color: ${(props) => props.theme.profile.base.dark};
    margin: 10;
    margin-left: 25px;
    font-size: 2em;
`;

export const StyledUserMeta = styled.span`
    margin-right: 20px;

    a {
        color: #4f56b0;
    }

    a:hover {
        color: #828bcf;
    }

    i {
        color: rgba(0, 0, 0, 0.65);
    }
`;

export const StyledPersonalId = styled.span`
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);
`;

export const StyledDivider = styled(Divider)`
    margin: 8px 0;
    height: 1px;
`;

export const StyledHeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    align-items: center;
`;

export const StyledPatientInformation = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledTableWrapper = styled.div`
    padding-top: 10px;
    table {
        width: auto;
    }

    .ant-table-thead
        > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
        > td,
    .ant-table-tbody
        > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
        > td,
    .ant-table-thead
        > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
        > td,
    .ant-table-tbody
        > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
        > td {
        background-color: transparent;
    }
`;
