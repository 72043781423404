import React, { PropsWithChildren } from 'react';
import { styled } from '../../../theme/styled';

type Props = PropsWithChildren<{
    selected: boolean;
    onClick?: () => void;
}>;

export const SelectableRow = (props: Props): JSX.Element => {
    const { selected, onClick, children } = props;

    return (
        <StyledRow $selected={selected} onClick={onClick}>
            <StyledDot $selected={selected} />
            <span>{children}</span>
        </StyledRow>
    );
};

const StyledRow = styled.div<{ $selected: boolean }>`
    min-width: 100px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: ${({ $selected, theme }) =>
        $selected ? theme.color.selected : theme.color.text};

    &:hover {
        color: ${({ $selected, theme }) =>
            $selected ? theme.color.selected : theme.color.hover};
    }
`;

const StyledDot = styled.div<{ $selected: boolean }>`
    min-width: 10px;
    min-height: 10px;
    background-color: ${({ $selected, theme }) =>
        $selected ? theme.color.selected : theme.color.deselected};
    margin-right: 10px;
    border-radius: 100%;
`;
