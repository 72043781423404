import React from 'react';
import { PatientGroup } from '../../../../../redux/domains/groups/groups.type';
import { useHistory } from '../../../../../hooks/use-router';
import { useReduxSelector } from '../../../../../hooks/use-redux-selector';
import { ActivePatient } from '../../../../../redux/domains/active-patient/active-patient.type';
import { getConditions } from '../../../../../redux/domains/conditions/conditions.selectors';
import { styled } from '../../../../../theme/styled';
import { PatientDataGroup } from './PatientDataGroup';
import { pathPatientProfileLink } from '../tabs/path';
import { PatientTabs } from '../tabs/tabs';

type Props = {
    patient: ActivePatient;
    patientGroups: Record<string, PatientGroup>;
    selectedPatientGroupId: string;
    tab: PatientTabs;
};

export const PatientDataGroups = (props: Props) => {
    const { patient, patientGroups, selectedPatientGroupId, tab } = props;
    const routeHistory = useHistory();
    const conditions = useReduxSelector(getConditions);
    const accessiblePatientGroups = useReduxSelector(
        (state) => state.groups.patientGroups.byId
    );

    return (
        <StyledGroups>
            {Object.keys(patientGroups).map((key) => {
                const patientGroup = patientGroups[key];
                const conditionCode = conditions[patientGroup.condition].code;
                const isSelected = selectedPatientGroupId === patientGroup.id;

                return (
                    <PatientDataGroup
                        key={key}
                        patient={patient}
                        patientGroup={patientGroup}
                        conditionCode={conditionCode}
                        isSelected={isSelected}
                        showActionPanel={
                            patientGroup.id in accessiblePatientGroups
                        }
                        onClick={() =>
                            routeHistory.push(
                                pathPatientProfileLink(
                                    patientGroup.id,
                                    patient.externalId,
                                    tab
                                )
                            )
                        }
                    />
                );
            })}
        </StyledGroups>
    );
};

const StyledGroups = styled.div`
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 20px;
`;
