import { authStorageKeys } from './auth-storage-keys';
import { AuthTokens } from '../../token/token-service';

export function addTokensToLocalStorage({ idToken, refreshToken }: AuthTokens) {
    window.localStorage.setItem(authStorageKeys.idToken, idToken);
    window.localStorage.setItem(
        authStorageKeys.refreshToken,
        refreshToken || ''
    );
}
