import { Icon } from 'antd';
import styled from 'styled-components';

export const StyledPatientMeta = styled.p`
    font-weight: bold;
    margin-bottom: 5px;
`;

export const StyledSelectWrapper = styled.div`
    p {
        margin-top: 10px;
    }

    .ant-select {
        width: 100%;
    }
`;

export const StyledTextBoxWrapper = styled.div`
    margin-top: 10px;
`;

export const StyledLimitsText = styled.div`
    margin-top: 30px;
`;

export const StyledIcon = styled(Icon)`
    color: rgb(250, 173, 20);
    font-size: 20px;
    margin-right: 5px;
    vertical-align: middle;
`;
