import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import { BaseButton } from './BaseButton';

const AppNeutralButton = (props: ButtonProps) => (
    <BaseButton {...props}>
        {props.children}
        {props.title}
    </BaseButton>
);

export { AppNeutralButton };
