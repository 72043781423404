import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

type Values = {
    [key: string]: string | object | JSX.Element | undefined;
    tagName?: string;
    children?: (...formattedMessage: Array<ReactElement>) => ReactElement;
};

export type MessageProps = {
    id: string;
    defaultMessage?: string;
    description?: string | object;
    values?: Values;
};

export const Message = (props: MessageProps) => {
    return <FormattedMessage {...props} />;
};
