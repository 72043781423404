import React, { Component } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { PatientGroups } from '../../../../../redux/domains/groups/groups.type';
import { PatientAdministrationUser } from '../../../../../redux/domains/patient-administration/patient-administration.type';
import styled from 'styled-components';
import { ContentCardLayout } from '../../../../../components/content/ContentLayout';
import SearchBar from '../../../../../components/shared/search-bar/SearchBar';
import { PatientAdministrationTable } from './PatientAdministrationTable';
import { pathCreatePatient } from '../../../create/path';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { connect } from 'react-redux';

type Props = {
    filterPatients: any;
    searchTerm: any;
    patientsList: PatientAdministrationUser[];
    togglePatient: any;
    loading: any;
    groups: PatientGroups;
    statusChangeReasons: any;
    savePendingRegisterPatientId: any;
    savePendingPatientActivation: any;
    routeProps: any;
    selectedPatient: string;
};

type State = {
    searchTerm: any;
};

enum Behavior {
    smooth = 'smooth'
}

class PatientAdministrationList extends Component<Props, State> {
    private searchInput: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            searchTerm: props.searchTerm
        };
    }

    componentDidMount(): void {
        const { selectedPatient } = this.props;

        if (selectedPatient) {
            const selectedColumn = document.querySelectorAll(
                `[data-row-key='${selectedPatient}']`
            )[0];

            if (selectedColumn) {
                const tableWrap =
                    document.getElementsByClassName('ant-table-body')[0];
                const offset = 210;
                const elementPosition =
                    selectedColumn.getBoundingClientRect().top;
                const offsetPosition = elementPosition - offset;

                tableWrap.scrollTo({
                    top: offsetPosition,
                    behavior: Behavior.smooth
                });
            }
        }
    }
    componentWillUnmount() {
        const { searchTerm } = this.state;
        if (searchTerm) {
            this.props.filterPatients('');
        }
    }

    render() {
        const {
            patientsList,
            togglePatient,
            loading,
            groups,
            statusChangeReasons,
            savePendingRegisterPatientId,
            savePendingPatientActivation,
            routeProps
        } = this.props;
        const { searchTerm } = this.state;

        return (
            <ContentCardLayout>
                <StyledActionRow>
                    <Link to={pathCreatePatient}>
                        <Button type="primary">
                            {formatMessage(
                                Strings.ui.buttons.general.createPatient
                            )}
                        </Button>
                    </Link>
                    <SearchBar
                        placeholder={formatMessage(
                            Strings.form.general.searchPlaceholder
                        )}
                        onChange={this.onSearchChange}
                        value={searchTerm}
                        onEmitEmpty={this.emitEmpty}
                        innerRef={(node: any) => {
                            this.searchInput = node;
                        }}
                    />
                </StyledActionRow>
                <PatientAdministrationTable
                    loading={loading}
                    patientsList={patientsList}
                    groups={groups}
                    togglePatient={togglePatient}
                    statusChangeReasons={statusChangeReasons}
                    savePendingRegisterPatientId={savePendingRegisterPatientId}
                    savePendingPatientActivation={savePendingPatientActivation}
                    routeProps={routeProps}
                />
            </ContentCardLayout>
        );
    }

    emitEmpty = () => {
        this.searchInput.focus();
        this.setState({ searchTerm: '' });
        this.props.filterPatients('');
    };

    onSearchChange = (e: any) => {
        const value = e.target.value;
        this.props.filterPatients(value);

        if (!value) {
            this.setState({ searchTerm: '' });
        } else {
            this.setState({ searchTerm: value });
        }
    };
}

const mapStateToProps = (state: any) => {
    return {
        selectedPatient: state.patientAdministration.selectedPatient
    };
};

export default connect(mapStateToProps)(PatientAdministrationList);

const StyledActionRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 1rem;
`;
