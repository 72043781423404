import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAppPageTitle } from '../redux/domains/app-state/app-state.actions';

export function usePageTitle(text: string) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setAppPageTitle(text));
    }, [dispatch, text]);
}
