import _ from 'lodash';
import { createSelector } from 'reselect';
import { ReduxState } from '../../redux-state.type';
import { getPatientGroupsForCurrentSelectedMedicGroup } from '../groups/groups.selectors';
import { MedicGroupId, PatientGroupId } from '../groups/groups.type';
import { UserStatus } from '../user/user.type';
import { PatientAdministrationUser } from './patient-administration.type';

const allPatients = (state: ReduxState) => state.patientAdministration.byId;
const searchTerm = (state: ReduxState) =>
    state.ui.patientAdministration.searchTerm;
const patientById = (state: ReduxState, props: { externalId: string }) =>
    state.patientAdministration.byId[props.externalId];

export const getAllPatients = createSelector(
    [getPatientGroupsForCurrentSelectedMedicGroup, allPatients],
    (groups, allPatients) => {
        const { allIds = [], byId = {} } = groups;

        const userIds = _.uniq(_.flatten(allIds.map((id) => byId[id].users)));

        return userIds.map((id) => allPatients[id]);
    }
);

export const getAllPatientsById = allPatients;

export const getFilteredPatients = createSelector(
    [getAllPatients, searchTerm],
    (patients, searchterm) => {
        return filterPatientsBySearchterm(searchterm, patients);
    }
);

const filterPatientsBySearchterm = (
    searchterm: string,
    patients: PatientAdministrationUser[]
) => {
    if (!searchterm) return patients;

    return _.filter(patients, (patient) => {
        const {
            meta: { personal_id = '' }
        } = patient;
        let {
            meta: { name = '', family_name = '' }
        } = patient;
        name = _.toLower(name);
        family_name = _.toLower(family_name);
        searchterm = _.toLower(searchterm);

        return (
            name.includes(searchterm) ||
            family_name.includes(searchterm) ||
            personal_id.replace('-', '').includes(searchterm.replace('-', ''))
        );
    });
};

export const getPatientById = createSelector(
    [patientById],
    (patient) => patient
);

export const getActivePatientsByMedicGroupId = (
    state: ReduxState,
    props: { medicGroupId: MedicGroupId }
): Record<PatientGroupId, PatientAdministrationUser> => {
    const patientGroupIds =
        state.groups.medicGroups.byId[props.medicGroupId]?.manages || [];

    return _.pickBy(
        allPatients(state),
        (patient: PatientAdministrationUser) =>
            patient.status === UserStatus.Active &&
            patient.groups.some((group) => patientGroupIds.includes(group))
    );
};
