import { defineMessages } from 'react-intl';

const watchover = defineMessages({
    patientInformed: {
        id: 'history.watchover.patient-informed',
        defaultMessage: 'The patient has been informed'
    }
});

export const history = { watchover };
