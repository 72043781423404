import { ConditionRules } from '../../conditions/condition.type';
import { IndicatorMeta } from '../../conditions/indicator-meta';
import indicatorRules from './indicator-rules';
import { indicatorItem } from './items';
import { reasonsStatusChange } from './reasons-status-change';

export const createDefaultConditionConfiguration = (): ConditionRules => {
    return {
        priorityTable: {
            hidden: [
                IndicatorMeta.FISH.code,
                IndicatorMeta.VEGETABLES.code,
                IndicatorMeta.CANDY.code,
                IndicatorMeta.EHFSCBS.code,
                IndicatorMeta.EQ5D3L.code
            ],
            order: []
        },
        initialObservationIndicators: [],
        indicatorSettings: {
            preventFromEditLimits: [],
            preventFromEditReminders: []
        },
        reasons: reasonsStatusChange(),
        indicators: {
            [IndicatorMeta.ACTIVE_MOTION_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.ACTIVE_MOTION_HS.code]
            ),
            [IndicatorMeta.APPETITE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.APPETITE.code]
            ),
            [IndicatorMeta.BLOOD_PRESSURE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BLOOD_PRESSURE.code]
            ),
            [IndicatorMeta.BLOOD_PRESSURE_LVAD.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BLOOD_PRESSURE_LVAD.code]
            ),
            [IndicatorMeta.BLOOD_SUGAR.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BLOOD_SUGAR.code]
            ),
            [IndicatorMeta.BMI.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BMI.code]
            ),
            [IndicatorMeta.BODY_LENGTH.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BODY_LENGTH.code]
            ),
            [IndicatorMeta.BODY_WEIGHT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BODY_WEIGHT.code]
            ),
            [IndicatorMeta.BREATHLESSNESS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BREATHLESSNESS.code]
            ),
            [IndicatorMeta.CANDY.code]: indicatorItem(
                indicatorRules[IndicatorMeta.CANDY.code]
            ),
            [IndicatorMeta.CAT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.CAT.code]
            ),
            [IndicatorMeta.DAILY_ACTIVITY_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.DAILY_ACTIVITY_HS.code]
            ),
            [IndicatorMeta.DIZZINESS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.DIZZINESS.code]
            ),
            [IndicatorMeta.EHFSCBS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.EHFSCBS.code]
            )
        }
    };
};
