import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useReduxSelector } from '../../../../../../hooks/use-redux-selector';
import {
    Condition,
    IndicatorConfigurations
} from '../../../../../../redux/domains/conditions/conditions.type';
import { IndicatorWithSettings } from '../../../../../../redux/domains/indicators/indicator.type';
import {
    ReportReminder,
    ReportRemindersByExternalId
} from '../../../../../../redux/domains/reminders/reminders.type';
import { RouteMatchType } from '../../../match-type';
import IndicatorList from './indicator-list/IndicatorList';

export const LimitsTab = (props: {
    condition: Condition;
    indicators: IndicatorWithSettings[];
    indicatorConfigurations: IndicatorConfigurations;
    isReadonly?: boolean;
}) => {
    const {
        condition,
        indicators,
        indicatorConfigurations,
        isReadonly = false
    } = props;
    const routeMatch = useRouteMatch<RouteMatchType>();
    const { externalId } = routeMatch.params;
    const patient = useReduxSelector((state) => state.selectedPatient);
    const reportReminders = useReduxSelector(
        (state) => state.reminders.reportReminders || {}
    );
    const remindersByIndicatorId = getReminders(
        reportReminders,
        externalId,
        condition.id
    );

    if (!patient) {
        return <></>;
    }

    return (
        <IndicatorList
            condition={condition}
            indicators={indicators}
            indicatorConfigurations={indicatorConfigurations}
            reportReminders={remindersByIndicatorId}
            isReadonly={isReadonly}
        />
    );
};

function getReminders(
    reportReminders: ReportRemindersByExternalId,
    externalId: string,
    conditionId: string
) {
    if (!externalId || !reportReminders || !reportReminders[externalId])
        return {};

    const remindersByIndicatorId = reportReminders[externalId];

    return Object.keys(remindersByIndicatorId).reduce(
        (acc: Record<string, ReportReminder>, id: string) => {
            acc[id] = remindersByIndicatorId[id].filter(
                (item) => item.conditionId === conditionId
            )[0];
            return acc;
        },
        {}
    );
}
