import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Smoking',
        id: 'indicator.smoke.title'
    },
    question: {
        defaultMessage: 'Have you smoked?',
        id: 'indicator.smoke.question'
    },
    description: {
        defaultMessage:
            'How much have you smoked the chosen date?  \n\n*(If you have already reported on that date, then this report will be added to the previous report.)*',
        id: 'indicator.smoke.description'
    },
    values: {
        id: 'indicator.smoke.values',
        defaultMessage:
            '{value} {value, plural, one {cigarette} other {cigarettes}}'
    },
    unit: {
        id: 'indicator.smoke.unit',
        defaultMessage: '{value, plural, one {cigarette} other {cigarettes}}'
    }
});

const choices = defineMessages({
    none: {
        defaultMessage: 'Cigarettes',
        id: 'indicator.smoke.choices.0'
    },
    few: {
        defaultMessage: 'Cigarette',
        id: 'indicator.smoke.choices.1'
    },
    several: {
        defaultMessage: 'Cigarettes',
        id: 'indicator.smoke.choices.2'
    },
    pack: {
        defaultMessage: 'Cigarettes',
        id: 'indicator.smoke.choices.3'
    },
    lots: {
        defaultMessage: 'Cigarettes',
        id: 'indicator.smoke.choices.4'
    },
    horde: {
        defaultMessage: 'Cigarettes or more',
        id: 'indicator.smoke.choices.5'
    }
});

const heatmapChoices = defineMessages({
    none: {
        defaultMessage: '0 cigarettes',
        id: 'indicator.smoke.range-choices.0'
    },
    few: {
        defaultMessage: '1-5 cigarettes',
        id: 'indicator.smoke.range-choices.1'
    },
    several: {
        defaultMessage: '6-10 cigarettes',
        id: 'indicator.smoke.range-choices.2'
    },
    pack: {
        defaultMessage: '11-15 cigarettes',
        id: 'indicator.smoke.range-choices.3'
    },
    lots: {
        defaultMessage: '16-20 cigarettes',
        id: 'indicator.smoke.range-choices.4'
    },
    horde: {
        defaultMessage: '21 cigarettes or more',
        id: 'indicator.smoke.range-choices.5'
    }
});

export const messagesSmoke = {
    general,
    choices,
    heatmapChoices
};
