import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Waist Circumference',
        id: 'indicator.waist-circumference.title'
    },
    unit: {
        id: 'indicator.waist-circumference.unit',
        defaultMessage: 'cm'
    }
});

export const messagesWaistCircumference = {
    general
};
