export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCH_MESSAGES_PENDING = 'FETCH_MESSAGES_PENDING';
export const FETCH_MESSAGES_FULFILLED = 'FETCH_MESSAGES_FULFILLED';
export const FETCH_MESSAGES_REJECTED = 'FETCH_MESSAGES_REJECTED';

export const FETCH_CONVERSATION = 'FETCH_CONVERSATION';
export const FETCH_CONVERSATION_PENDING = 'FETCH_CONVERSATION_PENDING';
export const FETCH_CONVERSATION_FULFILLED = 'FETCH_CONVERSATION_FULFILLED';
export const FETCH_CONVERSATION_REJECTED = 'FETCH_CONVERSATION_REJECTED';

export const FETCH_OR_CREATE_CONVERSATION = 'FETCH_OR_CREATE_CONVERSATION';
export const FETCH_OR_CREATE_CONVERSATION_PENDING =
    'FETCH_OR_CREATE_CONVERSATION_PENDING';
export const FETCH_OR_CREATE_CONVERSATION_FULFILLED =
    'FETCH_OR_CREATE_CONVERSATION_FULFILLED';
export const FETCH_OR_CREATE_CONVERSATION_REJECTED =
    'FETCH_OR_CREATE_CONVERSATION_REJECTED';

export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const CREATE_CONVERSATION_PENDING = 'CREATE_CONVERSATION_PENDING';
export const CREATE_CONVERSATION_FULFILLED = 'CREATE_CONVERSATION_FULFILLED';
export const CREATE_CONVERSATION_REJECTED = 'CREATE_CONVERSATION_REJECTED';

export const POST_MESSAGE = 'POST_MESSAGE';
export const POST_MESSAGE_PENDING = 'POST_MESSAGE_PENDING';
export const POST_MESSAGE_FULFILLED = 'POST_MESSAGE_FULFILLED';
export const POST_MESSAGE_REJECTED = 'POST_MESSAGE_REJECTED';

export const UPDATE_CONVERSATION_STATUS = 'UPDATE_CONVERSATION_STATUS';
export const UPDATE_CONVERSATION_STATUS_PENDING =
    'UPDATE_CONVERSATION_STATUS_PENDING';
export const UPDATE_CONVERSATION_STATUS_FULFILLED =
    'UPDATE_CONVERSATION_STATUS_FULFILLED';
export const UPDATE_CONVERSATION_STATUS_REJECTED =
    'UPDATE_CONVERSATION_STATUS_REJECTED';

export const CLEAR_CONVERSATION_STATUS = 'CLEAR_CONVERSATION_STATUS';
export const SET_CURRENT_ACTIVE_CONVERSATION =
    'SET_CURRENT_ACTIVE_CONVERSATION';
