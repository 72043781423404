import React, { useState } from 'react';
import styled from 'styled-components';
import { Avatar, Badge, Icon, Menu, Modal, Popover } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import { ClickParam } from 'antd/lib/menu';
import { ModalFuncProps } from 'antd/lib/modal';
import { theme } from '../../../../../theme/colors/commonColors';
import { testSelectors } from '../../../../../constants/test-id';
import { useReduxSelector } from '../../../../../hooks/use-redux-selector';
import { routeLogout } from '../../../../../pages/auth/logout/route';
import { routeEducation } from '../../../../../pages/education/page/route';
import { routeMessageOfTheDay } from '../../../../../pages/message-of-the-day/route';
import { routeAbout } from '../../../../../pages/about/route';
import { NativeButtonProps } from 'antd/lib/button/button';
import './app-menu.css';
import { pathProfile } from '../../../../../pages/medic/profile/path';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../locale/format/format-message';

const TopRightMenu = (props: { onClick: (param: any) => void }) => {
    const isEducated = useReduxSelector((state) => state.user.medicEducated);

    return (
        <Menu
            selectable={false}
            onClick={(param: ClickParam) => props.onClick(param)}
        >
            {isEducated && (
                <Menu.Item key="profile">
                    <NavLink
                        to={{
                            pathname: pathProfile
                        }}
                    >
                        <Icon type="user" />
                        {formatMessage(Strings.ui.header.general.profile)}
                    </NavLink>
                </Menu.Item>
            )}
            <Menu.Item key="messageOfTheDay">
                <NavLink to={routeMessageOfTheDay.link}>
                    <Icon type="message" />
                    {formatMessage(
                        Strings.communication.general.messageOfTheDayTitle
                    )}
                </NavLink>
            </Menu.Item>
            <Menu.Item key="instruction-videos">
                <NavLink to={routeEducation.link}>
                    <Icon type={routeEducation.icon} />
                    {formatMessage(Strings.screen.education.page.title)}
                </NavLink>
            </Menu.Item>
            <Menu.Item key="about">
                <NavLink to={routeAbout.link}>
                    <Icon type="info-circle-o" />
                    {formatMessage(Strings.ui.header.general.about)}
                </NavLink>
            </Menu.Item>
            <Menu.Item
                key="signOut"
                data-test-id={testSelectors.auth.signOutButton}
            >
                <a target="_blank" rel="noopener noreferrer">
                    <Icon type="lock" />
                    {formatMessage(Strings.common.general.signOut)}
                </a>
            </Menu.Item>
        </Menu>
    );
};

const showSignoutConfirm = (logout: () => void) => {
    const modalProps: ModalFuncProps & {
        okButtonProps: NativeButtonProps & { 'data-test-id': string };
    } = {
        title: formatMessage(Strings.ui.header.general.signOutTitle),
        okText: formatMessage(Strings.ui.header.general.signOutBtnOk),
        okButtonProps: {
            'data-test-id': testSelectors.auth.signOutConfirmButton,
            className: testSelectors.auth.signOutConfirmButton
        },
        cancelText: formatMessage(Strings.ui.header.general.signOutBtnCancel),
        onOk: () => logout()
    };
    Modal.confirm(modalProps);
};

export const AppMenu = (): JSX.Element => {
    const routeHistory = useHistory();
    const [popoverVisible, setPopoverVisible] = useState(false);
    const userMeta = useReduxSelector((state) => state.user.meta);

    return (
        <StyledHeaderMenuButton
            data-test-id={testSelectors.header.headerUserMenu}
            id="header-user-menu"
        >
            <Popover
                overlayClassName="custom-popover"
                placement="bottomRight"
                content={<TopRightMenu onClick={onLogout} />}
                trigger="click"
                visible={popoverVisible}
                onVisibleChange={setPopoverVisible}
            >
                <Badge count={0}>
                    <StyledAvatar icon="user" />
                </Badge>
                <StyledHeaderTitle>{userMeta.name}</StyledHeaderTitle>
            </Popover>
        </StyledHeaderMenuButton>
    );

    function onLogout(param: ClickParam) {
        if (param.key === 'signOut') {
            showSignoutConfirm(logout);
        }
        setPopoverVisible(false);
    }

    function logout() {
        routeHistory.push(routeLogout.link);
    }
};

const StyledHeaderMenuButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: inherit;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
        cursor: pointer;
        background-color: rgba(91, 100, 194, 0.09);
        background-color: #eef2ff;
    }
`;

const StyledAvatar = styled(Avatar)`
    background-color: ${theme.layout.primary} !important;
`;

const StyledHeaderTitle = styled.span`
    font-weight: 400;
    font-size: 15px;
    margin-left: 10px;
    vertical-align: middle;
`;
