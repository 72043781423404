import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Respiratory problems',
        id: 'indicator.respiratory-problems.title'
    }
});

const status = defineMessages({
    legend: {
        id: 'indicator.respiratory-problems.status.legend',
        defaultMessage:
            'Difficulties breathing\nRespiratory rate: {range} breaths per minute'
    },
    heatmap: {
        id: 'indicator.respiratory-problems.status.heatmap',
        defaultMessage:
            'Difficulties breathing<br/>Respiratory rate: {range} breaths per minute'
    },
    noProblems: {
        defaultMessage: 'No difficulties',
        id: 'indicator.respiratory-problems.status.no-problems'
    }
});

export const messagesRespiratoryProblems = {
    general,
    status
};
