import {
    BOWEL_DYSFUNCTION,
    CAT,
    RESPIRATORY_PROBLEMS
} from '../../../../conditions/indicator-meta';

export const isCatValue = (
    value: any
): value is Record<typeof CAT.properties.sum, number> =>
    CAT.properties.sum in value;

export const isMultipropertyValue = (
    value: any
): value is Record<string, number> => Object.keys(value).length >= 1;

export const isNumber = (value: any): value is number =>
    typeof value === 'number';

export const isRespiratoryProblemValue = (
    value: any
): value is Record<typeof RESPIRATORY_PROBLEMS.properties.frequency, number> =>
    RESPIRATORY_PROBLEMS.properties.frequency in value;

export const isBowelDysfunctionValue = (
    value: any
): value is Record<typeof BOWEL_DYSFUNCTION.properties.sum, number> =>
    BOWEL_DYSFUNCTION.properties.sum in value;
