import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/orthosis-day.svg';
import { messagesOrthosisDay } from './messages/messages';
import { rules } from './orthosis-day-hs-rules';

export const parameterOrthosisDayHs = {
    code: 'ORTHOSIS-DAY-HS'
};

export const orthosisDay: Indicator = {
    code: parameterOrthosisDayHs.code,
    icon,
    messages: messagesOrthosisDay,
    rules
};
