import { Indicator } from '../../../../types/indicator/indicator.type';
import { rules } from './exercises-hs-rules';

import icon from './images/exercises.svg';
import { messagesExercises } from './messages/messages';

export const parameterExercises = {
    code: 'EXERCISES-HS'
};

export const exercises: Indicator = {
    code: parameterExercises.code,
    icon,
    messages: messagesExercises,
    rules
};
