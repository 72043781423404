import React from 'react';
import { Input, Icon } from 'antd';
import styled from 'styled-components';

type Props = {
    onChange: any;
    onEmitEmpty: any;
    value: any;
    innerRef: any;
    placeholder: string;
};

export const SearchBar = (props: Props) => {
    const {
        onChange,
        onEmitEmpty,
        value,
        innerRef,
        placeholder = 'placeholder'
    } = props;
    const suffix = value ? (
        <Icon type="close-circle" onClick={onEmitEmpty} />
    ) : null;
    const prefix = <Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />;

    return (
        <StyledSearchBarContainer>
            <Input
                className="searchbar"
                placeholder={placeholder}
                onChange={onChange}
                prefix={prefix}
                suffix={suffix}
                value={value}
                ref={innerRef}
            />
        </StyledSearchBarContainer>
    );
};

const StyledSearchBarContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    .searchbar {
        min-width: 200px;
    }
`;

export default SearchBar;
