import React from 'react';
import { MedicGroupId } from '../../../redux/domains/groups/groups.type';
import { StyledTable } from '../../ui/table/Table';
import { Admin, CareUnit, PatientAccess } from './table-columns';
import { Checkbox } from 'antd';
import { useReduxSelector } from '../../../hooks/use-redux-selector';

export const MedicGroups = (props: {
    medicGroups: MedicGroupId[];
    adminGroups: MedicGroupId[];
}) => {
    const { medicGroups, adminGroups } = props;

    const allGroupsById = useReduxSelector(
        (state) => state.groups.medicGroups.byId
    );

    return (
        <StyledTable
            style={{ marginBottom: 20 }}
            $clickable={false}
            columns={[CareUnit, PatientAccess, Admin]}
            dataSource={medicGroups
                .filter((groupId) => !!allGroupsById[groupId])
                .map((groupId) => {
                    const careUnit = `${allGroupsById[groupId]?.name}`;

                    return {
                        id: groupId,
                        key: groupId,
                        careUnit: careUnit,
                        patientAccess: (
                            <Checkbox
                                disabled={true}
                                checked={medicGroups.includes(groupId)}
                                style={{ textAlign: 'center' }}
                            />
                        ),
                        admin: (
                            <Checkbox
                                disabled={true}
                                checked={adminGroups.includes(groupId)}
                                style={{ textAlign: 'center' }}
                            />
                        )
                    };
                })}
            rowKey={(group: GroupRowType) => group.id}
            pagination={false}
            loading={false}
        />
    );
};

type GroupRowType = {
    id: MedicGroupId;
    careUnit: string;
    patientAccess: string;
};
