import { GraphState } from './chart.graph.types';

const graphInitialState: GraphState = {
    sortedValuesByCode: {},
    settings: {},
    activeGraph: null,
    yAxis: {
        default: {
            min: 0,
            max: 1
        },
        calculated: {
            min: 0,
            max: 1,
            startEndOnTick: null
        }
    }
};

export function graphGetInitialState(): GraphState {
    return JSON.parse(
        JSON.stringify(graphInitialState)
    ) as unknown as GraphState;
}
