import { IndicatorWithSettings } from '../../../../../redux/domains/indicators/indicator.type';

export function sortVisibleIndicators(
    unsortedVisibleIndicators: IndicatorWithSettings[],
    order: string[]
) {
    if (order.length === 0) {
        return unsortedVisibleIndicators;
    }

    return unsortedVisibleIndicators.sort((a, b) => {
        return order.indexOf(a.code) - order.indexOf(b.code);
    });
}
