import { useSelector } from 'react-redux';
import { getSettingsByPath } from '../redux/domains/settings/settings.selectors';
import { Settings } from '../redux/domains/settings/settings.type';
import { IndicatorCode } from '../types/indicator/indicator.type';

export const useSettings = (
    path = '',
    conditionCode?: IndicatorCode
): Record<IndicatorCode, Settings> => {
    const settingsSelector = getSettingsByPath(path, conditionCode);
    const settings = useSelector(settingsSelector);

    return settings;
};
