import { colors } from '../../../theme/colors/commonColors';
import { ChartType } from '../../../types/indicator/chart.type';
import { IndicatorChartGraphRules } from '../../../types/indicator/indicator-rules.type';
import { PainCIProperties } from './pain-ci-properties';

export const rules: IndicatorChartGraphRules<PainCIProperties> = {
    step: 1,
    chartTypes: [ChartType.Spline],
    lineColor: {
        name: colors.chart.graph.green.name,
        default: colors.chart.graph.green.forestBright,
        active: colors.chart.graph.green.forestBright,
        rest: colors.chart.graph.green.forestDark
    },
    chartOptions: {
        fixedYAxisMin: 0,
        fixedYAxisMax: 10,
        tickInterval: 2,
        hideVerticalLine: true
    },
    limits: {
        report_min_enabled: false,
        report_max_enabled: false
    },
    shouldNotRenderPlotbands: true
};
