import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/sleep.svg';
import { messagesSleepHs } from './messages/messages';
import { rules } from './sleep-hs-rules';

export const parameterSleepHS = {
    code: 'SLEEP-HS'
};

export const sleep: Indicator = {
    code: parameterSleepHS.code,
    icon,
    messages: messagesSleepHs,
    rules
};
