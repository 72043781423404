import { IndicatorCode } from '../../../../../../types/indicator/indicator.type';
import { isChartRules } from '../../../../settings/settings.type';
import {
    IndicatorConfigurationState,
    IndicatorWithSettings
} from '../../../../indicators/indicator.type';
import { createBoundaries } from './create-boundaries';
import { mergeBoundaries } from './merge-boundaries';
import { getGreenBoundary } from './get-green-boundary';
import { getChartMin } from './get-chart-min';
import { getChartMax } from './get-chart-max';
import { getConfigurationFromConditionIndicatorConfiguration } from './get-configuration-from-condition-indicator-configuration';

export function setYMinMaxPlotbands(params: {
    indicatorConfiguration: IndicatorConfigurationState;
    settingsMapping: Record<IndicatorCode, IndicatorWithSettings>;
    code: IndicatorCode;
}) {
    const code = params.code;
    const indicatorConfiguration = params.indicatorConfiguration.configuration;
    const indicator = params.settingsMapping[code];

    let maybeMin;
    let maybeMax;
    let fixedYAxisMin;
    let fixedYAxisMax;

    if (
        isChartRules(indicator.settings.rules) &&
        indicator.settings.rules.chartOptions
    ) {
        maybeMin = indicator.settings.rules.chartOptions.fixedYAxisMin;
        fixedYAxisMin = typeof maybeMin === 'number' ? maybeMin : false;
    }
    if (
        isChartRules(indicator.settings.rules) &&
        indicator.settings.rules.chartOptions
    ) {
        maybeMax = indicator.settings.rules.chartOptions.fixedYAxisMax;
        fixedYAxisMax = typeof maybeMax === 'number' ? maybeMax : false;
    }

    const useReportMax =
        (isChartRules(indicator.settings.rules) &&
            indicator.settings.rules.chartOptions?.useReportMax) ||
        false;
    const useReportMin =
        (isChartRules(indicator.settings.rules) &&
            indicator.settings.rules.chartOptions?.useReportMin) ||
        false;

    const {
        report_min,
        lower_red,
        lower_yellow,
        upper_yellow,
        upper_red,
        report_max
    } = getConfigurationFromConditionIndicatorConfiguration(
        indicatorConfiguration
    );

    const chartMin = report_min || 0;
    const chartMax = report_max || 1;
    const chartSpan = chartMax - chartMin;
    const lowerBoundaries = createBoundaries(
        {
            report_min,
            lower_red,
            lower_yellow
        },
        chartMin,
        Math.min
    );

    const upperBoundaries = createBoundaries(
        {
            report_max,
            upper_red,
            upper_yellow
        },
        chartMax,
        Math.max
    );

    const greenBoundary = getGreenBoundary(
        lowerBoundaries,
        upperBoundaries,
        report_min,
        report_max
    );

    const plotBands = mergeBoundaries(
        lowerBoundaries,
        greenBoundary,
        upperBoundaries
    );

    const margin = chartSpan * 0.1;

    const min = getChartMin(
        chartMin,
        // @ts-ignore
        fixedYAxisMin,
        useReportMin,
        lowerBoundaries,
        report_min,
        margin,
        greenBoundary
    );

    const max = getChartMax(
        chartMax,
        //@ts-ignore
        fixedYAxisMax,
        useReportMax,
        upperBoundaries,
        report_max,
        margin,
        greenBoundary
    );

    return { plotBands, min, max };
}
