import { getAxis } from './axis/get-axis';
import { IndicatorCode } from '../../../../types/indicator/indicator.type';
import {
    Condition,
    ConditionConfiguration
} from '../../conditions/conditions.type';
import { IndicatorWithSettings } from '../../indicators/indicator.type';
import {
    GraphSettingsByIndicatorCode,
    GraphState,
    IndicatorGraphSettings
} from './chart.graph.types';
import { ValuesIndicator } from '../../values/values.type';
import { GraphValuesSorted } from '../../../../types/indicator/indicator-value.type';
import { sortByProperty } from '../../../../utils/create-condition-settings/create-settings';
import { IndicatorSettingsMapping } from '../../../../conditions/condition.type';

export function getActiveIndicatorGraph(
    state: GraphState
): IndicatorGraphSettings | null {
    const activeChartIndicators = getActiveChartIndicators(state);
    const showPlotBand = activeChartIndicators.length === 1;

    if (showPlotBand) {
        if (activeChartIndicators.length === 1) {
            return activeChartIndicators[0];
        }
    }

    return null;

    function getActiveChartIndicators(
        state: GraphState
    ): IndicatorGraphSettings[] {
        return Object.values(state.settings)
            .map((s) => s)
            .filter((s) => s.hidden === false);
    }
}

function getInitialGraphSettingsForIndicator(params: {
    condition: Condition;
    chartIndicatorsMapping: Record<IndicatorCode, IndicatorWithSettings>;
    indicatorCode: IndicatorCode;
    conditionsConfiguration: ConditionConfiguration;
    indicatorsSettingsMapping: IndicatorSettingsMapping;
}): IndicatorGraphSettings {
    const conditionId = params.condition.id;
    const indicatorCode = params.indicatorCode;

    const indicator = params.chartIndicatorsMapping[indicatorCode];
    const indicators = params.chartIndicatorsMapping;
    const indicatorSettings = params.indicatorsSettingsMapping[indicatorCode];
    const indicatorConfiguration =
        params.conditionsConfiguration[conditionId][indicator.id];

    const divideBy = indicatorSettings?.rules.divideBy;

    const yAxis = getAxis({
        code: indicatorCode,
        chartIndicators: indicators,
        indicatorConfiguration: indicatorConfiguration
    });

    const _yAxis = {
        max: yAxis.max,
        min: yAxis.min,
        plotbands: yAxis.plotbands || []
    };

    if (divideBy) {
        _yAxis.max = yAxis.max / divideBy;
        _yAxis.min = yAxis.min / divideBy;
    }

    return {
        code: params.indicatorCode,
        hidden: false,
        yAxis: _yAxis,
        indicatorConfiguration: indicatorConfiguration,
        indicatorSettings: indicatorSettings
    };
}

export function graphGenerateSettings(params: {
    indicators: Record<string, IndicatorWithSettings>;
    condition: Condition;
    conditionConfiguration: ConditionConfiguration;
    indicatorSettingsMapping: IndicatorSettingsMapping;
}) {
    const indicators = params.indicators;
    const condition = params.condition;
    const conditionConfiguration = params.conditionConfiguration;
    const indicatorSettings = params.indicatorSettingsMapping;

    const newGraphSettings: Record<IndicatorCode, IndicatorGraphSettings> = {};

    Object.keys(indicators).forEach((code) =>
        Object.assign(newGraphSettings, {
            [code]: getInitialGraphSettingsForIndicator({
                indicatorCode: code,
                chartIndicatorsMapping: indicators,
                condition: condition,
                conditionsConfiguration: conditionConfiguration,
                indicatorsSettingsMapping: indicatorSettings
            })
        })
    );

    return newGraphSettings;
}

export function graphSortValuesByCode(params: {
    valuesByCode: Record<IndicatorCode, ValuesIndicator | undefined>;
    indicatorsByCode: Record<IndicatorCode, IndicatorWithSettings>;
}) {
    const sorted: Record<IndicatorCode, GraphValuesSorted[]> = {};
    const values = params.valuesByCode;
    const indicators = params.indicatorsByCode;

    Object.keys(indicators).forEach((code) => {
        Object.assign(sorted, {
            [code]: sortByProperty(values[code]?.values || [], 'date')
        });
    });

    return sorted;
}

/**
 * @deprecated May be deleted in the furure - 20220919
 */
export function getYAxisTickIntervalExternal(state: GraphState) {
    const activeGraph = state.activeGraph;

    if (activeGraph) {
        if (typeof activeGraph.yAxis.max === 'number') {
            if (typeof activeGraph.yAxis.min === 'number') {
                return (activeGraph.yAxis.max - activeGraph.yAxis.min) / 5;
            }
        }
        return null;
    }
    return state.yAxis.default.max / 5;
}

export function getGraphYAxisMax(state: GraphState) {
    const { settings } = state;

    if (getIsAnyIndicatorHidden(settings)) {
        return getMaxWhenHidden(settings);
    }

    if (typeof state.activeGraph?.yAxis.max === 'number') {
        return state.activeGraph
            ? state.activeGraph.yAxis.max
            : state.yAxis.default.max;
    }
    return state.yAxis.default.max;
}

function getIsAnyIndicatorHidden(settings: GraphSettingsByIndicatorCode) {
    return !!Object.values(settings).filter((indicator) => indicator.hidden)
        .length;
}

export function getGraphYAxisMin(state: GraphState) {
    const { settings } = state;

    if (getIsAnyIndicatorHidden(settings)) {
        return getMinWhenHidden(settings);
    }

    if (typeof state.activeGraph?.yAxis.min === 'number') {
        return state.activeGraph
            ? state.activeGraph.yAxis.min
            : state.yAxis.default.min;
    }

    return state.yAxis.default.min;
}

function getMinWhenHidden(settings: GraphSettingsByIndicatorCode) {
    const minimums = Object.values(settings)
        .filter((a) => !a.hidden)
        .map((b) => {
            return b.yAxis.min || 0;
        })
        .filter((c) => {
            return typeof c === 'number';
        });

    return Math.min(...minimums);
}

function getMaxWhenHidden(settings: GraphSettingsByIndicatorCode) {
    const maximums = Object.values(settings)
        .filter((a) => !a.hidden)
        .map((b) => {
            return b.yAxis.max || 0;
        })
        .filter((c) => {
            return typeof c === 'number';
        });

    return Math.max(...maximums);
}

export function getYAxisStartEndOnTick(state: GraphState) {
    return state.activeGraph && state.activeGraph.yAxis.plotbands.length === 0;
}

export function getGraphYAxisMinMaxByCode(params: {
    settings: Record<IndicatorCode, IndicatorGraphSettings>;
}) {
    const settings = params.settings;
    const max = Math.max(
        ...[
            1,
            ...(Object.keys(settings)
                .map((indicatorCode) => {
                    const max = settings[indicatorCode].yAxis.max;
                    const divideBy =
                        settings[indicatorCode].indicatorSettings.rules
                            .divideBy;

                    if (divideBy) {
                        if (typeof max === 'number') {
                            return max / divideBy;
                        }
                    }
                    return max;
                })
                .filter((max) => max != null) as number[])
        ]
    );

    const min = Math.min(
        ...[
            0,
            ...(Object.values(settings)
                .map((setting) => setting.yAxis.min)
                .filter((min) => min != null) as number[])
        ]
    );
    return { max, min };
}
