import { combineReducers } from 'redux';
import {
    SAVE_REGISTER_FORM_EXTERNAL_ID,
    SAVE_REGISTER_FORM_EXTERNAL_ID_AND_REASON
} from '../active-patient/active-patient.constants';
import {
    CLEAR_REGISTER_FORM_FIELDS,
    SAVE_REGISTER_FORM_FIELDS
} from './forms.constants';
import { Forms } from './forms.type';

const initialState: Forms = {
    registerPatient: {
        values: {},
        externalId: '',
        reason: undefined,
        note: undefined
    }
};

function registerPatient(
    state = initialState.registerPatient,
    action: any
): typeof initialState.registerPatient {
    const { payload, type } = action;
    switch (type) {
        case SAVE_REGISTER_FORM_FIELDS: {
            return { ...state, values: { ...payload } };
        }
        case CLEAR_REGISTER_FORM_FIELDS: {
            return initialState.registerPatient;
        }
        case SAVE_REGISTER_FORM_EXTERNAL_ID: {
            return {
                ...state,
                externalId: payload,
                reason: undefined,
                note: undefined
            };
        }
        case SAVE_REGISTER_FORM_EXTERNAL_ID_AND_REASON: {
            return { ...state, ...payload };
        }
        default:
            return state;
    }
}

const formsReducer = combineReducers({
    registerPatient
});

export default formsReducer;
