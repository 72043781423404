import React from 'react';
import { Checkbox } from 'antd';
import { useReduxSelector } from '../../../../../hooks/use-redux-selector';
import { styled } from '../../../../../theme/styled';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';

type Props = {
    filterPatients: Function;
};

const PatientFilter = ({ filterPatients }: Props) => {
    const newMessages = useReduxSelector(
        (state) => state.ui.overview.filter.newMessages
    );

    return (
        <StyledFilterContainer>
            <StyledCheckbox
                checked={newMessages}
                onChange={(e) => {
                    const { checked } = e.target;
                    filterPatients(checked);
                }}
            >
                {formatMessage(
                    Strings.filter.general.togglePatientsByUnreadMessages
                )}
            </StyledCheckbox>
        </StyledFilterContainer>
    );
};

const StyledFilterContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 70px;
    margin-right: 10px;

    @media (max-width: ${(props) =>
            props.theme.layout.mediaQuerySize.width.laptop}) {
        margin: auto 0;
    }
`;

const StyledCheckbox = styled(Checkbox)`
    label& {
        display: flex;
        align-items: center;

        .ant-checkbox {
            top: 1px;
        }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${(props) => props.theme.color.red};
    }

    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-checked::after {
        border-color: ${(props) => props.theme.color.red};
    }

    .ant-checkbox-checked + span {
        color: ${(props) => props.theme.color.red};
    }
`;

export default PatientFilter;
