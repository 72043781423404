import React from 'react';
import { Card, Icon } from 'antd';
import { ContentLayout } from '../../../../components/content/ContentLayout';
import { AppNeutralButton } from '../../../../components/ui/buttons/AppNeutralButton';
import { messages } from '../messages';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';

type Props = {
    onBack: () => void;
};

export const EducationFormError = ({ onBack }: Props): JSX.Element => (
    <ContentLayout>
        <Card>
            <h2 style={{ marginBottom: 20 }}>
                {formatMessage(messages.educationForm.errorHeader)}
            </h2>
            <p>{formatMessage(messages.educationForm.errorInformation)}</p>
            <AppNeutralButton onClick={onBack}>
                <Icon type="arrow-left" />
                {formatMessage(Strings.navigation.general.back)}
            </AppNeutralButton>
        </Card>
    </ContentLayout>
);
