import React from 'react';
import { Strings } from '../../../locale/messagesDescriptors';
import { formatMessage } from '../../../locale/format/format-message';

export const PhoneLink = ({ phone }: { phone: string | null }) => {
    if (phone) {
        return (
            <a onClick={() => (window.location.href = `tel:${phone}`)}>
                {phone}
            </a>
        );
    }
    return <>{formatMessage(Strings.common.general.missing)}</>;
};
