import { RouteItemType } from '../../../utils/route/switch-routes';
import { Sso } from './Sso';

export const routeSso: RouteItemType = {
    name: '',
    link: '/sso/:sessionId',
    icon: '',
    exact: true,
    component: Sso
};
