import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    getMedicGroupIds,
    getVisibleMedicGroups
} from '../../../../../../../redux/domains/patient-data/patient-data.selectors';
import { fetchPatientDataMedicGroups } from '../../../../../../../redux/domains/patient-data/patient-data.actions';

export function useMedicGroupsForPatient() {
    const dispatch = useDispatch();
    const medicGroupIds = useSelector(getMedicGroupIds, shallowEqual);

    useEffect(() => {
        if (medicGroupIds.length) {
            dispatch(fetchPatientDataMedicGroups(medicGroupIds));
        }
    }, [dispatch, medicGroupIds]);

    return useSelector(getVisibleMedicGroups);
}
