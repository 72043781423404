import { colors } from '../../../theme/colors/commonColors';
import { ChartType } from '../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../types/indicator/indicator-rules.type';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

export const rules: IndicatorChartHeatmapRules = {
    step: 100,
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        { color: colors.chart.heatMap.purple80, min: 1, max: 1000 }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },

        {
            textId: Strings.indicators.vegetables.general.legendYes.id,
            text: formatMessage(
                Strings.indicators.vegetables.general.legendYes
            ),
            color: colors.chart.heatMap.purple80
        }
    ]
};
