import { defineMessages } from 'react-intl';

const tableData = defineMessages({
    watchedOver: {
        id: 'shared.ui.tableDate.WatchedOver',
        defaultMessage: 'Not checked yet'
    }
});

const general = defineMessages({
    actions: {
        id: 'shared.ui.tabHeader.actions',
        defaultMessage: 'Actions'
    },
    watchedOver: {
        id: 'shared.ui.tabHeader.watchedOver',
        defaultMessage: 'Checked'
    },
    date: {
        id: 'shared.ui.tabHeader.date',
        defaultMessage: 'Date'
    },
    notes: {
        id: 'shared.ui.tabHeader.notes',
        defaultMessage: 'Comment'
    },
    sum: {
        id: 'shared.ui.tabHeader.sum',
        defaultMessage: 'Sum'
    },
    ehfscbsScore: {
        id: 'shared.ui.tabHeader.ehfscbs-score',
        defaultMessage: 'Score'
    },
    assertion: {
        id: 'shared.ui.tabHeader.assertion',
        defaultMessage: 'Assertion'
    },
    answer: {
        id: 'shared.ui.tabHeader.answer',
        defaultMessage: 'Answer'
    }
});

export const table = {
    general,
    tableData
};
