import indicatorRules from '../../utils/create-condition-settings/indicator-rules';
import { indicatorItem } from '../../utils/create-condition-settings/items';
import { reasonsStatusChange } from '../../utils/create-condition-settings/reasons-status-change';
import { ConditionRules } from '../condition.type';
import { IndicatorMeta } from '../indicator-meta';

export const createFakeConditionConfiguration = (): ConditionRules => {
    return {
        initialObservationIndicators: [],
        priorityTable: {
            hidden: [],
            order: [
                IndicatorMeta.BLOOD_SUGAR.code,
                IndicatorMeta.WEIGHT_PD.code
            ]
        },
        indicatorSettings: {
            preventFromEditLimits: [],
            preventFromEditReminders: [],
            preventFromToggle: []
        },
        reasons: reasonsStatusChange(),
        indicators: {
            [IndicatorMeta.BLOOD_SUGAR.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BLOOD_SUGAR.code]
            ),
            [IndicatorMeta.WEIGHT_PD.code]: indicatorItem(
                indicatorRules[IndicatorMeta.WEIGHT_PD.code]
            )
        }
    };
};
