import { colors } from '../../../theme/colors/commonColors';
import { ChartType } from '../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../types/indicator/indicator-rules.type';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

export const rules: IndicatorChartHeatmapRules = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        { value: 1, color: colors.chart.heatMap.purple10 },
        { value: 2, color: colors.chart.heatMap.purple30 },
        { value: 3, color: colors.chart.heatMap.purple50 },
        { value: 4, color: colors.chart.heatMap.purple80 }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },

        {
            textId: Strings.indicators.breathlessness.choices.unaffected.id,
            text: formatMessage(
                Strings.indicators.breathlessness.choices.unaffected
            ),
            color: colors.chart.heatMap.purple10
        },

        {
            textId: Strings.indicators.breathlessness.choices.moderateActivity
                .id,
            text: formatMessage(
                Strings.indicators.breathlessness.choices.moderateActivity
            ),
            color: colors.chart.heatMap.purple30
        },

        {
            textId: Strings.indicators.breathlessness.choices.lightActivity.id,
            text: formatMessage(
                Strings.indicators.breathlessness.choices.lightActivity
            ),
            color: colors.chart.heatMap.purple50
        },
        {
            textId: Strings.indicators.breathlessness.choices.rest.id,
            text: formatMessage(Strings.indicators.breathlessness.choices.rest),
            color: colors.chart.heatMap.purple80
        }
    ],
    boundariesConfiguration: {
        type: 'radio'
    },
    choices: [
        {
            localeId: Strings.indicators.breathlessness.choices.unaffected.id,
            text: formatMessage(
                Strings.indicators.breathlessness.choices.unaffected
            ),
            value: 1
        },
        {
            localeId:
                Strings.indicators.breathlessness.choices.moderateActivity.id,
            text: formatMessage(
                Strings.indicators.breathlessness.choices.moderateActivity
            ),
            value: 2
        },
        {
            localeId:
                Strings.indicators.breathlessness.choices.lightActivity.id,
            text: formatMessage(
                Strings.indicators.breathlessness.choices.lightActivity
            ),
            value: 3
        },
        {
            localeId: Strings.indicators.breathlessness.choices.rest.id,
            text: formatMessage(Strings.indicators.breathlessness.choices.rest),
            value: 4
        }
    ]
};
