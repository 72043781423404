import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Breathlessness',
        id: 'indicator.breathlessness.title'
    }
});

const choices = defineMessages({
    unaffected: {
        defaultMessage: 'Completely unaffected',
        id: 'indicator.breathlessness.choices.1'
    },
    moderateActivity: {
        defaultMessage: 'Breathless from more than moderate activity',
        id: 'indicator.breathlessness.choices.2'
    },
    lightActivity: {
        defaultMessage: 'Breathless from light activity',
        id: 'indicator.breathlessness.choices.3'
    },
    rest: {
        defaultMessage: 'Breathless at rest',
        id: 'indicator.breathlessness.choices.4'
    }
});

export const messagesBreathlessness = {
    general,
    choices
};
