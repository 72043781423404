import React from 'react';
import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import { IndicatorCode } from '../../../../types/indicator/indicator.type';
import { MultiPropertySplineSeries } from './MultiPropertySplineSeries';
import { multiCreateColumnRangeData } from './utils/multi-create-column-range-data';
import { isChartRules } from '../../../../redux/domains/settings/settings.type';
import {
    GraphValuesSorted,
    IndicatorValue
} from '../../../../types/indicator/indicator-value.type';
import { IndicatorSettingsMapping } from '../../../../conditions/condition.type';

export function MultiPropertyIndicators(props: {
    indicator: IndicatorWithSettings;
    sortedGraphValuesByDate: Record<
        IndicatorCode,
        GraphValuesSorted<IndicatorValue>[]
    >;
    chartIndicators: Record<IndicatorCode, IndicatorWithSettings>;
    settingsMapping: IndicatorSettingsMapping;
    sharedTooltips: (value: boolean) => void;
}) {
    const indicator = props.indicator;
    const code = indicator.code;

    const sortedGraphValuesByDate = props.sortedGraphValuesByDate;
    const chartIndicator = props.chartIndicators[code];
    const settingsMapping = props.settingsMapping;
    const sharedTooltips = props.sharedTooltips;
    if (Object.keys(sortedGraphValuesByDate).length === 0) {
        return null;
    }
    const sortedIndicator = sortedGraphValuesByDate[code];
    const verticalLines =
        (sortedIndicator && sortedIndicator.map(multiCreateColumnRangeData)) ||
        [];

    const showVerticalLine =
        isChartRules(indicator.settings.rules) &&
        !indicator.settings.rules.chartOptions?.hideVerticalLine;

    return (
        <MultiPropertySplineSeries
            key={code}
            chartIndicator={chartIndicator}
            data={showVerticalLine ? verticalLines : []}
            settingsMapping={settingsMapping}
            sortedValues={sortedGraphValuesByDate[code]}
            sharedTooltips={sharedTooltips}
        />
    );
}
