import { RouteItemType } from '../../../utils/route/switch-routes';
import { pathRegisterMedic } from './path';
import { CreateMedicPage } from './CreateMedicPage';

export const routeMedicRegister: RouteItemType = {
    name: '',
    link: pathRegisterMedic,
    icon: '',
    component: CreateMedicPage
};
