import { formatPersonalId } from '../../utils';

export const formatPatients = (patients: any) => {
    patients = Object.keys(patients).reduce((acc: any, curr: any) => {
        return {
            ...acc,
            [curr]: {
                ...patients[curr],
                meta: {
                    ...patients[curr].meta,
                    personal_id:
                        patients[curr].meta != null
                            ? formatPersonalId(patients[curr].meta.personal_id)
                            : ''
                }
            }
        };
    }, {});
    return patients;
};
