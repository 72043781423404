import { userStorageKeys } from './user-storage-keys';
import { getUserValue } from './get-user-value';

export function getUserObj(userId: string): { [key: string]: string } {
    return Object.keys(userStorageKeys).reduce(
        (userObj: Record<string, string>, key: string) => {
            userObj[key] = getUserValue(userId, key);
            return userObj;
        },
        {}
    );
}
