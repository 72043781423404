import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        id: 'reset.general.title',
        defaultMessage: 'Reset'
    },
    notResetYet: {
        id: 'reset.general.not-reset-yet',
        defaultMessage: 'Not reset yet'
    },
    registerReset: {
        id: 'reset.general.register-reset',
        defaultMessage: 'Resets priority...'
    },
    registeredReset: {
        id: 'reset.general.registered-reset',
        defaultMessage: 'Successfully reset priority!'
    },
    resetPriorityReason: {
        id: 'reset.general.reset-priority-reason',
        defaultMessage:
            '{reason, select, changed_limits {Changed limits} changed_medication {Medicine adjustment} changed_medication_dizzy {Med. adjustment due to dizziness} changed_medication_other {Med. adjustment due to other reason} changed_medication_swollen {Med. adjustment due to swelling} other {Other}}'
    }
});

export const reset = { general };
