import React from 'react';
import { Card, Tabs } from 'antd';
import { ContentLayout } from '../../../components/content/ContentLayout';
import styled from 'styled-components';
import { GroupsSearchTab } from './content/GroupsSearchTab';
import { SystemSearchTab } from './content/SystemSearchTab';
import { patientSearch } from './messages';
import { usePageTitle } from '../../../hooks/use-page-title';
import { titlePatientSearch } from './title';
import { formatMessage } from '../../../locale/format/format-message';

export const PatientSearchPage = (): JSX.Element => {
    usePageTitle(titlePatientSearch);

    return (
        <ContentLayout>
            <Card>
                <Tabs type="card" defaultActiveKey="group-search">
                    <Tabs.TabPane
                        key="group-search"
                        tab={
                            <StyledTab>
                                {formatMessage(patientSearch.tabs.groupSearch)}
                            </StyledTab>
                        }
                    >
                        <GroupsSearchTab />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        key="system-search"
                        tab={
                            <StyledTab>
                                {formatMessage(patientSearch.tabs.systemSearch)}
                            </StyledTab>
                        }
                    >
                        <SystemSearchTab />
                    </Tabs.TabPane>
                </Tabs>
            </Card>
        </ContentLayout>
    );
};

const StyledTab = styled.span`
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
`;
