import { Indicator } from '../../../types/indicator/indicator.type';

import icon from './images/tiredness.svg';
import { messagesTiredness } from './messages/messages';
import { rules } from './tiredness-rules';

export const parameterTiredness = {
    code: 'TIREDNESS'
};

export const tiredness: Indicator = {
    code: parameterTiredness.code,
    icon,
    messages: messagesTiredness,
    rules
};
