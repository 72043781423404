import { authMethods } from './authMethods';
import { authText } from './authText';
import { chart } from './chart';
import { chat } from './chat';
import { common } from './common';
import { communication } from './communication';
import { conditions } from './conditions';
import { confirm } from './confirm';
import { constants } from './constant';
import { date } from './date';
import { editPatient } from './screen/patientAdministration/editPatient/editPatient';
import { editPatientGroup } from './screen/patientAdministration/editPatientGroup/editPatientGroup';
import { events } from './events';
import { field } from './field';
import { filter } from './filter';
import { form } from './form';
import { graph } from './graph';
import { indicatorConfig } from './indicatorConfig';
import { indicators } from './indicators';
import { indicatorText } from './indicatorText';
import { labels } from './labels';
import { limits } from './limits';
import { navigation } from './navigation';
import { notification } from './notification';
import { other } from './other';
import { patient } from './patient';
import { reminders } from './reminders';
import { statusChange } from './status-change';
import { resetPriority } from './reset-priority';
import { roles } from './roles';
import { screen } from './screen';
import { statistics } from './statistics';
import { trend } from './trend';
import { ui } from './ui';
import { values } from './values';
import { video } from './video';
import { patientSearch } from '../../pages/patient/search/messages';
import { errorMessages } from './errors';

export const Strings = {
    authMethods,
    authText,
    chart,
    chat,
    common,
    communication,
    confirm,
    conditions,
    constants,
    date,
    editPatient,
    editPatientGroup,
    error: errorMessages,
    events,
    field,
    filter,
    form,
    graph,
    indicatorConfig,
    indicators,
    indicatorText,
    labels,
    limits,
    navigation,
    notification,
    other,
    patient,
    reminders,
    statusChange,
    resetPriority,
    roles,
    patientSearch,
    screen,
    statistics,
    trend,
    ui,
    values,
    video
};
