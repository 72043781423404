import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Sleep',
        id: 'indicator.sleep-sci.title'
    },
    affectedBy: {
        defaultMessage: 'Mainly affected by',
        id: 'indicator.sleep.sci.affectedBy'
    }
});

const choices = defineMessages({
    none: {
        defaultMessage: 'Unaffected',
        id: 'indicator.sleep-sci.choices.1'
    },
    some: {
        defaultMessage: 'Affected to some extent',
        id: 'indicator.sleep-sci.choices.2'
    },
    much: {
        defaultMessage: 'Very much affected',
        id: 'indicator.sleep-sci.choices.3'
    }
});

const issues = defineMessages({
    pain: {
        defaultMessage: 'Pain',
        id: 'indicator.sleep-sci.issues.1'
    },
    spasticity: {
        defaultMessage: 'Spasticity',
        id: 'indicator.sleep-sci.issues.2'
    },
    leakingUrine: {
        defaultMessage: 'Leaking urine',
        id: 'indicator.sleep-sci.issues.3'
    },
    bowelDysfunction: {
        defaultMessage: 'Bowel dysfunction',
        id: 'indicator.sleep-sci.issues.4'
    },
    respiratoryProblems: {
        defaultMessage: 'Respiratory problems',
        id: 'indicator.sleep-sci.issues.5'
    },
    other: {
        defaultMessage: 'Other',
        id: 'indicator.sleep-sci.issues.6'
    }
});

export const messagesSleepSCI = {
    general,
    issues,
    choices
};
