import { colors } from '../../../../theme/colors/commonColors';
import { ChartType } from '../../../../types/indicator/chart.type';
import { IndicatorChartGraphRules } from '../../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartGraphRules<'sum'> = {
    step: 1,
    chartTypes: [ChartType.Spline],
    lineColor: {
        name: colors.chart.graph.yellow.name,
        default: colors.chart.graph.yellow.primary,
        sum: colors.chart.graph.yellow.primary
    },
    chartOptions: {
        fixedYAxisMin: 0,
        fixedYAxisMax: 100,
        tickInterval: 20
    }
};
