import { education } from './education';
import { main } from './main';
import { patientOverview } from './patientOverview';
import { patientAdministration } from './patientAdministration';
import { patient } from './patient';

const screen = {
    main,
    education,
    patientOverview,
    patient,
    patientAdministration
};

export { screen };
