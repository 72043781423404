import { Strings } from '../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../locale/format/format-message';
import { colors } from '../../../../theme/colors/commonColors';
import { ChartType } from '../../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartHeatmapRules = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        { value: 1, color: colors.chart.heatMap.purple10 },
        { value: 2, color: colors.chart.heatMap.purple30 },
        { value: 3, color: colors.chart.heatMap.purple50 },
        { value: 4, color: colors.chart.heatMap.purple60 },
        { value: 5, color: colors.chart.heatMap.purple80 }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.ehra.choices.none.id,
            text: formatMessage(Strings.indicators.ehra.choices.none),
            color: colors.chart.heatMap.purple10
        },
        {
            textId: Strings.indicators.ehra.choices.notAffected.id,
            text: formatMessage(Strings.indicators.ehra.choices.notAffected),
            color: colors.chart.heatMap.purple30
        },
        {
            textId: Strings.indicators.ehra.choices.troubledBySymptoms.id,
            text: formatMessage(
                Strings.indicators.ehra.choices.troubledBySymptoms
            ),
            color: colors.chart.heatMap.purple50
        },
        {
            textId: Strings.indicators.ehra.choices.symptomsRelated.id,
            text: formatMessage(
                Strings.indicators.ehra.choices.symptomsRelated
            ),
            color: colors.chart.heatMap.purple60
        },
        {
            textId: Strings.indicators.ehra.choices.activityDiscontinued.id,
            text: formatMessage(
                Strings.indicators.ehra.choices.activityDiscontinued
            ),
            color: colors.chart.heatMap.purple80
        }
    ],
    boundariesConfiguration: {
        type: 'radio'
    },
    choices: [
        {
            localeId: Strings.indicators.ehra.choices.none.id,
            text: formatMessage(Strings.indicators.ehra.choices.none),
            value: 1
        },
        {
            localeId: Strings.indicators.ehra.choices.notAffected.id,
            text: formatMessage(Strings.indicators.ehra.choices.notAffected),
            value: 2
        },
        {
            localeId: Strings.indicators.ehra.choices.troubledBySymptoms.id,
            text: formatMessage(
                Strings.indicators.ehra.choices.troubledBySymptoms
            ),
            value: 3
        },
        {
            localeId: Strings.indicators.ehra.choices.symptomsRelated.id,
            text: formatMessage(
                Strings.indicators.ehra.choices.symptomsRelated
            ),
            value: 4
        },
        {
            localeId: Strings.indicators.ehra.choices.activityDiscontinued.id,
            text: formatMessage(
                Strings.indicators.ehra.choices.activityDiscontinued
            ),
            value: 5
        }
    ]
};
