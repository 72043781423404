import { HTTPClient } from '../../../services/http-client/http-client';
import { ConditionResponse } from './conditions.api.type';
import {
    normalizeConditions,
    NormalizedConditionsState
} from './conditions.normalizer';

export const getExpandedIndicatorsFromConditions = async (
    conditionIds: string
): Promise<NormalizedConditionsState> => {
    const params = {
        expand: 'indicators'
    };
    return normalizeConditions(
        await HTTPClient.get(`conditions/${conditionIds}`, { params })
    );
};
