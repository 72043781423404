import React from 'react';
import styled from 'styled-components';

export function Progressbar(props: { progress: number }) {
    const progressNumber = props.progress > 0 ? props.progress : 0;

    const Parentdiv = {
        height: 30,
        backgroundColor: 'whitesmoke',
        borderRadius: 10
    };

    const Childdiv = {
        height: '100%',
        width: `${progressNumber}%`,
        backgroundColor: '#5b3495',
        borderRadius: 10
    };

    const progresstext = {
        padding: 5,
        color: 'black',
        fontWeight: 900
    };

    return (
        <StyledProgressBar>
            <div className="parentDiv" style={Parentdiv}>
                <div style={Childdiv} />
            </div>
            <span style={progresstext}>{`${progressNumber}%`}</span>
        </StyledProgressBar>
    );
}

const StyledProgressBar = styled.div`
    display: grid;
    grid-template-columns: 1fr 50px;
`;

export const StyledParent = styled.div``;
