import { RouteItemType } from '../../utils/route/switch-routes';
import { AboutPage } from './AboutPage';
import { pathAbout } from './path';
import { titleAbout } from './title';

export const routeAbout: RouteItemType = {
    name: titleAbout,
    link: pathAbout,
    icon: '',
    component: AboutPage
};
