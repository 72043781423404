import { Strings } from '../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../locale/format/format-message';
import { colors } from '../../../../theme/colors/commonColors';
import { ChartType } from '../../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartHeatmapRules = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [{ color: colors.chart.heatMap.purple80, min: 0, max: 99 }],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.prnmedLvad.general.legendYes.id,
            text: formatMessage(
                Strings.indicators.prnmedLvad.general.legendYes
            ),
            color: colors.chart.heatMap.purple80
        }
    ]
};
