import { defineMessages } from 'react-intl';

const general = defineMessages({
    email: {
        id: 'shared.auth.email',
        defaultMessage: 'Email'
    },
    bankid: {
        id: 'shared.auth.bankid',
        defaultMessage: 'BankID'
    },
    siths: {
        id: 'shared.auth.siths',
        defaultMessage: 'SITHS'
    }
});

export const authMethods = {
    rootId: 'shared.auth.',
    general
};
