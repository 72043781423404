import {
    PHYSACT,
    MEDICATION_CI,
    PHLEGM,
    PRNMED,
    PRNMED_AF,
    PRNMED_CI,
    PRNMED_COPD,
    PRNMED_LVAD,
    PUMP_SELF_TEST_LVAD,
    SMOKE,
    SWELLING,
    SLEEP_SCI,
    RESPIRATORY_PROBLEMS,
    BOWEL_DYSFUNCTION
} from '../../../conditions/indicator-meta';
import { sumByDay } from '../../../conditions/shared-indicators/smoke/utils/sum-by-day';
import { DateValueTuple, Values } from './heatmap.type';

const getValuesByProperty = (
    values: Values,
    property: string,
    getValue = (obj: any) => obj[property]
) =>
    values
        .map(
            ({ date, value: obj }): DateValueTuple => [
                date,
                obj && getValue(obj)
            ]
        )
        .filter(([, value]) => value != null);

export const getValues = (props: { values: Values; indicator: any }) => {
    const {
        values,
        indicator: { code }
    } = props;

    switch (code) {
        case PHYSACT.code: {
            const customValidator = (obj: any) => {
                const { intensity } = obj;
                return intensity;
            };
            return getValuesByProperty(
                values,
                PHYSACT.properties.intensity,
                customValidator
            );
        }
        case SWELLING.code:
            return getValuesByProperty(values, SWELLING.properties.degree);
        case PRNMED.code:
        case PRNMED_AF.code:
            return getValuesByProperty(values, PRNMED_AF.properties.dose);
        case PRNMED_COPD.code:
            return getValuesByProperty(
                props.values,
                PRNMED_COPD.properties.dose
            );
        case PRNMED_CI.code:
            return getValuesByProperty(values, PRNMED_CI.properties.dose);
        case PRNMED_LVAD.code:
            return getValuesByProperty(values, PRNMED_LVAD.properties.dose);
        case MEDICATION_CI.code: {
            const customValidator = (obj: any) => {
                const { anticoagulant } = obj;
                const antiLipoproteins = obj['anti-lipoproteins'];
                if (anticoagulant === 0 || antiLipoproteins === 0) {
                    return 0;
                }
                if (anticoagulant === -1 && antiLipoproteins === -1) {
                    return -1;
                }
                return 1;
            };
            return getValuesByProperty(
                values,
                MEDICATION_CI.properties.anticoagulant,
                customValidator
            );
        }
        case PHLEGM.code: {
            const customValidator = (obj: any) => {
                const { color, blood } = obj;
                if (blood) return 5;
                switch (color) {
                    case 3:
                        return 4;
                    case 2:
                        return 3;
                    case 1:
                        return 2;
                    default:
                        return 1;
                }
            };
            return getValuesByProperty(
                values,
                PHLEGM.properties.status,
                customValidator
            );
        }

        case SLEEP_SCI.code:
            return getValuesByProperty(values, SLEEP_SCI.properties.status);

        case RESPIRATORY_PROBLEMS.code: {
            const customValidator = (obj: any) => {
                const { frequency } = obj;

                switch (true) {
                    case frequency >= 25:
                        return 5;
                    case frequency > 20 && frequency < 25:
                        return 4;
                    case frequency > 10 && frequency < 20:
                        return 3;
                    case frequency === 10:
                        return 2;
                    case frequency >= 5 && frequency < 10:
                        return 1;
                    default:
                        return 0;
                }
            };
            return getValuesByProperty(
                values,
                RESPIRATORY_PROBLEMS.properties.status,
                customValidator
            );
        }
        case BOWEL_DYSFUNCTION.code: {
            const customValidator = (obj: any) => {
                const { sum } = obj;
                switch (true) {
                    case sum >= 14:
                        return 5;
                    case sum >= 10:
                        return 4;
                    case sum >= 7:
                        return 3;
                    case sum >= 0:
                        return 2;
                    default:
                        return 1;
                }
            };
            return getValuesByProperty(
                values,
                BOWEL_DYSFUNCTION.properties.status,
                customValidator
            );
        }
    }
    const data = values.map(({ date, value }): DateValueTuple => [date, value]);

    switch (code) {
        case SMOKE.code: {
            return sumByDay(data);
        }
        case PUMP_SELF_TEST_LVAD.code: {
            return data.sort((value: any) => (value[1] ? -1 : 1));
        }
        default:
            return data;
    }
};
