import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Body Weight',
        id: 'indicator.body-weight.title'
    },
    value: {
        id: 'indicator.body-weight.value',
        defaultMessage: '{value, number, ::.0}'
    },
    unit: {
        id: 'indicator.body-weight.unit',
        defaultMessage: 'kg'
    }
});

export const messagesBodyWeight = {
    general
};
