import styled from 'styled-components';

const BlockQuote = styled.blockquote<any>`
    background: ${(props) => props.background || '#fff'};
    border-left: ${(props) => props.borderLeft || 'none'}; // 10px solid #ccc
    padding: 0.5em 10px;
    quotes: '\\201C''\\201D''\\2018''\\2019';

    &:before {
        color: #ccc;
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }

    p {
        display: inline;
    }
`;

export default BlockQuote;
