import indicatorRules from '../../utils/create-condition-settings/indicator-rules';
import { indicatorItem } from '../../utils/create-condition-settings/items';
import { reasonsStatusChange } from '../../utils/create-condition-settings/reasons-status-change';
import { ConditionRules } from '../condition.type';
import { IndicatorMeta } from '../indicator-meta';

export const createCOPDRsConfiguration = (): ConditionRules => {
    return {
        initialObservationIndicators: [
            IndicatorMeta.BODY_WEIGHT.code,
            IndicatorMeta.BODY_LENGTH.code
        ],
        priorityTable: {
            hidden: [
                IndicatorMeta.EQ5D3L.code,
                IndicatorMeta.BODY_LENGTH.code,
                IndicatorMeta.APPETITE.code,
                IndicatorMeta.BODY_WEIGHT.code,
                IndicatorMeta.VAS.code
            ],
            order: [
                IndicatorMeta.PRNMED_COPD.code,
                IndicatorMeta.CAT.code,
                IndicatorMeta.MMRC.code,
                IndicatorMeta.PHYSACT.code,
                IndicatorMeta.PHLEGM.code,
                IndicatorMeta.BMI.code,
                IndicatorMeta.SMOKE.code
            ]
        },
        indicatorSettings: {
            preventFromEditLimits: [
                IndicatorMeta.APPETITE.code,
                IndicatorMeta.PRNMED_COPD.code,
                IndicatorMeta.EQ5D3L.code,
                IndicatorMeta.PHLEGM.code,
                IndicatorMeta.PHYSACT.code,
                IndicatorMeta.BMI.code,
                IndicatorMeta.MMRC.code,
                IndicatorMeta.BODY_LENGTH.code,
                IndicatorMeta.BODY_WEIGHT.code,
                IndicatorMeta.SMOKE.code,
                IndicatorMeta.VAS.code
            ],
            preventFromEditReminders: [
                IndicatorMeta.PRNMED_COPD.code,
                IndicatorMeta.BODY_LENGTH.code
            ],
            preventFromToggle: [
                IndicatorMeta.BODY_LENGTH.code,
                IndicatorMeta.VAS.code
            ]
        },
        reasons: reasonsStatusChange(),
        indicators: {
            [IndicatorMeta.PRNMED_COPD.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PRNMED_COPD.code]
            ),
            [IndicatorMeta.CAT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.CAT.code]
            ),
            [IndicatorMeta.MMRC.code]: indicatorItem(
                indicatorRules[IndicatorMeta.MMRC.code]
            ),
            [IndicatorMeta.PHYSACT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PHYSACT.code]
            ),
            [IndicatorMeta.PHLEGM.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PHLEGM.code]
            ),
            [IndicatorMeta.BODY_LENGTH.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BODY_LENGTH.code]
            ),
            [IndicatorMeta.BODY_WEIGHT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BODY_WEIGHT.code]
            ),
            [IndicatorMeta.BMI.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BMI.code]
            ),
            [IndicatorMeta.APPETITE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.APPETITE.code]
            ),
            [IndicatorMeta.SMOKE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.SMOKE.code]
            ),
            [IndicatorMeta.VAS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.VAS.code]
            ),
            [IndicatorMeta.EQ5D3L.code]: indicatorItem(
                indicatorRules[IndicatorMeta.EQ5D3L.code]
            )
        }
    };
};
