import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Active Range of Motion',
        id: 'indicator.active-motion-hs.title'
    }
});

export const messagesActiveMotion = {
    general
};
