import { defineMessages } from 'react-intl';

const general = defineMessages({
    togglePatientsByUnreadMessages: {
        id: 'filter.general.toggle-patients-by-unread-messages',
        defaultMessage: 'Only show patients who have sent new messages'
    }
});

export const filter = { general };
