import { createSelector } from 'reselect';
import { getAllPatientsById } from '../patient-administration/patient-administration.selectors';

const getPendingRegisterPatientObj = (state: any) =>
    state.forms.registerPatient;

export const getPendingRegisterPatient = createSelector(
    [getAllPatientsById, getPendingRegisterPatientObj],
    (patients, obj) => {
        const patient = {
            ...patients[obj.externalId],
            ...obj
        };
        return patient || {};
    }
);
