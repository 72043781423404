import indicatorRules from '../../utils/create-condition-settings/indicator-rules';
import { indicatorItem } from '../../utils/create-condition-settings/items';
import { reasonsStatusChange } from '../../utils/create-condition-settings/reasons-status-change';
import { ConditionRules } from '../condition.type';
import { IndicatorMeta } from '../indicator-meta';

export const createSpinalCordInjuryConfiguration = (): ConditionRules => {
    return {
        indicatorSettings: {
            preventFromEditLimits: [
                IndicatorMeta.PHYSACT.code,
                IndicatorMeta.BOWEL_DYSFUNCTION.code,
                IndicatorMeta.SMOKE.code,
                IndicatorMeta.URINE_LEAKAGE.code,
                IndicatorMeta.VAS.code
            ],
            preventFromEditReminders: [IndicatorMeta.SWELLING.code]
        },
        initialObservationIndicators: [IndicatorMeta.BODY_WEIGHT.code],
        priorityTable: {
            hidden: [
                IndicatorMeta.WAIST_CIRCUMFERENCE.code,
                IndicatorMeta.VAS.code,
                IndicatorMeta.SMOKE.code,
                IndicatorMeta.SWELLING.code,
                IndicatorMeta.LIQUID_MEASUREMENT.code,
                IndicatorMeta.SLEEP_SCI.code
            ],
            order: [
                IndicatorMeta.RESPIRATORY_PROBLEMS.code,
                IndicatorMeta.OXYGEN_SATURATION.code,
                IndicatorMeta.BODY_TEMP.code,
                IndicatorMeta.BLOOD_PRESSURE.code,
                IndicatorMeta.SLEEP_SCI.code,
                IndicatorMeta.PULSE.code,
                IndicatorMeta.URINE_LEAKAGE.code,
                IndicatorMeta.BOWEL_DYSFUNCTION.code,
                IndicatorMeta.PHYSACT.code,
                IndicatorMeta.BODY_WEIGHT.code
            ]
        },
        reasons: reasonsStatusChange(),
        indicators: {
            [IndicatorMeta.BLOOD_PRESSURE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BLOOD_PRESSURE.code]
            ),
            [IndicatorMeta.WAIST_CIRCUMFERENCE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.WAIST_CIRCUMFERENCE.code]
            ),
            [IndicatorMeta.BODY_WEIGHT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BODY_WEIGHT.code]
            ),
            [IndicatorMeta.VAS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.VAS.code]
            ),
            [IndicatorMeta.SWELLING.code]: indicatorItem(
                indicatorRules[IndicatorMeta.SWELLING.code]
            ),
            [IndicatorMeta.SMOKE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.SMOKE.code]
            ),
            [IndicatorMeta.OXYGEN_SATURATION.code]: indicatorItem(
                indicatorRules[IndicatorMeta.OXYGEN_SATURATION.code]
            ),
            [IndicatorMeta.BODY_TEMP.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BODY_TEMP.code]
            ),
            [IndicatorMeta.URINE_LEAKAGE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.URINE_LEAKAGE.code]
            ),
            [IndicatorMeta.RESPIRATORY_PROBLEMS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.RESPIRATORY_PROBLEMS.code]
            ),
            [IndicatorMeta.SLEEP_SCI.code]: indicatorItem(
                indicatorRules[IndicatorMeta.SLEEP_SCI.code]
            ),
            [IndicatorMeta.BOWEL_DYSFUNCTION.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BOWEL_DYSFUNCTION.code]
            ),
            [IndicatorMeta.LIQUID_MEASUREMENT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.LIQUID_MEASUREMENT.code]
            ),
            [IndicatorMeta.PULSE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PULSE.code]
            ),
            [IndicatorMeta.PHYSACT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PHYSACT.code]
            )
        }
    };
};
