import moment from 'moment';

export const isValidDate = (
    century: string,
    year: string,
    month: string,
    day: string
) => {
    const formatInput = (input: string) => {
        if (
            (typeof input === 'string' && input.length === 1) ||
            (typeof input === 'number' && input < 10)
        ) {
            input = `0${input}`;
        }

        return input;
    };

    try {
        year = formatInput(year);
        month = formatInput(month);
        day = formatInput(day);

        const dateString = `${century}${year}${month}${day}`;

        return moment(dateString, 'YYYYMMDD').isValid();
    } catch (err) {
        return false;
    }
};
