import _ from 'lodash';
import { PlotbandLimit } from '../../../../../../components/charts/graph/types/plotband.type';

export function getChartMin(
    chartMin: number,
    fixedYAxisMin: number,
    useReportMin: boolean,
    lowerBoundaries: PlotbandLimit[],
    report_min: number | null | undefined,
    margin: number,
    greenBoundary: PlotbandLimit
) {
    if (_.isNumber(fixedYAxisMin)) {
        return fixedYAxisMin;
    } else if (fixedYAxisMin || useReportMin) {
        return report_min;
    } else if (lowerBoundaries == null || !lowerBoundaries.length) {
        if (greenBoundary != null && !_.isEmpty(greenBoundary)) {
            const { to } = greenBoundary;
            return to - margin;
        }
        return chartMin;
    } else {
        // @ts-ignore
        const lowestTo = _.minBy(lowerBoundaries, 'from').to - margin;

        return lowestTo > chartMin ? chartMin : lowestTo;
    }
}
