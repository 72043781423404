import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Extra medication',
        id: 'indicator.prnmed-ci.title'
    },
    values: {
        defaultMessage:
            '{dose, plural, one {{dose} dose} other {{dose} doses}} of {medication}',
        id: 'indicator.prnmed-ci.values'
    },
    legendNo: {
        defaultMessage: 'No doses',
        id: 'indicator.prnmed-ci.legend.no'
    },
    legendYes: {
        defaultMessage: 'One or more doses',
        id: 'indicator.prnmed-ci.legend.yes'
    }
});

const dose = defineMessages({
    title: {
        defaultMessage: 'Dose',
        id: 'indicator.prnmed-ci.dose.title'
    },
    description: {
        defaultMessage: 'Number of doses exceeding prescription',
        id: 'indicator.prnmed-ci.dose.description'
    },
    unit: {
        id: 'indicator.prnmed-ci.dose.unit',
        defaultMessage: '{value, plural, one {dose} other {doses}}'
    }
});

const medication = defineMessages({
    analgesic: {
        defaultMessage: 'painkiller',
        id: 'indicator.prnmed-ci.medication.choices.analgesic'
    }
});

const choices = defineMessages({
    title: {
        defaultMessage: 'Medication',
        id: 'indicator.prnmed-ci.medication.title'
    },
    question: {
        defaultMessage: 'Type of medicine',
        id: 'indicator.prnmed-ci.medication.description'
    },
    analgesic: {
        defaultMessage: 'painkiller',
        id: 'indicator.prnmed-ci.choices.analgesic'
    }
});

export const messagesPrnmedCi = {
    general,
    dose,
    choices,
    medication
};
