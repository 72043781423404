import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessageOfTheDay, markAsRead } from './motd.api';

export const fetchUnreadMessagesOfTheDay = createAsyncThunk(
    'FETCH_UNREAD_MOTD',
    () => getMessageOfTheDay({ status: 'unread' })
);

export const markMessagesOfTheDayAsRead = createAsyncThunk(
    'MARK_MOTD_AS_READ',
    markAsRead
);
