import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/vocational-activity.svg';
import { messagesVocationalActivity } from './messages/messages';
import { rules } from './vocational-activity-hs-rules';

export const parameterVocationalActivityHS = {
    code: 'VOCATIONAL-ACTIVITY-HS'
};

export const vocationalActivity: Indicator = {
    code: parameterVocationalActivityHS.code,
    icon,
    messages: messagesVocationalActivity,
    rules
};
