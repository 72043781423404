import React from 'react';
import moment from 'moment';
import { Avatar } from 'antd';
import { getPractitionerName } from './utils/getPracticionerName';
import { HistoryItemProps } from '../HistoryPanelContent';
import { StyledMetaItem } from './styledHistoryItem';
import { history } from './message-descriptors';
import { Strings } from '../../../../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../../../../locale/format/format-message';

export const Watchover = ({ event }: HistoryItemProps): JSX.Element => (
    <StyledMetaItem
        title={moment(event.dtCreated).format('YYYY-MM-DD HH:mm')}
        avatar={
            <Avatar
                icon="eye"
                style={{
                    backgroundColor: '#5b64c2'
                }}
            />
        }
        description={
            <>
                {formatMessage(Strings.events.general.watchedOver, {
                    practitioner: getPractitionerName(event.proxyMeta)
                })}
                <br />
                {formatMessage(history.watchover.patientInformed)}
            </>
        }
    />
);
