import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import { setSelectedCondition } from '../../redux/domains/conditions/conditions.actions';
import {
    setSelectedMedicGroup,
    setSelectedPatientGroup
} from '../../redux/domains/groups/groups.actions';
import { routePatientOverview } from '../../pages/patient/overview/route';

type PatientGroupLinkProps = {
    patientGroupId: string;
    children?: ReactNode;
};

export const PatientGroupLink = ({
    patientGroupId,
    children
}: PatientGroupLinkProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const patientGroups = useReduxSelector(
        (state) => state.groups.patientGroups
    );
    const medicGroups = useReduxSelector((state) => state.groups.medicGroups);
    const selectedMedicGroupId = useReduxSelector(
        (state) => state.groups.selectedMedicGroupId
    );
    const patientGroupName = patientGroups.byId[patientGroupId]?.name;

    if (patientGroupName) {
        return (
            <a
                onClick={(event: any) => {
                    event.stopPropagation();
                    const conditionId =
                        patientGroups.byId[patientGroupId].condition;
                    const managedBy =
                        patientGroups.byId[patientGroupId].managedBy;
                    const medicGroupId =
                        managedBy.find((id) => id === selectedMedicGroupId) ||
                        managedBy.find((id) =>
                            Object.keys(medicGroups.byId).includes(id)
                        ) ||
                        patientGroups.byId[patientGroupId].managedBy[0];
                    dispatch(setSelectedCondition(conditionId));
                    dispatch(setSelectedMedicGroup(medicGroupId));
                    dispatch(setSelectedPatientGroup(patientGroupId));
                    history.push(`${routePatientOverview.link}`);
                }}
            >
                {children != null ? children : patientGroupName}
            </a>
        );
    }
    return <></>;
};
