const auth = {
    userField: 'user-field-login',
    passwordField: 'user-field-password',
    signInButton: `auth-login-button`,
    signInAgainButton: `auth-login-again-button`,
    signOutButton: 'auth-logout-button',
    signOutConfirmButton: 'auth-logout-confirm-button',
    loginMethodEmailButton: 'auth-login-method-email-button',
    loggedOutTitle: 'auth-logged-out-title',
    loggedOutSubTitle: 'auth-logged-out-subtitle'
};

const page = {
    headerTitle: 'header-title',
    patientPrioTable: 'patient-prio-table'
};

const header = {
    headerUserMenu: 'user-menu'
};

export const testSelectors = {
    auth,
    header,
    page
};
