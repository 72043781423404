import { parameterPumpSpeedLVAD } from '../../../../../conditions/left-ventricular-assist-device/indicators/pump-speed/pump-speed';
import { IndicatorCode } from '../../../../../types/indicator/indicator.type';
import {
    GraphValue,
    GraphValueWithComment
} from '../../types/graph-value.type';

export const parseSingleValueIndicator = (
    code: IndicatorCode,
    eventData: GraphValueWithComment[]
): GraphValueWithComment[] => {
    if (parameterPumpSpeedLVAD.code === code) {
        return eventData.map((data) => {
            if (typeof data.y !== 'number') {
                throw 'data.y is not a number';
            }

            return {
                x: data.x,
                y: data.y / 1000,
                comment: data.comment,
                marker: data.marker
            };
        });
    }

    return eventData;
};
