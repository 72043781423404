import { BaseUserMeta } from '../../../../../redux/domains/user/user.type';
import { FormikType } from '../../formik-type';

export const createFormikState = (meta?: BaseUserMeta) => {
    if (meta == null) {
        return {
            email: '',
            ssn: '',
            firstName: '',
            familyName: '',
            phoneNumber: ''
        };
    }

    return {
        email: meta.email,
        ssn: meta.personal_id || '',
        firstName: meta.name,
        familyName: meta.family_name,
        phoneNumber: meta.phone_number
    };
};

export const createRequestMeta = (values: FormikType) => {
    return {
        name: values.firstName.trim(),
        family_name: values.familyName.trim(),
        phone_number: values.phoneNumber.trim()
    };
};
