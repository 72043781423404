import { createSelector } from 'reselect';
import { ReduxState } from '../../redux-state.type';
import { FETCH_CONDITIONS_WITH_INDICATORS_FULFILLED } from '../conditions/conditions.constants';
import { NormalizedIndicators } from './indicator.type';

type IndicatorsState = NormalizedIndicators;

const initialState: IndicatorsState = {
    byId: {},
    allIds: []
};

/* Getters */
const indicators = (state: ReduxState) => state.indicators;

/* Selectors */
export const getIndicators = createSelector([indicators], (indicators) => {
    return indicators.byId;
});

export default function indicatorReducer(
    state = initialState,
    action: { payload: { indicators: NormalizedIndicators }; type: string }
): IndicatorsState {
    const { payload, type } = action;

    switch (type) {
        case FETCH_CONDITIONS_WITH_INDICATORS_FULFILLED: {
            const { indicators } = payload;
            return (state = indicators);
        }
        default:
            return state;
    }
}
