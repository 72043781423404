import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Blood Pressure',
        id: 'indicator.blood-pressure.title'
    }
});

const systolic = defineMessages({
    title: {
        defaultMessage: 'Systolic',
        id: 'indicator.blood-pressure.systolic.title'
    },
    unit: {
        id: 'indicator.blood-pressure.systolic.unit',
        defaultMessage: 'mmHg'
    }
});

const diastolic = defineMessages({
    title: {
        defaultMessage: 'Diastolic',
        id: 'indicator.blood-pressure.diastolic.title'
    },
    unit: {
        id: 'indicator.blood-pressure.diastolic.unit',
        defaultMessage: 'mmHg'
    }
});

export const messagesBloodPressure = {
    general,
    systolic,
    diastolic
};
