export const FETCH_PRIORITY_LIST = 'FETCH_PRIORITY_LIST';
export const FETCH_PRIORITY_LIST_PENDING = 'FETCH_PRIORITY_LIST_PENDING';
export const FETCH_PRIORITY_LIST_FULFILLED = 'FETCH_PRIORITY_LIST_FULFILLED';
export const FETCH_PRIORITY_LIST_REJECTED = 'FETCH_PRIORITY_LIST_REJECTED';

export const RESET_PRIORITY = 'RESET_PRIORITY';

export const FILTER_PRIORITY_LISTS = 'FILTER_PRIORITY_LISTS';
export const CLEAR_FILTER = 'CLEAR_FILTER';

export enum ActionTypes {
    FETCH_PRIORITY_LIST_BY_ID = 'FETCH_PRIORITY_LIST_BY_ID',
    FETCH_PRIORITY_LIST_BY_ID_PENDING = 'FETCH_PRIORITY_LIST_BY_ID_PENDING',
    FETCH_PRIORITY_LIST_BY_ID_FULFILLED = 'FETCH_PRIORITY_LIST_BY_ID_FULFILLED',
    FETCH_PRIORITY_LIST_BY_ID_REJECTED = 'FETCH_PRIORITY_LIST_BY_ID_REJECTED'
}
