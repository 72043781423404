import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Pain',
        id: 'indicator.pain-ci.title'
    }
});

const rest = defineMessages({
    rest: {
        defaultMessage: 'Pain at rest',
        id: 'indicator.pain-ci.rest.title'
    }
});

const activity = defineMessages({
    activity: {
        defaultMessage: 'Pain during activity',
        id: 'indicator.pain-ci.active.title'
    }
});

export const messagesPainCi = {
    general,
    rest,
    activity
};
