import React from 'react';
import { Modal } from 'antd';
import { PatientGroup } from '../../../redux/domains/groups/groups.type';
import styled from 'styled-components';
import { OnClick } from '../../../types/custom-types';
import { ConnectedActionPanelContent } from './action-modal-content/ActionPanelContent';
import { ActionPanelProps } from './actionPanelProps';

type Props = ActionPanelProps & {
    visible: boolean;
    hide: OnClick;
    show: OnClick;
    name: string;
    patientGroup?: PatientGroup;
    conditionCode?: string;
};

export const ActionPanelModal = (props: Props): JSX.Element => {
    return (
        <StyledModal
            visible={props.visible}
            maskClosable={false}
            onCancel={props.hide}
            destroyOnClose={true}
            width={720}
            wrapClassName="action-panel"
            footer={null}
        >
            <ConnectedActionPanelContent
                name={props.name}
                externalId={props.externalId}
                hide={props.hide}
                isMedicAdmin={false}
                patientGroup={props.patientGroup}
                conditionCode={props.conditionCode}
            />
        </StyledModal>
    );
};

const StyledModal = styled(Modal)`
    .ant-modal-content {
        .ant-modal-body {
            padding: 0px;
        }
    }
`;
