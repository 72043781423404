import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Medication',
        id: 'indicator.medication-ci.title'
    }
});

const antiLipoproteins = defineMessages({
    title: {
        defaultMessage: 'Medication to prevent high blood lipids',
        id: 'indicator.medication-ci.anti-lipoproteins.title'
    },
    question: {
        defaultMessage: 'Medication to prevent high blood lipids',
        id: 'indicator.medication-ci.anti-lipoproteins.question'
    },
    description: {
        defaultMessage: 'Have you taken your prescribed medication?',
        id: 'indicator.medication-ci.anti-lipoproteins.description'
    },
    valueNa: {
        defaultMessage: 'I do not have this medication',
        id: 'indicator.medication-ci.anti-lipoproteins.choices.-1'
    },
    valueNo: {
        defaultMessage: 'No',
        id: 'indicator.medication-ci.anti-lipoproteins.choices.0'
    },
    valueYes: {
        defaultMessage: 'Yes',
        id: 'indicator.medication-ci.anti-lipoproteins.choices.1'
    }
});

const anticoagulant = defineMessages({
    title: {
        defaultMessage: 'Anticoagulant',
        id: 'indicator.medication-ci.anticoagulant.title'
    },
    question: {
        defaultMessage: 'Anticoagulant',
        id: 'indicator.medication-ci.anticoagulant.question'
    },
    description: {
        defaultMessage: 'Have you taken your prescribed medication?',
        id: 'indicator.medication-ci.anticoagulant.description'
    },
    valueNa: {
        defaultMessage: 'I do not have this medication',
        id: 'indicator.medication-ci.anticoagulant.choices.-1'
    },
    valueNo: {
        defaultMessage: 'No',
        id: 'indicator.medication-ci.anticoagulant.choices.0'
    },
    valueYes: {
        defaultMessage: 'Yes',
        id: 'indicator.medication-ci.anticoagulant.choices.1'
    }
});

const legend = defineMessages({
    na: {
        defaultMessage: 'I do not have this medication',
        id: 'indicator.medication-ci.legend.na'
    },
    no: {
        defaultMessage: 'Medicines have not been taken',
        id: 'indicator.medication-ci.legend.no'
    },
    yes: {
        defaultMessage: 'Medicines have been taken',
        id: 'indicator.medication-ci.legend.yes'
    }
});

export const messagesMedicationCi = {
    general,
    legend,
    antiLipoproteins,
    anticoagulant
};
