import messagesSV from '../messages/sv.json';
import messagesEN from '../messages/en.json';
import { getLanguageCode } from './get-language-code';
import { getLanguageWithoutRegionCode } from './get-language-without-region-code';

const translations: Record<string, Record<string, string>> = {
    sv: messagesSV,
    en: messagesEN
};

export const getMessages = (): Record<string, string> => {
    return (
        translations[getLanguageWithoutRegionCode()] ||
        translations[getLanguageCode()] ||
        translations.en
    );
};
