import _ from 'lodash';
import defaultColorObj from '../../../../../utils/create-condition-settings/indicator-rules/defaults/colors/default';
import { IndicatorWithSettings } from '../../../../../redux/domains/indicators/indicator.type';
import { GraphValueWithComment } from '../../types/graph-value.type';
import {
    GraphValuesSorted,
    IndicatorValue
} from '../../../../../types/indicator/indicator-value.type';
import { IndicatorCode } from '../../../../../types/indicator/indicator.type';
import { getLineColor } from '../../utils/graph';
import { singleCreateEventData } from './single-create-event-data';
import { IndicatorSettingsMapping } from '../../../../../conditions/condition.type';

export const mapSingleValueIndicatorData = (
    sortedValues: GraphValuesSorted<IndicatorValue>[],
    indicator: IndicatorWithSettings,
    settingsMapping: IndicatorSettingsMapping,
    legendIds: IndicatorCode | null
): GraphValueWithComment[] => {
    const code = indicator.code;
    const lineColorObj = legendIds
        ? defaultColorObj
        : getLineColor(settingsMapping[code]);

    return (
        (sortedValues &&
            _.flatten<GraphValueWithComment>(
                sortedValues.map((event) =>
                    singleCreateEventData(indicator, event, lineColorObj)
                )
            )) ||
        []
    );
};
