import { RouteItemType } from '../../../../../utils/route/switch-routes';
import Consume from './Consume';
import { samlConsumePath } from './path-saml-consume';

export const routeSamlConsume: RouteItemType = {
    name: '',
    exact: true,
    link: samlConsumePath,
    icon: '',
    component: Consume
};
