import { MessageDescriptor } from 'react-intl';

import { intl } from '../setup/intl/get-intl';
import { PrimitiveType } from 'intl-messageformat';
import { getMessages } from '../setup/get-messages';

export const formatMessage = (
    messageDescriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType> | undefined
): string => {
    if (!messageDescriptor.defaultMessage) {
        console.warn(`Empty string:${messageDescriptor.id}`);
        return '';
    }

    return intl.formatMessage(messageDescriptor, values);
};

export const formatMessageById = (
    id: string,
    values?: Record<string, PrimitiveType> | undefined
): string => {
    if (hasMessageId(id)) {
        if (!values) {
            return getMessages()[id];
        }

        return intl.formatMessage({ id }, values);
    }

    return '';
};

export const hasMessageId = (id: string): boolean => {
    return id in getMessages();
};
