import styled from 'styled-components';

export const StyledActionBar = styled.div`
    margin-top: 60px;
    text-align: center;
`;

export const StyledFormWrapper = styled.div`
    margin: auto;
`;
