import * as actionTypes from './authentication.constants';
import { addTokensToLocalStorage } from '../../../utils/storage/auth/add-tokens-to-local-storage';
import { Authentication } from './authentication.type';

const initialState: Authentication = {
    isAuthenticated: false
};

export default function authReducer(
    state = initialState,
    action: any
): Authentication {
    switch (action.type) {
        case actionTypes.LOGIN_USER_FULFILLED: {
            addTokensToLocalStorage(action.payload);
            return {
                isAuthenticated: true
            };
        }
        case actionTypes.LOGIN_USER_REJECTED:
            return initialState;
        case actionTypes.CHECK_AUTHENTICATION_FULFILLED:
            return {
                isAuthenticated: true
            };
        case actionTypes.CHECK_AUTHENTICATION_REJECTED:
            return initialState;
        case actionTypes.LOGOUT_USER_FULFILLED:
            return initialState;
        default:
            return state;
    }
}
