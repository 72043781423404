import React from 'react';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import { getAntdLocale } from './setup/antd/get-antd-locale';
import { getMessages } from './setup/get-messages';
import {
    getDefaultLanguage,
    getLanguageWithoutRegionCode
} from './setup/get-language-without-region-code';
import { setMomentLocale } from './setup/moment/set-moment-locale';

export const LocaleProvider = ({ children }: { children: any }) => {
    setMomentLocale(getDefaultLanguage());

    return (
        <AntdConfigProvider locale={getAntdLocale()}>
            <IntlProvider
                locale={getLanguageWithoutRegionCode()}
                messages={getMessages()}
            >
                {children}
            </IntlProvider>
        </AntdConfigProvider>
    );
};
