import React, { Component } from 'react';
// @ts-ignore
import queryString from 'query-string';
import { connect } from 'react-redux';

import { CrossSpinner } from '../../../../../components/ui/loading/loading';
import { loginUserWithIdentityProvider } from '../../../../../redux/domains/authentication/authentication.actions';
import { LOGIN_USER } from '../../../../../redux/domains/authentication/authentication.constants';
import { createLoadingSelector } from '../../../../../redux/domains/requests/requests.selectors';
import { History, LocationState } from 'history';
import { CreatePatientState } from '../../../../patient/create/steps/step-1/Step1';
import { routePatientOverview } from '../../../../patient/overview/route';
import { pathCreatePatientStep1 } from '../../../../patient/create/steps/step-1/path';
import { pathPatientProfileLink } from '../../../../patient/data/components/tabs/path';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../locale/format/format-message';

type Props = {
    loginUserWithIdentityProvider: any;
    history: History<LocationState>;
    selectedPatientGroupId: string;
    loading: boolean;
};

class Tokens extends Component<Props> {
    componentDidMount() {
        const { loginUserWithIdentityProvider } = this.props;
        const params = queryString.parse(this.props.history.location.search);
        const { code, state } = params;

        if (code && state) {
            loginUserWithIdentityProvider({ code, state }).then(
                ({ value }: any) => {
                    this.handleLoginResponse(value);
                }
            );
        }
    }

    handleLoginResponse = ({ data }: any) => {
        const { next_action } = data;
        const createState: CreatePatientState = {
            patient: data.patient
        };

        switch (next_action) {
            case 'dashboard':
                window.location.href = `#${routePatientOverview.dashboard}`;
                break;
            case 'register_user':
                this.props.history.push({
                    pathname: `${pathCreatePatientStep1}`,
                    state: createState
                });
                break;
            case 'user_details': {
                const { externalId } = data.patient;
                this.props.history.push({
                    pathname: pathPatientProfileLink(
                        this.props.selectedPatientGroupId,
                        externalId
                    )
                });
                break;
            }
            default:
                break;
        }
    };

    render() {
        const { loading } = this.props;

        if (!loading) {
            return (
                <div>
                    <span>
                        <strong>
                            {formatMessage(Strings.authText.main.redirect)}
                        </strong>
                    </span>
                </div>
            );
        }

        return (
            <div>
                <CrossSpinner position="left" display="inline-block" />
                <span style={{ marginLeft: 10 }}>
                    <strong>
                        {formatMessage(Strings.authText.main.loggingIn)}
                    </strong>
                </span>
            </div>
        );
    }
}

const loadingSelector = createLoadingSelector([LOGIN_USER]);

function mapStateToProps(state: any) {
    return {
        selectedPatientGroupId: state.groups.selectedPatientGroupId,
        loading: loadingSelector(state)
    };
}

export default connect(mapStateToProps, { loginUserWithIdentityProvider })(
    Tokens
);
