import { SharedState } from './chart.shared.types';

const sharedInitialState: SharedState = {
    selectedDate: null
};

export function sharedGetInitialState(): SharedState {
    return JSON.parse(
        JSON.stringify(sharedInitialState)
    ) as unknown as SharedState;
}
