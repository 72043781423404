import moment from 'moment';
import { formatMessageById } from '../../../../locale/format/format-message';
import { IndicatorReport } from '../../../../types/indicators/Indicator';
import { TrendData } from './trend-data.type';

export const getYearMonthDayString = ({ date }: { date: string | number }) => {
    return moment(date).format('YYYY-MM-DD');
};

export const getMatchingDayTrendItems = ({
    unitId,
    matchingDate,
    latestReports
}: {
    unitId: string;
    matchingDate: string;
    latestReports: IndicatorReport[];
}): TrendData[] => {
    const items: TrendData[] = [];
    Object.keys(latestReports).map((key: any) => {
        if (
            matchingDate ===
            getYearMonthDayString({ date: latestReports[key].date })
        ) {
            items.push({
                title: undefined,
                value: latestReports[key].value,
                unit: formatMessageById(unitId, {
                    value: latestReports[key].value
                })
            });
        }
    });
    return items;
};

export const hasDataInCurrentDateRange = (
    data: Highcharts.PointOptionsObject[],
    startDate: number
) => {
    return data.some(({ x: date = 0 }) => {
        return date >= startDate;
    });
};
