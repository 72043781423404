import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, Icon } from 'antd';
import { styled } from '../../../../theme/styled';
import { ContentLayout } from '../../../../components/content/ContentLayout';
import { RadioGroupFields } from '../../../../components/form/inputs/types';
import { routeEducation } from '../../page/route';
import { messages } from '../messages';
import { formatMessage } from '../../../../locale/format/format-message';

type Props = {
    fields: RadioGroupFields;
};

export const EducationFormFailed = ({ fields }: Props): JSX.Element => (
    <ContentLayout>
        <Card>
            <p>{formatMessage(messages.educationForm.failedInformation)}</p>
            <StyledUl>
                {Object.keys(fields)
                    .filter(
                        (key) => fields[key].value !== fields[key].correctValue
                    )
                    .map((key) => (
                        <li key={key}>{fields[key].label}</li>
                    ))}
            </StyledUl>
            <NavLink
                to={{
                    pathname: routeEducation.link
                }}
            >
                <Button>
                    <Icon type="arrow-left" />
                    {formatMessage(messages.educationForm.failedButton)}
                </Button>
            </NavLink>
        </Card>
    </ContentLayout>
);

const StyledUl = styled.ul`
    list-style: none;
    margin-left: 20px;
    padding-left: 0px;
`;
