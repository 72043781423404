import {
    BLOOD_PRESSURE,
    PAIN_CI,
    BMI
} from '../../../../conditions/indicator-meta';
import { formatMessageById } from '../../../../locale/format/format-message';
import {
    DateAndObjTuple,
    ModifiedIndicatorProperties,
    SeriesData
} from '../chart-types';

export const getMultiPropertiesSeriesData = (
    data: ModifiedIndicatorProperties[],
    code: string
): SeriesData[] => {
    return data.map((property): SeriesData => {
        return {
            data: property.values.map((dateValueArray) => {
                const unit = formatMessageById(
                    `indicator.${code}.${property.name}.unit`
                );
                const value = dateValueArray[1];
                const date = dateValueArray[0];
                const formattedValue = formatMessageById(
                    `indicator.${code}.value`,
                    { value }
                );
                return {
                    x: date,
                    y: value,
                    unit,
                    customUnit: formattedValue && `${formattedValue} ${unit}`
                };
            }),
            indicator: {
                name: formatMessageById(
                    `indicator.${code}.${property.name}.title`
                ),
                unit: formatMessageById(
                    `indicator.${code}.${property.name}.unit`
                )
            }
        };
    });
};

export const mapIndicatorProperties = (
    data: DateAndObjTuple[],
    code: string
): ModifiedIndicatorProperties[] => {
    const indicatorProperties = fetchIndicatorProperties(code);
    return Object.values(indicatorProperties).map((property) => {
        const propValues = dataByProperty(property, data);
        return { name: property, values: propValues };
    });
};

export const fetchIndicatorProperties = (
    code: string
): Record<string, string> => {
    const properties = indicatorCodeToPropertiesMapping[code];
    if (!properties) throw new Error('That indicator does not have properties');
    return properties;
};

export const dataByProperty = (
    property: string,
    data: DateAndObjTuple[]
): number[][] => {
    return data.map((item: DateAndObjTuple) => {
        return [item[0], item[1][property]].filter((item) => item !== null);
    });
};

const indicatorCodeToPropertiesMapping = {
    [BLOOD_PRESSURE.code]: BLOOD_PRESSURE.properties,
    [PAIN_CI.code]: PAIN_CI.properties,
    [BMI.code]: { bmi: 'bmi' }
};
