import { isEmpty, isMatch, sortBy } from 'lodash';
import { Value, ValuesIndicator } from './values.type';
import { SWELLING } from '../../../conditions/indicator-meta';

type GetValuesResponse = {
    subjectId: string;
    indicators: ValuesIndicator[];
}[];

const NO_SWELLING = {
    degree: 1
};

export function parseValue(value: Value) {
    if (
        value.indicatorCode === SWELLING.code &&
        isMatch(value, { value: NO_SWELLING })
    ) {
        return {
            ...value,
            value: NO_SWELLING
        };
    }

    return value;
}

export const normalizeValues = (data: GetValuesResponse) => {
    if (isEmpty(data)) return [];

    return data[0].indicators.map(({ values, ...indicator }) => ({
        ...indicator,
        values: sortBy(values, 'date').map(parseValue)
    }));
};
