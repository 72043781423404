import { Indicator } from '../../../types/indicator/indicator.type';
import { rules } from './body-weight-rules';

import icon from './images/body-weight.svg';
import { messagesBodyWeight } from './messages/messages';

export const parameterBodyWeight = {
    code: 'BODY-WEIGHT'
};

export const bodyWeight: Indicator = {
    code: parameterBodyWeight.code,
    icon,
    messages: messagesBodyWeight,
    rules
};
