import React from 'react';
import { useSelector } from 'react-redux';
import { testSelectors } from '../../../../constants/test-id';
import { createLoadingSelector } from '../../../../redux/domains/requests/requests.selectors';
import { AppNeutralButton } from '../../../../components/ui/buttons/AppNeutralButton';
import { styled } from '../../../../theme/styled';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';

const loadingSelector = createLoadingSelector([
    'CREATE_AUTH_REQUEST',
    'LOGIN_USER'
]);

export const LoginEmailButton = (props: {
    handleSubmit?: () => void;
}): JSX.Element => {
    const signInPending = useSelector(loadingSelector);

    return (
        <StyledLargeButton
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            loading={signInPending}
            data-test-id={testSelectors.auth.signInButton}
        >
            {formatMessage(Strings.authText.main.logIn)}
        </StyledLargeButton>
    );
};

export const StyledLargeButton = styled(AppNeutralButton)`
    && {
        height: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
`;
