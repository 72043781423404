import icon from './images/mmrc.svg';
import { Indicator } from '../../../types/indicator/indicator.type';
import { messagesMmrc } from './messages/messages';
import { rules } from './mmrc-rules';

export const parameterMmrc = {
    code: 'MMRC'
};

export const mmrc: Indicator = {
    code: parameterMmrc.code,
    icon,
    messages: messagesMmrc,
    rules
};
