import {
    FETCH_CONDITIONS_WITH_INDICATORS,
    SET_SELECTED_CONDITION
} from './conditions.constants';
import { getExpandedIndicatorsFromConditions } from './conditions.api';
import { Dispatch } from 'redux';

export const fetchConditionsByIds =
    (
        conditionIds: string[]
    ): ((dispatch: Dispatch<any>) => {
        type: string;
        payload: Promise<{
            indicators: Record<string, unknown>;
            conditions: Record<string, unknown>;
        }>;
    }) =>
    (dispatch: Dispatch<any>) => {
        return dispatch({
            type: FETCH_CONDITIONS_WITH_INDICATORS,
            payload: getExpandedIndicatorsFromConditions(conditionIds.join(','))
        });
    };

export const setSelectedCondition = (
    conditionId: string
): {
    type: typeof SET_SELECTED_CONDITION;
    payload: string;
} => ({
    type: SET_SELECTED_CONDITION,
    payload: conditionId
});
