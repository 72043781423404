import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Swelling',
        id: 'indicator.swelling.title'
    },
    values: {
        defaultMessage: '{degree}\n{position}',
        id: 'indicator.swelling.values'
    }
});

const position = defineMessages({
    title: {
        defaultMessage: 'Where?',
        id: 'indicator.swelling.position.title'
    },
    legs: {
        defaultMessage: 'The swelling feels most in the legs',
        id: 'indicator.swelling.position.choices.1'
    },
    general: {
        defaultMessage: 'The swelling feels general',
        id: 'indicator.swelling.position.choices.2'
    }
});

const degree = defineMessages({
    title: {
        defaultMessage: 'Degree',
        id: 'indicator.swelling.degree.title'
    },
    noSwelling: {
        defaultMessage: 'No swelling',
        id: 'indicator.swelling.degree.choices.1'
    },
    lightSwelling: {
        defaultMessage: 'Light swelling',
        id: 'indicator.swelling.degree.choices.2'
    },
    moderateSwelling: {
        defaultMessage: 'Moderate swelling',
        id: 'indicator.swelling.degree.choices.3'
    },
    severeSwelling: {
        defaultMessage: 'Severe swelling',
        id: 'indicator.swelling.degree.choices.4'
    }
});

export const messagesSwelling = {
    general,
    degree,
    position
};
