import React, { useEffect, useMemo } from 'react';
import { Tabs, Tooltip } from 'antd';
import { Keyframes, keyframes } from 'styled-components';
import { useReduxSelector } from '../../../../../../hooks/use-redux-selector';
import {
    fetchValues,
    setDateFilter
} from '../../../../../../redux/domains/values/values.actions';
import {
    getDateFilters,
    getFilteredValues,
    getValuesParams
} from '../../../../../../redux/domains/values/values.selectors';
import { Condition } from '../../../../../../redux/domains/conditions/conditions.type';
import { IndicatorIconWithBorder } from '../../../../../../components/ui/icons/indicator-icon/IndicatorIcon';
import { DateFilter } from './export-data/DateFilter';
import ExportButton from './export-data/ExportButton';
import HistoryTable from './HistoryTable';
import { useDispatch } from 'react-redux';
import { styled } from '../../../../../../theme/styled';
import { IndicatorWithSettings } from '../../../../../../redux/domains/indicators/indicator.type';
import {
    formatMessage,
    formatMessageById
} from '../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../locale/messagesDescriptors';

const { TabPane } = Tabs;

type Props = {
    condition: Condition;
    indicators: IndicatorWithSettings[];
};

export const HistoryTab = (props: Props) => {
    const { condition, indicators } = props;
    const dispatch = useDispatch();
    const valuesFilteredByDate = useReduxSelector(getFilteredValues);

    const initialDateRange = useMemo(getPastYear, []);
    const valuesParams = useReduxSelector(getValuesParams);
    const valuesFrom = useReduxSelector(
        (state) => getDateFilters(state).from || initialDateRange.from
    );

    useEffect(() => {
        if (valuesFrom > 0 && valuesFrom < (valuesParams.from || 0)) {
            dispatch(
                fetchValues({
                    ...valuesParams,
                    from: valuesFrom,
                    to: Date.now()
                })
            );
        }
    }, [dispatch, valuesFrom, valuesParams]);

    useEffect(() => {
        return () => {
            dispatch(setDateFilter({}));
        };
    }, [dispatch]);

    return (
        <>
            <DateFilter
                defaultValue={initialDateRange}
                onDateChange={(params) => dispatch(setDateFilter(params))}
            >
                <ExportButton conditionCode={condition.code} />
            </DateFilter>
            <StyledTabs tabPosition="left">
                {indicators.map((indicator) => {
                    const { id } = indicator;
                    return (
                        <TabPane
                            key={id}
                            tab={
                                <TabTitle
                                    code={indicator.code.toLowerCase()}
                                    valuesExists={valuesFilteredByDate[id]}
                                />
                            }
                        >
                            <HistoryTable
                                indicator={indicator}
                                indicatorValues={valuesFilteredByDate[id]}
                            />
                        </TabPane>
                    );
                })}
            </StyledTabs>
        </>
    );
};

function getPastYear() {
    const date = new Date();
    const to = date.valueOf();

    date.setFullYear(date.getFullYear() - 1);

    return {
        from: date.valueOf(),
        to
    };
}

const TabTitleWrapper = styled.div<{ fade: any }>`
    color: ${(props) =>
        props.fade ? 'rgba(0, 0, 0, 0.35)' : 'rgba(0, 0, 0, 0.85)'};
    height: 34px;
    display: flex;
    align-items: center;
`;

const Title = styled.span`
    margin-left: 10px;
`;

const TabTitle = ({ code, valuesExists }: any) => {
    const title = formatMessageById(
        `${Strings.indicators.rootId}${code}.title`
    );

    if (valuesExists) {
        return (
            <TabTitleWrapper fade={false}>
                <IndicatorIconWithBorder code={code} size={36} />
                <Title>{title}</Title>
            </TabTitleWrapper>
        );
    }

    return (
        <Tooltip
            title={formatMessage(Strings.values.general.noReportedValues)}
            mouseLeaveDelay={0}
        >
            <TabTitleWrapper fade={true}>
                <IndicatorIconWithBorder code={code} size={36} color="gray" />
                <Title>{title}</Title>
            </TabTitleWrapper>
        </Tooltip>
    );
};

const StyledTabs = styled(Tabs)`
    .ant-tabs-tab {
        margin: 0 0 8px;
    }
    .ant-tabs-tabpane {
        max-height: calc(97vh - 350px);
    }
    .ant-tabs-tab-arrow-show {
        display: none;
    }

    .ant-tabs-bar {
        position: relative;

        &::before {
            content: '';
            z-index: 1;
            position: absolute;
            top: 0;
            height: 40px;
            width: 100%;
            background: linear-gradient(white, rgba(255, 255, 255, 0.001));
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 40px;
            width: 100%;
            background: linear-gradient(rgba(255, 255, 255, 0.001), white);
        }
    }
    .ant-tabs-nav-container {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(97vh - 400px);
    }

    .ant-tabs-tab-active {
        background: ${(props) =>
            `linear-gradient(90deg, white, rgba(${props.theme.util.hexToRGB(
                props.theme.primary.color
            )}, 0.6));`};

        background-size: 400% 100%; // Needed in conjunction with gradientEffect
        animation: ${(_) => gradientEffect()} 0.6s ease;
    }
`;

const gradientEffect = (): Keyframes => {
    return keyframes`
         0% {
          background-position:0 50%
         }
         50% {
          background-position:100% 50%
         }
         to {
          background-position:0 50%
         }
    `;
};
