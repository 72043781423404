import React, { Component, FormEvent } from 'react';
import styled from 'styled-components';
import { Button, Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Strings } from '../../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../../locale/format/format-message';

const FormItem = Form.Item;

type onFormSubmit = {
    (event: FormEvent<HTMLFormElement>): void;
};

type Props = FormComponentProps & {
    loading: boolean;
    onSubmit: onFormSubmit;
    initialLoading: boolean;
};

class SendMessageForm extends Component<Props> {
    private inputRef?: Input | null;

    componentDidUpdate(prevProps: Props) {
        if (prevProps.loading && !this.props.loading) {
            if (this.inputRef != null) {
                this.inputRef.focus();
            }
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <StyledForm onSubmit={this.props.onSubmit}>
                <InputContainer>
                    <StyledFormItem>
                        {getFieldDecorator('lp-message-content')(
                            <StyledInput
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                autoFocus
                                placeholder={formatMessage(
                                    Strings.form.general.messagePlaceholder
                                )}
                                ref={(node) => (this.inputRef = node)}
                            />
                        )}
                    </StyledFormItem>
                </InputContainer>
                <ButtonContainer>
                    <FormItem style={{ marginBottom: 0 }}>
                        <StyledButton
                            htmlType="submit"
                            type="primary"
                            loading={this.props.loading}
                            disabled={
                                this.props.initialLoading ||
                                !this.inputRef?.input.value
                            }
                        >
                            {formatMessage(Strings.ui.buttons.general.send)}
                        </StyledButton>
                    </FormItem>
                </ButtonContainer>
            </StyledForm>
        );
    }
}

export const WrappedSendMessageForm = Form.create<Props>()(SendMessageForm);

const StyledButton = styled(Button)`
    && {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

const StyledInput = styled(Input)`
    && {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

const StyledFormItem = styled(Form.Item)`
    && {
        margin-bottom: 0;
    }
`;

const StyledForm = styled(Form)`
    display: flex;
    width: 100%;
    margin-bottom: 2px;
`;

const InputContainer = styled.div`
    flex-grow: 1;
    margin-left: 8px;
    input {
        &:focus {
            box-shadow: none;
        }
    }
    margin-bottom: 0;
`;

const ButtonContainer = styled.div`
    margin-right: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;
