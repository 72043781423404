import React, { useRef, useState } from 'react';
import { Input } from 'antd';
import { InputField, ValidationMessage } from './types';
import {
    StyledField,
    StyledInputWrapper,
    StyledLabel,
    StyledValidationMessage
} from './styled';

type Props = { field: InputField };

export const TextField = (props: Props): JSX.Element => {
    const { field } = props;
    const timerRef = useRef(0);
    const [value, setValue] = useState<string>(field.value || '');
    const [error, setError] = useState<ValidationMessage>(
        field.validationMessage
    );

    return (
        <StyledField>
            {field.label && (
                <StyledLabel
                    htmlFor={field.name}
                    $required={!!field.indicateRequired}
                >
                    {field.label}
                </StyledLabel>
            )}
            <StyledInputWrapper>
                <Input
                    id={field.name}
                    name={field.name}
                    type={field.type || 'text'}
                    placeholder={field.placeholder || ''}
                    value={field.value}
                    onBlur={onBlur}
                    onChange={onChange}
                    autoComplete="off"
                    pattern={field.pattern}
                    minLength={field.minLength}
                    maxLength={field.maxLength}
                    required={field.required}
                />
            </StyledInputWrapper>
            {/* !field.isValid is needed when resetting forms with errors */}
            {error && !field.isValid && (
                <StyledValidationMessage>{error}</StyledValidationMessage>
            )}
        </StyledField>
    );

    function onBlur(event: React.FocusEvent<HTMLInputElement>) {
        clearTimeout(timerRef.current);
        checkValidity(event);
    }

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.persist(); // TODO: Remove when React is updated to 17+ (https://reactjs.org/docs/legacy-event-pooling.html)
        clearTimeout(timerRef.current);
        field.isTouched = true;
        field.value = event.target.value;
        setValue(field.value);
        checkValidity(event);
        setError(null);

        timerRef.current = window.setTimeout(function () {
            clearTimeout(timerRef.current);
            checkValidity(event);
        }, 5000);
    }

    function checkValidity(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();

        let error;

        if (field.validate) {
            error = field.validate();
        }
        if (!error && field.required && field.value === '') {
            error = field.validationMessages.isRequired;
        }
        if (!error && !event.target.validity.valid) {
            error = getDefaultError(event.target);
        }
        setError(error || null);
        field.isValid = !error;
        return error;
    }

    function getDefaultError(target: any) {
        const { tooShort, tooLong, patternMismatch } =
            target.validity as ValidityState; //TODO: Fix type of target instead

        if (field.validationMessages.custom) {
            return field.validationMessages.custom;
        } else if (tooShort) {
            return field.validationMessages.tooShort;
        } else if (tooLong) {
            return field.validationMessages.tooLong;
        } else if (patternMismatch) {
            return field.validationMessages.patternMismatch;
        }
        return null;
    }
};
