import { HTTPClient } from '../../../services/http-client/http-client';

export const getWatchoversForGroup = (groupId: string) =>
    HTTPClient.get('watchovers', {
        params: {
            groupId,
            limit: 1
        }
    });

export const postWatchover = (externalId: string, groupId: string) =>
    HTTPClient.post('watchovers', { externalId, groupId });
