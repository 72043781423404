import React from 'react';
import { Tooltip } from 'antd';

import { AppMenu } from './header-menus/AppMenu';
import { ClinicGroupMenu } from './header-menus/ClinicMenu';
import { DemoBanner } from '../../../banner/DemoBanner';
import { testSelectors } from '../../../../constants/test-id';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { Strings } from '../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../locale/format/format-message';
import {
    StyledHeader,
    StyledLeftContainer,
    StyledIcon,
    StyledRightContainer
} from './styled';
import { StyledH2 } from '../../../../styled/styled-h2';

const appConfig = __APP_CONFIG__;

type Props = {
    collapsed: boolean;
    toggleCollapsed: () => void;
};

export const Header = (props: Props): JSX.Element => {
    const { toggleCollapsed, collapsed } = props;
    const pageTitle = useReduxSelector((state) => state.appState.pageTitle);
    const isEducated = useReduxSelector((state) => state.user.medicEducated);

    return (
        <StyledHeader collapsed={collapsed.toString()}>
            {appConfig.STAGE === 'demo' && <DemoBanner />}
            <StyledLeftContainer>
                <Tooltip
                    title={
                        collapsed
                            ? formatMessage(
                                  Strings.ui.header.general.showSidebar
                              )
                            : formatMessage(
                                  Strings.ui.header.general.hideSidebar
                              )
                    }
                    mouseLeaveDelay={0}
                    mouseEnterDelay={0.5}
                >
                    <StyledIcon
                        type={collapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={toggleCollapsed}
                    />
                </Tooltip>
                <StyledH2 data-test-id={testSelectors.page.headerTitle}>
                    {pageTitle}
                </StyledH2>
            </StyledLeftContainer>
            <StyledRightContainer>
                {isEducated && <ClinicGroupMenu />}
                <AppMenu />
            </StyledRightContainer>
        </StyledHeader>
    );
};
