import { combineReducers } from 'redux';
import * as patientActionTypes from '../active-patient/active-patient.constants';
import * as conditionTypes from '../conditions/conditions.constants';
import * as patientsActionTypes from '../patient-administration/patient-administration.constants';
import * as priorityActionTypes from '../priority/priority.constants';
import * as valuesActionTypes from '../values/values.constants';
import * as watchOverActionTypes from '../watchovers/watchovers.constants';
import { UiStateType } from './ui.type';

export const initialUiState: UiStateType = {
    overview: {
        filter: {
            searchTerm: '',
            newMessages: false
        },
        conditionsLoading: false,
        latestReportsLoading: false
    },
    patientAdministration: {
        searchTerm: '',
        loading: false
    }
};

function overview(state = initialUiState.overview, action: any) {
    const { payload, type } = action;

    switch (type) {
        // FILTER
        case priorityActionTypes.FILTER_PRIORITY_LISTS:
            return {
                ...state,
                filter: payload
            };
        case priorityActionTypes.CLEAR_FILTER:
            return {
                ...state,
                filter: initialUiState.overview.filter
            };
        // FETCH_PRIORITY_LIST
        case priorityActionTypes.FETCH_PRIORITY_LIST_PENDING:
            return { ...state, priorityListLoading: true };
        case priorityActionTypes.FETCH_PRIORITY_LIST_FULFILLED:
            return { ...state, priorityListLoading: false };
        case priorityActionTypes.FETCH_PRIORITY_LIST_REJECTED:
            return { ...state, priorityListLoading: false };
        // FETCH_WATCHOVERS
        case watchOverActionTypes.FETCH_WATCHOVERS_PENDING:
            return { ...state, watchOversLoading: true };
        case watchOverActionTypes.FETCH_WATCHOVERS_FULFILLED:
            return { ...state, watchOversLoading: false };
        case watchOverActionTypes.FETCH_WATCHOVERS_REJECTED:
            return { ...state, watchOversLoading: false };
        // CONDITIONS
        case conditionTypes.FETCH_CONDITIONS_WITH_INDICATORS_PENDING:
            return { ...state, conditionsLoading: true };
        case conditionTypes.FETCH_CONDITIONS_WITH_INDICATORS_FULFILLED:
            return { ...state, conditionsLoading: false };
        case conditionTypes.FETCH_CONDITIONS_WITH_INDICATORS_REJECTED:
            return { ...state, conditionsLoading: false };
        // START_FETCHING_LATEST
        case valuesActionTypes.START_FETCHING_LATEST_VALUES:
            return { ...state, latestReportsLoading: true };
        case valuesActionTypes.FINISHED_FETCHING_LATEST_VALUES:
            return { ...state, latestReportsLoading: false };
        default:
            return state;
    }
}

function patientAdministration(
    state = initialUiState.patientAdministration,
    action: any
) {
    const { payload, type } = action;

    switch (type) {
        case patientsActionTypes.FILTER_PATIENTS:
            return { ...state, searchTerm: payload };
        case priorityActionTypes.CLEAR_FILTER:
            return {
                ...state,
                searchTerm: ''
            };
        case patientActionTypes.CREATE_PATIENT_PENDING:
            return { ...state, loading: true };
        case patientActionTypes.CREATE_PATIENT_FULFILLED:
            return { ...state, loading: false };
        case patientActionTypes.CREATE_PATIENT_REJECTED:
            return {
                ...state,
                loading: false,
                error: payload?.response?.data
            };

        default:
            return state;
    }
}

const uiReducer = combineReducers({
    overview,
    patientAdministration
});

export default uiReducer;
