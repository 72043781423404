import { PatientTabs } from './tabs';
import { PatientGroupId } from '../../../../../redux/domains/groups/groups.type';
import { IndicatorId } from '../../../../../redux/domains/indicators/indicator.type';
import { PatientExternalId } from '../../../../../redux/domains/user/user.type';
import { pathPatientDataPage } from '../../path';

export const pathPatientProfileLink = (
    patientGroupId: string,
    externalId: PatientExternalId,
    tab?: PatientTabs | string
): string => {
    return `${pathPatientDataPage}/${patientGroupId}/${externalId}/${
        tab || PatientTabs.charts
    }`;
};

export const pathPatientProfileForIndicator = (
    patientGroupId: PatientGroupId,
    externalId: PatientExternalId,
    indicatorId: IndicatorId
): string =>
    `${pathPatientDataPage}/${patientGroupId}/${externalId}/${PatientTabs.charts}/${indicatorId}`;
