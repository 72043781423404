import { styled } from '../../../theme/styled';
import { StyledH2 } from '../../../styled/styled-h2';

export const StyledTableRow = styled.div`
    display: table-row;
`;

export const StyledTableCell = styled.div`
    display: table-cell;
`;

export const StyledTableCellContent = styled.div`
    margin-top: 30px;
`;

export const StyledTitle = styled(StyledH2)`
    color: ${(props) => props.theme.primary};
    margin-bottom: 10px;
`;

export const StyledFlexWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;

export const StyledFullCol = styled.div`
    width: 100%;
    margin-bottom: 30px;
`;

export const StyledBoldSpan = styled.span`
    font-weight: bold;
`;
