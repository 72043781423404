import React from 'react';
import styled from 'styled-components';
import { Alert, Button, Divider } from 'antd';

import { testSelectors } from '../../../../constants/test-id';

import { useHistory, useLocation } from 'react-router-dom';
import { StyledLogoutTitle } from '../styled/styled-logout';
import { routeLogin } from '../../login/route';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';

export type LogoutMessage = {
    message: string;
};

export const LogoutCompleted = () => {
    const routeHistory = useHistory();
    const routeLocation = useLocation<LogoutMessage>();

    const message = routeLocation.state ? routeLocation.state.message : null;

    return (
        <div>
            <StyledLogoutTitle
                data-test-id={testSelectors.auth.loggedOutTitle}
                message={message}
            >
                {formatMessage(Strings.authText.main.logoutCompleted)}
            </StyledLogoutTitle>
            {message && (
                <Alert
                    message={<span style={{ marginRight: 10 }}>{message}</span>}
                    type="info"
                    showIcon
                    style={{ marginBottom: 30 }}
                    closable
                />
            )}
            <StyledLogoutSubtitle
                data-test-id={testSelectors.auth.loggedOutSubTitle}
            >
                {formatMessage(Strings.authText.main.closeBrowser)}
            </StyledLogoutSubtitle>
            <Divider style={{ width: 300, textTransform: 'lowercase' }}>
                {formatMessage(Strings.common.general.or)}
            </Divider>
            <Button
                data-test-id={testSelectors.auth.signInAgainButton}
                type="primary"
                onClick={() => routeHistory.push(routeLogin.link)}
            >
                {formatMessage(Strings.authText.main.loginAgain)}
            </Button>
        </div>
    );
};

const StyledLogoutSubtitle = styled.h3``;
