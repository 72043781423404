import { defineMessages } from 'react-intl';

const general = defineMessages({
    selectQuestion: {
        id: 'placeholder.selectQuestion',
        defaultMessage: 'Select question'
    },
    showColumns: {
        id: 'placeholder.showColumns',
        defaultMessage: 'Show columns'
    }
});

export const field = {
    general
};
