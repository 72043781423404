import { errorHandler } from '../../../services/http-client/handle-error';
import { HTTPClient } from '../../../services/http-client/http-client';
import { MessageOfTheDay } from './motd.types';

export const getMessageOfTheDay = (params?: object) =>
    HTTPClient.get<MessageOfTheDay[]>('motd', { params }).catch((error) =>
        errorHandler(error, 'Error fetching message of the day')
    );

export const markAsRead = (motds: MessageOfTheDay[]) => {
    const idsString = motds.map((motd) => motd.id).join(',');
    HTTPClient.put(`motd/${idsString}`).catch((error) =>
        errorHandler(error, 'Error marking message of the day as read')
    );
};
