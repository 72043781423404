import { defineMessages } from 'react-intl';

const general = defineMessages({
    noReportedValues: {
        id: 'medic.values.no-reported-values',
        defaultMessage: 'No reported values'
    },
    noReportedValuesThisMonth: {
        id: 'medic.values.no-reported-values-this-month',
        defaultMessage: 'No reported values this month'
    }
});

export const values = {
    general
};
