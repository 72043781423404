import { defineMessages } from 'react-intl';

const form1 = defineMessages({
    desc: {
        id: 'education.form-01.desc',
        defaultMessage:
            'När du har tittat på filmerna och manualerna är det dags att göra testet. Klicka i denna ruta för att komma till testet.  \n\nDu får besked direkt efteråt om du blivit godkänd. Om du svarat fel på någon fråga får du göra om testet.  \nNär testet är godkänt får du automatiskt tillgång till hela LifePod och kan börja följa dina patienter på distans.'
    }
});

const page = defineMessages({
    title: {
        id: 'education.form.title',
        defaultMessage: 'Education Test'
    }
});

export const form = {
    page,
    introduction: {
        form1
    }
};
