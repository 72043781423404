import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Pump speed',
        id: 'indicator.pump-speed-lvad.title'
    },
    value: {
        defaultMessage: '{value, number, ::.0}',
        id: 'indicator.pump-speed-lvad.value'
    },
    unit: {
        id: 'indicator.pump-speed-lvad.unit',
        defaultMessage: 'rpm'
    },
    unitX1000: {
        id: 'indicator.pump-speed-lvad.unit-x1000',
        defaultMessage: 'x1000 rpm'
    }
});

export const messagesPumpSpeedLvad = {
    general
};
