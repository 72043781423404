import React from 'react';
import { Avatar, Button, Divider } from 'antd';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { PatientGroup } from '../../../../redux/domains/groups/groups.type';
import styled from 'styled-components';
import { EmailLink } from '../../../patient/header/EmailLink';
import { PhoneLink } from '../../../patient/header/PhoneLink';
import {
    StyledContactIcon,
    StyledUserMeta,
    StyledHeader,
    StyledPersonalId
} from '../../../patient/header/styled';
import { conditions } from '../../../../conditions/index';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { getParticipants } from '../../../../redux/domains/chat/chat.selectors';
import { getCondition } from '../../../../redux/domains/conditions/conditions.selectors';
import {
    getSelectedMedicGroupId,
    getSelectedPatientGroup,
    isMedicAdmin
} from '../../../../redux/domains/groups/groups.selectors';
import { BaseUserMeta } from '../../../../redux/domains/user/user.type';
import { joinVideoMeeting } from '../../../../redux/domains/video-meeting/video-meeting.actions';
import { profiles } from '../../../../theme/profile/profiles';
import { OnClick } from '../../../../types/custom-types';
import { pathPatientProfileLink } from '../../../../pages/patient/data/components/tabs/path';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';

type Props = {
    patientId: string;
    meta: BaseUserMeta;
    hide: OnClick;
    patientGroup?: PatientGroup;
    conditionCode?: string;
};

export const ActionPanelHeader = (props: Props) => {
    const { patientId, meta } = props;
    const selectedPatientGroupId = useReduxSelector(
        (state) => state.groups.selectedPatientGroupId
    );
    const selectedPatientGroupName = useReduxSelector(
        (state) => getSelectedPatientGroup(state)?.name
    );
    const patientGroupName =
        props.patientGroup?.name || selectedPatientGroupName || '';
    const selectedConditionId = useReduxSelector(
        (state) => getSelectedPatientGroup(state)?.condition
    );
    const selectedConditionCode = useReduxSelector(
        (state) =>
            selectedConditionId &&
            getCondition(state, { conditionId: selectedConditionId })?.code
    );
    const conditionName =
        conditions[props.conditionCode || selectedConditionCode || '']?.name ||
        '';
    const videoMeeting = useReduxSelector((state) => state.videoMeeting);
    const medicGroupId = useReduxSelector(getSelectedMedicGroupId);
    const participants = useReduxSelector((state) =>
        getParticipants(state, { patientId, medicGroupId })
    );
    const isVideoMeetingOpen = videoMeeting.subjectId != '';
    const isVideoMeetingDisabled =
        useReduxSelector(isMedicAdmin) ||
        (isVideoMeetingOpen && videoMeeting.token != '');
    const dispatch = useDispatch();

    return (
        <StyledActionPanelHeader>
            <StyledAvatar>
                <Avatar
                    icon="user"
                    size="large"
                    style={{
                        backgroundColor: profiles.main.profile.layout.primary
                    }}
                />
            </StyledAvatar>
            <StyledInfo>
                <StyledHeader>{`${meta.name} ${meta.family_name}`}</StyledHeader>
                <StyledUserMeta>
                    <StyledPersonalId>{meta.personal_id}</StyledPersonalId>
                </StyledUserMeta>
                <StyledUserMeta>
                    <StyledContactIcon type="phone" />
                    <PhoneLink phone={meta.phone_number} />
                </StyledUserMeta>
                <StyledUserMeta>
                    <StyledContactIcon type="mail" />
                    <EmailLink email={meta.email} />
                </StyledUserMeta>
                <Divider style={{ margin: '8px 0', height: 1 }} />
                <StyledSecondRow>
                    <StyledLeftColumn>
                        <StyledPatientGroup>
                            {patientGroupName}
                        </StyledPatientGroup>
                        <p>{conditionName}</p>
                    </StyledLeftColumn>
                    <StyledButtons>
                        {__APP_CONFIG__.FEATURE_VIDEO_MEETING && (
                            <Button
                                icon="video-camera"
                                style={{ marginRight: 8 }}
                                loading={
                                    isVideoMeetingOpen && !videoMeeting.token
                                }
                                disabled={isVideoMeetingDisabled}
                                onClick={openVideoMeeting}
                            >
                                {formatMessage(
                                    Strings.video.actionPanel.header.text
                                )}
                            </Button>
                        )}
                        <Link
                            to={{
                                pathname: pathPatientProfileLink(
                                    props.patientGroup?.id ||
                                        selectedPatientGroupId,
                                    patientId
                                )
                            }}
                        >
                            <Button
                                type="primary"
                                icon="export"
                                onClick={props.hide}
                            >
                                {formatMessage(
                                    Strings.common.general.patientData
                                )}
                            </Button>
                        </Link>
                    </StyledButtons>
                </StyledSecondRow>
            </StyledInfo>
        </StyledActionPanelHeader>
    );

    function openVideoMeeting() {
        dispatch(joinVideoMeeting(medicGroupId, patientId, participants));
    }
};

const StyledAvatar = styled.div`
    .ant-avatar-lg {
        width: 120px !important;
        height: 120px !important;
        line-height: 120px !important;
        border-radius: 60px !important;
    }

    .ant-avatar-icon {
        font-size: 72px !important;
    }
`;

const StyledInfo = styled.div`
    margin-left: 20px;
    flex-grow: 1;
`;

const StyledSecondRow = styled.div`
    display: flex;
`;

const StyledLeftColumn = styled.div`
    flex-grow: 1;
`;

const StyledPatientGroup = styled.p`
    margin: 0;
    font-weight: bold;
`;

const StyledButtons = styled.div`
    white-space: nowrap;
`;

const StyledActionPanelHeader = styled.div`
    display: flex;
    padding: 24px;
`;
