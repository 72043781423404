import React, { Fragment } from 'react';
import { Card, Layout } from 'antd';
import styled from 'styled-components';

const { Content } = Layout;

type Props = {
    title?: string;
    loading?: boolean;
    children: React.ReactElement | React.ReactElement[];
};

export const ContentCardLayout = (props: Props) => {
    const { title, children, loading } = props;
    return (
        <StyledContentCard loading={loading}>
            <StyledLayout>
                {title ? <StyledTitle>{title}</StyledTitle> : <Fragment />}
                <Content>{children}</Content>
            </StyledLayout>
        </StyledContentCard>
    );
};

export const ContentLayout = (props: Props) => {
    const { title, children } = props;
    return (
        <StyledLayout>
            {title ? <StyledTitle>{title}</StyledTitle> : <Fragment />}
            <Content>{children}</Content>
        </StyledLayout>
    );
};

export const StyledLayout = styled(Layout)`
    background-color: transparent;
`;

const StyledContentCard = styled(Card)`
    min-width: 630px;
`;

export const StyledTitle = styled.h2`
    color: rgba(0, 0, 0, 0.85);
    font-weight: 300;
    font-size: 1.1rem;
    margin-bottom: 20px;
`;
