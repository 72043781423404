import { Strings } from '../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../locale/format/format-message';
import { isValidSwedishSSN } from '../../../../../utils/validate/ssn/is-valid-swedish-ssn';

export const authIsEmail = (authMethod?: string) => {
    return authMethod === 'email';
};

export const emailMissing = (email: string) => {
    return !email;
};

export const phoneMissing = (phone: string) => {
    return !phone;
};

export const authIsBankID = (authMethod?: string) => {
    return authMethod === 'bankid';
};

export const phoneOrEmailMissing = (phone: string, email: string) => {
    return !phone && !email;
};

export const validateSSID = (rule: any, value: any, callback: any) => {
    if (value && !isValidSwedishSSN(value)) {
        callback(formatMessage(Strings.form.general.validSwedishPIN));
    }
    return callback();
};

export const getConfirmText = (email: string, phone: string) => {
    const messages = [];
    messages.push(
        formatMessage(Strings.screen.patientAdministration.step1.dialogContent)
    );

    if (emailMissing(email)) {
        messages.push(formatMessage(Strings.form.error.didNotAddEmail));
    }

    if (phoneMissing(phone)) {
        messages.push(formatMessage(Strings.form.error.didNotAddPhone));
    }

    return messages;
};
