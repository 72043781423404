export const typeConfig = {
    400: {
        img: '',
        title: '400',
        description: 'Bad Request'
    },
    403: {
        img: '',
        title: '403',
        description: 'Forbidden'
    },
    404: {
        img: '',
        title: '404',
        description: 'Not found'
    },
    500: {
        img: '',
        title: '500',
        description: 'Server error'
    }
};
