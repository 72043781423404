import { defineMessages } from 'react-intl';

const general = defineMessages({
    patientCommentTitle: {
        id: 'communication.patient-comment.title',
        defaultMessage: 'Patient comments'
    },
    published: {
        id: 'communication.published',
        defaultMessage: 'Published: '
    },
    messageOfTheDayTitle: {
        id: 'communication.message-of-the-day.title',
        defaultMessage: 'Important information'
    },
    messageOfTheDayOKInfoMD: {
        id: 'communication.message-of-the-day.info.title',
        defaultMessage:
            'By pressing OK you have confirmed that you have read this information. You can find all previous messages in the menu at the top right.'
    },
    noMessagesOfTheDay: {
        id: 'communication.message-of-the-day.empty',
        defaultMessage: 'there are no messages'
    }
});

export const communication = {
    general,
    rootId: 'communication.'
};
