import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../../../redux/redux-state.type';
import { isMedicAdmin } from '../../../../redux/domains/groups/groups.selectors';
import {
    getUnreadMessageIds,
    getUnreadValueComments
} from '../../../../redux/domains/notifications/notifications.selectors';
import { getPatientById } from '../../../../redux/domains/patient-administration/patient-administration.selectors';
import { getSettingsByPath } from '../../../../redux/domains/settings/settings.selectors';
import { OnClick } from '../../../../types/custom-types';
import { ActionPanelProps } from '../actionPanelProps';
import { ActionPanelBody } from './ActionPanelBody';
import { ActionPanelHeader } from './ActionPanelHeader';

type Props = ActionPanelProps & {
    patient: any;
    hide: OnClick;
};

class ActionPanelContent extends Component<Props> {
    render() {
        const {
            patient,
            settings,
            defaultTab,
            unreadMessagesCount,
            unreadValueCommentsCount,
            hide,
            isMedicAdmin,
            patientGroup,
            conditionCode
        } = this.props;
        const { meta, externalId } = patient;

        return (
            <>
                <ActionPanelHeader
                    meta={meta}
                    patientId={externalId}
                    hide={hide}
                    patientGroup={patientGroup}
                    conditionCode={conditionCode}
                />
                <ActionPanelBody
                    externalId={externalId}
                    settings={settings}
                    defaultTab={defaultTab}
                    unreadMessagesCount={unreadMessagesCount}
                    unreadValueCommentsCount={unreadValueCommentsCount}
                    isMedicAdmin={isMedicAdmin}
                    patientGroup={patientGroup}
                />
            </>
        );
    }
}

const mapStateToProps = (state: ReduxState, props: any) => {
    const getSettings = getSettingsByPath('');
    return {
        patient: getPatientById(state, props),
        settings: getSettings(state),
        unreadMessagesCount: getUnreadMessageIds(state, props).length,
        unreadValueCommentsCount: getUnreadValueComments(state, props).length,
        isMedicAdmin: isMedicAdmin(state),
        defaultTab: state.actionPanel.defaultTab
    };
};

export const ConnectedActionPanelContent = connect(
    mapStateToProps,
    null
)(ActionPanelContent);
