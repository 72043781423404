import indicatorRules from '../../utils/create-condition-settings/indicator-rules';
import { indicatorItem } from '../../utils/create-condition-settings/items';
import { reasonsStatusChange } from '../../utils/create-condition-settings/reasons-status-change';
import { ConditionRules } from '../condition.type';
import { IndicatorMeta } from '../indicator-meta';

export const createLvadRsConfiguration = (): ConditionRules => {
    return {
        initialObservationIndicators: [IndicatorMeta.BODY_WEIGHT.code],
        indicatorSettings: {
            preventFromEditLimits: [
                IndicatorMeta.PUMP_SELF_TEST_LVAD.code,
                IndicatorMeta.PRNMED_LVAD.code,
                IndicatorMeta.VAS.code,
                IndicatorMeta.PHYSACT.code,
                IndicatorMeta.EQ5D3L.code
            ],
            preventFromEditReminders: [IndicatorMeta.PRNMED_LVAD.code],
            preventFromToggle: [IndicatorMeta.VAS.code]
        },
        priorityTable: {
            hidden: [
                IndicatorMeta.PRNMED_LVAD.code,
                IndicatorMeta.VAS.code,
                IndicatorMeta.PHYSACT.code,
                IndicatorMeta.PUMP_FLOW_LVAD.code,
                IndicatorMeta.PUMP_POWER_LVAD.code,
                IndicatorMeta.PUMP_PULSE_INDEX_LVAD.code,
                IndicatorMeta.PUMP_SPEED_LVAD.code,
                IndicatorMeta.PUMP_SELF_TEST_LVAD.code,
                IndicatorMeta.EQ5D3L.code
            ],
            order: [
                IndicatorMeta.BLOOD_PRESSURE_LVAD.code,
                IndicatorMeta.DIZZINESS.code,
                IndicatorMeta.SWELLING.code,
                IndicatorMeta.BODY_WEIGHT.code,
                IndicatorMeta.BREATHLESSNESS.code,
                IndicatorMeta.TIREDNESS.code,
                IndicatorMeta.BLOOD_PRESSURE.code,
                IndicatorMeta.PULSE.code
            ]
        },
        reasons: reasonsStatusChange(),
        indicators: {
            [IndicatorMeta.PRNMED_LVAD.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PRNMED_LVAD.code]
            ),
            [IndicatorMeta.DIZZINESS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.DIZZINESS.code]
            ),
            [IndicatorMeta.SWELLING.code]: indicatorItem(
                indicatorRules[IndicatorMeta.SWELLING.code]
            ),
            [IndicatorMeta.BODY_WEIGHT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BODY_WEIGHT.code]
            ),
            [IndicatorMeta.TIREDNESS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.TIREDNESS.code]
            ),
            [IndicatorMeta.BREATHLESSNESS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BREATHLESSNESS.code]
            ),
            [IndicatorMeta.BLOOD_PRESSURE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BLOOD_PRESSURE.code]
            ),
            [IndicatorMeta.BLOOD_PRESSURE_LVAD.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BLOOD_PRESSURE_LVAD.code]
            ),
            [IndicatorMeta.VAS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.VAS.code]
            ),
            [IndicatorMeta.PHYSACT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PHYSACT.code]
            ),
            [IndicatorMeta.PULSE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PULSE.code]
            ),
            [IndicatorMeta.PUMP_FLOW_LVAD.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PUMP_FLOW_LVAD.code]
            ),
            [IndicatorMeta.PUMP_SPEED_LVAD.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PUMP_SPEED_LVAD.code]
            ),
            [IndicatorMeta.PUMP_PULSE_INDEX_LVAD.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PUMP_PULSE_INDEX_LVAD.code]
            ),
            [IndicatorMeta.PUMP_POWER_LVAD.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PUMP_POWER_LVAD.code]
            ),
            [IndicatorMeta.PUMP_SELF_TEST_LVAD.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PUMP_SELF_TEST_LVAD.code]
            ),
            [IndicatorMeta.EQ5D3L.code]: indicatorItem(
                indicatorRules[IndicatorMeta.EQ5D3L.code]
            )
        }
    };
};
