import React from 'react';
import { resource } from '../../../../../constants/resources';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { MarkdownFromDescriptor } from '../../../../../locale/format/format-markdown';
import { formatMessage } from '../../../../../locale/format/format-message';

export const videoFiles = [
    {
        path: resource.getVideoResourceUrl('01'),
        title: formatMessage(
            Strings.screen.education.videos.introduction.video01.title
        ),
        desc: (
            <MarkdownFromDescriptor
                messageDescriptor={
                    Strings.screen.education.videos.introduction.video01.desc
                }
            />
        )
    },
    {
        path: resource.getVideoResourceUrl('02'),
        title: formatMessage(
            Strings.screen.education.videos.introduction.video02.title
        ),
        desc: (
            <MarkdownFromDescriptor
                messageDescriptor={
                    Strings.screen.education.videos.introduction.video02.desc
                }
            />
        )
    },
    {
        path: resource.getVideoResourceUrl('03'),
        title: formatMessage(
            Strings.screen.education.videos.introduction.video03.title
        ),
        desc: (
            <MarkdownFromDescriptor
                messageDescriptor={
                    Strings.screen.education.videos.introduction.video03.desc
                }
            />
        )
    },
    {
        path: resource.getVideoResourceUrl('04'),
        title: formatMessage(
            Strings.screen.education.videos.introduction.video04.title
        ),
        desc: (
            <MarkdownFromDescriptor
                messageDescriptor={
                    Strings.screen.education.videos.introduction.video04.desc
                }
            />
        )
    },
    {
        path: resource.getVideoResourceUrl('05'),
        title: formatMessage(
            Strings.screen.education.videos.introduction.video05.title
        ),
        desc: (
            <MarkdownFromDescriptor
                messageDescriptor={
                    Strings.screen.education.videos.introduction.video05.desc
                }
            />
        )
    },
    {
        path: resource.getVideoResourceUrl('06'),
        title: formatMessage(
            Strings.screen.education.videos.introduction.video06.title
        ),
        desc: (
            <MarkdownFromDescriptor
                messageDescriptor={
                    Strings.screen.education.videos.introduction.video06.desc
                }
            />
        )
    },
    {
        path: resource.getVideoResourceUrl('07'),
        title: formatMessage(
            Strings.screen.education.videos.introduction.video07.title
        ),
        desc: (
            <MarkdownFromDescriptor
                messageDescriptor={
                    Strings.screen.education.videos.introduction.video07.desc
                }
            />
        )
    }
];
