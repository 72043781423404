import { Icon } from 'antd';
import React, { PropsWithChildren, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { appLogos } from '../../../constants/app-logos';
import { useMenuRoutes } from '../../../hooks/layout/use-menu-items';
import { useReduxDispatch } from '../../../hooks/use-redux-dispatch';
import { useHistory, useLocation } from '../../../hooks/use-router';
import { constants } from '../../../locale/messagesDescriptors/constant';
import { updateHasError } from '../../../redux/domains/error-boundary/error-boundary.actions';
import { IdleCounter } from '../../idle-counter/IdleCounter';
import { CustomImage } from '../../image/custom-image/CustomImage';
import { VideoMeeting } from '../../video/VideoMeeting';
import { Header } from './header/Header';
import {
    StyledAppNavigation,
    StyledContentLayout,
    StyledH1,
    StyledLogoContent,
    StyledLeftMenuItem,
    StyledPageLayout,
    StyledSider
} from './styled-app-navigation';

export const AppNavigation = (
    props: PropsWithChildren<unknown>
): JSX.Element => {
    const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 1200);
    const routeHistory = useHistory();
    const routeLocation = useLocation();
    const menuRoutes = useMenuRoutes();

    return (
        <StyledPageLayout>
            {__APP_CONFIG__.FEATURE_VIDEO_MEETING && <VideoMeeting />}
            <IdleCounter />
            <StyledSider
                theme="light"
                style={{
                    overflow: 'hidden',
                    borderRight: '1px solid #E8E8E8'
                }}
                width={200}
                collapsible
                trigger={null}
                collapsed={isCollapsed}
            >
                <div>
                    <StyledLogoContent
                        $collapsed={isCollapsed}
                        onClick={routeHome}
                    >
                        <CustomImage
                            src={appLogos.standardLogo.src}
                            alt={appLogos.standardLogo.alt}
                        />
                        {!isCollapsed && (
                            <StyledH1>{constants.lifepodDetails.name}</StyledH1>
                        )}
                    </StyledLogoContent>
                    <LeftMenu />
                </div>
            </StyledSider>
            <StyledContentLayout>
                <Header
                    collapsed={isCollapsed}
                    toggleCollapsed={() => setIsCollapsed(!isCollapsed)}
                />
                {props.children}
            </StyledContentLayout>
        </StyledPageLayout>
    );

    function routeHome() {
        if (routeLocation.pathname !== menuRoutes.defaultRoute.link) {
            routeHistory.push(menuRoutes.defaultRoute.link);
        }
    }
};

const LeftMenu = () => {
    const dispatch = useReduxDispatch();
    const menuRoutes = useMenuRoutes();

    const routeLocation = useLocation();
    const matchedLinks = menuRoutes.routes.filter(({ link }) =>
        routeLocation.pathname.startsWith(link)
    );

    return (
        <StyledAppNavigation
            theme="light"
            mode="inline"
            selectedKeys={matchedLinks.map(({ link }) => link)}
        >
            {menuRoutes.routes.map((item) => (
                <StyledLeftMenuItem
                    key={item.link}
                    id={`menu-item-${item.link
                        .replace(/\//g, '-')
                        .replace(/^-/, '')}`}
                >
                    <NavLink
                        to={item.link}
                        onClick={() =>
                            dispatch(updateHasError({ hasError: false }))
                        }
                    >
                        <Icon type={item.icon} />
                        <span>{item.name}</span>
                    </NavLink>
                </StyledLeftMenuItem>
            ))}
        </StyledAppNavigation>
    );
};
