import styled from 'styled-components';
import { List } from 'antd';
import { getRGBA, theme } from '../../../theme/colors/commonColors';

const StyledListItem = styled(List.Item)`
    padding: 0;
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    && {
        border-bottom: 1px solid ${getRGBA(theme.base.alter2, 0.3)};
    }
`;

export { StyledListItem };
