import { Indicator } from '../../../types/indicator/indicator.type';

import icon from './images/body-temp.svg';
import { rules } from './body-temp-rules';
import { messagesBodyTemp } from './messages/messages';

export const parameterBodyTemp = {
    code: 'BODY-TEMP'
};

export const bodyTemp: Indicator = {
    code: parameterBodyTemp.code,
    icon,
    messages: messagesBodyTemp,
    rules
};
