import React from 'react';
import styled from 'styled-components';
import { FormattedDate } from 'react-intl';

import { MessageOfTheDay } from '../../../redux/domains/motd/motd.types';
import { StyledIcon } from '../../ui/list';
import { motdColorForType, getIconType } from './messageOfTheDayUtils';
import { Markdown } from '../../../locale/format/format-markdown';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

const buildMessage = (motd: MessageOfTheDay) => {
    return (
        <MsgWrapper key={motd.id}>
            <StyledHeaderIcon
                type={getIconType(motd.type)}
                color={motdColorForType(motd.type)}
            />
            <MotdWrapper>
                <StyledMessageTitle>{motd.message.title}</StyledMessageTitle>
                <StyledMsgContent color={motdColorForType(motd.type)}>
                    <Markdown source={motd.message.body} />
                </StyledMsgContent>
                <DateWrapper>
                    {formatMessage(Strings.communication.general.published)}
                    <FormattedDate value={motd.startDate} />
                </DateWrapper>
            </MotdWrapper>
        </MsgWrapper>
    );
};

export const buildMotdMessages = (motds: MessageOfTheDay[]) => {
    return (
        <StyledMsgsContainer>
            <StyledMsgs>{motds.map(buildMessage)}</StyledMsgs>
        </StyledMsgsContainer>
    );
};

const MsgWrapper = styled.div`
    &:not(:last-child) {
        border-bottom: 1px solid #ccc;
    }
    display: flex;
    flex-direction: row;

    padding-top: 1rem;
    padding-bottom: 1rem;
`;

const StyledHeaderIcon = styled(StyledIcon)<{ color: string }>`
    color: ${(props) => (props.color ? props.color : '')};
    font-size: xx-large;

    padding-left: 0.5rem;
    padding-right: 1rem;
`;

const StyledMessageTitle = styled.div`
    font-weight: bold;
    font-size: x-large;
    padding-bottom: 0.5rem;
`;

const MotdWrapper = styled.div``;

export const StyledMsgContent = styled.div`
    margin-top: 0px;
    padding-top: 0px;
`;

const DateWrapper = styled.div`
    color: ${(props) => props.theme.motd.text.body};
    font-style: italic;
    font-size: small;
`;
export const StyledMsgs = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    &:last-child {
        margin-bottom: auto;
    }
`;

export const StyledMsgsContainer = styled.div`
    display: block;
`;
