import { Indicator } from '../../../types/indicator/indicator.type';
import { rules } from './blood-sugar-rules';

import icon from './images/blood-sugar.svg';
import { messagesBloodSugar } from './messages/messages';

export const parameterBloodSugar = {
    code: 'BLOOD-SUGAR'
};

export const bloodSugar: Indicator = {
    code: parameterBloodSugar.code,
    icon,
    messages: messagesBloodSugar,
    rules
};
