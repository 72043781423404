import { IndicatorWithSettings } from '../../../../../redux/domains/indicators/indicator.type';
import { getMarkerWithSymbol } from '../../utils/graph';
import { GraphValueWithComment } from '../../types/graph-value.type';
import {
    GraphValuesSorted,
    IndicatorValue
} from '../../../../../types/indicator/indicator-value.type';
import { CombinedGraphColor } from '../../../../../utils/create-condition-settings/indicator-rules/defaults/colors/graph-color.type';

export const singleCreateEventData = (
    indicator: IndicatorWithSettings,
    event: GraphValuesSorted<IndicatorValue> & {
        bmi?: number;
        sum?: number;
        value?: IndicatorValue;
    },
    lineColorObj: CombinedGraphColor
): GraphValueWithComment[] => {
    const { settings: { rules: { decimals = null } = {} } = {} } = indicator;

    const value = getValue(decimals, event);
    const marker = getMarkerWithSymbol(event.comment, lineColorObj);

    const point: GraphValueWithComment = {
        x: event.date,
        y: parseFloat(`${value}`),
        marker: marker,
        comment: event.comment
    };

    if (event.comment) {
        // Duplicate point in order to show both comment symbol (envelope) and
        // data point marker symbol
        return [
            point,
            {
                ...point,
                marker: getMarkerWithSymbol(false, lineColorObj)
            }
        ];
    }

    return [point];
};

function getValue(
    decimals: Record<string, number> | null,
    event: GraphValuesSorted<IndicatorValue> & {
        value?: IndicatorValue & {
            bmi?: number; // DOES ONLY EXIST FOR BMI -- USED TO SILENCE TYPEING
            sum?: number; // DOES ONLY EXIST FOR EQ5D3L -- USED TO SILENCE TYPEING
        };
    }
): IndicatorValue {
    const sum = event.value?.sum;
    const bmi = event.value?.bmi;

    if (sum != null) {
        return Math.round(sum);
    }

    if (bmi != null && decimals != null) {
        const _decimals = decimals.bmi;
        return parseFloat(bmi.toFixed(_decimals));
    }

    return event.value;
}
