import { HTTPClient } from '../../../services/http-client/http-client';
import { normalizeReportReminders } from './reminders.utils';

export const getMissedReports = (groupId: string, conditionId: string) =>
    HTTPClient.get('reminders/reportreminders/missedreports', {
        params: {
            groupId,
            conditionId
        }
    });

export const getReportReminders = (subjectIds: string, conditionId: string) =>
    HTTPClient.get('reminders/reportreminders', {
        params: {
            subjectIds,
            conditionId
        }
    }).then(normalizeReportReminders);

export const updateReminders = (
    reminder: any,
    indicatorId: string,
    externalId: string,
    reminderId: string
) =>
    HTTPClient.put('reminders/reportreminders', { ...reminder }).then(
        (data) => {
            return {
                externalId,
                indicatorId,
                reminderId,
                data: data[0]
            };
        }
    );
