import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Sleep',
        id: 'indicator.sleep-hs.title'
    },
    question: {
        defaultMessage: 'Is your hand injury affecting your sleep?',
        id: 'indicator.sleep-hs.question'
    }
});

const choices = defineMessages({
    none: {
        defaultMessage: 'Unaffected',
        id: 'indicator.sleep-hs.choices.1'
    },
    some: {
        defaultMessage: 'Affected to some extent',
        id: 'indicator.sleep-hs.choices.2'
    },
    much: {
        defaultMessage: 'Very much affected',
        id: 'indicator.sleep-hs.choices.3'
    }
});

const legend = defineMessages({
    green: {
        defaultMessage: 'Unaffected',
        id: 'indicator.sleep-hs.legend.1'
    },
    some: {
        defaultMessage: 'Affected to some extent',
        id: 'indicator.sleep-hs.legend.2'
    },
    much: {
        defaultMessage: 'Very much affected',
        id: 'indicator.sleep-hs.legend.3'
    }
});

export const messagesSleepHs = {
    general,
    choices,
    legend
};
