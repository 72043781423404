import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ContentCardLayout } from '../../../components/content/ContentLayout';
import { MedicGroupList } from './components/MedicGroupList';
import { MedicList } from './components/MedicList';
import { usePageTitle } from '../../../hooks/use-page-title';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { fetchGroups } from '../../../redux/domains/groups/groups.actions';
import { createLoadingSelector } from '../../../redux/domains/requests/requests.selectors';
import { FETCH_GROUPS } from '../../../redux/domains/groups/groups.constants';
import { CrossSpinner } from '../../../components/ui/loading/loading';
import { titleMedicOverview } from './title';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { AppPrimaryButton } from '../../../components/ui/buttons/AppPrimaryButton';
import { messages } from '../messages';
import { Link, useParams } from 'react-router-dom';
import { pathRegisterMedic } from '../register/path';

import { isCurrentUserAdminInGroup } from '../../../redux/domains/groups/groups.selectors';
import { MedicGroupId } from '../../../redux/domains/groups/groups.type';

const loadingSelector = createLoadingSelector([FETCH_GROUPS]);

export const MedicOverviewPage = (): JSX.Element => {
    usePageTitle(titleMedicOverview);
    const dispatch = useDispatch();
    const isLoading = useReduxSelector(loadingSelector);
    const routeParams = useParams<{ medicGroupId: MedicGroupId }>();
    const selectedMedicGroup = routeParams.medicGroupId;

    useEffect(() => {
        dispatch(fetchGroups());
    }, [dispatch]);

    const isAdmin = useReduxSelector((state) =>
        isCurrentUserAdminInGroup(state, { medicGroupId: selectedMedicGroup })
    );

    return (
        <ContentCardLayout>
            {isLoading ? (
                <CrossSpinner
                    text={formatMessage(
                        Strings.events.general.fetchingInformation
                    )}
                />
            ) : (
                <div>
                    {isAdmin ? (
                        <Link to={pathRegisterMedic}>
                            <AppPrimaryButton
                                style={{ marginLeft: 24, marginBottom: 24 }}
                            >
                                {formatMessage(messages.registerMedic.add)}
                            </AppPrimaryButton>
                        </Link>
                    ) : (
                        <></>
                    )}

                    <div style={{ display: 'flex' }}>
                        <MedicGroupList />
                        <MedicList />
                    </div>
                </div>
            )}
        </ContentCardLayout>
    );
};
