import React, { CSSProperties, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { useTheme } from '../../../hooks/use-theme';
import { Card, Icon } from 'antd';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { getSelectedCondition } from '../../../redux/domains/conditions/conditions.selectors';
import { IndicatorWithSettings } from '../../../redux/domains/indicators/indicator.type';
import { fetchReportReminders } from '../../../redux/domains/reminders/reminders.actions';
import { ExternalId } from '../../../redux/domains/user/user.type';
import { useChartWidth } from '../use-chart-width';
import { getDaysFromMillisec } from './time-convert';

export const MissedReportInfo = (props: {
    indicator: IndicatorWithSettings;
    subjectId: ExternalId;
}): JSX.Element => {
    const { indicator, subjectId } = props;
    const dispatch = useDispatch<any>();

    const theme = useTheme();
    const width = useChartWidth(props.indicator.settings.rules);

    const conditionId = useReduxSelector(getSelectedCondition).id;

    const reminderInterval = useReduxSelector((state) => {
        const reminders = state.reminders.reportReminders[subjectId];
        return reminders && reminders[indicator.id][0].missedReportInterval;
    });

    useEffect(() => {
        dispatch(fetchReportReminders([subjectId], conditionId));
    }, [dispatch, conditionId, subjectId]);

    const intervalDays = getDaysFromMillisec(reminderInterval);

    const intervalText = formatMessage(
        Strings.common.general.recommendationDynamicDay,
        {
            numeric: intervalDays
        }
    );

    return (
        <Card
            style={{ width }}
            title={
                <span style={titleSpanStyle}>
                    <Icon
                        type="exclamation-circle"
                        theme="outlined"
                        style={{ fontSize: 24, marginRight: 10 }}
                    />
                    {formatMessage(Strings.common.general.overdue)}
                </span>
            }
            headStyle={{
                backgroundColor: theme.profile.denote.yellowBright
            }}
        >
            {intervalText}
        </Card>
    );
};

const titleSpanStyle: CSSProperties = {
    display: 'flex',
    maxHeight: 21,
    height: '100%'
};
