import React, { PropsWithChildren } from 'react';
import { colors } from '../../../../../../theme/colors/commonColors';
import { TrendPopoverContent } from './TrendPopoverContent';
import { IndicatorWithSettings } from '../../../../../../redux/domains/indicators/indicator.type';
import { PatientExternalId } from '../../../../../../redux/domains/user/user.type';
import { Popover, Button } from 'antd';

type IndicatorTrendProps = {
    indicator: IndicatorWithSettings;
    patientName: string;
    subjectId: PatientExternalId;
};

export const IndicatorTrend = (
    props: PropsWithChildren<IndicatorTrendProps>
): JSX.Element => {
    const [visible, setVisible] = React.useState(false);

    return (
        <Popover
            trigger="click"
            placement="bottom"
            content={
                visible && (
                    <TrendPopoverContent
                        indicator={props.indicator}
                        subjectId={props.subjectId}
                    />
                )
            }
            title={
                <div style={titleStyle}>
                    <span style={titleSpanStyle}>
                        <div>
                            {props.indicator.title}
                            <span style={{ color: colors.ui.title.user }}>
                                {props.patientName}
                            </span>
                        </div>
                    </span>
                    <Button
                        icon="close"
                        shape="circle"
                        size="small"
                        onClick={() => setVisible(false)}
                    />
                </div>
            }
            visible={visible}
            onVisibleChange={(visible: boolean) => setVisible(visible)}
        >
            {props.children}
        </Popover>
    );
};

const titleStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5
};

const titleSpanStyle: React.CSSProperties = { marginRight: 10 };
