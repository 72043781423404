import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchEvents } from '../../../../../redux/domains/events/events.actions';
import {
    fetchWatchoversForGroup,
    registerWatchover
} from '../../../../../redux/domains/watchovers/watchovers.actions';
import {
    fetchPriorityList,
    resetPriority
} from '../../../../../redux/domains/priority/priority.actions';
import {
    getLatestReset,
    getLogEvents,
    getResetEvents
} from '../../../../../redux/domains/events/events.selectors';
import { getLatestWatchoverForPatient } from '../../../../../redux/domains/watchovers/watchovers.selectors';
import { getSelectedPatientGroupId } from '../../../../../redux/domains/groups/groups.selectors';
import {
    fetchNotificationsBySelectedMedicId,
    confirmValueComments
} from '../../../../../redux/domains/notifications/notifications.actions';
import styled from 'styled-components';
import { ReduxState } from '../../../../../redux/redux-state.type';

import { RightColumn } from './general-content/RightColumn';
import { LeftColumn } from './general-content/LeftColumn';

type Props = any;

class GeneralTab extends Component<Props> {
    componentDidMount() {
        const { externalId, fetchEvents, fetchNotificationsBySelectedMedicId } =
            this.props;
        fetchEvents({
            principalId: externalId,
            type: 'watchover,reset_priority,user_status_change,feedback'
        });
        fetchNotificationsBySelectedMedicId();
        this.props.fetchWatchoversForGroup();
    }

    render() {
        const {
            fetchingEvents,
            latestReset,
            logEvents,
            latestWatchover,
            settings,
            isMedicAdmin
        } = this.props;

        return (
            <StyledDiv>
                <LeftColumn
                    latestWatchover={latestWatchover}
                    latestReset={latestReset}
                    isMedicAdmin={isMedicAdmin}
                    settings={settings}
                    onRegisterWatchover={this.onRegisterWatchover}
                    onResetPriority={this.onResetPriority}
                />
                <RightColumn
                    fetchingEvents={fetchingEvents}
                    logEvents={logEvents}
                />
            </StyledDiv>
        );
    }

    onRegisterWatchover = () => {
        const {
            externalId,
            registerWatchover,
            fetchEvents,
            confirmValueComments
        } = this.props;
        return registerWatchover(externalId).then(() => {
            fetchEvents({
                principalId: externalId,
                type: 'watchover,reset_priority,user_status_change,feedback'
            });
            confirmValueComments(externalId);
        });
    };

    onResetPriority = ({ reason, note }: any) => {
        const {
            externalId,
            groupId,
            proxyId,
            registerWatchover,
            fetchPriorityList,
            resetPriority,
            fetchEvents,
            confirmValueComments
        } = this.props;

        const data = {
            externalId,
            externalGroup: groupId,
            proxyId,
            action: 'reset_priority',
            reason,
            note
        };

        return resetPriority(data)
            .then(() => registerWatchover(externalId))
            .then(fetchPriorityList)
            .then(() => {
                fetchEvents({
                    principalId: externalId,
                    type: 'watchover,reset_priority,user_status_change,feedback'
                });
                confirmValueComments(externalId);
            });
    };
}

const mapStateToProps = (state: ReduxState, props: any) => {
    return {
        latestWatchover: getLatestWatchoverForPatient(state, props), // This includes status property
        resetEvents: getResetEvents(state),
        logEvents: getLogEvents(state),
        fetchingEvents: state.actionPanel.tabs.general.fetchingEvents,
        groupId: getSelectedPatientGroupId(state),
        proxyId: state.user.externalId,
        latestReset: getLatestReset(state)
    };
};

export const ConnectedGeneralTab = connect(mapStateToProps, {
    resetPriority,
    fetchPriorityList,
    registerWatchover,
    fetchWatchoversForGroup,
    fetchEvents,
    fetchNotificationsBySelectedMedicId,
    confirmValueComments
})(GeneralTab);

const StyledDiv = styled.div`
    display: flex;
    height: 100%;
`;
