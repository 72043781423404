import hsRs from '../hand-surgery/hs-rs';
import { Condition, ConditionIndicator } from '../condition.type';
import { swellingHsDemo } from './indicators/swelling-hs-demo/swelling-hs-demo';
import { activeMotion } from '../hand-surgery/indicators/active-motion/active-motion';
import { painAtRest } from '../hand-surgery/indicators/pain/pain-at-rest';
import { painDuringActivity } from '../hand-surgery/indicators/pain/pain-during-activity';
import { painAfterActivity } from '../hand-surgery/indicators/pain/pain-after-activity';
import { medication } from '../hand-surgery/indicators/medication/medication';
import { orthosisNight } from '../hand-surgery/indicators/orthosis-night/orthosis-night';
import { orthosisDay } from '../hand-surgery/indicators/orthosis-day/orthosis-day';
import { sleep } from '../hand-surgery/indicators/sleep/sleep';
import { grip } from '../hand-surgery/indicators/grip/grip';
import { dailyActivity } from '../hand-surgery/indicators/daily-activity/daily-activity';
import { vocationalActivity } from '../hand-surgery/indicators/vocational-activity/vocational-activity';
import { vas } from '../shared-indicators/vas/vas';
import { exercisesDemo } from './indicators/exercises-hs-demo/exercises-hs-demo';

const code = 'LP_2_0_HS_RS_DEMO_UH';
const publicCode = 'hs';

const hsRsName = Object.keys(hsRs)[0];

const indicators: ConditionIndicator[] = [
    exercisesDemo,
    swellingHsDemo,
    activeMotion,
    painAtRest,
    painDuringActivity,
    painAfterActivity,
    medication,
    orthosisNight,
    orthosisDay,
    sleep,
    grip,
    dailyActivity,
    vocationalActivity,
    vas
];

const condition: Record<string, Condition> = {
    [code]: {
        ...hsRs[hsRsName],
        indicators,
        code,
        publicCode
    }
};

export default condition;
