import { routeEducation } from '../../pages/education/page/route';
import { routeUneducatedStart } from '../../pages/education/uneducated-start/route';
import { routePatientOverview } from '../../pages/patient/overview/route';
import { routePatientSearch } from '../../pages/patient/search/route';
import { routeMedicOverview } from '../../pages/medic/overview/route';
import { RouteItemType } from '../../utils/route/switch-routes';
import { useReduxSelector } from '../use-redux-selector';
import {
    isCurrentUserAdminInSelectedGroup,
    isCurrentUserMedicInSelectedGroup
} from '../../redux/domains/groups/groups.selectors';
import { routePatientAdministrationSwitch } from '../../pages/patient/administration/route';
import { routeStatistics } from '../../pages/statistics/route';

const uneducatedRoutes: RouteItemType[] = [
    routeUneducatedStart,
    routeEducation
];

const educatedRoutes: RouteItemType[] = [
    routePatientOverview,
    routePatientAdministrationSwitch,
    routePatientSearch,
    routeMedicOverview,
    routeStatistics
];

const adminRoutes: RouteItemType[] = [routeMedicOverview];

export function useMenuRoutes(): {
    routes: RouteItemType[];
    defaultRoute: RouteItemType;
} {
    const isEducated = useReduxSelector((state) => state.user.medicEducated);
    const isMedic = useReduxSelector(isCurrentUserMedicInSelectedGroup);
    const isAdmin = useReduxSelector(isCurrentUserAdminInSelectedGroup);

    if (!isEducated) {
        return {
            routes: uneducatedRoutes,
            defaultRoute: uneducatedRoutes[0]
        };
    }

    if (isMedic) {
        return {
            routes: educatedRoutes,
            defaultRoute: educatedRoutes[0]
        };
    }

    if (isAdmin) {
        return {
            routes: adminRoutes,
            defaultRoute: adminRoutes[0]
        };
    }

    throw new Error('Route for user does not exist');
}
