import React, { PropsWithChildren } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

export type RouteItemType = {
    key?: string;
    name: string;
    link: string;
    links?: string[];
    icon: string;
    exact?: boolean;
    component:
        | React.ComponentType<RouteComponentProps<any>>
        | React.ComponentType<any>;
};

export const SwitchRoutes = (
    props: PropsWithChildren<{
        routes: RouteItemType[];
    }>
): JSX.Element => (
    <Switch>
        {props.routes.map((route) => (
            <Route
                key={route.key ? route.key : route.link}
                exact={route.exact}
                path={route.links ? route.links : route.link}
                component={route.component}
            />
        ))}
        {props.children}
    </Switch>
);
