import { Icon, Layout } from 'antd';
import {
    animationCss,
    calcWidthBySubtract,
    keyWidthSubtract
} from '../styled-helpers/animationMain';
import { styled } from '../../../../theme/styled';

export const StyledHeader = styled(Layout.Header)<{
    collapsed: string;
}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
    position: absolute;

    // High z number to cover messages "dot" in priority list
    z-index: 15;

    background-color: white;
    width: ${(props) =>
        props.collapsed === 'true'
            ? calcWidthBySubtract('80px')
            : calcWidthBySubtract('200px')};
    ${(props) =>
        props.collapsed === 'true'
            ? animationCss(
                  keyWidthSubtract(
                      calcWidthBySubtract('200px'),
                      calcWidthBySubtract('80px')
                  )
              )
            : animationCss(
                  keyWidthSubtract(
                      calcWidthBySubtract('80px'),
                      calcWidthBySubtract('200px')
                  )
              )};
    height: ${(props) => props.theme.layout.header.height}px;
    line-height: ${(props) => props.theme.layout.header.height}px;
    padding: 0;
    margin-bottom: 0;

    border-bottom: ${(props) => props.theme.layout.header.border.size}px solid
        #e8e8e8;
`;

export const StyledLeftContainer = styled.div`
    display: flex;
`;

export const StyledRightContainer = styled.div`
    right: 0px;
    display: flex;
    position: absolute;

    height: ${(props) =>
        props.theme.layout.header.height -
        props.theme.layout.header.border.size}px;

    // IE fix
    top: 0;
`;

export const StyledIcon = styled(Icon)`
    height: ${(props) =>
        props.theme.layout.header.height -
        props.theme.layout.header.border.size}px;
    margin: 0;
    padding: 15px;
    transition: all 0.25s ease-in-out;

    &:hover {
        cursor: pointer;
        background-color: rgba(91, 100, 194, 0.09);
        background-color: #eef2ff;
    }

    cursor: pointer;
    font-size: 1.2rem;
`;
