import React from 'react';
import { useHistory } from 'react-router-dom';
import { MedicDetails } from '../../../../components/medic/details/MedicDetails';
import { MedicMeta } from '../../../../redux/domains/medic/medic.type';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';
import { AppPrimaryButton } from '../../../../components/ui/buttons/AppPrimaryButton';
import { BackButtonWithArrow } from '../../../../components/ui/buttons/BackButtonWithArrow';

export const ProfileMetaView = (props: {
    meta: MedicMeta;
    setEditable: (editable: boolean) => void;
}): JSX.Element => {
    const history = useHistory();

    const { meta, setEditable } = props;

    return (
        <>
            <BackButtonWithArrow
                style={{ marginBottom: 10 }}
                onClick={() => history.goBack()}
            />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 50
                }}
            >
                <MedicDetails meta={meta} />
                <AppPrimaryButton onClick={() => setEditable(true)}>
                    {formatMessage(Strings.ui.buttons.general.edit)}
                </AppPrimaryButton>
            </div>
        </>
    );
};
