import { defineMessages } from 'react-intl';

const general = defineMessages({
    groupSearchDescription: {
        id: 'patient-search.general.group-search-description',
        defaultMessage:
            'Search for patients in my groups and clinics. At least one field needs to be entered.'
    },
    systemSearchDescription: {
        id: 'patient-search.general.system-search-description',
        defaultMessage:
            'Search for a patient in the system. You have to enter a valid social security number (must be 12 digits).'
    },
    tooManyResults: {
        id: 'patient-search.general.to-many-results',
        defaultMessage: 'Too many results. Please enter more search criterias.'
    },
    proceedWarning: {
        id: 'patient-search.general.proceed-warning',
        defaultMessage:
            'Do you have a medical relation and a consent from this patient to view his/her data?\nIf you continue, this will be logged by LifePod.\nDo you want to continue?'
    }
});

const errors = defineMessages({
    navigateToPatientFailed: {
        id: 'patient-search.errors.navigate-to-patient-failed',
        defaultMessage: 'Failed to navigate to the patient. Please try again.'
    }
});

export const patientSearch = {
    general,
    errors
};
