const companyDetails = {
    name: 'Cross Technology Solutions AB',
    addressRow1: 'Åldermansgatan 10',
    addressRow2: 'SE-227 64 Lund, Sweden',
    phoneNumber: '+46 (0) 700-400 402',
    phoneHref: '0700400402',
    supportEmail: 'support@lifepod.se'
};

const lifepodDetails = {
    name: 'LifePod',
    versionNumber: '2.0',
    date: '2000_1_2.0',
    usaPatent: 'U.S. Patent No. 10,952,664',
    eurPatent: 'European Patent No. 2043507',
    canPatent: 'CA Patent No. 2656074',
    ausPatent: 'AU Patent No. 2007275137',
    rusPatent: 'RU Patent No. 2472427',
    trademarkId: '393890'
};

const constants = {
    companyDetails,
    lifepodDetails
};

export { constants };
