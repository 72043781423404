import React from 'react';
import styled from 'styled-components';

import { ContentCardLayout } from '../../components/content/ContentLayout';
import { titleAbout } from './title';
import { usePageTitle } from '../../hooks/use-page-title';
import { messagesAbout } from './messages';
import { MarkdownFromDescriptor } from '../../locale/format/format-markdown';
import { appIcons } from '../../constants/app-icons';
import { CustomImage } from '../../components/image/custom-image/CustomImage';
import { Strings } from '../../locale/messagesDescriptors';
import { formatMessage } from '../../locale/format/format-message';

export const AboutPage = (): JSX.Element => {
    usePageTitle(titleAbout);

    return (
        <ContentCardLayout>
            <StyledRow>
                <StyledColumn>
                    <div style={{ maxWidth: 350 }}>
                        <h3>
                            <span>{Strings.constants.lifepodDetails.name}</span>
                            <sup>&reg; </sup>
                            {formatMessage(messagesAbout.general.platform)}
                        </h3>
                        <p>
                            {formatMessage(messagesAbout.general.version, {
                                value: process.env.version
                            })}
                        </p>

                        <div style={{ whiteSpace: 'normal' }}>
                            <MarkdownFromDescriptor
                                messageDescriptor={
                                    messagesAbout.condition.intendedUse
                                }
                            />
                        </div>

                        <h4>
                            {formatMessage(messagesAbout.general.manufacturer)}
                        </h4>
                        <div>{Strings.constants.companyDetails.name}</div>
                        <div>
                            {Strings.constants.companyDetails.addressRow1}
                        </div>
                        <div>
                            {Strings.constants.companyDetails.addressRow2}
                        </div>
                        <div>
                            {Strings.constants.companyDetails.phoneNumber}
                        </div>
                        <CustomImageWrapper src={appIcons.manufacturer} />
                        <CustomImageWrapper src={appIcons.ce} />
                        <br />
                        <br />

                        <p>
                            {formatMessage(messagesAbout.general.patentPrefix, {
                                patentId:
                                    Strings.constants.lifepodDetails.usaPatent
                            })}
                            <br />
                            {formatMessage(messagesAbout.general.patentPrefix, {
                                patentId:
                                    Strings.constants.lifepodDetails.eurPatent
                            })}
                            <br />
                            {formatMessage(messagesAbout.general.patentPrefix, {
                                patentId:
                                    Strings.constants.lifepodDetails.canPatent
                            })}
                            <br />
                            {formatMessage(messagesAbout.general.patentPrefix, {
                                patentId:
                                    Strings.constants.lifepodDetails.ausPatent
                            })}
                            <br />
                            {formatMessage(messagesAbout.general.patentPrefix, {
                                patentId:
                                    Strings.constants.lifepodDetails.rusPatent
                            })}
                        </p>
                        <p>
                            {formatMessage(
                                messagesAbout.general.usesOpenSource
                            )}
                        </p>
                        <p>
                            <span>{Strings.constants.lifepodDetails.name}</span>
                            <sup>&reg; </sup>
                            {formatMessage(messagesAbout.general.trademark, {
                                trademarkId:
                                    Strings.constants.lifepodDetails.trademarkId
                            })}
                            <br />
                            {formatMessage(
                                messagesAbout.general.copyright
                            )}{' '}
                            &copy; {Strings.constants.companyDetails.name}
                        </p>
                    </div>
                </StyledColumn>
                <StyledColumn>
                    <h3>{formatMessage(messagesAbout.general.support)}</h3>
                    <span style={{ fontWeight: 'bold' }}>
                        {formatMessage(Strings.labels.label.phoneNumber)}:{' '}
                    </span>
                    <span>{Strings.constants.companyDetails.phoneNumber}</span>
                    <br />
                    <span style={{ fontWeight: 'bold' }}>
                        {formatMessage(Strings.labels.label.email)}:{' '}
                    </span>
                    <a
                        href={`mailto:${Strings.constants.companyDetails.supportEmail}`}
                    >
                        {Strings.constants.companyDetails.supportEmail}
                    </a>
                </StyledColumn>
            </StyledRow>
        </ContentCardLayout>
    );
};

const StyledRow = styled.div`
    display: flex;
    overflow: auto;
`;

const StyledColumn = styled.div`
    margin-right: 80px;
    display: inline-block;
    white-space: nowrap;

    :last-child {
        margin-right: unset;
    }
`;

const CustomImageWrapper = styled(CustomImage)`
    width: 50px;
    height: 50px;
`;
