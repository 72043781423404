import { ModifiedDataType } from '../../../redux/domains/statistics/statistics.type';
import { getValues } from './getValues';

export const getOptionVAS = (props: { data: ModifiedDataType[] }) => {
    const data = getValues(props);

    const colorPalette: string[] = [];
    Object.values(data).map((item) => {
        item && colorPalette.push(item.color);
    });

    return {
        legend: {
            orient: 'vertical',
            bottom: '40%',
            left: '300',
            textStyle: {
                width: '100',
                overflow: 'break'
            }
        },
        series: [
            {
                type: 'pie',
                radius: '35%',
                center: ['40%', '50%'],
                data: [...data],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                color: colorPalette,
                itemStyle: {
                    normal: {
                        label: {
                            show: false
                        },
                        labelLine: {
                            show: false
                        }
                    }
                }
            }
        ]
    };
};
