import React, { useState } from 'react';
import Highcharts, { Point, TooltipFormatterContextObject } from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import {
    Caption,
    Chart,
    HighchartsChart,
    Legend,
    PlotBand,
    Subtitle,
    Title,
    Tooltip,
    XAxis,
    YAxis
} from 'react-jsx-highcharts';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { IndicatorWithSettings } from '../../../redux/domains/indicators/indicator.type';
import { IndicatorCode } from '../../../types/indicator/indicator.type';
import { isIndicatorMultiProperty } from '../../../guards/indicator/is-indicator-multi-property';
import { plotOptions } from './options';
import { isChartRules } from '../../../redux/domains/settings/settings.type';
import { styleChart } from './style';
import {
    tooltipFormatterSingleProperty,
    tooltipFormatterMultiProperty
} from './utils/tooltip-formatter-code';
import { isIndicatorSingleProperty } from '../../../guards/indicator/is-indicator-single-property';

import { MultiPropertyIndicators } from './multi/MultiPropertyIndicator';
import { SingleIndicators } from './single/SingleValueIndicator';
import { getYAxisTitle } from './utils/render-y-axis-title';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { ChartDateRange } from '../../../redux/domains/values/values.selectors';
import { useDispatch } from 'react-redux';
import { setSelectedDate } from '../../../redux/domains/chart/shared/chart.shared.actions';
import { plotBandConfigColors } from '../../../redux/domains/chart/graph/axis/plotbands/plot-band-config-colors';
import { formatDate } from '../../../locale/format/format-date';
import { IndicatorSettingsMapping } from '../../../conditions/condition.type';
import { ToolTipPoint, UserOptionsWithCode } from './utils/tooltip.type';

HighchartsMore(Highcharts);

export const GraphWithRedux = (props: {
    settingsMapping: IndicatorSettingsMapping;
    chartIndicatorsWithSettings: Record<IndicatorCode, IndicatorWithSettings>;
    xAxisDateRange: ChartDateRange;
}): JSX.Element => {
    const dispatch = useDispatch();

    const settingsMapping = props.settingsMapping;
    const xAxisDateRange = props.xAxisDateRange;
    const chartIndicators = props.chartIndicatorsWithSettings;

    const sortedGraphValuesByDate = useReduxSelector(
        (state) => state.charts.graph.sortedValuesByCode
    );

    const activeGraph = useReduxSelector(
        (state) => state.charts.graph.activeGraph
    );

    const startEndOnTick = useReduxSelector(
        (state) => state.charts.graph.yAxis.calculated.startEndOnTick
    );

    const yAxisMin = useReduxSelector(
        (state) => state.charts.graph.yAxis.calculated.min
    );

    const yAxisMax = useReduxSelector(
        (state) => state.charts.graph.yAxis.calculated.max
    );

    const indicators = Object.values(chartIndicators);
    const multiPropertyIndicators = indicators.filter(isIndicatorMultiProperty);
    const singlePropertyIndicators = indicators.filter(
        isIndicatorSingleProperty
    );

    const selectedDate = useReduxSelector(
        (state) => state.charts.shared.selectedDate
    );

    const selectedConditionCode = useReduxSelector(
        (state) => state.selectedPatient.conditionIds[0]
    );

    const code = useReduxSelector(
        (state) => state.conditions.byId[selectedConditionCode].code
    );

    const preventFromEditLimits = useReduxSelector(
        (state) => state.settings[code].indicatorSettings.preventFromEditLimits
    );

    const [sharedTooltip, setSharedTooltip] = useState(false);

    const renderPlotbands = () => {
        if (!activeGraph) {
            return;
        }

        if (activeGraph.yAxis.plotbands.length > 0) {
            return activeGraph.yAxis.plotbands.map((plotband, index) => {
                return (
                    <PlotBand
                        id={`${index}`}
                        key={index}
                        from={plotband.from}
                        to={plotband.to}
                        color={plotband.color}
                    />
                );
            });
        }

        if (
            isChartRules(activeGraph.indicatorSettings.rules) &&
            !activeGraph.indicatorSettings.rules.shouldNotRenderPlotbands &&
            !preventFromEditLimits.includes(activeGraph.code)
        ) {
            return (
                <PlotBand
                    from={activeGraph.yAxis.min || 0}
                    to={activeGraph.yAxis.max || 0}
                    color={plotBandConfigColors.green}
                />
            );
        }

        return;
    };
    return (
        <HighchartsChart
            plotOptions={plotOptions}
            time={{ useUTC: false }}
            boost={{
                useGPUTranslations: true,
                usePreallocated: true
            }}
        >
            <Chart
                marginRight={10}
                zoomType="x"
                panning={{
                    enabled: true,
                    type: 'x'
                }}
                panKey="shift"
                resetZoomButton={{
                    position: { y: -60, align: 'left', x: -50 },
                    theme: {
                        fill: 'white',
                        stroke: '#d9d9d9',
                        r: 3,
                        style: {
                            color: '#595959',
                            fontWeight: '300',
                            paddingLeft: '15px'
                        },
                        states: {
                            hover: {
                                fill: '#fff',
                                stroke: '#828bcf',
                                style: {
                                    color: '#828bcf'
                                }
                            }
                        }
                    }
                }}
                style={styleChart}
                onClick={(e) => {
                    //@ts-ignore
                    const xAxis = e.xAxis;
                    showNeedleLine(parseInt(xAxis[0].value));
                }}
            />

            <Title>{formatMessage(Strings.chart.general.title)}</Title>

            <Subtitle widthAdjust={-250}>
                {formatMessage(Strings.chart.general.subtitle)}
            </Subtitle>

            <XAxis
                id="xAxis"
                type="datetime"
                dateTimeLabelFormats={{
                    millisecond: '%H:%M',
                    second: '%H:%M',
                    day: '%e %b',
                    week: '%e %b',
                    month: '%b %Y'
                }}
                min={xAxisDateRange.from}
                max={xAxisDateRange.to}
                plotLines={
                    selectedDate
                        ? [
                              {
                                  zIndex: 9999,
                                  color: '#000000',
                                  width: 2,
                                  value: selectedDate,
                                  label: {
                                      text: '&#9654;',
                                      x: -7,
                                      y: 7,
                                      align: 'center',
                                      style: {
                                          color: '#000000',
                                          fontSize: '23px'
                                      }
                                  }
                              }
                          ]
                        : []
                }
            />

            <YAxis
                id="yAxis"
                startOnTick={!!startEndOnTick}
                endOnTick={!!startEndOnTick}
                min={yAxisMin}
                max={yAxisMax}
            >
                <YAxis.Title>
                    {getYAxisTitle({
                        code: activeGraph?.code,
                        indicators: Object.values(chartIndicators)
                    })}
                </YAxis.Title>
                {renderPlotbands()}
                {multiPropertyIndicators.map((indicator, index) => (
                    <MultiPropertyIndicators
                        key={index}
                        indicator={indicator}
                        sortedGraphValuesByDate={sortedGraphValuesByDate}
                        chartIndicators={chartIndicators}
                        settingsMapping={settingsMapping}
                        sharedTooltips={handleSharedTooltips}
                    />
                ))}
                {singlePropertyIndicators.map((indicator, index) => (
                    <SingleIndicators
                        key={index}
                        indicator={indicator}
                        sortedGraphValuesByDate={sortedGraphValuesByDate}
                        chartIndicators={chartIndicators}
                        settingsMapping={settingsMapping}
                        activeGraph={activeGraph}
                        sharedTooltips={handleSharedTooltips}
                    />
                ))}
            </YAxis>
            <Caption
                align="center"
                style={{
                    fontWeight: 'bold',
                    color: 'rgb(51, 51, 51)'
                }}
            >
                {selectedDate
                    ? formatMessage(Strings.chart.general.selectedDate) +
                      formatDate(selectedDate)
                    : null}
            </Caption>

            <Tooltip
                formatter={tooltipFormatterFn}
                split={sharedTooltip}
                snap={0}
            />

            <Legend />
        </HighchartsChart>
    );

    function tooltipFormatterFn(this: TooltipFormatterContextObject): string {
        if (sharedTooltip && this.points) {
            const points: ToolTipPoint[] = this.points;
            return tooltipFormatterMultiProperty(chartIndicators, points);
        } else {
            const userOptions: UserOptionsWithCode = this.series.userOptions;
            const indicatorCode = userOptions.indicatorCode;
            const points: Point[] = [this.point];

            return tooltipFormatterSingleProperty(
                chartIndicators,
                indicatorCode,
                points
            );
        }
    }

    function showNeedleLine(date: number) {
        dispatch(setSelectedDate(date));
    }

    function handleSharedTooltips(value: boolean) {
        if (value !== sharedTooltip) {
            setSharedTooltip(value);
        }
    }
};
