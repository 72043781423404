import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        id: 'medic-overview.title',
        defaultMessage: 'Staff'
    }
});

const error = defineMessages({
    deactivationNotAllowedTitle: {
        id: 'medic-overview.deactivation-not-allows-title',
        defaultMessage: 'Deactivation not allowed'
    },
    deactivationNotAllowedContent: {
        id: 'medic-overview.deactivation-not-allows-content',
        defaultMessage:
            'There must be staff in all care units with active patients. This is the last coworker in this group and can therefore not be deactivated.'
    }
});

export const medicOverview = {
    general,
    error
};
