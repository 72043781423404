import * as actionTypes from './video-meeting.constants';
import {
    createVideoConnection,
    createVideoSession,
    deleteVideoSession,
    getActiveVideoSessions
} from './video-meeting.api';

import { getConversationId } from '../chat/chat.selectors';
import { fetchOrCreateConversationId, sendMessage } from '../chat/chat.actions';
import { VideoSession } from '@lifepod-legacy/openvidu-frontend';
import { VideoMeeting } from './video-meeting.types';
import { MessagingParticipant } from '../chat/chat.types';

export const joinVideoMeeting =
    (
        medicGroupId: string,
        subjectId: string,
        participants: MessagingParticipant[]
    ) =>
    (dispatch: any) => {
        dispatch({
            type: actionTypes.CREATE_VIDEO_MEETING,
            payload: getActiveVideoSessions(subjectId)
                .then((sessions) =>
                    sessions.length
                        ? sessions[0]
                        : createVideoSession(subjectId).then(function (
                              session
                          ) {
                              dispatch(
                                  sendVideoMeeting(
                                      medicGroupId,
                                      subjectId,
                                      session,
                                      participants
                                  )
                              );
                              return session;
                          })
                )
                .then((session) =>
                    createVideoConnection(session).then(({ token }) => ({
                        session,
                        token
                    }))
                )
        });

        return dispatch({
            type: actionTypes.JOIN_VIDEO_MEETING,
            payload: subjectId
        });
    };

const sendVideoMeeting =
    (
        medicGroupId: string,
        patientId: string,
        session: VideoSession,
        participants: MessagingParticipant[]
    ) =>
    (dispatch: any, getState: any) =>
        Promise.resolve(
            getConversationId(getState(), { medicGroupId, patientId }) ||
                dispatch(
                    fetchOrCreateConversationId(
                        medicGroupId,
                        patientId,
                        participants
                    )
                )
        ).then((conversationId) =>
            dispatch(
                sendMessage(patientId, conversationId, {
                    content: '#video/' + session.id
                })
            )
        );

export const leaveVideoMeeting =
    (othersHaveLeft: boolean) => (dispatch: any, getState: any) => {
        const videoMeeting: VideoMeeting = getState().videoMeeting;
        const { subjectId, session } = videoMeeting;

        return dispatch({
            type: actionTypes.LEAVE_VIDEO_MEETING,
            payload: Promise.resolve(
                othersHaveLeft ||
                    getActiveVideoSessions(subjectId).then(
                        (sessions) => sessions.length === 0
                    )
            ).then(
                (othersHaveLeft) =>
                    othersHaveLeft && session && deleteVideoSession(session)
            )
        });
    };
