import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'EHRA',
        id: 'indicator.ehra.title'
    }
});

const choices = defineMessages({
    none: {
        defaultMessage: '1 AF does not cause any symptoms',
        id: 'indicator.ehra.choices.1'
    },
    notAffected: {
        defaultMessage:
            '2a Normal daily activity not affected by symptoms related to AF',
        id: 'indicator.ehra.choices.2'
    },
    troubledBySymptoms: {
        defaultMessage:
            '2b Normal daily activity not affected by symptoms related to AF, but patient troubled by symptoms',
        id: 'indicator.ehra.choices.3'
    },
    symptomsRelated: {
        defaultMessage:
            '3 Normal daily activity affected by symptoms related to AF',
        id: 'indicator.ehra.choices.4'
    },
    activityDiscontinued: {
        defaultMessage: '4 Normal daily activity discontinued',
        id: 'indicator.ehra.choices.5'
    }
});

export const messagesEhra = {
    general,
    choices
};
