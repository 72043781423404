import { Indicator } from '../../../types/indicator/indicator.type';

import icon from './images/phlegm.svg';
import { messagesPhlegm } from './messages/messages';
import { PhlegmProperties } from './phlegm-properties';
import { rules } from './phlegm-rules';

export const parameterPhlegm = {
    code: 'PHLEGM',
    properties: PhlegmProperties
};

export const phlegm: Indicator = {
    code: parameterPhlegm.code,
    icon,
    messages: messagesPhlegm,
    rules
};
