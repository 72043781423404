export function getYAxisMinMaxForPlotbandExcluded(params: {
    fixedYAxisMin: number | undefined;
    fixedYAxisMax: number | undefined;
    defaultMin: number;
    defaultMax: number;
}): {
    min: number;
    max: number;
} {
    const fixedYAxisMin = params.fixedYAxisMin;
    const fixedYAxisMax = params.fixedYAxisMax;
    const defaultMin = params.defaultMin;
    const defaultMax = params.defaultMax;

    let min = null;
    let max = null;

    if (typeof fixedYAxisMin === 'number') {
        min = fixedYAxisMin;
    }

    if (typeof fixedYAxisMax === 'number') {
        max = fixedYAxisMax;
    }

    return { min: min || defaultMin, max: max || defaultMax };
}
