import React from 'react';
import { Row, Col } from 'antd';
import { EchartHeatmap } from './EchartHeatmap';
import { IndicatorConfigurations } from '../../../redux/domains/conditions/conditions.type';
import {
    ChartDateRange,
    getChartValues
} from '../../../redux/domains/values/values.selectors';
import { IndicatorWithSettings } from '../../../redux/domains/indicators/indicator.type';
import { useSelector } from 'react-redux';
import { IndicatorSettingsMapping } from '../../../conditions/condition.type';

export const HeatmapContainer = (props: {
    settingsMapping: IndicatorSettingsMapping;
    heatmapIndicators: IndicatorWithSettings[];
    dateRange: ChartDateRange;
    indicatorConfigurations: IndicatorConfigurations;
}) => {
    const settingsMapping = props.settingsMapping;
    const indicatorConfigurations = props.indicatorConfigurations;
    const heatmapIndicators = props.heatmapIndicators;
    const values = useSelector(getChartValues);

    return (
        <Row gutter={16} style={{ marginTop: 50 }}>
            {heatmapIndicators.map((indicator, index: number) => {
                const valuesForIndicator = values[indicator.id]
                    ? values[indicator.id]
                    : [];
                const offset = index % 2 === 0 ? 1 : 2;

                return (
                    <Col
                        xxl={{ span: 10, offset }}
                        sm={{ span: 20, offset: 1 }}
                        key={indicator.id}
                        style={{ marginBottom: 30 }}
                    >
                        <EchartHeatmap
                            indicator={indicator}
                            values={valuesForIndicator}
                            dateRange={props.dateRange}
                            indicatorSettings={settingsMapping[indicator.code]}
                            indicatorConfiguration={
                                indicatorConfigurations[indicator.id]
                            }
                        />
                    </Col>
                );
            })}
        </Row>
    );
};
