import { Reasons } from '../../../types/reasons.enum';
import { GroupsType } from '../patient-search/patient-search.type';
import { UserMeta } from './user.reducer.type';
import { BaseUserMeta, ExternalId, UserStatus } from './user.type';

export enum Roles {
    medic = 'medic',
    admin = 'admin'
}

export type UpdateUserMeta = Omit<UserMeta, 'username' | 'medicID'>;
export type BaseUserResponse = GroupsType & {
    externalId: ExternalId;
    username: string;
    ssn: string;
    status: string;
    meta: BaseUserMeta;
    medicEducated: boolean;
};

export type RequestUpdateUserStatus = {
    status: UserStatus;
    reason: Reasons;
};

export type UpdateMedic = Omit<
    {
        [Key in keyof UpdateUserMeta]?: UpdateUserMeta[Key];
    },
    'username' | 'medicID'
> & { medicEducated?: boolean };

export type UserGroupsForMedic = {
    roles: string[];
};
