import images from '../static/images/logos/logo';
const defaultAlt = 'Lifepod Logo';

const authLogo = {
    src: images.auth_logo,
    alt: defaultAlt
};

const standardLogo = {
    src: images.logo,
    alt: defaultAlt
};

const standardLogoSvg = {
    src: images.logo_svg,
    alt: defaultAlt
};

export const appLogos = {
    authLogo,
    standardLogo,
    standardLogoSvg
};
