import React, { Component, Fragment } from 'react';
import { Button, Form, Input, Modal, Row, Select } from 'antd';
import { History } from 'history';
import { mapValues, trim } from 'lodash';
import { connect } from 'react-redux';
import { PatientGroup } from '../../../../../redux/domains/groups/groups.type';
import { theme } from '../../../../../theme/colors/commonColors';
import SimpleForm from '../../../../../components/form/SimpleForm';
import {
    createPatient,
    savePendingRegisterPatientId
} from '../../../../../redux/domains/active-patient/active-patient.actions';
import {
    clearRegisterFormFields,
    saveRegisterFormFields
} from '../../../../../redux/domains/forms/forms.actions';
import { RegisterPatientValues } from '../../../../../redux/domains/forms/forms.type';
import { getSelectablePatientGroups } from '../../../../../redux/domains/groups/groups.selectors';
import {
    authIsBankID,
    authIsEmail,
    emailMissing,
    getConfirmText,
    phoneMissing,
    phoneOrEmailMissing
} from './step-1-support';
import { StyledActionBar } from '../styled';
import { StyledFormWrapper } from '../styled';
import {
    buildAuthField,
    buildEmailField,
    buildFirstNameField,
    buildGroupField,
    buildLastNameField,
    buildPasswordField,
    buildPhoneField,
    buildSSIDField
} from './components/fields';
import { pathCreatePatientStep2 } from '../step-2/path';
import { pathPatientAdministrationSwitch } from '../../../administration/path';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { AuthMethods } from '../../../../../constants/auth-methods';
import { CreatePatient } from '../../../../../redux/domains/active-patient/active-patient.api.types';
import { LoginMethods } from '../../../../../constants/login-options';

const showEmailModalError = () => {
    return Modal.error({
        title: formatMessage(Strings.form.error.ohMy),
        content: formatMessage(Strings.form.error.emailMissing)
    });
};

const showPhoneOrEmailError = () => {
    return Modal.error({
        title: formatMessage(Strings.form.error.ohMy),
        content: formatMessage(Strings.form.error.phoneOrEmailMissing)
    });
};

const buildConfirmMessage = (rows: any) => {
    return (
        <Fragment>
            {rows.map((row: any, index: any) => {
                if (index > 0) {
                    return (
                        <p style={{ fontWeight: 'bold' }} key={index}>
                            {row}
                        </p>
                    );
                }
                return <p key={index}>{row}</p>;
            })}
        </Fragment>
    );
};

const callingCodeFieldDecorator = (fieldDecorator: any) => {
    const initialValue = '+46';

    return fieldDecorator('countryCallingCode', {
        initialValue: initialValue
    })(
        <Select style={{ width: 70 }} tabIndex={-1}>
            <Select.Option value={initialValue}>{initialValue}</Select.Option>
        </Select>
    );
};

export type CreatePatientState = {
    patient: {
        name: string;
        family_name: string;
        identity: string;
    };
};

type Props = {
    history: History<CreatePatientState>;
    selectableGroups: PatientGroup[];
    savedFormValues: RegisterPatientValues;
    saveRegisterFormFields: typeof saveRegisterFormFields;
    createPatient: any;
    savePendingRegisterPatientId: any;
};

class Step1 extends Component<Props> {
    private form: any;

    componentDidMount() {
        const { state } = this.props.history.location;

        if (state) {
            if (state.patient) {
                // If coming from o-auth login

                this.form.props.form.setFieldsValue({
                    firstname: state.patient.name,
                    lastname: state.patient.family_name,
                    identity: state.patient.identity
                });
            }
        }
    }

    render() {
        const { selectableGroups = [], savedFormValues } = this.props;

        return (
            <StyledFormWrapper>
                <SimpleForm
                    onSubmit={this.handleSubmit}
                    wrappedComponentRef={(form: any) => (this.form = form)}
                    render={(form: any) => {
                        const { getFieldDecorator, getFieldValue } = form;

                        return (
                            <Row
                                style={{
                                    borderTop: `2px solid ${theme.layout.primary}`,
                                    padding: 20,
                                    width: 350,
                                    margin: 'auto'
                                }}
                            >
                                {buildSSIDField(
                                    getFieldDecorator,
                                    savedFormValues
                                )}
                                {buildFirstNameField(
                                    getFieldDecorator,
                                    savedFormValues
                                )}
                                {buildLastNameField(
                                    getFieldDecorator,
                                    savedFormValues
                                )}
                                {buildEmailField(
                                    getFieldDecorator,
                                    savedFormValues
                                )}
                                {buildPhoneField(
                                    getFieldDecorator,
                                    savedFormValues,
                                    callingCodeFieldDecorator(getFieldDecorator)
                                )}
                                {buildGroupField(
                                    getFieldDecorator,
                                    savedFormValues,
                                    selectableGroups
                                )}
                                {buildAuthField(
                                    getFieldDecorator,
                                    savedFormValues
                                )}

                                {getFieldValue('nAuthMethod') ===
                                    LoginMethods.email &&
                                    buildPasswordField(getFieldDecorator)}
                                <Form.Item>
                                    {getFieldDecorator('identity')(
                                        <Input type="hidden" />
                                    )}
                                </Form.Item>
                                <StyledActionBar>
                                    <Button onClick={this.onCancel}>
                                        {formatMessage(
                                            Strings.common.general.cancel
                                        )}
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ marginLeft: 8 }}
                                    >
                                        {formatMessage(
                                            Strings.common.general.next
                                        )}
                                    </Button>
                                </StyledActionBar>
                            </Row>
                        );
                    }}
                />
            </StyledFormWrapper>
        );
    }

    handleSubmit = () => {
        this.form.props.form.validateFields(
            (errors: any, values: RegisterPatientValues) => {
                if (!errors) {
                    values = this.trimValues(values);
                    values.nSsid = values.nSsid.replace('-', '');

                    const {
                        nSsid,
                        nFirstName,
                        nLastName,
                        countryCallingCode,
                        nGroups,
                        nPassword,
                        nAuthMethod,
                        identity
                    } = values;

                    let { nEmail, nPhone } = values;

                    if (authIsEmail(nAuthMethod)) {
                        if (emailMissing(nEmail)) {
                            showEmailModalError();
                            return;
                        }
                    }

                    if (authIsBankID(nAuthMethod)) {
                        if (phoneOrEmailMissing(nPhone, nEmail)) {
                            showPhoneOrEmailError();
                            return;
                        }
                    }

                    this.props.saveRegisterFormFields(values);

                    const confirmMessage = buildConfirmMessage(
                        getConfirmText(nEmail, nPhone)
                    );

                    if (emailMissing(nEmail)) {
                        nEmail = '';
                    }

                    if (phoneMissing(nPhone)) {
                        nPhone = '';
                    } else {
                        nPhone = countryCallingCode + nPhone;
                    }

                    nEmail = nEmail.toLowerCase();

                    const username =
                        nAuthMethod === LoginMethods.email ? nEmail : undefined;

                    let authMethods = nAuthMethod ? [nAuthMethod] : [];

                    if (nAuthMethod === LoginMethods.email) {
                        authMethods = [AuthMethods.basic];
                    }

                    if (nAuthMethod === LoginMethods.bankid) {
                        authMethods = [AuthMethods.bankId];
                    }

                    const userForBE: CreatePatient = {
                        ssn: nSsid,
                        firstName: nFirstName,
                        lastName: nLastName,
                        email: nEmail,
                        username: username,
                        phoneNumber: nPhone,
                        groups: nGroups ? [nGroups] : [],
                        roles: ['patient'],
                        authMethods,
                        password: nPassword,
                        identity: identity ? [identity] : undefined
                    };

                    Modal.confirm({
                        title: formatMessage(
                            Strings.screen.patientAdministration.step1
                                .dialogTitle
                        ),

                        content: confirmMessage,

                        onOk: () => {
                            return this.props
                                .createPatient(userForBE)
                                .then((data: any) => {
                                    const {
                                        value: { externalId }
                                    } = data;

                                    this.props.savePendingRegisterPatientId(
                                        externalId
                                    );

                                    this.props.history.push(
                                        pathCreatePatientStep2
                                    );
                                });
                        }
                    });
                }
            }
        );
    };

    onCancel = () => {
        this.props.history.push(pathPatientAdministrationSwitch);
    };

    trimValues = (obj: RegisterPatientValues) => mapValues(obj, trim);
}

const mapStateToProps = (state: any) => {
    return {
        selectableGroups: getSelectablePatientGroups(state),
        savedFormValues: state.forms.registerPatient.values
    };
};

export default connect(mapStateToProps, {
    saveRegisterFormFields,
    clearRegisterFormFields,
    createPatient,
    savePendingRegisterPatientId
})(Step1);
