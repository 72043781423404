import * as actionTypes from './conditions.constants';
import { NormalizedConditionsState } from './conditions.normalizer';
import { Conditions } from './conditions.type';

const initialState: Conditions = {
    byId: {},
    allIds: [],
    selectedCondition: ''
};

export interface FetchConditionsWithIndicatorsFulfilledAction {
    type: 'FETCH_CONDITIONS_WITH_INDICATORS_FULFILLED';
    payload: NormalizedConditionsState;
}

export interface SetSelectedConditionPendingAction {
    type: 'SET_SELECTED_CONDITION';
    payload: any;
}

type PatientAction =
    | FetchConditionsWithIndicatorsFulfilledAction
    | SetSelectedConditionPendingAction;

export default function conditionReducer(
    state = initialState,
    action: PatientAction | undefined = undefined
) {
    if (action === undefined) {
        return state;
    }

    switch (action.type) {
        case actionTypes.FETCH_CONDITIONS_WITH_INDICATORS_FULFILLED: {
            const { conditions } = action.payload;
            const byId = { ...state.byId, ...conditions.byId };
            return {
                ...state,
                allIds: Object.keys(byId),
                byId
            };
        }
        case actionTypes.SET_SELECTED_CONDITION:
            return { ...state, selectedCondition: action.payload };
        default:
            return state;
    }
}
