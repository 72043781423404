import { formatMessageById } from '../../../../../../../locale/format/format-message';
import { formatNumber } from '../../../../../../../locale/format/format-number';
import { Strings } from '../../../../../../../locale/messagesDescriptors';

export const valueIsNumber = (
    value: number,
    codeToLowerCase: string,
    step: number | undefined
) => {
    const unit = formatMessageById(
        `${Strings.indicators.rootId}${codeToLowerCase}.unit`,
        { value: value }
    );

    const formattedValue = formatMessageById(
        `indicator.${codeToLowerCase}.value`,
        {
            value
        }
    );
    const choices = formatMessageById(
        `indicator.${codeToLowerCase}.choices.${value}`
    );

    if (unit) {
        return step === 0.1 ? `${formattedValue} ${unit}` : `${value} ${unit}`;
    }
    if (choices) {
        return choices;
    }

    return formattedValue || formatNumber(value);
};

export const valueIsBoolean = (value: boolean, codeToLowerCase: string) => {
    const returnValue = value.toString();
    return formatMessageById(
        `indicator.${codeToLowerCase}.choices.${returnValue}`
    );
};
