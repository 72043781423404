import React from 'react';
import { MedicMeta } from '../../../redux/domains/medic/medic.type';

import {
    StyledContactIcon,
    StyledHeader,
    StyledTitle,
    StyledUserMeta,
    StyledWrapper
} from '../../patient/header/styled';
import { PhoneLink } from '../../patient/header/PhoneLink';
import { EmailLink } from '../../patient/header/EmailLink';
import { MedicGroupId } from '../../../redux/domains/groups/groups.type';

export const MedicDetails = (props: {
    meta: MedicMeta;
    style?: React.CSSProperties;
}): JSX.Element => {
    const { meta, style } = props;
    const { medicTitle, name, family_name, email, phone_number } = meta;

    const fullName = `${name} ${family_name}`;

    return (
        <div style={style}>
            <StyledWrapper>
                <StyledHeader>{fullName}</StyledHeader>
                {medicTitle && <StyledTitle> - {medicTitle} </StyledTitle>}
            </StyledWrapper>

            <StyledUserMeta>
                <StyledContactIcon type="mail" />
                <EmailLink email={email} />
            </StyledUserMeta>
            <StyledUserMeta>
                <StyledContactIcon type="phone" />
                <PhoneLink phone={phone_number} />
            </StyledUserMeta>
        </div>
    );
};
