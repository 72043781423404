export const FETCH_MISSED_REPORTS = 'FETCH_MISSED_REPORTS';
export const FETCH_MISSED_REPORTS_PENDING = 'FETCH_MISSED_REPORTS_PENDING';
export const FETCH_MISSED_REPORTS_FULFILLED = 'FETCH_MISSED_REPORTS_FULFILLED';
export const FETCH_MISSED_REPORTS_REJECTED = 'FETCH_MISSED_REPORTS_REJECTED';

export const FETCH_REPORT_REMINDERS = 'FETCH_REPORT_REMINDERS';
export const FETCH_REPORT_REMINDERS_PENDING = 'FETCH_REPORT_REMINDERS_PENDING';
export const FETCH_REPORT_REMINDERS_FULFILLED =
    'FETCH_REPORT_REMINDERS_FULFILLED';
export const FETCH_REPORT_REMINDERS_REJECTED =
    'FETCH_REPORT_REMINDERS_REJECTED';

export const UPDATE_REPORT_REMINDERS = 'UPDATE_REPORT_REMINDERS';
export const UPDATE_REPORT_REMINDERS_PENDING =
    'UPDATE_REPORT_REMINDERS_PENDING';
export const UPDATE_REPORT_REMINDERS_FULFILLED =
    'UPDATE_REPORT_REMINDERS_FULFILLED';
export const UPDATE_REPORT_REMINDERS_REJECTED =
    'UPDATE_REPORT_REMINDERS_REJECTED';
