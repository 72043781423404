import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import {
    ContentCardLayout,
    ContentLayout
} from '../../components/content/ContentLayout';
import { getMessageOfTheDay } from '../../redux/domains/motd/motd.api';
import { CrossSpinner } from '../../components/ui/loading/loading';
import { MessageOfTheDay } from '../../redux/domains/motd/motd.types';
import { hasMotds } from '../../components/shared/message-of-the-day/messageOfTheDayUtils';
import { buildMotdMessages } from '../../components/shared/message-of-the-day/motdShared';
import { titleMessageOfTheDay } from './title';
import { usePageTitle } from '../../hooks/use-page-title';
import { formatMessage } from '../../locale/format/format-message';
import { Strings } from '../../locale/messagesDescriptors';

const buildEmptyMessage = () => {
    return (
        <StyledEmptyMessage>
            {formatMessage(Strings.communication.general.noMessagesOfTheDay)}
        </StyledEmptyMessage>
    );
};

export const buildTimeline = (motds: MessageOfTheDay[]) => {
    return hasMotds(motds) ? buildMotdMessages(motds) : buildEmptyMessage();
};

export const MessageOfTheDayPage = () => {
    usePageTitle(titleMessageOfTheDay);
    const mounted = useRef(true);
    const [loading, setLoading] = useState(true);
    const [motds, setMOTDs] = useState<MessageOfTheDay[]>([]);

    useEffect(() => {
        if (mounted.current) {
            getMessageOfTheDay({ status: 'read' })
                .then((motds) => {
                    setMOTDs(motds);
                })
                .finally(() => setLoading(false));
        }

        return () => {
            mounted.current = false;
        };
    }, [loading, motds]);

    return (
        <ContentLayout>
            <Card>
                {loading ? (
                    <ContentCardLayout>
                        <CrossSpinner />
                    </ContentCardLayout>
                ) : (
                    buildTimeline(motds)
                )}
            </Card>
        </ContentLayout>
    );
};

const StyledEmptyMessage = styled.div`
    text-align: center;
`;
