export const plotOptions: Highcharts.PlotOptions = {
    series: {
        states: {
            inactive: {
                opacity: 1
            }
        }
    },
    spline: {
        lineWidth: 2,
        states: {
            hover: {
                lineWidth: 3
            }
        }
    },
    columnrange: {
        pointWidth: 2,
        grouping: false
    }
};
