import React from 'react';
import { Select } from 'antd';
import { ModeOption } from 'antd/lib/select';
import { LoginMethods } from '../../../../constants/login-options';
import { inputFieldWidth } from '../style-options';
import { GroupOption } from './group-option.type';

export const SelectOptions = (props: {
    handleChange: (event: LoginMethods) => void;
    placeholder: string;
    options: GroupOption[];
    mode: ModeOption;
    defaultValue?: LoginMethods;
}): JSX.Element => {
    const { handleChange, options, placeholder, defaultValue } = props;
    const mode = props.mode;

    return (
        <Select
            mode={mode}
            style={{ width: inputFieldWidth }}
            placeholder={placeholder}
            onChange={(event: LoginMethods) => handleChange(event)}
            defaultValue={defaultValue}
        >
            {mapOptions(options)}
        </Select>
    );

    function mapOptions(options: GroupOption[]) {
        return options.map((x) => {
            return <Select.Option key={x.key}>{x.label}</Select.Option>;
        });
    }
};
