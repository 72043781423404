import colors from '../../../../theme/colors/commonColors';
import { ChartType } from '../../../../types/indicator/chart.type';
import { IndicatorChartGraphRules } from '../../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartGraphRules = {
    step: 1,
    chartTypes: [ChartType.Spline],
    lineColor: {
        name: colors.chart.graph.purple.name,
        default: colors.chart.graph.purple.primary
    }
};
