import { defineMessages } from 'react-intl';

const general = defineMessages({
    changeMedicGroupTitle: {
        id: 'medic.confirm.changeMedicGroupTitle',
        defaultMessage: 'Change group'
    },
    changeMedicGroupContent: {
        id: 'medic.confirm.changeMedicGroupContent',
        defaultMessage: 'You will navigate back to the overview'
    },
    unsavedChanges: {
        id: 'confirm.unsaved-changes',
        defaultMessage:
            'You have unsaved changes. Are you sure you want to cancel?'
    }
});

export const confirm = {
    general
};
