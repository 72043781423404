import { defineMessages } from 'react-intl';

const hf = defineMessages({
    title: {
        id: 'conditions.lphf.title',
        defaultMessage: 'Heart failure'
    },
    abbreviation: {
        id: 'conditions.lphf.abbreviation',
        defaultMessage: 'HF'
    }
});

const copd = defineMessages({
    title: {
        id: 'conditions.copd.title',
        defaultMessage: 'Chronic obstructive pulmonary disease'
    },
    abbreviation: {
        id: 'conditions.copd.abbreviation',
        defaultMessage: 'COPD'
    }
});

export const conditions = {
    hf,
    copd
};
