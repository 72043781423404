import React, { PropsWithChildren } from 'react';
import {
    StyledColumn,
    StyledColumnRight,
    StyledRowWrapper
} from './styled-column';

type Props = {
    icon?: JSX.Element;
};

export const ColumnItem = (props: PropsWithChildren<Props>): JSX.Element => (
    <StyledRowWrapper>
        <StyledColumn>{props.children}</StyledColumn>
        <StyledColumnRight>{props.icon}</StyledColumnRight>
    </StyledRowWrapper>
);
