import * as React from 'react';
import { ContentCardLayout } from '../content/ContentLayout';
import { Icon } from 'antd';
import { errorMessages } from '../../locale/messagesDescriptors/errors';
import { formatMessage } from '../../locale/format/format-message';
import { updateHasError } from '../../redux/domains/error-boundary/error-boundary.actions';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import { useReduxDispatch } from '../../hooks/use-redux-dispatch';

const ErrorBoundary = (props: React.PropsWithChildren<unknown>) => {
    const dispatch = useReduxDispatch();
    const hasError = useReduxSelector((state) => state.errorBoundary.hasError);

    return (
        <ErrorBoundaryWrapped
            hasError={hasError}
            updateHasError={() => dispatch(updateHasError({ hasError: true }))}
        >
            {props.children}
        </ErrorBoundaryWrapped>
    );
};

type Props = { hasError: boolean; updateHasError: () => void };

type State = { hasError: boolean };
class ErrorBoundaryWrapped extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: any, info: any) {
        console.log('ErrorBoundary', error);
        this.setState({ hasError: true });
        this.props.updateHasError();
    }

    static getDerivedStateFromError() {
        // Silence getDerivedStateFromError error since we moved the
        // state to redux for easier handling
        return { hasError: false };
    }

    render() {
        if (this.props.hasError) {
            return (
                <ContentCardLayout>
                    <div style={{ textAlign: 'center' }}>
                        <Icon type="frown-o" style={{ fontSize: 74 }} />
                        <h1>{formatMessage(errorMessages.unknown.message)}</h1>
                    </div>
                </ContentCardLayout>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
