import _ from 'lodash';
import { PlotbandLimit } from '../../../../../../components/charts/graph/types/plotband.type';

export function getChartMax(
    chartMax: number,
    fixedYAxisMax: number,
    useReportMax: boolean,
    upperBoundaries: PlotbandLimit[],
    report_max: number | null | undefined,
    margin: number,
    greenBoundary: PlotbandLimit
) {
    if (_.isNumber(fixedYAxisMax)) {
        return fixedYAxisMax;
    } else if (fixedYAxisMax || useReportMax) {
        return report_max;
    } else if (upperBoundaries == null || !upperBoundaries.length) {
        if (greenBoundary != null && !_.isEmpty(greenBoundary)) {
            const { from } = greenBoundary;
            return from + margin;
        }
        return chartMax;
    } else {
        // @ts-ignore
        const highestTo = _.maxBy(upperBoundaries, 'to').to + margin;

        return highestTo < chartMax ? chartMax : highestTo;
    }
}
