import {
    FieldType,
    RadioGroupFields
} from '../../../components/form/inputs/types';
import { formatMessage } from '../../../locale/format/format-message';
import { messages } from './messages';

export const initialFields: RadioGroupFields = {
    question1: {
        fieldType: FieldType.RadioGroup,
        name: 'question1',
        label: formatMessage(messages.questions.question1),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer1, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer1, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer1, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 2,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question2: {
        fieldType: FieldType.RadioGroup,
        name: 'question2',
        label: formatMessage(messages.questions.question2),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer2, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer2, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer2, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 1,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question3: {
        fieldType: FieldType.RadioGroup,
        name: 'question3',
        label: formatMessage(messages.questions.question3),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer3, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer3, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer3, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 0,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question4: {
        fieldType: FieldType.RadioGroup,
        name: 'question4',
        label: formatMessage(messages.questions.question4),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer4, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer4, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer4, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 0,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question5: {
        fieldType: FieldType.RadioGroup,
        name: 'question5',
        label: formatMessage(messages.questions.question5),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer5, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer5, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer5, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 1,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question6: {
        fieldType: FieldType.RadioGroup,
        name: 'question6',
        label: formatMessage(messages.questions.question6),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer6, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer6, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer6, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 1,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question7: {
        fieldType: FieldType.RadioGroup,
        name: 'question7',
        label: formatMessage(messages.questions.question7),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer7, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer7, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer7, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 0,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question8: {
        fieldType: FieldType.RadioGroup,
        name: 'question8',
        label: formatMessage(messages.questions.question8),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer8, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer8, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer8, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 1,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question9: {
        fieldType: FieldType.RadioGroup,
        name: 'question9',
        label: formatMessage(messages.questions.question9),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer9, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer9, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer9, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 1,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question10: {
        fieldType: FieldType.RadioGroup,
        name: 'question10',
        label: formatMessage(messages.questions.question10),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer10, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer10, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer10, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 1,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question11: {
        fieldType: FieldType.RadioGroup,
        name: 'question11',
        label: formatMessage(messages.questions.question11),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer11, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer11, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer11, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 1,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question12: {
        fieldType: FieldType.RadioGroup,
        name: 'question12',
        label: formatMessage(messages.questions.question12),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer12, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer12, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer12, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 2,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question13: {
        fieldType: FieldType.RadioGroup,
        name: 'question13',
        label: formatMessage(messages.questions.question13),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer13, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer13, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer13, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 2,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question14: {
        fieldType: FieldType.RadioGroup,
        name: 'question14',
        label: formatMessage(messages.questions.question14),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer14, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer14, {
                    value: 1
                }),
                value: 1
            },
            {
                label: formatMessage(messages.answers.answer14, {
                    value: 2
                }),
                value: 2
            }
        ],
        correctValue: 0,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question15: {
        fieldType: FieldType.RadioGroup,
        name: 'question15',
        label: formatMessage(messages.questions.question15),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer15, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer15, {
                    value: 1
                }),
                value: 1
            }
        ],
        correctValue: 0,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question16: {
        fieldType: FieldType.RadioGroup,
        name: 'question16',
        label: formatMessage(messages.questions.question16),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer16, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer16, {
                    value: 1
                }),
                value: 1
            }
        ],
        correctValue: 0,
        isTouched: false,
        required: true,
        indicateRequired: false
    },
    question17: {
        fieldType: FieldType.RadioGroup,
        name: 'question17',
        label: formatMessage(messages.questions.question17),
        value: null,
        options: [
            {
                label: formatMessage(messages.answers.answer17, {
                    value: 0
                }),
                value: 0
            },
            {
                label: formatMessage(messages.answers.answer17, {
                    value: 1
                }),
                value: 1
            }
        ],
        correctValue: 0,
        isTouched: false,
        required: true,
        indicateRequired: false
    }
};
