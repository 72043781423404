import { defineMessages } from 'react-intl';

const general = defineMessages({
    'blood-pressure.systolic': {
        id: 'limits.recommendations.blood-pressure.systolic',
        defaultMessage: 'Recommended systolic interval: {min} - {max} mmHg'
    },
    'blood-pressure.diastolic': {
        id: 'limits.recommendations.blood-pressure.diastolic',
        defaultMessage: 'Recommended diastolic interval: {min} - {max} mmHg'
    },
    'physact.duration': {
        id: 'limits.recommendations.physact.duration',
        defaultMessage: 'Recommended duration interval: {min} - {max} min'
    },
    'physact.intensity': {
        id: 'limits.recommendations.physact.intensity',
        defaultMessage: 'Recommended intensity interval: {min} - {max}'
    },
    'pain-ci.rest': {
        id: 'limits.recommendations.pain-ci.rest',
        defaultMessage: 'Recommended interval at rest: {min} - {max}'
    },
    'pain-ci.active': {
        id: 'limits.recommendations.pain-ci.active',
        defaultMessage: 'Recommended interval during activity: {min} - {max}'
    },
    general: {
        id: 'limits.recommendations.general',
        defaultMessage: 'Recommended interval: {min} - {max} {unit}'
    },
    outsideLimitWarning: {
        id: 'limits.recommendations.outside-limit-warning',
        defaultMessage: 'Recommended interval: {min} - {max}'
    },
    missing: {
        id: 'limits.recommendations.missing',
        defaultMessage: 'Recommended interval is missing'
    },
    activateAlert: {
        id: 'limits.alert.activate',
        defaultMessage:
            'To enable {indicator} you need to approve the limits again.'
    }
});

export const limits = {
    general,
    rootId: 'limits.recommendations.'
};
