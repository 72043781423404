import { IndicatorCode } from '../../../../../types/indicator/indicator.type';
import {
    IndicatorConfigurationState,
    IndicatorWithSettings
} from '../../../indicators/indicator.type';
import { isChartRules } from '../../../settings/settings.type';
import { setYMinMaxPlotbands } from './plotbands/set-y-min-max-plotbands';
import { getDefaultMinMaxFromConfiguration } from './y-axis/get-default-min-max-from-configuration';
import { getYAxisMinMaxForPlotbandExcluded } from './y-axis/get-x-axis-min-max-for-plotband-excluded';

export function getAxis(params: {
    code: IndicatorCode;
    chartIndicators: Record<IndicatorCode, IndicatorWithSettings>;
    indicatorConfiguration: IndicatorConfigurationState;
}) {
    const code = params.code;
    const chartIndicators = params.chartIndicators;
    const indicator = chartIndicators[code];
    const indicatorConfiguration = params.indicatorConfiguration;

    const defaultMax = getDefaultMinMaxFromConfiguration({
        indicatorConfiguration: indicatorConfiguration,
        minMaxFunction: Math.max,
        defaultValue: 1
    });

    const defaultMin = getDefaultMinMaxFromConfiguration({
        indicatorConfiguration: indicatorConfiguration,
        minMaxFunction: Math.min,
        defaultValue: 0
    });

    if (
        isChartRules(indicator.settings.rules) &&
        indicator.settings.rules.shouldNotRenderPlotbands
    ) {
        const chartOptions = indicator.settings.rules.chartOptions;

        const minMax = getYAxisMinMaxForPlotbandExcluded({
            fixedYAxisMin: chartOptions?.fixedYAxisMin,
            fixedYAxisMax: chartOptions?.fixedYAxisMax,
            defaultMin,
            defaultMax
        });

        return { ...minMax, plotbands: [] };
    }

    const yAxisPlot = setYMinMaxPlotbands({
        indicatorConfiguration: indicatorConfiguration,
        settingsMapping: chartIndicators,
        code
    });

    return {
        plotbands: yAxisPlot.plotBands,
        min: yAxisPlot.min || defaultMin,
        max: yAxisPlot.max || defaultMax
    };
}
