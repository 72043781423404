import { fetchSettings } from '../../redux/domains/settings/settings.actions';
import { useReduxDispatch } from '../use-redux-dispatch';
import { useEffect } from 'react';

export function useFetchSettings(): void {
    const dispatch = useReduxDispatch();

    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
}
