import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Candy',
        id: 'indicator.candy.title'
    },
    legendNo: {
        defaultMessage: 'Have not eaten candy',
        id: 'indicator.candy.legend.no'
    },
    legendYes: {
        defaultMessage: 'Have eaten candy',
        id: 'indicator.candy.legend.yes'
    },
    question: {
        defaultMessage: 'Have you eaten candy?',
        id: 'indicator.candy.question'
    }
});

const choices = defineMessages({
    false: {
        defaultMessage: 'Have not eaten candy',
        id: 'indicator.candy.choices.false'
    },
    no: {
        defaultMessage: 'No',
        id: 'indicator.candy.choices.no'
    },
    true: {
        defaultMessage: 'Have eaten candy',
        id: 'indicator.candy.choices.true'
    },
    yes: {
        defaultMessage: 'Yes',
        id: 'indicator.candy.choices.yes'
    }
});

export const messagesCandy = {
    general,
    choices
};
