import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import {
    CLEAR_LATEST_VALUES,
    FETCH_LATEST_VALUES,
    FETCH_VALUES,
    FINISHED_FETCHING_LATEST_VALUES,
    SET_DATE_FILTER,
    START_FETCHING_LATEST_VALUES
} from './values.constants';
import { getValues } from './values.api';
import { Filters, ValuesParams } from './values.type';

export const fetchLatestReportsForIndicatorPopover =
    (params: {
        subjectId: string;
        indicatorId: string;
        limit: number;
        from: number;
        to: number;
    }) =>
    (dispatch: any) => {
        dispatch({ type: START_FETCHING_LATEST_VALUES });
        return Promise.all([
            dispatch(
                fetchLatestValues({
                    subjectId: params.subjectId,
                    indicatorId: params.indicatorId,
                    from: params.from,
                    to: params.to,
                    limit: params.limit
                })
            ),
            dispatch(
                fetchValues({
                    subjectId: params.subjectId,
                    indicatorIds: params.indicatorId,
                    from: params.from,
                    to: params.to
                })
            )
        ]).then((res) => {
            dispatch({ type: FINISHED_FETCHING_LATEST_VALUES });
            return res;
        });
    };

export const fetchLatestValues = createAsyncThunk(
    FETCH_LATEST_VALUES,
    (params: {
        subjectId: string;
        indicatorId: string;
        to: number;
        from: number;
        limit: number;
    }) =>
        getValues({
            subjectId: params.subjectId,
            indicatorIds: params.indicatorId,
            from: params.from,
            to: params.to,
            limit: params.limit
        })
);

export const fetchValuesForPastSixMonths = (subjectId: string) =>
    fetchValues({
        subjectId,
        from: moment().subtract(6, 'months').valueOf()
    });

export const fetchValues = createAsyncThunk(
    FETCH_VALUES,
    (params: ValuesParams) => getValues(params)
);

export const clearLatestReports = () => {
    return {
        type: CLEAR_LATEST_VALUES
    };
};

export const setDateFilter = createAction<Filters>(SET_DATE_FILTER);
