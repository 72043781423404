import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ContentCardLayout } from '../../../components/content/ContentLayout';
import { CrossSpinner } from '../../../components/ui/loading/loading';
import { usePageTitle } from '../../../hooks/use-page-title';
import { getPatient } from '../../../redux/domains/active-patient/active-patient.api';
import { titleEditPatient } from './title';

import { NormalizedPatientType } from '../../../redux/domains/active-patient/active-patient.utils';
import { EditPatientMeta } from './components/meta-edit/EditPatientMeta';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

export const EditPatientPage = (): JSX.Element => {
    usePageTitle(titleEditPatient);

    const routeParams = useParams<{ id: string }>();
    const patientId = routeParams.id;
    const routeHistory = useHistory();

    const [currentPatient, setCurrentPatient] =
        useState<NormalizedPatientType>();

    useEffect(() => {
        void getPatient(patientId).then((_patient) => {
            setCurrentPatient(_patient);
        });
    }, [patientId]);

    const handleBack = () => {
        routeHistory.goBack();
    };

    return !currentPatient ? (
        <ContentCardLayout>
            <CrossSpinner
                text={formatMessage(Strings.events.general.fetchingInformation)}
            />
        </ContentCardLayout>
    ) : (
        <ContentCardLayout>
            <EditPatientMeta
                patientId={patientId}
                patient={currentPatient}
                handleBack={handleBack}
            />
        </ContentCardLayout>
    );
};
