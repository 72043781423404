import { colors } from '../../../../theme/colors/commonColors';
import { ChartType } from '../../../../types/indicator/chart.type';
import { IndicatorChartGraphRules } from '../../../../types/indicator/indicator-rules.type';
import { CATProperties } from './cat-properties';

export const rules: IndicatorChartGraphRules<CATProperties> = {
    step: 1,
    chartTypes: [ChartType.Spline],
    lineColor: {
        name: colors.chart.graph.green.name,
        default: colors.chart.graph.green.primaryDark,
        sum: colors.chart.graph.green.primaryDark
    },
    chartOptions: {
        fixedYAxisMin: 0,
        fixedYAxisMax: 40,
        tickInterval: 10
    },
    limits: {
        report_min_enabled: false,
        report_max_enabled: false
    }
};
