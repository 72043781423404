import React, { Component, Fragment } from 'react';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import styled from 'styled-components';
import { Avatar, Divider, Tooltip } from 'antd';
import { FormattedDate } from 'react-intl';

import { MessageListItem } from './MessageListItem';
import { profiles } from '../../../../../../theme/profile/profiles';
import { getInitials } from '../../../../../../utils/create-condition-settings/create-settings';
import {
    Message,
    ConversationStatus
} from '../../../../../../redux/domains/chat/chat.types';
import { Strings } from '../../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../../locale/format/format-message';

type Props = {
    patientId: string;
    proxyId: string;
    messages: Message[];
    status: ConversationStatus[];
};
type State = any;

export class MessageList extends Component<Props, State> {
    private readonly el: any;

    constructor(props: Props) {
        super(props);
        this.el = React.createRef();
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.messages.length !== this.props.messages.length) {
            this.scrollToBottom();
        }
    }

    render() {
        return (
            <MessageListContainer>
                {isEmpty(this.props.messages) ? (
                    <EmptyMessage />
                ) : (
                    <>
                        {this.props.messages.map(this.renderMessage)}
                        {this.renderStatusRow()}
                    </>
                )}
                <div ref={this.el} />
            </MessageListContainer>
        );
    }

    renderMessage = (message: Message, idx: number, messages: Message[]) => {
        const { patientId, proxyId } = this.props;
        const { id, sender: senderId } = message;

        const isOutgoing = senderId !== patientId;
        const backgroundColor = isOutgoing
            ? senderId === proxyId
                ? profiles.main.profile.interactive.usability_neutral_0
                : profiles.main.profile.layout.background
            : profiles.main.profile.layout.primary;

        const textColor =
            senderId !== patientId && senderId !== proxyId
                ? profiles.main.profile.dark
                : profiles.main.profile.bright;

        const renderDivider = shouldRenderDateDivider(message, idx, messages);

        return (
            <Fragment key={id}>
                {renderDivider && <DateDivider message={message} />}
                <MessageRow isOutgoing={isOutgoing}>
                    <MessageListItem
                        message={message}
                        isOutgoing={isOutgoing}
                        backgroundColor={backgroundColor}
                        textColor={textColor}
                    />
                </MessageRow>
            </Fragment>
        );
    };

    renderStatusRow = () => {
        const { proxyId, messages } = this.props;
        let { status } = this.props;
        const lastMessage = messages[messages.length - 1];

        status = status.filter(
            (item: any) =>
                item.participantId !== proxyId &&
                item.participantId !== lastMessage.sender
        );

        return <StatusRow>{status.map(this.renderStatusItem)}</StatusRow>;
    };

    renderStatusItem = (participant: ConversationStatus) => {
        const { meta, dtCreated, participantId } = participant;

        const name = `${meta.name} ${meta.family_name}`;
        const formattedDate = moment(dtCreated).format('LLL');

        return (
            <Tooltip
                key={participantId}
                title={formatMessage(Strings.chat.general.viewedBy, {
                    name,
                    formattedDate
                })}
            >
                <Avatar style={{ margin: '0 2px' }} size="small">
                    {getInitials(name)}
                </Avatar>
            </Tooltip>
        );
    };

    scrollToBottom = () => {
        this.el.current.scrollIntoView({ behaviour: 'smooth' });
    };
}

export function shouldRenderDateDivider(
    message: Message,
    idx: number,
    messages: Message[]
) {
    if (idx === 0) return true;

    const { dtCreated } = message;
    const { dtCreated: previousDtCreated } = messages[idx - 1];

    return !moment(dtCreated).isSame(previousDtCreated, 'day');
}

export function DateDivider(props: { message: Message }) {
    return (
        <Divider
            style={{
                fontWeight: 400,
                fontSize: 12,
                color: '#595368'
            }}
        >
            <FormattedDate
                value={props.message.dtCreated}
                year="numeric"
                month="long"
                day="numeric"
            />
        </Divider>
    );
}

const MessageListContainer = styled.div`
    flex-grow: 1;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: auto;
`;

const MessageRow = styled.div<{ isOutgoing: boolean }>`
    margin-bottom: 40px;
    display: flex;
    flex-direction: ${(props) => (props.isOutgoing ? 'row-reverse' : 'row')};
`;

const StatusRow = styled.div`
    text-align: right;
    font-size: 12px;
    font-style: italic;
`;

const EmptyMessage = () => (
    <div
        style={{
            textAlign: 'center',
            color: profiles.main.profile.layout.subtitles_alter,
            marginTop: 50
        }}
    >
        <p>{formatMessage(Strings.chat.general.newConversation)}</p>
    </div>
);
