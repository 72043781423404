import styled from 'styled-components';
import { List } from 'antd';

export const StyledMetaItem = styled(List.Item.Meta)`
    &&& {
        margin: 0px;
    }

    && .ant-list-item-meta-title {
        padding: 0px;
        margin: 0px;
    }
`;
