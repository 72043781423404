import { defineMessages } from 'react-intl';

const general = defineMessages({
    noData: {
        id: 'medic.other.patientDetails.noData',
        defaultMessage: 'No data..'
    },
    activationSuccess: {
        id: 'medic.other.activationSuccess',
        defaultMessage: 'Successfully activated patient!'
    },
    deactivationSuccess: {
        id: 'medic.other.deactivationSuccess',
        defaultMessage: 'Successfully deactivated patient!'
    }
});

export const other = {
    general
};
