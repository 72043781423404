import React from 'react';
import { Switch } from 'antd';
import {
    MedicGroupId,
    MedicUser,
    MedicUsers
} from '../../../../redux/domains/groups/groups.type';
import { labels } from '../../../../locale/messagesDescriptors/labels';
import { ColumnProps } from 'antd/lib/table';
import { alphabetic, OrderBy } from '../../../../utils/sort/alphabetic';
import {
    MedicExternalId,
    UserStatus
} from '../../../../redux/domains/user/user.type';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';

export const firstName = {
    title: formatMessage(labels.label.firstName),
    dataIndex: 'meta.name',
    sorter: (a: MedicUser, b: MedicUser): OrderBy =>
        alphabetic(a.meta.name, b.meta.name)
};

export const familyName = {
    title: formatMessage(labels.label.familyName),
    dataIndex: 'meta.family_name',
    defaultSortOrder: 'ascend',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a: MedicUser, b: MedicUser): OrderBy =>
        alphabetic(a.meta.family_name, b.meta.family_name)
};

export const medicTitle = {
    title: formatMessage(labels.label.title),
    dataIndex: 'meta.medicTitle',
    sorter: (a: MedicUser, b: MedicUser): OrderBy =>
        alphabetic(a.meta.medicTitle, b.meta.medicTitle)
};

function getRoleNames(
    medicGroupId: MedicGroupId,
    medicGroups: string[] = [],
    adminGroups: string[] = []
) {
    const roles = [];

    if (medicGroups.includes(medicGroupId)) {
        roles.push(formatMessage(Strings.roles.role.medic));
    }
    if (adminGroups.includes(medicGroupId)) {
        roles.push(formatMessage(Strings.roles.role.admin));
    }

    return roles.join(', ');
}

export const getRoles = (
    medicGroupId: MedicGroupId
): ColumnProps<MedicUser> => {
    return {
        title: formatMessage(labels.label.role),
        dataIndex: 'roles',
        render: (text: string[], record: MedicUser) =>
            getRoleNames(medicGroupId, record.medicGroups, record.adminGroups),
        sorter: (a: MedicUser, b: MedicUser) =>
            alphabetic(
                getRoleNames(medicGroupId, a.medicGroups, a.adminGroups),
                getRoleNames(medicGroupId, b.medicGroups, b.adminGroups)
            )
    };
};

export const status = {
    title: formatMessage(labels.label.status),
    dataIndex: 'status',
    render: (status: string): string =>
        formatMessage(Strings.common.general.statusValue, {
            status
        }),
    sorter: (a: MedicUser, b: MedicUser): OrderBy =>
        alphabetic(a.status, b.status)
};

export const getSwitchStatus = (
    medicUsers: MedicUsers,
    changeStatus: (medicId: MedicExternalId, checked: boolean) => void
): ColumnProps<MedicUser> => {
    return {
        render: (text: string, record: MedicUser): JSX.Element => (
            <Switch
                checked={
                    medicUsers[record.externalId].status === UserStatus.Active
                }
                loading={medicUsers[record.externalId]?.loading || false}
                onChange={(checked: boolean, event: MouseEvent) => {
                    event.stopPropagation();
                    changeStatus(record.externalId, checked);
                }}
            />
        )
    };
};

export const email = {
    title: formatMessage(labels.label.email),
    dataIndex: 'meta.email',
    sorter: (a: MedicUser, b: MedicUser): OrderBy =>
        alphabetic(a.meta.email, b.meta.email)
};

export const phoneNumber = {
    title: formatMessage(labels.label.phoneNumber),
    dataIndex: 'meta.phone_number',
    sorter: (a: MedicUser, b: MedicUser): OrderBy =>
        alphabetic(a.meta.phone_number, b.meta.phone_number)
};
