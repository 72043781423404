import React from 'react';
import { Modal, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { MedicGroupsState } from '../../../../../redux/domains/groups/groups.type';
import styled from 'styled-components';
import { routeAbout } from '../../../../../pages/about/route';
import { routePatientOverview } from '../../../../../pages/patient/overview/route';
import { routePatientSearch } from '../../../../../pages/patient/search/route';
import { useReduxSelector } from '../../../../../hooks/use-redux-selector';
import {
    setSelectedMedicGroup,
    setSelectedPatientGroup
} from '../../../../../redux/domains/groups/groups.actions';
import {
    getMedicGroupsForCurrentUser,
    getSelectedMedicGroupId
} from '../../../../../redux/domains/groups/groups.selectors';
import { pathPatientAdministrationSwitch } from '../../../../../pages/patient/administration/path';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../locale/format/format-message';
import { sortMedicGroups } from '../../../../../utils/sort/sort-medic-groups';

const { Option } = Select;
const { confirm } = Modal;

const handleSelect = (
    dispatch: Dispatch<any>,
    medicGroups: MedicGroupsState,
    selectedGroup: string,
    routeHistory: any
) => {
    const patientGroupId = medicGroups.byId[selectedGroup].manages[0];

    const excludeFromRedirect = [
        routePatientOverview.link,
        pathPatientAdministrationSwitch,
        routeAbout.link,
        routePatientSearch.link
    ];

    if (excludeFromRedirect.includes(routeHistory.location.pathname)) {
        dispatch(setSelectedMedicGroup(selectedGroup));

        if (patientGroupId) {
            dispatch(setSelectedPatientGroup(patientGroupId));
        }
    } else {
        confirm({
            title: formatMessage(Strings.confirm.general.changeMedicGroupTitle),
            content: formatMessage(
                Strings.confirm.general.changeMedicGroupContent
            ),
            onOk: () => {
                dispatch(setSelectedMedicGroup(selectedGroup));

                if (patientGroupId) {
                    dispatch(setSelectedPatientGroup(patientGroupId));
                    routeHistory.push(routePatientOverview.link);
                } else {
                    routeHistory.push(pathPatientAdministrationSwitch);
                }
            }
        });
    }
};

export const ClinicGroupMenu = (): JSX.Element => {
    const dispatch = useDispatch();

    const routeHistory = useHistory();
    const medicGroups = useReduxSelector((state) => state.groups.medicGroups);
    const selectedMedicGroupId = useReduxSelector(getSelectedMedicGroupId);
    const medicGroupsForCurrentUser = useReduxSelector(
        getMedicGroupsForCurrentUser
    );

    return (
        <StyledHeaderMenu>
            <Select
                value={selectedMedicGroupId}
                style={{ width: 270 }}
                onSelect={(selectedGroup: string) => {
                    if (selectedMedicGroupId !== selectedGroup) {
                        handleSelect(
                            dispatch,
                            medicGroups,
                            selectedGroup,
                            routeHistory
                        );
                    }
                }}
            >
                {sortMedicGroups(medicGroups.byId)
                    .filter((groupId) =>
                        medicGroupsForCurrentUser.includes(groupId.id)
                    )
                    .map((group) => (
                        <Option key={group.id} value={group.id}>
                            {group.name}
                        </Option>
                    ))}
            </Select>
        </StyledHeaderMenu>
    );
};

const StyledHeaderMenu = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-right: 15px;
`;
