import {
    CHECK_AUTHENTICATION,
    CREATE_AUTH_REQUEST,
    CREATE_SINGLE_SIGN_ON_URL,
    LOGIN_USER,
    LOGOUT_USER,
    LOGOUT_USER_FULFILLED
} from './authentication.constants';
import {
    generateAuthRequest,
    generateSingleSignOnUrl,
    loginWithIdentityProvider,
    postLoginUser,
    postLogoutUser,
    eidGetSession,
    oAuthLogin
} from './authentication.api';
import {
    isTokenValid,
    validateTokens,
    AuthTokens
} from '../../../utils/token/token-service';
import { Dispatch } from 'redux';
import { authStorageKeys } from '../../../utils/storage/auth/auth-storage-keys';
import { EidAuthMethods } from './authentication.enums';

export const loginWithCredentials = (username: string, password: string) =>
    authenticateWithTokens(postLoginUser({ username, password }));

export const logoutUser = () => (dispatch: Dispatch<any>) => {
    const idToken = window.localStorage.getItem(authStorageKeys.idToken);

    window.sessionStorage.clear();

    if (!idToken || !isTokenValid(idToken)) {
        return dispatch({ type: LOGOUT_USER_FULFILLED });
    }
    return dispatch({
        type: LOGOUT_USER,
        payload: postLogoutUser(idToken).catch((error) => ({}))
    });
};

export const authenticateWithTokens = (
    tokens: AuthTokens | Promise<AuthTokens>
) => ({
    type: LOGIN_USER,
    payload: Promise.resolve(tokens)
});

export const checkAuthentication = (payload = validateTokens()) => ({
    type: CHECK_AUTHENTICATION,
    payload
});

// OAuth2
export const createSingleSignOnURL =
    (payload: any) => (dispatch: Dispatch<any>) =>
        dispatch({
            type: CREATE_SINGLE_SIGN_ON_URL,
            payload: generateSingleSignOnUrl(payload)
        });

export const loginUserWithIdentityProvider =
    (payload: any) => (dispatch: Dispatch<any>) =>
        dispatch({
            type: LOGIN_USER,
            payload: loginWithIdentityProvider(payload)
        });

// SAML
export const createAuthRequest = () => (dispatch: Dispatch<any>) =>
    dispatch({
        type: CREATE_AUTH_REQUEST,
        payload: generateAuthRequest()
    });

export const authenticateWithEId = (
    sessionId: string,
    authMethod: EidAuthMethods
) => authenticateWithTokens(eidGetSession(sessionId, authMethod));

export const authenticateWithSso = (sessionId: string) =>
    authenticateWithTokens(oAuthLogin(sessionId));
