import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Extra medication',
        id: 'indicator.prnmed-af.title'
    },
    values: {
        defaultMessage:
            '{dose, plural, one {{dose} dose} other {{dose} doses}} {medication}',
        id: 'indicator.prnmed-af.values'
    },
    legendNo: {
        defaultMessage: 'No doses',
        id: 'indicator.prnmed-af.legend.no'
    },
    legendYes: {
        defaultMessage: 'One or more doses',
        id: 'indicator.prnmed-af.legend.yes'
    }
});

const dose = defineMessages({
    title: {
        defaultMessage: 'Dose',
        id: 'indicator.prnmed-af.dose.title'
    },
    description: {
        defaultMessage: 'Number of doses exceeding prescription',
        id: 'indicator.prnmed-af.dose.description'
    },
    unit: {
        id: 'indicator.prnmed-af.dose.unit',
        defaultMessage: '{value, plural, one {dose} other {doses}}'
    }
});

const medication = defineMessages({
    title: {
        defaultMessage: 'Medication',
        id: 'indicator.prnmed-af.medication.title'
    },
    description: {
        defaultMessage: 'Type of medicine',
        id: 'indicator.prnmed-af.medication.description'
    },
    betablocker: {
        defaultMessage: 'betablocker',
        id: 'indicator.prnmed-af.medication.choices.betablocker'
    }
});

const choices = defineMessages({
    betablocker: {
        defaultMessage: 'Betablocker',
        id: 'indicator.prnmed-af.choices.betablocker'
    }
});

export const messagesPrnmedAf = {
    general,
    dose,
    medication,
    choices
};
