import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import moment from 'moment';
import { Message } from '../../../../../../redux/domains/chat/chat.types';
import { formatMessage } from '../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../locale/messagesDescriptors';

type Props = {
    message: Message;
    isOutgoing: boolean;
    backgroundColor: string;
    textColor: string;
};

export const MessageListItem = ({
    message,
    isOutgoing,
    backgroundColor,
    textColor
}: Props) => {
    const {
        senderMeta: { name, family_name },
        content,
        dtCreated
    } = message;
    const metaInfo = `${name} ${family_name} - ${moment(dtCreated).format(
        'HH:mm'
    )}`;

    const theme = {
        backgroundColor,
        textColor,
        textAlign: isOutgoing ? 'right' : 'left',
        padding: isOutgoing ? '0 10px 0 0' : '0 0 0 10px',
        justify: isOutgoing ? 'flex-end' : 'flex-start'
    };

    return (
        <ThemeProvider theme={theme}>
            <MessageItemWrapper>
                <Meta>{metaInfo}</Meta>
                <MessageBubbleWrapper>
                    {content.startsWith('#video/') ? (
                        <p>
                            {formatMessage(
                                Strings.video.actionPanel.messages.text
                            )}
                        </p>
                    ) : (
                        <MessageBubble>{content}</MessageBubble>
                    )}
                </MessageBubbleWrapper>
            </MessageItemWrapper>
        </ThemeProvider>
    );
};

const MessageItemWrapper = styled.div`
    max-width: 50%;
    display: flex;
    justify-content: ${(props) => props.theme.justify};
    flex-direction: column;
`;

const MessageBubbleWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => props.theme.justify};
`;

const MessageBubble = styled.div`
    display: inline-block;
    color: ${(props) => props.theme.profile.bright};
    border-radius: 15px;
    padding: 5px 15px;
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.textColor};
    overflow: hidden;
`;

const Meta = styled.div`
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 5px;
    color: ${(props) => props.theme.profile.layout.subtitles_alter};
    text-align: ${(props) => props.theme.textAlign};
`;
