export const FETCH_PATIENT = 'FETCH_PATIENT';
export const FETCH_PATIENT_PENDING = 'FETCH_PATIENT_PENDING';
export const FETCH_PATIENT_FULFILLED = 'FETCH_PATIENT_FULFILLED';
export const FETCH_PATIENT_REJECTED = 'FETCH_PATIENT_REJECTED';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const UPDATE_PATIENT_PENDING = 'UPDATE_PATIENT_PENDING';
export const UPDATE_PATIENT_FULFILLED = 'UPDATE_PATIENT_FULFILLED';
export const UPDATE_PATIENT_REJECTED = 'UPDATE_PATIENT_REJECTED';
export const UPDATE_INDICATORCONFIG = 'UPDATE_INDICATORCONFIG';
export const UPDATE_INDICATORCONFIG_PENDING = 'UPDATE_INDICATORCONFIG_PENDING';
export const UPDATE_INDICATORCONFIG_FULFILLED =
    'UPDATE_INDICATORCONFIG_FULFILLED';
export const UPDATE_INDICATORCONFIG_REJECTED =
    'UPDATE_INDICATORCONFIG_REJECTED';
export const CREATE_PATIENT = 'CREATE_PATIENT';
export const CREATE_PATIENT_PENDING = 'CREATE_PATIENT_PENDING';
export const CREATE_PATIENT_FULFILLED = 'CREATE_PATIENT_FULFILLED';
export const CREATE_PATIENT_REJECTED = 'CREATE_PATIENT_REJECTED';
export const SAVE_REGISTER_FORM_EXTERNAL_ID = 'SAVE_REGISTER_FORM_EXTERNAL_ID';
export const SAVE_REGISTER_FORM_EXTERNAL_ID_AND_REASON =
    'SAVE_REGISTER_FORM_EXTERNAL_ID_AND_REASON';
export const MOVE_PATIENT_TO_GROUP = 'MOVE_PATIENT_TO_GROUP';
