import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { IndicatorMeta } from '../../../../../../../../conditions/indicator-meta';
import { ActivePatient } from '../../../../../../../../redux/domains/active-patient/active-patient.type';
import {
    IndicatorConfigurationState,
    IndicatorWithSettings
} from '../../../../../../../../redux/domains/indicators/indicator.type';
import RadioFormGroupWrapper from '../../forms/form-sections/indicator-configuration/radio/RadioFormGroupWrapper';
import SliderFormGroupWrapper from '../../forms/form-sections/indicator-configuration/slider/SliderFormGroupWrapper';
import ReminderInputGroup from '../../forms/form-sections/reminders/ReminderInputGroup';
import UpdateConfigurationForm from '../../forms/UpdateConfigurationForm';
import UpdateRemindersForm from '../../forms/UpdateRemindersForm';
import TitleWithIcon from '../title-with-icon/TitleWithIcon';
import EditIndicatorDrawer from './EditIndicatorDrawer';
import { formatMessage } from '../../../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../../../locale/messagesDescriptors';

type Name = 'edit-configuration' | 'edit-reminders' | string;

type FormFieldTouched = Record<Name, boolean>;

const formTouchedState: FormFieldTouched = {
    'edit-configuration': false,
    'edit-reminders': false
};

type Props = {
    patient: ActivePatient;
    indicator: IndicatorWithSettings;
    indicatorConfiguration: IndicatorConfigurationState;
    reportReminder: any;
    indicatorSettings: any;
    conditionId: string;
    isReadonly: boolean;
};

export const EditIndicator = ({
    patient,
    indicator,
    indicatorConfiguration,
    reportReminder,
    indicatorSettings,
    conditionId,
    isReadonly
}: Props) => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [formTouched, setFormTouched] = React.useState(formTouchedState);

    const handleDirtyChanged = (name: Name, dirty: boolean) => {
        if (formTouched[name] !== dirty) {
            setFormTouched((state: any) => ({
                ...state,
                [name]: dirty
            }));
        }
    };

    const handleClose = () => {
        const formsAreDirty = Object.values(formTouched).some((form) => form);

        if (!isReadonly && formsAreDirty) {
            Modal.confirm({
                title: formatMessage(Strings.confirm.general.unsavedChanges),
                okText: formatMessage(Strings.common.general.yes),
                cancelText: formatMessage(Strings.common.general.no),
                onOk: () => {
                    setDrawerVisible(false);
                }
            });
        } else {
            setDrawerVisible(false);
        }
    };

    const renderConfigurationForm = () => {
        const { transformedCode, settings } = indicator;
        const { boundariesConfiguration } = settings?.rules;

        return (
            indicatorSettings.allowEditLimits && (
                <DrawerSection
                    header={formatMessage(
                        Strings.indicatorText.general.limitsTitle
                    )}
                    panelKey="limits"
                >
                    <UpdateConfigurationForm
                        configuration={indicatorConfiguration}
                        onDirtyChange={handleDirtyChanged}
                        name="edit-configuration"
                        patient={patient}
                        conditionId={conditionId}
                    >
                        {boundariesConfiguration?.type === 'radio' ? (
                            <RadioFormGroupWrapper
                                indicatorMeta={IndicatorMeta[transformedCode]}
                                rules={settings?.rules}
                            />
                        ) : (
                            <SliderFormGroupWrapper
                                indicatorMeta={IndicatorMeta[transformedCode]}
                                rules={settings?.rules}
                            />
                        )}
                        <FormButtons isReadonly={isReadonly} />
                    </UpdateConfigurationForm>
                </DrawerSection>
            )
        );
    };

    const renderReminderConfigurationForm = () => {
        return (
            indicatorSettings.allowEditReminders &&
            reportReminder && (
                <DrawerSection
                    header={formatMessage(Strings.reminders.general.title)}
                    panelKey="reminders"
                >
                    <UpdateRemindersForm
                        name="edit-reminders"
                        externalId={patient.externalId}
                        onDirtyChange={handleDirtyChanged}
                        reportReminder={reportReminder}
                        indicatorId={indicator.id}
                    >
                        <ReminderInputGroup isReadonly={isReadonly} />
                    </UpdateRemindersForm>
                </DrawerSection>
            )
        );
    };

    const isDisabled = () => {
        const { allowEditLimits, allowEditReminders } = indicatorSettings;
        const {
            configuration: { in_use },
            loading = false
        } = indicatorConfiguration;

        return loading || !in_use || (!allowEditLimits && !allowEditReminders);
    };

    return (
        <>
            <Button
                type="primary"
                size="small"
                onClick={() => setDrawerVisible(true)}
                disabled={isDisabled()}
            >
                {isReadonly
                    ? formatMessage(Strings.ui.buttons.general.viewMore)
                    : formatMessage(Strings.ui.buttons.general.edit)}
            </Button>
            <EditIndicatorDrawer
                isReadonly={isReadonly}
                indicatorTitle={indicator.title}
                title={<TitleWithIcon indicatorCode={indicator.code} />}
                visible={drawerVisible}
                onClose={handleClose}
            >
                {renderConfigurationForm()}
                {renderReminderConfigurationForm()}
            </EditIndicatorDrawer>
        </>
    );
};

export default EditIndicator;

const DrawerSection = ({ children }: any) => {
    return <>{children || null}</>;
};

const FormButtons = (props: any) => {
    const { isReadonly, dirty, handleReset, isSubmitting } = props;

    if (isReadonly) {
        return <></>;
    }

    return (
        <div
            style={{
                marginTop: 20,
                textAlign: 'right',
                borderTop: '1px solid #ccc',
                paddingTop: 10
            }}
        >
            <Button
                onClick={handleReset}
                disabled={!dirty}
                style={{ float: 'left' }}
            >
                {formatMessage(Strings.common.general.reset)}
            </Button>
            <Button
                type="primary"
                htmlType="submit"
                disabled={!dirty}
                loading={isSubmitting}
                style={{ marginLeft: 10 }}
            >
                {formatMessage(Strings.common.general.save)}
            </Button>
        </div>
    );
};
