import { createReducer } from '@reduxjs/toolkit';
import { updateHasError } from './error-boundary.actions';
import { ErrorBoudaryType } from './error-boundary.type';

const initialState: ErrorBoudaryType = {
    hasError: false
};

export const errorBoundaryReducer = createReducer(initialState, (builder) => {
    builder.addCase(updateHasError.fulfilled, (state, { payload }) => {
        state.hasError = payload.hasError;
    });
});
