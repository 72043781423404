import { ConditionId } from '../conditions/conditions.type';
import { MedicMeta } from '../medic/medic.type';
import { BaseUser, MedicExternalId, UserStatus } from '../user/user.type';

export enum GroupTag {
    Medic = 'medic',
    MedicAdmin = 'medicadmin',
    Patient = 'patient',
    RegionSkane = 'regionskane',
    Cross = 'cross'
}

export type GroupId = string;
export type MedicGroupId = GroupId;
export type PatientGroupId = GroupId;

type BaseGroup = {
    id: GroupId;
    name: string;
    tags: GroupTag[];
    managedBy: string[];
    manages: string[];
    users: string[];
};

export type PatientGroup = BaseGroup & {
    id: PatientGroupId;
    condition: ConditionId;
};

export type PatientGroupsById = Record<PatientGroupId, PatientGroup>;

export type PatientGroups = {
    allIds: PatientGroupId[];
    byId: PatientGroupsById;
};

export type MedicGroup = BaseGroup & {
    id: MedicGroupId;
};

export type MedicGroupsById = Record<MedicGroupId, MedicGroup>;

export type MedicUser = BaseUser & {
    status: UserStatus;
    meta: MedicMeta;
    groups: MedicGroupId[];
    medicGroups?: MedicGroupId[];
    adminGroups?: MedicGroupId[];
    loading?: boolean;
};

export type MedicUsers = Record<MedicExternalId, MedicUser>;

export type MedicGroupsState = {
    allIds: MedicGroupId[];
    byId: MedicGroupsById;
};

export type GroupsState = {
    loaded: boolean;
    medicGroups: MedicGroupsState;
    patientGroups: PatientGroups;
    medicUsers: MedicUsers;
    selectedMedicGroupId: MedicGroupId;
    selectedPatientGroupId: PatientGroupId;
};
