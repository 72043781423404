import { defineMessages } from 'react-intl';

const general = defineMessages({
    social: {
        id: 'medic.meta.social',
        defaultMessage: 'Personal identification number'
    },
    name: {
        id: 'medic.meta.name',
        defaultMessage: 'Name'
    },
    memberOf: {
        id: 'medic.meta.memberOf',
        defaultMessage: 'Member of'
    }
});

export const meta = {
    general
};
