import { RouteItemType } from '../../../utils/route/switch-routes';
import { EditPatientGroupPage } from './EditPatientGroupPage';
import { pathEditPatientGroupId } from './path';
import { titleEditPatient } from './title';

export const routeEditPatientGroup: RouteItemType = {
    name: titleEditPatient,
    link: pathEditPatientGroupId,
    icon: '',
    component: EditPatientGroupPage
};
