import React, { PropsWithChildren } from 'react';
import { Icon } from 'antd';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';

const NoValues = (props: PropsWithChildren<unknown>): JSX.Element => (
    <div style={{ textAlign: 'center' }}>
        <Icon type="dislike-o" style={{ marginRight: 5 }} />
        {props.children}
    </div>
);

export const NoReportedValues = (): JSX.Element => {
    return (
        <NoValues>
            {formatMessage(Strings.values.general.noReportedValues)}
        </NoValues>
    );
};

export const NoReportedValuesThisMonth = (): JSX.Element => {
    return (
        <NoValues>
            {formatMessage(Strings.values.general.noReportedValuesThisMonth)}
        </NoValues>
    );
};
