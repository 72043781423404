import * as eventsActionTypes from '../events/events.constants';
import * as actionPanelActionTypes from './action-panel.constants';
import {
    ActionPanelActionType,
    ActionPanelLatest,
    ActionPanelStateType
} from './action-panel.type';

export const initialActionPanelState: ActionPanelStateType = {
    isOpen: false,
    patientExternalId: '',
    latest: { reset: null, watchover: null },
    tabs: {
        general: {
            fetchingEvents: false
        }
    }
};

export default function actionPanelReducer(
    state = initialActionPanelState,
    action: ActionPanelActionType
): ActionPanelStateType {
    switch (action.type) {
        // FETCH
        case eventsActionTypes.FETCH_EVENTS_PENDING:
            return toggleActionPanelFetchingEvents(state, true);
        case eventsActionTypes.FETCH_EVENTS_FULFILLED:
            return toggleActionPanelFetchingEvents(state, false);
        case eventsActionTypes.FETCH_EVENTS_REJECTED:
            return toggleActionPanelFetchingEvents(state, false);

        // OPEN
        case actionPanelActionTypes.OPEN_ACTION_PANEL:
            return setActionPanelOpenState(state, action.payload);
        case actionPanelActionTypes.CLOSE_ACTION_PANEL:
            return initialActionPanelState;
        case actionPanelActionTypes.SET_LATEST_ACTION_PANEL:
            return setActionPanelLatestReducer(state, action.payload);
        default:
            return state;
    }
}

function toggleActionPanelFetchingEvents(
    state: ActionPanelStateType,
    fetching: boolean
) {
    return {
        ...state,
        tabs: {
            general: {
                fetchingEvents: fetching
            }
        }
    };
}

export interface ActionPanelPayloadType {
    patientExternalId: string;
    defaultTab?: 'messages';
}

function setActionPanelOpenState(
    state: ActionPanelStateType,
    payload: ActionPanelPayloadType
): ActionPanelStateType {
    return {
        ...state,
        ...payload,
        isOpen: true
    };
}

function setActionPanelLatestReducer(
    state: ActionPanelStateType,
    payload: ActionPanelLatest
): ActionPanelStateType {
    return {
        ...state,
        latest: payload
    };
}
