import { ConditionIndicator } from '../condition.type';
import { bloodPressure } from '../shared-indicators/blood-pressure/blood-pressure';
import { bodyTemp } from '../shared-indicators/body-temp/body-temp';
import { waistCircumference } from '../shared-indicators/waist-circumference/waist-circumference';
import { vas } from '../shared-indicators/vas/vas';
import { swelling } from '../shared-indicators/swelling/swelling';
import { smoke } from '../shared-indicators/smoke/smoke';
import { formatMessage } from '../../locale/format/format-message';
import { spinalCordInjury01 } from './messages/messages';
import { bodyWeight } from '../shared-indicators/body-weight/body-weight';
import { oxygenSaturation } from './indicators/oxygen-saturation/oxygen-saturation';
import { urineLeakage } from './indicators/urine-leakage/urine-leakage';
import { respiratoryProblems } from './indicators/respiratory-problems/respiratory-problems';
import { sleepSCI } from './indicators/sleep-sci/sleep-sci';
import { bowelDysfunction } from './indicators/bowel-dysfunction/bowel-dysfunction';
import { liquidMeasurement } from './indicators/liquid-measurement/liquid-measurement';
import { pulse } from '../shared-indicators/pulse/pulse';
import { physact } from '../shared-indicators/physact/physact';

const code = 'LP_2_0_SPINAL_CORD_INJURY_01';
const publicCode = 'SPINAL_CORD_INJURY';

const indicators: ConditionIndicator[] = [
    bloodPressure,
    waistCircumference,
    bodyWeight,
    vas,
    swelling,
    smoke,
    oxygenSaturation,
    bodyTemp,
    urineLeakage,
    respiratoryProblems,
    sleepSCI,
    bowelDysfunction,
    liquidMeasurement,
    pulse,
    physact
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(spinalCordInjury01.name),
        nameLong: formatMessage(spinalCordInjury01.nameLong)
    }
};

export default condition;
