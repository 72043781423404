import { RouteItemType } from '../../../utils/route/switch-routes';
import { EducationPage } from './EducationPage';
import { pathEducation } from './path';
import { titleEducation } from './title';

export const routeEducation: RouteItemType = {
    name: titleEducation,
    link: pathEducation,
    icon: 'ordered-list',
    component: EducationPage
};
