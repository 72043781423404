import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        id: 'watchover.general.title',
        defaultMessage: 'Check'
    },
    notCheckedYet: {
        id: 'watchover.general.not-checked-yet',
        defaultMessage: 'Not checked yet'
    },
    registeredWatchover: {
        id: 'watchover.general.registered-watchover',
        defaultMessage: 'Successfully marked as checked!'
    }
});

export const watchover = { general };
