import React from 'react';
import { Switch as AntSwitch } from 'antd';

const Switch = ({
    setShowConfirmLimitsModal,
    disabled = false,
    shouldShowConfirmLimitsModal = true,
    ...formActions
}: any) => {
    const { setFieldValue, values, isSubmitting, handleSubmit } = formActions;

    const handleChange = async (checked: boolean) => {
        await setFieldValue('configuration.in_use', checked);

        checked && shouldShowConfirmLimitsModal
            ? setShowConfirmLimitsModal(true)
            : handleSubmit();
    };

    return (
        <AntSwitch
            checked={values.configuration.in_use}
            onChange={handleChange}
            loading={values.loading || isSubmitting}
            disabled={disabled}
        />
    );
};

export default Switch;
