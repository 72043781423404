import { RouteItemType } from '../../../../utils/route/switch-routes';
import { pathPatientAdministration } from './path';
import { PatientAdministrationListWrapperPage } from './PatientAdministrationListWrapperPage';
import { titlePatientAdministration } from './title';

export const routePatientAdministration: RouteItemType = {
    name: titlePatientAdministration,
    link: pathPatientAdministration,
    icon: 'user-add',
    component: PatientAdministrationListWrapperPage
};
