import React, { Fragment } from 'react';
import { DefaultTrendItem } from '../utils/DefaultTrendItem';

import { DataItem } from '../utils/DataItem';
import {
    getMatchingDayTrendItems,
    getYearMonthDayString
} from '../utils/utils';

import { DashedDivider } from '../utils/Divider';
import {
    PrnMedReport,
    PrnMedTrendData
} from '../../../../types/indicators/PrnMed';
import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import { Strings } from '../../../../locale/messagesDescriptors';
import {
    formatMessageById,
    formatMessage
} from '../../../../locale/format/format-message';

type PrnMedProps = {
    latestReport: PrnMedReport;
    indicator: IndicatorWithSettings;
    latestReports: PrnMedReport[];
};

const getTrendData = ({
    latestReport,
    indicatorId
}: {
    latestReport: PrnMedReport;
    indicatorId: string;
}): PrnMedTrendData[] => {
    const { value: report } = latestReport;

    return Object.keys(report).map((valueKey) => {
        const reportedValue = report[valueKey];

        const valueText = formatMessageById(
            `${indicatorId}.choices.${reportedValue}`
        );

        return {
            title: formatMessageById(`${indicatorId}.${valueKey}.title`),
            value: valueText ? valueText : reportedValue,
            unit: formatMessageById(`${indicatorId}.${valueKey}.unit`, {
                value: report.dose
            })
        };
    });
};

const getMedicationDoses = ({
    totalObj,
    data
}: {
    totalObj: { [key: string]: number };
    data: PrnMedTrendData;
}): number => {
    return totalObj[data.value.medication] == null
        ? (totalObj[data.value.medication] = Number(data.value.dose))
        : (totalObj[data.value.medication] += Number(data.value.dose));
};

const getDoseTrendTotalForDay = ({
    code,
    dayTrends
}: {
    code: string;
    dayTrends: PrnMedTrendData[];
}) => {
    if (dayTrends != null) {
        const medicationTotal: { [key: string]: number } = {};

        dayTrends.map((data: PrnMedTrendData) => {
            medicationTotal[data.value.medication] = getMedicationDoses({
                totalObj: medicationTotal,
                data: data
            });
        });

        if (Object.keys(medicationTotal).length) {
            return Object.keys(medicationTotal).map((medication: string) => {
                return {
                    title: null,
                    value: medicationTotal[medication],
                    unit: formatMessageById(
                        `${
                            Strings.indicators.rootId
                        }${code.toLowerCase()}.choices.${medication}`
                    )
                };
            });
        }
    }

    return null;
};

export const PrnMed = ({
    latestReport,
    indicator,
    latestReports
}: PrnMedProps): JSX.Element => {
    const indicatorId = `${
        Strings.indicators.rootId
    }${indicator.code.toLowerCase()}`;

    const doseTrendTotalForDay = getDoseTrendTotalForDay({
        code: indicator.code,
        dayTrends: getMatchingDayTrendItems({
            unitId: `${indicatorId}.unit`,
            matchingDate: getYearMonthDayString({ date: latestReport.date }),
            latestReports: latestReports
        })
    });

    return (
        <Fragment>
            <DefaultTrendItem
                latestReport={latestReport}
                formattedData={getTrendData({
                    latestReport: latestReport,
                    indicatorId: indicatorId
                })}
            />
            <DashedDivider />
            {doseTrendTotalForDay
                ? doseTrendTotalForDay.map((ii, index: number) => {
                      if (index === 0) {
                          return (
                              <DataItem
                                  key={ii.value}
                                  title={formatMessage(
                                      Strings.trend.totalForDay.title
                                  )}
                                  value={ii.value}
                                  unit={ii.unit}
                                  addMargin={true}
                              />
                          );
                      }
                      return (
                          <DataItem
                              key={ii.value}
                              value={ii.value}
                              unit={ii.unit}
                              addMargin={true}
                          />
                      );
                  })
                : null}
        </Fragment>
    );
};
