import { bloodSugar } from '../shared-indicators/blood-sugar/blood-sugar';
import { weightPd } from '../shared-indicators/weight-pd/weight-pd';
import { conditions } from '../messages';
import { ConditionIndicator } from '../condition.type';
import { formatMessage } from '../../locale/format/format-message';

const code = 'LP_2_0_FAKE_CONDITION_RS';
const publicCode = 'fc';

const indicators: ConditionIndicator[] = [bloodSugar, weightPd];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(conditions.rs.fc.name),
        nameLong: formatMessage(conditions.rs.fc.nameLong)
    }
};

export default condition;
