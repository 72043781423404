export const FETCH_VALUES = 'FETCH_VALUES';

export const FETCH_LATEST_VALUES = 'FETCH_LATEST_VALUES';

export const START_FETCHING_LATEST_VALUES = 'START_FETCHING_LATEST_VALUES';
export const FINISHED_FETCHING_LATEST_VALUES =
    'FINISHED_FETCHING_LATEST_VALUES';

export const CLEAR_LATEST_VALUES = 'CLEAR_LATEST_VALUES';

export const SET_DATE_FILTER = 'SET_DATE_FILTER';
