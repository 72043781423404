import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/pain-during-activity.svg';
import { messagesPainDuringActivity } from './messages/during-activity';
import { rules } from './pain-during-activity-hs-rules';

export const parameterPainDuringActivityHs = {
    code: 'PAIN-DURING-ACTIVITY-HS'
};

export const painDuringActivity: Indicator = {
    code: parameterPainDuringActivityHs.code,
    icon,
    messages: messagesPainDuringActivity,
    rules
};
