import React from 'react';
import { Tooltip } from 'antd';
import { IndicatorIconWithBorder } from '../../ui/icons/indicator-icon/IndicatorIcon';
import { AntdToolTipContentWrapper } from '../../ui/tooltip/AntdToolTipContentWrapper';
import { formatMessageById } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

type Props = {
    code: string;
};

const HeatmapAvatar = ({ code }: Props) => {
    return (
        <div className="heatmap-img-wrapper">
            <Tooltip
                placement="top"
                title={formatMessageById(
                    `${Strings.indicators.rootId}${code.toLowerCase()}.title`
                )}
            >
                <AntdToolTipContentWrapper>
                    <IndicatorIconWithBorder code={code} size={36} />
                </AntdToolTipContentWrapper>
            </Tooltip>
        </div>
    );
};

export default HeatmapAvatar;
