import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Blood sugar',
        id: 'indicator.blood-sugar.title'
    },
    value: {
        id: 'indicator.blood-sugar.value',
        defaultMessage: '{value, number, ::.0}'
    },
    unit: {
        id: 'indicator.blood-sugar.unit',
        defaultMessage: 'mmol/l'
    }
});

export const messagesBloodSugar = {
    general
};
