import indicatorRules from '../../utils/create-condition-settings/indicator-rules';
import { indicatorItem } from '../../utils/create-condition-settings/items';
import { reasonsStatusChange } from '../../utils/create-condition-settings/reasons-status-change';
import { ConditionRules } from '../condition.type';
import { IndicatorMeta } from '../indicator-meta';

export const createAoRsConfiguration = (): ConditionRules => {
    return {
        indicatorSettings: {
            preventFromEditLimits: [
                IndicatorMeta.MEDICATION_CI.code,
                IndicatorMeta.SMOKE.code,
                IndicatorMeta.PHYSACT.code,
                IndicatorMeta.VAS.code,
                IndicatorMeta.EQ5D3L.code
            ],
            preventFromEditReminders: [],
            preventFromToggle: [IndicatorMeta.VAS.code]
        },
        initialObservationIndicators: [IndicatorMeta.BODY_WEIGHT.code],
        priorityTable: {
            hidden: [
                IndicatorMeta.BODY_WEIGHT.code,
                IndicatorMeta.EQ5D3L.code,
                IndicatorMeta.VAS.code,
                IndicatorMeta.PHYSACT.code
            ],
            order: [
                IndicatorMeta.MEDICATION_CI.code,
                IndicatorMeta.PAIN_CI.code,
                IndicatorMeta.BLOOD_PRESSURE.code,
                IndicatorMeta.PULSE.code,
                IndicatorMeta.SMOKE.code
            ]
        },
        reasons: reasonsStatusChange(),
        indicators: {
            [IndicatorMeta.BLOOD_PRESSURE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BLOOD_PRESSURE.code]
            ),
            [IndicatorMeta.PULSE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PULSE.code]
            ),
            [IndicatorMeta.BODY_WEIGHT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BODY_WEIGHT.code]
            ),
            [IndicatorMeta.MEDICATION_CI.code]: indicatorItem(
                indicatorRules[IndicatorMeta.MEDICATION_CI.code]
            ),
            [IndicatorMeta.PAIN_CI.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PAIN_CI.code]
            ),
            [IndicatorMeta.PHYSACT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PHYSACT.code]
            ),
            [IndicatorMeta.SMOKE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.SMOKE.code]
            ),
            [IndicatorMeta.VAS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.VAS.code]
            ),
            [IndicatorMeta.EQ5D3L.code]: indicatorItem(
                indicatorRules[IndicatorMeta.EQ5D3L.code]
            )
        }
    };
};
