import moment from 'moment';
import {
    MEDICATION_CI,
    PHLEGM,
    PHYSACT,
    PRNMED,
    PRNMED_AF,
    PRNMED_CI,
    PRNMED_COPD,
    PRNMED_LVAD,
    SLEEP_SCI,
    RESPIRATORY_PROBLEMS,
    SMOKE,
    SWELLING,
    BOWEL_DYSFUNCTION
} from '../../../conditions/indicator-meta';
import { Strings } from '../../../locale/messagesDescriptors';
import {
    formatMessage,
    formatMessageById
} from '../../../locale/format/format-message';

type Data = Item[];
type Item = { date: number; value: any; comment?: string | null };

const getTitle = (title: string) =>
    `<h5 class="heatmap-tooltip-title">${title}</h5>`;

const formatTime = (date: number) => moment(date).format('LT');

const getChoiceText = (value: any, code: string) => {
    return formatMessageById(
        `${Strings.indicators.rootId}${code.toLowerCase()}.choices.${value}`
    );
};

const defaultChoices = (item: Item, code: string) => {
    const { date, value, comment } = item;

    const valueReported = getChoiceText(value, code);

    return {
        valueReported,
        timeReported: formatTime(date),
        comment
    };
};

const prnmedTooltip = (item: Item, code: string) => {
    const { date, value, comment } = item;

    const { dose, medication } = value;

    const medicationText = formatMessageById(
        `${
            Strings.indicators.rootId
        }${code.toLowerCase()}.choices.${medication}`
    );

    const valueReported = formatMessageById(
        `${Strings.indicators.rootId}${code.toLowerCase()}.values`,
        {
            dose,
            medication: medicationText
        }
    );

    return {
        valueReported,
        timeReported: formatTime(date),
        comment
    };
};

const smokeTooltip = (item: Item, code: string) => {
    const { value, comment } = item;

    const valueReported = formatMessageById(
        `${Strings.indicators.rootId}${code.toLowerCase()}.values`,
        {
            value
        }
    );

    return {
        valueReported,
        comment
    };
};

const physactTooltip = (item: Item, code: string) => {
    const { date, value, comment } = item;
    const { duration, intensity } = value;

    const valueReported = formatMessageById(
        `${Strings.indicators.rootId}${code.toLowerCase()}.values`,
        {
            duration,
            intensity
        }
    );

    return {
        valueReported,
        timeReported: formatTime(date),
        comment
    };
};

const medicationCiTooltip = (item: Item, code: string) => {
    const { date, value, comment } = item;

    const name1 = formatMessageById(
        `${
            Strings.indicators.rootId
        }${code.toLowerCase()}.anti-lipoproteins.title`
    );

    const val1 = formatMessageById(
        `${
            Strings.indicators.rootId
        }${code.toLowerCase()}.anti-lipoproteins.choices.${
            value['anti-lipoproteins']
        }`
    );

    const name2 = formatMessageById(
        `${Strings.indicators.rootId}${code.toLowerCase()}.anticoagulant.title`
    );

    const val2 = formatMessageById(
        `${
            Strings.indicators.rootId
        }${code.toLowerCase()}.anticoagulant.choices.${value['anticoagulant']}`
    );

    const valueReported = `<div style="padding-bottom: 20px">${name1}: ${val1} <br> ${name2}: ${val2}</div>`;

    return {
        valueReported,
        timeReported: formatTime(date),
        comment
    };
};

const phlegmTooltip = (item: Item, code: string) => {
    const {
        date,
        value: { color = 0, blood = false },
        comment
    } = item;
    const value = blood ? 5 : color + 1;

    const valueReported = formatMessageById(
        `${
            Strings.indicators.rootId
        }${code.toLowerCase()}.heatmap.legend.${value}`
    );

    return {
        valueReported,
        timeReported: formatTime(date),
        comment
    };
};

const respiratoryProblemsTooltip = (item: Item) => {
    const { date, value, comment } = item;
    const { status, frequency } = value;

    const valueReported = status
        ? formatMessage(Strings.indicators.respiratoryProblems.status.heatmap, {
              range: frequency
          })
        : formatMessage(
              Strings.indicators.respiratoryProblems.status.noProblems
          );

    return {
        valueReported,
        timeReported: formatTime(date),
        comment
    };
};

const swellingTooltip = (item: Item, code: string) => {
    const { date, value, comment } = item;

    const { position, degree } = value;

    const degreeText = formatMessageById(
        `${
            Strings.indicators.rootId
        }${code.toLowerCase()}.degree.choices.${degree}`
    );

    const positionText = formatMessageById(
        `${
            Strings.indicators.rootId
        }${code.toLowerCase()}.position.choices.${position}`
    );

    const valueReported = `${degreeText} - ${positionText}`;

    return {
        valueReported,
        timeReported: formatTime(date),
        comment
    };
};

const sleepSCITooltip = (item: Item, code: string) => {
    const { date, value, comment } = item;
    const { status, issue, other } = value;

    const statusText = formatMessageById(
        `${Strings.indicators.rootId}${code.toLowerCase()}.choices.${status}`
    );

    const issueText = formatMessageById(
        `${Strings.indicators.rootId}${code.toLowerCase()}.issues.${issue}`
    );

    const affectedByText = formatMessage(
        Strings.indicators.sleepSci.general.affectedBy
    );

    const valueReported =
        status === 0
            ? formatMessage(Strings.indicators.sleepSci.choices.none)
            : `${statusText}. ${affectedByText}: ${issueText} ${other}`;

    return {
        valueReported,
        timeReported: formatTime(date),
        comment
    };
};

const nbdTooltip = (item: Item) => {
    const { date, value, comment } = item;
    const { status, general_satisfaction, sum } = value;

    let choicesText = '';

    if (status === false) {
        choicesText = formatMessage(Strings.indicators.nbd.choices.none);
    } else if (status === true && sum >= 14) {
        choicesText = formatMessage(
            Strings.indicators.nbd.choicesNoScore.severe
        );
    } else if (status === true && sum >= 10) {
        choicesText = formatMessage(
            Strings.indicators.nbd.choicesNoScore.moderate
        );
    } else if (status === true && sum >= 7) {
        choicesText = formatMessage(
            Strings.indicators.nbd.choicesNoScore.minor
        );
    } else if (status === true && sum >= 0) {
        choicesText = formatMessage(
            Strings.indicators.nbd.choicesNoScore.veryMinor
        );
    }

    const score = formatMessage(Strings.indicators.nbd.general.score, {
        score: sum
    });

    const valueReported = `${choicesText} (${score})<br>${formatMessage(
        Strings.indicators.nbd.general.satisfaction
    )}: ${general_satisfaction}`;

    return {
        valueReported,
        timeReported: formatTime(date),
        comment
    };
};

const getTooltipData = (
    item: Item,
    code: string
): {
    valueReported: string;
    timeReported?: string;
    comment?: string | null;
} => {
    switch (code) {
        case MEDICATION_CI.code:
            return medicationCiTooltip(item, code);
        case PHLEGM.code:
            return phlegmTooltip(item, code);
        case PHYSACT.code:
            return physactTooltip(item, code);
        case PRNMED.code:
        case PRNMED_AF.code:
        case PRNMED_COPD.code:
        case PRNMED_CI.code:
        case PRNMED_LVAD.code:
            return prnmedTooltip(item, code);
        case SMOKE.code:
            return smokeTooltip(item, code);
        case SWELLING.code:
            return swellingTooltip(item, code);
        case SLEEP_SCI.code:
            return sleepSCITooltip(item, code);
        case RESPIRATORY_PROBLEMS.code:
            return respiratoryProblemsTooltip(item);
        case BOWEL_DYSFUNCTION.code:
            return nbdTooltip(item);

        default:
            return defaultChoices(item, code);
    }
};

export const getHeatmapTooltip = (
    indicatorCode: string,
    data: Data,
    date: number
) => {
    if (indicatorCode === SMOKE.code && data.length > 1) {
        const item = data.reduce(({ date, value: sum }, item) => ({
            date,
            value: sum + Number(item.value)
        }));
        const comments = data
            .map(({ comment }) => comment || '')
            .filter((comment) => comment != '');

        item.comment = comments.join('\n');
        data = [item];
    }

    const dataToRender = data.slice(0, 10);
    const numberOfAdditionalReportedValues = data.length - dataToRender.length;

    return `
        <div class="heatmap-tooltip-container">
            ${getTitle(moment(date).format('LL'))}
            <ul class="heatmap-tooltip-value-list">
                ${dataToRender
                    .map((item) => {
                        const { valueReported, timeReported, comment } =
                            getTooltipData(item, indicatorCode);

                        const commentText = comment
                            ? `<div class="heatmap-tooltip-comment"><blockquote>${comment}</blockquote><div>`
                            : '';

                        const timeText = timeReported
                            ? `<div class="heatmap-tooltip-value-time">${timeReported}</div>`
                            : '';

                        return `
                        <li class="heatmap-tooltip-value-row">
                            <div class="heatmap-tooltip-flex-container">
                                <div class="heatmap-tooltip-value-text">
                                    ${valueReported}
                                </div>
                               ${timeText}
                            </div>
                            ${commentText}
                        </li>
                    `;
                    })
                    .join('')}
            </ul>
            ${
                numberOfAdditionalReportedValues > 0
                    ? `<p class="heatmap-tooltip-more">${formatMessage(
                          Strings.chart.heatmap.moreTooltipValues,
                          { value: numberOfAdditionalReportedValues }
                      )}</p>`
                    : ''
            }
        </div>
    `;
};
