import React, { useMemo } from 'react';
import { Badge } from 'antd';
import { theme } from '../../../../theme/colors/commonColors';
import { makeGetFilterResultsCount } from '../../../../redux/domains/priority/priority.selectors';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';

const CustomTabTitle = ({
    title,
    groupId
}: {
    title: string;
    groupId: string;
}) => {
    const getFilterResultsCount = useMemo(makeGetFilterResultsCount, []);
    const searchResults = useReduxSelector((state) =>
        getFilterResultsCount(state, groupId)
    );

    return (
        <Badge
            offset={[5, 14]}
            style={{ backgroundColor: theme.layout.primary }}
            count={searchResults}
        >
            <span
                style={{
                    fontSize: 14,
                    paddingLeft: 10,
                    paddingRight: 10
                }}
            >
                {title}
            </span>
        </Badge>
    );
};

export default CustomTabTitle;
