import styled from 'styled-components';

export const StyledPatientCount = styled.div`
    border: 1px solid grey;
    border-radius: 3px;
    padding: 10px;
    text-align: center;
    display: inline-block;
`;

export const StyledContent = styled.div`
    overflow-x: auto;
    display: grid;
    height: calc(100vh - 250px);
    grid-template-rows: 1fr;
`;

export const StyledEmptyValueDiv = styled.div`
    height: 250px;
    padding: 100px;
`;
