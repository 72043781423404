import React, { FormEvent, useState } from 'react';
import { useDispatch, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MedicExternalId } from '../../../../redux/domains/user/user.type';
import { updateMedic } from '../../../../redux/domains/medic/medic.actions';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { TextField } from '../../../../components/form/inputs/TextField';
import { AppNeutralButton } from '../../../../components/ui/buttons/AppNeutralButton';
import { AppPrimaryButton } from '../../../../components/ui/buttons/AppPrimaryButton';
import { InputFields } from '../../../../components/form/inputs/types';
import { isEveryFieldValid } from '../../../../components/form/inputs/validation';
import { styled } from '../../../../theme/styled';
import { getInitialFields } from './profile-edit-fields';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';
import { formatPhoneNumber } from '../../../../redux/utils/format-phone-number';

export const ProfileEdit = (props: {
    setEditable: (editable: boolean) => void;
}): JSX.Element => {
    const dispatch = useDispatch();

    const routeParams = useParams<{ medicId: MedicExternalId }>();

    const userId = useReduxSelector((state) => state.user.externalId);

    const medicId = routeParams.medicId || userId;
    const meta = useReduxSelector(
        (state) => state.groups.medicUsers[medicId].meta,
        shallowEqual
    );

    const [fields, setFields] = useState<InputFields>(getInitialFields(meta));
    const [isValid, setIsValid] = useState<boolean>(isEveryFieldValid(fields));
    const [isTouched, setIsTouched] = useState<boolean>(false);

    return (
        <form onChange={onChange} onSubmit={onSubmit} onReset={onReset}>
            {Object.keys(fields).map((key) => (
                <TextField key={key} field={fields[key]} />
            ))}
            <StyledTableRow>
                <StyledTableCell>
                    <StyledTableCellContent>
                        <AppNeutralButton onClick={onBack}>
                            {formatMessage(Strings.navigation.general.back)}
                        </AppNeutralButton>
                    </StyledTableCellContent>
                </StyledTableCell>
                <StyledTableCell>
                    <StyledTableCellContent style={{ textAlign: 'right' }}>
                        <AppNeutralButton
                            htmlType="reset"
                            disabled={!isTouched}
                            style={{ marginRight: 10 }}
                        >
                            {formatMessage(Strings.common.general.reset)}
                        </AppNeutralButton>
                        <AppPrimaryButton
                            htmlType="submit"
                            disabled={!isValid || !isTouched}
                        >
                            {formatMessage(Strings.ui.buttons.general.save)}
                        </AppPrimaryButton>
                    </StyledTableCellContent>
                </StyledTableCell>
            </StyledTableRow>
        </form>
    );

    function onChange() {
        setIsValid(isEveryFieldValid(fields));
        setIsTouched(
            !Object.values(fields).every(
                (field) =>
                    field.value == getInitialFields(meta)[field.name].value
            )
        );
    }

    function onSubmit(event: FormEvent<HTMLFormElement>) {
        const currentTarget = event.currentTarget;
        event.preventDefault();
        dispatch(
            updateMedic(medicId, {
                email: currentTarget['email'].value,
                family_name: currentTarget['lastName'].value,
                name: currentTarget['firstName'].value,
                personal_id: currentTarget['ssn']?.value,
                phone_number: formatPhoneNumber(currentTarget['phone'].value),
                medicTitle: currentTarget['medicTitle'].value
            })
        );
        props.setEditable(false);
    }

    function onBack() {
        setFields(getInitialFields(meta));
        props.setEditable(false);
    }

    function onReset() {
        setFields(getInitialFields(meta));
        setIsTouched(false);
    }
};

const StyledTableRow = styled.div`
    display: table-row;
`;

const StyledTableCell = styled.div`
    display: table-cell;
`;

const StyledTableCellContent = styled.div`
    margin-top: 30px;
`;
