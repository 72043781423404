import React, { FormEvent, useState } from 'react';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { defaultValidationTexts } from '../../../../components/form/inputs/default-validation-texts';
import { placeholders } from '../../../../components/form/inputs/message-descriptors';
import {
    FieldType,
    InputFields
} from '../../../../components/form/inputs/types';
import { validateSsn } from '../../../../components/form/inputs/validation';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { postAction } from '../../../../redux/domains/actions/actions.api';
import { Actions } from '../../../../redux/domains/actions/actions.enums';
import { getAllPatientsById } from '../../../../redux/domains/patient-administration/patient-administration.selectors';
import { getPatient } from '../../../../redux/domains/patient-search/patient-search.api';
import {
    PatientSearchUser,
    RowSearchType
} from '../../../../redux/domains/patient-search/patient-search.type';
import { Name, Ssn } from './columns';
import { patientSearch } from './message-descriptors';
import { SearchForm } from './search-form/SearchForm';
import {
    StyledError,
    StyledFormDescription,
    StyledIcon,
    StyledModalContent
} from './styled';
import { PatientSearchState } from './types';
import { StyledTable } from '../../../../components/ui/table/Table';
import { Reasons } from '../../../../types/reasons.enum';
import { pathPatientProfileLink } from '../../data/components/tabs/path';
import { Strings } from '../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../locale/format/format-message';

const ssn = 'ssn';

const initialState: PatientSearchState = {
    loading: false,
    totalNumberOfHits: 0,
    patients: []
};

const initialFields: InputFields = {
    ssn: {
        fieldType: FieldType.Input,
        name: ssn,
        label: formatMessage(Strings.patient.meta.general.social),
        value: '',
        placeholder: formatMessage(placeholders.ssn),
        isValid: false,
        isTouched: false,
        required: true,
        minLength: 12,
        maxLength: 12,
        pattern: '^[a-zA-Z0-9åäöÅÄÖ-]+$',
        validationMessage: null,
        validationMessages: defaultValidationTexts,
        validate: function () {
            return validateSsn(this);
        }
    }
};

export const SystemSearchTab = (): JSX.Element => {
    const history = useHistory();
    const selectedPatientGroupId = useReduxSelector(
        (state) => state.groups.selectedPatientGroupId
    );
    const patientGroupsById = useReduxSelector(
        (state) => state.groups.patientGroups.byId
    );
    const allPatients = useReduxSelector(getAllPatientsById);
    const medicId = useReduxSelector((state) => state.user.externalId);
    const [state, setState] = useState<PatientSearchState>(initialState);
    const [error, setError] = useState<string>('');
    const columns = [Ssn, Name];

    return (
        <>
            <StyledFormDescription>
                {formatMessage(patientSearch.general.systemSearchDescription)}
            </StyledFormDescription>
            <SearchForm
                initialFields={initialFields}
                onSubmit={onSubmit}
                clearTable={() => {
                    setState({
                        ...initialState,
                        loading: false
                    });
                }}
            />
            {error && <StyledError>{error}</StyledError>}
            {!error && (
                <StyledTable
                    $clickable={true}
                    columns={columns}
                    dataSource={state.patients}
                    rowKey={(patient: PatientSearchUser) =>
                        patient.meta.personal_id
                    }
                    pagination={false}
                    loading={state.loading}
                    onRow={(record: RowSearchType) => {
                        return {
                            onClick: () => onRowClick(record)
                        };
                    }}
                />
            )}
        </>
    );

    function onRowClick(record: RowSearchType) {
        const patientDataLink = pathPatientProfileLink(
            record.group.find(
                (groupId) => groupId === selectedPatientGroupId
            ) ||
                record.group.find((groupId) => patientGroupsById[groupId]) ||
                record.group[0],
            record.externalId
        );

        if (allPatients[record.externalId]) {
            history.push(patientDataLink);
        } else {
            Modal.confirm({
                icon: <StyledIcon type="warning" />,
                content: (
                    <StyledModalContent>
                        {formatMessage(patientSearch.general.proceedWarning)}
                    </StyledModalContent>
                ),
                onOk() {
                    postAction({
                        proxyId: medicId,
                        externalId: record.externalId,
                        action: Actions.PATIENT_VIEWS_BY_MEDIC_OUTSIDE_UNIT,
                        reason: Reasons.OTHER,
                        note: 'Patient viewed from exact match patient search'
                    })
                        .then(() => history.push(patientDataLink))
                        .catch(() =>
                            setError(
                                formatMessage(
                                    patientSearch.errors.navigateToPatientFailed
                                )
                            )
                        );
                },
                okText: formatMessage(Strings.common.general.yes),
                cancelText: formatMessage(Strings.common.general.no)
            });
        }
    }

    async function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setError('');
        setState({
            ...initialState,
            loading: true
        });
        try {
            const result = await getPatient(
                event.currentTarget[ssn].value.replace('-', '')
            );
            setState({
                loading: false,
                totalNumberOfHits: result.totalNumberOfHits,
                patients: result.users
            });
        } catch (error) {
            setState(initialState);
            setError(formatMessage(Strings.error.general.message));
        }
    }
};
