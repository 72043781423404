import React, { useState } from 'react';
import {
    formatMessage,
    formatMessageById
} from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { ContentCardLayout } from '../../../components/content/ContentLayout';
import { LoginMethods } from '../../../constants/login-options';
import { CreateBasicMedic } from './components/create-medic-basic/CreateMedicBasic';
import { configAuthMethods, isDevEnv, samlUrl } from './env.constants';
import { usePageTitle } from '../../../hooks/use-page-title';
import { titleMedicOverview } from '../overview/title';
import { getBasicUserMedicFields } from './components/create-medic-basic/create-medic-fields';
import { SelectwithLabel } from '../../../components/form/inputs/select/SelectWithLabel';
import { placeholders } from '../../../components/form/inputs/message-descriptors';
import { CreateMedicSiths } from './components/create-medic-siths/CreateMedicSiths';
import { getSithsUserMedicFields } from './components/create-medic-siths/create-medic-siths-fields';

if (isDevEnv) {
    if (!configAuthMethods.includes(LoginMethods.email)) {
        configAuthMethods.push(LoginMethods.email);
    }

    if (!configAuthMethods.includes(LoginMethods.bankid)) {
        configAuthMethods.push(LoginMethods.bankid);
    }
}

export const dropdownLoginOptions = configAuthMethods.map((method) => {
    return {
        label: formatMessageById(`${Strings.authMethods.rootId}${method}`),
        key: method
    };
});

export const CreateMedicPage = () => {
    usePageTitle(titleMedicOverview);

    const [loginMethod, setLoginMethod] = useState<LoginMethods>(
        LoginMethods.siths
    );

    let createComponent = <></>;

    if (loginMethod === LoginMethods.email) {
        createComponent = (
            <CreateBasicMedic fields={getBasicUserMedicFields()} />
        );
    }

    if (loginMethod === LoginMethods.siths) {
        createComponent = <div>seid-siths not implemented</div>;

        if (samlUrl) {
            createComponent = (
                <CreateMedicSiths fields={getSithsUserMedicFields()} />
            );
        }
    }

    if (loginMethod === LoginMethods.bankid) {
        createComponent = <div>seid-bankid not implemented</div>;
    }

    return (
        <ContentCardLayout>
            <SelectwithLabel
                options={dropdownLoginOptions}
                handleChange={(event: LoginMethods) => {
                    setLoginMethod(event);
                }}
                label={formatMessage(Strings.labels.label.loginMethod)}
                placeholder={formatMessage(placeholders.chooseLoginMethod)}
                mode="default"
                defaultValue={loginMethod}
            />
            {createComponent}
        </ContentCardLayout>
    );
};
