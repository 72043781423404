import _ from 'lodash';
import { IndicatorProperty } from '../../../../../types/indicator/indicator-properties';
import {
    GraphValuesSorted,
    IndicatorValue,
    KeyValues
} from '../../../../../types/indicator/indicator-value.type';
import { CombinedGraphColor } from '../../../../../utils/create-condition-settings/indicator-rules/defaults/colors/graph-color.type';
import { GraphValueWithComment } from '../../types/graph-value.type';
import { getMarkerWithSymbol } from '../../utils/graph';

function propertyValue(data: any): data is KeyValues {
    return typeof data === 'object';
}

function guardEvent(data: any): GraphValuesSorted<KeyValues> {
    if (propertyValue(data.value)) {
        return data;
    }

    throw 'Guarded value is not an object';
}

export const multiCreateSerieData = (
    event: GraphValuesSorted<IndicatorValue>,
    property: IndicatorProperty,
    lineColorObj: CombinedGraphColor
): GraphValueWithComment[] => {
    const indicatorData = guardEvent(event);

    const maxProperty = _.maxBy(
        Object.keys(indicatorData.value),
        (prop) => indicatorData.value[prop]
    );

    const lastProperty = _.last(Object.keys(indicatorData.value));
    const envelope = indicatorData.comment && property === maxProperty;
    const point: GraphValueWithComment = {
        x: indicatorData.date,
        y: parseFloat(`${indicatorData.value[property]}`),
        marker: getMarkerWithSymbol(envelope, lineColorObj),
        comment: property === lastProperty ? indicatorData.comment : undefined
    };

    if (envelope) {
        // Duplicate point in order to show both comment symbol (envelope) and
        // data point marker symbol
        return [
            point,
            {
                ...point,
                marker: getMarkerWithSymbol(false, lineColorObj)
            }
        ];
    }

    return [point];
};
