import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Urine leakage',
        id: 'indicator.urine-leakage.title'
    }
});
export const messagesUrineLeakage = {
    general
};
