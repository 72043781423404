import { Indicator } from '../../../types/indicator/indicator.type';
import { rules } from './breathlessness-rules';

import icon from './images/breathlessness.svg';
import { messagesBreathlessness } from './messages/messages';

export const parameterBreathlessness = {
    code: 'BREATHLESSNESS'
};

export const breathlessness: Indicator = {
    code: parameterBreathlessness.code,
    icon,
    messages: messagesBreathlessness,
    rules
};
