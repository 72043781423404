import { RouteItemType } from '../../../utils/route/switch-routes';
import { CreatePatientPage } from './CreatePatientPage';
import { pathCreatePatient } from './path';

export const routeCreatePatient: RouteItemType = {
    name: '',
    link: pathCreatePatient,
    icon: 'user-add',
    component: CreatePatientPage
};
