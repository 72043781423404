import { RouteItemType } from '../../../utils/route/switch-routes';
import { EducationForm } from './EducationForm';
import { pathEducationForm } from './path';
import { titleEducationForm } from './title';

export const routeEducationForm: RouteItemType = {
    name: titleEducationForm,
    link: pathEducationForm,
    icon: 'error',
    component: EducationForm
};
