import indicatorRules from './indicator-rules';
import { indicatorItem } from './items';
import { IndicatorMeta } from '../../conditions/indicator-meta';
import { activate, deactivate } from '../../constants/status-change';
import { resetPriority } from '../../constants/reset-priority';
import { createHypertension01ConditionConfiguration } from '../../conditions/hypertension/01/hypertension-01-configuration';
import { createSpinalCordInjuryConfiguration } from '../../conditions/spinal-cord-injury/spinal-cord-injury-01-configuration';
import { createCiMauConfiguration } from '../../conditions/intermittent-claudication/ci-mau-configuration';
import { createPadRsConfiguration } from '../../conditions/peripheral-artery-disease/pad-rs-configuration';
import { createHeartFailureConfiguration } from '../../conditions/heart-failure/hf-rs-configuration';
import { createCOPDRsConfiguration } from '../../conditions/chronic-obstructive-pulmonary-disease/copd-rs-configuration';
import { createHypertensionCapioConditionConfiguration } from '../../conditions/hypertension/capio/hypertension-capio-configuration';
import { createLvadRsConfiguration } from '../../conditions/left-ventricular-assist-device/lvad-rs-configuration';
import { createKidneyFailureRsConfiguration } from '../../conditions/kidney-failure/kf-rs-configuration';
import { createAfRsConfiguration } from '../../conditions/atrial-fibrillation/af-rs-configuration';
import { createHsRsConfiguration } from '../../conditions/hand-surgery/hs-rs-configuration';
import { createFakeConditionConfiguration } from '../../conditions/fake-condition/fc-rs-configuration';
import { createDiabetesConfiguration } from '../../conditions/demo-diabetes/diabetes-configuration';
import { createDemoKfRsConfiguration } from '../../conditions/demo-kidney-failure/demo-kf-rs-configuration';
import { createAoRsConfiguration } from '../../conditions/aortic-dissection/ao-rs-configuration';
import { createDefaultConditionConfiguration } from './default-condition-configurations';

export const createConditionSettings = () => {
    const settingsObject = {
        default: createDefaultConditionConfiguration(),
        LPHF_REG_SKANE: createHeartFailureConfiguration(),
        LP_2_0_COPD_RS: createCOPDRsConfiguration(),
        CAPIO_HYPERTONI: createHypertensionCapioConditionConfiguration(),
        LP_2_0_HYPERTENSION_01: createHypertension01ConditionConfiguration(),
        LP_2_0_CI_MAU: createCiMauConfiguration(),
        LP_2_0_AO_RS: createAoRsConfiguration(),
        LP_2_0_PAD_RS: createPadRsConfiguration(),
        LP_2_0_LVAD_RS: createLvadRsConfiguration(),
        LP_2_0_KF_RS: createKidneyFailureRsConfiguration(),
        LP_2_0_AF_RS: createAfRsConfiguration(),
        LP_2_0_HS_RS: createHsRsConfiguration(),
        LP_2_0_FAKE_CONDITION_RS: createFakeConditionConfiguration(),
        LP_2_0_DIABETES_RS_DEMO_UH: createDiabetesConfiguration(),
        LP_2_0_KF_RS_DEMO_UH: createDemoKfRsConfiguration(),
        LP_2_0_SPINAL_CORD_INJURY_01: createSpinalCordInjuryConfiguration()
    };

    return settingsObject;
};
