import { IndicatorConfigurationState } from '../../../../indicators/indicator.type';
import _ from 'lodash';

export function getDefaultMinMaxFromConfiguration(params: {
    indicatorConfiguration: IndicatorConfigurationState;
    minMaxFunction: (...values: number[]) => number;
    defaultValue: number;
}): number {
    const max = [
        params.defaultValue,
        ...Object.values(params.indicatorConfiguration.configuration)
            .map((item) => {
                if (typeof item === 'object') {
                    return params.minMaxFunction(
                        ...(_.flatMapDeep(item).filter(
                            (s) => typeof s === 'number'
                        ) as number[])
                    );
                }
                if (typeof item === 'number') {
                    return item;
                }
                return params.defaultValue;
            })

            .filter((s) => typeof s === 'number')
            .flatMap((e) => e)
    ];

    return params.minMaxFunction(...max);
}
