import * as actionTypes from './chat.constants';
import {
    addConversation,
    getMessages,
    getConversations,
    postMessage,
    updateStatus
} from './chat.api';

import { ChatConversation, MessagingParticipant } from './chat.types';

type ConversationId = {
    externalId: string;
    conversationId: string;
};

export const fetchOrCreateConversationId =
    (
        medicGroupId: string,
        subjectId: string,
        participants: MessagingParticipant[]
    ) =>
    (dispatch: any) =>
        dispatch(fetchConversation(medicGroupId, subjectId)).then(
            (conversation: ChatConversation | undefined) =>
                conversation
                    ? conversation.id
                    : dispatch(
                          createConversation(
                              subjectId,
                              medicGroupId,
                              participants
                          )
                      ).then(
                          ({ value }: { value: ConversationId }) =>
                              value.conversationId
                      )
        );

export const fetchOrCreateConversation =
    (
        medicGroupId: string,
        externalId: string,
        participants: MessagingParticipant[]
    ) =>
    (dispatch: any) => {
        dispatch({ type: actionTypes.FETCH_OR_CREATE_CONVERSATION_PENDING });

        return dispatch(fetchConversation(medicGroupId, externalId))
            .then((conversation: ChatConversation | undefined) =>
                dispatch(
                    conversation
                        ? fetchMessages(conversation.id, externalId)
                        : createConversation(
                              externalId,
                              medicGroupId,
                              participants
                          )
                )
            )
            .then((data: { value: ConversationId }) => {
                const { value } = data;
                const { conversationId } = value;
                dispatch({
                    type: actionTypes.FETCH_OR_CREATE_CONVERSATION_FULFILLED
                });
                dispatch(setCurrentActiveConversation(conversationId));
                return conversationId;
            });
    };

const fetchConversation =
    (medicGroupId: string, externalId: string) => (dispatch: any) =>
        dispatch(fetchConversations(externalId)).then(
            ({ value: conversationList }: { value: ChatConversation[] }) =>
                conversationList.find((conversation) =>
                    conversation.tags.some(
                        (tag) =>
                            tag.key === 'groupId' && tag.value === medicGroupId
                    )
                )
        );

const fetchConversations = (externalId: string) => ({
    type: actionTypes.FETCH_CONVERSATION,
    payload: getConversations(externalId),
    meta: { externalId }
});

export const fetchMessages = (conversationId: string, externalId: string) => ({
    type: actionTypes.FETCH_MESSAGES,
    payload: getMessages(conversationId).then((data) => ({
        data,
        externalId,
        conversationId
    }))
});

export const createConversation =
    (
        externalId: string,
        medicGroupId: string,
        participants: MessagingParticipant[]
    ) =>
    (dispatch: any) => {
        const data = {
            topic: 'just talking',
            participants: participants,
            tags: {
                subjectId: externalId,
                groupId: medicGroupId
            }
        };
        return dispatch({
            type: actionTypes.CREATE_CONVERSATION,
            payload: addConversation(data, externalId)
        });
    };

export const sendMessage =
    (
        externalId: string,
        conversationId: string,
        body: {
            content: string;
        }
    ) =>
    (dispatch: any) => {
        return dispatch({
            type: actionTypes.POST_MESSAGE,
            payload: postMessage(externalId, conversationId, body)
        }).then(() =>
            dispatch(clearConversationStatus(externalId, conversationId))
        );
    };

export const updateConversationStatus =
    (conversationId: string, externalId: string) => (dispatch: any) =>
        dispatch({
            type: actionTypes.UPDATE_CONVERSATION_STATUS,
            payload: updateStatus(conversationId, externalId)
        });

export const clearConversationStatus =
    (externalId: string, conversationId: string) => (dispatch: any) =>
        dispatch({
            type: actionTypes.CLEAR_CONVERSATION_STATUS,
            payload: { externalId, conversationId, status: [] }
        });

export const setCurrentActiveConversation = (conversationId: string) => {
    return {
        type: actionTypes.SET_CURRENT_ACTIVE_CONVERSATION,
        payload: conversationId
    };
};
