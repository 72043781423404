import React, { useState, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { Card } from 'antd';
import { ContentLayout } from '../../../components/content/ContentLayout';
import { usePageTitle } from '../../../hooks/use-page-title';
import { initialFields } from './initial-fields';
import { RadioGroup } from '../../../components/form/inputs/RadioGroup';
import { formatMessage } from '../../../locale/format/format-message';
import { AppPrimaryButton } from '../../../components/ui/buttons/AppPrimaryButton';
import { RadioGroupFields } from '../../../components/form/inputs/types';
import { messages } from './messages';
import { EducationFormFailed } from './components/EducationFormFailed';
import { EducationFormError } from './components/EducationFormError';
import { styled } from '../../../theme/styled';
import { updateMedic } from '../../../redux/domains/medic/medic.actions';
import { useDispatch } from 'react-redux';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { pathEducationFormPassed } from '../form-passed/path';

export const EducationForm = (): JSX.Element => {
    usePageTitle(formatMessage(messages.educationForm.title));
    const dispatch = useDispatch<any>();
    const routeHistory = useHistory();
    const userId = useReduxSelector((state) => state.user.externalId);
    const getInitialFields = () => cloneDeep(initialFields);
    const [fields, setFields] = useState<RadioGroupFields>(getInitialFields());
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isCorrect, setIsCorrect] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [formFailed, setFormFailed] = useState<boolean>(false);

    if (formFailed) {
        return <EducationFormFailed fields={fields} />;
    }
    if (hasError) {
        return <EducationFormError onBack={() => setHasError(false)} />;
    }

    return (
        <ContentLayout>
            <Card style={{ marginBottom: 10 }}>
                <div style={{ maxWidth: 600, marginBottom: 50 }}>
                    <p>{formatMessage(messages.educationForm.about)}</p>
                    <p>{formatMessage(messages.educationForm.info)}</p>
                    <p>{formatMessage(messages.educationForm.gdpr)}</p>
                </div>
                <StyledForm onChange={onChange} onSubmit={onSubmit}>
                    {Object.keys(fields).map((key) => (
                        <RadioGroup
                            key={fields[key].name}
                            field={fields[key]}
                        />
                    ))}
                    <AppPrimaryButton
                        id="submit-form"
                        style={{
                            marginTop: 20,
                            marginBottom: 50
                        }}
                        htmlType="submit"
                        disabled={!isValid}
                        loading={isLoading}
                    >
                        {formatMessage(messages.educationForm.submit)}
                    </AppPrimaryButton>
                </StyledForm>
                <StyleVersion>T1Mver02</StyleVersion>
            </Card>
        </ContentLayout>
    );

    function onChange(event: FormEvent<HTMLFormElement>) {
        setIsValid(
            Object.keys(fields).every((key) => fields[key].value != null)
        );
        setIsCorrect(
            Object.keys(fields).every(
                (key) => fields[key].correctValue === fields[key].value
            )
        );
    }

    function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (isCorrect) {
            setIsLoading(true);
            void dispatch(updateMedic(userId, { medicEducated: true }))
                .then(() => routeHistory.replace(pathEducationFormPassed))
                .catch(() => {
                    setIsLoading(false);
                    setHasError(true);
                });
        } else {
            setFormFailed(true);
        }
    }
};

const StyledForm = styled.form`
    max-width: 600px;

    label {
        display: flex !important;
        margin: 5px 0;
    }

    .ant-radio-group > label > span {
        white-space: normal;
    }

    .ant-radio-wrapper {
        margin-left: 20px;
    }

    .ant-radio {
        height: 19px; //Needed for the click animation
        padding-top: 2px;
    }
`;

const StyleVersion = styled.div`
    position: absolute;
    right: 20px;
    bottom: 20px;
`;
