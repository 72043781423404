import { HTTPClient } from '../../../services/http-client/http-client';
import { PatientGroupId } from '../groups/groups.type';
import { IndicatorConfigurationState } from '../indicators/indicator.type';
import {
    UpdatePatientGroupRequest,
    CreatePatient,
    GetPatientResponse,
    UpdatePatient
} from './active-patient.api.types';
import {
    NormalizedPatientType,
    normalizePatient
} from './active-patient.utils';
import { isPatientGroupData } from './active-patient.guard';

export const getPatient = (
    subjectId: string
): Promise<NormalizedPatientType> => {
    return HTTPClient.get<GetPatientResponse>(`users/${subjectId}`).then(
        normalizePatient
    );
};

export const updateIndicatorConfig = (
    patientId: string,
    conditionId: string,
    { indicatorId, configuration }: IndicatorConfigurationState
): Promise<IndicatorConfigurationState> =>
    HTTPClient.put(`users/${patientId}/conditions/${conditionId}/indicators`, {
        indicatorId,
        configuration
    }).then(() => ({ conditionId, indicatorId, configuration }));

export const addPatient = (user: CreatePatient) =>
    HTTPClient.post('users', user);

export const putPatient = (
    subjectId: string,
    data: UpdatePatient
): Promise<NormalizedPatientType> => {
    return HTTPClient.put(`users/${subjectId}`, data)
        .then(normalizePatient)
        .catch((error) => {
            console.error(`Error in ${putPatient.name}\n`, error);
            throw error;
        });
};

export const putPatientGroup = (
    groupId: PatientGroupId,
    data: UpdatePatientGroupRequest
): any => {
    if (isPatientGroupData(data)) {
        return HTTPClient.put(`groups/${groupId}/users`, data).catch((error) =>
            console.warn('error status', error)
        );
    }

    throw 'Wrong data for update patient group';
};
