import React from 'react';
import styled from 'styled-components';

export const DemoBanner = () => (
    <BannerWrapper>
        <BannerText>LIFEPOD DEMO</BannerText>
    </BannerWrapper>
);

const BannerWrapper = styled.div`
    top: 0;
    position: absolute;
    width: 250px;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 9999;
    height: 80px;
    background-color: #5b64c2;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    text-align: center;
`;

const BannerText = styled.h1`
    color: #fff;
    line-height: 80px;
    text-align: center;
    position: relative;
    ${
        '' /* animation: ${slideFromRight} 7s linear infinite; // It currently lags a bit when rendering the app */
    };
`;
