import React from 'react';
import moment from 'moment';
import { Button } from 'antd';

import { GeneralPanelWrapper } from '../GeneralPanelWrapper';
import { reset } from './message-descriptors';
import { formatMessage } from '../../../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../../../locale/messagesDescriptors';
import { LatestResetType } from './latest-reset.type';

export const ResetStatus = (props: {
    latestDateForReset: number;
    latestReset: LatestResetType;
    toggleResetForm: () => void;
    disabled: boolean;
}): JSX.Element => {
    const { proxyMeta: { name = undefined, family_name = undefined } = {} } =
        props.latestReset;

    const fullName = name
        ? formatMessage(Strings.common.general.mostRecentlyBy, {
              name: `${name} ${family_name}`
          })
        : null;
    const fromNow = props.latestDateForReset
        ? moment(props.latestDateForReset).fromNow()
        : formatMessage(reset.general.notResetYet);

    return (
        <GeneralPanelWrapper
            title={formatMessage(reset.general.title)}
            practitioner={fullName}
            data={fromNow}
        >
            <Button
                type="danger"
                style={{ width: '100%' }}
                onClick={() => props.toggleResetForm()}
                disabled={props.disabled}
            >
                {formatMessage(reset.general.title)}
            </Button>
        </GeneralPanelWrapper>
    );
};
