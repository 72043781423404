import { defineMessages } from 'react-intl';

const error = defineMessages({
    title: {
        id: 'sso.error.title',
        defaultMessage: 'LifePod - login error'
    },
    info: {
        id: 'sso.error.info',
        defaultMessage:
            'The automatic login failed. Please try again. If the error persists, please contact the LifePod support.'
    },
    support: {
        id: 'sso.error.support',
        defaultMessage:
            'We can be reached at [support@lifepod.se](mailto:support@lifepod.se) or 0700 - 400 402.'
    }
});

export const messagesSso = {
    error
};
