import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/swelling-hs.svg';
import { messagesSwellingHs } from './messages/messages';
import { rules } from './swelling-hs-rules';

export const parameterSwellingHs = {
    code: 'SWELLING-HS'
};

export const swellingHs: Indicator = {
    code: parameterSwellingHs.code,
    icon,
    messages: messagesSwellingHs,
    rules
};
