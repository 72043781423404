import React from 'react';
import { PatientSearchUser } from '../../../../redux/domains/patient-search/patient-search.type';
import { alphabetic } from '../../../../utils/sort/alphabetic';
import { Strings } from '../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../locale/format/format-message';
import { PatientGroupLink } from '../../../../components/patient/PatientGroupLink';

export const Ssn = {
    title: formatMessage(Strings.patient.meta.general.social),
    key: 'ssn',
    dataIndex: 'meta.personal_id',
    width: 200
};

export const SortableSsn = {
    ...Ssn,
    sorter: (a: PatientSearchUser, b: PatientSearchUser) =>
        alphabetic(a.meta.personal_id, b.meta.personal_id)
};

const RenderName = (text: { name: string; family_name: string }) => (
    <a>{`${text.name} ${text.family_name}`}</a>
);

export const Name = {
    title: formatMessage(Strings.patient.meta.general.name),
    key: 'name',
    dataIndex: 'meta',
    render: RenderName,
    width: 200
};

export const FirstName = {
    title: formatMessage(Strings.labels.label.firstName),
    key: 'name',
    dataIndex: 'meta.name',
    width: 200,
    sorter: (a: PatientSearchUser, b: PatientSearchUser) =>
        alphabetic(a.meta.name, b.meta.name)
};

export const FamilyName = {
    title: formatMessage(Strings.labels.label.familyName),
    key: 'family_name',
    dataIndex: 'meta.family_name',
    width: 200,
    sorter: (a: PatientSearchUser, b: PatientSearchUser) =>
        alphabetic(a.meta.family_name, b.meta.family_name)
};

const RenderMemberOf = (text: string[], record: { groups: string[] }) => (
    <>
        {record.groups.map((groupId) => (
            <PatientGroupLink key={groupId} patientGroupId={groupId} />
        ))}
    </>
);

export const GetMemberOf = (patientGroups: any) => {
    return {
        title: formatMessage(Strings.patient.meta.general.memberOf),
        key: 'memberOf',
        dataIndex: 'groups',
        render: RenderMemberOf,
        sorter: (a: PatientSearchUser, b: PatientSearchUser) =>
            alphabetic(
                a.groups
                    .map((group) => patientGroups.byId[group]?.name || '')
                    .join(),
                b.groups
                    .map((group) => patientGroups.byId[group]?.name || '')
                    .join()
            )
    };
};

export const Status = {
    title: formatMessage(Strings.labels.label.status),
    key: 'status',
    dataIndex: 'status',
    width: 200,
    render: (status: string) =>
        formatMessage(Strings.common.general.statusValue, {
            status
        }),
    sorter: (a: PatientSearchUser, b: PatientSearchUser) =>
        alphabetic(a.status, b.status)
};
