import { defineMessages } from 'react-intl';

const role = defineMessages({
    admin: {
        id: 'roles.role.admin',
        defaultMessage: 'Admin'
    },
    medic: {
        id: 'roles.role.medic',
        defaultMessage: 'Caregiver'
    }
});

export const roles = { role };
