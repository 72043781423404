import { HTTPClient } from '../../../services/http-client/http-client';
import { normalizeNotifications } from './notifications.utils';

export const getNotificationsForAuthUser = (
    params: any,
    activeConversation: string
) =>
    HTTPClient.get('notifications', { params }).then((data) => {
        return normalizeNotifications(data, activeConversation);
    });

export const updateStatus = (externalId: string, data: any) =>
    HTTPClient.put('notifications', data).then((data) => {
        const idsToRemove = data.map((item: any) => item.id);
        return { externalId, idsToRemove };
    });
