export const activate = {
    INITIAL_ACTIVATION: 'initial_activation',
    NO_REASON: 'no_reason',
    PATIENT_CONTINUES: 'patient_continues',
    VACATION_BACK: 'vacation_back',
    HOSPITAL_DISCHARGED: 'hospital_discharged',
    MOVED_FROM_OTHER_CAREGIVER: 'moved_from_other_caregiver',
    OTHER: 'other'
};

export const deactivate = {
    DIAGNOSIS_CHANGED: 'diagnosis_changed',
    DECEASED: 'deceased',
    PATIENT_DECLINES: 'patient_declines',
    STUDY_COMPLETED: 'study_completed',
    TOO_ILL: 'too_ill',
    VACATION: 'vacation',
    HOSPITALIZATION: 'hospitalization',
    TREATMENT_FINISHED: 'treatment_finished',
    MOVED_TO_OTHER_CAREGIVER: 'moved_to_other_caregiver',
    OTHER: 'other'
};
