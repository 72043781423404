import { RouteItemType } from '../../../utils/route/switch-routes';
import { pathPatientOverview, pathPatientOverviewDashboard } from './path';
import { PatientOverviewPage } from './PatientOverviewPage';
import { titlePatientOverview } from './title';

export const routePatientOverview: RouteItemType & { dashboard: string } = {
    name: titlePatientOverview,
    link: pathPatientOverview,
    icon: 'solution',
    dashboard: pathPatientOverviewDashboard,
    component: PatientOverviewPage
};
