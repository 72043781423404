import React from 'react';
import { SplineSeries } from 'react-jsx-highcharts';
import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import defaultColorObj from '../../../../utils/create-condition-settings/indicator-rules/defaults/colors/default';
import { GraphValue } from '../types/graph-value.type';
import { useDispatch } from 'react-redux';
import { getLineColor } from '../utils/graph';
import { IndicatorGraphSettings } from '../../../../redux/domains/chart/graph/chart.graph.types';
import { setSelectedDate } from '../../../../redux/domains/chart/shared/chart.shared.actions';
import { IndicatorSettingsMapping } from '../../../../conditions/condition.type';
import {
    graphSetHiddenindicator,
    graphSetShowIndicator
} from '../../../../redux/domains/chart/graph/chart.graph.actions';
import { HighchartJSXTarget } from '../types/graph.type';
import { SeriesClickEventObject } from 'highcharts';

export function SinglePropertySplineSeries(props: {
    chartIndicator: IndicatorWithSettings;
    data: GraphValue[];
    settingsMapping: IndicatorSettingsMapping;
    activeGraph: IndicatorGraphSettings | null;
    sharedTooltips: (value: boolean) => void;
}) {
    const dispatch = useDispatch();
    const chartIndicator = props.chartIndicator;
    const indicatorCode = chartIndicator.code;
    const indicatorTitle = chartIndicator.title;

    const data = props.data;
    const settingsMapping = props.settingsMapping;
    const activeGraph = props.activeGraph;
    const sharedTooltips = props.sharedTooltips;

    const lineColor = findLineColor({
        chartIndicator: chartIndicator,
        settingsMapping: settingsMapping,
        activeGraph: activeGraph
    });

    return (
        <SplineSeries
            key={indicatorCode}
            name={indicatorTitle}
            findNearestPointBy="xy"
            data={data}
            stickyTracking={false}
            onClick={onClickHandler}
            onMouseOver={() => {
                sharedTooltips(false);
            }}
            onHide={onHideHandler}
            onShow={onShowHandler}
            color={lineColor.toString()}
            // UserOptions
            indicatorCode={indicatorCode}
        />
    );

    function onHideHandler(event: Event) {
        const target = event.target as HighchartJSXTarget;
        const userOptions = target.userOptions;

        dispatch(
            graphSetHiddenindicator({
                code: userOptions.indicatorCode
            })
        );
    }

    function onShowHandler(event: Event) {
        const target = event.target as HighchartJSXTarget;
        const userOptions = target.userOptions;

        dispatch(
            graphSetShowIndicator({
                code: userOptions.indicatorCode
            })
        );
    }

    function onClickHandler(event: SeriesClickEventObject) {
        return (
            event.point.options.x &&
            dispatch(setSelectedDate(event.point.options.x))
        );
    }
}

function findLineColor(props: {
    chartIndicator: IndicatorWithSettings;
    settingsMapping: IndicatorSettingsMapping;
    activeGraph: IndicatorGraphSettings | null;
}) {
    const indicatorCode = props.chartIndicator.code;

    return props.activeGraph != null
        ? defaultColorObj.default
        : getLineColor(props.settingsMapping[indicatorCode]).default;
}
