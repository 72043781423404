import React, { useState } from 'react';

import { DataPoint } from '../chart-types';
import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import { HeatmapLegends } from '../../legends/HeatmapLegend';
import { HeatmapEchart } from './components/echart-heatmap/HeatmapEchart';
import { ChartDateButtons } from '../date-buttons/ChartDateButtons';
import { Duration } from '../duration';
import { isHeatmapRules } from '../../../../redux/domains/settings/settings.type';

export const Heatmap = (props: {
    indicator: IndicatorWithSettings;
    values: DataPoint[];
}): JSX.Element => {
    const [duration, setDuration] = useState<Duration>(Duration.Week);

    const indicatorSettings = props.indicator.settings;

    return isHeatmapRules(indicatorSettings.rules) ? (
        <>
            <HeatmapLegends
                legends={indicatorSettings.rules.heatmapLegendItems}
                style={{
                    textAlign: 'right',
                    position: 'relative',
                    zIndex: 1
                }}
            />
            <HeatmapEchart
                duration={duration}
                indicator={props.indicator}
                values={props.values}
            />
            <ChartDateButtons setDuration={setDuration} />
        </>
    ) : (
        <></>
    );
};
