import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { Redirect } from 'react-router-dom';
import { CustomImage } from '../../../components/image/custom-image/CustomImage';

import { ContentCardLayout } from '../../../components/content/ContentLayout';

import './oauth2page.css';
import { appLogos } from '../../../constants/app-logos';
import {
    RouteItemType,
    SwitchRoutes
} from '../../../utils/route/switch-routes';
import { routeOauthLaunch } from './pages/launch/route';
import { routeOauthTokens } from './pages/tokens/route';
import { pathOauth2 } from './path';
import { pathOauth2Launch } from './pages/launch/path';

const oAuthRoutes: RouteItemType[] = [routeOauthLaunch, routeOauthTokens];
export default class OAuthPage extends Component {
    render() {
        return (
            <Row
                type="flex"
                justify="center"
                align="middle"
                style={{ height: '30%' }}
            >
                <Col>
                    <ContentCardLayout>
                        <div className="oauth-container">
                            <div className="oauth-logo-container">
                                <CustomImage
                                    src={appLogos.standardLogo.src}
                                    alt={appLogos.standardLogo.alt}
                                    style={{ height: 100 }}
                                />
                            </div>
                            <div className="oauth-content-container">
                                <div className="title">
                                    <h2>LifePod</h2>
                                    <h5>by Cross Technology Solutions AB</h5>
                                    <hr />
                                </div>
                                <SwitchRoutes routes={oAuthRoutes}>
                                    <Redirect
                                        from={pathOauth2}
                                        to={pathOauth2Launch}
                                    />
                                </SwitchRoutes>
                            </div>
                        </div>
                    </ContentCardLayout>
                </Col>
            </Row>
        );
    }
}
