import {
    PatientGroupId,
    PatientGroupsById
} from '../../../redux/domains/groups/groups.type';

export function findMedicGroup(
    allGroups: PatientGroupsById,
    prioPatientGroup: PatientGroupId
) {
    const keys = Object.keys(allGroups);
    for (let i = 0, len = keys.length; i < len; i++) {
        const k = keys[i];
        if (allGroups[k].id === prioPatientGroup) {
            const medicGroup: string = allGroups[k].managedBy[0];
            return medicGroup;
        }
    }

    return '';
}
