import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Icon, Spin } from 'antd';
import { ContentCardLayout } from '../../content/ContentLayout';
import { CubeFold } from './cube/Cube';
import { ProfileType } from '../../../theme/profile/profile.types';

type LoadingProp = {
    type?: string;
    color?: string;
    theme: ProfileType;
};

export const Loading = withTheme((props: LoadingProp) => {
    return (
        <CubeFold
            size={props.type === 'content' ? 30 : null}
            color={props.color ? props.color : props.theme.primary.color}
        />
    );
});

type SpinnerProp = {
    iconOnly?: boolean;
    position?: string;
    display?: string;
    card?: boolean;
    text?: string;
};

export const CrossSpinner = ({
    iconOnly = false,
    position = 'center',
    display = 'block',
    card = false,
    text
}: SpinnerProp) => {
    const icon = <ColorChangingIcon type="loading" />;
    if (iconOnly) {
        return icon;
    }

    if (!card)
        return (
            <StyledDiv position={position} display={display}>
                <Spin indicator={icon} />
                <h4 style={{ marginTop: 10 }}>{text}</h4>
            </StyledDiv>
        );

    return (
        <ContentCardLayout>
            <StyledDiv position={position} display={display}>
                <Spin indicator={icon} />
                <h4 style={{ marginTop: 10 }}>{text}</h4>
            </StyledDiv>
        </ContentCardLayout>
    );
};

const StyledDiv = styled.div<{ position: string; display: string }>`
    text-align: ${(props) => props.position};
`;

const ColorChangingIcon = styled(Icon)`
    font-size: 22px;
    font-weight: bold;
    animation: loader-color-change 2.5s infinite;
`;
