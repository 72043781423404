import { keyBy, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { ReduxState } from '../../redux-state.type';
import { getMedicsInGroup } from '../groups/groups.selectors';
import { getAllPatientsById } from '../patient-administration/patient-administration.selectors';
import { ConversationStatus, MessagingParticipant } from './chat.types';

type MedicGroupIdProps = { medicGroupId: string; patientId: string };
type ConversationIdProps = { conversationId: string; patientId: string };

const getConversations = (state: ReduxState, props: { patientId: string }) =>
    state.chat.bySubjectId[props.patientId];

const getConversation = (state: ReduxState, props: ConversationIdProps) =>
    getConversations(state, props)?.byConversationId[props.conversationId];

const getMedicGroupId = (state: ReduxState, props: MedicGroupIdProps) =>
    props.medicGroupId;

export const getParticipants = (
    state: ReduxState,
    props: MedicGroupIdProps
): MessagingParticipant[] => {
    const patient = getAllPatientsById(state)[props.patientId];
    const medics = getMedicsInGroup(state, props);

    return medics.concat(patient).map((user) => ({
        name: user.meta.name,
        family_name: user.meta.family_name,
        id: user.externalId
    }));
};

export const getConversationId = createSelector(
    [getConversations, getMedicGroupId],
    (conversations, medicGroupId) =>
        conversations &&
        Object.values(conversations.byConversationId).find((conversation) =>
            conversation.tags.some(
                (tag) => tag.key === 'groupId' && tag.value === medicGroupId
            )
        )?.id
);

export const getMessages = createSelector(getConversation, (conversation) =>
    sortBy(conversation?.messages, 'dtCreated')
);

export const getConversationStatus = createSelector(
    [getConversations, getConversationId],
    (conversations, conversationId): ConversationStatus[] => {
        if (conversations && conversationId) {
            const conversation =
                conversations?.byConversationId[conversationId];
            if (conversation) {
                const participantsById = keyBy(conversation.participants, 'id');
                return conversation.status
                    .map((status) => ({
                        ...status,
                        meta: participantsById[status.participantId]
                    }))
                    .filter((status) => status.meta != null);
            }
        }
        return [];
    }
);
