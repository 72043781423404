import { createReducer } from '@reduxjs/toolkit';
import {
    fetchUnreadMessagesOfTheDay,
    markMessagesOfTheDayAsRead
} from './motd.actions';
import { MessageOfTheDay } from './motd.types';

type MotdState = {
    messages: MessageOfTheDay[];
};

const initialState: MotdState = {
    messages: []
};

const motdReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(
            fetchUnreadMessagesOfTheDay.fulfilled,
            (state, { payload }) => {
                state.messages = payload;
            }
        )
        .addCase(markMessagesOfTheDayAsRead.fulfilled, (state) => {
            state.messages = [];
        });
});

export default motdReducer;
