import { defineMessages } from 'react-intl';

const general = defineMessages({
    back: {
        id: 'navigation.back.label',
        defaultMessage: 'Back'
    }
});

export const navigation = {
    general
};
