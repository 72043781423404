import { Strings } from '../../../locale/messagesDescriptors';
import { formatMessage } from '../../../locale/format/format-message';
import { colors } from '../../../theme/colors/commonColors';
import { ChartType } from '../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartHeatmapRules = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        { value: 1, color: colors.chart.heatMap.purple10 },
        { value: 2, color: colors.chart.heatMap.purple30 },
        { value: 3, color: colors.chart.heatMap.purple50 },
        { value: 4, color: colors.chart.heatMap.purple80 }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.tiredness.choices.unaffected.id,
            text: formatMessage(
                Strings.indicators.tiredness.choices.unaffected
            ),
            color: colors.chart.heatMap.purple10
        },
        {
            textId: Strings.indicators.tiredness.choices.moderate.id,
            text: formatMessage(Strings.indicators.tiredness.choices.moderate),
            color: colors.chart.heatMap.purple30
        },
        {
            textId: Strings.indicators.tiredness.choices.light.id,
            text: formatMessage(Strings.indicators.tiredness.choices.light),
            color: colors.chart.heatMap.purple50
        },

        {
            textId: Strings.indicators.tiredness.choices.rest.id,
            text: formatMessage(Strings.indicators.tiredness.choices.rest),
            color: colors.chart.heatMap.purple80
        }
    ],
    boundariesConfiguration: {
        type: 'radio'
    },
    choices: [
        {
            localeId: Strings.indicators.tiredness.choices.unaffected.id,
            text: formatMessage(
                Strings.indicators.tiredness.choices.unaffected
            ),
            value: 1
        },
        {
            localeId: Strings.indicators.tiredness.choices.moderate.id,
            text: formatMessage(Strings.indicators.tiredness.choices.moderate),
            value: 2
        },
        {
            localeId: Strings.indicators.tiredness.choices.light.id,
            text: formatMessage(Strings.indicators.tiredness.choices.light),
            value: 3
        },
        {
            localeId: Strings.indicators.tiredness.choices.rest.id,
            text: formatMessage(Strings.indicators.tiredness.choices.rest),
            value: 4
        }
    ]
};
