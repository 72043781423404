import { colors } from '../../../../theme/colors/commonColors';
import { ChartType } from '../../../../types/indicator/chart.type';
import { IndicatorChartGraphRules } from '../../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartGraphRules = {
    step: 100,
    chartTypes: [ChartType.Spline],
    lineColor: {
        name: colors.chart.graph.green.name,
        default: colors.chart.graph.green.forestBright
    },
    hideLimits: true,
    divideBy: 1000
};
