import { prnmed as sharedPrnmed } from '../../../shared-indicators/prnmed/prnmed';
import { messagesPrnmedCopd } from './messages/messages';
import { PrnmedCOPDProperties } from './prnmed-copd-properties';
import { rules } from './prnmed_copd-rules';

export const parameterPrnmedCopd = {
    code: 'PRNMED_COPD',
    properties: PrnmedCOPDProperties
};

export const prnmed: typeof sharedPrnmed = {
    ...sharedPrnmed,
    code: parameterPrnmedCopd.code,
    messages: messagesPrnmedCopd,
    rules
};
