import { defineMessages } from 'react-intl';

export const editPatientOther = defineMessages({
    nonEditable: {
        id: 'medic.edit-patient.other.non-editable',
        defaultMessage: 'Cannot be edited'
    },
    aboutToUpdate: {
        id: 'medic.edit-patient.other.about-to-update',
        defaultMessage: 'You are about to update patient:'
    }
});
