import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Tiredness',
        id: 'indicator.tiredness.title'
    }
});

const choices = defineMessages({
    unaffected: {
        defaultMessage: 'Completely unaffected',
        id: 'indicator.tiredness.choices.1'
    },
    moderate: {
        defaultMessage: 'Tired from more than moderate activity',
        id: 'indicator.tiredness.choices.2'
    },
    light: {
        defaultMessage: 'Tired from light activity',
        id: 'indicator.tiredness.choices.3'
    },
    rest: {
        defaultMessage: 'Tired at rest',
        id: 'indicator.tiredness.choices.4'
    }
});

export const messagesTiredness = {
    general,
    choices
};
