import React, { useMemo } from 'react';
import { Icon, Tabs as AntdTabs } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PatientGroup } from '../../../redux/domains/groups/groups.type';
import { ActivePatient } from '../../../redux/domains/active-patient/active-patient.type';
import { Condition } from '../../../redux/domains/conditions/conditions.type';
import { makeGetIndicatorsForConditionWithSettings } from '../../../redux/domains/indicators/indicators.selectors';
import { RouteMatchType } from './match-type';
import { PatientTabs } from './components/tabs/tabs';
import { ChartsTab } from './components/tabs/ChartsTab';
import { ConversationTab } from './components/tabs/conversations/ConversationTab';
import { HistoryTab } from './components/tabs/history/HistoryTab';
import { LimitsTab } from './components/tabs/limits/LimitsTab';
import { tabs } from './components/tabs/messages-descriptors';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { pathPatientProfileLink } from './components/tabs/path';

export const PatientDataContent = (props: {
    patient: ActivePatient;
    patientGroup: PatientGroup;
    condition: Condition;
    isReadonly: boolean;
}) => {
    const routeHistory = useHistory();

    const activeTab =
        useRouteMatch<RouteMatchType>().params.tab || PatientTabs.charts;

    const isReadonly = props.isReadonly;
    const condition = props.condition;
    const patientGroup = props.patientGroup;
    const patient = props.patient;
    const externalId = patient.externalId;

    const getIndicatorsForConditionWithSettings = useMemo(
        () => makeGetIndicatorsForConditionWithSettings(condition),
        [condition]
    );

    const indicatorsWithSettings = useSelector(
        getIndicatorsForConditionWithSettings
    );

    return (
        <AntdTabs
            type="card"
            activeKey={activeTab}
            onChange={(tab) =>
                routeHistory.push(
                    pathPatientProfileLink(patientGroup.id, externalId, tab)
                )
            }
        >
            <AntdTabs.TabPane
                key={PatientTabs.charts}
                tab={
                    <span>
                        <Icon type="line-chart" />
                        {formatMessage(Strings.ui.tab.charts.title)}
                    </span>
                }
            >
                <ChartsTab
                    patient={patient}
                    condition={condition}
                    indicatorsWithSettings={indicatorsWithSettings}
                />
            </AntdTabs.TabPane>
            <AntdTabs.TabPane
                key={PatientTabs.history}
                tab={
                    <span>
                        <Icon type="history" />
                        {formatMessage(Strings.ui.tab.history.title)}
                    </span>
                }
            >
                <HistoryTab
                    condition={condition}
                    indicators={indicatorsWithSettings}
                />
            </AntdTabs.TabPane>
            <AntdTabs.TabPane
                key={PatientTabs.limits}
                tab={
                    <span>
                        <Icon type="solution" />
                        {formatMessage(Strings.ui.tab.limits.title)}
                    </span>
                }
            >
                <LimitsTab
                    condition={condition}
                    indicators={indicatorsWithSettings}
                    indicatorConfigurations={
                        patient.conditionConfiguration[patientGroup.condition]
                    }
                    isReadonly={isReadonly}
                />
            </AntdTabs.TabPane>
            <AntdTabs.TabPane
                key={PatientTabs.conversation}
                tab={
                    <span>
                        <Icon type="mail" />
                        {formatMessage(tabs.conversation.header.title)}
                    </span>
                }
            >
                <ConversationTab
                    patient={props.patient}
                    patientGroup={props.patientGroup}
                />
            </AntdTabs.TabPane>
        </AntdTabs>
    );
};
