import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Dizziness',
        id: 'indicator.dizziness.title'
    }
});

const choices = defineMessages({
    none: {
        defaultMessage: 'No dizziness',
        id: 'indicator.dizziness.choices.1'
    },
    light: {
        defaultMessage: 'Light dizziness',
        id: 'indicator.dizziness.choices.2'
    },
    moderate: {
        defaultMessage: 'Moderate dizziness',
        id: 'indicator.dizziness.choices.3'
    },
    severe: {
        defaultMessage: 'Severe dizziness',
        id: 'indicator.dizziness.choices.4'
    }
});

export const messagesDizziness = {
    general,
    choices
};
