import { appetite } from '../shared-indicators/appetite/appetite';
import { bloodPressure } from '../shared-indicators/blood-pressure/blood-pressure';
import { bloodSugar } from '../shared-indicators/blood-sugar/blood-sugar';
import { bodyWeight } from '../shared-indicators/body-weight/body-weight';
import { physact } from '../shared-indicators/physact/physact';
import { vas } from '../shared-indicators/vas/vas';
import { conditions } from '../messages';
import { ConditionIndicator } from '../condition.type';
import { formatMessage } from '../../locale/format/format-message';

const code = 'LP_2_0_DIABETES_RS_DEMO_UH';
const publicCode = 'diabetes';

const indicators: ConditionIndicator[] = [
    bloodSugar,
    bloodPressure,
    bodyWeight,
    appetite,
    physact,
    vas
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(conditions.rs.demoDiabetes.name),
        nameLong: formatMessage(conditions.rs.demoDiabetes.nameLong)
    }
};

export default condition;
