import { getQuestionnaireFormValues } from './getQuestionnaireFormValues';
import { IndicatorWithSettings } from '../../../../../../../redux/domains/indicators/indicator.type';
import { getSinglePropertyValues } from './getSinglePropertyValues';
import { getCombinedFormValues } from './getCombinedFormValues';
import { isRequired } from '../utils/isRequired';
import { isProperty } from '../utils/isProperty';

export const getValueColumn = (
    indicator: IndicatorWithSettings,
    keys: string[]
) => {
    const {
        data,
        form,
        code,
        settings: { rules: { decimals = null } = {} } = {}
    } = indicator;

    const required = isRequired(data.jsonSchema);

    if (form && form.type === 'questionnaire') {
        return getQuestionnaireFormValues(indicator, keys);
    } else {
        if (required) {
            const properties = isProperty(data.jsonSchema);
            return getCombinedFormValues(properties, code, decimals);
        } else {
            return getSinglePropertyValues(indicator);
        }
    }
};
