import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/sleep-sci.svg';
import { messagesSleepSCI } from './messages/messages';
import { SleepSCIProperties } from './sleep-sci-properties';
import { rules } from './sleep-sci-rules';

export const parameterSleepSCI = {
    code: 'SLEEP-SCI',
    properties: SleepSCIProperties
};

export const sleepSCI: Indicator = {
    code: parameterSleepSCI.code,
    icon,
    messages: messagesSleepSCI,
    rules
};
