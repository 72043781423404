import { compact } from 'lodash';
import { Requests } from './requests.type';

type State = {
    requests: Requests;
};

type LoadingAction = keyof Requests['loading'];
type ErrorAction = keyof Requests['error'];

export const createLoadingSelector =
    (actions: LoadingAction[]) => (state: State) =>
        actions.some((action) => state.requests.loading[action]);

export const createErrorSelector = (actions: ErrorAction[]) => (state: State) =>
    compact(actions.map((action) => state.requests.error[action]))[0] || '';
