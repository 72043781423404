import { bloodPressure } from '../shared-indicators/blood-pressure/blood-pressure';
import { smoke } from '../shared-indicators/smoke/smoke';
import { physact } from '../shared-indicators/physact/physact';
import { vas } from '../shared-indicators/vas/vas';
import { conditions } from '../messages';
import { ConditionIndicator } from '../condition.type';
import { medication } from './indicators/medication/medication';
import { pain } from './indicators/pain/pain';
import { prnmedCI } from './indicators/prnmed/prnmed';
import { formatMessage } from '../../locale/format/format-message';

const code = 'LP_2_0_CI_MAU';
const publicCode = 'ci';

const indicators: ConditionIndicator[] = [
    prnmedCI,
    medication,
    pain,
    bloodPressure,
    smoke,
    physact,
    vas
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(conditions.mau.ic.name),
        nameLong: formatMessage(conditions.mau.ic.nameLong)
    }
};

export default condition;
