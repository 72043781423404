import indicatorRules from '../../utils/create-condition-settings/indicator-rules';
import { indicatorItem } from '../../utils/create-condition-settings/items';
import { reasonsStatusChange } from '../../utils/create-condition-settings/reasons-status-change';
import { ConditionRules } from '../condition.type';
import { IndicatorMeta } from '../indicator-meta';

export const createDiabetesConfiguration = (): ConditionRules => {
    return {
        initialObservationIndicators: [IndicatorMeta.BODY_WEIGHT.code],
        priorityTable: {
            hidden: [],
            order: [
                IndicatorMeta.BLOOD_SUGAR.code,
                IndicatorMeta.BLOOD_PRESSURE.code,
                IndicatorMeta.BODY_WEIGHT.code,
                IndicatorMeta.APPETITE.code,
                IndicatorMeta.PHYSACT.code,
                IndicatorMeta.VAS.code
            ]
        },
        indicatorSettings: {
            preventFromEditLimits: [],
            preventFromEditReminders: [],
            preventFromToggle: []
        },
        reasons: reasonsStatusChange(),
        indicators: {
            [IndicatorMeta.BLOOD_SUGAR.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BLOOD_SUGAR.code]
            ),
            [IndicatorMeta.BLOOD_PRESSURE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BLOOD_PRESSURE.code]
            ),
            [IndicatorMeta.BODY_WEIGHT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BODY_WEIGHT.code]
            ),
            [IndicatorMeta.APPETITE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.APPETITE.code]
            ),
            [IndicatorMeta.PHYSACT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PHYSACT.code]
            ),
            [IndicatorMeta.VAS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.VAS.code]
            )
        }
    };
};
