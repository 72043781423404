import { RouteItemType } from '../../../../../utils/route/switch-routes';
import { pathCreatePatientStep1 } from './path';
import { Step1Wrapper } from './Step1Wrapper';

export const routeCreatePatientStep1: RouteItemType = {
    name: '',
    link: pathCreatePatientStep1,
    icon: 'user-add',
    component: Step1Wrapper,
    exact: true
};
