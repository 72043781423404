import React from 'react';
import { Form, Formik } from 'formik';
import { isEqual, isNumber, mapValues } from 'lodash';
import { useReduxDispatch } from '../../../../../../../hooks/use-redux-dispatch';
import { useReduxSelector } from '../../../../../../../hooks/use-redux-selector';
import { updateIndicatorConfiguration } from '../../../../../../../redux/domains/active-patient/active-patient.actions';
import { ActivePatient } from '../../../../../../../redux/domains/active-patient/active-patient.type';
import {
    IndicatorConfigurationState,
    JsonSchema
} from '../../../../../../../redux/domains/indicators/indicator.type';
import { getIndicators } from '../../../../../../../redux/domains/indicators/indicators.selectors';

type SingleConfiguration = {
    in_use: boolean;
    lower_red: number | null;
    lower_yellow: number | null;
    upper_yellow: number | null;
    upper_red: number | null;
    type: string;
    report_min: number | null;
    report_max: number | null;
};

type NestedConfiguration = {
    lower_red: number | null;
    lower_yellow: number | null;
    upper_yellow: number | null;
    upper_red: number | null;
    type: string;
    report_min: number | null;
    report_max: number | null;
};

interface MultiConfiguration {
    [key: string]: NestedConfiguration | boolean;
    in_use: boolean;
}

type Configuration = SingleConfiguration | MultiConfiguration;

export interface IndicatorConfiguration {
    indicatorId: string;
    configuration: Configuration;
}

type Props = {
    children: React.ReactElement | React.ReactElement[];
    configuration: IndicatorConfigurationState;
    patient: ActivePatient;
    conditionId: string;
    name: string;
    onDirtyChange?: (name: string, dirty: boolean) => void;
    onSubmit?: (values: any) => void | null;
};

const UpdateConfigurationForm = ({
    children,
    configuration,
    patient,
    conditionId,
    name,
    onDirtyChange,
    onSubmit
}: Props) => {
    const dispatch = useReduxDispatch();

    const dispatchDirtyness = (dirty: boolean) => {
        onDirtyChange && onDirtyChange(name, dirty);
    };

    const defaultSubmit = (values: any, { setSubmitting, resetForm }: any) => {
        dispatch(updateIndicatorConfiguration(patient, values, conditionId))
            .then(() => {
                setSubmitting(false);
                resetForm();
            })
            .catch(() => {
                setSubmitting(false);
                resetForm();
            });
    };

    const jsonSchema = useReduxSelector(
        (state) =>
            getIndicators(state)[configuration.indicatorId].data.jsonSchema
    );

    function toValidLimits<T>(values: T, schema: JsonSchema): T;
    function toValidLimits(
        values: Configuration | NestedConfiguration,
        schema: JsonSchema
    ) {
        if (schema.properties) {
            const properties = schema.properties;
            return mapValues(values, (values, key) =>
                values && typeof values === 'object' && properties[key]
                    ? toValidLimits(values, properties[key])
                    : values
            );
        }
        if ('report_min' in values && isNumber(schema.minimum)) {
            const minimum = schema.minimum;
            return mapValues(values, (value) =>
                isNumber(value) ? Math.max(value, minimum) : value
            );
        }
        return values;
    }

    const initialValues = {
        ...configuration,
        configuration: toValidLimits(configuration.configuration, jsonSchema)
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit || defaultSubmit}
            enableReinitialize={true}
        >
            {(props) => {
                onDirtyChange && dispatchDirtyness(props.dirty);

                return (
                    <Form>
                        {React.Children.map(children, (child) =>
                            React.cloneElement(child, {
                                ...props,
                                dirty:
                                    props.dirty ||
                                    !isEqual(initialValues, configuration)
                            })
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default UpdateConfigurationForm;
