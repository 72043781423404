import { flatMap, pick, sortBy, uniq, zipObject } from 'lodash';
import { createSelector } from 'reselect';
import { ReduxState } from '../../redux-state.type';
import {
    getPatientConditionIds,
    getPatientGroupIds
} from '../active-patient/active-patient.selectors';
import { getConditions } from '../conditions/conditions.selectors';
import { getDefaultMedicGroupId } from '../groups/groups.selectors';
import { GroupTag, PatientGroup } from '../groups/groups.type';

const getPatientGroup = (state: ReduxState, patientGroup: PatientGroup) =>
    patientGroup;

const getPatientConversations = (state: ReduxState) =>
    state.patientData.conversations.patientConversations;

const getCurrentMessages = (state: ReduxState) =>
    state.patientData.conversations.currentMessages;

const getSelectedConversationId = (state: ReduxState) =>
    state.patientData.conversations.selectedConversationId;

export const getSortedMessages = createSelector(
    getCurrentMessages,
    (messages) => sortBy(messages, 'dtCreated')
);

export function getDefaultConversationId(
    state: ReduxState,
    patientGroup: PatientGroup
) {
    const patientConversations = getPatientConversations(state);

    if (!patientConversations) return null;

    const defaultMedicGroupId = getDefaultMedicGroupId(state, patientGroup);

    const conversation =
        (defaultMedicGroupId && patientConversations[defaultMedicGroupId]) ||
        getConversationsForPatientGroup(state, patientGroup)[0];

    return conversation?.id;
}

export const getConversationsForPatientGroup = createSelector(
    [getPatientConversations, getPatientGroup],
    (patientConversations, patientGroup) =>
        patientConversations
            ? patientGroup.managedBy
                  .map((medicGroupId) => patientConversations[medicGroupId])
                  .filter((conversation) => conversation != null)
            : []
);

export const getSelectedConversationForPatientGroup = createSelector(
    [getConversationsForPatientGroup, getSelectedConversationId],
    (conversations, selectedConversationId) =>
        conversations.find(
            (conversation) => conversation.id === selectedConversationId
        )
);

export const getPatientGroups = createSelector(
    (state) => state.patientData.patientGroups,
    getPatientGroupIds,
    (patientGroups, groupIds) =>
        patientGroups && groupIds.every((id) => patientGroups[id])
            ? pick(patientGroups, groupIds)
            : null
);

export const getMedicGroupIds = createSelector(
    getPatientGroups,
    (patientGroups) =>
        uniq(flatMap(patientGroups, (patientGroup) => patientGroup.managedBy))
);

const getMedicGroups = createSelector(
    (state) => state.patientData.medicGroups,
    getMedicGroupIds,
    (medicGroups, groupIds) =>
        medicGroups.filter((medicGroup) => groupIds.includes(medicGroup.id))
);

export const getVisibleMedicGroups = createSelector(
    getMedicGroups,
    (medicGroups) =>
        medicGroups.filter(
            ({ tags }) =>
                tags.includes(GroupTag.Medic) &&
                !tags.includes(GroupTag.MedicAdmin)
        )
);

const getPatientGroupsArray = createSelector(
    getPatientGroups,
    (patientGroups) => (patientGroups ? Object.values(patientGroups) : [])
);

const getPatientConditions = createSelector(
    getConditions,
    getPatientConditionIds,
    (conditions, ids) => Object.values(pick(conditions, ids))
);

export const getConditionsByMedicGroup = createSelector(
    getMedicGroupIds,
    getPatientGroupsArray,
    getPatientConditions,
    (medicGroupsIds, patientGroups, conditions) =>
        zipObject(
            medicGroupsIds,
            medicGroupsIds.map((medicGroupId) =>
                conditions.filter((condition) =>
                    patientGroups.some(
                        (patientGroup) =>
                            patientGroup.condition === condition.id &&
                            patientGroup.managedBy.includes(medicGroupId)
                    )
                )
            )
        )
);
