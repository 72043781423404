import React from 'react';
import { BaseButton } from './BaseButton';
import { ButtonProps } from 'antd/lib/button';

const AppPrimaryButton = (props: ButtonProps) => (
    <BaseButton type="primary" {...props}>
        {props.children}
        {props.title}
    </BaseButton>
);

export { AppPrimaryButton };
