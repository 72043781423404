import React from 'react';
import { Tabs } from 'antd';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { useHistory, useParams } from 'react-router-dom';
import { pathMedicOverview } from '../path';
import { labels } from '../../../../locale/messagesDescriptors/labels';
import { formatMessage } from '../../../../locale/format/format-message';
import { MedicGroupsById } from '../../../../redux/domains/groups/groups.type';
import { sortMedicGroups } from '../../../../utils/sort/sort-medic-groups';

export const medicGroupListId = 'medicGroupListId';

export const MedicGroupList = (): JSX.Element => {
    const routeParams = useParams<{ medicGroupId: string }>();
    const routeHistory = useHistory();
    const medicGroups = useReduxSelector(
        (state) => state.groups.medicGroups.byId
    );

    const medicGroupIds = sortMedicGroupsById(medicGroups);
    const selectedMedicGroup = routeParams.medicGroupId;

    const { TabPane } = Tabs;

    if (!selectedMedicGroup) {
        routeHistory.replace(`${pathMedicOverview}/${medicGroupIds[0]}`);
    }

    if (medicGroupIds.length === 1) {
        return <></>;
    }

    return (
        <div id={medicGroupListId}>
            <h2 style={{ marginLeft: 24, marginBottom: 24 }}>
                {formatMessage(labels.label.medicGroup)}
            </h2>
            <Tabs
                activeKey={selectedMedicGroup}
                tabPosition="left"
                onChange={(activeKey) =>
                    routeHistory.replace(`${pathMedicOverview}/${activeKey}`)
                }
            >
                {medicGroupIds.map((key) => (
                    <TabPane
                        key={key}
                        tab={
                            <div
                                style={{
                                    textAlign: 'left'
                                }}
                            >
                                {medicGroups[key].name}
                            </div>
                        }
                    />
                ))}
            </Tabs>
        </div>
    );
};

export function sortMedicGroupsById(medicGroups: MedicGroupsById) {
    const sortedMedicGroups = sortMedicGroups(medicGroups);

    return sortedMedicGroups.map((medicGroup) => medicGroup.id);
}
