import { combineReducers } from 'redux';
import { FETCH_PRIORITY_LIST_FULFILLED } from './priority.constants';
import {
    FETCH_WATCHOVERS_FULFILLED,
    REGISTER_WATCHOVER_FULFILLED
} from '../watchovers/watchovers.constants';
import { SET_SELECTED_MEDIC_GROUP } from '../groups/groups.constants';
import { Priority, PriorityPatientWithIndicators } from './priority.type';
import { createReducer } from '@reduxjs/toolkit';
import { fetchPriorityListByGroupId } from './priority.actions';

const initialState: Priority = {
    byGroupId: {},
    lastUpdated: {},
    watchovers: {}
};
const initialPriorityState:
    | PriorityPatientWithIndicators[]
    | Record<string, never> = [];

export const priorityByGroupReducer = createReducer<
    PriorityPatientWithIndicators[]
>(initialPriorityState, (builder) => {
    builder.addCase(
        fetchPriorityListByGroupId.fulfilled,
        (state, { payload }) => {
            return (state = payload);
        }
    );
});

function addWatchoverEntries(state = initialState.watchovers, action: any) {
    const { payload } = action;
    const { users, groupId } = payload[0];

    const patients = { ...state[groupId] };

    users.forEach((user: any) => {
        const { externalId: id, watchovers } = user;

        patients[id] = watchovers[0];
    });

    return { ...state, [groupId]: patients };
}

function addWatchoverEntry(state = initialState.watchovers, action: any) {
    const { recipientId, reporterId } = action.payload; // reporterId === PatientGroupId

    return {
        ...state,
        [reporterId]: {
            ...state[reporterId],
            [recipientId]: action.payload
        }
    };
}

function byGroupId(state = initialState.byGroupId, action: any) {
    const { payload, type } = action;

    switch (type) {
        case FETCH_PRIORITY_LIST_FULFILLED: {
            const { groupId, patients } = payload;
            return {
                ...state,
                [groupId]: patients
            };
        }
        default:
            return state;
    }
}

function lastUpdated(state = initialState.lastUpdated, action: any) {
    const { payload, type } = action;

    switch (type) {
        case FETCH_PRIORITY_LIST_FULFILLED: {
            const { groupId } = payload;

            return { ...state, [groupId]: Date.now() };
        }
        default:
            return state;
    }
}

function watchovers(state = initialState.watchovers, action: any) {
    const { type } = action;

    switch (type) {
        case FETCH_WATCHOVERS_FULFILLED: {
            return addWatchoverEntries(state, action);
        }
        case REGISTER_WATCHOVER_FULFILLED: {
            return addWatchoverEntry(state, action);
        }
        default:
            return state;
    }
}

const combined = combineReducers({
    byGroupId,
    lastUpdated,
    watchovers
});

function priorityReducer(state: Priority | undefined, action: any) {
    if (action.type === SET_SELECTED_MEDIC_GROUP) {
        return initialState;
    }

    return combined(state, action);
}

export default priorityReducer;
