import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Pump power',
        id: 'indicator.pump-power-lvad.title'
    },
    unit: {
        id: 'indicator.pump-power-lvad.unit',
        defaultMessage: 'W'
    },
    value: {
        defaultMessage: '{value, number, ::.0}',
        id: 'indicator.pump-power-lvad.value'
    }
});

export const messagesPumpPowerLvad = {
    general
};
