import Highcharts from 'highcharts';
import moment from 'moment';

import { daysInMilliseconds } from './regression';
import { CustomSeriesSplineDataOptions } from '../chart-types';

import { Duration, GraphDurationSteps } from '../duration';
import { formatNumber } from '../../../../locale/format/format-number';

const dateTimeLabelFormats = {
    millisecond: '%H:%M',
    second: '%H:%M',
    day: '%e %b',
    week: '%e %b',
    month: '%b %Y'
};

export const generateInitialTrendGraphOptions = (): Highcharts.Options => {
    return {
        title: { text: '' },
        credits: { enabled: false },
        xAxis: {
            type: 'datetime',
            labels: {
                rotation: -45
            },
            tickInterval: daysInMilliseconds(1),
            endOnTick: true,
            dateTimeLabelFormats
        },
        yAxis: {
            title: {
                text: null
            }
        },
        plotOptions: {
            series: {
                showInLegend: false,
                states: {
                    hover: {
                        enabled: false
                    },
                    inactive: {
                        opacity: 1
                    }
                },
                marker: {
                    enabled: true
                },
                animation: {
                    duration: 300
                }
            }
        },
        tooltip: {
            formatter() {
                const date = `<strong>${moment(this.x).format('L')}</strong>`;
                const point = this.point
                    .options as CustomSeriesSplineDataOptions;

                const value =
                    point.customUnit ||
                    `${formatNumber(this.y || 0)} ${point.unit || ''}`;

                return `${date}<br>${value}`;
            }
        }
    };
};

export function generateTrendChartOptions(props: {
    series: Highcharts.SeriesSplineOptions[];
    startDate: number;
    endDate: number;
    duration: Duration;
    fixedYAxisMin: number | string | null;
    fixedYAxisMax: number | string | null;
    tickInterval: number | undefined;
    prevOptions: Highcharts.Options;
}): Highcharts.Options {
    const yAxisMin =
        typeof props.fixedYAxisMin === 'number' ? props.fixedYAxisMin : null;
    const yAxisMax =
        typeof props.fixedYAxisMax === 'number' ? props.fixedYAxisMax : null;
    return {
        ...props.prevOptions,
        series: props.series,
        xAxis: {
            ...props.prevOptions.xAxis,
            min: props.startDate,
            max: props.endDate,
            labels: {
                ...(props.prevOptions.xAxis as Highcharts.XAxisOptions).labels,
                step: GraphDurationSteps[props.duration]
            }
        },
        yAxis: {
            ...props.prevOptions.yAxis,
            min: yAxisMin,
            max: yAxisMax,
            tickInterval: props.tickInterval
        }
    };
}
