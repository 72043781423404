import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Reminders',
        id: 'reminders.title'
    },
    interval: {
        id: 'reminders.interval',
        defaultMessage:
            'How many days shall pass until the next reminder is sent?'
    },
    timeToFirstReminder: {
        id: 'reminders.time-to-first-reminder',
        defaultMessage:
            'After how many days without reporting shall the patient receive a reminder?'
    }
});

export const reminders = {
    general
};
