import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'CAT',
        id: 'indicator.cat.title'
    },
    titleShort: {
        defaultMessage: 'CAT',
        id: 'indicator.cat.title-short'
    },
    titleLong: {
        defaultMessage: 'COPD Assessment Test',
        id: 'indicator.cat.title-long'
    },
    description: {
        defaultMessage:
            'This questionnaire will help you and your healthcare professional measure the impact COPD (Chronic Obstructive Pulmonary Disease) is having on your wellbeing and daily life.\n\nYour answers and test score, can be used by you and your healthcare professional to help improve the management of your COPD and get the greatest benefit from treatment.',
        id: 'indicator.cat.description'
    },
    helptext: {
        defaultMessage:
            'Please indicate your answer on each of the following questions by moving the slider to select the number that best describes your current situation',
        id: 'indicator.cat.helptext'
    },
    legaltext: {
        defaultMessage:
            'The COPD Assessment Test was developed by a multi-disciplinary group of international experts in COPD supported by GSK. GSK activities with respect to the COPD Assessment Test are overseen by a governance board that includes independent external experts, one of whom chairs the board.',
        id: 'indicator.cat.legaltext'
    },
    copyright: {
        defaultMessage:
            'COPD Assessment Test and the CAT logo is a trademark of the GlaxoSmithKline group of companies. © 2009 GlaxoSmithKline. All rights reserved.',
        id: 'indicator.cat.copyright'
    },
    sum: {
        defaultMessage: 'Sum',
        id: 'indicator.cat.sum.title'
    }
});

const cough = defineMessages({
    title: {
        defaultMessage: 'Cough',
        id: 'indicator.cat.cough.title'
    },
    choiceTitle: {
        defaultMessage: 'Cough',
        id: 'indicator.cat.cough.choices.title'
    },
    reportMaxText: {
        defaultMessage: 'I cough all the time',
        id: 'indicator.cat.cough.max_text'
    },
    reportMinText: {
        defaultMessage: 'I never cough',
        id: 'indicator.cat.cough.min_text'
    }
});

const phlegm = defineMessages({
    title: {
        defaultMessage: 'Phlegm',
        id: 'indicator.cat.phlegm.title'
    },
    choiceTitle: {
        defaultMessage: 'Phlegm',
        id: 'indicator.cat.phlegm.choices.title'
    },
    reportMaxText: {
        defaultMessage: 'My chest is completely full of phlegm (mucus)',
        id: 'indicator.cat.phlegm.max_text'
    },
    reportMinText: {
        defaultMessage: 'I have no phlegm (mucus) in my chest at all',
        id: 'indicator.cat.phlegm.min_text'
    }
});

const chest = defineMessages({
    title: {
        defaultMessage: 'Chest',
        id: 'indicator.cat.chest.title'
    },
    choiceTitle: {
        defaultMessage: 'Chest',
        id: 'indicator.cat.chest.choices.title'
    },
    reportMaxText: {
        defaultMessage: 'My chest feels very tight',
        id: 'indicator.cat.chest.max_text'
    },
    reportMinText: {
        defaultMessage: 'My chest does not feel tight at all',
        id: 'indicator.cat.chest.min_text'
    }
});

const breathlessness = defineMessages({
    title: {
        defaultMessage: 'Breathlessness',
        id: 'indicator.cat.breathlessness.title'
    },
    choicesTitle: {
        defaultMessage: 'Breathlessness',
        id: 'indicator.cat.breathlessness.choices.title'
    },
    reportMaxText: {
        defaultMessage:
            'When I walk up a hill or one flight of stairs I am very breathless',
        id: 'indicator.cat.breathlessness.max_text'
    },
    reportMinText: {
        defaultMessage:
            'When I walk up a hill or one flight of stairs I am not breathless',
        id: 'indicator.cat.breathlessness.min_text'
    }
});

const limitation = defineMessages({
    title: {
        defaultMessage: 'Limitation',
        id: 'indicator.cat.limitation.title'
    },
    choicesTitle: {
        defaultMessage: 'Limitation',
        id: 'indicator.cat.limitation.choices.title'
    },
    reportMaxText: {
        defaultMessage: 'I am very limited doing activities at home',
        id: 'indicator.cat.limitation.max_text'
    },
    reportMinText: {
        defaultMessage: 'I am not limited doing any activities at home',
        id: 'indicator.cat.limitation.min_text'
    }
});

const confidence = defineMessages({
    title: {
        defaultMessage: 'Confidence',
        id: 'indicator.cat.confidence.title'
    },
    choicesTitle: {
        defaultMessage: 'Confidence',
        id: 'indicator.cat.confidence.choices.title'
    },
    reportMaxText: {
        defaultMessage:
            'I am not at all confident leaving my home because of my lung condition',
        id: 'indicator.cat.confidence.max_text'
    },
    reportMinText: {
        defaultMessage:
            'I am confident leaving my home despite my lung condition',
        id: 'indicator.cat.confidence.min_text'
    }
});

const sleep = defineMessages({
    title: {
        defaultMessage: 'Sleep',
        id: 'indicator.cat.sleep.title'
    },
    choicesTitle: {
        defaultMessage: 'Sleep',
        id: 'indicator.cat.sleep.choices.title'
    },
    reportMaxText: {
        defaultMessage: "I don't sleep soundly because of my lung condition",
        id: 'indicator.cat.sleep.max_text'
    },
    reportMinText: {
        defaultMessage: 'I sleep soundly',
        id: 'indicator.cat.sleep.min_text'
    }
});

const energy = defineMessages({
    title: {
        defaultMessage: 'Energy',
        id: 'indicator.cat.energy.title'
    },
    choicesTitle: {
        defaultMessage: 'Energy',
        id: 'indicator.cat.energy.choices.title'
    },
    reportMaxText: {
        defaultMessage: 'I have no energy at all',
        id: 'indicator.cat.energy.max_text'
    },
    reportMinText: {
        defaultMessage: 'I have lots of energy',
        id: 'indicator.cat.energy.min_text'
    }
});

export const messagesCat = {
    general,
    breathlessness,
    chest,
    confidence,
    cough,
    energy,
    limitation,
    phlegm,
    sleep
};
