import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { useHistory } from '../../../../../hooks/use-router';
import { Roles } from '../../../../../redux/domains/user/user.api.type';
import {
    createBasicMedic,
    createMedicGroups
} from '../../../../../redux/domains/medic/medic.actions';
import { useReduxSelector } from '../../../../../hooks/use-redux-selector';
import { fetchGroups } from '../../../../../redux/domains/groups/groups.actions';
import { routeMedicOverview } from '../../../overview/route';
import { Modal } from 'antd';
import {
    CreateBasicAuthMedic,
    CreateBasicAuthMedicResponse
} from '../../../../../redux/domains/user/user.api.create.type';
import { messages } from '../../../messages';
import { getAdminGroupsForCurrentUser } from '../../../../../redux/domains/groups/groups.selectors';
import { createMedicMessages } from '../../messages';
import { asyncForEach, waitFor } from '../../../../../utils/wait-for';
import { StyledFlexWrapper, StyledFullCol } from '../../styled';
import { ModalDetailRow } from '../ModalDetailRow';
import { GroupSelectorTable } from '../GroupSelectorTable';
import { CreateMedicModalFooter } from '../ModalCreateMedicFooter';
import { uniq } from 'lodash';

export const CreateMedicBasicModal = (props: {
    pendingMedicUser: CreateBasicAuthMedic;
    setSubmitModalVisible: (visible: boolean) => void;
}): JSX.Element => {
    const dispatch = useDispatch<any>();
    const routeHistory = useHistory();

    const { pendingMedicUser, setSubmitModalVisible } = props;

    const [pendingBasicGroups, setPendingBasicGroups] = useState<string[]>([]);
    const [pendingAdminGroups, setPendingAdminGroups] = useState<string[]>([]);

    const [waitingOnDispatch, setWaitingOnDispatch] = useState(false);

    const allMedicGroups = useReduxSelector(
        (state) => state.groups.medicGroups.byId
    );

    const adminGroupsForCurrentUser = useReduxSelector(
        getAdminGroupsForCurrentUser
    );

    const disabled = !pendingBasicGroups.length && !pendingAdminGroups.length;

    return (
        <Modal
            title={formatMessage(createMedicMessages.comfirm.title)}
            visible={true}
            width={800}
            footer={
                <CreateMedicModalFooter
                    loading={waitingOnDispatch}
                    disabled={disabled}
                    onSubmit={onModalSubmit}
                    afterSubmit={afterSubmit}
                    onCancel={onModalCancel}
                />
            }
        >
            <StyledFlexWrapper>
                <StyledFullCol>
                    <ModalDetailRow
                        label={formatMessage(messages.registerMedic.coworker)}
                        text={`${pendingMedicUser.firstName} ${pendingMedicUser.lastName}`}
                    />
                    <ModalDetailRow
                        label={formatMessage(Strings.labels.label.email)}
                        text={pendingMedicUser.email}
                    />
                    <ModalDetailRow
                        label={formatMessage(
                            Strings.editPatient.fields.phoneNumber.label
                        )}
                        text={pendingMedicUser.phoneNumber || ''}
                    />
                    <ModalDetailRow
                        label={formatMessage(Strings.labels.label.title)}
                        text={pendingMedicUser.medicTitle || ''}
                    />
                </StyledFullCol>
            </StyledFlexWrapper>

            <GroupSelectorTable
                adminGroupsForCurrentUser={adminGroupsForCurrentUser}
                allMedicGroups={allMedicGroups}
                setPendingBasicGroups={setPendingBasicGroups}
                pendingBasicGroups={pendingBasicGroups}
                setPendingAdminGroups={setPendingAdminGroups}
                pendingAdminGroups={pendingAdminGroups}
                waitingOnDispatch={waitingOnDispatch}
            />
        </Modal>
    );

    function navigateTo(path: string) {
        routeHistory.push(path);
    }

    function onModalCancel() {
        setSubmitModalVisible(false);
    }

    async function onModalSubmit() {
        setWaitingOnDispatch(true);

        if (typeof pendingMedicUser.password !== 'string') {
            throw 'password is missing';
        }

        const postUser: CreateBasicAuthMedic = {
            firstName: pendingMedicUser.firstName,
            lastName: pendingMedicUser.lastName,
            email: pendingMedicUser.email,
            phoneNumber: pendingMedicUser.phoneNumber || undefined,
            groups: uniq([...pendingAdminGroups, ...pendingBasicGroups]),
            medicTitle: pendingMedicUser.medicTitle || undefined,
            password: pendingMedicUser.password
        };

        if (pendingAdminGroups.length) {
            await dispatchBasicMedicWhenAdmin({
                postUser,
                basicMedicGroups: pendingBasicGroups,
                adminGroups: pendingAdminGroups
            });
            return;
        }

        await dispatchBasicMedicWhenOnlyMedic({
            postUser
        });
    }

    async function dispatchBasicMedicWhenAdmin(params: {
        postUser: CreateBasicAuthMedic;
        basicMedicGroups: string[];
        adminGroups: string[];
    }) {
        const { postUser, adminGroups, basicMedicGroups } = params;

        await dispatch(createBasicMedic(postUser)).then(
            async (response: { value: CreateBasicAuthMedicResponse }) => {
                const externalId = response.value.externalId;

                await asyncForEach(adminGroups, async (groupId) => {
                    const roles = [];

                    if (basicMedicGroups.includes(groupId)) {
                        roles.push(Roles.medic);
                    }

                    if (adminGroups.includes(groupId)) {
                        roles.push(Roles.admin);
                    }

                    await dispatch(
                        createMedicGroups({
                            medicId: externalId,
                            groupId: groupId,
                            data: { roles }
                        })
                    );
                });

                await waitFor(3000); // Waiting for BE data to propagate
            }
        );
    }

    async function dispatchBasicMedicWhenOnlyMedic(params: {
        postUser: CreateBasicAuthMedic;
    }) {
        const { postUser } = params;

        await dispatch(createBasicMedic(postUser));
        await waitFor(3000); // Waiting for BE data to propagate
    }

    function afterSubmit() {
        dispatch(fetchGroups())
            .then(() => navigateTo(routeMedicOverview.link))
            .catch((error: string) => {
                console.error('error in updateMedicGroups', error);
            });
    }
};
