import { defineMessages } from 'react-intl';

const general = defineMessages({
    activatedBy: {
        id: 'medic.events.activatedBy',
        defaultMessage: 'User activated by {practitioner}'
    },
    inActivatedBy: {
        id: 'medic.events.inActivatedBy',
        defaultMessage: 'User inactivated by {practitioner}'
    },
    watchedOver: {
        id: 'medic.events.watchedOver',
        defaultMessage: 'Checked by {practitioner}'
    },
    resetBy: {
        id: 'medic.events.resetBy',
        defaultMessage: 'Reset by {practitioner}'
    },
    fetchingInformation: {
        id: 'medic.events.fetchingInformation',
        defaultMessage: 'Fetching information...'
    },
    register: {
        id: 'medic.events.register',
        defaultMessage: 'Registering...'
    }
});

export const events = {
    general,
    rootId: 'medic.events.'
};
