import moment from 'moment';

type ValueTuple = [number, number | boolean];

type SumByDay = {
    [key: string]: number;
};

export const sumByDay = (values: ValueTuple[]) => {
    const valuesByDay = values.reduce((acc: SumByDay, [date, value]) => {
        const day = moment(date).startOf('day').valueOf();

        acc[day] = (acc[day] || 0) + Number(value);
        return acc;
    }, {});

    return Object.keys(valuesByDay).map(
        (day): ValueTuple => [Number(day), valuesByDay[day]]
    );
};
