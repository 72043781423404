export function isStrings(array?: any): array is string[] {
    if (array) {
        if (!isString(array)) {
            return typeof array[0] === 'string';
        }
    }

    return false;
}

export function isString(str: any): str is string {
    return typeof str === 'string';
}
