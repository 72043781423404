import {
    SeriesData,
    DateAndNumberTuple,
    DateAndObjTuple
} from '../chart-types';
import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import {
    getMultiPropertiesSeriesData,
    mapIndicatorProperties
} from './getMultiPropertiesSeriesData';
import { getSingleValueSeriesData } from './getSingleValueSeriesData';

export const createReportAndRegressionSeries = (props: {
    indicator: IndicatorWithSettings;
    data: DateAndNumberTuple[] | DateAndObjTuple[];
}): SeriesData[] => {
    const code = props.indicator.code;
    const lowerCaseCode = props.indicator.code.toLowerCase();
    const data = props.data;

    const dataValueIsNumberType = data.map(
        ([date, value]) => typeof value === 'number'
    );

    return dataValueIsNumberType[0]
        ? getSingleValueSeriesData(dataValueIsTypeNumber(data), lowerCaseCode)
        : getMultiPropertiesSeriesData(
              mapIndicatorProperties(dataValueIsTypeObj(data), code),
              lowerCaseCode
          );
};

export function dataValueIsTypeNumber(
    data: DateAndNumberTuple[] | DateAndObjTuple[]
): DateAndNumberTuple[] {
    return data.map((item) => {
        if (typeof item[1] === 'number') {
            return [item[0], item[1]] as DateAndNumberTuple;
        } else {
            throw new Error('Data is of wrong type');
        }
    });
}

export function dataValueIsTypeObj(
    data: DateAndNumberTuple[] | DateAndObjTuple[]
): DateAndObjTuple[] {
    return data.map((item) => {
        if (typeof item[1] !== 'number') {
            return [item[0], item[1]] as DateAndObjTuple;
        } else {
            throw new Error('Data is of wrong type');
        }
    });
}
