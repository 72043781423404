import React, { Fragment } from 'react';
import { Divider } from 'antd';
import { useReduxSelector } from '../../../../../../../../../../hooks/use-redux-selector';

import SliderFormGroup from './SliderFormGroup';
import { formatMessageById } from '../../../../../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../../../../../locale/messagesDescriptors';

const SliderFormGroupWrapper = ({
    values,
    setFieldValue,
    indicatorMeta,
    handleChange,
    initialValues,
    resetForm,
    rules
}: any) => {
    const jsonSchema = useReduxSelector(
        (state) => state.indicators.byId[values.indicatorId].data.jsonSchema
    );
    const { properties, code } = indicatorMeta;
    const { boundariesConfiguration = {} } = rules;
    const { disabledProperties = [] } = boundariesConfiguration;

    return (
        <>
            {properties ? (
                Object.keys(properties)
                    .filter((prop) => !disabledProperties.includes(prop))
                    .map((prop, i, arr) => (
                        <Fragment key={prop}>
                            <h4 style={{ marginBottom: 10 }}>
                                {formatMessageById(
                                    `${
                                        Strings.indicators.rootId
                                    }${code.toLowerCase()}.${prop}.title`
                                )}
                            </h4>
                            <SliderFormGroup
                                key={prop}
                                setFieldValue={setFieldValue}
                                resetForm={resetForm}
                                prefix={prop}
                                configuration={values.configuration[prop]}
                                handleChange={handleChange}
                                initialValues={initialValues}
                                rules={rules}
                                jsonSchema={
                                    jsonSchema.properties &&
                                    jsonSchema.properties[prop]
                                }
                            />
                            {i < arr.length - 1 && <Divider />}
                        </Fragment>
                    ))
            ) : (
                <SliderFormGroup
                    setFieldValue={setFieldValue}
                    resetForm={resetForm}
                    configuration={values.configuration}
                    handleChange={handleChange}
                    initialValues={initialValues}
                    rules={rules}
                    jsonSchema={jsonSchema}
                    key="1"
                />
            )}
        </>
    );
};

export default SliderFormGroupWrapper;
