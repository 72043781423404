import { LayoutType } from './layout.type';
import { deviceWidth } from './media-queries';

export const layout: LayoutType = {
    header: {
        height: 52,
        border: {
            size: 1
        }
    },
    mediaQuerySize: {
        width: deviceWidth
    }
};
