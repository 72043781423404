import { Fields, InputField, isInputField, isRadioGroupField } from './types';
import { isValidSwedishSSN } from '../../../utils/validate/ssn/is-valid-swedish-ssn';
import { validation } from '../../../components/form/inputs/message-descriptors';
import { formatMessage } from '../../../locale/format/format-message';

export function hasRequiredFields(fields: Fields): boolean {
    return Object.keys(fields).some((key) => fields[key].required);
}

export function anyFieldValidAsRequired(fields: Fields): boolean {
    return Object.keys(fields).some((key) => {
        const field = fields[key];
        if (isInputField(field)) {
            return field.isValid && field.value.length > 0;
        }
        if (isRadioGroupField(field)) {
            throw Error('Not implemented');
        }
        throw Error('Unknown field type');
    });
}

export function isEveryFieldValid(fields: Fields): boolean {
    return Object.keys(fields).every((key) => {
        const field = fields[key];
        if (isInputField(field)) {
            return field.isValid;
        }
        if (isRadioGroupField(field)) {
            throw Error('Not implemented');
        }
        throw Error('Unknown field type');
    });
}

export function isUnrequiredFieldsValid(fields: Fields): boolean {
    return anyFieldValidAsRequired(fields) && isEveryFieldValid(fields);
}

export function validatePartialSsn(field: InputField): string | null {
    const ssnRegex = new RegExp('^\\d{1,12}$');
    if (field.value.length > 0 && !ssnRegex.exec(field.value)) {
        return formatMessage(validation.invalidSsn);
    }

    return null;
}

export function validateSsn(
    field: InputField,
    checkForDash?: boolean
): string | null {
    if (!isValidSwedishSSN(field.value, checkForDash)) {
        return formatMessage(validation.invalidSsn);
    }

    return null;
}
