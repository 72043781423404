import React from 'react';
import ReactECharts from 'echarts-for-react';
import { getOptionVAS } from './getOptionVAS';
import { ModifiedDataType } from '../../../redux/domains/statistics/statistics.type';

export function PieChartVAS(props: { data: ModifiedDataType[] }): JSX.Element {
    return (
        <ReactECharts
            style={{
                width: '450px'
            }}
            option={getOptionVAS(props)}
            notMerge={true}
            lazyUpdate={true}
        />
    );
}
