import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Liquid measurement',
        id: 'indicator.liquid-measurement.title'
    },
    values: {
        defaultMessage: 'Liquid measurement',
        id: 'indicator.liquid-measurement.values'
    }
});

export const messagesLiquidMeasurement = {
    general
};
