import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Pain during activity',
        id: 'indicator.pain-during-activity-hs.title'
    }
});

export const messagesPainDuringActivity = {
    general
};
