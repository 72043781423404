const ssnRegexCentury = '(19|20)';
const ssnRegexYear = '([0-9]{2})';
const ssnRegex31DaysInMonth = '(?:0[13578]|1[02])(?:0[1-9]|[12][0-9]|3[01])';
const ssnRegex30DaysInMonth = '(?:0[469]|11)(?:0[1-9]|[12][0-9]|30)';
const ssnRegex29DaysInMonth = '(?:02(?:0[1-9]|[12][0-9]))';
const ssnRegexMonthAndDay = `(${ssnRegex31DaysInMonth}|${ssnRegex30DaysInMonth}|${ssnRegex29DaysInMonth})`;
const ssnRegexBirthPlace = '([0-9]{2})';
const ssnRegexSex = '([0-9]{1})';
const ssnRegexControlNumber = '([0-9]{1})';

const ssnRegex = new RegExp(
    `^${ssnRegexCentury}${ssnRegexYear}${ssnRegexMonthAndDay}-?${ssnRegexBirthPlace}${ssnRegexSex}${ssnRegexControlNumber}$`
);

export function splitSSNWithRegExp(ssn: string) {
    return ssnRegex.exec(ssn);
}
