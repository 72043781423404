export function getUpdatedPendingGroups(params: {
    checked: boolean;
    pendingGroups: string[];
    groupId: string;
}) {
    const { checked, pendingGroups, groupId } = params;

    if (checked) {
        return [...pendingGroups, groupId];
    }

    return pendingGroups.filter((pendingId) => pendingId !== groupId);
}
