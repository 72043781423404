import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/pain-at-rest.svg';
import { messagesPainAtRest } from './messages/at-rest';
import { rules } from './pain-at-rest-hs-rules';

export const parameterPainAtRestHs = {
    code: 'PAIN-AT-REST-HS'
};

export const painAtRest: Indicator = {
    code: parameterPainAtRestHs.code,
    icon,
    messages: messagesPainAtRest,
    rules
};
