import { combineReducers } from 'redux';
import { Requests } from './requests.type';

const initialState: Requests = {
    loading: {},
    error: {}
};

const loading = (state = initialState.loading, action: { type: string }) => {
    const { type } = action;

    const matches = /(.*)_(PENDING|FULFILLED|REJECTED)/.exec(type);
    const matchesReduxToolkit = /(.*)\/(pending|fulfilled|rejected)/.exec(type);

    let _state = {
        ...state
    };

    if (matches) {
        const [, requestName, requestState] = matches;
        _state = {
            ...state,
            [requestName]: requestState === 'PENDING'
        };
    }

    if (matchesReduxToolkit) {
        const [, requestName, requestState] = matchesReduxToolkit;
        _state = {
            ...state,
            [requestName]: requestState === 'pending'
        };
    }

    return _state;
};

const error = (state = initialState.error, action: any) => {
    const { type, payload = {} } = action;
    const matches = /(.*)_(PENDING|REJECTED)/.exec(type);
    const matchesReduxToolkit = /(.*)\/(pending|rejected)/.exec(type);

    let _state = {
        ...state
    };

    if (matches) {
        const error = payload?.response
            ? payload?.response.data
            : payload?.message || '';

        const [, requestName, requestState] = matches;

        _state = {
            ...state,
            [requestName]: requestState === 'REJECTED' ? error : ''
        };
    }

    if (matchesReduxToolkit) {
        const error = payload?.response
            ? payload?.response.data
            : payload?.message || '';

        const [, requestName, requestState] = matchesReduxToolkit;

        _state = {
            ...state,
            [requestName]: requestState === 'rejected' ? error : ''
        };
    }

    return _state;
};

const requestsReducer = combineReducers({
    loading,
    error
});

export default requestsReducer;
