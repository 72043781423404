import { Indicator } from '../../../types/indicator/indicator.type';
import { rules } from './fish-rules';

import icon from './images/fish.svg';
import { messagesFish } from './messages/messages';

export const parameterFish = {
    code: 'FISH'
};

export const fish: Indicator = {
    code: parameterFish.code,
    icon,
    messages: messagesFish,
    rules
};
