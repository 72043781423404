import { defineMessages } from 'react-intl';

const page = defineMessages({
    title: {
        id: 'patient-search.page.title',
        defaultMessage: 'Search Patient'
    }
});

const tabs = defineMessages({
    groupSearch: {
        id: 'patient-search.tabs.group-search',
        defaultMessage: 'Search in my groups & clinics'
    },
    systemSearch: {
        id: 'patient-search.tabs.system-search',
        defaultMessage: 'Search in system (even outside my groups)'
    }
});

export const patientSearch = {
    tabs,
    page
};
