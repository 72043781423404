import React from 'react';

import { BackButtonWithArrow } from '../../../../../components/ui/buttons/BackButtonWithArrow';
import { VideoWrapper } from './styled';

export const VideoModule = (props: { path: string; navBack: () => void }) => {
    return (
        <VideoWrapper>
            <div>
                <BackButtonWithArrow
                    style={{
                        display: 'inline-block',
                        marginBottom: 22
                    }}
                    onClick={() => props.navBack()}
                />
            </div>
            <video
                controls
                controlsList="nodownload"
                disablePictureInPicture
                src={props.path}
                crossOrigin=""
                width="1000px"
            />
        </VideoWrapper>
    );
};
