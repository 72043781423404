import React, { useState } from 'react';
import { Card } from 'antd';
import { ContentLayout } from '../../../components/content/ContentLayout';

import { VideoModule } from './components/video-module/VideoModule';
import { EducationModule } from './components/education-module/EducationModule';
import { titleEducation } from './title';
import { usePageTitle } from '../../../hooks/use-page-title';

export const EducationPage = (): JSX.Element => {
    const [showVideo, setShowVideo] = useState(false);
    const [videoPath, setVideoPath] = useState('');
    usePageTitle(titleEducation);

    return (
        <ContentLayout>
            <Card>
                {showVideo ? (
                    <VideoModule
                        path={videoPath}
                        navBack={() => {
                            setShowVideo(false);
                            setVideoPath('');
                        }}
                    />
                ) : (
                    <EducationModule
                        handleClick={(path: string) => {
                            setVideoPath(path);
                            setShowVideo(true);
                        }}
                    />
                )}
            </Card>
        </ContentLayout>
    );
};
