import React from 'react';
import ReactECharts from 'echarts-for-react';
import { getOption } from './getOption';
import { Patients } from '../../../redux/domains/statistics/statistics.type';

export function BarChart(props: { patients: Patients }) {
    return (
        <ReactECharts
            style={{
                width: '100%'
            }}
            option={getOption(props.patients)}
            notMerge={true}
            lazyUpdate={true}
            theme="theme_name"
        />
    );
}
