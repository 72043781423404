import { AuthMethods } from '../../../constants/auth-methods';
import { Reasons } from '../../../types/reasons.enum';
import {
    getUser,
    postBasicMedic,
    postSithsMedic,
    putMedic,
    putMedicGroups,
    toggleUser
} from '../user/user.api';
import { Roles, UpdateMedic } from '../user/user.api.type';
import { MedicExternalId, UserStatus } from '../user/user.type';
import {
    CREATE_MEDIC,
    FETCH_MEDIC,
    TOGGLE_MEDIC,
    UPDATE_MEDIC,
    UPDATE_MEDIC_GROUPS,
    CREATE_MEDIC_GROUPS
} from './medic.constants';
import { PutMedicGroupsInterface } from '../user/user.api.interface';
import {
    CreateBasicAuthMedic,
    CreateSithsAuthMedic
} from '../user/user.api.create.type';

export const fetchMedic = (
    medicId: MedicExternalId
): {
    type: string;
    payload: Promise<unknown>;
} => ({
    type: FETCH_MEDIC,
    payload: getUser(medicId)
});

export const createBasicMedic = (
    data: CreateBasicAuthMedic
): {
    type: string;
    payload: Promise<unknown>;
} => ({
    type: CREATE_MEDIC,
    payload: postBasicMedic({
        data: {
            ...data,
            username: data.email,
            roles: [Roles.medic],
            authMethods: [AuthMethods.basic]
        }
    })
});

export const createSithsMedic = (
    data: CreateSithsAuthMedic
): {
    type: string;
    payload: Promise<unknown>;
} => ({
    type: CREATE_MEDIC,
    payload: postSithsMedic({
        data: {
            ...data,
            roles: [Roles.medic],
            authMethods: [AuthMethods.siths]
        }
    })
});

export const updateMedic = (
    medicId: MedicExternalId,
    data: UpdateMedic
): {
    type: string;
    payload: Promise<unknown>;
} => ({
    type: UPDATE_MEDIC,
    payload: putMedic(medicId, data)
});

export const createMedicGroups = (
    params: PutMedicGroupsInterface
): {
    type: string;
    payload: Promise<unknown>;
} => ({
    type: CREATE_MEDIC_GROUPS,
    payload: putMedicGroups(params)
});

export const updateMedicGroups = (
    params: PutMedicGroupsInterface
): {
    type: string;
    payload: Promise<unknown>;
} => ({
    type: UPDATE_MEDIC_GROUPS,
    payload: putMedicGroups(params)
});

export const toggleMedic = (
    medicId: MedicExternalId,
    active: boolean
): {
    type: string;
    payload: Promise<unknown>;
    meta: { medicId: MedicExternalId };
} => ({
    type: TOGGLE_MEDIC,
    payload: toggleUser(medicId, {
        status: active ? UserStatus.Active : UserStatus.Inactive,
        reason: Reasons.NO_REASON
    }),
    meta: { medicId }
});
