import { defineMessages } from 'react-intl';

const info = defineMessages({
    text: {
        id: 'edit.indicator.limits.not-allowed.info.text',
        defaultMessage:
            'For information only. You do not have permission to save any changes.'
    }
});

export const limits = {
    notAllowed: {
        info
    }
};
