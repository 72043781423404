import React, { Component } from 'react';
import { Button, Popconfirm } from 'antd';
import {
    StyledActionTabHeader,
    StyledDivider
} from '../../../styledActionTabs';
import { styled } from '../../../../../../../theme/styled';

type Props = any;
type State = any;

export class GeneralPanelWrapper extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            visible: false,
            showConfirm: this.props.showConfirm
        };
    }

    handleVisibleChange = (visible: any) => {
        const { showConfirm } = this.state;
        const { preventPopconfirmHide } = this.props;

        if (!visible) {
            if (preventPopconfirmHide) {
                //  external prop to prevent popup from dissaperaing when an error occurs in the popconfirm
                this.setState({ visible: !visible });
            } else {
                this.setState({ visible });
                this.props.toggleModalOverlay();
            }
            return;
        }

        if (showConfirm) {
            this.setState({ visible });
            this.props.toggleModalOverlay();
        } else {
            this.props.onConfirm();
        }
    };

    render() {
        const {
            title = 'Titel',
            data = '',
            buttonType = 'primary',
            buttonLabel = 'OK',
            buttonIcon = 'check',
            confirmText = 'Är du säker?',
            okText = 'Ja',
            cancelText = 'Nej',
            practitioner,
            disabled,
            children
        } = this.props;

        return (
            <div>
                <StyledActionTabHeader>{title}</StyledActionTabHeader>
                {practitioner && (
                    <PanelBoxPractitioner>{practitioner}</PanelBoxPractitioner>
                )}
                <PanelBoxData>{data}</PanelBoxData>
                {children ? (
                    children
                ) : (
                    <PanelBoxButtonWrapper>
                        <Popconfirm
                            title={confirmText}
                            okText={okText}
                            cancelText={cancelText}
                            onConfirm={this.props.onConfirm}
                            onCancel={this.onCancel}
                            visible={this.state.visible}
                            onVisibleChange={this.handleVisibleChange}
                        >
                            <Button
                                type={buttonType}
                                icon={buttonIcon}
                                disabled={disabled}
                                style={{ width: '100%' }}
                            >
                                {buttonLabel}
                            </Button>
                        </Popconfirm>
                    </PanelBoxButtonWrapper>
                )}
                <StyledDivider />
            </div>
        );
    }

    onCancel = () => {
        this.setState({ visible: false });
        this.props.toggleModalOverlay();
        this.props.onCancel();
    };
}

export const PanelBoxPractitioner = styled.div`
    font-weight: bold;
    font-style: italic;
    color: ${(props) => props.theme.profile.base.primary};
    padding: 2px 4px;
    margin-bottom: 4px;
`;

export const PanelBoxData = styled.div`
    font-weight: 400;
    color: ${(props) => props.theme.profile.layout.subtitles};
    margin-bottom: 10px;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.profile.bright};
`;

export const PanelBoxButtonWrapper = styled.div`
    text-align: right;
    margin: 12px 0;
`;
