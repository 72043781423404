import styled from 'styled-components';
import { Layout, Menu } from 'antd';
import {
    animationCss,
    keyOpacity,
    keyLeft
} from './styled-helpers/animationMain';

export const StyledAppNavigation = styled(Menu)`
    && {
        border-right: none;
    }
`;

export const StyledLeftMenuItem = styled(Menu.Item)`
    &&& {
        font-size: 14px;
    }
`;

export const StyledPageLayout = styled(Layout)`
    height: inherit;
    background-color: transparent;
`;

export const StyledContentLayout = styled(Layout)`
    height: inherit;

    background: ${(props) => props.theme && props.theme.contentBackground};
`;

export const StyledSider = styled(Layout.Sider)`
    // Edge height fix
    height: inherit;
`;

export const StyledH1 = styled.h1`
    ${animationCss(keyOpacity('0', '1'), '0.35', 'ease-in')};

    padding-left: 7px;
    font-size: 1.2rem;
    font-weight: 300;
    color: #000000;
    white-space: nowrap;
`;

export const StyledLogoContent = styled.div<{ $collapsed: boolean }>`
    position: relative;
    width: 100%;
    padding: 10px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    float: left;
    transition: all 0.25s ease-in-out;

    &:hover {
        cursor: pointer;
    }

    img {
        height: 32px;
        width: auto;
        position: relative;
        ${(props) => (props.$collapsed ? 'left: 0.5rem;' : 'left: 0px;')}

        ${(props) =>
            props.$collapsed
                ? animationCss(keyLeft('0rem', '0.5rem'), '0.2', 'ease-in')
                : animationCss(keyLeft('0.5rem', '0rem'), '0.2', 'ease-in')}
    }
`;
