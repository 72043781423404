import React, { useEffect } from 'react';
import { AppNavigation } from './navigation/AppNavigation';
import { Redirect } from 'react-router-dom';
import { checkAuthentication } from '../../redux/domains/authentication/authentication.actions';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Layout } from 'antd';
import ErrorBoundary from './ErrorBoundary';
import { MessageOfTheDayModal } from '../shared/message-of-the-day/MessageOfTheDayModal';
import { oldTokenInEvent } from './main-layout-utils';
import { RouteHistory } from '../../types/route-types';
import { useHistory } from '../../hooks/use-router';
import { routeEducation } from '../../pages/education/page/route';
import { routeMessageOfTheDay } from '../../pages/message-of-the-day/route';
import { routePatient } from '../../pages/patient/data/route';
import { routePatientOverview } from '../../pages/patient/overview/route';
import { routePatientSearch } from '../../pages/patient/search/route';
import { routeAbout } from '../../pages/about/route';
import { authStorageKeys } from '../../utils/storage/auth/auth-storage-keys';
import { routeMedicOverview } from '../../pages/medic/overview/route';
import { routeProfile } from '../../pages/medic/profile/route';
import { useMenuRoutes } from '../../hooks/layout/use-menu-items';
import { routeEducationForm } from '../../pages/education/form/route';
import { routeUneducatedStart } from '../../pages/education/uneducated-start/route';
import { routeEducationFormPassed } from '../../pages/education/form-passed/route';
import { routeLogout } from '../../pages/auth/logout/route';
import { routeEditPatient } from '../../pages/patient/edit/route';
import { routeEditPatientGroup } from '../../pages/patient/edit-group/route';
import { RouteItemType, SwitchRoutes } from '../../utils/route/switch-routes';
import { routePatientAdministrationSwitch } from '../../pages/patient/administration/route';
import { routeMedicRegister } from '../../pages/medic/register/route';
import { routeStatistics } from '../../pages/statistics/route';

const mainNavigationRoutes: RouteItemType[] = [
    routeEditPatient,
    routeEditPatientGroup,
    routePatientOverview,
    routePatientAdministrationSwitch,
    routePatient,
    routePatientSearch,
    routeStatistics,
    routeMedicOverview,
    routeMedicRegister,
    routeProfile,
    routeAbout,
    routeMessageOfTheDay,
    routeEducation,
    routeUneducatedStart,
    routeEducationForm,
    routeEducationFormPassed
];

export const MainLayout = (): JSX.Element => {
    useSyncLogout();

    const menuRoutes = useMenuRoutes();

    return (
        <AppNavigation>
            <StyledContent>
                <MessageOfTheDayModal />
                <ErrorBoundary>
                    <SwitchRoutes routes={mainNavigationRoutes}>
                        <Redirect
                            to={{
                                pathname: menuRoutes.defaultRoute.link
                            }}
                        />
                    </SwitchRoutes>
                </ErrorBoundary>
            </StyledContent>
        </AppNavigation>
    );
};

function useSyncLogout() {
    const dispatch = useDispatch();
    const routeHistory = useHistory();

    useEffect(() => {
        const handleStorageEvent = (event: StorageEvent) =>
            syncLogout(event, routeHistory, dispatch);

        window.addEventListener('storage', handleStorageEvent);

        return () => {
            window.removeEventListener('storage', handleStorageEvent);
        };
    }, [dispatch, routeHistory]);
}

async function syncLogout(
    event: StorageEvent,
    routeHistory: RouteHistory,
    dispatch: any
) {
    if (event.key === authStorageKeys.logout) {
        routeHistory.push(routeLogout.link);
    }

    if (oldTokenInEvent(event)) {
        // If tokens are cleared in other tab, save tokens to session storage
        // and check that we're still logged in
        window.sessionStorage.setItem(event.key, event.oldValue);

        if (event.key === authStorageKeys.idToken) {
            try {
                await dispatch(checkAuthentication());
            } catch (error) {
                console.log(error);
            }
        }
    }
}

export const StyledContent = styled(Layout.Content)`
    margin: 10px;
    padding-top: ${(props) => props.theme.layout.header.height}px;
    position: relative;
`;
