import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        id: 'medic.chart.patientDetails.title',
        defaultMessage: 'Patient data'
    },
    subtitle: {
        id: 'medic.chart.patientDetails.subtitle',
        defaultMessage:
            'Click and drag in the plot area to zoom in; hold shift and drag to scroll sideways. Click on the labels under the chart to turn on/off series. Click in the chart or in a heatmap to show a time indicator for the given date.'
    },
    noData: {
        id: 'medic.chart.patientDetails.noData',
        defaultMessage: 'No data..'
    },
    multipleUnits: {
        id: 'medic.chart.patientDetails.multipleUnits',
        defaultMessage: 'Multiple units'
    },
    selectedDate: {
        id: 'medic.chart.patientDetails.selectedDate',
        defaultMessage: 'Selected date: '
    }
});

const heatmap = defineMessages({
    moreTooltipValues: {
        id: 'chart.heatmap.tooltip.more-reported-values',
        defaultMessage:
            'Another {value} reported {value, plural, one {value} other {values}} today'
    },
    noReport: {
        id: 'chart.heatmap.legend.no-report',
        defaultMessage: 'No report'
    }
});

export const chart = {
    general,
    heatmap
};
