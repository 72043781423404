import { Indicator } from '../../../types/indicator/indicator.type';

import icon from './images/waist-circumference.svg';
import { messagesWaistCircumference } from './messages/messages';
import { rules } from './waist-circumference-rules';

export const parameterWaistCircumference = {
    code: 'WAIST-CIRCUMFERENCE'
};

export const waistCircumference: Indicator = {
    code: parameterWaistCircumference.code,
    icon,
    messages: messagesWaistCircumference,
    rules
};
