import { createReducer } from '@reduxjs/toolkit';
import { resetPatientMeta, fetchPatientMeta } from './patient-meta.actions';
import { initialState } from './patient-meta.initial-state';

export const patientMetaReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fetchPatientMeta.fulfilled, (state, { payload }) => {
            state.ssn = payload.ssn;
            state.firstName = payload.firstName;
            state.lastName = payload.lastName;
            state.email = payload.email;
            state.phoneNumber = payload.phoneNumber;
        })
        .addCase(resetPatientMeta.fulfilled, (state) => {
            state.ssn = initialState.ssn;
            state.firstName = initialState.firstName;
            state.lastName = initialState.lastName;
            state.email = initialState.email;
            state.phoneNumber = initialState.phoneNumber;
        });
});
