import React, { PropsWithChildren } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Card } from 'antd';

import { NoReportedValues } from '../charts/no-values/NoValues';
import { Smoke } from './card-content/Smoke';
import { Default } from './card-content/Default';
import { PrnMed } from './card-content/PrnMed';
import { Physact } from './card-content/Physact';
import { IndicatorReport } from '../../../types/indicators/Indicator';
import {
    PHYSACT,
    PRNMED,
    PRNMED_CI,
    PRNMED_COPD,
    SMOKE
} from '../../../conditions/indicator-meta';
import { useChartWidth } from '../use-chart-width';
import { IndicatorWithSettings } from '../../../redux/domains/indicators/indicator.type';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { isHeatmapRules } from '../../../redux/domains/settings/settings.type';

export const LatestValue = (props: {
    indicator: IndicatorWithSettings;
    latestReports: IndicatorReport[];
}): JSX.Element => {
    const indicator = props.indicator;
    const width = useChartWidth(indicator.settings.rules);

    const latestReports = props.latestReports;

    if (isEmpty(latestReports)) {
        return (
            <LatestReportCard width={width}>
                <NoReportedValues />
            </LatestReportCard>
        );
    }

    const code = indicator.code;
    const latestReport = latestReports[latestReports.length - 1];

    if (code === SMOKE.code && isHeatmapRules(indicator.settings.rules)) {
        return (
            <LatestReportCard width={width}>
                <Smoke
                    latestReport={latestReport}
                    indicator={indicator}
                    latestReports={latestReports}
                />
            </LatestReportCard>
        );
    }
    if (code === PHYSACT.code) {
        return (
            <LatestReportCard width={width}>
                <Physact
                    latestReport={latestReport}
                    indicator={indicator}
                    latestReports={latestReports}
                />
            </LatestReportCard>
        );
    }
    if (
        (code === PRNMED.code ||
            code === PRNMED_CI.code ||
            code === PRNMED_COPD.code) &&
        isHeatmapRules(indicator.settings.rules)
    ) {
        return (
            <LatestReportCard width={width}>
                <PrnMed
                    latestReport={latestReport}
                    indicator={indicator}
                    latestReports={latestReports}
                />
            </LatestReportCard>
        );
    }

    return (
        <LatestReportCard width={width}>
            <Default latestReport={latestReport} indicator={indicator} />
        </LatestReportCard>
    );
};

const LatestReportCard = (props: PropsWithChildren<{ width: string }>) => (
    <Card
        title={formatMessage(
            Strings.screen.patientOverview.general.indicatorPopoverContentTitle
        )}
        style={{
            width: props.width,
            height: '100%',
            animation: 'fade 0.5s forwards'
        }}
    >
        {props.children}
    </Card>
);
