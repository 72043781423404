import { createReducer } from '@reduxjs/toolkit';
import {
    clearSelectedPrioPatient,
    setAppPageTitle,
    setPendingPatient,
    setSelectedPrioPatient
} from './app-state.actions';
import { AppStateType } from './app-state.type';

const initialState: AppStateType = {
    pageTitle: '',
    selectedPrioPatient: null,
    pendingPatientSsn: null
};

export const appStateReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setAppPageTitle.fulfilled, (state, payload) => {
            state.pageTitle = payload.payload;
        })
        .addCase(setSelectedPrioPatient.fulfilled, (state, payload) => {
            state.selectedPrioPatient = payload.payload;
        })
        .addCase(clearSelectedPrioPatient.fulfilled, (state, payload) => {
            state.selectedPrioPatient = payload.payload;
        })
        .addCase(setPendingPatient.fulfilled, (state, payload) => {
            state.pendingPatientSsn = payload.payload;
        });
});
