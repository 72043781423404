import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from '../../../shared-indicators/medication/images/medication.svg';
import { MedicationCIProperties } from './medication-ci-properties';
import { rules } from './medication-ci-rules';
import { messagesMedicationCi } from './messages/messages';

export const parameterMedicationCi = {
    code: 'MEDICATION-CI',
    properties: MedicationCIProperties
};

export const medication: Indicator = {
    code: parameterMedicationCi.code,
    icon,
    messages: messagesMedicationCi,
    rules
};
