import { useEffect, useState } from 'react';

import Highcharts from 'highcharts';

import { getXAxisExtremes } from './regression';
import { SeriesData } from '../chart-types';
import { createSeries } from './graph-series';

import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import { DataPoint } from '../chart-types';
import { Duration, DurationValues } from '../duration';
import { createReportAndRegressionSeries } from './series';
import {
    generateInitialTrendGraphOptions,
    generateTrendChartOptions
} from './options';
import { ChartOptions } from '../../../../types/indicator/indicator-rules.type';

export function useTrendGraphOptions(props: {
    indicator: IndicatorWithSettings;
    data: DataPoint[];
    duration: Duration;
    chartOptions?: ChartOptions;
}): Highcharts.Options {
    const duration = props.duration;

    const [options, setOptions] = useState<Highcharts.Options>(() =>
        generateInitialTrendGraphOptions()
    );
    const [seriesData, setSeriesData] = useState<SeriesData[]>();

    useEffect(() => {
        setSeriesData(
            createReportAndRegressionSeries({
                indicator: props.indicator,
                data: props.data
            })
        );
    }, [props.data, props.indicator]);

    const fixedYAxisMin = calculateAxisValue(props.chartOptions?.fixedYAxisMin);
    const fixedYAxisMax = calculateAxisValue(props.chartOptions?.fixedYAxisMax);
    const tickInterval = calculateTickInterval(
        props.chartOptions?.tickInterval
    );

    useEffect(() => {
        if (seriesData) {
            const { startDate, endDate } = getXAxisExtremes(
                DurationValues[duration]
            );

            const series = createSeries(seriesData, {
                startDate,
                endDate
            });

            setOptions((prevOptions) =>
                generateTrendChartOptions({
                    series,
                    startDate,
                    endDate,
                    duration: duration,
                    fixedYAxisMax,
                    fixedYAxisMin,
                    tickInterval,
                    prevOptions
                })
            );
        }
    }, [fixedYAxisMax, fixedYAxisMin, duration, seriesData]);

    return options;
}

export const calculateAxisValue = (value: number | null | undefined) =>
    typeof value === 'number' ? value : null;

export const calculateTickInterval = (
    tickInterval: number | null | undefined
) => (typeof tickInterval === 'number' ? tickInterval : undefined);
