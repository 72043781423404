import { Indicator } from '../../../../types/indicator/indicator.type';
import icon from './images/orthosis-night.svg';
import { messagesOrthosisNight } from './messages/messages';
import { rules } from './orthosis-night-hs-rules';

export const parameterOrthosisNightHs = {
    code: 'ORTHOSIS-NIGHT-HS'
};

export const orthosisNight: Indicator = {
    code: parameterOrthosisNightHs.code,
    icon,
    messages: messagesOrthosisNight,
    rules
};
