import React from 'react';
import { Patients } from '../../../redux/domains/statistics/statistics.type';
import { BarChart } from '../../../components/charts/bar/BarChart';
import { getData } from '../../../components/charts/pie/getModifiedData';
import { PieChart } from '../../../components/charts/pie/PieChart';
import {
    StyledBarContent,
    StyledPatientsInfo,
    StyledPatientsInfoContent,
    StyledPieContent,
    StyledPieWrapper
} from '../styles/patient-styles';
import { statisticsTitle } from '../../../components/charts/pie/pie-enums';
import { formatMessage } from '../../../locale/format/format-message';
import { StyledH3 } from '../styles/conditions-styles';
import { Strings } from '../../../locale/messagesDescriptors';
import { checkIfHasValues } from './checkIfHasValues';
import { StyledEmptyValueDiv } from '../styles/statistics-page-styles';

export function PatientsContent(props: { patients: Patients }): JSX.Element {
    const patients = props.patients;

    const report = getData(patients, statisticsTitle.report);
    const gender = getData(patients, statisticsTitle.gender);

    const genderHasValues = checkIfHasValues(gender);
    const reportHasValues = checkIfHasValues(report);

    return patients ? (
        <StyledPatientsInfoContent>
            <StyledPieContent>
                <StyledH3 style={{ textAlign: 'center' }}>
                    {formatMessage(Strings.statistics.general.amount)}
                </StyledH3>
                <StyledPieWrapper className="styledPieWrapper">
                    {reportHasValues ? (
                        <PieChart data={report} />
                    ) : (
                        <StyledEmptyValueDiv>
                            <p>
                                {formatMessage(
                                    Strings.statistics.general.noPatients
                                )}
                            </p>
                        </StyledEmptyValueDiv>
                    )}
                    {genderHasValues ? (
                        <PieChart data={gender} />
                    ) : (
                        <StyledEmptyValueDiv>
                            <p>
                                {formatMessage(
                                    Strings.statistics.general.noPatients
                                )}
                            </p>
                        </StyledEmptyValueDiv>
                    )}
                </StyledPieWrapper>
            </StyledPieContent>
            <StyledPatientsInfo>
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    {formatMessage(
                                        Strings.statistics.general.new
                                    )}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {patients.registered}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {formatMessage(
                                        Strings.statistics.general.deactivated
                                    )}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {patients.inactivated}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </StyledPatientsInfo>
            <StyledBarContent>
                <div
                    style={{
                        paddingRight: '2em'
                    }}
                >
                    <StyledH3 style={{ textAlign: 'center' }}>
                        {formatMessage(Strings.statistics.charts.title)}
                    </StyledH3>
                    <BarChart patients={patients} />
                </div>
            </StyledBarContent>
        </StyledPatientsInfoContent>
    ) : (
        <></>
    );
}
