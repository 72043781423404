import React from 'react';
import moment from 'moment';
import { Avatar } from 'antd';

import BlockQuote from '../../../../../../../shared/styled/blockquote';
import { getPractitionerName } from './utils/getPracticionerName';
import { HistoryItemProps } from '../HistoryPanelContent';
import { profiles } from '../../../../../../../../theme/profile/profiles';
import { StyledMetaItem } from './styledHistoryItem';
import { Strings } from '../../../../../../../../locale/messagesDescriptors';
import {
    formatMessageById,
    formatMessage
} from '../../../../../../../../locale/format/format-message';

export const UserStatusChange = ({ event }: HistoryItemProps): JSX.Element => {
    const { status, dtCreated, proxyMeta, reason, note } = event;

    const localeId = status === 'active' ? 'activatedBy' : 'inActivatedBy';
    const statusChangeLocalId = status === 'active' ? 'activate' : 'deactivate';

    return (
        <>
            <StyledMetaItem
                title={moment(dtCreated).format('YYYY-MM-DD HH:mm')}
                avatar={
                    <Avatar
                        icon="issues-close"
                        style={{
                            backgroundColor:
                                profiles.main.profile.denote.disabled
                        }}
                    />
                }
                description={formatMessageById(
                    `${Strings.events.rootId}${localeId}`,
                    {
                        practitioner: getPractitionerName(proxyMeta)
                    }
                )}
            />
            <div style={{ margin: '8px 0 0 0' }}>
                <div
                    style={{
                        marginBottom: 8,
                        fontSize: 13,
                        color: profiles.main.profile.dark
                    }}
                >
                    <span style={{ fontWeight: 600 }}>
                        {formatMessage(Strings.common.general.reason)}
                    </span>
                    {': '}
                    {formatMessageById(
                        `status-change.${statusChangeLocalId}.${reason}`
                    )}
                </div>
                {reason === 'other' && (
                    <BlockQuote
                        borderLeft={`4px solid ${profiles.main.profile.layout.background}`}
                    >
                        {note}
                    </BlockQuote>
                )}
            </div>
        </>
    );
};
