import { Indicator } from '../../../types/indicator/indicator.type';
import { rules } from './form-rules';

import icon from './images/form.svg';
import { messagesForm } from './messages/messages';

export const parameterForm = {
    code: 'FORM'
};

export const form: Indicator = {
    code: parameterForm.code,
    icon,
    messages: messagesForm,
    rules
};
