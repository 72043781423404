import { defineMessages } from 'react-intl';

const condition = defineMessages({
    intendedUse: {
        id: 'education.intended-use',
        defaultMessage:
            '#### Intended use  \nLifePod^®^ Platform is a software platform to be used in combination with applications, sensors and medical devices to provide information to the healthcare sector to enable prioritization and/or overview of individuals in a patient group for a specific health condition.'
    }
});

const general = defineMessages({
    about: {
        id: 'about.title',
        defaultMessage: 'About'
    },
    platform: {
        id: 'about.platform-title',
        defaultMessage: 'Platform'
    },
    patentPrefix: {
        id: 'about.patent-prefix',
        defaultMessage: '{patentId}'
    },
    usesOpenSource: {
        id: 'about.uses-open-source',
        defaultMessage: 'This product utilizes open source'
    },
    trademark: {
        id: 'about.trademark',
        defaultMessage: 'is a registered trademark {trademarkId}'
    },
    copyright: {
        id: 'about.copyright',
        defaultMessage: 'Copyright'
    },
    support: {
        id: 'about.support',
        defaultMessage: 'Support'
    },
    supportTelephone: {
        id: 'about.support-telephone',
        defaultMessage: 'For support, please call'
    },
    version: {
        id: 'about.version',
        defaultMessage: 'Version {value}'
    },

    manufacturer: {
        id: 'about.manufacturer',
        defaultMessage: 'Manufacturer'
    }
});

export const messagesAbout = {
    general,
    condition
};
