import React from 'react';
import moment from 'moment';
import { styled } from '../../../../../../theme/styled';
import { Message } from '../../../../../../redux/domains/chat/chat.types';
import { DateDivider } from '../../../../../../components/action-panel/action-panel-modal/action-modal-tabs/messages/content/MessageList';
import { formatMessage } from '../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../locale/messagesDescriptors';

type Props = {
    message: Message;
    renderDateDivider: boolean;
    isOutbound: boolean;
    isMedicMessage: boolean;
};

function formatUserMeta(message: Message) {
    return `${message.senderMeta.name} ${message.senderMeta.family_name}`;
}

function formatDateInfo(message: Message) {
    return `${moment(message.dtCreated).format('HH:mm')}`;
}

function userAndDate(message: Message) {
    return formatUserMeta(message) + ' - ' + formatDateInfo(message);
}

export const MessageRow = (props: Props) => {
    const content = props.message.content;
    const isOutbound = props.isOutbound;

    return (
        <StyledMessageRow>
            {props.renderDateDivider ? (
                <DateDivider message={props.message} />
            ) : null}
            <StyledMetaRow
                isOutbound={isOutbound}
                isMedicMessage={props.isMedicMessage}
            >
                <StyledTextAlign isOutbound={isOutbound}>
                    <StyledMeta isOutbound={isOutbound}>
                        {userAndDate(props.message)}
                    </StyledMeta>
                </StyledTextAlign>
            </StyledMetaRow>

            <StyledMessageContentRow isOutbound={isOutbound}>
                {content.startsWith('#video/') ? (
                    <MessageBubble
                        isOutbound={isOutbound}
                        isMedicMessage={props.isMedicMessage}
                    >
                        {formatMessage(Strings.video.actionPanel.messages.text)}
                    </MessageBubble>
                ) : (
                    <MessageBubble
                        isOutbound={isOutbound}
                        isMedicMessage={props.isMedicMessage}
                    >
                        {content}
                    </MessageBubble>
                )}
            </StyledMessageContentRow>
        </StyledMessageRow>
    );
};

const StyledMessageContentRow = styled.div<{ isOutbound: boolean }>`
    width: 100%;
    text-align: ${(props) => (props.isOutbound ? 'right' : 'left')};
`;

const StyledTextAlign = styled.div<{ isOutbound: boolean }>`
    width: 100%;
    text-align: ${(props) => (props.isOutbound ? 'right' : 'left')};
`;

const StyledMetaRow = styled.div<{
    isOutbound: boolean;
    isMedicMessage: boolean;
}>`
    width: 100%;
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    text-align: ${(props) => (props.isOutbound ? 'right' : 'left')};
`;

const StyledMessageRow = styled.div`
    padding: 10px;
`;

const MessageBubble = styled.div<{
    isOutbound: boolean;
    isMedicMessage: boolean;
}>`
    display: inline-block;
    max-width: 50%;
    white-space: pre-wrap;
    border-radius: 15px;
    padding: 5px 15px;
    color: ${(props) => props.theme.profile.bright};

    background-color: ${(props) => {
        return props.isOutbound
            ? props.isMedicMessage
                ? props.theme.conversation.message.activeMedic.bgColor
                : props.theme.conversation.message.otherMedic.bgColor
            : props.theme.conversation.message.patient.bgColor;
    }};
    color: ${(props) => {
        return props.isOutbound
            ? props.isMedicMessage
                ? props.theme.conversation.message.activeMedic.textColor
                : props.theme.conversation.message.otherMedic.textColor
            : props.theme.conversation.message.patient.textColor;
    }};
`;

const StyledMeta = styled.span<{ isOutbound: boolean }>`
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 5px;
    color: ${(props) => props.theme.profile.layout.subtitles_alter};
`;
