import { BMI, BODY_WEIGHT } from '../../../conditions/indicator-meta';
import { ReduxState } from '../../redux-state.type';
import { PatientGroupId } from '../groups/groups.type';
import {
    IndicatorConfigurationState,
    NormalizedIndicators
} from '../indicators/indicator.type';
import { ActionPayload } from '../../redux.type';
import {
    addPatient,
    getPatient,
    putPatient,
    putPatientGroup,
    updateIndicatorConfig
} from './active-patient.api';
import {
    CreatePatient,
    UpdatePatient,
    UpdatePatientGroupRequest
} from './active-patient.api.types';
import {
    CREATE_PATIENT,
    FETCH_PATIENT,
    MOVE_PATIENT_TO_GROUP,
    SAVE_REGISTER_FORM_EXTERNAL_ID,
    SAVE_REGISTER_FORM_EXTERNAL_ID_AND_REASON,
    UPDATE_INDICATORCONFIG,
    UPDATE_PATIENT
} from './active-patient.constants';
import { ActivePatient } from './active-patient.type';

export const fetchPatient = (subjectId: string) => ({
    type: FETCH_PATIENT,
    payload: getPatient(subjectId)
});

export const updatePatient = (subjectId: string, data: UpdatePatient) => ({
    type: UPDATE_PATIENT,
    payload: putPatient(subjectId, data)
});

const getIndicatorCode = (
    indicatorConfig: IndicatorConfigurationState,
    indicators: NormalizedIndicators
) => indicators.byId[indicatorConfig.indicatorId].code;

export const updateIndicatorConfiguration =
    (patient: ActivePatient, values: any, conditionId: string) =>
    (dispatch: Function, getState: () => ReduxState) => {
        const { externalId, conditionConfiguration } = patient;
        const { indicators } = getState();
        const indicatorCode = getIndicatorCode(values, indicators);

        if (indicatorCode === BMI.code) {
            toggleIndicator(BODY_WEIGHT.code);
        }
        if (indicatorCode === BODY_WEIGHT.code) {
            toggleIndicator(BMI.code);
        }

        return dispatch({
            type: UPDATE_INDICATORCONFIG,
            payload: updateIndicatorConfig(externalId, conditionId, {
                indicatorId: values.indicatorId,
                configuration:
                    values.configuration.in_use === false
                        ? { in_use: false }
                        : values.configuration
            })
        });

        function toggleIndicator(code: string) {
            const { in_use } = values.configuration;
            const indicatorConfig = Object.values<any>(
                conditionConfiguration[conditionId]
            ).find(
                (indicatorConfig) =>
                    getIndicatorCode(indicatorConfig, indicators) === code
            );

            if (
                indicatorConfig &&
                indicatorConfig.configuration.in_use !== in_use
            ) {
                return dispatch({
                    type: UPDATE_INDICATORCONFIG,
                    payload: updateIndicatorConfig(externalId, conditionId, {
                        indicatorId: indicatorConfig.indicatorId,
                        // @ts-ignore
                        configuration: {
                            in_use
                        }
                    }),
                    meta: {
                        conditionId: conditionId,
                        indicatorId: indicatorConfig.indicatorId
                    }
                });
            }
        }
    };

export const createPatient = (user: CreatePatient) => (dispatch: any) =>
    dispatch({
        type: CREATE_PATIENT,
        payload: addPatient(user)
    });

export const savePendingRegisterPatientId = (externalId: string) => ({
    type: SAVE_REGISTER_FORM_EXTERNAL_ID,
    payload: externalId
});

export const savePendingPatientActivation = (
    externalId: string,
    reason?: string,
    note?: string
): ActionPayload<{
    externalId: string;
    reason: string | undefined;
    note: string | undefined;
}> => ({
    type: SAVE_REGISTER_FORM_EXTERNAL_ID_AND_REASON,
    payload: { externalId, reason, note }
});

export const updatePatientGroup = (
    groupId: PatientGroupId,
    data: UpdatePatientGroupRequest
): ActionPayload<void> => ({
    type: MOVE_PATIENT_TO_GROUP,
    payload: putPatientGroup(groupId, data)
});
