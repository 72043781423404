import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'EQ-5D-3L',
        id: 'indicator.eq5d3l.title'
    },
    titleLong: {
        defaultMessage: 'EQ-5D-3L',
        id: 'indicator.eq5d3l.title-long'
    },
    helptext: {
        defaultMessage:
            'On the following screens, please tap the statement that best describes your health TODAY.',
        id: 'indicator.eq5d3l.helptext'
    },
    copyright: {
        defaultMessage:
            '© EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation',
        id: 'indicator.eq5d3l.copyright'
    },
    sum: {
        defaultMessage: 'Sum',
        id: 'indicator.eq5d3l.sum.title'
    }
});

const activities = defineMessages({
    title: {
        defaultMessage: 'Activities',
        id: 'indicator.eq5d3l.activities.title'
    },
    noActivityProblems: {
        defaultMessage:
            'I have no problems with performing my usual activities',
        id: 'indicator.eq5d3l.activities.choices.1'
    },
    someActivityProblems: {
        defaultMessage:
            'I have some problems with performing my usual activities',
        id: 'indicator.eq5d3l.activities.choices.2'
    },
    unablePerformActivity: {
        defaultMessage: 'I am unable to perform my usual activities',
        id: 'indicator.eq5d3l.activities.choices.3'
    },
    usualActivities: {
        defaultMessage:
            'Your usual activities TODAY (e.g. work, study, housework, family or leisure activities)',
        id: 'indicator.eq5d3l.activities.choices.title'
    }
});

const anxiety = defineMessages({
    title: {
        defaultMessage: 'Anxiety',
        id: 'indicator.eq5d3l.anxiety.title'
    },
    anxietyToday: {
        defaultMessage: 'Your anxiety/depression TODAY',
        id: 'indicator.eq5d3l.anxiety.choices.title'
    },
    noAnxiety: {
        defaultMessage: 'I am not anxious or depressed',
        id: 'indicator.eq5d3l.anxiety.choices.1'
    },
    someAnxiety: {
        defaultMessage: 'I am moderately anxious or depressed',
        id: 'indicator.eq5d3l.anxiety.choices.2'
    },
    extremeAnxiety: {
        defaultMessage: 'I am extremely anxious or depressed',
        id: 'indicator.eq5d3l.anxiety.choices.3'
    }
});

const mobility = defineMessages({
    title: {
        defaultMessage: 'Mobility',
        id: 'indicator.eq5d3l.mobility.title'
    },
    mobilityToday: {
        defaultMessage: 'Your mobility TODAY',
        id: 'indicator.eq5d3l.mobility.choices.title'
    },
    noProblems: {
        defaultMessage: 'I have no problems in walking about',
        id: 'indicator.eq5d3l.mobility.choices.1'
    },
    someProblems: {
        defaultMessage: 'I have some problems in walking about',
        id: 'indicator.eq5d3l.mobility.choices.2'
    },
    confinedToBed: {
        defaultMessage: 'I am confined to bed',
        id: 'indicator.eq5d3l.mobility.choices.3'
    }
});

const pain = defineMessages({
    title: {
        defaultMessage: 'Pain',
        id: 'indicator.eq5d3l.pain.title'
    },
    discomfortToday: {
        defaultMessage: 'Your pain/discomfort TODAY',
        id: 'indicator.eq5d3l.pain.choices.title'
    },
    noPain: {
        defaultMessage: 'I have no pain or discomfort',
        id: 'indicator.eq5d3l.pain.choices.1'
    },

    moderatePain: {
        defaultMessage: 'I have moderate pain or discomfort',
        id: 'indicator.eq5d3l.pain.choices.2'
    },
    extremePain: {
        defaultMessage: 'I have extreme pain or discomfort',
        id: 'indicator.eq5d3l.pain.choices.3'
    }
});

const selfCare = defineMessages({
    title: {
        defaultMessage: 'Selfcare',
        id: 'indicator.eq5d3l.selfcare.title'
    },
    selfCareToday: {
        defaultMessage: 'Your self-care TODAY',
        id: 'indicator.eq5d3l.selfcare.choices.title'
    },
    noSelfCareProblems: {
        defaultMessage: 'I have no problems with self-care',
        id: 'indicator.eq5d3l.selfcare.choices.1'
    },
    someSelfCareProblems: {
        defaultMessage: 'I have some problems washing or dressing myself',
        id: 'indicator.eq5d3l.selfcare.choices.2'
    },
    unableWashDress: {
        defaultMessage: 'I am unable to wash or dress myself',
        id: 'indicator.eq5d3l.selfcare.choices.3'
    }
});

const vasInfo = defineMessages({
    title: {
        defaultMessage: 'Quality of Life',
        id: 'indicator.eq5d3l.vas_info.title'
    },
    description: {
        defaultMessage:
            'On the next screen you will see a scale numbered 0 to 100.',
        id: 'indicator.eq5d3l.vas_info.choices.description'
    },
    helptext: {
        defaultMessage:
            '100 means the best health you can imagine. 0 means the worst health you can imagine.',
        id: 'indicator.eq5d3l.vas_info.choices.helptext'
    },
    healthToday: {
        defaultMessage:
            'We would like to know how good or bad your health is TODAY.',
        id: 'indicator.eq5d3l.vas_info.choices.title'
    }
});

const vas = defineMessages({
    title: {
        defaultMessage: 'Quality of Life',
        id: 'indicator.eq5d3l.vas.title'
    },
    helptext: {
        defaultMessage:
            'Please tap on the scale to indicate how your health is TODAY.',
        id: 'indicator.eq5d3l.vas.choices.helptext'
    },
    reportMaxText: {
        defaultMessage: 'The best health you can imagine',
        id: 'indicator.eq5d3l.vas.report_max_text'
    },
    reportMinText: {
        defaultMessage: 'The worst health you can imagine',
        id: 'indicator.eq5d3l.vas.report_min_text'
    },
    healthToday: {
        defaultMessage: 'Your health TODAY',
        id: 'indicator.eq5d3l.vas.choices.title'
    }
});

export const messagesEq5d3l = {
    general,
    activities,
    anxiety,
    mobility,
    pain,
    selfCare,
    vas,
    vasInfo
};
