import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    width: 100%;
    background-color: #82fb6e;
    border-color: #82fb6e;
    &:hover,
    &:focus,
    &:active {
        background-color: rgba(130, 251, 110, 0.7);
        border-color: rgba(130, 251, 110, 0.7);
        color: rgba(0, 0, 0, 0.65);
    }
`;
