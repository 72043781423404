import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Physical Activity',
        id: 'indicator.physact.title'
    }
});

const intensity = defineMessages({
    title: {
        defaultMessage: 'Intensity',
        id: 'indicator.physact.intensity.title'
    },
    none: {
        id: 'indicator.physact.intensity.0',
        defaultMessage: 'No effort {range}'
    },
    extremelyEasy: {
        id: 'indicator.physact.intensity.1',
        defaultMessage: 'Extremely easy {range}'
    },
    veryEasy: {
        id: 'indicator.physact.intensity.2',
        defaultMessage: 'Very easy {range}'
    },
    easy: {
        id: 'indicator.physact.intensity.3',
        defaultMessage: 'Easy {range}'
    },
    somewhat: {
        id: 'indicator.physact.intensity.4',
        defaultMessage: 'Somewhat hard {range}'
    },
    hard: {
        id: 'indicator.physact.intensity.5',
        defaultMessage: 'Hard {range}'
    },
    veryHard: {
        id: 'indicator.physact.intensity.6',
        defaultMessage: 'Very hard {range}'
    },
    extremelyHard: {
        id: 'indicator.physact.intensity.7',
        defaultMessage: 'Extremely hard {range}'
    },
    maximum: {
        id: 'indicator.physact.intensity.8',
        defaultMessage: 'Maximum effort! {range}'
    }
});

const heatmapLegends = defineMessages({
    none: {
        id: 'indicator.physact.legend.0',
        defaultMessage: 'No effort {range}'
    },
    extremelyEasy: {
        id: 'indicator.physact.legend.1',
        defaultMessage: 'Extremely easy {range}'
    },
    veryEasy: {
        id: 'indicator.physact.legend.2',
        defaultMessage: 'Very easy {range}'
    },
    easy: {
        id: 'indicator.physact.legend.3',
        defaultMessage: 'Easy {range}'
    },
    somewhat: {
        id: 'indicator.physact.legend.4',
        defaultMessage: 'Somewhat hard {range}'
    },
    hard: {
        id: 'indicator.physact.legend.5',
        defaultMessage: 'Hard {range}'
    },
    veryHard: {
        id: 'indicator.physact.legend.6',
        defaultMessage: 'Very hard {range}'
    },
    extremelyHard: {
        id: 'indicator.physact.legend.7',
        defaultMessage: 'Extremely hard {range}'
    },
    maximum: {
        id: 'indicator.physact.legend.8',
        defaultMessage: 'Maximum effort! {range}'
    }
});

const duration = defineMessages({
    title: {
        defaultMessage: 'Duration',
        id: 'indicator.physact.duration.title'
    },

    legendAbove: {
        defaultMessage:
            '{PERS, plural, =20 {Intensity {PERS}} other {Intensity more than {PERS}}}',
        id: 'indicator.physact.legend.above'
    },
    values: {
        defaultMessage: 'Intensity {intensity} for {duration} minutes',
        id: 'indicator.physact.values'
    }
});

const unit = defineMessages({
    duration: {
        id: 'indicator.physact.duration.unit',
        defaultMessage: 'min'
    },
    intensity: {
        id: 'indicator.physact.intensity.unit',
        defaultMessage: ''
    }
});

export const messagesPhysact = {
    general,
    duration,
    intensity,
    unit,
    heatmapLegends
};
