import { RouteItemType } from '../../../../../utils/route/switch-routes';
import { LaunchWrapper } from './LaunchWrapper';
import { pathOauth2Launch } from './path';

export const routeOauthLaunch: RouteItemType = {
    name: '',
    exact: true,
    link: pathOauth2Launch,
    icon: '',
    component: LaunchWrapper
};
