import { form } from './form';
import { videos } from './videos';
import { defineMessages } from 'react-intl';

const page = defineMessages({
    title: {
        id: 'education.page.title',
        defaultMessage: 'Education module'
    }
});

export const education = {
    page,
    videos,
    form
};
