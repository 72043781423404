import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { Patients } from '../../../redux/domains/statistics/statistics.type';
import { getValues } from './getValues';

export const getOption = (props: Patients) => {
    const data = getValues(props);

    return {
        xAxis: {
            type: 'value',
            interval: 1,
            splitLine: {
                show: false
            },
            name: formatMessage(Strings.statistics.charts.years),
            nameLocation: 'center',
            nameTextStyle: { padding: 15 }
        },
        yAxis: {
            type: 'value',
            name: formatMessage(Strings.statistics.charts.patients),
            nameRotate: 90,
            nameLocation: 'center',
            nameTextStyle: { padding: 15 }
        },
        series: [
            {
                data: data.data,
                type: 'bar',
                color: '#5b3495',
                barCategoryGap: '0%'
            }
        ]
    };
};
