import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Pump self test',
        id: 'indicator.pump-self-test-lvad.title'
    },
    question: {
        defaultMessage: "Was the pump's self-test successful?",
        id: 'indicator.pump-self-test-lvad.question'
    }
});

const legends = defineMessages({
    legendNo: {
        defaultMessage: 'Self-test not ok',
        id: 'indicator.pump-self-test-lvad.legend.no'
    },
    legendYes: {
        defaultMessage: 'Self-test ok',
        id: 'indicator.pump-self-test-lvad.legend.yes'
    }
});

const choices = defineMessages({
    false: {
        defaultMessage: 'Self-test not ok',
        id: 'indicator.pump-self-test-lvad.choices.false'
    },
    true: {
        defaultMessage: 'Self-test ok',
        id: 'indicator.pump-self-test-lvad.choices.true'
    }
});

export const messagesPumpSelfTestLvad = {
    general,
    legends,
    choices
};
