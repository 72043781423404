import { defineMessages } from 'react-intl';

const general = defineMessages({
    patientOverview: {
        id: 'medic.screen.main.patientOverview',
        defaultMessage: 'Patient Overview'
    },
    patientAdministration: {
        id: 'medic.screen.main.patientAdministration',
        defaultMessage: 'Patient Administration'
    }
});

export const main = {
    general
};
