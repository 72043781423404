import { Indicator } from '../../../../types/indicator/indicator.type';
import { rules } from './ehfscbs-rules';

import icon from './images/ehfscbs.svg';
import { messagesEhfscbs } from './messages/messages';

export const parameterEhfscbs = {
    code: 'EHFSCBS'
};

export const ehfscbs: Indicator = {
    code: parameterEhfscbs.code,
    icon,
    messages: messagesEhfscbs,
    rules
};
