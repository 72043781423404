import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Bowel Dysfunction',
        id: 'indicator.nbd.title'
    },
    titleLong: {
        defaultMessage: 'Bowel Dysfunction',
        id: 'indicator.nbd.title-long'
    },
    satisfaction: {
        defaultMessage: 'General satisfaction',
        id: 'indicator.nbd.satisfaction'
    },
    score: {
        defaultMessage: 'Score {score}',
        id: 'indicator.nbd.score'
    },
    sum: {
        defaultMessage: 'Score',
        id: 'indicator.nbd.sum.title'
    }
});

const choices = defineMessages({
    none: {
        defaultMessage: 'No difficulties',
        id: 'indicator.nbd.choices.1'
    },
    veryMinor: {
        defaultMessage: 'Very minor dysfunction (Score 0-6)',
        id: 'indicator.nbd.choices.2'
    },
    minor: {
        defaultMessage: 'Minor dysfunction (Score 7-9)',
        id: 'indicator.nbd.choices.3'
    },
    moderate: {
        defaultMessage: 'Moderate dysfunction (Score 10-13)',
        id: 'indicator.nbd.choices.4'
    },
    severe: {
        defaultMessage: 'Severe dysfunction (Score 14+)',
        id: 'indicator.nbd.choices.5'
    }
});

const choicesNoScore = defineMessages({
    veryMinor: {
        defaultMessage: 'Very minor dysfunction',
        id: 'indicator.nbd.choices-no-score.2'
    },
    minor: {
        defaultMessage: 'Minor dysfunction',
        id: 'indicator.nbd.choices-no-score.3'
    },
    moderate: {
        defaultMessage: 'Moderate dysfunction',
        id: 'indicator.nbd.choices-no-score.4'
    },
    severe: {
        defaultMessage: 'Severe dysfunction',
        id: 'indicator.nbd.choices-no-score.5'
    }
});

const status = defineMessages({
    title: {
        defaultMessage: 'Are you experiencing difficulties with your bowel?',
        id: 'indicator.nbd.status.title'
    },

    choicesTitle: {
        defaultMessage: 'Are you experiencing difficulties with your bowel?',
        id: 'indicator.nbd.status.choices.title'
    },

    yes: {
        defaultMessage: 'Yes',
        id: 'indicator.nbd.status.choices.true'
    },

    no: {
        defaultMessage: 'No',
        id: 'indicator.nbd.status.choices.false'
    }
});

const defFrequency = defineMessages({
    title: {
        defaultMessage: 'How often do you defecate',
        id: 'indicator.nbd.def_frequency.title'
    },

    choicesTitle: {
        defaultMessage: 'How often do you defecate',
        id: 'indicator.nbd.def_frequency.choices.title'
    },

    daily: {
        defaultMessage: 'Daily',
        id: 'indicator.nbd.def_frequency.choices.0'
    },

    weekly: {
        defaultMessage: '2-6 times per week',
        id: 'indicator.nbd.def_frequency.choices.1'
    },

    rarely: {
        defaultMessage: 'Less than once per week',
        id: 'indicator.nbd.def_frequency.choices.6'
    }
});

const defTime = defineMessages({
    title: {
        defaultMessage: 'How much time do you spend on each defecation',
        id: 'indicator.nbd.def_time.title'
    },

    choicesTitle: {
        defaultMessage: 'How much time do you spend on each defecation',
        id: 'indicator.nbd.def_time.choices.title'
    },

    halfHour: {
        defaultMessage: 'Less than 30 min.',
        id: 'indicator.nbd.def_time.choices.0'
    },

    aboutAnHour: {
        defaultMessage: '31-60 min.',
        id: 'indicator.nbd.def_time.choices.3'
    },

    moreThanAnHour: {
        defaultMessage: 'More than an hour',
        id: 'indicator.nbd.def_time.choices.7'
    }
});

const defUneasiness = defineMessages({
    title: {
        defaultMessage:
            'Do you experience uneasiness, sweating or headaches during or after defecation?',
        id: 'indicator.nbd.def_uneasiness.title'
    },

    choicesTitle: {
        defaultMessage:
            'Do you experience uneasiness, sweating or headaches during or after defecation?',
        id: 'indicator.nbd.def_uneasiness.choices.title'
    },

    yes: {
        defaultMessage: 'Yes',
        id: 'indicator.nbd.def_uneasiness.choices.2'
    },

    no: {
        defaultMessage: 'No',
        id: 'indicator.nbd.def_uneasiness.choices.0'
    }
});

const medicationTablets = defineMessages({
    title: {
        defaultMessage:
            'Do you take medication (tablets) to treat constipation?',
        id: 'indicator.nbd.const_medication_tablets.title'
    },

    choicesTitle: {
        defaultMessage:
            'Do you take medication (tablets) to treat constipation?',
        id: 'indicator.nbd.const_medication_tablets.choices.title'
    },

    yes: {
        defaultMessage: 'Yes',
        id: 'indicator.nbd.const_medication_tablets.choices.2'
    },

    no: {
        defaultMessage: 'No',
        id: 'indicator.nbd.const_medication_tablets.choices.0'
    }
});

const medicationLiquid = defineMessages({
    title: {
        defaultMessage:
            'Do you take medication (drops or liquid) to treat constipation?',
        id: 'indicator.nbd.const_medication_liquid.title'
    },

    choicesTitle: {
        defaultMessage:
            'Do you take medication (drops or liquid) to treat constipation?',
        id: 'indicator.nbd.const_medication_liquid.choices.title'
    },

    yes: {
        defaultMessage: 'Yes',
        id: 'indicator.nbd.const_medication_liquid.choices.2'
    },

    no: {
        defaultMessage: 'No',
        id: 'indicator.nbd.const_medication_liquid.choices.0'
    }
});

const digitalEvac = defineMessages({
    title: {
        defaultMessage: 'How often do you use digital evacuation?',
        id: 'indicator.nbd.digital_evac.title'
    },

    choicesTitle: {
        defaultMessage: 'How often do you use digital evacuation?',
        id: 'indicator.nbd.digital_evac.choices.title'
    },

    lessOncePerWeek: {
        defaultMessage: 'Less than once per week',
        id: 'indicator.nbd.digital_evac.choices.0'
    },

    moreThanOnce: {
        defaultMessage: 'Once or more per week',
        id: 'indicator.nbd.digital_evac.choices.6'
    }
});

const involuntary = defineMessages({
    title: {
        defaultMessage: 'How often do you have involuntary defecation?',
        id: 'indicator.nbd.involuntary.title'
    },

    choicesTitle: {
        defaultMessage: 'How often do you have involuntary defecation?',
        id: 'indicator.nbd.involuntary.choices.title'
    },

    daily: {
        defaultMessage: 'Daily',
        id: 'indicator.nbd.involuntary.choices.13'
    },

    weekly: {
        defaultMessage: '1-6 times a week',
        id: 'indicator.nbd.involuntary.choices.7'
    },

    monthly: {
        defaultMessage: '3-4 times a month',
        id: 'indicator.nbd.involuntary.choices.6'
    },

    yearly: {
        defaultMessage: 'A few times a year or less',
        id: 'indicator.nbd.involuntary.choices.0'
    }
});

const incontMedication = defineMessages({
    title: {
        defaultMessage: 'Do you take medication to treat faecal incontinence?',
        id: 'indicator.nbd.incont_medication.title'
    },

    choicesTitle: {
        defaultMessage: 'Do you take medication to treat faecal incontinence?',
        id: 'indicator.nbd.incont_medication.choices.title'
    },

    yes: {
        defaultMessage: 'Yes',
        id: 'indicator.nbd.incont_medication.choices.4'
    },

    no: {
        defaultMessage: 'No',
        id: 'indicator.nbd.incont_medication.choices.0'
    }
});

const flatus = defineMessages({
    title: {
        defaultMessage: 'Do you experience uncontrollable flatus?',
        id: 'indicator.nbd.flatus.title'
    },

    choicesTitle: {
        defaultMessage: 'Do you experience uncontrollable flatus?',
        id: 'indicator.nbd.flatus.choices.title'
    },

    yes: {
        defaultMessage: 'Yes',
        id: 'indicator.nbd.flatus.choices.2'
    },

    no: {
        defaultMessage: 'No',
        id: 'indicator.nbd.flatus.choices.0'
    }
});

const skin = defineMessages({
    title: {
        defaultMessage: 'Do you have peri-anal skin problems?',
        id: 'indicator.nbd.skin.title'
    },

    choicesTitle: {
        defaultMessage: 'Do you have peri-anal skin problems?',
        id: 'indicator.nbd.skin.choices.title'
    },

    yes: {
        defaultMessage: 'Yes',
        id: 'indicator.nbd.skin.choices.3'
    },

    no: {
        defaultMessage: 'No',
        id: 'indicator.nbd.skin.choices.0'
    }
});

const generalSatisfaction = defineMessages({
    title: {
        defaultMessage: 'General satisfaction',
        id: 'indicator.nbd.general_satisfaction.title'
    },

    choicesTitle: {
        defaultMessage: 'General satisfaction',
        id: 'indicator.nbd.general_satisfaction.choices.title'
    }
});

export const messagesBowelDysfunction = {
    general,
    choices,
    choicesNoScore,
    status,
    defFrequency,
    defTime,
    defUneasiness,
    medicationTablets,
    medicationLiquid,
    digitalEvac,
    involuntary,
    incontMedication,
    flatus,
    skin,
    generalSatisfaction
};
