import React from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '../../../../../../theme/styled';
import { PatientConversation } from '../../../../../../redux/domains/patient-data/patient-data.types';
import { setConversationId } from '../../../../../../redux/domains/patient-data/patient-data.actions';
import { SelectableRow } from '../../../../../../components/ui/list/SelectableRow';

export const ConversationSection = (props: {
    patientConversations: PatientConversation[];
    selectedConversationId?: string;
}): JSX.Element => {
    const dispatch = useDispatch();

    return (
        <StyledConversations>
            {props.patientConversations.map((conversation) => (
                <SelectableRow
                    key={conversation.id}
                    selected={conversation.id === props.selectedConversationId}
                    onClick={() => {
                        dispatch(setConversationId(conversation.id));
                    }}
                >
                    {conversation.medicGroupName}
                </SelectableRow>
            ))}
        </StyledConversations>
    );
};

const StyledConversations = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;

    overflow-y: auto;
`;
