import React from 'react';
import moment from 'moment';
import BlockQuote from '../../../shared/styled/blockquote/index';
import { DataItem } from './DataItem';
import { TrendData } from './trend-data.type';
import { IndicatorReport } from '../../../../types/indicators/Indicator';

export const DefaultTrendItem = (props: {
    latestReport: IndicatorReport<number>;
    formattedData: TrendData[];
}): JSX.Element => {
    const latestReport = props.latestReport;
    const formattedData = props.formattedData;

    return (
        <div>
            <h4 style={{ marginBottom: 15 }}>
                {moment(latestReport.date).format('LLL')}
            </h4>
            {mapFormattedData({ formattedData: formattedData })}
            {latestReport.comment && (
                <div className="popover-comment">
                    <BlockQuote>{latestReport.comment}</BlockQuote>
                </div>
            )}
        </div>
    );
};

const mapFormattedData = (props: { formattedData: TrendData[] }) => {
    return props.formattedData.map((data: TrendData, i) => (
        <DataItem
            key={i}
            title={data.title}
            value={data.value}
            unit={data.unit}
            addMargin={i !== props.formattedData.length - 1}
        />
    ));
};
