import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Pain at rest',
        id: 'indicator.pain-at-rest-hs.title'
    }
});

export const messagesPainAtRest = {
    general
};
