import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts';

import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/calendar';
import 'echarts/lib/chart/heatmap';
import { IndicatorWithSettings } from '../../../../../../redux/domains/indicators/indicator.type';
import { DataPoint } from '../../../chart-types';
import { getValuesForIndicator } from './get-values-for-indicator';
import { useEchartOptions } from './use-echart-options';
import { Duration } from '../../../duration';

export const HeatmapEchart = (props: {
    duration: Duration;
    indicator: IndicatorWithSettings;
    values: DataPoint[];
}): JSX.Element => {
    const values = getValuesForIndicator({
        indicator: props.indicator,
        values: props.values
    });

    const options = useEchartOptions({
        duration: props.duration,
        indicator: props.indicator,
        values
    });

    return (
        <ReactEchartsCore
            echarts={echarts}
            option={options}
            opts={{ renderer: 'svg' }}
            style={{
                width: '100%',
                height: '150px',
                marginTop: '-20px',
                paddingBottom: '10px'
            }}
        />
    );
};
