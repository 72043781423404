import React, { CSSProperties, ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';

export const EnvelopeIconSVG = (props: CSSProperties) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="22.032pt"
            height="54.432pt"
            viewBox="0 0 22.032 54.432"
            version="1.1"
        >
            <g id="surface31">
                <path
                    style={{
                        stroke: 'none',
                        fillRule: 'evenodd',
                        fill: props.fill ? props.fill : 'rgb(0%,0%,0%)',
                        fillOpacity: 1
                    }}
                    d="M 3.40625 11.113281 L 10.523438 15.636719 C 10.824219 15.828125 11.207031 15.828125 11.507812 15.636719 L 18.625 11.113281 C 18.675781 11.082031 18.742188 11.097656 18.773438 11.148438 C 18.785156 11.164062 18.792969 11.183594 18.792969 11.203125 L 18.792969 17.847656 C 18.792969 18.355469 18.382812 18.765625 17.875 18.765625 L 4.15625 18.765625 C 3.652344 18.765625 3.238281 18.355469 3.238281 17.847656 L 3.238281 11.203125 C 3.238281 11.144531 3.289062 11.097656 3.347656 11.097656 C 3.367188 11.097656 3.386719 11.101562 3.40625 11.113281 Z M 3.617188 9.046875 L 18.425781 9.046875 C 18.488281 9.046875 18.535156 9.09375 18.535156 9.152344 C 18.535156 9.191406 18.515625 9.222656 18.484375 9.246094 L 11.507812 13.671875 C 11.207031 13.863281 10.824219 13.863281 10.523438 13.671875 L 3.558594 9.242188 C 3.507812 9.210938 3.496094 9.144531 3.527344 9.09375 C 3.546875 9.0625 3.582031 9.046875 3.617188 9.046875 Z M 3.617188 9.046875 "
                />
            </g>
        </svg>
    );
};

export function encodeSvg(reactElement: ReactElement) {
    return (
        'data:image/svg+xml,' +
        escape(ReactDOMServer.renderToStaticMarkup(reactElement))
    );
}
