import {
    GraphValuesSorted,
    IndicatorValue
} from '../../../../../types/indicator/indicator-value.type';
import { RangeSeriesData } from '../../types/range-series-data.type';

export const multiCreateColumnRangeData = (
    event: GraphValuesSorted<IndicatorValue>
): RangeSeriesData => {
    const values = Object.values(event.value) as unknown as number[];

    return {
        x: event.date,
        low: Math.min(...values),
        high: Math.max(...values)
    };
};
