import React from 'react';
import { Icon, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { PatientGroup } from '../../redux/domains/groups/groups.type';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import {
    closeActionPanel,
    openActionPanel
} from '../../redux/domains/action-panel/action-panel.actions';
import { styled } from '../../theme/styled';
import { ActionPanelModal } from './action-panel-modal/ActionPanelModal';
import { formatMessage } from '../../locale/format/format-message';
import { Strings } from '../../locale/messagesDescriptors';

type Props = {
    name: string;
    externalId: string;
    patientGroup?: PatientGroup;
    conditionCode?: string;
    disabled?: boolean;
};

export const ActionPanelWrapper = (props: Props) => {
    const dispatch = useDispatch();
    const visible = useReduxSelector(
        ({ actionPanel }) =>
            actionPanel.isOpen &&
            actionPanel.patientExternalId === props.externalId
    );
    const title = formatMessage(Strings.common.general.openActionPanel);

    if (props.disabled) {
        return <StyledDisabledIcon type="profile" />;
    }

    return (
        <>
            <Tooltip title={title} placement="topRight">
                <Icon
                    type="profile"
                    onClick={handleShow}
                    className="actionpanel-icon"
                />
            </Tooltip>

            <ActionPanelModal
                visible={visible}
                hide={handleHide}
                show={handleShow}
                externalId={props.externalId}
                name={props.name}
                isMedicAdmin={false}
                unreadMessagesCount={0}
                unreadValueCommentsCount={0}
                patientGroup={props.patientGroup}
                conditionCode={props.conditionCode}
            />
        </>
    );

    function handleShow() {
        dispatch(openActionPanel(props.externalId));
    }

    function handleHide() {
        dispatch(closeActionPanel());
    }
};

const StyledDisabledIcon = styled(Icon)`
    color: #ccc;
`;
