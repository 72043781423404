import React, { Component } from 'react';
import { Button, InputNumber, Modal, Row } from 'antd';
import { Field, Form, Formik } from 'formik';
import { filter, get, isEmpty, map } from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import * as Yup from 'yup';
import { theme } from '../../../../../theme/colors/commonColors';
import { CrossSpinner } from '../../../../../components/ui/loading/loading';
import {
    BMI,
    BODY_LENGTH,
    BODY_WEIGHT
} from '../../../../../conditions/indicator-meta';
import { MarkdownFromDescriptor } from '../../../../../locale/format/format-markdown';
import { getLanguageWithoutRegionCode } from '../../../../../locale/setup/get-language-without-region-code';
import {
    fetchPatient,
    updateIndicatorConfiguration
} from '../../../../../redux/domains/active-patient/active-patient.actions';
import { ActivePatient } from '../../../../../redux/domains/active-patient/active-patient.type';
import { getPatient } from '../../../../../redux/domains/active-patient/active-patient.selectors';
import { setSelectedCondition } from '../../../../../redux/domains/conditions/conditions.actions';
import { clearRegisterFormFields } from '../../../../../redux/domains/forms/forms.actions';
import { getPendingRegisterPatient } from '../../../../../redux/domains/forms/forms.selectors';
import { IndicatorState } from '../../../../../redux/domains/indicators/indicator.type';
import {
    getIndicators,
    getIndicatorsForConditionWithSettings
} from '../../../../../redux/domains/indicators/indicators.selectors';
import { MedicUser } from '../../../../../redux/domains/medic/medic.type';
import { newObservation } from '../../../../../redux/domains/observations/observations.actions';
import { getSettingsByPath } from '../../../../../redux/domains/settings/settings.selectors';
import { getAuthenticatedUser } from '../../../../../redux/domains/user/user.selectors';
import { fetchValues } from '../../../../../redux/domains/values/values.actions';
import { getAllValues } from '../../../../../redux/domains/values/values.selectors';
import { StyledActionBar } from '../styled';
import { StyledFormWrapper } from '../styled';
import { routeCreatePatientStep3 } from '../step-3/route';
import { pathCreatePatientStep1 } from '../step-1/path';
import { pathPatientAdministrationSwitch } from '../../../administration/path';
import {
    formatMessage,
    formatMessageById
} from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { roundNumber } from '../../../../../utils/math/round-number';

const { confirm } = Modal;

const decimalSeparator: any = {
    sv: ',',
    en: '.'
};

type InitialValues = Record<string, any>;

type Props = {
    patient: any;
    fetchValues: any;
    history: any;
    fetchPatient: any;
    setSelectedCondition: any;
    initialIndicators: IndicatorState[];
    initialObservations: InitialValues;
    proxyUser: MedicUser;
    clearRegisterFormFields: any;
    newObservation: any;
    indicators: any;
    updateIndicatorConfiguration: any;
    currentSelectedPatient: ActivePatient;
};

class Step2 extends Component<Props> {
    state = {
        isFetchingData: false
    };

    componentDidMount() {
        const { patient, fetchValues } = this.props;

        if (isEmpty(patient)) {
            this.props.history.push(pathCreatePatientStep1);
        } else {
            this.setState({ isFetchingData: true });
            this.props
                .fetchPatient(patient.externalId)
                .then(({ value }: any) => {
                    const { conditionIds } = value;

                    this.props.setSelectedCondition(conditionIds[0]);
                    // Assumes that all reported values so far are so-called initial values
                    // since the patient-data is unconfirmed and thus unable to use LifePod
                    fetchValues({ subjectId: patient.externalId }).then(() => {
                        this.setState({ isFetchingData: false });
                    });
                });
        }
    }

    renderField = (indicator: any) => {
        const {
            defaultIndicatorConfigurations: { configuration },
            code,
            id
        } = indicator;
        return (
            <Field name={code} key={id}>
                {({ field, form, meta }: any) => {
                    return (
                        <FormField>
                            <label
                                htmlFor={code}
                                style={{
                                    marginRight: 8,
                                    flex: 2,
                                    textAlign: 'right'
                                }}
                            >
                                {formatMessageById(
                                    `${
                                        Strings.indicators.rootId
                                    }${code.toLowerCase()}.title`
                                )}
                                :
                            </label>
                            <div>
                                <InputNumber
                                    {...field}
                                    min={configuration.report_min}
                                    max={configuration.report_max}
                                    step={
                                        configuration.type === 'number'
                                            ? 0.1
                                            : 1
                                    }
                                    decimalSeparator={
                                        decimalSeparator[
                                            getLanguageWithoutRegionCode()
                                        ]
                                    }
                                    onChange={(v) => {
                                        if (typeof v === 'number') {
                                            form.setFieldValue(field.name, v);
                                        }
                                    }}
                                />
                                {meta.touched && meta.error && (
                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                )}
                            </div>
                            <span
                                style={{
                                    flex: 1,
                                    paddingLeft: 8
                                }}
                            >
                                {formatMessageById(
                                    `${
                                        Strings.indicators.rootId
                                    }${code.toLowerCase()}.unit`
                                )}
                            </span>
                        </FormField>
                    );
                }}
            </Field>
        );
    };

    validateAndNavigate = (formProps: any) => {
        const { validateForm, setTouched } = formProps;

        validateForm().then((errors: any) => {
            const hasErrors = !isEmpty(errors);

            if (hasErrors) {
                const touched = Object.keys(errors).reduce(
                    (acc: any, curr: any) => {
                        acc[curr] = true;
                        return acc;
                    },
                    {}
                );
                setTouched(touched);
            } else {
                this.props.history.push(routeCreatePatientStep3.link);
            }
        });
    };

    render() {
        const {
            initialIndicators = [],
            patient = {},
            initialObservations
        } = this.props;
        const { isFetchingData } = this.state;
        const validationSchema = Yup.object().shape(
            initialIndicators.reduce((schema: any, indicator: any) => {
                schema[indicator.code] = Yup.number().required(
                    formatMessage(Strings.form.general.required)
                );
                return schema;
            }, {})
        );

        return isFetchingData ? (
            <CrossSpinner />
        ) : (
            <StyledFormWrapper>
                <Formik
                    initialValues={initialObservations}
                    onSubmit={this.handleSubmit}
                    validationSchema={validationSchema}
                >
                    {(formProps) => {
                        return (
                            <Form style={{ width: 350, margin: 'auto' }}>
                                <Title patient={patient} />
                                <Row
                                    style={{
                                        borderTop: `2px solid ${theme.layout.primary}`,
                                        padding: '70px 20px 20px'
                                    }}
                                >
                                    {initialIndicators.length === 0 ? (
                                        <StyledNoInitialMessage>
                                            {formatMessage(
                                                Strings.screen
                                                    .patientAdministration.step2
                                                    .noInitialObservations
                                            )}
                                        </StyledNoInitialMessage>
                                    ) : null}
                                    {initialIndicators.map((indicator: any) =>
                                        this.renderField(indicator)
                                    )}
                                </Row>
                                <StyledActionBar>
                                    <Button onClick={this.onCancel}>
                                        {formatMessage(
                                            Strings.common.general.cancel
                                        )}
                                    </Button>
                                    <Button
                                        style={{ marginLeft: 8 }}
                                        onClick={formProps.resetForm as any}
                                        disabled={!formProps.dirty}
                                    >
                                        {formatMessage(
                                            Strings.common.general.reset
                                        )}
                                    </Button>
                                    {formProps.dirty ? (
                                        <Button
                                            style={{ marginLeft: 8 }}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {formatMessage(
                                                Strings.common.general.next
                                            )}
                                        </Button>
                                    ) : (
                                        <Button
                                            style={{ marginLeft: 8 }}
                                            type="primary"
                                            onClick={() =>
                                                this.validateAndNavigate(
                                                    formProps
                                                )
                                            }
                                        >
                                            {formatMessage(
                                                Strings.common.general.next
                                            )}
                                        </Button>
                                    )}
                                </StyledActionBar>
                            </Form>
                        );
                    }}
                </Formik>
            </StyledFormWrapper>
        );
    }

    handleSubmit = (values: any) => {
        const { patient, proxyUser } = this.props;
        const observations = map(values, (data, indicatorCode) => {
            return {
                indicatorCode,
                data,
                dtObserved: Date.now()
            };
        });

        const data = {
            subjectId: patient.externalId,
            subjectProxyId: proxyUser.externalId,
            dtRegistered: Date.now(),
            observations
        };

        confirm({
            title: formatMessage(
                Strings.screen.patientAdministration.step2.dialogTitle
            ),
            content: (
                <MarkdownFromDescriptor
                    messageDescriptor={
                        Strings.screen.patientAdministration.step2.dialogContent
                    }
                />
            ),
            onOk: () => {
                return this.postInitialObservations(data, values)
                    .then(() =>
                        this.props.history.push(routeCreatePatientStep3.link)
                    )
                    .catch((err) => console.log('error', err));
            }
        });
    };

    onCancel = () => {
        this.props.clearRegisterFormFields();
        this.props.history.push(pathPatientAdministrationSwitch);
    };

    postInitialObservations = async (data: any, values: any) => {
        const { indicators, initialIndicators, newObservation } = this.props;

        const bodyWeightExists = this.allItemsExists(
            initialIndicators,
            [BODY_WEIGHT.code],
            'code'
        );
        const bodyWeightAndBodyLengthExists = this.allItemsExists(
            initialIndicators,
            [BODY_WEIGHT.code, BODY_LENGTH.code],
            'code'
        );

        const bmiExists = this.allItemsExists(indicators, [BMI.code], 'code');

        if (bodyWeightExists) {
            const bodyWeightValue = values[BODY_WEIGHT.code];
            const bodyWeightConfig = this.getDefaultIndicatorConfig(
                BODY_WEIGHT.code
            );

            if (bodyWeightAndBodyLengthExists) {
                const bodyLengthValue = values[BODY_LENGTH.code];

                if (bmiExists && bodyLengthValue) {
                    const bmiConfig = this.getDefaultIndicatorConfig(BMI.code);
                    // For KOL
                    await this.updateBodyWeightConfigWithBMI(
                        bodyWeightValue,
                        bodyLengthValue,
                        bodyWeightConfig,
                        bmiConfig
                    );
                } else {
                    // for Kidney Failure
                    await this.updateBodyWeightConfig(
                        bodyWeightValue,
                        bodyWeightConfig
                    );
                }
            } else {
                // for Heartfailure and LVAD
                await this.updateBodyWeightConfig(
                    bodyWeightValue,
                    bodyWeightConfig
                );
            }
        }
        return newObservation(data);
    };

    getDefaultIndicatorConfig = (code: string) => {
        const { indicators } = this.props;
        const indicator = indicators.find(
            (indicator: any) => indicator.code === code
        );

        if (indicator.defaultIndicatorConfigurations) {
            return indicator.defaultIndicatorConfigurations;
        }
        return {};
    };

    updateBodyWeightConfig = (currentValue: any, indicatorConfig: any) => {
        let { configuration } = indicatorConfig;

        configuration = {
            ...configuration,
            report_min: currentValue - 10,
            lower_red: currentValue - 3,
            lower_yellow: currentValue - 2,
            upper_yellow: currentValue + 2,
            upper_red: currentValue + 3,
            report_max: currentValue + 10
        };

        indicatorConfig = {
            ...indicatorConfig,
            configuration
        };

        return this.updateIndicatorConfig(indicatorConfig);
    };

    updateBodyWeightConfigWithBMI = (
        bodyWeightValue: any,
        bodyLengthValue: any,
        bodyWeightConfig: any,
        bmiConfig: any
    ) => {
        const { lower_red: lower_red_bmi, upper_red: upper_red_bmi } =
            bmiConfig.configuration.bmi;

        let { configuration } = bodyWeightConfig;

        const lower_red = this.getCalculatedWeight(
            bodyLengthValue,
            lower_red_bmi
        );

        const upper_red = this.getCalculatedWeight(
            bodyLengthValue,
            upper_red_bmi
        );

        configuration = {
            ...configuration,
            lower_red,
            lower_yellow: null,
            upper_yellow: null,
            upper_red
        };

        bodyWeightConfig = {
            ...bodyWeightConfig,
            configuration
        };

        return this.updateIndicatorConfig(bodyWeightConfig);
    };

    updateIndicatorConfig = (configuration: any) => {
        const { updateIndicatorConfiguration, currentSelectedPatient } =
            this.props;
        return updateIndicatorConfiguration(
            currentSelectedPatient,
            configuration,
            currentSelectedPatient.conditionIds[0]
        );
    };

    getCalculatedWeight = (length: any, bmi: any) => {
        return bmi ? roundNumber(bmi * Math.pow(length / 100, 2), 1) : null;
    };

    allItemsExists = (list: any, itemsToLookFor: any, path: any) =>
        itemsToLookFor.every((item: any) => {
            return list.some((innerItem: any) => {
                if (path) {
                    return get(innerItem, path) === item;
                }
                return innerItem === item;
            });
        });
}

const getInitialObservationIndicators = getSettingsByPath(
    'initialObservationIndicators'
);

const getInitialIndicators = createSelector(
    [getIndicators, getInitialObservationIndicators],
    (indicators, codes) =>
        filter(indicators, (indicator) => codes.includes(indicator.code))
);

const getInitialValues = createSelector(
    [getAllValues, getInitialIndicators],
    (values, indicators) =>
        indicators.reduce((acc: InitialValues, curr) => {
            acc[curr.code] = isEmpty(values[curr.id])
                ? ''
                : values[curr.id][0].value;
            return acc;
        }, {})
);

const mapStateToProps = (state: any) => {
    return {
        initialIndicators: getInitialIndicators(state),
        patient: getPendingRegisterPatient(state),
        proxyUser: getAuthenticatedUser(state),
        currentSelectedPatient: getPatient(state),
        indicators: getIndicatorsForConditionWithSettings(state),
        initialObservations: getInitialValues(state)
    };
};

export default connect(mapStateToProps, {
    clearRegisterFormFields,
    fetchPatient,
    setSelectedCondition,
    newObservation,
    updateIndicatorConfiguration,
    fetchValues
})(Step2);

const Title = ({ patient }: any) => {
    if (!patient) return null;

    const { meta: { name = null, family_name = null } = {} } = patient;
    const patientName = `${name} ${family_name}`;

    return (
        <h3
            style={{
                textAlign: 'center',
                marginBottom: 20
            }}
        >
            {patientName}
        </h3>
    );
};

const FormField = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
`;

const ErrorMessage = styled.div`
    position: absolute;
    color: #f5222d;
    transition: color 300ms ease;
`;

const StyledNoInitialMessage = styled.div`
    text-align: center;
`;
