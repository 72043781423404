import { RouteItemType } from '../../../utils/route/switch-routes';
import { EditPatientPage } from './EditPatientPage';
import { pathEditPatientId } from './path';
import { titleEditPatient } from './title';

export const routeEditPatient: RouteItemType = {
    name: titleEditPatient,
    link: pathEditPatientId,
    icon: '',
    component: EditPatientPage
};
