import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'BMI',
        id: 'indicator.bmi.title'
    },
    value: {
        defaultMessage: '{value, number, ::.0}',
        id: 'indicator.bmi.value'
    }
});

const bmi = defineMessages({
    title: {
        defaultMessage: 'BMI',
        id: 'indicator.bmi.bmi.title'
    },
    unit: {
        id: 'indicator.bmi.bmi.unit',
        defaultMessage: ''
    }
});

const weight = defineMessages({
    title: {
        defaultMessage: 'Weight',
        id: 'indicator.bmi.weight.title'
    },
    unit: {
        id: 'indicator.bmi.weight.unit',
        defaultMessage: 'kg'
    }
});

const length = defineMessages({
    title: {
        defaultMessage: 'Length',
        id: 'indicator.bmi.length.title'
    },
    unit: {
        id: 'indicator.bmi.length.unit',
        defaultMessage: 'm'
    }
});

export const messagesBmi = {
    general,
    bmi,
    weight,
    length
};
