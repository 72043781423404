import Highcharts from 'highcharts';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import regression from 'regression';

import moment from 'moment';

import {
    SeriesData,
    CustomSeriesSplineDataOptions,
    DateAndNumberTuple
} from '../chart-types';
import { hasDataInCurrentDateRange } from '../../latest-value/utils/utils';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';

addNoDataModule(Highcharts);

type DefaultSerieConfig = {
    lineColor: string;
    dashStyle: 'ShortDot';
};

export const defaultSerieConfig: DefaultSerieConfig = {
    lineColor: '#888',
    dashStyle: 'ShortDot'
};

const calculateRegressionData = (
    data: CustomSeriesSplineDataOptions[]
): Highcharts.PointOptionsObject[] => {
    const formattedData: DateAndNumberTuple[] = data.map((d) => [d.x, d.y]);

    const { equation } = regression.linear(formattedData, {
        precision: 13
    });
    const [k, m] = equation;

    let { x: startDate } = data[0];
    let { x: endDate } = data[data.length - 1];

    startDate = moment(startDate).subtract(1, 'day').valueOf();
    endDate = moment(endDate).add(1, 'day').valueOf();

    return [
        { x: startDate, y: k * startDate + m },
        { x: endDate, y: k * endDate + m }
    ];
};

export const createSeries = (
    seriesData: SeriesData[],
    options: { startDate: number; endDate: number }
): Highcharts.SeriesSplineOptions[] => {
    const { startDate } = options;

    const dataSeries: Highcharts.SeriesSplineOptions[] = seriesData
        .filter((serie) => hasDataInCurrentDateRange(serie.data, startDate))
        .map((serie, i, series) => ({
            name: serie.indicator.name,
            id: serie.indicator.name,
            type: 'spline',
            data: serie.data,
            showInLegend: series.length > 1
        }));

    const trendSeries: Highcharts.SeriesSplineOptions[] = seriesData
        .filter((serie) => {
            return (
                hasDataInCurrentDateRange(serie.data, startDate) &&
                serie.data.filter(({ x: date = 0 }) => date >= startDate)
                    .length > 1
            );
        })
        .map((serie, i, series): Highcharts.SeriesSplineOptions => {
            const preRegressionData = serie.data.filter(
                ({ x: date = 0 }) => date >= startDate
            );

            const data = calculateRegressionData(preRegressionData);

            return {
                name: formatMessage(Strings.trend.general.line),
                id: `${serie.indicator.name}-trend`,
                type: 'spline',
                data,
                dashStyle: defaultSerieConfig.dashStyle,
                marker: {
                    enabled: false
                },
                color: defaultSerieConfig.lineColor,
                showInLegend: i === 0,
                linkedTo:
                    series.length > 1
                        ? `${series[0].indicator.name}-trend`
                        : undefined,
                enableMouseTracking: false
            };
        });

    return [...dataSeries, ...trendSeries];
};
