import React, { Component } from 'react';
import { Skeleton } from 'antd';
import { diff } from 'deep-diff';
import { connect } from 'react-redux';
import { PatientGroup } from '../../../../../redux/domains/groups/groups.type';
import styled from 'styled-components';
import { ReduxState } from '../../../../../redux/redux-state.type';
import {
    fetchOrCreateConversation,
    sendMessage,
    setCurrentActiveConversation,
    updateConversationStatus
} from '../../../../../redux/domains/chat/chat.actions';
import {
    FETCH_OR_CREATE_CONVERSATION,
    POST_MESSAGE
} from '../../../../../redux/domains/chat/chat.constants';
import {
    getConversationId,
    getConversationStatus,
    getMessages,
    getParticipants
} from '../../../../../redux/domains/chat/chat.selectors';
import {
    ConversationStatus,
    Message,
    MessagingParticipant
} from '../../../../../redux/domains/chat/chat.types';
import { getSelectedMedicGroupId } from '../../../../../redux/domains/groups/groups.selectors';
import { MedicUser } from '../../../../../redux/domains/medic/medic.type';
import { updateNotificationsStatus } from '../../../../../redux/domains/notifications/notifications.actions';
import {
    getIgnoredMessageNotificationIds,
    getUnreadMessageIds
} from '../../../../../redux/domains/notifications/notifications.selectors';
import { createLoadingSelector } from '../../../../../redux/domains/requests/requests.selectors';
import { getAuthenticatedUser } from '../../../../../redux/domains/user/user.selectors';
import { StyledActionPanelFullSizeBox } from '../styledActionTabs';
import { MessageList } from './content/MessageList';
import { WrappedSendMessageForm } from './content/SendMessageForm';

type Props = {
    patientId: string;
    patientGroup?: PatientGroup;
};

type StateProps = {
    conversationId: string | undefined;
    conversationStatus: ConversationStatus[];
    ignoredMessageNotifications: string[];
    initialLoading: boolean;
    medicUser: MedicUser;
    messages: Message[];
    postMessageLoading: boolean;
    selectedMedicGroupId: string;
    unreadMessageIds: string[];
    participants: MessagingParticipant[];
};

type Actions = {
    fetchOrCreateConversation: (
        medicGroupId: string,
        subjectId: string,
        participants: MessagingParticipant[]
    ) => Promise<string>;
    sendMessage: (
        externalId: string,
        conversationId: string,
        body: { content: string }
    ) => void;
    setCurrentActiveConversation: (conversationId: string) => void;
    updateConversationStatus: (conversationId: string, userId: string) => void;
    updateNotificationsStatus: (
        userId: string,
        status: string,
        unreadMessageIds: string[]
    ) => void;
};

type ComponentProps = Props & StateProps & Actions;

class MessagesTab extends Component<ComponentProps> {
    private formRef: any;

    componentDidMount() {
        const {
            fetchOrCreateConversation,
            patientId,
            selectedMedicGroupId,
            unreadMessageIds,
            updateConversationStatus,
            updateNotificationsStatus,
            participants
        } = this.props;

        fetchOrCreateConversation(
            selectedMedicGroupId,
            patientId,
            participants
        ).then((conversationId) => {
            updateConversationStatus(conversationId, patientId);

            if (unreadMessageIds.length > 0) {
                updateNotificationsStatus(
                    patientId,
                    'confirmed',
                    unreadMessageIds
                );
            }
        });
        window.addEventListener('beforeunload', this.onUnload);
    }

    componentWillUnmount = () => {
        const { setCurrentActiveConversation } = this.props;

        setCurrentActiveConversation('');
        this.updateIgnoredMessageNotifications();

        window.removeEventListener('beforeunload', this.onUnload);
    };

    shouldComponentUpdate(nextProps: ComponentProps) {
        const changes = diff(nextProps, this.props);
        return !!changes;
    }

    render() {
        const {
            patientId,
            conversationStatus,
            initialLoading,
            medicUser,
            messages,
            postMessageLoading
        } = this.props;

        return (
            <StyledActionPanelFullSizeBox>
                {initialLoading ? (
                    <StyledSkeletonWrapper>
                        <Skeleton active />
                    </StyledSkeletonWrapper>
                ) : (
                    <MessageList
                        patientId={patientId}
                        proxyId={medicUser.externalId}
                        messages={messages}
                        status={conversationStatus}
                    />
                )}
                <WrappedSendMessageForm
                    onSubmit={this.handleSubmit}
                    wrappedComponentRef={(form: any) => (this.formRef = form)}
                    loading={postMessageLoading}
                    initialLoading={initialLoading}
                />
            </StyledActionPanelFullSizeBox>
        );
    }

    handleSubmit = (e: any) => {
        e.preventDefault();
        const { sendMessage, conversationId, patientId } = this.props;
        const { form } = this.formRef.props;

        form.validateFields((err: any, values: any) => {
            if (!err) {
                const body = {
                    content: values['lp-message-content']
                };
                if (conversationId != null) {
                    sendMessage(patientId, conversationId, body);
                }
                form.resetFields();
            }
        });
    };

    updateIgnoredMessageNotifications = () => {
        const {
            conversationId,
            ignoredMessageNotifications,
            patientId,
            updateNotificationsStatus
        } = this.props;

        if (ignoredMessageNotifications.length > 0) {
            updateNotificationsStatus(
                patientId,
                'confirmed',
                ignoredMessageNotifications
            );
            if (conversationId != null) {
                updateConversationStatus(conversationId, patientId);
            }
        }
    };

    onUnload = () => {
        const { setCurrentActiveConversation } = this.props;
        setCurrentActiveConversation('');
        this.updateIgnoredMessageNotifications();
    };
}

const loadingSelector = createLoadingSelector([FETCH_OR_CREATE_CONVERSATION]);

const postMessageLoadingSelector = createLoadingSelector([POST_MESSAGE]);

const mapStateToProps = (state: ReduxState, props: Props): StateProps => {
    const patientId = props.patientId;
    const patientGroup = props.patientGroup;
    const selectedMedicGroup = getSelectedMedicGroupId(state);
    const medicGroupId =
        patientGroup && !patientGroup.managedBy.includes(selectedMedicGroup)
            ? patientGroup.managedBy[0]
            : selectedMedicGroup;
    const conversationId = getConversationId(state, {
        medicGroupId,
        patientId
    });

    return {
        conversationId: conversationId,
        conversationStatus: getConversationStatus(state, {
            medicGroupId,
            patientId
        }),
        ignoredMessageNotifications: getIgnoredMessageNotificationIds(state, {
            externalId: patientId
        }),
        initialLoading: loadingSelector(state),
        selectedMedicGroupId: medicGroupId,
        medicUser: getAuthenticatedUser(state),
        messages: conversationId
            ? getMessages(state, { conversationId, patientId })
            : [],
        postMessageLoading: postMessageLoadingSelector(state),
        unreadMessageIds: getUnreadMessageIds(state, {
            externalId: patientId
        }),
        participants: getParticipants(state, {
            patientId,
            medicGroupId
        })
    };
};

export const ConnectedMessageTab = connect(mapStateToProps, {
    fetchOrCreateConversation,
    sendMessage,
    setCurrentActiveConversation,
    updateConversationStatus,
    updateNotificationsStatus
})(MessagesTab);

const StyledSkeletonWrapper = styled.div`
    flex-grow: 1;
    margin-bottom: 20px;
`;
