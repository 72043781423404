export const normalizeReportReminders = (data: any) => {
    const subjectId = Object.keys(data)[0];
    const byIndicatorId = data[subjectId].reduce((acc: any, curr: any) => {
        acc[curr.indicatorId] = curr.conditionReportConfigurations.map(
            (item: any) => {
                return {
                    ...item,
                    id: curr.id
                };
            }
        );
        return acc;
    }, {});

    return {
        [subjectId]: byIndicatorId
    };
};
