import { RouteItemType } from '../../../utils/route/switch-routes';
import { pathSaml } from './path';
import SAMLPage from './SAMLPage';

export const routeSaml: RouteItemType = {
    name: '',
    link: pathSaml,
    icon: '',
    component: SAMLPage
};
