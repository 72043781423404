import indicatorRules from '../../utils/create-condition-settings/indicator-rules';
import { indicatorItem } from '../../utils/create-condition-settings/items';
import { reasonsStatusChange } from '../../utils/create-condition-settings/reasons-status-change';
import { ConditionRules } from '../condition.type';
import { IndicatorMeta } from '../indicator-meta';

export const createDemoKfRsConfiguration = (): ConditionRules => {
    return {
        initialObservationIndicators: [
            IndicatorMeta.BODY_WEIGHT.code,
            IndicatorMeta.BODY_LENGTH.code
        ],
        priorityTable: {
            hidden: [
                IndicatorMeta.BODY_LENGTH.code,
                IndicatorMeta.SMOKE.code,
                IndicatorMeta.WAIST_CIRCUMFERENCE.code,
                IndicatorMeta.DIZZINESS.code,
                IndicatorMeta.EQ5D3L.code
            ],
            order: [
                IndicatorMeta.WEIGHT_PD.code,
                IndicatorMeta.BLOOD_PRESSURE.code,
                IndicatorMeta.PULSE.code,
                IndicatorMeta.BODY_WEIGHT.code,
                IndicatorMeta.BREATHLESSNESS.code,
                IndicatorMeta.VAS.code,
                IndicatorMeta.TIREDNESS.code,
                IndicatorMeta.APPETITE.code,
                IndicatorMeta.SWELLING.code,
                IndicatorMeta.PHYSACT.code
            ]
        },
        indicatorSettings: {
            preventFromEditLimits: [
                IndicatorMeta.BODY_LENGTH.code,
                IndicatorMeta.PHYSACT.code,
                IndicatorMeta.SMOKE.code,
                IndicatorMeta.APPETITE.code,
                IndicatorMeta.VAS.code,
                IndicatorMeta.EQ5D3L.code
            ],
            preventFromEditReminders: [IndicatorMeta.BODY_LENGTH.code],
            preventFromToggle: [
                IndicatorMeta.BODY_LENGTH.code,
                IndicatorMeta.VAS.code
            ]
        },
        reasons: reasonsStatusChange(),
        indicators: {
            [IndicatorMeta.WEIGHT_PD.code]: indicatorItem(
                indicatorRules[IndicatorMeta.WEIGHT_PD.code]
            ),
            [IndicatorMeta.BLOOD_PRESSURE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BLOOD_PRESSURE.code]
            ),
            [IndicatorMeta.PULSE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PULSE.code]
            ),
            [IndicatorMeta.DIZZINESS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.DIZZINESS.code]
            ),
            [IndicatorMeta.SWELLING.code]: indicatorItem(
                indicatorRules[IndicatorMeta.SWELLING.code]
            ),
            [IndicatorMeta.BODY_LENGTH.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BODY_LENGTH.code]
            ),
            [IndicatorMeta.BODY_WEIGHT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BODY_WEIGHT.code]
            ),
            [IndicatorMeta.TIREDNESS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.TIREDNESS.code]
            ),
            [IndicatorMeta.BREATHLESSNESS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.BREATHLESSNESS.code]
            ),
            [IndicatorMeta.VAS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.VAS.code]
            ),
            [IndicatorMeta.PHYSACT.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PHYSACT.code]
            ),
            [IndicatorMeta.APPETITE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.APPETITE.code]
            ),
            [IndicatorMeta.SMOKE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.SMOKE.code]
            ),
            [IndicatorMeta.WAIST_CIRCUMFERENCE.code]: indicatorItem(
                indicatorRules[IndicatorMeta.WAIST_CIRCUMFERENCE.code]
            ),
            [IndicatorMeta.EQ5D3L.code]: indicatorItem(
                indicatorRules[IndicatorMeta.EQ5D3L.code]
            )
        }
    };
};
