import React from 'react';

import { LatestValue } from './latest-value/LatestValue';
import { TrendChart } from './charts/TrendChart';
import { IndicatorWithSettings } from '../../redux/domains/indicators/indicator.type';
import { PatientGroupId } from '../../redux/domains/groups/groups.type';
import { ExternalId } from '../../redux/domains/user/user.type';
import { IndicatorReminder } from '../../redux/domains/reminders/reminders.type';
import { IndicatorReport } from '../../types/indicators/Indicator';
import { RightWrapper, OuterWrapper } from './styled-indicator-trend';
import { MissedReportInfo } from './missed-report/MissedReportInfo';

export const TrendPresentation = (props: {
    selectedPatientGroupId: PatientGroupId;
    indicator: IndicatorWithSettings;
    subjectId: ExternalId;
    latestReports: IndicatorReport<any>[];
    indicatorReminder?: IndicatorReminder;
}): JSX.Element => {
    return (
        <OuterWrapper>
            <TrendChart
                selectedPatientGroupId={props.selectedPatientGroupId}
                indicator={props.indicator}
                subjectId={props.subjectId}
            />
            <RightWrapper>
                <LatestValue
                    latestReports={props.latestReports}
                    indicator={props.indicator}
                />
                {props.indicatorReminder?.missedReport && (
                    <MissedReportInfo
                        indicator={props.indicator}
                        subjectId={props.subjectId}
                    />
                )}
            </RightWrapper>
        </OuterWrapper>
    );
};
