import { createSelector } from 'reselect';
import { getSelectedMedicGroupId } from '../groups/groups.selectors';
import { Notification } from './notifications.type';

const getNotifications = (state: any, props: { externalId: string }) =>
    state.notifications.bySubjectId[props.externalId];

export const getUnreadValueComments = createSelector(
    [getNotifications, getSelectedMedicGroupId],
    (notifications) => {
        if (!notifications) return [];
        const { allIds, byId } = notifications;
        return allIds
            .map((id: string) => byId[id])
            .filter(
                (item: Notification) =>
                    item.notificationType === 'value_comment' &&
                    item.tags.includes('sent')
            );
    }
);

export const getUnreadMessages = createSelector(
    [getNotifications, getSelectedMedicGroupId],
    (notifications, selectedMedicGroupId) => {
        if (!notifications) return [];
        const { allIds, byId } = notifications;
        return allIds
            .map((id: string) => byId[id])
            .filter(
                (item: Notification) =>
                    item.notificationType === 'message' &&
                    item.tags.includes('sent') &&
                    item.meta.groupId === selectedMedicGroupId
            );
    }
);

export const getUnreadMessageIds = createSelector(
    [getUnreadMessages],
    (messages) => {
        return messages.map((message: Notification) => message.id);
    }
);

export const getIgnoredMessageNotificationIds = createSelector(
    [getNotifications],
    (notifications) => {
        if (!notifications) return [];

        const { allIds, byId } = notifications;
        return allIds
            .map((id: string) => byId[id])
            .filter(
                (notification: Notification) =>
                    notification.notificationType === 'message' &&
                    notification.tags.includes('ignore')
            )
            .map((notification: Notification) => notification.id);
    }
);
