import { ModifiedDataType } from '../../../redux/domains/statistics/statistics.type';

export function checkIfHasValues(data: ModifiedDataType[]) {
    let zeroValueCounter = 0;
    Object.values(data).map((item: ModifiedDataType) => {
        if (item?.value === 0) {
            zeroValueCounter++;
        }
    });
    if (zeroValueCounter === data.length) {
        return false;
    }

    return true;
}
