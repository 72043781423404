import { defineMessages, MessageDescriptor } from 'react-intl';

const uneducatedStart = defineMessages({
    title: {
        id: 'uneducated-medic-start-page.title',
        defaultMessage: 'Information'
    },
    subtitle: {
        id: 'uneducated-medic-start-page.subtitle',
        defaultMessage: 'Välkommen till LifePod'
    },
    ce: {
        id: 'uneducated-medic-start-page.ce',
        defaultMessage:
            'LifePod är en CE-märkt medicinteknisk produkt. Som tillverkare måste vi därför säkerställa att all personal som använder LifePod har kännedom om de viktigaste funktionerna i systemet.'
    },
    menuInfo: {
        id: 'uneducated-medic-start-page.menuInfo',
        defaultMessage:
            'I menyn till vänster hittar du länken till Utbildningsmodulen. Där finns instruktionsfilmer, manualer och även länken till ett test. Detta test måste du göra innan du får tillgång till hela LifePod.'
    },
    testInfo: {
        id: 'uneducated-medic-start-page.testInfo',
        defaultMessage:
            'Direkt när du genomfört testet får du veta om du klarat det. Om du inte klarat det får du göra testet igen. När du klarat testet får du direkt tillgång till hela LifePod och kan börja följa dina patienter på distans.'
    },
    support: {
        id: 'uneducated-medic-start-page.support',
        defaultMessage:
            'Om det uppstår frågor eller problem är du alltid välkommen att kontakta LifePod Support!  \nVi nås på [support@lifepod.se](mailto:support@lifepod.se) eller 0700 - 400 402.'
    },
    welcome: {
        id: 'uneducated-medic-start-page.welcome',
        defaultMessage: 'Återigen välkommen till LifePod!'
    }
});

export const messages = {
    uneducatedStart
};
