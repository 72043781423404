import React from 'react';
import { Radio } from 'antd';
import { RadioGroupField } from './types';
import { RadioChangeEvent } from 'antd/lib/radio';
import styled from 'styled-components';

type Props = {
    field: RadioGroupField;
};

export const RadioGroup = ({ field }: Props): JSX.Element => {
    return (
        <div style={{ marginBottom: 20 }}>
            {field.label && (
                <StyledLabel
                    htmlFor={field.name}
                    $required={!!field.indicateRequired}
                >
                    {field.label}
                </StyledLabel>
            )}
            <Radio.Group key={field.name} name={field.name} onChange={onChange}>
                {field.options.map((option, index) => (
                    <Radio
                        key={index}
                        value={option.value}
                        style={{ display: 'block' }}
                    >
                        <span id={`${field.name}-${option.value}`}>
                            {option.label}
                        </span>
                    </Radio>
                ))}
            </Radio.Group>
        </div>
    );

    function onChange(event: RadioChangeEvent) {
        field.isTouched = true;
        field.value = event.target.value;
    }
};

export const StyledLabel = styled.label<{ $required: boolean }>`
    display: block;
    white-space: nowrap;
    font-size: 13px;

    ${({ $required }) =>
        $required
            ? `
    ::after {
        content: '*';
        color: #f5222d;
        font-family: SimSun, sans-serif;
        margin-left: 2px;
    }`
            : ''}
`;
