export enum Actions {
    RESET_PRIORITY = 'reset_priority',
    WATCH_OVER = 'watch_over', // In the events API it is called watchover (as one word)
    PAUSE_PATIENT = 'pause_patient',
    ACTIVATE_PATIENT = 'activate_patient',
    DEACTIVATE_PATIENT = 'deactivate_patient',
    CHANGE_MEDICATION = 'change_medication',
    CHANGE_LIMITS = 'change_limits',
    SEND_MESSAGE = 'send_message',
    TEST = 'test',
    OTHER = 'other',
    NO_ACTION = 'no_action',
    PATIENT_VIEWS_BY_MEDIC_OUTSIDE_UNIT = 'patient_viewed_by_medic_outside_unit',
    HIDDEN_PATIENT_VIEWED_BY_MEDIC_OUTSIDE_UNIT = 'hidden_patient_viewed_by_medic_outside_unit',
    PATIENT_DATA_EXPORTED = 'patient_data_exported',
    FEEDBACK = 'feedback'
}
