import { Indicator } from '../../../types/indicator/indicator.type';
import { rules } from './appetite-rules';

import icon from './images/appetite.svg';
import { messagesAppetite } from './messages/messages';

export const parameterAppetite = {
    code: 'APPETITE'
};

export const appetite: Indicator = {
    code: parameterAppetite.code,
    icon,
    messages: messagesAppetite,
    rules: rules
};
