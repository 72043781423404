import { ChartType } from '../../../../types/indicator/chart.type';
import { colors } from '../../../../theme/colors/commonColors';
import { IndicatorChartGraphRules } from '../../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartGraphRules = {
    step: 1,
    chartTypes: [ChartType.Spline],
    lineColor: {
        name: colors.chart.graph.orange.name,
        default: colors.chart.graph.orange.secondary
    },
    chartOptions: {
        fixedYAxisMin: 0,
        fixedYAxisMax: 5,
        tickInterval: 1
    },
    limits: {
        report_min_enabled: false,
        report_max_enabled: false
    }
};
