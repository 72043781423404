import { Indicator } from '../../../types/indicator/indicator.type';

import icon from './images/vegetables.svg';
import { messagesVegetables } from './messages/messages';
import { rules } from './vegetables-rules';

export const parameterVegetables = {
    code: 'VEGETABLES'
};

export const vegetables: Indicator = {
    code: parameterVegetables.code,
    icon,
    messages: messagesVegetables,
    rules
};
