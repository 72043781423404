import { defineMessages } from 'react-intl';

const general = defineMessages({
    send: {
        id: 'feedback.general.send',
        defaultMessage: 'Encourage'
    },
    title: {
        id: 'feedback.general.title',
        defaultMessage: 'Encourage'
    },
    description: {
        id: 'feedback.general.description',
        defaultMessage: 'Send encouragement "{message}"'
    },
    message: {
        id: 'feedback.general.message',
        defaultMessage: 'Well done, keep it up {name}'
    },
    notFeedbackYet: {
        id: 'feedback.general.not-feedback-yet',
        defaultMessage: 'Not encouraged yet'
    },
    sendingEncouragement: {
        id: 'feedback.general.sendingEncouragement',
        defaultMessage: 'Sending encouragement...'
    },
    sentEncouragement: {
        id: 'feedback.general.sentEncouragement',
        defaultMessage: 'Successfully sent encouragement!'
    },
    encouragementBy: {
        id: 'feedback.general.encouragementBy',
        defaultMessage: 'Encouragement sent by {practitioner}'
    }
});

export const feedback = { general };
