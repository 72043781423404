import { defineMessages } from 'react-intl';

const general = defineMessages({
    greenArea: {
        defaultMessage: 'Use OK (green) area',
        id: 'Form.Toggle.GreenArea'
    },
    limitsTitle: {
        defaultMessage: 'Limits',
        id: 'medic.indicator.text.limitsTitle'
    }
});

export const indicatorText = {
    general
};
