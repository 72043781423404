import React from 'react';
import OpvSession from '@lifepod-legacy/openvidu-frontend';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import { getAuthenticatedUser } from '../../redux/domains/user/user.selectors';
import { leaveVideoMeeting } from '../../redux/domains/video-meeting/video-meeting.actions';

export function VideoMeeting() {
    const { token } = useReduxSelector((state) => state.videoMeeting);
    const medic = useReduxSelector(getAuthenticatedUser);
    const dispatch = useDispatch();

    console.log('Video meeting token:', token);
    if (token) {
        const displayName = `${medic.meta.name} ${medic.meta.family_name}`;
        return (
            <Draggable bounds="parent">
                <StyledVideoWindow>
                    <OpvSession
                        userId={medic.externalId}
                        displayName={displayName}
                        token={token}
                        leaveSession={(othersHaveLeft) =>
                            dispatch(leaveVideoMeeting(othersHaveLeft))
                        }
                    />
                </StyledVideoWindow>
            </Draggable>
        );
    }
    return null;
}

const StyledVideoWindow = styled.div`
    position: absolute;
    z-index: 1001;
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 600px;
    height: 400px;
`;
