import { ModifiedDataType } from '../../../redux/domains/statistics/statistics.type';

export const getValues = (props: { data: ModifiedDataType[] }) => {
    const patients = Object.values(props).map((item: ModifiedDataType[]) => {
        return item;
    });

    const data: ModifiedDataType[] = [];
    patients.map((item: ModifiedDataType[]) => {
        item.forEach((obj) => {
            obj &&
                data.push({
                    value: obj.value,
                    name: obj.name,
                    color: obj.color
                });
        });
    });

    return data;
};
