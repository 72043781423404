import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Icon } from 'antd';

import { Heatmap } from './heatmap/ChartHeatmap';
import { IndicatorWithSettings } from '../../../redux/domains/indicators/indicator.type';
import { useChartWidth } from '../use-chart-width';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { getTrendValues } from '../../../redux/domains/values/values.selectors';
import { Graph } from './graph/ChartGraph';
import { NoReportedValuesThisMonth } from './no-values/NoValues';
import { pathPatientProfileForIndicator } from '../../../pages/patient/data/components/tabs/path';
import { StyledTitleWrapper } from './styled-trend-chart';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { getTrendValueRange } from './utils/use-trend-value-range';
import moment from 'moment';
import { Duration, DurationValues } from './duration';
import { isChartRules } from '../../../redux/domains/settings/settings.type';

type Props = {
    selectedPatientGroupId: string;
    indicator: IndicatorWithSettings;
    subjectId: string;
};

export const TrendChart = (props: Props): JSX.Element => {
    const trendValues = useReduxSelector((state) =>
        getTrendValues(state, props.indicator.id)
    );
    const valuesInRange = getTrendValueRange({
        subtract: DurationValues[Duration.OneMonth],
        trends: trendValues,
        now: moment()
    });

    const width = useChartWidth(props.indicator.settings.rules);

    return (
        <Card
            title={
                <StyledTitleWrapper>
                    {formatMessage(Strings.trend.general.title)}
                    <Link
                        to={{
                            pathname: pathPatientProfileForIndicator(
                                props.selectedPatientGroupId,
                                props.subjectId,
                                props.indicator.id
                            )
                        }}
                        style={{ marginLeft: 10 }}
                    >
                        <Icon type="line-chart" />
                    </Link>
                </StyledTitleWrapper>
            }
            style={{
                width: width,
                animation: 'fade 0.5s forwards'
            }}
        >
            {valuesInRange ? (
                renderChart(props.indicator)
            ) : (
                <NoReportedValuesThisMonth />
            )}
        </Card>
    );

    function renderChart(indicator: IndicatorWithSettings): JSX.Element {
        if (trendValues) {
            return isChartRules(indicator.settings.rules) ? (
                <Graph
                    data={trendValues}
                    indicator={indicator}
                    chartOptions={indicator.settings.rules.chartOptions}
                />
            ) : (
                <Heatmap indicator={indicator} values={trendValues} />
            );
        }

        return <></>;
    }
};
