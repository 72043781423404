import { splitSSNWithRegExp } from './ssn-regex-century';

type PartsSSN = {
    century: string;
    year: string;
    month: string;
    day: string;
    num: string;
    check: string;
};

export const extractPartsFromSSN = (ssn: string): PartsSSN | undefined => {
    const parts = splitSSNWithRegExp(ssn);

    if (!parts) return undefined;

    if (parts[2].length === 4) parts[2] = parts[2].substring(2);

    return {
        century: parts[1],
        year: parts[2],
        month: parts[3].substring(0, 2),
        day: parts[3].substring(2),
        num: parts[4] + parts[5],
        check: parts[6]
    };
};
