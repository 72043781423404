import { authStorageKeys } from '../../utils/storage/auth/auth-storage-keys';

type OldTokenStorageEvent = StorageEvent & {
    key: 'idToken' | 'refreshToken';
    oldValue: string;
    newValue: null;
};

export function oldTokenInEvent(
    event: StorageEvent
): event is OldTokenStorageEvent {
    if (
        event.key === authStorageKeys.idToken ||
        event.key === authStorageKeys.refreshToken
    ) {
        if (event.oldValue) {
            if (!event.newValue) {
                return true;
            }
        }
    }

    return false;
}
