import { createSelector } from 'reselect';
import { ReduxState } from '../../redux-state.type';
import { getSelectedPatientGroupId } from '../groups/groups.selectors';

const externalId = (state: any, props: { externalId: string }) =>
    props.externalId;
const getWatchovers = (state: ReduxState) => state.priority.watchovers;

export const getWatchoversForPatientGroup = createSelector(
    [getSelectedPatientGroupId, getWatchovers],
    (groupId, watchovers) => watchovers[groupId] || {}
);

export const getLatestWatchoverForPatient = createSelector(
    [externalId, getWatchoversForPatientGroup],
    (externalId, watchoverPatients) => watchoverPatients[externalId] || {}
);
