import { normalize, schema } from 'normalizr';
import isEmpty from 'lodash/isEmpty';

export const normalizeEvents = (data: any) => {
    if (isEmpty(data)) return [];

    const { events: arrayOfEvents } = data;

    const eventSchema = new schema.Entity(
        'events',
        {},
        { idAttribute: 'eventId' }
    );

    const normalizedData = normalize(arrayOfEvents, [eventSchema]);

    let { events } = normalizedData.entities;

    events = {
        byId: events,
        allIds: Object.keys(events)
    };

    return { events };
};
