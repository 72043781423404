import { HTTPClient } from '../../../services/http-client/http-client';
import { Statistics } from './statistics.type';

export const getStatistics = (params: {
    id: string | null;
    from: number | null;
    to: number | null;
}) => {
    const response = HTTPClient.get<Statistics>(
        `/statistics/${params.id}?from=${params.from}&to=${params.to}`
    );
    return response;
};
