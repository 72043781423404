import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'mMRC',
        id: 'indicator.mmrc.title'
    }
});

const choices = defineMessages({
    fromHeavyActivity: {
        defaultMessage:
            'Breathless from heavy activity, not from fast paced walk or from walk up hill',
        id: 'indicator.mmrc.choices.1'
    },
    fromFastPaced: {
        defaultMessage: 'Breathless from fast paced walk or from up hill walk',
        id: 'indicator.mmrc.choices.2'
    },
    fromWalkingPace: {
        defaultMessage:
            'Breathless while walking on plane ground, at the same pace as someone my age',
        id: 'indicator.mmrc.choices.3'
    },
    fromWalkingOwnPace: {
        defaultMessage:
            'I need to stop while walking from breathlessness even though i set my own pace',
        id: 'indicator.mmrc.choices.4'
    },
    fromNone: {
        defaultMessage:
            'Breathlessness from getting dressed or from washing my self',
        id: 'indicator.mmrc.choices.5'
    }
});

export const messagesMmrc = {
    general,
    choices
};
