import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { LOGOUT_USER_FULFILLED } from '../../redux/domains/authentication/authentication.constants';
import { updateTokens } from '../../utils/token/token-service';
import { authStorageKeys } from '../../utils/storage/auth/auth-storage-keys';
import { displayError } from './display-error';

const appConfig = __APP_CONFIG__;

interface HTTPClientInterface {
    get<R = any>(url: string, config?: AxiosRequestConfig): Promise<R>;
    post<T = any, R = any>(
        url: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<R>;
    put<T = any, R = any>(
        url: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<R>;
    delete<T = any, R = any>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<R>;
    setupInterceptors: (store: any) => void;
}

export const privateAxios = axios.create({
    baseURL: appConfig.API_ROOT_URL,
    timeout: 20000
});

export const HTTPClient: HTTPClientInterface = {
    get: async (url, config) =>
        await privateAxios.get(url, config).then(({ data }) => data),
    post: async (url, data, config) =>
        await privateAxios.post(url, data, config).then(({ data }) => data),
    put: async (url, data, config) =>
        await privateAxios.put(url, data, config).then(({ data }) => data),
    delete: async (url, config) =>
        await privateAxios.delete(url, config).then(({ data }) => data),
    setupInterceptors: (store) => {
        privateAxios.interceptors.request.use(
            (request) => {
                const idToken = window.localStorage.getItem(
                    authStorageKeys.idToken
                );

                if (idToken) {
                    request.headers['Authorization'] = idToken;
                    request.headers['Content-Type'] = 'application/json';
                    request.headers['x-api-key'] = appConfig.API_KEY;
                }
                return request;
            },
            (error) => {
                console.error(error);
                return Promise.reject(error);
            }
        );

        privateAxios.interceptors.response.use(
            (response) => response,
            (error: AxiosError) => {
                const errorResponse = error.response;
                if (
                    error.config &&
                    errorResponse &&
                    errorResponse.status === 401
                ) {
                    return updateTokens()
                        .then((idToken) => {
                            error.config.headers['Authorization'] = idToken;
                            return privateAxios.request(error.config);
                        })
                        .catch((error) => {
                            console.error('Could not update tokens');
                            handleError(store);
                            throw error;
                        });
                } else {
                    displayError(error);
                }

                throw error;
            }
        );
    }
};

function handleError(store: any) {
    console.log('handleError -> dispatch LOGOUT_USER_FULFILLED');
    window.sessionStorage.clear();
    store.dispatch({ type: LOGOUT_USER_FULFILLED });
}
