import React, { Component } from 'react';
import { Icon } from 'antd';
import { connect } from 'react-redux';
import { getIndicatorReminder } from '../../../../../redux/domains/reminders/reminders.selectors';
import styled from 'styled-components';
import { IndicatorReminder } from '../../../../../redux/domains/reminders/reminders.type';

type Props = {
    result: any;
    indicatorReminder?: IndicatorReminder;
    onClick: () => void;
    showEffects: boolean;
};

class PriorityTableIndicator extends Component<Props> {
    render() {
        const { result, onClick, showEffects } = this.props;

        const missedReport = this.props.indicatorReminder?.missedReport;

        if (!result) {
            return null;
        }

        // Disabled indicator
        if (result[0] && result[0].color === 'WHITE') {
            return <div className="indicator-wrapper white" />;
        }

        return (
            <StyledIndicatorWrapper
                className={
                    showEffects
                        ? 'indicator-wrapper'
                        : 'indicator-wrapper no-hover'
                }
                onClick={onClick}
            >
                {result.map((res: any, i: number) => {
                    return (
                        <StyledWrapperIndicator
                            key={i}
                            className={`wrapped-indicator ${res.color}`}
                            indicatorColor={res.color}
                        />
                    );
                })}
                {missedReport && (
                    <Icon type="exclamation-circle" theme="outlined" />
                )}
            </StyledIndicatorWrapper>
        );
    }
}

const mapStateToProps = (state: any, props: any) => {
    return {
        indicatorReminder: getIndicatorReminder(state, {
            id: props.row?.meta?.externalId || '',
            indicatorId: props.indicator.id
        })
    };
};

export default connect(mapStateToProps, null)(PriorityTableIndicator);

const StyledWrapperIndicator = styled.div<{ indicatorColor: any }>`
    height: 25px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.indicatorColor};

    // Set the right background-color
    background-color: ${(props) => {
        const color = props.indicatorColor;

        switch (color) {
            case 'RED':
                return '#e8462c';
            case 'GREEN':
                return '#2dcc39';
            case 'YELLOW':
                return '#efea17';
            case 'WHITE':
                return '#ffffff';

            default:
                return '#bfbfbf';
        }
    }};

    // TODO: remove classes and use logic
    &.RED,
    &.YELLOW,
    &.GREEN {
        & + & {
            border-top: 1px solid #ffffff;
        }
    }

    &.WHITE {
        /* TODO: Don't do this in css */
        pointer-events: none;
        cursor: default;
    }
    border-radius: 0;
    flex: 1;
`;

const StyledIndicatorWrapper = styled.div`
    display: flex;
    transition: all 0.18s ease-in-out;
    border-radius: 2px;
    height: 25px;
    overflow: hidden;
    flex-direction: column;
    position: relative;

    /* TODO: Don't do this in css */
    &.white {
        pointer-events: none;
        cursor: default;
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.03);
    }

    &.no-hover {
        &:hover {
            transform: none;
            cursor: default;
        }
    }
    /* Reminder Icon */
    i {
        color: white;
        font-size: 21px;
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
    }
`;
