import { createReducer } from '@reduxjs/toolkit';
import {
    graphInitializeSettings,
    graphReset,
    graphSetHiddenindicator,
    graphSetShowIndicator
} from './chart.graph.actions';
import { graphGetInitialState } from './chart.graph.state';
import {
    graphGenerateSettings,
    getActiveIndicatorGraph,
    getGraphYAxisMinMaxByCode,
    getGraphYAxisMax,
    getGraphYAxisMin,
    getYAxisStartEndOnTick,
    graphSortValuesByCode
} from './chart.graph.reducer.utils';
import { GraphState } from './chart.graph.types';

export const graphReducer = createReducer(graphGetInitialState(), (builder) => {
    builder
        .addCase(graphInitializeSettings.fulfilled, (state, payload) => {
            const condition = payload.payload.condition;
            const conditionConfiguration =
                payload.payload.conditionConfiguration;
            const indicatorSettingsByCode =
                payload.payload.indicatorSettingsByCode;
            const indicatorsByCode = payload.payload.indicatorsByCode;
            const valuesByCode = payload.payload.values;

            state.settings = graphGenerateSettings({
                condition,
                conditionConfiguration,
                indicators: indicatorsByCode,
                indicatorSettingsMapping: indicatorSettingsByCode
            });

            state.yAxis.default = getGraphYAxisMinMaxByCode({
                settings: state.settings
            });

            state.sortedValuesByCode = graphSortValuesByCode({
                valuesByCode: valuesByCode,
                indicatorsByCode: indicatorsByCode
            });

            setGraphCalculated(state);
        })
        .addCase(graphReset.fulfilled, (state) => {
            Object.assign(state, graphGetInitialState());
        })

        .addCase(graphSetHiddenindicator.fulfilled, (state, payload) => {
            const code = payload.payload.code;

            state.settings[code].hidden = true;
            setGraphCalculated(state);
        })
        .addCase(graphSetShowIndicator.fulfilled, (state, payload) => {
            const code = payload.payload.code;

            state.settings[code].hidden = false;
            setGraphCalculated(state);
        });

    function setGraphCalculated(state: GraphState) {
        state.activeGraph = getActiveIndicatorGraph(state);

        state.yAxis.calculated.max = getGraphYAxisMax(state);
        state.yAxis.calculated.min = getGraphYAxisMin(state);
        state.yAxis.calculated.startEndOnTick = getYAxisStartEndOnTick(state);
    }
});
