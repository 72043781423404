import { defineMessages } from 'react-intl';

const page = defineMessages({
    title: {
        id: 'statistics.title',
        defaultMessage: 'Statistics'
    },
    selectedPeriod: {
        id: 'statistics.selected-period',
        defaultMessage: 'Selected period:'
    },
    watchovers: {
        id: 'statistics.actions.watchovers',
        defaultMessage: 'Checked'
    },
    feedback: {
        id: 'statistics.actions.feedback',
        defaultMessage: 'Encouragement'
    },
    messages: {
        id: 'statistics.actions.messages',
        defaultMessage: 'Messages'
    }
});

const general = defineMessages({
    currentAmount: {
        id: 'statistics.current-amount',
        defaultMessage: 'Number of patients in this group since start: '
    },
    amount: {
        id: 'statistics.number',
        defaultMessage: 'Number of patients '
    },
    new: {
        id: 'statistics.new',
        defaultMessage: 'Number of registrered patients: '
    },
    deactivated: {
        id: 'statistics.deactivated',
        defaultMessage: 'Number of deactivated patients: '
    },
    printPDF: {
        id: 'statistics.print-pdf',
        defaultMessage: 'Print/Save as PDF'
    },
    noPatients: {
        id: 'statistics.no-patients',
        defaultMessage: 'No patients'
    }
});

const charts = defineMessages({
    title: {
        id: 'statistics.charts.title',
        defaultMessage: 'Time in Lifepod'
    },
    male: {
        id: 'statistics.charts.male',
        defaultMessage: 'Male '
    },
    female: {
        id: 'statistics.charts.female',
        defaultMessage: 'Female '
    },
    patients: {
        id: 'statistics.charts.patients',
        defaultMessage: 'Number of patients'
    },
    years: {
        id: 'statistics.charts.years',
        defaultMessage: 'Years'
    },
    vas: {
        id: 'statistics.charts.vas',
        defaultMessage: 'Reported Quality of Life'
    },
    smoke: {
        id: 'statistics.charts.smoke',
        defaultMessage: 'Smoking'
    },
    physact: {
        id: 'statistics.charts.physact',
        defaultMessage: 'Physical activity'
    },
    reporting: {
        id: 'statistics.charts.reporting',
        defaultMessage: 'Reporting patients'
    },
    nonReporting: {
        id: 'statistics.charts.non-reporting',
        defaultMessage: 'Non reporting patients'
    },
    reported: {
        id: 'statistics.charts.reported',
        defaultMessage: 'Reported'
    },
    notReported: {
        id: 'statistics.charts.not-reported',
        defaultMessage: 'Not reported'
    },
    deactivated: {
        id: 'statistics.charts.deactivated',
        defaultMessage: 'Parameter deactivated'
    },
    vasRed: {
        id: 'statistics.charts.vas-red',
        defaultMessage: '(0-40)'
    },
    vasYellow: {
        id: 'statistics.charts.vas-yellow',
        defaultMessage: '(41-64)'
    },
    vasGreen: {
        id: 'statistics.charts.vas-green',
        defaultMessage: '(65-100)'
    },
    vasDesc: {
        id: 'statistics.charts.pie.vas.desc',
        defaultMessage: 'The distribution of the total number of reports'
    },
    vasEmpty: {
        id: 'statistics.charts.pie.vas.empty',
        defaultMessage: 'No reports'
    }
});

const bar = defineMessages({
    title: {
        id: 'statistics.bar.title',
        defaultMessage: 'Actions (proportion of patients)'
    },
    limits: {
        id: 'statistics.bar.limits',
        defaultMessage: 'Number of changed limits: '
    },
    resets: {
        id: 'statistics.bar.resets',
        defaultMessage: 'Number of prio resets: '
    }
});

const coworker = defineMessages({
    access: {
        id: 'statistics.co-worker.access',
        defaultMessage: 'Number of coworkers with access to Lifepod: '
    },
    loggedIn: {
        id: 'statistics.co-worker.logged-in',
        defaultMessage: 'Number of coworkers who have logged in: '
    },
    logInAmount: {
        id: 'statistics.co-worker.log-in-amount',
        defaultMessage: 'Number of logins by coworkers '
    },
    patients: {
        id: 'statistics.co-worker.patients',
        defaultMessage:
            '{numeric, plural, one {{numeric} patient} other {{numeric} patients}}'
    },
    title: {
        id: 'statistics.co-worker.title',
        defaultMessage: 'Number of actions'
    }
});

export const statistics = {
    page,
    general,
    charts,
    bar,
    coworker
};
