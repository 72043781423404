import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/pump-self-test.svg';
import { messagesPumpSelfTestLvad } from './messages/messages';
import { rules } from './pump-self-test-lvad-rules';

export const parameterPumpSelfTestLVAD = {
    code: 'PUMP-SELF-TEST-LVAD'
};

export const pumpSelfTest: Indicator = {
    code: parameterPumpSelfTestLVAD.code,
    icon,
    messages: messagesPumpSelfTestLvad,
    rules
};
