import React, { useState } from 'react';
import moment from 'moment';
import { message, Tooltip } from 'antd';
import { feedback } from './message-descriptors';
import { StyledActionTabHeader } from '../../../../styledActionTabs';
import { Actions } from '../../../../../../../../redux/domains/actions/actions.enums';
import { Reasons } from '../../../../../../../../types/reasons.enum';
import { useReduxSelector } from '../../../../../../../../hooks/use-redux-selector';
import { postAction } from '../../../../../../../../redux/domains/actions/actions.api';
import { fetchEvents } from '../../../../../../../../redux/domains/events/events.actions';
import { useDispatch } from 'react-redux';
import { getLatestEncourage } from '../../../../../../../../redux/domains/events/events.selectors';
import { PanelBoxData, PanelBoxPractitioner } from '../GeneralPanelWrapper';
import { StyledButton } from './styled-feedback-panel-item';
import { Strings } from '../../../../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../../../../locale/format/format-message';

export const FeedbackPanelItem = (): JSX.Element => {
    const dispatch = useDispatch();
    const patientId = useReduxSelector(
        (state) => state.actionPanel.patientExternalId
    );
    const medicId = useReduxSelector((state) => state.user.externalId);
    const name = useReduxSelector(
        (state) => state.patientAdministration.byId[patientId]?.meta?.name || ''
    );
    const latestEncourage = useReduxSelector(getLatestEncourage);
    const fromNow = latestEncourage.dtCreated
        ? moment(latestEncourage.dtCreated).fromNow()
        : formatMessage(feedback.general.notFeedbackYet);
    const feedbackMessage = formatMessage(feedback.general.message, { name });

    const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);

    return (
        <div>
            <StyledActionTabHeader>
                {formatMessage(feedback.general.title)}
            </StyledActionTabHeader>
            {latestEncourage.proxyMeta && (
                <PanelBoxPractitioner>
                    {formatMessage(Strings.common.general.mostRecentlyBy, {
                        name: `${latestEncourage.proxyMeta.name} ${latestEncourage.proxyMeta.family_name}`
                    })}
                </PanelBoxPractitioner>
            )}
            <PanelBoxData>{fromNow}</PanelBoxData>
            <Tooltip title={formatMessage(feedback.general.message, { name })}>
                <StyledButton onClick={handleClick} loading={isLoadingFeedback}>
                    {formatMessage(feedback.general.send)}
                </StyledButton>
            </Tooltip>
        </div>
    );

    function handleClick() {
        setIsLoadingFeedback(true);

        const hideMessage = message.loading(
            formatMessage(feedback.general.sendingEncouragement),
            0
        );
        postAction({
            externalId: patientId,
            proxyId: medicId,
            action: Actions.FEEDBACK,
            reason: Reasons.OTHER,
            note: feedbackMessage
        })
            .then(hideMessage)
            .then(() =>
                message.success(
                    formatMessage(feedback.general.sentEncouragement),
                    2
                )
            )
            .then(() =>
                dispatch(
                    fetchEvents({
                        principalId: patientId,
                        type: 'watchover,reset_priority,user_status_change,feedback'
                    })
                )
            )
            .then(() => setIsLoadingFeedback(false))
            .catch(() => message.destroy());
    }
};
