import * as actionTypes from './priority.constants';
import { getPriorityList, getPriorityListById } from './priority.api';
import { ReduxState } from '../../redux-state.type';
import { getSelectedPatientGroupId } from '../groups/groups.selectors';
import { postAction } from '../actions/actions.api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionTypes } from './priority.constants';

export const fetchPriorityList =
    () => (dispatch: any, getState: () => ReduxState) => {
        const groupId = getSelectedPatientGroupId(getState());

        if (groupId) {
            return dispatch({
                type: actionTypes.FETCH_PRIORITY_LIST,
                payload: getPriorityList(groupId)
            });
        }
    };

export const fetchPriorityListByGroupId = createAsyncThunk(
    ActionTypes.FETCH_PRIORITY_LIST_BY_ID,
    async (params: {
        patientGroupId: string | null;
        externalId: string | null;
    }) => {
        const response = await getPriorityListById({
            patientGroupId: params.patientGroupId,
            externalId: params.externalId
        });
        return response;
    }
);

export const resetPriority = (data: any) => (dispatch: any) =>
    dispatch({
        type: actionTypes.RESET_PRIORITY,
        payload: postAction(data)
    });

export const filterPriorityListsBySearchTerm =
    (searchTerm: string) => (dispatch: any, getState: () => ReduxState) => {
        const filter = {
            ...getState().ui.overview.filter,
            searchTerm: searchTerm
        };

        return dispatch({
            type: actionTypes.FILTER_PRIORITY_LISTS,
            payload: filter
        });
    };

export const filterPriorityListsByUnreadMessages =
    (newMessages: boolean) => (dispatch: any, getState: () => ReduxState) => {
        const filter = {
            ...getState().ui.overview.filter,
            newMessages: newMessages
        };

        return dispatch({
            type: actionTypes.FILTER_PRIORITY_LISTS,
            payload: filter
        });
    };

export const resetFilter = () => (dispatch: any) => {
    return dispatch({
        type: actionTypes.CLEAR_FILTER
    });
};
