import React, { CSSProperties } from 'react';
import { Duration } from '../duration';
import { Group as RadioGroup, Button as RadioButton } from 'antd/lib/radio';
import { Strings } from '../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../locale/format/format-message';

const defaultWrapperStyle: CSSProperties = { textAlign: 'right' };

export function ChartDateButtons(props: {
    wrapperStyle?: CSSProperties;
    setDuration: (duration: Duration) => void;
}): JSX.Element {
    return (
        <div style={props.wrapperStyle || defaultWrapperStyle}>
            <RadioGroup
                defaultValue={Duration.Week}
                buttonStyle="solid"
                size="small"
            >
                <RadioButton
                    value={Duration.Week}
                    onClick={() => props.setDuration(Duration.Week)}
                >
                    {formatMessage(Strings.date.general.weekShort, {
                        weeks: 2
                    })}
                </RadioButton>
                <RadioButton
                    value={Duration.OneMonth}
                    onClick={() => props.setDuration(Duration.OneMonth)}
                >
                    {formatMessage(Strings.date.general.monthShort, {
                        months: 1
                    })}
                </RadioButton>
                <RadioButton
                    value={Duration.TwoMonths}
                    onClick={() => props.setDuration(Duration.TwoMonths)}
                >
                    {formatMessage(Strings.date.general.monthShort, {
                        months: 2
                    })}
                </RadioButton>
            </RadioGroup>
        </div>
    );
}
