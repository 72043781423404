import { Strings } from '../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../locale/format/format-message';
import { colors } from '../../../../theme/colors/commonColors';
import { ChartType } from '../../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartHeatmapRules = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        { value: -1, color: colors.chart.heatMap.purple10 },
        { value: 1, color: colors.chart.heatMap.purple40 },
        { value: 0, color: colors.chart.heatMap.purple80 }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.medicationCi.legend.na.id,
            text: formatMessage(Strings.indicators.medicationCi.legend.na),
            color: colors.chart.heatMap.purple10
        },
        {
            textId: Strings.indicators.medicationCi.legend.yes.id,
            text: formatMessage(Strings.indicators.medicationCi.legend.yes),
            color: colors.chart.heatMap.purple40
        },
        {
            textId: Strings.indicators.medicationCi.legend.no.id,
            text: formatMessage(Strings.indicators.medicationCi.legend.no),
            color: colors.chart.heatMap.purple80
        }
    ]
};
