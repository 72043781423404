import { defineMessages } from 'react-intl';

const patient = defineMessages({
    activatePatient: {
        id: 'shared.common.patient.activate',
        defaultMessage: 'Activate patient'
    },
    deactivatePatient: {
        id: 'shared.common.patient.deactivate',
        defaultMessage: 'Deactivate patient'
    }
});

const general = defineMessages({
    true: {
        id: 'shared.common.true',
        defaultMessage: 'Yes'
    },
    false: {
        id: 'shared.common.false',
        defaultMessage: 'No'
    },
    reason: {
        id: 'shared.common.reason',
        defaultMessage: 'Reason'
    },
    mostRecentlyBy: {
        id: 'shared.common.mostRecently',
        defaultMessage: 'Most recently by {name}'
    },
    clear: {
        id: 'shared.common.clear',
        defaultMessage: 'Clear'
    },
    search: {
        id: 'shared.common.search',
        defaultMessage: 'Search'
    },
    infoMissing: {
        id: 'shared.common.infoMissing',
        defaultMessage: 'Missing information'
    },
    send: {
        id: 'shared.common.send',
        defaultMessage: 'Send'
    },
    cancel: {
        id: 'shared.common.cancel',
        defaultMessage: 'Cancel'
    },
    ok: {
        id: 'shared.common.ok',
        defaultMessage: 'OK'
    },
    save: {
        id: 'shared.common.save',
        defaultMessage: 'Save'
    },
    reset: {
        id: 'shared.common.reset.reset',
        defaultMessage: 'Reset'
    },
    missing: {
        id: 'shared.common.missing',
        defaultMessage: 'Missing'
    },
    patientData: {
        id: 'shared.common.patientData',
        defaultMessage: 'Patient data'
    },
    edit: {
        id: 'shared.common.edit',
        defaultMessage: 'Edit'
    },
    memberOf: {
        id: 'shared.common.memberOf',
        defaultMessage: 'Member of'
    },
    active: {
        id: 'shared.common.active',
        defaultMessage: 'Active'
    },
    inactive: {
        id: 'shared.common.inactive',
        defaultMessage: 'Inactive'
    },
    unconfirmed: {
        id: 'shared.common.unconfirmed',
        defaultMessage: 'Unconfirmed'
    },
    statusValue: {
        id: 'shared.common.status-value',
        defaultMessage:
            '{status, select, active { Active } inactive { Inactive } unconfirmed { Unconfirmed }}'
    },
    general: {
        id: 'shared.common.general',
        defaultMessage: 'General'
    },
    journal: {
        id: 'shared.common.journal',
        defaultMessage: 'Journal'
    },
    messages: {
        id: 'shared.common.messages',
        defaultMessage: 'Messages'
    },
    comments: {
        id: 'shared.common.comments',
        defaultMessage: 'New report comments'
    },
    commentsHeader: {
        id: 'shared.common.commentsHeader',
        defaultMessage: 'New report comments since last checked or reset'
    },
    unknownIndicator: {
        id: 'shared.common.unknownIndicator',
        defaultMessage: 'Unknown parameter'
    },
    misc: {
        id: 'shared.common.misc',
        defaultMessage: 'Misc'
    },
    history: {
        id: 'shared.common.history',
        defaultMessage: 'History'
    },
    yes: {
        id: 'shared.common.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'shared.common.no',
        defaultMessage: 'No'
    },
    by: {
        id: 'shared.common.By',
        defaultMessage: 'by'
    },
    deactivate: {
        id: 'shared.common.deactivate',
        defaultMessage: 'Deactivate'
    },
    activate: {
        id: 'shared.common.activate',
        defaultMessage: 'Activate'
    },
    openActionPanel: {
        id: 'shared.common.openActionPanel',
        defaultMessage: 'Open Action Panel'
    },
    settingsPageProfile: {
        id: 'shared.common.settingsPageProfile',
        defaultMessage: 'User info'
    },
    conditionSelect: {
        id: 'shared.common.conditionSelect',
        defaultMessage: 'Select condition'
    },
    overdueBy: {
        id: 'shared.common.overdueBy',
        defaultMessage: 'Overdue by {days}'
    },
    overdue: {
        id: 'shared.common.overdue',
        defaultMessage: 'Overdue'
    },
    note: {
        id: 'shared.common.note',
        defaultMessage: 'Note'
    },
    skip: {
        id: 'shared.common.skip',
        defaultMessage: 'Skip'
    },
    recommendationDynamicDay: {
        id: 'shared.time.recommendation.reminder.dynamic.day',
        defaultMessage:
            'The patient has not reported according to recommendation ({numeric, selectordinal, =0 {never} one {daily} =2 {every other day} few {every #:rd day} other {every #th day}}).'
    },
    removed: {
        id: 'shared.common.removed',
        defaultMessage: 'Removed'
    },
    loggedInAs: {
        id: 'common.loggedInAs',
        defaultMessage: 'Logged in as'
    },
    download: {
        id: 'common.download',
        defaultMessage: 'Download'
    },
    signOut: {
        id: 'common.logout',
        defaultMessage: 'Sign out'
    },
    showDetails: {
        id: 'common.details.show',
        defaultMessage: 'Show details'
    },
    hideDetails: {
        id: 'common.details.hide',
        defaultMessage: 'Hide details'
    },
    or: {
        id: 'common.or',
        defaultMessage: 'Or'
    },
    on: {
        id: 'common.on',
        defaultMessage: 'On'
    },
    off: {
        id: 'common.off',
        defaultMessage: 'Off'
    },
    report: {
        id: 'common.report',
        defaultMessage: 'Report'
    },
    copied: {
        id: 'common.copied',
        defaultMessage: 'Copied!'
    },
    clickToCopy: {
        id: 'common.click-to-copy',
        defaultMessage: 'Click to copy'
    },
    acceptAndActivate: {
        id: 'common.accept-and-activate',
        defaultMessage: 'Accept and activate'
    },
    next: {
        id: 'common.next',
        defaultMessage: 'Next'
    },
    status: {
        id: 'common.status',
        defaultMessage: 'Status'
    }
});

export const common = {
    general,
    patient,
    rootId: 'shared.common.'
};
