import { exercises } from './indicators/exercises/exercises';
import { swellingHs } from './indicators/swelling-hs/swelling-hs';
import { activeMotion } from './indicators/active-motion/active-motion';
import { painAtRest } from './indicators/pain/pain-at-rest';
import { painDuringActivity } from './indicators/pain/pain-during-activity';
import { painAfterActivity } from './indicators/pain/pain-after-activity';
import { medication } from './indicators/medication/medication';
import { orthosisNight } from './indicators/orthosis-night/orthosis-night';
import { orthosisDay } from './indicators/orthosis-day/orthosis-day';
import { sleep } from './indicators/sleep/sleep';
import { grip } from './indicators/grip/grip';
import { dailyActivity } from './indicators/daily-activity/daily-activity';
import { vocationalActivity } from './indicators/vocational-activity/vocational-activity';
import { vas } from '../shared-indicators/vas/vas';
import { conditions } from '../messages';
import { Condition, ConditionIndicator } from '../condition.type';
import { formatMessage } from '../../locale/format/format-message';

const code = 'LP_2_0_HS_RS';
const publicCode = 'hs';

const indicators: ConditionIndicator[] = [
    exercises,
    swellingHs,
    activeMotion,
    painAtRest,
    painDuringActivity,
    painAfterActivity,
    medication,
    orthosisNight,
    orthosisDay,
    sleep,
    grip,
    dailyActivity,
    vocationalActivity,
    vas
];

const condition: Record<string, Condition> = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(conditions.rs.hs.name),
        nameLong: formatMessage(conditions.rs.hs.nameLong)
    }
};

export default condition;
