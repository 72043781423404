import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
`;

export { StyledNavLink };
