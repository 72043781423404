import svSE from 'antd/lib/locale-provider/sv_SE';
import enUS from 'antd/lib/locale-provider/en_US';

import { Locale as AntdLocaleProvider } from 'antd/lib/locale-provider';
import { getLanguageWithoutRegionCode } from '../get-language-without-region-code';

export const getAntdLocale = (): AntdLocaleProvider => {
    // needed for translating antd components such as a RangePicker
    const antdProvider: { [key: string]: AntdLocaleProvider } = {
        sv: svSE,
        en: enUS
    };

    return antdProvider[getLanguageWithoutRegionCode()];
};
