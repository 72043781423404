import { RouteItemType } from '../../../utils/route/switch-routes';
import { LoginPage } from './LoginPage';
import { pathLoginMethods } from './path';
import { titleLoginMethods } from './title';

export const routeLogin: RouteItemType = {
    name: titleLoginMethods,
    link: pathLoginMethods,
    icon: '',
    exact: true,
    component: LoginPage
};
