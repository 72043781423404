import { Indicator } from '../../../types/indicator/indicator.type';
import { rules } from './eq5d3l-rules';

import icon from './images/eq5d3l.svg';
import { messagesEq5d3l } from './messages/messages';

export const parameterEq5d3l = {
    code: 'EQ5D3L'
};

export const eq5d3l: Indicator = {
    code: parameterEq5d3l.code,
    icon,
    messages: messagesEq5d3l,
    rules
};
