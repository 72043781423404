import { indicatorsByCode } from '../../../conditions';
import { missing } from '../../../conditions/shared-indicators/missing/missing';

export const getIndicatorFromCode = (code: string) => {
    const image = indicatorsByCode[code.toUpperCase()];

    if (image) {
        return image.icon;
    }

    console.warn(`IndicatorImage is missing: ${code}`);
    return missing.icon;
};
