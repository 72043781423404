import { createSelector } from 'reselect';
import { ReduxState } from '../../redux-state.type';

export const getPatient = (state: ReduxState) => state.selectedPatient;
const getSelectedConditionId = (state: ReduxState) =>
    state.conditions.selectedCondition;

export const getIndicatorConfigurations = createSelector(
    [getSelectedConditionId, getPatient],
    (selectedConditionId, patient) =>
        patient.conditionConfiguration[selectedConditionId] || {}
);

export const getPatientGroupIds = createSelector(
    getPatient,
    (patient) => patient.groupIds
);

export const getPatientConditionIds = createSelector(
    getPatient,
    (patient) => patient.conditionIds
);
