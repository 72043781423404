import { useEffect } from 'react';
import { useReduxDispatch } from '../use-redux-dispatch';
import { useReduxSelector } from '../use-redux-selector';
import { fetchMedic } from '../../redux/domains/medic/medic.actions';

export function useFetchMedic(): void {
    const dispatch = useReduxDispatch();

    const medicId = useReduxSelector((state) => state.user.externalId);

    useEffect(() => {
        if (medicId) {
            dispatch(fetchMedic(medicId));
        }
    }, [dispatch, medicId]);
}
