import {
    CLEAR_REGISTER_FORM_FIELDS,
    SAVE_REGISTER_FORM_FIELDS
} from './forms.constants';
import { RegisterPatientValues } from './forms.type';

export const saveRegisterFormFields = (values: RegisterPatientValues) => ({
    type: SAVE_REGISTER_FORM_FIELDS,
    payload: values
});

export const clearRegisterFormFields = () => ({
    type: CLEAR_REGISTER_FORM_FIELDS
});
