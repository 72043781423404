import validator from 'validator';

export const checkUrl = (url: string) => {
    if (
        !validator.isURL(url, {
            require_tld: false
        })
    ) {
        throw new Error(`${url} is not a valid url`);
    }

    return url;
};
