import { Indicator } from '../../../types/indicator/indicator.type';
import { rules } from './candy-rules';

import icon from './images/candy.svg';
import { messagesCandy } from './messages/messages';

export const parameterCandy = {
    code: 'CANDY'
};

export const candy: Indicator = {
    code: parameterCandy.code,
    icon,
    messages: messagesCandy,
    rules
};
