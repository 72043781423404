import { intl as defaultIntl } from '../setup/intl/get-intl';
import { FormatNumberOptions } from 'react-intl';

export const numberError = `value cannot be null or undefined`;

export const formatNumber = (
    value: number,
    options?: FormatNumberOptions,
    intl = defaultIntl
): string => {
    if (typeof value === 'number') {
        return intl.formatNumber(value, options);
    }

    throw numberError;
};
