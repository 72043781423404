import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

const FormDetailsTitle = ({ title, date }: any) => {
    return (
        <div>
            <h3>{title}</h3>
            <span>
                {date && (
                    <div
                        style={{
                            fontStyle: 'italic',
                            fontSize: 12,
                            marginTop: 4
                        }}
                    >
                        <FormattedDate value={date} />
                        <FormattedTime value={date}>
                            {(txt: any) => (
                                <span style={{ marginLeft: 5 }}>{txt}</span>
                            )}
                        </FormattedTime>
                    </div>
                )}
            </span>
        </div>
    );
};

export default FormDetailsTitle;
