import { Strings } from '../../../locale/messagesDescriptors';
import { formatMessage } from '../../../locale/format/format-message';
import { colors } from '../../../theme/colors/commonColors';
import { ChartType } from '../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../types/indicator/indicator-rules.type';

export const rules: IndicatorChartHeatmapRules = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        { value: 1, color: colors.chart.heatMap.purple10 },
        { value: 2, color: colors.chart.heatMap.purple30 },
        { value: 3, color: colors.chart.heatMap.purple50 },
        { value: 4, color: colors.chart.heatMap.purple80 }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.swelling.degree.noSwelling.id,
            text: formatMessage(Strings.indicators.swelling.degree.noSwelling),
            color: colors.chart.heatMap.purple10
        },
        {
            textId: Strings.indicators.swelling.degree.lightSwelling.id,
            text: formatMessage(
                Strings.indicators.swelling.degree.lightSwelling
            ),
            color: colors.chart.heatMap.purple30
        },
        {
            textId: Strings.indicators.swelling.degree.moderateSwelling.id,
            text: formatMessage(
                Strings.indicators.swelling.degree.moderateSwelling
            ),
            color: colors.chart.heatMap.purple50
        },
        {
            textId: Strings.indicators.swelling.degree.severeSwelling.id,
            text: formatMessage(
                Strings.indicators.swelling.degree.severeSwelling
            ),
            color: colors.chart.heatMap.purple80
        }
    ],
    boundariesConfiguration: {
        type: 'radio',
        disabledProperties: ['position']
    },
    choices: {
        degree: [
            {
                localeId: Strings.indicators.swelling.degree.noSwelling.id,
                text: formatMessage(
                    Strings.indicators.swelling.degree.noSwelling
                ),
                value: 1
            },
            {
                localeId: Strings.indicators.swelling.degree.lightSwelling.id,
                text: formatMessage(
                    Strings.indicators.swelling.degree.lightSwelling
                ),
                value: 2
            },
            {
                localeId:
                    Strings.indicators.swelling.degree.moderateSwelling.id,
                text: formatMessage(
                    Strings.indicators.swelling.degree.moderateSwelling
                ),
                value: 3
            },
            {
                localeId: Strings.indicators.swelling.degree.severeSwelling.id,
                text: formatMessage(
                    Strings.indicators.swelling.degree.severeSwelling
                ),
                value: 4
            }
        ],
        position: [
            {
                localeId: Strings.indicators.swelling.position.legs.id,
                text: formatMessage(Strings.indicators.swelling.position.legs),
                value: 1
            },
            {
                localeId: Strings.indicators.swelling.position.general.id,
                text: formatMessage(
                    Strings.indicators.swelling.position.general
                ),
                value: 2
            }
        ]
    }
};
