import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Bag weight',
        id: 'indicator.weight-pd.title'
    },
    unit: {
        id: 'indicator.weight-pd.unit',
        defaultMessage: 'g'
    }
});

export const messagesWeightPd = {
    general
};
