import { prnmed as sharedPrnmed } from '../../../shared-indicators/prnmed/prnmed';
import { messagesPrnmedLvad } from './messages/messages';
import { PrnmedLVADProperties } from './prnmed-lvad-properties';
import { rules } from './prnmed-lvad-rules';

export const parameterPrnmedLvad = {
    code: 'PRNMED-LVAD',
    properties: PrnmedLVADProperties
};

export const prnmed: typeof sharedPrnmed = {
    ...sharedPrnmed,
    code: parameterPrnmedLvad.code,
    messages: messagesPrnmedLvad,
    rules
};
