import { prnmed } from './indicators/prnmed/prnmed';
import { dizziness } from '../shared-indicators/dizziness/dizziness';
import { swelling } from '../shared-indicators/swelling/swelling';
import { breathlessness } from '../shared-indicators/breathlessness/breathlessness';
import { tiredness } from '../shared-indicators/tiredness/tiredness';
import { ehra } from './indicators/ehra/ehra';
import { bodyWeight } from '../shared-indicators/body-weight/body-weight';
import { pulse } from '../shared-indicators/pulse/pulse';
import { bloodPressure } from '../shared-indicators/blood-pressure/blood-pressure';
import { waistCircumference } from '../shared-indicators/waist-circumference/waist-circumference';
import { vas } from '../shared-indicators/vas/vas';
import { smoke } from '../shared-indicators/smoke/smoke';
import { physact } from '../shared-indicators/physact/physact';
import { eq5d3l } from '../shared-indicators/eq5d3l/eq5d3l';
import { conditions } from '../messages';
import { ConditionIndicator } from '../condition.type';
import { formatMessage } from '../../locale/format/format-message';

const code = 'LP_2_0_AF_RS';
const publicCode = 'af';

const indicators: ConditionIndicator[] = [
    prnmed,
    dizziness,
    swelling,
    breathlessness,
    tiredness,
    ehra,
    bodyWeight,
    pulse,
    bloodPressure,
    waistCircumference,
    vas,
    smoke,
    physact,
    eq5d3l
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(conditions.rs.af.name),
        nameLong: formatMessage(conditions.rs.af.nameLong)
    }
};

export default condition;
