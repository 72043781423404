import React, { PropsWithChildren } from 'react';
import {
    StyledColumn,
    StyledColumnRight,
    StyledRowTitle,
    StyledRowWrapper
} from './styled-column';

type Props = {
    icon: JSX.Element;
    title?: string;
    onClick: () => void;
};

export const ColumnVideoItem = (
    props: PropsWithChildren<Props>
): JSX.Element => (
    <StyledRowWrapper
        style={{ cursor: 'pointer' }}
        onClick={() => props.onClick()}
    >
        <StyledColumn>
            <StyledRowTitle>{props.title}</StyledRowTitle>
            <div>{props.children}</div>
        </StyledColumn>
        <StyledColumnRight>{props.icon}</StyledColumnRight>
    </StyledRowWrapper>
);
