import { prnmed } from './indicators/prnmed/prnmed';
import { cat } from './indicators/cat/cat';
import { mmrc } from './indicators/mmrc/mmrc';
import { physact } from './indicators/physact/physact';
import { phlegm } from './indicators/phlegm/phlegm';
import { bodyLength } from '../shared-indicators/body-length/body-length';
import { bodyWeight } from './indicators/body-weight/body-weight';
import { bmi } from '../shared-indicators/bmi/bmi';
import { appetite } from './indicators/appetite/appetite';
import { smoke } from './indicators/smoke/smoke';
import { vas } from '../shared-indicators/vas/vas';
import { eq5d3l } from '../shared-indicators/eq5d3l/eq5d3l';
import { conditions } from '../messages';
import { ConditionIndicator } from '../condition.type';
import { formatMessage } from '../../locale/format/format-message';

const code = 'LP_2_0_COPD_RS';
const publicCode = 'copd';

const indicators: ConditionIndicator[] = [
    prnmed,
    cat,
    mmrc,
    physact,
    phlegm,
    bodyLength,
    bodyWeight,
    bmi,
    appetite,
    smoke,
    vas,
    eq5d3l
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(conditions.rs.copd.name),
        nameLong: formatMessage(conditions.rs.copd.nameLong)
    }
};

export default condition;
