import React, { FormEvent, useState } from 'react';
import { TextField } from '../../../../../components/form/inputs/TextField';
import { isEveryFieldValid } from '../../../../../components/form/inputs/validation';
import { formatMessage } from '../../../../../locale/format/format-message';
import { messages } from '../../../messages';
import { ContentCardLayout } from '../../../../../components/content/ContentLayout';
import { FieldNamesBasic } from '../../field-names';
import { StyledTitle } from '../../styled';
import { useHistory } from '../../../../../hooks/use-router';
import { InputFields } from '../../../../../components/form/inputs/types';
import { CreateMedicBasicModal } from './CreateMedicBasicModal';
import { formatPhoneNumber } from '../../../../../redux/utils/format-phone-number';
import { CreateBasicAuthMedic } from '../../../../../redux/domains/user/user.api.create.type';
import { CreateFormActions } from '../CreateFormActions';

export const CreateBasicMedic = (props: {
    fields: InputFields;
}): JSX.Element => {
    const { fields } = props;

    const routeHistory = useHistory();

    const [submitModalVisible, setSubmitModalVisible] = useState(false);

    const [isFormValid, setIsFormValid] = useState<boolean>(
        isEveryFieldValid(fields)
    );

    const [pendingMedicUser, setPendingMedicUser] =
        useState<CreateBasicAuthMedic>({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            medicTitle: '',
            password: '',
            groups: []
        });

    return (
        <ContentCardLayout>
            <StyledTitle>
                {formatMessage(messages.registerMedic.add)}
            </StyledTitle>

            <form onChange={onChange} onSubmit={onSubmit}>
                <TextField field={fields[FieldNamesBasic.firstName]} />
                <TextField field={fields[FieldNamesBasic.lastName]} />

                <TextField field={fields[FieldNamesBasic.phoneNumber]} />
                <TextField field={fields[FieldNamesBasic.medicTitle]} />

                <TextField field={fields[FieldNamesBasic.email]} />
                <TextField field={fields[FieldNamesBasic.password]} />

                <CreateFormActions onBack={onBack} isFormValid={isFormValid} />
            </form>

            {submitModalVisible ? (
                <CreateMedicBasicModal
                    pendingMedicUser={pendingMedicUser}
                    setSubmitModalVisible={setSubmitModalVisible}
                />
            ) : (
                <></>
            )}
        </ContentCardLayout>
    );

    function onBack() {
        routeHistory.goBack();
    }

    function onChange() {
        setIsFormValid(isEveryFieldValid(fields));
    }

    function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const currentTarget = event.currentTarget;

        setSubmitModalVisible(true);

        const pendingUser: CreateBasicAuthMedic = {
            firstName: currentTarget[FieldNamesBasic.firstName].value,
            lastName: currentTarget[FieldNamesBasic.lastName].value,
            email: currentTarget[FieldNamesBasic.email].value,
            password: currentTarget[FieldNamesBasic.password].value,
            phoneNumber: formatPhoneNumber(
                currentTarget[FieldNamesBasic.phoneNumber].value
            ),
            medicTitle: currentTarget[FieldNamesBasic.medicTitle].value,
            groups: []
        };

        setPendingMedicUser(pendingUser);
    }
};
