import React from 'react';
import { Modal, Alert, Button } from 'antd';
import { IndicatorMeta } from '../../../../../../../../conditions/indicator-meta';
import { RadioFormGroupWrapper } from '../../forms/form-sections/indicator-configuration/radio/RadioFormGroupWrapper';
import SliderFormGroupWrapper from '../../forms/form-sections/indicator-configuration/slider/SliderFormGroupWrapper';
import TitleWithIcon from '../title-with-icon/TitleWithIcon';
import { Strings } from '../../../../../../../../locale/messagesDescriptors';
import {
    formatMessage,
    formatMessageById
} from '../../../../../../../../locale/format/format-message';
import styled from 'styled-components';

const ConfirmIndicatorConfigurationModal = ({
    handleReset,
    handleSubmit,
    values,
    setFieldValue,
    showConfirmLimitsModal,
    setShowConfirmLimitsModal,
    handleChange,
    indicator
}: any) => {
    const { code, settings: { rules = {} } = {} } = indicator;
    const { boundariesConfiguration = {} } = rules;

    const handleResetForm = () => {
        handleReset();
        setFieldValue('configuration.in_use', true);
    };

    const handleCancel = () => {
        setShowConfirmLimitsModal(false);
        handleReset();
    };

    const handleOk = () => {
        setShowConfirmLimitsModal(false);
        handleSubmit();
    };

    const meta = IndicatorMeta[indicator.transformedCode];

    return (
        <Modal
            visible={showConfirmLimitsModal}
            width={600}
            footer={[
                <StyledButtonContainer key="buttons">
                    <div>
                        <Button key="reset" onClick={handleResetForm}>
                            {formatMessage(Strings.common.general.reset)}
                        </Button>
                    </div>
                    <div>
                        <Button key="cancel" onClick={handleCancel}>
                            {formatMessage(Strings.common.general.cancel)}
                        </Button>
                        <Button key="submit" type="primary" onClick={handleOk}>
                            {formatMessage(
                                Strings.common.general.acceptAndActivate
                            )}
                        </Button>
                    </div>
                </StyledButtonContainer>
            ]}
            title={<TitleWithIcon indicatorCode={code} />}
            destroyOnClose={true}
            closable={false}
        >
            <Alert
                message={formatMessage(Strings.limits.general.activateAlert, {
                    indicator: formatMessageById(
                        `${
                            Strings.indicators.rootId
                        }${indicator.code.toLowerCase()}.title`
                    )
                })}
                type="info"
                showIcon
                style={{ marginBottom: 30 }}
            />
            {boundariesConfiguration.type === 'radio' ? (
                <RadioFormGroupWrapper
                    values={values}
                    setFieldValue={setFieldValue}
                    indicatorMeta={meta}
                    rules={rules}
                />
            ) : (
                <SliderFormGroupWrapper
                    values={values}
                    setFieldValue={setFieldValue}
                    indicator={indicator}
                    indicatorMeta={meta}
                    handleChange={handleChange}
                    rules={rules}
                />
            )}
        </Modal>
    );
};

export default ConfirmIndicatorConfigurationModal;

export const StyledButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
