export const normalizeNotifications = (data: any, activeConversation: any) => {
    return data.reduce((acc: any, curr: any) => {
        const subjectId = curr.meta.subjectId || curr.sender;
        const obj = acc[subjectId] || {};

        if (curr.notificationType === 'message') {
            const {
                meta: { conversationId }
            } = curr;
            if (conversationId === activeConversation) {
                const tags = ['ignore'];
                curr = {
                    ...curr,
                    tags
                };
            }
        }

        acc[subjectId] = {
            byId: {
                ...obj.byId,
                [curr.id]: curr
            },
            allIds: [...(obj.allIds || []), curr.id]
        };
        return acc;
    }, {});
};
