import { prnmed as sharedPrnmed } from '../../../shared-indicators/prnmed/prnmed';
import { messagesPrnmedCi } from './messages/messages';
import { PrnmedCIProperties } from './prnmed-ci-properties';
import { rules } from './prnmed-ci-rules';

export const parameterPrnmedCi = {
    code: 'PRNMED-CI',
    properties: PrnmedCIProperties
};

export const prnmedCI: typeof sharedPrnmed = {
    ...sharedPrnmed,
    code: parameterPrnmedCi.code,
    messages: messagesPrnmedCi,
    rules
};
