import { Action, combineReducers } from 'redux';
import { removeTokensFromLocalStorage } from '../../utils/storage/auth/remove-tokens-from-local-storage';
import actionPanel from '../domains/action-panel/action-panel.reducer';
import selectedPatient from '../domains/active-patient/active-patient.reducer';
import { LOGOUT_USER_FULFILLED } from '../domains/authentication/authentication.constants';
import authentication from '../domains/authentication/authentication.reducer';
import chat from '../domains/chat/chat.reducer';
import conditions from '../domains/conditions/conditions.reducer';
import events from '../domains/events/events.reducer';
import forms from '../domains/forms/forms.reducer';
import { appStateReducer as appState } from '../domains/app-state/app-state.reducer';
import groups from '../domains/groups/groups.reducer';
import indicators from '../domains/indicators/indicators.reducer';
import motd from '../domains/motd/motd.reducer';
import statistics from '../domains/statistics/statistics.reducer';
import notifications from '../domains/notifications/notifications.reducer';
import patientAdministration from '../domains/patient-administration/patient-administration.reducer';
import { patientData } from '../domains/patient-data/patient-data.reducer';
import priority from '../domains/priority/priority.reducer';
import { priorityByGroupReducer } from '../domains/priority/priority.reducer';
import reminders from '../domains/reminders/reminders.reducer';
import requests from '../domains/requests/requests.reducer';
import settings from '../domains/settings/settings.reducer';
import ui from '../domains/ui/ui.reducer';
import user from '../domains/user/user.reducer';
import values from '../domains/values/values.reducer';
import videoMeeting from '../domains/video-meeting/video-meeting.reducer';
import { patientMetaReducer as patientMeta } from '../domains/patient-meta/patient-meta.reducer';
import { chartReducer } from '../domains/chart/chart.reducer';
import { errorBoundaryReducer } from '../domains/error-boundary/error-boundary.reducer';

const appReducer = combineReducers({
    actionPanel,
    appState,
    authentication,
    charts: chartReducer,
    chat,
    conditions,
    events,
    errorBoundary: errorBoundaryReducer,
    forms,
    groups,
    indicators,
    motd,
    notifications,
    patientAdministration,
    patientData,
    patientMeta,
    priority,
    reminders,
    requests,
    selectedPatient,
    settings,
    statistics,
    ui,
    user,
    values,
    videoMeeting,
    priorityByGroupReducer
});

type AppReducer = typeof appReducer;

const rootReducer: AppReducer = (state, action: Action) => {
    if (action.type === LOGOUT_USER_FULFILLED) {
        removeTokensFromLocalStorage();
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
