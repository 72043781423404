import { RouteItemType } from '../../utils/route/switch-routes';
import { MessageOfTheDayPage } from './MessageOfTheDayPage';
import { pathMessageOfTheDay } from './path';
import { titleMessageOfTheDay } from './title';

export const routeMessageOfTheDay: RouteItemType = {
    name: titleMessageOfTheDay,
    link: pathMessageOfTheDay,
    icon: '',
    component: MessageOfTheDayPage
};
