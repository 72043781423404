import { rules as activeMotionHsRules } from '../../../conditions/hand-surgery/indicators/active-motion/active-motion-rules';
import { rules as appetiteRules } from '../../../conditions/shared-indicators/appetite/appetite-rules';
import { rules as bloodPressureLvadRules } from '../../../conditions/left-ventricular-assist-device/indicators/blood-pressure-average/blood-pressure-lvad-rules';
import { rules as bloodPressureRules } from '../../../conditions/shared-indicators/blood-pressure/blood-pressure-rules';
import { rules as bloodSugarRules } from '../../../conditions/shared-indicators/blood-sugar/blood-sugar-rules';
import { rules as bmiRules } from '../../../conditions/shared-indicators/bmi/bmi-rules';
import { rules as bodyLengthRules } from '../../../conditions/shared-indicators/body-length/body-length-rules';
import { rules as bodyWeightRules } from '../../../conditions/shared-indicators/body-weight/body-weight-rules';
import { rules as breathlessnessRules } from '../../../conditions/shared-indicators/breathlessness/breathlessness-rules';
import { rules as candyRules } from '../../../conditions/shared-indicators/candy/candy-rules';
import { rules as catRules } from '../../../conditions/chronic-obstructive-pulmonary-disease/indicators/cat/cat-rules';
import { rules as dailyActivityHsRules } from '../../../conditions/hand-surgery/indicators/daily-activity/daily-activity-hs-rules';
import { rules as dizzinessRules } from '../../../conditions/shared-indicators/dizziness/dizziness-rules';
import { rules as ehfscbsRules } from '../../../conditions/heart-failure/indicators/ehfscbs/ehfscbs-rules';
import { rules as ehraRules } from '../../../conditions/atrial-fibrillation/indicators/ehra/ehra-rules';
import { rules as eq5d3lRules } from '../../../conditions/shared-indicators/eq5d3l/eq5d3l-rules';
import { rules as exercisesHsRules } from '../../../conditions/hand-surgery/indicators/exercises/exercises-hs-rules';
import { rules as fishRules } from '../../../conditions/shared-indicators/fish/fish-rules';
import { rules as gripHsRules } from '../../../conditions/hand-surgery/indicators/grip/grip-hs-rules';
import { rules as medicationCiRules } from '../../../conditions/intermittent-claudication/indicators/medication/medication-ci-rules';
import { rules as medicationHsRules } from '../../../conditions/hand-surgery/indicators/medication/medication-hs-rules';
import { rules as mmrcRules } from '../../../conditions/shared-indicators/mmrc/mmrc-rules';
import { rules as orthosisDayHsRules } from '../../../conditions/hand-surgery/indicators/orthosis-day/orthosis-day-hs-rules';
import { rules as orthosisNightHsRules } from '../../../conditions/hand-surgery/indicators/orthosis-night/orthosis-night-hs-rules';
import { rules as painAfterActivityHs } from '../../../conditions/hand-surgery/indicators/pain/pain-after-activity-hs-rules';
import { rules as painAtRestHs } from '../../../conditions/hand-surgery/indicators/pain/pain-at-rest-hs-rules';
import { rules as painCiRules } from '../../../conditions/shared-indicators/pain/pain-ci-rules';
import { rules as painDuringActivityHs } from '../../../conditions/hand-surgery/indicators/pain/pain-during-activity-hs-rules';
import { rules as phlegmRules } from '../../../conditions/shared-indicators/phlegm/phlegm-rules';
import { rules as physactRules } from '../../../conditions/shared-indicators/physact/physact-rules';
import { rules as prnmedCopdRules } from '../../../conditions/chronic-obstructive-pulmonary-disease/indicators/prnmed/prnmed_copd-rules';
import { rules as prnmedAfRules } from '../../../conditions/atrial-fibrillation/indicators/prnmed/prnmed-af-rules';
import { rules as prnmedCiRules } from '../../../conditions/intermittent-claudication/indicators/prnmed/prnmed-ci-rules';
import { rules as prnmedLvadRules } from '../../../conditions/left-ventricular-assist-device/indicators/prnmed/prnmed-lvad-rules';
import { rules as prnmedRules } from '../../../conditions/shared-indicators/prnmed/prnmed-rules';
import { rules as pulseRules } from '../../../conditions/shared-indicators/pulse/pulse-rules';
import { rules as pumpFlowRules } from '../../../conditions/left-ventricular-assist-device/indicators/pump-flow/pump-flow-lvad-rules';
import { rules as pumpPowerRules } from '../../../conditions/left-ventricular-assist-device/indicators/pump-power/pump-power-lvad-rules';
import { rules as pumpPulseIndexRules } from '../../../conditions/left-ventricular-assist-device/indicators/pump-pulse-index/pump-pulse-index-lvad-rules';
import { rules as pumpSelfTestRules } from '../../../conditions/left-ventricular-assist-device/indicators/pump-self-test/pump-self-test-lvad-rules';
import { rules as pumpSpeedRules } from '../../../conditions/left-ventricular-assist-device/indicators/pump-speed/pump-speed-lvad-rules';
import { rules as sleepHsRules } from '../../../conditions/hand-surgery/indicators/sleep/sleep-hs-rules';
import { rules as smokeRules } from '../../../conditions/shared-indicators/smoke/smoke-rules';
import { rules as swellingHsRules } from '../../../conditions/hand-surgery/indicators/swelling-hs/swelling-hs-rules';
import { rules as swellingRules } from '../../../conditions/shared-indicators/swelling/swelling-rules';
import { rules as tirednessRules } from '../../../conditions/shared-indicators/tiredness/tiredness-rules';
import { rules as vasRules } from '../../../conditions/shared-indicators/vas/vas-rules';
import { rules as vegetablesRules } from '../../../conditions/shared-indicators/vegetables/vegetables-rules';
import { rules as vocationalActivityHsRules } from '../../../conditions/hand-surgery/indicators/vocational-activity/vocational-activity-hs-rules';
import { rules as waistCircumferenceRules } from '../../../conditions/shared-indicators/waist-circumference/waist-circumference-rules';
import { rules as weightPdRules } from '../../../conditions/shared-indicators/weight-pd/weight-pd-rules';
import { rules as respiratoryProblemsRules } from '../../../conditions/spinal-cord-injury/indicators/respiratory-problems/respiratory-problems-rules';
import { rules as oxygenSaturationRules } from '../../../conditions/spinal-cord-injury/indicators/oxygen-saturation/oxygen-saturation-rules';
import { rules as bodyTempRules } from '../../../conditions/shared-indicators/body-temp/body-temp-rules';
import { rules as urineLeakageRules } from '../../../conditions/spinal-cord-injury/indicators/urine-leakage/urine-leakage-rules';
import { rules as bowelDysfunctionRules } from '../../../conditions/spinal-cord-injury/indicators/bowel-dysfunction/bowel-dysfunction-rules';
import { rules as liquidMeasurementRules } from '../../../conditions/spinal-cord-injury/indicators/liquid-measurement/liquid-measurement-rules';
import { rules as sleepSCIRules } from '../../../conditions/spinal-cord-injury/indicators/sleep-sci/sleep-sci-rules';
import { IndicatorMeta } from '../../../conditions/indicator-meta';

export default {
    [IndicatorMeta.ACTIVE_MOTION_HS.code]: activeMotionHsRules,
    [IndicatorMeta.APPETITE.code]: appetiteRules,
    [IndicatorMeta.BLOOD_PRESSURE.code]: bloodPressureRules,
    [IndicatorMeta.BLOOD_PRESSURE_LVAD.code]: bloodPressureLvadRules,
    [IndicatorMeta.BLOOD_SUGAR.code]: bloodSugarRules,
    [IndicatorMeta.BMI.code]: bmiRules,
    [IndicatorMeta.BODY_LENGTH.code]: bodyLengthRules,
    [IndicatorMeta.BODY_WEIGHT.code]: bodyWeightRules,
    [IndicatorMeta.BREATHLESSNESS.code]: breathlessnessRules,
    [IndicatorMeta.CANDY.code]: candyRules,
    [IndicatorMeta.CAT.code]: catRules,
    [IndicatorMeta.DAILY_ACTIVITY_HS.code]: dailyActivityHsRules,
    [IndicatorMeta.DIZZINESS.code]: dizzinessRules,
    [IndicatorMeta.EHFSCBS.code]: ehfscbsRules,
    [IndicatorMeta.EHRA.code]: ehraRules,
    [IndicatorMeta.EQ5D3L.code]: eq5d3lRules,
    [IndicatorMeta.EXERCISES_HS.code]: exercisesHsRules,
    [IndicatorMeta.FISH.code]: fishRules,
    [IndicatorMeta.GRIP_HS.code]: gripHsRules,
    [IndicatorMeta.MEDICATION_CI.code]: medicationCiRules,
    [IndicatorMeta.MEDICATION_HS.code]: medicationHsRules,
    [IndicatorMeta.MMRC.code]: mmrcRules,
    [IndicatorMeta.ORTHOSIS_DAY_HS.code]: orthosisDayHsRules,
    [IndicatorMeta.ORTHOSIS_NIGHT_HS.code]: orthosisNightHsRules,
    [IndicatorMeta.PAIN_AFTER_ACTIVITY_HS.code]: painAfterActivityHs,
    [IndicatorMeta.PAIN_AT_REST_HS.code]: painAtRestHs,
    [IndicatorMeta.PAIN_CI.code]: painCiRules,
    [IndicatorMeta.PAIN_DURING_ACTIVITY_HS.code]: painDuringActivityHs,
    [IndicatorMeta.PHLEGM.code]: phlegmRules,
    [IndicatorMeta.PHYSACT.code]: physactRules,
    [IndicatorMeta.PRNMED.code]: prnmedRules,
    [IndicatorMeta.PRNMED_AF.code]: prnmedAfRules,
    [IndicatorMeta.PRNMED_CI.code]: prnmedCiRules,
    [IndicatorMeta.PRNMED_COPD.code]: prnmedCopdRules,
    [IndicatorMeta.PRNMED_LVAD.code]: prnmedLvadRules,
    [IndicatorMeta.PUMP_FLOW_LVAD.code]: pumpFlowRules,
    [IndicatorMeta.PUMP_POWER_LVAD.code]: pumpPowerRules,
    [IndicatorMeta.PUMP_PULSE_INDEX_LVAD.code]: pumpPulseIndexRules,
    [IndicatorMeta.PUMP_SELF_TEST_LVAD.code]: pumpSelfTestRules,
    [IndicatorMeta.PUMP_SPEED_LVAD.code]: pumpSpeedRules,
    [IndicatorMeta.PULSE.code]: pulseRules,
    [IndicatorMeta.SLEEP_HS.code]: sleepHsRules,
    [IndicatorMeta.SMOKE.code]: smokeRules,
    [IndicatorMeta.SWELLING.code]: swellingRules,
    [IndicatorMeta.SWELLING_HS.code]: swellingHsRules,
    [IndicatorMeta.TIREDNESS.code]: tirednessRules,
    [IndicatorMeta.VAS.code]: vasRules,
    [IndicatorMeta.VEGETABLES.code]: vegetablesRules,
    [IndicatorMeta.VOCATIONAL_ACTIVITY_HS.code]: vocationalActivityHsRules,
    [IndicatorMeta.WAIST_CIRCUMFERENCE.code]: waistCircumferenceRules,
    [IndicatorMeta.WEIGHT_PD.code]: weightPdRules,
    [IndicatorMeta.RESPIRATORY_PROBLEMS.code]: respiratoryProblemsRules,
    [IndicatorMeta.OXYGEN_SATURATION.code]: oxygenSaturationRules,
    [IndicatorMeta.BODY_TEMP.code]: bodyTempRules,
    [IndicatorMeta.URINE_LEAKAGE.code]: urineLeakageRules,
    [IndicatorMeta.BOWEL_DYSFUNCTION.code]: bowelDysfunctionRules,
    [IndicatorMeta.LIQUID_MEASUREMENT.code]: liquidMeasurementRules,
    [IndicatorMeta.SLEEP_SCI.code]: sleepSCIRules
};
