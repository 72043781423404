import { RouteItemType } from '../../../utils/route/switch-routes';
import { Logout } from './Logout';
import { pathLogout } from './path';

export const routeLogout: RouteItemType = {
    name: '',
    link: pathLogout,
    icon: '',
    exact: true,
    component: Logout
};
