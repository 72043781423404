import { defineMessages } from 'react-intl';

const general = defineMessages({
    showSidebar: {
        id: 'shared.ui.header.showSidebar',
        defaultMessage: 'Show menu'
    },
    hideSidebar: {
        id: 'shared.ui.header.hideSidebar',
        defaultMessage: 'Hide menu'
    },
    profile: {
        id: 'shared.ui.header.profile',
        defaultMessage: 'My Profile'
    },
    about: {
        id: 'shared.ui.header.about',
        defaultMessage: 'About LifePod'
    },
    signOut: {
        id: 'shared.ui.header.sign-out',
        defaultMessage: 'Sign out'
    },
    signOutTitle: {
        id: 'shared.header.sign-out.title',
        defaultMessage: 'Are you sure you want to sign out?'
    },
    signOutBtnOk: {
        id: 'shared.header.sign-out.btn.ok',
        defaultMessage: 'Yes, sign out'
    },
    signOutBtnCancel: {
        id: 'shared.header.sign-out.btn.cancel',
        defaultMessage: 'No'
    }
});

export const header = {
    general
};
