import React from 'react';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { Medics } from '../../../redux/domains/statistics/statistics.type';
import {
    StyledActions,
    StyledActionWrapper,
    StyledCoWorkerContent,
    StyledCoWorkerInfo,
    StyledH4,
    StyledH4LeftAligned,
    StyledP,
    StyledProgressWrapper
} from '../styles/co-worker-styles';
import { Progressbar } from './Progressbar';

export function CoworkerContent(props: {
    medics: Medics;
    count: number;
}): JSX.Element {
    const { messages, watchovers, feedbacks } = props.medics.actions;

    const actions = { messages, watchovers, feedbacks };

    return (
        <StyledCoWorkerContent>
            <StyledCoWorkerInfo>
                <div className="styledCoworkerinfo">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    {formatMessage(
                                        Strings.statistics.coworker.access
                                    )}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {props.medics.count}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {formatMessage(
                                        Strings.statistics.coworker.loggedIn
                                    )}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {props.medics.loggedIn}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {formatMessage(
                                        Strings.statistics.coworker.logInAmount
                                    )}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {props.medics.sessions}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </StyledCoWorkerInfo>
            <StyledActionWrapper>
                <StyledProgressWrapper>
                    <StyledH4>
                        {formatMessage(Strings.statistics.bar.title)}
                    </StyledH4>
                    {Object.entries(actions).map((item: any) => {
                        const progress = Math.round(
                            (item[1].patients / props.count) * 100
                        );

                        return (
                            <div key={item[0]}>
                                <StyledP>
                                    {item[0] === 'watchovers' &&
                                        formatMessage(
                                            Strings.statistics.page.watchovers
                                        )}
                                    {item[0] === 'messages' &&
                                        formatMessage(
                                            Strings.statistics.page.messages
                                        )}
                                    {item[0] === 'feedbacks' &&
                                        formatMessage(
                                            Strings.statistics.page.feedback
                                        )}
                                </StyledP>
                                <Progressbar progress={progress} />
                            </div>
                        );
                    })}
                </StyledProgressWrapper>

                <StyledActions>
                    <StyledH4LeftAligned>
                        {formatMessage(Strings.statistics.coworker.title)}
                    </StyledH4LeftAligned>
                    <div style={{ marginTop: '20px' }}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        {formatMessage(
                                            Strings.statistics.bar.limits
                                        )}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {
                                            props.medics.actions.changedLimits
                                                .count
                                        }
                                        (
                                        {formatMessage(
                                            Strings.statistics.coworker
                                                .patients,
                                            {
                                                numeric:
                                                    props.medics.actions
                                                        .changedLimits.patients
                                            }
                                        )}
                                        )
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {formatMessage(
                                            Strings.statistics.bar.resets
                                        )}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {props.medics.actions.resetPrio.count} (
                                        {formatMessage(
                                            Strings.statistics.coworker
                                                .patients,
                                            {
                                                numeric:
                                                    props.medics.actions
                                                        .resetPrio.patients
                                            }
                                        )}
                                        )
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </StyledActions>
            </StyledActionWrapper>
        </StyledCoWorkerContent>
    );
}
