import { normalize, schema } from 'normalizr';
import { NormalizedIndicators } from '../indicators/indicator.type';
import { ConditionResponse, NormalizedConditions } from './conditions.api.type';

export type NormalizedConditionsState = {
    indicators: NormalizedIndicators;
    conditions: NormalizedConditions;
};

export const normalizeConditions = (
    data: ConditionResponse[]
): NormalizedConditionsState => {
    const indicatorSchema = new schema.Entity('indicators');
    const conditionsSchema = new schema.Entity('conditions', {
        indicators: [indicatorSchema]
    });

    const normalizedData = normalize(data, [conditionsSchema]);
    const { indicators, conditions } = normalizedData.entities;

    return {
        indicators: {
            byId: indicators,
            allIds: Object.keys(indicators)
        },
        conditions: {
            byId: conditions,
            allIds: Object.keys(conditions)
        }
    };
};
