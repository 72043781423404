import { defaultValidationTexts } from '../../../../components/form/inputs/default-validation-texts';
import {
    placeholders,
    validation
} from '../../../../components/form/inputs/message-descriptors';
import {
    FieldType,
    InputFields,
    InputType
} from '../../../../components/form/inputs/types';
import { validateSsn } from '../../../../components/form/inputs/validation';
import { details } from '../../../../components/medic/details/message-descriptors';
import { Strings } from '../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../locale/format/format-message';
import { MedicMeta } from '../../../../redux/domains/medic/medic.type';

export const getInitialFields = (meta: MedicMeta): InputFields => {
    const fields: InputFields = {
        firstName: {
            fieldType: FieldType.Input,
            name: 'firstName',
            label: formatMessage(Strings.labels.label.firstName),
            value: meta.name,
            isValid: true,
            isTouched: false,
            validationMessage: null,
            minLength: 2,
            required: true,
            indicateRequired: true,
            validationMessages: {
                ...defaultValidationTexts,
                tooShort: formatMessage(validation.shorterThanText, {
                    minLength: 2
                })
            }
        },
        lastName: {
            fieldType: FieldType.Input,
            name: 'lastName',
            label: formatMessage(Strings.labels.label.familyName),
            value: meta.family_name,
            isValid: true,
            isTouched: false,
            validationMessage: null,
            minLength: 2,
            required: true,
            indicateRequired: true,
            validationMessages: {
                ...defaultValidationTexts,
                tooShort: formatMessage(validation.shorterThanText, {
                    minLength: 2
                })
            }
        },
        ssn: {
            fieldType: FieldType.Input,
            name: 'ssn',
            label: formatMessage(details.fields.ssn),
            value: meta.personal_id ? meta.personal_id.replace('-', '') : '',
            placeholder: formatMessage(placeholders.ssn),
            isValid: true,
            isTouched: false,
            validationMessage: null,
            validationMessages: defaultValidationTexts,
            validate: function () {
                return validateSsn(this);
            }
        },
        email: {
            fieldType: FieldType.Input,
            name: 'email',
            label: formatMessage(Strings.labels.label.email),
            value: meta.email,
            type: InputType.Email,
            isValid: true,
            isTouched: false,
            validationMessage: null,
            required: true,
            indicateRequired: true,
            pattern:
                "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$",
            validationMessages: {
                ...defaultValidationTexts,
                custom: formatMessage(validation.invalidEmail)
            }
        },
        phone: {
            fieldType: FieldType.Input,
            name: 'phone',
            label: formatMessage(Strings.labels.label.phoneNumber),
            value: meta.phone_number,
            type: InputType.Tel,
            isValid: true,
            isTouched: false,
            pattern: '^[+0][0-9-]+$',
            validationMessage: null,
            validationMessages: {
                ...defaultValidationTexts,
                custom: formatMessage(validation.invalidPhoneNumber)
            }
        },
        medicTitle: {
            fieldType: FieldType.Input,
            name: 'medicTitle',
            label: formatMessage(Strings.labels.label.title),
            value: meta.medicTitle || '',
            isValid: true,
            isTouched: false,
            validationMessage: null,
            validationMessages: defaultValidationTexts
        }
    };

    if (!meta.personal_id) {
        delete fields['ssn'];
    }

    return fields;
};
