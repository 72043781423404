import { Indicator } from '../../../types/indicator/indicator.type';

import icon from './images/pulse.svg';
import { messagesPulse } from './messages/messages';
import { rules } from './pulse-rules';

export const parameterPulse = {
    code: 'PULSE'
};

export const pulse: Indicator = {
    code: parameterPulse.code,
    icon,
    messages: messagesPulse,
    rules
};
