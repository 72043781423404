import React from 'react';
import { Divider, Table } from 'antd';

import FormDetailsTitle from './FormDetailsTitle';
import { EHFSCBS } from '../../../../../../../conditions/indicator-meta';
import {
    formatMessage,
    formatMessageById
} from '../../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../../locale/messagesDescriptors';

const FormDetails = ({ indicator, selectedRow }: any) => {
    const { form, code } = indicator;
    const { value = {}, date = null } = selectedRow;

    const columns = [
        {
            title: formatMessage(Strings.ui.table.general.assertion),
            dataIndex: 'assertion',
            width: 300,
            key: 'assertion',
            render: (text: any, row: any) => {
                const { item } = row;
                const indicatorCode = indicator.code.toLowerCase();
                const { code: itemCode } = item;

                const id = item.description
                    ? 'description'
                    : item.title
                    ? 'title'
                    : '';

                const localeId = `${indicatorCode}.${itemCode}.choices.${id}`;

                return formatMessageById(
                    `${Strings.indicators.rootId}${localeId}`
                );
            }
        },
        {
            title: formatMessage(Strings.ui.table.general.answer),
            key: 'answer',
            dataIndex: 'answer',
            render: (text = {}, row: any) => {
                const { item } = row;
                const { code: itemCode } = item;

                const indicatorCode = indicator.code.toLowerCase();
                const value = Object.values(text);
                const answerId = value[0];

                if (typeof text === 'boolean') {
                    const boolValue = text.toString();
                    const localeId = `${indicatorCode}.${itemCode}.choices.${boolValue}`;

                    return `${formatMessageById(
                        `${Strings.indicators.rootId}${localeId}`
                    )}`;
                } else if (!value.length) {
                    return text;
                }

                const localeId = `${indicatorCode}.${itemCode}.choices.${answerId}`;

                return `${formatMessageById(
                    `${Strings.indicators.rootId}${localeId}`
                )} (${answerId})`;
            }
        }
    ];

    const dataSource = form.item
        .filter((item: any) => item.type !== 'text')
        .map((item: any) => {
            return {
                assertion: item.title || item.description,
                answer: value[item.code],
                item
            };
        });

    const msg =
        code.toLowerCase() === EHFSCBS.code.toLowerCase()
            ? formatMessage(Strings.ui.table.general.ehfscbsScore)
            : formatMessage(Strings.ui.table.general.sum);
    return (
        <div>
            <FormDetailsTitle
                title={formatMessageById(
                    `${
                        Strings.indicators.rootId
                    }${indicator.code.toLowerCase()}.title`
                )}
                date={date}
            />
            <Divider />
            <p style={{ marginBottom: 10 }}>
                {formatMessageById(
                    `${
                        Strings.indicators.rootId
                    }${indicator.code.toLowerCase()}.helptext`
                )}
            </p>
            <Table
                columns={columns}
                dataSource={dataSource}
                rowKey={(record) => record.item.id}
            />
            {value.sum && (
                <h3>
                    {msg}: {Math.round(value.sum)}
                </h3>
            )}
        </div>
    );
};

export default FormDetails;
