import colors from '../../../../../../theme/colors/commonColors';

export const plotBandConfigColors: { [key: string]: string } = {
    red: colors.ui.plotBand.red,
    yellow: colors.ui.plotBand.yellow,
    green: colors.ui.plotBand.okRange
};

export function getBoundaryColor(key: string): string {
    const color = key.split('_')[1];
    return plotBandConfigColors[color];
}
