import { Indicator } from '../../../types/indicator/indicator.type';
import { BMIProperties } from './bmi-properties';
import { rules } from './bmi-rules';

import icon from './images/bmi.svg';
import { messagesBmi } from './messages/messages';

export const parameterBMI = {
    code: 'BMI',
    properties: BMIProperties
};

export const bmi: Indicator = {
    code: parameterBMI.code,
    icon,
    messages: messagesBmi,
    rules
};
