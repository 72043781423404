import React, { useState } from 'react';
import { Row, InputNumber, Switch, Checkbox, message } from 'antd';
import styled from 'styled-components';
import RangeSlider from './RangeSlider';
import { isNumber } from 'lodash';

import { Message } from '../../../../../../../../../../locale/components/Message';
import { Strings } from '../../../../../../../../../../locale/messagesDescriptors';
import {
    formatMessage,
    formatMessageById
} from '../../../../../../../../../../locale/format/format-message';
import { roundNumber } from '../../../../../../../../../../utils/math/round-number';
import { countDecimals } from '../../../../../../../../../../utils/math/count-decimals';

const lowerLimits = ['lower_red', 'lower_yellow'];
const upperLimits = ['upper_yellow', 'upper_red'];

const getFieldName = (key: string, prefix: string) => {
    return prefix ? `configuration.${prefix}.${key}` : `configuration.${key}`;
};

const SliderFormGroup = ({
    setFieldValue,
    configuration,
    prefix,
    rules,
    jsonSchema
}: any) => {
    const { step = 1, limits = {}, hideLimits = false } = rules;
    const { report_min_enabled = true, report_max_enabled = true } = limits;

    const [userInput, setUserInput] = useState<number | undefined>();

    const getValues = () => {
        const {
            report_min: reportMin,
            report_max: reportMax,
            lower_red: lowerRed,
            lower_yellow: lowerYellow,
            upper_yellow: upperYellow,
            upper_red: upperRed
        } = configuration;

        const values = [
            reportMin,
            lowerRed,
            lowerYellow,
            upperYellow,
            upperRed,
            reportMax
        ].filter((value) => value != null);

        return values;
    };

    const onSliderChange = (values: any) => {
        const {
            report_min,
            report_max,
            lower_red,
            lower_yellow,
            upper_yellow,
            upper_red
        } = configuration;

        const configurationArray = [
            { report_min },
            { lower_red },
            { lower_yellow },
            { upper_yellow },
            { upper_red },
            { report_max }
        ].filter((item) => Object.values(item)[0] != null);

        values.forEach((value: number, index: number) => {
            const previousValue = Object.values(configurationArray[index])[0];

            if (previousValue !== value) {
                const changedKey = Object.keys(configurationArray[index])[0];

                if (
                    changedKey !== 'report_min' &&
                    changedKey !== 'report_max'
                ) {
                    setFieldValue(
                        getFieldName(changedKey, prefix),
                        roundNumber(value, countDecimals(step)),
                        true
                    );
                }

                return;
            }
        });

        return values;
    };

    const calculateNewConfiguration = ({
        key,
        overrideLimits
    }: {
        key?: string;
        overrideLimits?: any;
    }) => {
        if (overrideLimits) {
            configuration = {
                ...configuration,
                ...overrideLimits
            };
        }

        const {
            report_min,
            lower_red,
            lower_yellow,
            upper_yellow,
            upper_red,
            report_max
        } = configuration;

        const configurationArray = [
            { lower_red },
            { lower_yellow },
            { upper_yellow },
            { upper_red }
        ].filter((item) => {
            // Keep the limit that we are about to insert again, but filter out rest of
            // the limits that are not activated
            return (
                Object.keys(item)[0] === key || Object.values(item)[0] != null
            );
        });

        const range = report_max - report_min;

        const incrementBy =
            Math.floor(range / (configurationArray.length + 1)) || 1; // add one to get number of ranges

        let calculatedValue = report_min + incrementBy;

        configurationArray.forEach((obj) => {
            const key = Object.keys(obj)[0];
            setFieldValue(getFieldName(key, prefix), calculatedValue);
            calculatedValue += incrementBy;
        });
    };

    const toggleLimit = ({
        checked,
        fieldName,
        key
    }: {
        checked: boolean;
        fieldName: string;
        key: string;
    }) => {
        if (!checked) {
            setFieldValue(fieldName, null, true);
        } else {
            calculateNewConfiguration({ key });
        }
    };

    const onReportMinOrMaxChange = async (e: any) => {
        const { name: fieldId, value } = e.currentTarget;
        const key = fieldId.split('.')[+!!prefix + 1];
        await setFieldValue(fieldId, Number(value), true);

        evaluateUserInput();

        calculateNewConfiguration({
            overrideLimits: {
                [key]: +value
            }
        });
    };

    const reportMinKey = getFieldName('report_min', prefix);
    const reportMaxKey = getFieldName('report_max', prefix);
    const sliderValues = getValues();
    const minRangCount = hideLimits ? 1 : 5;

    return (
        <>
            <Row
                type="flex"
                justify="space-between"
                style={{ marginBottom: 40 }}
            >
                <div>
                    <Label htmlFor={reportMinKey}>
                        <Message
                            {...Strings.indicatorConfig.general.reportMin}
                        />
                    </Label>
                    <StyledInputNumber
                        name={reportMinKey}
                        value={configuration.report_min}
                        min={jsonSchema && jsonSchema.minimum}
                        max={configuration.report_max - minRangCount}
                        onBlur={onReportMinOrMaxChange}
                        onChange={onLimitChange}
                        disabled={!report_min_enabled}
                    />
                </div>
                <div>
                    <Label htmlFor={reportMaxKey}>
                        <Message
                            {...Strings.indicatorConfig.general.reportMax}
                        />
                    </Label>
                    <StyledInputNumber
                        name={reportMaxKey}
                        value={configuration.report_max}
                        min={configuration.report_min + minRangCount}
                        max={jsonSchema && jsonSchema.maximum}
                        onBlur={onReportMinOrMaxChange}
                        onChange={onLimitChange}
                        disabled={!report_max_enabled}
                    />
                </div>
            </Row>
            {!hideLimits && (
                <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginBottom: 40 }}
                >
                    {lowerLimits.map((key: string) => {
                        const fieldName = prefix
                            ? `configuration.${prefix}.${key}`
                            : `configuration.${key}`;

                        return (
                            <span key={key}>
                                {formatMessageById(
                                    `${Strings.indicatorConfig.rootId}${key}`
                                )}
                                <Checkbox
                                    name={fieldName}
                                    style={{ marginLeft: 5 }}
                                    checked={
                                        isNumber(configuration[key]) &&
                                        configuration[key] !==
                                            configuration.report_min
                                    }
                                    onChange={(e) => {
                                        const { checked } = e.target;
                                        toggleLimit({
                                            checked,
                                            fieldName,
                                            key
                                        });
                                    }}
                                />
                            </span>
                        );
                    })}
                    {upperLimits.map((key: string) => {
                        const fieldName = prefix
                            ? `configuration.${prefix}.${key}`
                            : `configuration.${key}`;
                        return (
                            <span key={key}>
                                {formatMessageById(
                                    `${Strings.indicatorConfig.rootId}${key}`
                                )}
                                <Checkbox
                                    name={fieldName}
                                    style={{ marginLeft: 5 }}
                                    checked={
                                        isNumber(configuration[key]) &&
                                        configuration[key] !==
                                            configuration.report_max
                                    }
                                    onChange={(e) => {
                                        const { checked } = e.target;
                                        toggleLimit({
                                            checked,
                                            fieldName,
                                            key
                                        });
                                    }}
                                />
                            </span>
                        );
                    })}
                </Row>
            )}
            <Row style={{ marginBottom: 10 }}>
                <RangeSlider
                    min={configuration.report_min}
                    max={configuration.report_max}
                    step={step}
                    values={sliderValues}
                    onChange={onSliderChange}
                    configuration={configuration}
                />
            </Row>
            {!hideLimits && (
                <Row style={{ marginBottom: 20 }}>
                    <ToggleGreenRange
                        configuration={configuration}
                        setFieldValue={setFieldValue}
                        calculateNewConfiguration={calculateNewConfiguration}
                        prefix={prefix}
                    />
                    <span style={{ verticalAlign: 'middle', marginLeft: 10 }}>
                        {formatMessage(Strings.indicatorText.general.greenArea)}
                    </span>
                </Row>
            )}
        </>
    );

    function showLimitMessage(min_value: number, max_value: number) {
        void message.error(
            formatMessage(Strings.limits.general.outsideLimitWarning, {
                min: min_value,
                max: max_value
            })
        );
    }

    function onLimitChange(value: number | undefined) {
        setUserInput(value);
    }

    function evaluateUserInput() {
        if (userInput != null) {
            const schemaMin = jsonSchema.minimum;
            const schemaMax = jsonSchema.maximum;

            const withinLimits = isWithinLimits(
                userInput,
                schemaMin,
                schemaMax
            );

            if (withinLimits === false) {
                showLimitMessage(schemaMin, schemaMax);
            }
        }
    }
};

export function isWithinLimits(value: number, min: number, max: number) {
    return value >= min && value <= max;
}

export default SliderFormGroup;

const Label = ({ htmlFor, children }: any) => {
    return (
        <div style={{ marginBottom: 5 }}>
            <label htmlFor={htmlFor}>{children}</label>
        </div>
    );
};
const StyledInputNumber = styled(InputNumber)`
    && {
        width: 100%;
    }

    .ant-input-number-handler-wrap {
        display: none;
    }
`;

const isEqualTo = (values: number[], targetValue: number) => {
    return values.some((value) => value === targetValue);
};

const ToggleGreenRange = ({
    configuration,
    setFieldValue,
    calculateNewConfiguration,
    prefix
}: any) => {
    const {
        report_min,
        lower_red,
        lower_yellow,
        upper_yellow,
        upper_red,
        report_max
    } = configuration;

    const lowerIsEqualToMax = isEqualTo([lower_red, lower_yellow], report_max);
    const upperIsEqualToMin = isEqualTo([upper_yellow, upper_red], report_min);

    const lowerConfigurationsEnabled =
        lower_red != null || lower_yellow != null;
    const upperConfigurationsEnabled =
        upper_yellow != null || upper_red != null;

    const onChange = (checked: boolean) => {
        if (checked) {
            if (upperIsEqualToMin) {
                calculateNewConfiguration({
                    key: findNearestLimitKey([{ upper_yellow }, { upper_red }])
                });
            }

            if (lowerIsEqualToMax) {
                calculateNewConfiguration({
                    key: findNearestLimitKey([{ lower_yellow }, { lower_red }])
                });
            }
        } else {
            if (lowerConfigurationsEnabled) {
                const key = findNearestLimitKey([
                    { lower_yellow },
                    { lower_red }
                ]);
                setFieldValue(getFieldName(key, prefix), report_max);
            }

            if (upperConfigurationsEnabled) {
                const key = findNearestLimitKey([
                    { upper_yellow },
                    { upper_red }
                ]);
                setFieldValue(getFieldName(key, prefix), report_min);
            }
        }
    };

    return (
        <Switch
            checked={!(lowerIsEqualToMax || upperIsEqualToMin)}
            onChange={onChange}
            disabled={
                lowerConfigurationsEnabled === upperConfigurationsEnabled // XOR!
            }
            size="small"
        />
    );
};

const findNearestLimitKey = (arr: any[]): string => {
    const result = arr.find((item: { [key: string]: number | null }) => {
        const value = Object.values(item)[0];
        return value != null;
    });

    return Object.keys(result)[0];
};
