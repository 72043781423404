import { defineMessages } from 'react-intl';

const careUnit = defineMessages({
    choose: {
        id: 'medic.create.care-unit.choose',
        defaultMessage: 'You have to select care unit'
    }
});

const comfirm = defineMessages({
    title: {
        id: 'medic.create.comfirm.title',
        defaultMessage: 'You are about to add a co-worker with these details:'
    }
});

export const createMedicMessages = { careUnit, comfirm };
