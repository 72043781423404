import React, { useEffect, useRef } from 'react';
import { shouldRenderDateDivider } from '../../../../../../components/action-panel/action-panel-modal/action-modal-tabs/messages/content/MessageList';
import { useReduxSelector } from '../../../../../../hooks/use-redux-selector';
import { ActivePatient } from '../../../../../../redux/domains/active-patient/active-patient.type';
import { Message } from '../../../../../../redux/domains/chat/chat.types';
import { styled } from '../../../../../../theme/styled';
import { MessageRow } from './MessageRow';

export const MessageSection = (props: {
    messages: Message[];
    patient: ActivePatient;
}) => {
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const medicId = useReduxSelector((state) => state.user.externalId);
    useEffect(scrollToBottom, [props.messages]);

    return (
        <StyledMessageList>
            {props.messages.map((message, index) => {
                const isOutbound = props.patient.externalId !== message.sender;
                const isMedicMessage = message.sender === medicId;

                return (
                    <MessageRow
                        key={message.dtCreated}
                        message={message}
                        renderDateDivider={shouldRenderDateDivider(
                            message,
                            index,
                            props.messages
                        )}
                        isOutbound={isOutbound}
                        isMedicMessage={isMedicMessage}
                    />
                );
            })}
            <div ref={messagesEndRef} />
        </StyledMessageList>
    );

    function scrollToBottom() {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    }
};

const StyledMessageList = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
`;
