import { Strings } from '../../../locale/messagesDescriptors';
import { alphabetic, OrderBy } from '../../../utils/sort/alphabetic';
import { formatMessage } from '../../../locale/format/format-message';
import { details } from './message-descriptors';
import { GroupRowType } from './MedicGroupsEdit';

export const CareUnit = {
    title: formatMessage(Strings.labels.label.medicGroup),
    key: 'careUnit',
    dataIndex: 'careUnit',
    sorter: (a: GroupRowType, b: GroupRowType): OrderBy =>
        alphabetic(a.careUnit, b.careUnit)
};

export const PatientAccess = {
    title: formatMessage(details.patient.access),
    key: 'patientAccess',
    dataIndex: 'patientAccess'
};

export const Admin = {
    title: formatMessage(details.patient.admin),
    key: 'admin',
    dataIndex: 'admin'
};
