export const createLuhnChecksum = (ssn: string) => {
    let v = 0;
    let sum = 0;

    ssn += '';

    for (let i = 0, l = ssn.length; i < l; i++) {
        v = +ssn[i];
        v *= 2 - (i % 2);
        if (v > 9) {
            v -= 9;
        }
        sum += v;
    }

    return Math.ceil(sum / 10) * 10 - sum;
};
