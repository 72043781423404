export enum FieldType {
    Input,
    RadioGroup
}

type BaseField = {
    fieldType: FieldType;
    name: string;
    label: string;
    isTouched: boolean;
    required?: boolean;
    indicateRequired?: boolean;
};

export type ValidationMessages = {
    isRequired: string;
    tooShort: string;
    tooLong: string;
    patternMismatch: string;
    custom?: string;
};

export type ValidationMessage = string | null;

export type Field = InputField | RadioGroupField;

export enum InputType {
    Email = 'email',
    Password = 'password',
    Tel = 'tel',
    Text = 'text'
}

export type InputField = BaseField & {
    fieldType: FieldType.Input;
    value: string;
    type?: InputType;
    placeholder?: string;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    isValid: boolean;
    validationMessages: ValidationMessages;
    validationMessage: ValidationMessage;
    validate?: () => ValidationMessage;
};

type Option = {
    label: string;
    value: number;
};

export type RadioGroupField = BaseField & {
    fieldType: FieldType.RadioGroup;
    value: string | null;
    options: Option[];
    correctValue?: string | number;
};

export type Fields = Record<string, Field>;

export type InputFields = Record<string, InputField>;

export type RadioGroupFields = Record<string, RadioGroupField>;

export function isInputField(field: Field): field is InputField {
    return field.fieldType === FieldType.Input;
}

export function isRadioGroupField(field: Field): field is RadioGroupField {
    return field.fieldType === FieldType.RadioGroup;
}
