export enum indicators {}

export enum statisticsTitle {
    report = 'report',
    gender = 'gender',
    smoke = 'smoke',
    physact = 'physact',
    vas = 'vas',
    reporting = 'reporting',
    nonReporting = 'nonreporting',
    male = 'male',
    female = 'female'
}

export enum coloredValues {
    red = '(0-40)',
    yellow = '(41-60)',
    green = '(61-100)'
}

export enum reportedValues {
    reported = 'reported',
    notReported = 'notReported',
    inactivated = 'inactivated'
}
