import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { Loading } from '../../../components/ui/loading/loading';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { authenticateWithSso } from '../../../redux/domains/authentication/authentication.actions';
import {
    CHECK_AUTHENTICATION,
    LOGIN_USER
} from '../../../redux/domains/authentication/authentication.constants';
import { createErrorSelector } from '../../../redux/domains/requests/requests.selectors';
import { useCheckAuthentication } from '../../../hooks/use-check-authentication';
import { SsoError } from './SsoError';
// @ts-ignore
import queryString from 'query-string';
import { routePatientOverview } from '../../patient/overview/route';
import {
    setPendingPatient,
    setSelectedPrioPatient
} from '../../../redux/domains/app-state/app-state.actions';
import { getPatient } from '../../../redux/domains/patient-search/patient-search.api';
import { PatientSearchResponse } from '../../../redux/domains/patient-search/PatientSearchResponse';

import { routeCreatePatient } from '../../patient/create/route';

const errorSelector = createErrorSelector([LOGIN_USER, CHECK_AUTHENTICATION]);

export const Sso = (): JSX.Element => {
    const dispatch = useDispatch<any>();
    const [didFetchPatient, setDidFetchPatient] = useState(false);
    const [didPatientExist, setDidPatientExist] = useState(false);

    const routeParams = useParams<{ sessionId: string }>();
    const { sessionId } = routeParams;
    const isAuthenticated = useReduxSelector(
        (state) => state.authentication.isAuthenticated
    );
    const authenticationError = useReduxSelector(errorSelector);

    const queryParams = queryString.parse(useLocation().search);
    const ssn = queryParams.ssn || null;

    useEffect(() => {
        if (sessionId) {
            dispatch(authenticateWithSso(sessionId));
        }
    }, [dispatch, sessionId]);

    useEffect(() => {
        if (isAuthenticated && ssn) {
            void getPatient(ssn).then((reponse: PatientSearchResponse) => {
                if (reponse.totalNumberOfHits === 1) {
                    dispatch(setSelectedPrioPatient(ssn));
                    setDidPatientExist(true);
                }

                if (reponse.totalNumberOfHits === 0) {
                    dispatch(setPendingPatient(ssn));
                }

                setDidFetchPatient(true);
            });
        }
    }, [dispatch, ssn, isAuthenticated]);

    useCheckAuthentication();

    if (isAuthenticated && didFetchPatient && didPatientExist) {
        return (
            <Redirect
                to={{
                    pathname: routePatientOverview.link
                }}
            />
        );
    }

    if (isAuthenticated && didFetchPatient) {
        return (
            <Redirect
                to={{
                    pathname: routeCreatePatient.link
                }}
            />
        );
    }

    if (authenticationError) {
        return <SsoError />;
    }

    if (isAuthenticated && ssn === null) {
        return (
            <Redirect
                to={{
                    pathname: routePatientOverview.link
                }}
            />
        );
    }

    return <Loading />;
};
