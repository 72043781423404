import { createSelector } from 'reselect';
import { getSelectedConditionId } from '../conditions/conditions.selectors';
import { ReduxState } from '../../redux-state.type';
import { IndicatorReminder } from './reminders.type';
import { PatientExternalId } from '../user/user.type';
import { IndicatorId } from '../indicators/indicator.type';

type RemindersSelectorProps = {
    id: PatientExternalId;
    indicatorId: IndicatorId;
};

const getReportRemindersForIndicator = (
    state: ReduxState,
    props: RemindersSelectorProps
): IndicatorReminder[] => {
    return state.reminders.missedReports[props.id] || [];
};

const getIndicatorId = (state: ReduxState, props: RemindersSelectorProps) =>
    props.indicatorId;

const getReportReminders = (state: ReduxState) =>
    state.reminders.reportReminders;

const getExternalId = (
    state: ReduxState,
    props: {
        id: string;
    }
) => {
    return props.id || state.forms.registerPatient.externalId;
};

export const getIndicatorReminder = createSelector(
    [getReportRemindersForIndicator, getIndicatorId],
    (reports, indicatorId) => {
        return reports.find((report) => indicatorId === report.indicatorId);
    }
);

export const getReportRemindersForPatient = createSelector(
    [getReportReminders, getExternalId, getSelectedConditionId],
    (reportReminders, externalId, conditionId) => {
        if (!externalId || !reportReminders || !reportReminders[externalId])
            return {};

        const remindersByIndicatorId = reportReminders[externalId];

        return Object.keys(remindersByIndicatorId).reduce(
            (acc: any, id: string) => {
                acc[id] = remindersByIndicatorId[id].filter(
                    (item: any) => item.conditionId === conditionId
                )[0];
                return acc;
            },
            {}
        );
    }
);
