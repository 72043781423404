import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useReduxSelector } from '../use-redux-selector';
import { fetchConditionsByIds } from '../../redux/domains/conditions/conditions.actions';
import { useReduxDispatch } from '../use-redux-dispatch';
import { createSelector } from 'reselect';
import { getSelectablePatientGroups } from '../../redux/domains/groups/groups.selectors';

export const getConditionIds = createSelector(
    getSelectablePatientGroups,
    (patientGroups) =>
        patientGroups.map((patientGroup) => patientGroup.condition)
);

export function useFetchConditionByIds(): void {
    const dispatch = useReduxDispatch();
    const conditionIds = useReduxSelector(getConditionIds, shallowEqual);

    useEffect(() => {
        if (conditionIds.length) {
            dispatch(fetchConditionsByIds(conditionIds));
        }
    }, [conditionIds, dispatch]);
}
