import { Indicator } from '../../../types/indicator/indicator.type';

import icon from './images/weight-pd.svg';
import { messagesWeightPd } from './messages/messages';
import { rules } from './weight-pd-rules';

export const parameterWeightPD = {
    code: 'WEIGHT-PD'
};

export const weightPd: Indicator = {
    code: parameterWeightPD.code,
    icon,
    messages: messagesWeightPd,
    rules
};
