import React from 'react';
import { Divider } from 'antd';
import RadioFormGroup from './RadioFormGroup';
import { formatMessageById } from '../../../../../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../../../../../locale/messagesDescriptors';

export const RadioFormGroupWrapper = ({
    setFieldValue,
    values,
    indicatorMeta,
    rules
}: any): JSX.Element => {
    const properties = indicatorMeta.properties;
    const choices = rules?.choices;
    const disabledProperties =
        rules?.boundariesConfiguration?.disabledProperties;

    return (
        <>
            {properties ? (
                Object.keys(properties)
                    .filter((prop) => !disabledProperties?.includes(prop))
                    .map((prop, i, arr) => (
                        <React.Fragment key={prop}>
                            <h4 style={{ marginBottom: 10 }}>
                                {formatMessageById(
                                    `${
                                        Strings.indicators.rootId
                                    }${indicatorMeta.code.toLowerCase()}.${prop}.title`
                                )}
                            </h4>
                            <RadioFormGroup
                                setFieldValue={setFieldValue}
                                prefix={prop}
                                choices={choices[prop]}
                                configuration={values.configuration[prop]}
                            />
                            {i < arr.length - 1 && <Divider />}
                        </React.Fragment>
                    ))
            ) : (
                <>
                    <h4 style={{ marginBottom: 10 }}>
                        {formatMessageById(
                            `${
                                Strings.indicators.rootId
                            }${indicatorMeta.code.toLowerCase()}.question`
                        )}
                    </h4>
                    <RadioFormGroup
                        setFieldValue={setFieldValue}
                        choices={choices}
                        indicatorMeta={indicatorMeta}
                        key="1"
                        configuration={values.configuration}
                    />
                </>
            )}
        </>
    );
};

export default RadioFormGroupWrapper;
