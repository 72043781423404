import { defineMessages } from 'react-intl';

const general = defineMessages({
    updated: {
        id: 'medic.screen.overview.Updated',
        defaultMessage: 'Updated'
    },
    indicatorPopoverContentTitle: {
        id: 'medic.screen.overview.indicatorPopoverContentTitle',
        defaultMessage: 'Latest report'
    }
});

export const patientOverview = {
    general
};
