import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Self-care behaviour',
        id: 'indicator.ehfscbs.title'
    },
    titleLong: {
        defaultMessage: 'Self-care behaviour',
        id: 'indicator.ehfscbs.title-long'
    },
    description: {
        defaultMessage:
            'This form makes some statements about your own care. It shall be completed once a month. In the history you can see the last time you completed it. Please indicate your agreement to these statements in the form.',
        id: 'indicator.ehfscbs.description'
    },
    helptext: {
        defaultMessage:
            'Please indicate your agreement with each statement from 1 (fully agree) to 5 (do not agree at all).',
        id: 'indicator.ehfscbs.helptext'
    },
    introHelptext: {
        defaultMessage:
            'Please indicate your agreement with each statement from 1 (fully agree) to 5 (do not agree at all).',
        id: 'indicator.ehfscbs.helptext'
    },
    sum: {
        defaultMessage: 'Score',
        id: 'indicator.ehfscbs.sum.title'
    }
});

const breathlessness = defineMessages({
    title: {
        defaultMessage: 'Breathlessness',
        id: 'indicator.ehfscbs.breathlessness.title'
    },
    description: {
        defaultMessage:
            'If my shortness of breath increases, I contact my doctor or nurse',
        id: 'indicator.ehfscbs.breathlessness.choices.description'
    },
    reportMaxText: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.breathlessness.max_text'
    },
    reportMinText: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.breathlessness.min_text'
    }
});

const excerciseCheck = defineMessages({
    title: {
        defaultMessage: 'Exercise check',
        id: 'indicator.ehfscbs.excercisecheck.title'
    },
    description: {
        defaultMessage: 'I exercise regularly',
        id: 'indicator.ehfscbs.excercisecheck.choices.description'
    },
    reportMaxText: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.excercisecheck.max_text'
    },
    reportMinText: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.excercisecheck.min_text'
    }
});

const fatigueCheck = defineMessages({
    title: {
        defaultMessage: 'Fatigue check',
        id: 'indicator.ehfscbs.fatiguecheck.title'
    },
    description: {
        defaultMessage:
            'If I experience increased fatigue, I contact my doctor or nurse',
        id: 'indicator.ehfscbs.fatiguecheck.choices.description'
    },
    reportMaxText: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.fatiguecheck.max_text'
    },
    reportMinText: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.fatiguecheck.min_text'
    }
});

const fluidLimitation = defineMessages({
    title: {
        defaultMessage: 'Fluid limitation',
        id: 'indicator.ehfscbs.fluidlimitation.title'
    },
    description: {
        defaultMessage:
            'I limit the amount of fluids I drink (not more than 1.5-2 l/day)',
        id: 'indicator.ehfscbs.fluidlimitation.choices.description'
    },
    reportMaxText: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.fluidlimitation.max_text'
    },
    reportMinText: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.fluidlimitation.min_text'
    }
});

const lowsaltDiet = defineMessages({
    title: {
        defaultMessage: 'Low salt diet',
        id: 'indicator.ehfscbs.lowsaltdiet.title'
    },
    description: {
        defaultMessage: 'I eat a low salt diet',
        id: 'indicator.ehfscbs.lowsaltdiet.choices.description'
    },
    reportMaxText: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.lowsaltdiet.max_text'
    },
    reportMinText: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.lowsaltdiet.min_text'
    }
});

const medicationCheck = defineMessages({
    title: {
        defaultMessage: 'Medication check',
        id: 'indicator.ehfscbs.medicationcheck.title'
    },
    description: {
        defaultMessage: 'I take my medication as prescribed',
        id: 'indicator.ehfscbs.medicationcheck.choices.description'
    },
    reportMaxText: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.medicationcheck.max_text'
    },
    reportMinText: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.medicationcheck.min_text'
    }
});

const swollenLegs = defineMessages({
    title: {
        defaultMessage: 'Swollen legs',
        id: 'indicator.ehfscbs.swollenlegs.title'
    },
    description: {
        defaultMessage:
            'If my feet/legs become more swollen than usual, I contact my doctor or nurse',
        id: 'indicator.ehfscbs.swollenlegs.choices.description'
    },
    reportMaxText: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.swollenlegs.max_text'
    },
    reportMinText: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.swollenlegs.min_text'
    }
});

const weightCheck = defineMessages({
    title: {
        defaultMessage: 'Weight check',
        id: 'indicator.ehfscbs.weightcheck.title'
    },
    description: {
        defaultMessage: 'I weigh myself every day',
        id: 'indicator.ehfscbs.weightcheck.choices.description'
    },
    reportMaxText: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.weightcheck.max_text'
    },
    reportMinText: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.weightcheck.min_text'
    }
});

const weightGain = defineMessages({
    title: {
        defaultMessage: 'Weight gain',
        id: 'indicator.ehfscbs.weightgain.title'
    },
    description: {
        defaultMessage:
            'If I gain 2 kg in 1 week, I contact my doctor or nurse',
        id: 'indicator.ehfscbs.weightgain.choices.description'
    },
    reportMaxText: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.weightgain.max_text'
    },
    reportMinText: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.weightgain.min_text'
    }
});

export const messagesEhfscbs = {
    general,
    weightGain,
    breathlessness,
    excerciseCheck,
    fatigueCheck,
    fluidLimitation,
    lowsaltDiet,
    medicationCheck,
    swollenLegs,
    weightCheck
};
