import React from 'react';
import { FormikType } from '../../formik-type';
import {
    StyledWrapper,
    StyledLabels,
    StyledLabelSpan,
    StyledValues,
    StyledValueSpan
} from './styled-confirm';
import { labelTextWithColon } from '../../../../../utils/label/label-with-colon';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';

export const ComfirmEditMeta = (props: {
    patientMeta: FormikType;
}): JSX.Element => {
    const { patientMeta } = props;

    return (
        <StyledWrapper>
            <StyledLabels>
                <StyledLabelSpan>
                    {labelTextWithColon(
                        formatMessage(Strings.editPatient.fields.ssn.label)
                    )}
                </StyledLabelSpan>
                <StyledLabelSpan>
                    {labelTextWithColon(
                        formatMessage(Strings.labels.label.email)
                    )}
                </StyledLabelSpan>
                <StyledLabelSpan>
                    {labelTextWithColon(
                        formatMessage(
                            Strings.editPatient.fields.firstName.label
                        )
                    )}
                </StyledLabelSpan>
                <StyledLabelSpan>
                    {labelTextWithColon(
                        formatMessage(
                            Strings.editPatient.fields.familyName.label
                        )
                    )}
                </StyledLabelSpan>
                <StyledLabelSpan>
                    {labelTextWithColon(
                        formatMessage(
                            Strings.editPatient.fields.phoneNumber.label
                        )
                    )}
                </StyledLabelSpan>
            </StyledLabels>
            <StyledValues>
                <StyledValueSpan>{patientMeta.ssn}</StyledValueSpan>
                <StyledValueSpan>{patientMeta.email}</StyledValueSpan>
                <StyledValueSpan>{patientMeta.firstName}</StyledValueSpan>
                <StyledValueSpan>{patientMeta.familyName}</StyledValueSpan>
                <StyledValueSpan>{patientMeta.phoneNumber}</StyledValueSpan>
            </StyledValues>
        </StyledWrapper>
    );
};
