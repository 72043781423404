import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import BlockQuote from '../../../../../../../shared/styled/blockquote';
import { Avatar } from 'antd';
import { getPractitionerName } from './utils/getPracticionerName';
import { HistoryItemProps } from '../HistoryPanelContent';
import { theme } from '../../../../../../../../theme/colors/commonColors';
import { StyledMetaItem } from './styledHistoryItem';
import { reset } from '../../general-panel/reset/message-descriptors';
import { Strings } from '../../../../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../../../../locale/format/format-message';

export const ResetPriorityItem = ({ event }: HistoryItemProps): JSX.Element => (
    <>
        <StyledMetaItem
            title={moment(event.dtCreated).format('YYYY-MM-DD HH:mm')}
            avatar={
                <Avatar
                    icon="check"
                    style={{
                        backgroundColor: '#ff4d4f'
                    }}
                />
            }
            description={formatMessage(Strings.events.general.resetBy, {
                practitioner: getPractitionerName(event.proxyMeta)
            })}
        />
        <StyledHistoryItem>
            <StyledItemTitle textColor={theme.dark}>
                <span style={{ fontWeight: 600 }}>
                    {formatMessage(Strings.common.general.reason) + ': '}
                </span>
                {formatMessage(reset.general.resetPriorityReason, {
                    reason: event.action.reason
                })}
            </StyledItemTitle>
            {event.action.note && (
                <BlockQuote borderLeft={`4px solid ${theme.layout.background}`}>
                    {event.action.note}
                </BlockQuote>
            )}
        </StyledHistoryItem>
    </>
);

const StyledHistoryItem = styled.div`
    margin: 8px 0 0 0;
`;

const StyledItemTitle = styled.div<{ textColor: string }>`
    margin-bottom: 8px;
    font-size: 13px;
    color: ${(props) => (props.textColor ? props.textColor : '')};
`;
