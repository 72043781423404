import {
    DataItemType,
    MonthsInLifepodObj,
    Patients
} from '../../../redux/domains/statistics/statistics.type';

export const getValues = (props: Patients) => {
    const patients = props;

    let sixMonths = 0;
    let twelveMonths = 0;
    let eighteenMonths = 0;
    let twentyfourMonths = 0;
    let thirtyMonths = 0;
    let thirtysixMonths = 0;
    let fortytwoMonths = 0;
    let fortyeightMonths = 0;
    let fiftyfourMonths = 0;
    let sixtyMonths = 0;

    const data: DataItemType[] = [];

    patients.monthsInLifepod.forEach((obj: MonthsInLifepodObj) => {
        if (obj.months < 6) {
            sixMonths += obj.count;
        } else if (obj.months > 6 && obj.months <= 12) {
            twelveMonths += obj.count;
        } else if (obj.months > 12 && obj.months <= 18) {
            eighteenMonths += obj.count;
        } else if (obj.months > 18 && obj.months <= 24) {
            twentyfourMonths += obj.count;
        } else if (obj.months > 24 && obj.months <= 30) {
            thirtyMonths += obj.count;
        } else if (obj.months > 30 && obj.months <= 36) {
            thirtysixMonths += obj.count;
        } else if (obj.months > 36 && obj.months <= 42) {
            fortytwoMonths += obj.count;
        } else if (obj.months > 42 && obj.months <= 48) {
            fortyeightMonths += obj.count;
        } else if (obj.months > 48 && obj.months <= 54) {
            fiftyfourMonths += obj.count;
        } else {
            sixtyMonths += obj.count;
        }
    });

    data.push(
        [0.25, sixMonths],
        [0.75, twelveMonths],
        [1.25, eighteenMonths],
        [1.75, twentyfourMonths],
        [2.25, thirtyMonths],
        [2.75, thirtysixMonths],
        [3.25, fortytwoMonths],
        [3.75, fortyeightMonths],
        [4.25, fiftyfourMonths],
        [4.75, sixtyMonths]
    );

    return { data: data };
};
