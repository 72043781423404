import { formatMessageById } from '../../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../../locale/messagesDescriptors';
import { IndicatorWithSettings } from '../../../../../../../redux/domains/indicators/indicator.type';
import { valueIsBoolean, valueIsNumber } from '../utils/formatCellData';

export const getSinglePropertyValues = (indicator: IndicatorWithSettings) => {
    const codeToLowerCase = indicator.code.toLowerCase();
    const step = indicator.settings.rules.step;

    const title = formatMessageById(
        `${Strings.indicators.rootId}${codeToLowerCase}.title`
    );
    const column = [
        {
            key: indicator.id,
            title: title,
            dataIndex: 'value',
            render: (value: number | boolean) => {
                if (typeof value === 'boolean') {
                    const columnValue = valueIsBoolean(value, codeToLowerCase);
                    return columnValue;
                }
                const columnValue = valueIsNumber(value, codeToLowerCase, step);
                return columnValue;
            },
            width: 150
        }
    ];
    return column;
};
