import React from 'react';
import { GroupOption } from './group-option.type';
import { StyledField, StyledInputWrapper, StyledLabel } from '../styled';
import { SelectOptions } from './SelectOptions';
import { ModeOption } from 'antd/lib/select';
import { LoginMethods } from '../../../../constants/login-options';

export const SelectwithLabel = (props: {
    handleChange: (event: LoginMethods) => void;
    label: string;
    placeholder: string;
    options: GroupOption[];
    mode: ModeOption;
    defaultValue?: LoginMethods;
}) => {
    const { label, placeholder, mode, defaultValue } = props;

    return (
        <StyledField>
            <StyledLabel htmlFor={label} $required={false}>
                {label}
            </StyledLabel>
            <StyledInputWrapper>
                <SelectOptions
                    handleChange={props.handleChange}
                    options={props.options}
                    placeholder={placeholder}
                    mode={mode}
                    defaultValue={defaultValue}
                />
            </StyledInputWrapper>
        </StyledField>
    );
};
