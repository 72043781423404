import moment from 'moment';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import { find, some } from 'lodash';

// lodash fp util functions
export const sortByProperty = (array = [], property) => {
    return flow(
        map((item) => item),
        sortBy(property)
    )(array);
};

export const removeItemFromArray = (array = [], item) => {
    return flow(filter((id) => id !== item))(array);
};

// pure util functions
export const getItemByProperty = (array = [], prop, valueToCompare) => {
    return find(array, { [prop]: valueToCompare });
};

export const checkIfItemExistByProperty = (
    array = [],
    prop,
    valueToCompare
) => {
    return some(array, { id: valueToCompare });
};

export const includesSubstring = (originalString, stringToFind) =>
    originalString.indexOf(stringToFind) !== -1;

// chart helper functions
export const msToDate = (data) => moment(data).format('L');

export const mapValues = (values) =>
    values.map((item) => [item.date, item.value]);

export const getInitials = (name) => {
    let initials = name.match(/\b\w/g) || [];
    initials = (
        (initials.shift() || '') + (initials.pop() || '')
    ).toUpperCase();
    return initials;
};
