import { HTTPClient } from '../../../services/http-client/http-client';
import { PatientSearchParams } from './patient-search.type';
import { PatientSearchResponse } from './PatientSearchResponse';

export const getPatient = (ssn: string) =>
    HTTPClient.get<PatientSearchResponse>(
        `users/search?exactMatch&ssn=${ssn}`
    ).catch(() => {
        return {
            totalNumberOfHits: 0,
            users: []
        };
    });

export const getPatients = (params: PatientSearchParams) => {
    const { ssn, firstName, lastName, limit = 100, offset = 0 } = params;

    return HTTPClient.get<PatientSearchResponse>(
        `users/search?ssn=${ssn}&firstName=${firstName}&lastName=${lastName}&limit=${limit}&offset=${offset}`
    );
};
