import { defineMessages } from 'react-intl';

export const hypertension01 = defineMessages({
    name: {
        defaultMessage: 'Hypertension',
        id: 'conditions.HYPERTENSION.name'
    },
    nameLong: {
        defaultMessage: 'Hypertension',
        id: 'conditions.HYPERTENSION.name-long'
    }
});
