import { colors } from '../../../theme/colors/commonColors';

import { ChartType } from '../../../types/indicator/chart.type';
import { IndicatorChartHeatmapRules } from '../../../types/indicator/indicator-rules.type';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

export const rules: IndicatorChartHeatmapRules<undefined | number, number> = {
    chartTypes: [ChartType.Heatmap],
    heatmapPieces: [
        { value: 0, color: colors.chart.heatMap.purple10 },
        {
            color: colors.chart.heatMap.purple20,
            min: 1,
            max: 5
        },

        {
            color: colors.chart.heatMap.purple40,
            min: 6,
            max: 10
        },

        {
            color: colors.chart.heatMap.purple50,
            min: 11,
            max: 15
        },
        {
            color: colors.chart.heatMap.purple60,
            min: 16,
            max: 20
        },

        {
            color: colors.chart.heatMap.purple80,
            min: 21,
            max: 99
        }
    ],
    heatmapLegendItems: [
        {
            textId: Strings.chart.heatmap.noReport.id,
            text: formatMessage(Strings.chart.heatmap.noReport),
            color: colors.chart.heatMap.gray.light
        },
        {
            textId: Strings.indicators.smoke.heatmapChoices.none.id,
            text: formatMessage(Strings.indicators.smoke.heatmapChoices.none),
            color: colors.chart.heatMap.purple10
        },
        {
            textId: Strings.indicators.smoke.heatmapChoices.few.id,
            text: formatMessage(Strings.indicators.smoke.heatmapChoices.few),
            color: colors.chart.heatMap.purple20
        },
        {
            textId: Strings.indicators.smoke.heatmapChoices.several.id,
            text: formatMessage(
                Strings.indicators.smoke.heatmapChoices.several
            ),
            color: colors.chart.heatMap.purple40
        },
        {
            textId: Strings.indicators.smoke.heatmapChoices.pack.id,
            text: formatMessage(Strings.indicators.smoke.heatmapChoices.pack),
            color: colors.chart.heatMap.purple50
        },
        {
            textId: Strings.indicators.smoke.heatmapChoices.lots.id,
            text: formatMessage(Strings.indicators.smoke.heatmapChoices.lots),
            color: colors.chart.heatMap.purple60
        },
        {
            textId: Strings.indicators.smoke.heatmapChoices.horde.id,
            text: formatMessage(Strings.indicators.smoke.heatmapChoices.horde),
            color: colors.chart.heatMap.purple80
        }
    ]
};
