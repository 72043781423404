import {
    DELETE_USERS_FROM_GROUP,
    FETCH_GROUPS,
    INIT_GROUPS,
    SET_SELECTED_MEDIC_GROUP,
    SET_SELECTED_PATIENT_GROUP
} from './groups.constants';
import { getGroupsForUsers } from './groups.api';
import { getUserValue } from '../../../utils/storage/local/get-user-value';
import { setUserStorageItem } from '../../../utils/storage/local/set-user-storage-item';
import { userStorageKeys } from '../../../utils/storage/local/user-storage-keys';
import { ReduxState } from '../../redux-state.type';
import { CLEAR_FILTER } from '../priority/priority.constants';
import { GroupId } from './groups.type';
import { ExternalId, MedicExternalId } from '../user/user.type';
import { deleteUserFromGroup } from '../user/user.api';

export const initGroups = () => async (dispatch: any) => {
    return dispatch({
        type: INIT_GROUPS,
        payload: getGroupsForUsers({
            expand: 'manages',
            includeUsersInOwnGroup: true
        })
    });
};

export const fetchGroups = () => async (dispatch: any) => {
    return dispatch({
        type: FETCH_GROUPS,
        payload: getGroupsForUsers({
            expand: 'manages',
            includeUsersInOwnGroup: true
        })
    });
};

export const deleteUserFromGroupAction = (params: {
    groupId: GroupId;
    medicId: MedicExternalId;
}) => {
    const { groupId, medicId } = params;

    return {
        type: DELETE_USERS_FROM_GROUP,
        payload: deleteUserFromGroup({
            groupId,
            medicId
        })
    };
};

export const setSelectedPatientGroup =
    (groupId?: string) => (dispatch: any, getState: () => ReduxState) => {
        const userId = getState().user.externalId;
        const storageKey = userStorageKeys.selectedPatientGroupId;
        if (groupId) {
            setUserStorageItem(userId, storageKey, groupId);
        } else {
            groupId = getUserValue(userId, storageKey);
        }

        return dispatch({
            type: SET_SELECTED_PATIENT_GROUP,
            payload: groupId
        });
    };

export const setSelectedMedicGroup =
    (groupId?: string) => (dispatch: any, getState: () => ReduxState) => {
        const userId = getState().user.externalId;
        const storageKey = userStorageKeys.selectedMedicGroupId;
        if (groupId) {
            setUserStorageItem(userId, storageKey, groupId);
        } else {
            groupId = getUserValue(userId, storageKey);
        }

        dispatch({
            type: CLEAR_FILTER
        });

        return dispatch({
            type: SET_SELECTED_MEDIC_GROUP,
            payload: { groupId, userId }
        });
    };
