import React, { PropsWithChildren, useEffect } from 'react';
import { getLatestReports } from '../../../../../../redux/domains/values/values.selectors';

import { CrossSpinner } from '../../../../../../components/ui/loading/loading';
import { getIndicatorReminder } from '../../../../../../redux/domains/reminders/reminders.selectors';
import {
    clearLatestReports,
    fetchLatestReportsForIndicatorPopover
} from '../../../../../../redux/domains/values/values.actions';
import { IndicatorWithSettings } from '../../../../../../redux/domains/indicators/indicator.type';
import { useReduxSelector } from '../../../../../../hooks/use-redux-selector';
import { ReduxState } from '../../../../../../redux/redux-state.type';
import { useDispatch } from 'react-redux';
import { TrendPresentation } from '../../../../../../components/trend/IndicatorTrend';
import moment from 'moment';
import {
    Duration,
    DurationValues
} from '../../../../../../components/trend/charts/duration';
import { getLastUpdated } from '../../../../../../redux/domains/priority/priority.selectors';

type Props = {
    indicator: IndicatorWithSettings;
    subjectId: string;
};

const getLatestReportsLoading = (state: ReduxState) =>
    state.ui.overview.latestReportsLoading;

const getSelectedPatientGroupId = (state: ReduxState) =>
    state.groups.selectedPatientGroupId;

export const TrendPopoverContent = (props: Props): JSX.Element => {
    const dispatch = useDispatch();
    const subjectId = props.subjectId;
    const indicatorId = props.indicator.id;
    const latestReportsLoading = useReduxSelector(getLatestReportsLoading);
    const selectedPatientGroupId = useReduxSelector(getSelectedPatientGroupId);

    const priorityListLastUpdated = useReduxSelector((state) =>
        getLastUpdated(state, { groupId: selectedPatientGroupId })
    );
    const latestReports = useReduxSelector(getLatestReports);

    const indicatorReminder = useReduxSelector((state) =>
        getIndicatorReminder(state, {
            id: subjectId,
            indicatorId: indicatorId
        })
    );

    useEffect(() => {
        void dispatch(
            fetchLatestReportsForIndicatorPopover({
                subjectId,
                indicatorId,
                limit: 10,
                from: moment()
                    .subtract(DurationValues[Duration.TwoMonths])
                    .valueOf(),
                to: priorityListLastUpdated
            })
        );

        return () => {
            dispatch(clearLatestReports());
        };
    }, [dispatch, indicatorId, priorityListLastUpdated, subjectId]);

    if (latestReportsLoading) {
        return <CrossSpinner />;
    }

    return (
        <TrendPresentation
            selectedPatientGroupId={selectedPatientGroupId}
            indicator={props.indicator}
            subjectId={props.subjectId}
            latestReports={latestReports}
            indicatorReminder={indicatorReminder}
        />
    );
};
