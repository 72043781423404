import React, { Dispatch, SetStateAction, useState } from 'react';
import {
    BaseUserMeta,
    UserStatus
} from '../../../../../redux/domains/user/user.type';
import {
    formatMessage,
    formatMessageById
} from '../../../../../locale/format/format-message';
import { Modal, Select } from 'antd';
import { Strings } from '../../../../../locale/messagesDescriptors';
import {
    StyledIcon,
    StyledPatientMeta,
    StyledSelectWrapper,
    StyledTextBoxWrapper
} from './StyledPatientModals';
import TextArea from 'antd/lib/input/TextArea';
import { TogglePatientData } from '../PatientAdministrationListWrapperPage';

const { Option } = Select;

interface DeactivatePatientModalProps {
    externalId: string;
    patientMeta: BaseUserMeta;
    reasons: string[];
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    toggleButton: Dispatch<SetStateAction<boolean>>;
    togglePatient: (
        externalId: string,
        data: TogglePatientData
    ) => Promise<void>;
    toggleVisibility: Dispatch<SetStateAction<boolean>>;
    visibility: boolean;
}

export const DeactivatePatientModal = ({
    externalId,
    patientMeta,
    reasons,
    setIsLoading,
    toggleButton,
    togglePatient,
    toggleVisibility,
    visibility
}: DeactivatePatientModalProps) => {
    const [chosenReason, setChosenReason] = useState('');
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [note, setNote] = useState('');
    const [showTextBox, setShowTextBox] = useState(false);

    const handleOk = () => {
        toggleVisibility(false);
        setIsLoading(true);

        const data = {
            status: UserStatus.Inactive,
            reason: chosenReason,
            ...(note && { note })
        };

        togglePatient(externalId, data)
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                throw new Error('An error occured');
            });
    };

    const handleCancel = () => {
        toggleVisibility(false);
        toggleButton(true);
    };

    const handleChange = (value: string) => {
        setChosenReason(value);
        setShowTextBox(value === 'other');
        setIsFormInvalid(value === 'other');
    };

    const handleTextBoxChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { value } = event.target;

        setNote(value);
        setIsFormInvalid(value.length === 0);
    };

    return (
        <Modal
            title={
                <>
                    <StyledIcon type="question-circle" />{' '}
                    {formatMessage(Strings.common.patient.deactivatePatient)}
                </>
            }
            visible={visibility}
            onOk={handleOk}
            okButtonProps={{ disabled: isFormInvalid }}
            onCancel={handleCancel}
        >
            <StyledPatientMeta>
                {patientMeta.name} {patientMeta.family_name}
            </StyledPatientMeta>

            <StyledPatientMeta>
                {patientMeta.personal_id || ''}
            </StyledPatientMeta>

            <StyledSelectWrapper>
                <p>{formatMessage(Strings.common.general.reason)}:</p>

                <Select onChange={handleChange}>
                    {reasons.map((reason: string) => {
                        const definedMessage = formatMessageById(
                            `status-change.deactivate.${reason}`
                        );

                        return (
                            <Option key={reason} value={reason}>
                                {definedMessage}
                            </Option>
                        );
                    })}
                </Select>
            </StyledSelectWrapper>

            {showTextBox && (
                <StyledTextBoxWrapper>
                    <p>{formatMessage(Strings.common.general.note)}</p>
                    <TextArea rows={4} onChange={handleTextBoxChange} />
                </StyledTextBoxWrapper>
            )}
        </Modal>
    );
};
