import React, { Fragment } from 'react';
import { DefaultTrendItem } from '../utils/DefaultTrendItem';

import { DataItem } from '../utils/DataItem';
import {
    getMatchingDayTrendItems,
    getYearMonthDayString
} from '../utils/utils';
import {
    PhysactReport,
    PhysactTrendData,
    PhysactType
} from '../../../../types/indicators/Physact';
import { DashedDivider } from '../utils/Divider';
import { Strings } from '../../../../locale/messagesDescriptors';
import {
    formatMessageById,
    formatMessage
} from '../../../../locale/format/format-message';

type PhysactProps = {
    latestReport: PhysactReport;
    indicator: PhysactType;
    latestReports: PhysactReport[];
};

const getTrendData = ({
    latestReport,
    indicatorId
}: {
    latestReport: PhysactReport;
    indicatorId: string;
}): PhysactTrendData[] => {
    const { value: report } = latestReport;

    return Object.keys(report).map((valueKey) => {
        return {
            title: formatMessageById(`${indicatorId}.${valueKey}.title`),
            value: report[valueKey],
            unit: formatMessageById(`${indicatorId}.${valueKey}.unit`)
        };
    });
};

const getActivityTrendTotalForDay = ({
    code,
    dayTrends
}: {
    code: string;
    dayTrends: PhysactTrendData[];
}) => {
    if (dayTrends != null) {
        let total = 0;

        dayTrends.forEach(
            (data: PhysactTrendData) => (total += Number(data.value.duration))
        );

        const unit = formatMessageById(
            `${Strings.indicators.rootId}${code.toLowerCase()}.duration.unit`
        );

        return { title: null, value: total, unit: unit };
    }

    return null;
};

export const Physact = ({
    latestReport,
    indicator,
    latestReports
}: PhysactProps) => {
    const indicatorId = `${
        Strings.indicators.rootId
    }${indicator.code.toLowerCase()}`;

    const activityTrendTotalForDay = getActivityTrendTotalForDay({
        code: indicator.code,
        dayTrends: getMatchingDayTrendItems({
            unitId: `${indicatorId}.unit`,
            matchingDate: getYearMonthDayString({ date: latestReport.date }),
            latestReports: latestReports
        })
    });

    return (
        <Fragment>
            <DefaultTrendItem
                latestReport={latestReport}
                formattedData={getTrendData({
                    latestReport: latestReport,
                    indicatorId: indicatorId
                })}
            />
            <DashedDivider />
            {activityTrendTotalForDay ? (
                <DataItem
                    key={activityTrendTotalForDay.value}
                    title={formatMessage(Strings.trend.totalForDay.title)}
                    value={activityTrendTotalForDay.value}
                    unit={activityTrendTotalForDay.unit}
                    addMargin={true}
                />
            ) : null}
        </Fragment>
    );
};
