import { RouteItemType } from '../../../utils/route/switch-routes';
import { pathOauth2 } from './path';
import OAuthPage from './OAuthPage';

export const routeOAuth2: RouteItemType = {
    name: '',
    link: pathOauth2,
    icon: '',
    component: OAuthPage
};
