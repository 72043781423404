import { useEffect } from 'react';
import { useReduxSelector } from '../use-redux-selector';
import { initGroups } from '../../redux/domains/groups/groups.actions';
import { Modal } from 'antd';
import { errorMessages } from '../../locale/messagesDescriptors/errors';
import { useReduxDispatch } from '../use-redux-dispatch';
import { formatMessage } from '../../locale/format/format-message';

export function useFetchGroups(): void {
    const dispatch = useReduxDispatch();
    const isMedicFetched = useReduxSelector((state) => state.user.isFetched);
    const isEducated = useReduxSelector((state) => state.user.medicEducated);

    useEffect(() => {
        if (isMedicFetched && isEducated) {
            dispatch(initGroups()).catch((error: { status: number }) => {
                if (error.status === 403) {
                    Modal.error({
                        title: formatMessage(errorMessages.unknown.message),
                        content: formatMessage(
                            errorMessages.unknown.description
                        )
                    });
                }
            });
        }
    }, [dispatch, isMedicFetched, isEducated]);
}
