import React from 'react';
import { MainLayout } from '../components/main-layout/MainLayout';
import { Loading } from '../components/ui/loading/loading';

import { useFetchConditionByIds } from '../hooks/app-setup/use-condition-by-ids';
import { useFetchSettings } from '../hooks/app-setup/use-fetch-settings';
import { useFetchMedic } from '../hooks/app-setup/use-fetch-medic';
import { useReduxSelector } from '../hooks/use-redux-selector';
import {
    isCurrentUserAdminInSelectedGroup,
    isCurrentUserMedicInSelectedGroup
} from '../redux/domains/groups/groups.selectors';
import { useFetchGroups } from '../hooks/app-setup/use-groups';
import { useSetSelectedGroups } from '../hooks/app-setup/use-set-selected-groups';

export const AppSetup = (): JSX.Element => {
    useFetchSettings();
    useFetchMedic();

    const isMedicFetched = useReduxSelector((state) => state.user.isFetched);
    const isEducated = useReduxSelector((state) => state.user.medicEducated);

    if (isEducated) {
        return <AppSetupForEducated />;
    }

    return isMedicFetched ? <MainLayout /> : <Loading />;
};

const AppSetupForEducated = (): JSX.Element => {
    const groupsLoaded = useReduxSelector((state) => state.groups.loaded);
    const didFetchAndSetGroups = useFetchAndSetGroups();

    useFetchConditionByIds();

    return groupsLoaded && didFetchAndSetGroups ? <MainLayout /> : <Loading />;
};

function useFetchAndSetGroups() {
    useFetchGroups();
    useSetSelectedGroups();

    const isMedic = useReduxSelector(isCurrentUserMedicInSelectedGroup);
    const isAdmin = useReduxSelector(isCurrentUserAdminInSelectedGroup);
    return isAdmin || isMedic;
}
