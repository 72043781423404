import { Indicator } from '../../../types/indicator/indicator.type';
import { rules } from './dizziness-rules';

import icon from './images/dizziness.svg';
import { messagesDizziness } from './messages/messages';

export const parameterDizziness = {
    code: 'DIZZINESS'
};

export const dizziness: Indicator = {
    code: parameterDizziness.code,
    icon,
    messages: messagesDizziness,
    rules
};
