import React from 'react';
import { List } from 'antd';
import styled from 'styled-components';
import { IndicatorIconWithBorder } from '../../../../../../../components/ui/icons/indicator-icon/IndicatorIcon';
import { ActivePatient } from '../../../../../../../redux/domains/active-patient/active-patient.type';
import EditIndicator from './edit-indicator/EditIndicator';
import PreviewRecommendedReportInterval from './PreviewRecommendedReportInterval';
import ToggleIndicator from './toggle-indicator/ToggleIndicator';
import { IndicatorConfigurationState } from '../../../../../../../redux/domains/indicators/indicator.type';
import { formatMessageById } from '../../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../../locale/messagesDescriptors';
import { Settings } from '../../../../../../../redux/domains/settings/settings.type';

const ListItem = List.Item;

const getIndicatorSettings = (code: string, indicatorsSettings: any) => {
    const {
        preventFromToggle = [],
        preventFromEditLimits = [],
        preventFromEditReminders = []
    } = indicatorsSettings;
    return {
        disableToggle: preventFromToggle.includes(code),
        allowEditLimits: !preventFromEditLimits.includes(code),
        allowEditReminders: !preventFromEditReminders.includes(code)
    };
};

type Props = {
    patient: ActivePatient;
    indicator: any;
    indicatorConfiguration: IndicatorConfigurationState;
    reportReminder: any;
    indicatorsSettings: Record<string, Settings>;
    conditionId: string;
    isReadonly?: boolean;
};

const IndicatorListItem = ({
    patient,
    indicator,
    indicatorConfiguration,
    reportReminder,
    indicatorsSettings,
    conditionId,
    isReadonly = false
}: Props) => {
    const title = formatMessageById(
        `${Strings.indicators.rootId}${indicator.code.toLowerCase()}.title`
    );
    const indicatorSettings = getIndicatorSettings(
        indicator.code,
        indicatorsSettings
    );
    const actions = [];

    if (!isReadonly) {
        actions.push(
            <ToggleIndicator
                key="1"
                patient={patient}
                indicatorConfiguration={indicatorConfiguration}
                indicator={indicator}
                indicatorSettings={indicatorSettings}
                conditionId={conditionId}
            />
        );
    }
    actions.push(
        <EditIndicator
            key="2"
            patient={patient}
            indicatorConfiguration={indicatorConfiguration}
            indicator={indicator}
            reportReminder={reportReminder}
            indicatorSettings={indicatorSettings}
            conditionId={conditionId}
            isReadonly={isReadonly}
        />
    );

    return (
        <ListItem actions={actions}>
            <ListItem.Meta
                title={title}
                avatar={
                    <AvatarWrapper>
                        <IndicatorIconWithBorder
                            code={indicator.code}
                            size={36}
                        />
                    </AvatarWrapper>
                }
                description={
                    <PreviewRecommendedReportInterval
                        indicatorCode={indicator.code}
                        indicatorConfiguration={indicatorConfiguration}
                    />
                }
            />
        </ListItem>
    );
};

const AvatarWrapper = styled.div`
    height: 40px;
    width: 40px;
`;

export default IndicatorListItem;
