import React from 'react';
import { Strings } from '../../../locale/messagesDescriptors';
import { formatMessage } from '../../../locale/format/format-message';

export const EmailLink = ({ email }: { email: string | null }) => {
    if (email) {
        return (
            <a onClick={() => (window.location.href = `mailto:${email}`)}>
                {email}
            </a>
        );
    }

    return <>{formatMessage(Strings.common.general.missing)}</>;
};
