import { defineMessages } from 'react-intl';

const general = defineMessages({
    unreadMessage: {
        id: 'medic.notification.unreadMessage',
        defaultMessage:
            '{unreadMessagesCount, plural, one {{unreadMessagesCount} unread message} other {{unreadMessagesCount} unread messages}}'
    }
});

export const notification = {
    general
};
