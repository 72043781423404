import { IndicatorState } from '../../redux/domains/indicators/indicator.type';
import { isIndicatorMultiProperty } from './is-indicator-multi-property';
import { treatBMIAsSingleProperty } from './treat-bmi-as-single-property';

export const isIndicatorSingleProperty = (
    indicator: IndicatorState
): boolean => {
    if (indicator) {
        return (
            treatBMIAsSingleProperty(indicator.code) ||
            !isIndicatorMultiProperty(indicator)
        );
    }

    throw 'Indicator must be defined';
};
