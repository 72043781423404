import React from 'react';

import { DefaultTrendItem } from '../utils/DefaultTrendItem';

import { getDefaultTrendData } from './get-data';
import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import { IndicatorReport } from '../../../../types/indicators/Indicator';

export const Default = (props: {
    latestReport: IndicatorReport<number>;
    indicator: IndicatorWithSettings;
}): JSX.Element => {
    const latestReport = props.latestReport;
    const indicator = props.indicator;

    const data = getDefaultTrendData({
        latestValue: latestReport.value,
        code: indicator.code,
        decimals: indicator.settings.rules.decimals
    });

    return (
        <DefaultTrendItem latestReport={latestReport} formattedData={data} />
    );
};
