import { defineMessages } from 'react-intl';

const general = defineMessages({
    searchPlaceholder: {
        id: 'medic.form.searchPlaceHolder',
        defaultMessage: 'Search for patient'
    },
    requiredFirstName: {
        id: 'medic.form.requiredFirstName',
        defaultMessage: 'Please enter a name'
    },
    requiredLastName: {
        id: 'medic.form.requiredLastName',
        defaultMessage: 'Please enter a name'
    },
    requiredSocialSecurityNumber: {
        id: 'medic.form.requiredSocialSecurityNumber',
        defaultMessage: 'Please enter a valid personal identification number'
    },
    requiredUsername: {
        id: 'medic.form.requiredUsername',
        defaultMessage: 'Please enter a username'
    },
    requiredEmail: {
        id: 'medic.form.requiredEmail',
        defaultMessage: 'Please enter an e-mail'
    },
    requiredPassword: {
        id: 'medic.form.requiredPassword',
        defaultMessage: 'Please enter a password'
    },
    requiredPhone: {
        id: 'medic.form.requiredPhone',
        defaultMessage: 'Please enter a valid phone number'
    },
    requiredGroup: {
        id: 'medic.form.requiredGroup',
        defaultMessage: 'Please select a group'
    },
    validEmail: {
        id: 'medic.form.validEmail',
        defaultMessage: 'Not a valid e-mail'
    },
    validSocialSecurityNumberLength: {
        id: 'medic.form.validSocialSecurityNumberLength',
        defaultMessage:
            'Personal identification number should be exactly 12 digits long'
    },
    validSwedishPIN: {
        id: 'medic.form.validSwedishPIN',
        defaultMessage: 'Not a valid personal identification number'
    },
    usernamePlaceholder: {
        id: 'medic.form.usernamePlaceholder',
        defaultMessage: 'Username'
    },
    passwordPlaceholder: {
        id: 'medic.form.passwordPlaceholder',
        defaultMessage: 'Password'
    },
    requiredNote: {
        id: 'medic.form.requiredNote',
        defaultMessage: 'Note is required'
    },
    requiredReason: {
        id: 'medic.form.requiredReason',
        defaultMessage: 'Please select a reason!'
    },
    required: {
        id: 'medic.form.required',
        defaultMessage: 'This field is required!'
    },
    placeholderReason: {
        id: 'medic.form.placeholderReason',
        defaultMessage: 'Select a reason'
    },
    notePlaceholder: {
        id: 'medic.form.notePlaceholder',
        defaultMessage: 'Write a note here'
    },
    messagePlaceholder: {
        id: 'medic.form.messagePlaceholder',
        defaultMessage: 'Type your message here'
    },
    journal: {
        id: 'medic.form.journal',
        defaultMessage: 'Journal entry'
    },
    loginFormTitle: {
        id: 'medic.loginFormTitle',
        defaultMessage: 'LifePod - Log in'
    },
    formLabelUserName: {
        id: 'medic.form.formLabelUserName',
        defaultMessage: 'Username'
    },
    loginSiths: {
        id: 'medic.form.loginSiths',
        defaultMessage: 'Start login via SITHS'
    },
    validPhoneLength: {
        id: 'medic.form.validPhoneLength',
        defaultMessage: 'Phone number should be exactly 9 digits'
    },
    pinPlaceholder: {
        id: 'medic.form.pinPlaceholder',
        defaultMessage: 'YYYYMMDDXXXX / YYYYMMDD-XXXX'
    },
    selectGroupPlaceholder: {
        id: 'medic.form.selectGroupPlaceholder',
        defaultMessage: 'Select group'
    }
});

const error = defineMessages({
    ohMy: {
        id: 'form.error.title',
        defaultMessage: 'Attention!'
    },
    emailMissing: {
        id: 'form.error.email-missing',
        defaultMessage: 'You must enter e-mail'
    },
    phoneOrEmailMissing: {
        id: 'form.error.phone-or-email-missing',
        defaultMessage:
            'You must have a telephone number or an e-mail to your patient'
    },
    didNotAddEmail: {
        id: 'form.error.did-not-add-email',
        defaultMessage:
            'Please note that you have not added an e-mail to the patient.'
    },
    didNotAddPhone: {
        id: 'form.error.did-not-add-phone',
        defaultMessage:
            'Please note that you have not added a telephone number to the patient.'
    }
});

const placeholder = defineMessages({
    name: {
        id: 'form.placeholder.name',
        defaultMessage: 'John'
    },
    lastName: {
        id: 'form.placeholder.last-name',
        defaultMessage: 'Smith'
    },
    email: {
        id: 'form.placeholder.email',
        defaultMessage: 'john.smith@example.com'
    }
});

const tooltip = defineMessages({
    smsLink: {
        id: 'form.tooltip.sms-link',
        defaultMessage:
            'An SMS with the link to LifePod will be sent to the patient. Reminders can be sent via sms if the patient forgot to report.'
    },
    emailRegistration: {
        id: 'form.tooltip.email-registration',
        defaultMessage:
            'An e-mail will be sent to the given e-mail with a manual and the link to LifePod.'
    },
    emailEditPrevention: {
        id: 'form.tooltip.email-edit-prevention',
        defaultMessage: 'To change the e-mail contact support@lifepod.se'
    }
});

export const form = {
    general,
    error,
    placeholder,
    tooltip
};
