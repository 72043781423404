import _ from 'lodash';
import {
    PlotbandLimit,
    PlotbandLimitUndefined
} from '../../../../../../components/charts/graph/types/plotband.type';
import { plotBandConfigColors } from './plot-band-config-colors';

export function getGreenBoundary(
    lowerBoundaries: PlotbandLimit[],
    upperBoundaries: PlotbandLimit[],
    report_min: number | null | undefined,
    report_max: number | null | undefined
): PlotbandLimitUndefined | PlotbandLimit | { label: 'green' } {
    const from = lowerBoundaries.length
        ? // @ts-ignore
          _.maxBy(lowerBoundaries, 'to').to
        : report_min;
    const to = upperBoundaries.length
        ? // @ts-ignore
          _.minBy(upperBoundaries, 'to').to
        : report_max;

    if (!lowerBoundaries.length && !upperBoundaries.length) return {};

    return {
        to,
        from,
        color: plotBandConfigColors.green,
        label: 'green'
    };
}
