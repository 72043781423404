import { ReduxState } from '../../redux-state.type';
import { createSelector } from 'reselect';

const overviewFilter = (state: ReduxState) => state.ui.overview.filter;
const patientAdministrationSearchTerm = (state: ReduxState) =>
    state.ui.patientAdministration.searchTerm;

export const getOverviewFilter = createSelector(
    [overviewFilter],
    (filter) => filter
);

export const getPatientAdministrationSearchTerm = createSelector(
    [patientAdministrationSearchTerm],
    (searchTerm) => searchTerm
);
