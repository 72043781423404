import { Avatar } from 'antd';
import moment from 'moment';
import React from 'react';
import { feedback } from '../../general-panel/feedback/message-descriptors';
import { theme } from '../../../../../../../../theme/colors/commonColors';
import { HistoryItemProps } from '../HistoryPanelContent';
import { StyledMetaItem } from './styledHistoryItem';
import { getPractitionerName } from './utils/getPracticionerName';
import { formatMessage } from '../../../../../../../../locale/format/format-message';

export const Feedback = ({ event, patient }: HistoryItemProps): JSX.Element => (
    <StyledMetaItem
        title={moment(event.dtCreated).format('YYYY-MM-DD HH:mm')}
        avatar={
            <Avatar
                icon="like"
                style={{
                    backgroundColor: theme.denote.good,
                    color: 'rgba(0, 0, 0, 0.65)'
                }}
            />
        }
        description={
            <>
                {formatMessage(feedback.general.encouragementBy, {
                    practitioner: getPractitionerName(event.proxyMeta)
                })}
                <br />
                <q>
                    {formatMessage(feedback.general.message, {
                        name: patient?.meta.name
                    })}
                </q>
            </>
        }
    />
);
