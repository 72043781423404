import React from 'react';
import { useLocation } from 'react-router-dom';

// @ts-ignore
import queryString from 'query-string';
import VerifyToken from './VerifyToken';
import { Message } from '../../../../../locale/components/Message';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../locale/format/format-message';

const Consume = () => {
    const params = queryString.parse(useLocation().search);
    const { idToken, refreshToken, error } = params;

    if (error) {
        return (
            <div>
                <Message
                    {...Strings.authText.main.samlGeneralError}
                    values={{
                        email: (
                            <a
                                href={`mailto:${Strings.constants.companyDetails.supportEmail}?Subject=Error SAML Login&body=%0D%0A%0D%0AError message:%0D%0A${error}`}
                                target="_top"
                                key="1"
                            >
                                {Strings.constants.companyDetails.supportEmail}
                            </a>
                        )
                    }}
                />

                <div style={{ maxWidth: 400 }}>
                    <details style={{ marginTop: 20 }}>
                        <summary>
                            {formatMessage(Strings.common.general.showDetails)}
                        </summary>
                        <code>{error}</code>
                    </details>
                </div>
            </div>
        );
    }

    return <VerifyToken idToken={idToken} refreshToken={refreshToken} />;
};

export default React.memo(Consume);
