const appConfig = __APP_CONFIG__;

const root = appConfig.ASSETS_ROOT_URL;
const version = appConfig.ASSETS_VERSION;

const patientFileName = 'Manual-LifePod-Patient';
const medicFileName = 'Manual-LifePod-Vardpersonal';

export const resource = {
    root,
    version,
    patientFileName: patientFileName,
    FileName: patientFileName,
    videoUrl: `${root}${version}/general/sv/medic/video/education/introduction/`,
    patientUrlForManual: `${root}${version}/general/sv/patient/manual/${patientFileName}.pdf`,
    medicUrlForManual: `${root}${version}/general/sv/medic/manual/${medicFileName}.pdf`,
    getVideoResourceUrl(name: string): string {
        return `${resource.videoUrl}${name}.mp4`;
    }
};
