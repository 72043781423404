import { Indicator } from '../../../../types/indicator/indicator.type';
import { rules } from './bowel-dysfunction-rules';
import { BowelDysfunctionProperties } from './bowel-dysfunction.properties';

import icon from './images/bowel-dysfunction.svg';
import { messagesBowelDysfunction } from './messages/messages';

export const parameterBowelDysfunction = {
    code: 'NBD',
    properties: BowelDysfunctionProperties
};

export const bowelDysfunction: Indicator = {
    code: parameterBowelDysfunction.code,
    icon,
    messages: messagesBowelDysfunction,
    rules
};
