export const FETCH_CONDITIONS_WITH_INDICATORS =
    'FETCH_CONDITIONS_WITH_INDICATORS';
export const FETCH_CONDITIONS_WITH_INDICATORS_PENDING =
    'FETCH_CONDITIONS_WITH_INDICATORS_PENDING';
export const FETCH_CONDITIONS_WITH_INDICATORS_FULFILLED =
    'FETCH_CONDITIONS_WITH_INDICATORS_FULFILLED';
export const FETCH_CONDITIONS_WITH_INDICATORS_REJECTED =
    'FETCH_CONDITIONS_WITH_INDICATORS_REJECTED';

export const SET_SELECTED_CONDITION = 'SET_SELECTED_CONDITION';
