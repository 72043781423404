import moment from 'moment';
import {
    IndicatorConfigurationState,
    IndicatorWithSettings
} from '../../../redux/domains/indicators/indicator.type';
import {
    isHeatmapRules,
    Settings
} from '../../../redux/domains/settings/settings.type';
import { getRGBA, theme } from '../../../theme/colors/commonColors';
import { getHeatmapTooltip } from './helpers';
import { DateValueTuple, Values } from './heatmap.type';
import { getValues } from './getValues';

export const getOption = (props: {
    indicator: IndicatorWithSettings;
    values: Values;
    dateRange: { from: number; to: number };
    indicatorSettings: Settings;
    indicatorConfiguration: IndicatorConfigurationState;
    selectedDate: number | null;
}) => {
    const { values, dateRange, indicator, indicatorSettings, selectedDate } =
        props;
    const startDate = moment(dateRange.from).format('YYYY-MM-DD');
    const endDate = moment(dateRange.to).format('YYYY-MM-DD');

    const data = getValues(props);

    // ghostValue is needed in order to pass the data to the data key in the series
    // ghostvalue has to be higher than 99999 because that is the max reporting value of vegetables
    const ghostValue = 100000;

    const dateRangeData = getDaysArray(dateRange.from, dateRange.to);
    return {
        visualMap: {
            orient: 'horizontal',
            pieces:
                isHeatmapRules(indicatorSettings.rules) &&
                indicatorSettings.rules.heatmapPieces,
            show: false,
            left: 80,
            top: 0,
            dimension: 1
        },
        series: [
            {
                type: 'heatmap',
                coordinateSystem: 'calendar',
                data: data,
                name: indicator.code,
                emphasis: {
                    itemStyle: {
                        borderWidth: 1
                    }
                }
            },
            {
                type: 'heatmap',
                coordinateSystem: 'calendar',
                data: selectedDate ? [[selectedDate, ghostValue]] : [],
                name: indicator.code,
                itemStyle: {
                    borderWidth: 1,
                    borderColor: '#000000'
                }
            },
            {
                type: 'heatmap',
                coordinateSystem: 'calendar',
                data: dateRangeData,
                name: indicator.code
            }
        ],
        tooltip: {
            // pos - mouse position
            // rect - data point rect
            // size = size of echarts container, tooltip (contentSize) and chart (viewSize)
            position: function (
                pos: any,
                params: any,
                dom: any,
                rect: any,
                size: any
            ) {
                // obj is and object that describes the position of the tooltip
                // for example { top: 30, left: 10 }
                const obj: {
                    top: number;
                    left?: number;
                    right?: number;
                } = { top: rect.y - size.contentSize[1] - 10 };
                const isLeft = pos[0] < size.viewSize[0] / 2;
                // place tooltip to the left or right of mouse pointer according to if the
                // data point rect is placed to the left or right of the center of the chart.
                if (isLeft) {
                    obj.left = rect.x - rect.width;
                } else {
                    obj.right = size.viewSize[0] - rect.x - rect.width * 2;
                }
                return obj;
            },
            formatter: function (params: { data: DateValueTuple }) {
                const [date] = params.data;
                const todaysData = getSortedDataFromSameDate(values, date);
                return getHeatmapTooltip(indicator.code, todaysData, date);
            },
            backgroundColor: getRGBA(theme.dark, 0.9)
        },
        calendar: [
            {
                range: [startDate, endDate],
                cellSize: ['auto', 15],
                dayLabel: {
                    firstDay: moment.localeData().firstDayOfWeek(),
                    nameMap: moment
                        .localeData()
                        .weekdaysMin()
                        .map((day) => day.charAt(0))
                },
                monthLabel: {
                    nameMap: moment.localeData().monthsShort()
                },
                top: 50,
                right: 10,
                itemStyle: {
                    borderColor: theme.base.extraBright,
                    color: theme.base.brightBackground
                },
                splitLine: {
                    lineStyle: {
                        width: 0.5,
                        color: theme.base.dark
                    }
                }
            }
        ]
    };
};

const getSortedDataFromSameDate = (data: Values, currentDate: number) => {
    return data
        .filter(({ date }) => moment(date).isSame(currentDate, 'day'))
        .sort((a, b) => b.date - a.date);
};

const getDaysArray = function (from: number, to: number) {
    const arr = [];

    // ghostValue is needed in order to pass the data to the data key in the series
    // ghostvalue has to be higher than 99999 because that is the max reporting value of vegetables
    const ghostValue = 101000;

    for (
        let date = new Date(from);
        date <= new Date(to);
        date.setDate(date.getDate() + 1)
    ) {
        const dateInMilliseconds = date.getTime();
        arr.push([dateInMilliseconds, ghostValue]);
    }
    return arr;
};
