import React, { Fragment } from 'react';

import { DataItem } from '../utils/DataItem';
import { DefaultTrendItem } from '../utils/DefaultTrendItem';
import {
    getMatchingDayTrendItems,
    getYearMonthDayString
} from '../utils/utils';
import { DashedDivider } from '../utils/Divider';
import {
    SmokeReport,
    SmokeTrendData
} from '../../../../types/indicators/Smoke';
import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import { Strings } from '../../../../locale/messagesDescriptors';
import {
    formatMessageById,
    formatMessage
} from '../../../../locale/format/format-message';

type SmokeProps = {
    latestReport: SmokeReport;
    indicator: IndicatorWithSettings;
    latestReports: SmokeReport[];
};

const getTrendData = ({
    latestReport,
    indicatorId
}: {
    latestReport: SmokeReport;
    indicatorId: string;
}): SmokeTrendData[] => {
    return [
        {
            title: undefined,
            value: latestReport.value,
            unit: formatMessageById(`${indicatorId}.unit`, {
                value: latestReport.value
            })
        }
    ];
};

const getSmokeTrendTotalForDay = ({
    code,
    dayTrends
}: {
    code: string;
    dayTrends: SmokeTrendData[];
}): SmokeTrendData | null => {
    if (dayTrends != null) {
        let total = 0;

        dayTrends.forEach(
            (data: SmokeTrendData) => (total += Number(data.value))
        );

        const unit = formatMessageById(
            `${Strings.indicators.rootId}${code.toLowerCase()}.unit`,
            {
                value: total
            }
        );

        return { title: undefined, value: total, unit: unit };
    }
    return null;
};

export const Smoke = ({
    latestReport,
    indicator,
    latestReports
}: SmokeProps): JSX.Element => {
    const indicatorId = `${
        Strings.indicators.rootId
    }${indicator.code.toLowerCase()}`;

    const smokeTrendTotalForDay = getSmokeTrendTotalForDay({
        code: indicator.code,
        dayTrends: getMatchingDayTrendItems({
            unitId: `${indicatorId}.unit`,
            matchingDate: getYearMonthDayString({ date: latestReport.date }),
            latestReports: latestReports
        })
    });

    return (
        <Fragment>
            <DefaultTrendItem
                latestReport={latestReport}
                formattedData={getTrendData({
                    latestReport: latestReport,
                    indicatorId: indicatorId
                })}
            />
            <DashedDivider />
            {smokeTrendTotalForDay ? (
                <DataItem
                    key={smokeTrendTotalForDay.value}
                    title={formatMessage(Strings.trend.totalForDay.title)}
                    value={smokeTrendTotalForDay.value}
                    unit={smokeTrendTotalForDay.unit}
                    addMargin={true}
                />
            ) : null}
        </Fragment>
    );
};
