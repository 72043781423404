import styled from 'styled-components';

export const StyledConditionContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;

export const StyledCondition = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const StyledExtraConditions = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

export const StyledH3 = styled.h3`
    margin-bottom: 0;
`;
