import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Quality of Life',
        id: 'indicator.vas.title'
    }
});

const choices = defineMessages({
    veryBad: {
        id: 'indicator.vas.choices.verybad',
        defaultMessage: 'Very bad'
    },
    bad: {
        id: 'indicator.vas.choices.bad',
        defaultMessage: 'Bad'
    },
    decent: {
        id: 'indicator.vas.choices.decent',
        defaultMessage: 'Decent'
    },
    ok: {
        id: 'indicator.vas.choices.ok',
        defaultMessage: 'OK'
    },
    good: {
        id: 'indicator.vas.choices.good',
        defaultMessage: 'Good'
    },
    veryGood: {
        id: 'indicator.vas.choices.verygood',
        defaultMessage: 'Very good'
    }
});

export const messagesVas = {
    general,
    choices
};
