import { Indicator } from '../../../../types/indicator/indicator.type';
import { rules } from './ehra-rules';
import icon from './images/ehra.svg';
import { messagesEhra } from './messages/messages';

export const parameterEhra = {
    code: 'EHRA'
};

export const ehra: Indicator = {
    code: parameterEhra.code,
    icon,
    messages: messagesEhra,
    rules
};
