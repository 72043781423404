import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import { PatientGroupId } from '../../../../redux/domains/groups/groups.type';
import { getPatientGroupsForCurrentSelectedMedicGroup } from '../../../../redux/domains/groups/groups.selectors';
import { FieldWrapper, StyledLabel } from '../../edit/styled-form';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';

export const PatientGroupSelectField = (props: {
    onChange: (groupId: PatientGroupId) => void;
    currentPatientGroupId: PatientGroupId;
}): JSX.Element => {
    const groups = useSelector(getPatientGroupsForCurrentSelectedMedicGroup);

    return (
        <FieldWrapper>
            <StyledLabel required>
                {formatMessage(Strings.ui.label.general.groups)}
            </StyledLabel>

            <Select
                onChange={props.onChange}
                value={props.currentPatientGroupId}
            >
                {Object.keys(groups.byId).map((groupId) => (
                    <Select.Option
                        key={groups.byId[groupId].id}
                        value={groups.byId[groupId].id}
                    >
                        {groups.byId[groupId].name}
                    </Select.Option>
                ))}
            </Select>
        </FieldWrapper>
    );
};
