import React, { useState } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updatePatient } from '../../../../../redux/domains/active-patient/active-patient.actions';
import { validate } from '../../validate-edit-patient';
import { pathPatientAdministrationSwitch } from '../../../administration/path';
import { PhoneNumberField } from './fields/PhoneNumberField';
import { EmailField } from './fields/EmailField';
import { SsnField } from './fields/SsnField';
import { FamilyNameField } from './fields/FamilyNameField';
import { FirstNameField } from './fields/FirstNameField';
import {
    StyledForm,
    StyledButtonWrapper,
    StyledNeutralButton,
    StyledPrimaryButton
} from '../../styled-form';
import { FormikType } from '../../formik-type';
import { createFormikState, createRequestMeta } from './create-form-state';
import { NormalizedPatientType } from '../../../../../redux/domains/active-patient/active-patient.utils';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';
import { BackButtonWithArrow } from '../../../../../components/ui/buttons/BackButtonWithArrow';
import { Modal } from 'antd';
import { AppNeutralButton } from '../../../../../components/ui/buttons/AppNeutralButton';
import { AppPrimaryButton } from '../../../../../components/ui/buttons/AppPrimaryButton';
import { ComfirmEditMeta } from '../comfirm-modal/ConfirmEditMeta';

export const EditPatientMeta = (props: {
    patientId: string;
    patient: NormalizedPatientType;
    handleBack: () => void;
}): JSX.Element => {
    const routeHistory = useHistory();
    const dispatch = useDispatch();

    const [currFormikState, setCurrFormikState] = useState<FormikType>(
        createFormikState(props.patient.meta)
    );

    const [submitModalVisible, setSubmitModalVisible] = useState(false);
    const [waitingOnModal, setWaitingOnModal] = useState(false);
    const [waitingOnDispatch, setWaitingOnDispatch] = useState(false);

    const [requestState, setRequestState] = useState<FormikType>(
        createFormikState()
    );

    const onFormikSubmit = (values: FormikType) => {
        setRequestState(values);
        setSubmitModalVisible(true);
        setWaitingOnModal(true);
    };

    const onSubmitModalOk = async () => {
        setCurrFormikState(requestState);
        setWaitingOnDispatch(true);
        dispatch(
            updatePatient(props.patientId, createRequestMeta(requestState))
        );

        routeHistory.push(pathPatientAdministrationSwitch);
    };

    const onSubmitModalCancel = () => {
        setSubmitModalVisible(false);
        setWaitingOnModal(false);
    };

    return (
        <>
            <Modal
                title={formatMessage(Strings.editPatient.other.aboutToUpdate)}
                visible={submitModalVisible}
                onCancel={onSubmitModalCancel}
                footer={
                    <>
                        <AppNeutralButton onClick={onSubmitModalCancel}>
                            {formatMessage(Strings.common.general.cancel)}
                        </AppNeutralButton>
                        <AppPrimaryButton
                            loading={waitingOnDispatch}
                            onClick={onSubmitModalOk}
                        >
                            {formatMessage(Strings.common.general.save)}
                        </AppPrimaryButton>
                    </>
                }
            >
                <ComfirmEditMeta patientMeta={requestState} />
            </Modal>
            <BackButtonWithArrow
                style={{ marginBottom: 20 }}
                onClick={props.handleBack}
            />
            <Formik
                initialValues={currFormikState}
                enableReinitialize={true}
                onSubmit={async (values) => {
                    onFormikSubmit(values);
                }}
                validate={validate}
            >
                {(formikProps) => {
                    const {
                        values,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset
                    } = formikProps;

                    return (
                        <StyledForm onSubmit={handleSubmit}>
                            <FirstNameField
                                value={values.firstName}
                                error={errors.firstName}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                            />
                            <FamilyNameField
                                value={values.familyName}
                                error={errors.familyName}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                            />
                            <SsnField value={values.ssn} />
                            <EmailField
                                value={values.email}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                            />
                            <PhoneNumberField
                                value={values.phoneNumber}
                                error={errors.phoneNumber}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                            />

                            <StyledButtonWrapper>
                                <StyledNeutralButton
                                    onClick={handleReset}
                                    disabled={!dirty || isSubmitting}
                                >
                                    {formatMessage(
                                        Strings.common.general.reset
                                    )}
                                </StyledNeutralButton>
                                <StyledPrimaryButton
                                    loading={waitingOnModal}
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    {formatMessage(
                                        Strings.ui.buttons.general.save
                                    )}
                                </StyledPrimaryButton>
                            </StyledButtonWrapper>
                        </StyledForm>
                    );
                }}
            </Formik>
        </>
    );
};
