import { prnmedCI } from '../intermittent-claudication/indicators/prnmed/prnmed';
import { medication } from '../intermittent-claudication/indicators/medication/medication';
import { pain } from '../shared-indicators/pain/pain';
import { bloodPressure } from '../shared-indicators/blood-pressure/blood-pressure';
import { smoke } from '../shared-indicators/smoke/smoke';
import { physact } from '../shared-indicators/physact/physact';
import { vas } from '../shared-indicators/vas/vas';
import { eq5d3l } from '../shared-indicators/eq5d3l/eq5d3l';
import { conditions } from '../messages';
import { ConditionIndicator } from '../condition.type';
import { formatMessage } from '../../locale/format/format-message';

const code = 'LP_2_0_PAD_RS';
const publicCode = 'pad';

const indicators: ConditionIndicator[] = [
    prnmedCI,
    medication,
    pain,
    bloodPressure,
    smoke,
    physact,
    vas,
    eq5d3l
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(conditions.rs.pad.name),
        nameLong: formatMessage(conditions.rs.pad.nameLong)
    }
};

export default condition;
