import { defineMessages } from 'react-intl';

const educationFormPassed = defineMessages({
    title: {
        id: 'education-form-passed.title',
        defaultMessage: 'Kunskapstest för vårdpersonalen'
    },
    header: {
        id: 'education-form-passed.header',
        defaultMessage: 'Bra jobbat!'
    },
    information: {
        id: 'education-form-passed.information',
        defaultMessage:
            'Du har klarat testet och är nu kvalificerad att använda LifePod.'
    },
    button: {
        id: 'education-form-passed.ok-button',
        defaultMessage: 'Till Patientöversikten'
    }
});

export const messages = {
    educationFormPassed
};
