import base from '../base';
const title = {
    primary: '#595368',
    user: '#4d49dd'
};

const icon = {
    profile: '#32E288',
    manual: 'black',
    support: '#1380FF',
    about: '#847a7a',
    twoTone: '#E6E6E6'
};

const alert = {
    primarySoft: '#ccc'
};

const message = {
    patientBase: '#14b9ff',
    medicBase: base.purple.primary,
    text: '#fff',
    textAlt1: '#c1c1c5',
    otherBase: '#edeaef'
};

const slider = {
    primary: '#91d5ff',
    gradientGood: '#82fb6e',
    gradientBad: '#fbd859'
};

const emote = {
    good: '#1ddb85',
    bad: '#fbd859',
    neutral: '#1ddb85'
};

const actionPanel = {
    header: {
        primary: '#e1e1e1bf'
    }
};

const avatar = {
    iconCheck: { primary: '#3dd721' }
};

const menu = {
    item: {
        primary: '#fff',
        border: '#d3d3d35c'
    }
};

const badge = {
    base: '#5a63c2',
    base2: '#5b64c2'
};

const plotBand = {
    red: '#ffb9b9',
    yellow: '#ffffbb',
    okRange: '#c3f0c3'
};

const heatMap = {
    tooltip: {
        base: '#323232'
    }
};
const drawer = {
    base: '#fafafb'
};

export const ui = {
    avatar,
    alert,
    actionPanel,
    title,
    message,
    icon,
    slider,
    emote,
    menu,
    badge,
    plotBand,
    heatMap,
    drawer
};

export default ui;
