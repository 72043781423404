import { RouteItemType } from '../../../utils/route/switch-routes';
import { pathPatientSearch } from './path';
import { PatientSearchPage } from './PatientSearchPage';
import { titlePatientSearch } from './title';

export const routePatientSearch: RouteItemType = {
    name: titlePatientSearch,
    link: pathPatientSearch,
    icon: 'search',
    component: PatientSearchPage
};
