import React from 'react';
import {
    Field,
    FieldType
} from '../../../../../../components/ui/field/formik/FormikField';
import { Strings } from '../../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../../locale/format/format-message';

export const SsnField = (props: FieldType): JSX.Element => {
    const { value } = props;

    return (
        <Field
            id="ssn"
            value={value}
            label={`${formatMessage(
                Strings.editPatient.fields.ssn.label
            )} (${formatMessage(Strings.editPatient.other.nonEditable)})`}
            required
        />
    );
};
