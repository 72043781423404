import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Pain after activity',
        id: 'indicator.pain-after-activity-hs.title'
    }
});

export const messagesPainAfterActivity = {
    general
};
