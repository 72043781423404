import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Phlegm status',
        id: 'indicator.phlegm.title'
    },
    noPhlegm: {
        defaultMessage: 'No phlegm',
        id: 'indicator.phlegm.choices.1'
    },
    values: {
        defaultMessage: '{status}\nColor: {color}\nBlood: {blood}',
        id: 'indicator.phlegm.values'
    }
});

const blood = defineMessages({
    title: {
        defaultMessage: 'Blood',
        id: 'indicator.phlegm.blood.title'
    },
    description: {
        defaultMessage: 'Phlegm with blood (Elements of red or brown)',
        id: 'indicator.phlegm.blood.description'
    },
    choicesTrue: {
        defaultMessage: 'Yes',
        id: 'indicator.phlegm.blood.choices.true'
    },
    choicesFalse: {
        defaultMessage: 'No',
        id: 'indicator.phlegm.blood.choices.false'
    }
});

const color = defineMessages({
    title: {
        defaultMessage: 'Color',
        id: 'indicator.phlegm.color.title'
    },
    description: {
        defaultMessage: 'Phlegm color',
        id: 'indicator.phlegm.color.description'
    },
    noColor: {
        defaultMessage: 'No color',
        id: 'indicator.phlegm.color.choices.1'
    },
    yellow: {
        defaultMessage: 'Yellow',
        id: 'indicator.phlegm.color.choices.2'
    },
    green: {
        defaultMessage: 'Green',
        id: 'indicator.phlegm.color.choices.3'
    }
});

const heatMap = defineMessages({
    none: {
        defaultMessage: 'No phlegm',
        id: 'indicator.phlegm.heatmap.legend.1'
    },
    colorless: {
        defaultMessage: 'Colorless phlegm',
        id: 'indicator.phlegm.heatmap.legend.2'
    },
    yellow: {
        defaultMessage: 'Yellow phlegm',
        id: 'indicator.phlegm.heatmap.legend.3'
    },
    green: {
        defaultMessage: 'Green phlegm',
        id: 'indicator.phlegm.heatmap.legend.4'
    },
    withBlood: {
        defaultMessage: 'Phlegm with blood',
        id: 'indicator.phlegm.heatmap.legend.5'
    }
});

const status = defineMessages({
    title: {
        defaultMessage: 'Status',
        id: 'indicator.phlegm.status.title'
    },
    description: {
        defaultMessage: 'Phlegm status',
        id: 'indicator.phlegm.status.description'
    },
    noPhlegm: {
        defaultMessage: 'No phlegm',
        id: 'indicator.phlegm.status.choices.1'
    },
    easyToCoughUp: {
        defaultMessage: 'Easy to cough up',
        id: 'indicator.phlegm.status.choices.2'
    },
    hardToCoughUp: {
        defaultMessage: 'Hard to cough up',
        id: 'indicator.phlegm.status.choices.3'
    }
});

export const messagesPhlegm = {
    general,
    status,
    blood,
    color,
    heatMap
};
