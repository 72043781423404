import { createReducer } from '@reduxjs/toolkit';
import { setSelectedDate, sharedReset } from './chart.shared.actions';
import { sharedGetInitialState } from './chart.shared.state';

export const sharedReducer = createReducer(
    sharedGetInitialState(),
    (builder) => {
        builder
            .addCase(sharedReset.fulfilled, (state) => {
                Object.assign(state, sharedGetInitialState());
            })
            .addCase(setSelectedDate.fulfilled, (state, payload) => {
                state.selectedDate = payload.payload;
            });
    }
);
