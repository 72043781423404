export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_PENDING = 'LOGIN_USER_PENDING';
export const LOGIN_USER_FULFILLED = 'LOGIN_USER_FULFILLED';
export const LOGIN_USER_REJECTED = 'LOGIN_USER_REJECTED';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_PENDING = 'LOGOUT_USER_PENDING';
export const LOGOUT_USER_FULFILLED = 'LOGOUT_USER_FULFILLED';
export const LOGOUT_USER_REJECTED = 'LOGOUT_USER_REJECTED';

export const CHECK_AUTHENTICATION = 'CHECK_AUTHENTICATION';
export const CHECK_AUTHENTICATION_PENDING = 'CHECK_AUTHENTICATION_PENDING';
export const CHECK_AUTHENTICATION_FULFILLED = 'CHECK_AUTHENTICATION_FULFILLED';
export const CHECK_AUTHENTICATION_REJECTED = 'CHECK_AUTHENTICATION_REJECTED';

export const CREATE_SINGLE_SIGN_ON_URL = 'CREATE_SINGLE_SIGN_ON_URL';
export const CREATE_SINGLE_SIGN_ON_URL_PENDING =
    'CREATE_SINGLE_SIGN_ON_URL_PENDING';
export const CREATE_SINGLE_SIGN_ON_URL_FULFILLED =
    'CREATE_SINGLE_SIGN_ON_URL_FULFILLED';
export const CREATE_SINGLE_SIGN_ON_URL_REJECTED =
    'CREATE_SINGLE_SIGN_ON_URL_REJECTED';

// SAML action types
export const CREATE_AUTH_REQUEST = 'CREATE_AUTH_REQUEST';
export const CREATE_AUTH_REQUEST_PENDING = 'CREATE_AUTH_REQUEST_PENDING';
export const CREATE_AUTH_REQUEST_FULFILLED = 'CREATE_AUTH_REQUEST_FULFILLED';
export const CREATE_AUTH_REQUEST_REJECTED = 'CREATE_AUTH_REQUEST_REJECTED';
