import axios from 'axios';

const appConfig = __APP_CONFIG__;

export const publicAxios = axios.create({
    baseURL: appConfig.API_ROOT_URL,
    timeout: 10000
});

publicAxios.interceptors.request.use(
    (request) => {
        request.headers['Content-Type'] = 'application/json';
        request.headers['x-api-key'] = appConfig.API_KEY;
        return request;
    },
    (error) => {
        console.error(error);
        return Promise.reject(error);
    }
);
