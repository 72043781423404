import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Alert, Button, Input, Select } from 'antd';
import {
    StyledActionTabHeader,
    StyledDivider
} from '../../../../styledActionTabs';
import { resetPriority } from '../../../../../../../../constants/reset-priority';
import { reset } from './message-descriptors';
import { Strings } from '../../../../../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../../../../../locale/format/format-message';
import Form, { FormComponentProps } from 'antd/lib/form/Form';

interface ResetFormProps extends FormComponentProps {
    toggleResetForm: () => void;
    reset_reasons: string[];
    loading: boolean;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

type Ref = FormComponentProps;

const ResetForm = forwardRef<Ref, ResetFormProps>(
    (
        {
            form,
            onSubmit,
            toggleResetForm,
            reset_reasons,
            loading
        }: ResetFormProps,
        ref
    ) => {
        useImperativeHandle(ref, () => ({
            form
        }));
        const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onSubmit(e);
        };
        const resetReasons = reset_reasons;
        const getFieldDecorator = form.getFieldDecorator;

        const [resetReason, setResetReason] = useState(resetReasons[0]);

        return (
            <Form onSubmit={handleSubmit}>
                <StyledActionTabHeader>
                    {formatMessage(reset.general.title)}
                </StyledActionTabHeader>
                <Alert
                    message={formatMessage(
                        Strings.resetPriority.general.description
                    )}
                    type="warning"
                    style={{ fontSize: 12, marginBottom: 20 }}
                    showIcon
                />
                <Form.Item>
                    {getFieldDecorator('reason', {
                        initialValue: resetReasons[0]
                    })(
                        <Select
                            onChange={setResetReason}
                            style={{ width: '100%' }}
                            dropdownMatchSelectWidth={false}
                        >
                            {resetReasons.map((reason: string) => {
                                return (
                                    <Select.Option key={reason} value={reason}>
                                        {formatMessage(
                                            reset.general.resetPriorityReason,
                                            { reason }
                                        )}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    )}
                </Form.Item>
                {(resetReason === resetPriority.OTHER ||
                    resetReason === resetPriority.CHANGED_MEDICATION_OTHER) && (
                    <Form.Item>
                        {getFieldDecorator('note', {
                            rules: [
                                {
                                    required: true,
                                    message: formatMessage(
                                        Strings.resetPriority.general.required
                                    )
                                }
                            ]
                        })(
                            <Input
                                placeholder={formatMessage(
                                    Strings.common.general.reason
                                )}
                                style={{ width: '100%' }}
                            />
                        )}
                    </Form.Item>
                )}

                <div style={{ display: 'flex' }}>
                    <Button
                        style={{ flex: 1, marginRight: 5 }}
                        onClick={toggleResetForm}
                    >
                        {formatMessage(Strings.common.general.cancel)}
                    </Button>
                    <Button
                        type="primary"
                        style={{ flex: 1, marginLeft: 5 }}
                        htmlType="submit"
                        loading={loading}
                    >
                        {formatMessage(Strings.common.general.save)}
                    </Button>
                </div>
                <StyledDivider />
            </Form>
        );
    }
);

ResetForm.displayName = 'ResetForm';
export const WrappedResetForm = Form.create<ResetFormProps>()(ResetForm);
