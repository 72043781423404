import { defineMessages } from 'react-intl';

const general = defineMessages({
    resetZoom: {
        id: 'medic.graph.resetZoom',
        defaultMessage: 'Reset zoom'
    },
    removeTimeMarker: {
        id: 'medic.graph.removeTimeMarker',
        defaultMessage: 'Remove time marker'
    }
});

export const graph = {
    general
};
