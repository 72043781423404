import { createAsyncThunk } from '@reduxjs/toolkit';
import { IndicatorSettingsMapping } from '../../../../conditions/condition.type';
import { IndicatorCode } from '../../../../types/indicator/indicator.type';
import {
    Condition,
    ConditionConfiguration
} from '../../conditions/conditions.type';
import { IndicatorWithSettings } from '../../indicators/indicator.type';
import { ValuesIndicator } from '../../values/values.type';
import {
    CHART_GRAPH_INITIALIZE_SETTINGS,
    CHART_GRAPH_RESET_CHARTS,
    CHART_GRAPH_SET_HIDDEN,
    CHART_GRAPH_SET_SHOW
} from './chart.graph.constants';
import { graphGetInitialState } from './chart.graph.state';
import { GraphState } from './chart.graph.types';

export const graphInitializeSettings = createAsyncThunk(
    CHART_GRAPH_INITIALIZE_SETTINGS,
    (payload: {
        condition: Condition;
        conditionConfiguration: ConditionConfiguration;
        indicatorSettingsByCode: IndicatorSettingsMapping;
        indicatorsByCode: Record<IndicatorCode, IndicatorWithSettings>;
        values: Record<IndicatorCode, ValuesIndicator | undefined>;
    }) => payload
);

export const graphReset = createAsyncThunk(
    CHART_GRAPH_RESET_CHARTS,
    (): GraphState => graphGetInitialState()
);

export const graphSetHiddenindicator = createAsyncThunk(
    CHART_GRAPH_SET_HIDDEN,
    (params: { code: IndicatorCode }) => params
);

export const graphSetShowIndicator = createAsyncThunk(
    CHART_GRAPH_SET_SHOW,
    (params: { code: IndicatorCode }) => params
);
