import React from 'react';
import { Button } from 'antd';
import { FormattedDate, FormattedTime } from 'react-intl';
import { formatMessage } from '../../../../../../../locale/format/format-message';
import { Strings } from '../../../../../../../locale/messagesDescriptors';
import { getValueColumn } from './getValueColumn';
import { IndicatorWithSettings } from '../../../../../../../redux/domains/indicators/indicator.type';
import { Row } from '../utils/table-row-types';

export const getColumns = (
    indicator: IndicatorWithSettings,
    keys: string[],
    onDetailsClick: (row: Row) => void
) => {
    const valueColumns = getValueColumn(indicator, keys);

    let columns = [
        {
            key: 'date',
            fixed: 'left',
            title: formatMessage(Strings.ui.table.general.date),
            dataIndex: 'date',
            width: 175,
            height: 'inherit',

            render: (text: number) => {
                return (
                    <div>
                        <span>
                            <FormattedDate value={new Date(text)} />
                        </span>
                        <span style={{ marginLeft: 10 }}>
                            <FormattedTime value={new Date(text)} />
                        </span>
                    </div>
                );
            }
        }
    ];

    columns = [
        ...columns,
        ...valueColumns,
        {
            key: 'notes',
            title: formatMessage(Strings.ui.table.general.notes),
            width: 175,
            dataIndex: 'comment'
        },
        {
            key: 'empty'
        }
    ];

    if (indicator.form && indicator.form.type === 'questionnaire') {
        const formNotCombined = {
            key: 'details',
            title: '',
            width: 100,
            fixed: 'right',
            height: 'inherit',
            render: (row: Row) => {
                return (
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => onDetailsClick(row)}
                    >
                        {formatMessage(Strings.ui.buttons.general.details)}
                    </Button>
                );
            }
        };
        columns.push(formNotCombined as any);
    }

    return columns;
};
