import React, {
    FocusEventHandler,
    MouseEventHandler,
    PropsWithChildren
} from 'react';

type Props = {
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
    onFocus?: FocusEventHandler;
    onClick?: MouseEventHandler;
};

export const AntdToolTipContentWrapper = (props: PropsWithChildren<Props>) => (
    <div
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onFocus={props.onFocus}
        onClick={props.onClick}
    >
        {props.children}
    </div>
);
