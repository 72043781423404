import React from 'react';
import { StyledBoldSpan } from '../styled';

export const ModalDetailRow = (props: { label: string; text: string }) => {
    const { label, text } = props;

    return (
        <p>
            <StyledBoldSpan>{`${label}: `}</StyledBoldSpan>
            {text}
        </p>
    );
};
