import React from 'react';
import styled from 'styled-components';
import { videoFiles } from './education-sources';
import { ColumnItem } from './column/ColumnItem';
import { CustomImage } from '../../../../../components/image/custom-image/CustomImage';
import { ColumnLinkItem } from './column/ColumnLinkItem';
import { appIcons } from '../../../../../constants/app-icons';
import { Icon } from 'antd';
import { messagesEducation } from './messages';
import { resource } from '../../../../../constants/resources';
import { ColumnVideoItem } from './column/ColumnVideoItem';
import { MarkdownFromDescriptor } from '../../../../../locale/format/format-markdown';
import { useReduxSelector } from '../../../../../hooks/use-redux-selector';
import { pathEducationForm } from '../../../form/path';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';

type Props = {
    handleClick: (path: string) => void;
};

export const EducationModule = (props: Props): JSX.Element => {
    const isEducated = useReduxSelector((state) => state.user.medicEducated);

    return (
        <div style={{ display: 'flex' }}>
            <StyledSection>
                <StyledSectionTitle>
                    {formatMessage(
                        Strings.screen.education.videos.module.title
                    )}
                </StyledSectionTitle>
                {videoFiles.map((item, index) => (
                    <ColumnVideoItem
                        key={index}
                        onClick={() => props.handleClick(item.path)}
                        icon={<StyledIcon type="play-circle" />}
                        title={item.title}
                    >
                        {item.desc}
                    </ColumnVideoItem>
                ))}
            </StyledSection>
            <StyledSection>
                {!isEducated && (
                    <>
                        <StyledSectionTitle>
                            {formatMessage(
                                Strings.screen.education.form.page.title
                            )}
                        </StyledSectionTitle>
                        <ColumnLinkItem
                            path={pathEducationForm}
                            icon={<StyledIcon type="form" />}
                        >
                            <MarkdownFromDescriptor
                                messageDescriptor={
                                    Strings.screen.education.form.introduction
                                        .form1.desc
                                }
                            />
                        </ColumnLinkItem>
                    </>
                )}
                <StyledSectionTitle>
                    {formatMessage(messagesEducation.general.manual)}
                </StyledSectionTitle>
                <ColumnItem
                    icon={
                        <>
                            <StyledImage src={appIcons.manual} />
                            <StyledImage src={appIcons.triangle} />
                        </>
                    }
                >
                    <StyledA
                        href={resource.medicUrlForManual}
                        target="_blank"
                        rel="noopener"
                    >
                        {formatMessage(messagesEducation.manual.medic)}
                    </StyledA>
                    <StyledA
                        href={resource.patientUrlForManual}
                        target="_blank"
                        rel="noopener"
                    >
                        {formatMessage(messagesEducation.manual.patient)}
                    </StyledA>
                </ColumnItem>
            </StyledSection>
        </div>
    );
};

const StyledImage = styled(CustomImage)`
    // target the fill inside svg
    // Sketch creates an extra g path, so in order to change the color, we need to target it like this.

    && {
        height: 32px;
        width: 36px;
        path {
            fill: ${(props) => props.theme.primary.color};
        }
    }
`;

const StyledSection = styled.div`
    margin-right: 40px;
`;

const StyledSectionTitle = styled.h2`
    font-weight: bold;
    border-bottom: 1px solid black;
    margin-bottom: ${(props) => props.theme.spacing.large}px;
`;

const StyledIcon = styled(Icon)`
    && {
        font-size: 32px;
        color: ${(props) => props.theme.primary.color};
    }
`;

const StyledA = styled.a`
    // This is used to fool intellisense
    // noreferrer
    // Since we have control over our content the risk is neglectable
    // https://mathiasbynens.github.io/rel-noopener/

    display: block;
    margin-bottom: 16px;
    :last-of-type {
        margin-bottom: 0;
    }
`;
