import React from 'react';
import { PatientGroup } from '../../../../../redux/domains/groups/groups.type';
import { ActionPanelWrapper } from '../../../../../components/action-panel/ActionPanelWrapper';
import { conditions } from '../../../../../conditions';
import { ActivePatient } from '../../../../../redux/domains/active-patient/active-patient.type';
import { styled } from '../../../../../theme/styled';

type Props = {
    patient: ActivePatient;
    patientGroup: PatientGroup;
    conditionCode: string;
    isSelected: boolean;
    showActionPanel: boolean;
    onClick: () => void;
};

export const PatientDataGroup = (props: Props) => {
    const {
        patient,
        patientGroup,
        conditionCode,
        isSelected,
        showActionPanel,
        onClick
    } = props;
    const { name, family_name } = patient.meta;

    return (
        <StyledGroup
            key={patientGroup.id}
            $selected={isSelected}
            onClick={onClick}
        >
            <StyledContent>
                <StyledPatientGroupName>
                    {patientGroup.name}
                </StyledPatientGroupName>
                <StyledConditionName>
                    {conditions[conditionCode].name}
                </StyledConditionName>
            </StyledContent>

            {showActionPanel && (
                <StyledActionPanelButton>
                    <ActionPanelWrapper
                        name={`${name} ${family_name}`}
                        externalId={patient.externalId}
                        patientGroup={patientGroup}
                        conditionCode={conditionCode}
                        disabled={showActionPanel && !isSelected}
                    />
                </StyledActionPanelButton>
            )}
        </StyledGroup>
    );
};

const StyledGroup = styled.div<{ $selected: boolean }>`
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    color: ${({ $selected, theme }) =>
        $selected ? theme.color.selected : 'inherit'};
    border: 1px solid
        ${({ $selected, theme }) =>
            $selected ? theme.color.selected : '#e8e8e8'};
    background: ${({ $selected }) => ($selected ? '#fff' : '#fafafa')};
    cursor: ${({ $selected }) => ($selected ? 'default' : 'pointer')};
    box-shadow: ${({ $selected }) =>
        $selected ? '0 2px 5px rgba(0, 0, 0, 0.1)' : 'none'};
    transition: 0.5s;
    user-select: none;

    color: ${({ $selected, theme }) =>
        $selected ? theme.color.selected : theme.color.text};

    &:hover {
        color: ${({ $selected, theme }) =>
            $selected ? theme.color.selected : theme.color.hover};
        border: 1px solid ${({ theme }) => theme.color.hover};
        transition: 0s;
    }
`;

const StyledContent = styled.div`
    display: flex;
    min-height: 40px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const StyledPatientGroupName = styled.p`
    margin: 0;
    white-space: nowrap;
    font-weight: bold;
`;

const StyledConditionName = styled.p`
    margin: 0;
    white-space: nowrap;
`;

const StyledActionPanelButton = styled.div`
    margin-left: 20px;
    font-size: 2em;
    color: #8b67ce;
`;
