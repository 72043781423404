import * as actionTypes from './video-meeting.constants';
import { VideoMeeting } from './video-meeting.types';

const initialState: VideoMeeting = {
    subjectId: '',
    token: ''
};

const videoMeetingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.CREATE_VIDEO_MEETING_FULFILLED:
            return { ...state, ...action.payload };
        case actionTypes.JOIN_VIDEO_MEETING:
            return { ...state, subjectId: action.payload };
        case actionTypes.LEAVE_VIDEO_MEETING_PENDING:
            return {
                ...state,
                subjectId: ''
            };
        case actionTypes.LEAVE_VIDEO_MEETING_FULFILLED:
        case actionTypes.LEAVE_VIDEO_MEETING_REJECTED:
            return state.subjectId ? state : initialState;
        default:
            return state;
    }
};

export default videoMeetingReducer;
