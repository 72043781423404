import { formatMessage } from '../../../locale/format/format-message';
import { validation } from './message-descriptors';
import { ValidationMessages } from './types';

export const defaultValidationTexts: ValidationMessages = {
    isRequired: formatMessage(validation.required),
    tooShort: formatMessage(validation.defaultLength),
    tooLong: formatMessage(validation.defaultLength),
    patternMismatch: formatMessage(validation.patternMismatch),
    custom: formatMessage(validation.patternMismatch)
};
