import { uniq } from 'lodash';
import { formatUser } from '../../utils';
import { CREATE_PATIENT_FULFILLED } from '../active-patient/active-patient.constants';
import {
    TOGGLE_MEDIC_FULFILLED,
    TOGGLE_MEDIC_PENDING,
    TOGGLE_MEDIC_REJECTED,
    UPDATE_MEDIC_FULFILLED,
    UPDATE_MEDIC_GROUPS_FULFILLED
} from '../medic/medic.constants';
import { BaseUserResponse, Roles } from '../user/user.api.type';
import { GroupsNormalizedResponse } from './groups.api.type';
import {
    DELETE_USERS_FROM_GROUP_FULFILLED,
    FETCH_GROUPS_FULFILLED,
    INIT_GROUPS_FULFILLED,
    INIT_GROUPS_PENDING,
    SET_SELECTED_MEDIC_GROUP,
    SET_SELECTED_PATIENT_GROUP
} from './groups.constants';
import { GroupsState } from './groups.type';

const initialState: GroupsState = {
    medicGroups: {
        byId: {},
        allIds: []
    },
    patientGroups: {
        byId: {},
        allIds: []
    },
    selectedMedicGroupId: '',
    selectedPatientGroupId: '',
    medicUsers: {},
    loaded: false
};

export default function groupsReducer(state = initialState, action: any) {
    const { payload, type } = action;

    switch (type) {
        case SET_SELECTED_PATIENT_GROUP: {
            const selectedMedicGroup = state.selectedMedicGroupId
                ? state.medicGroups.byId[state.selectedMedicGroupId]
                : null;
            const selectedPatientGroupId =
                payload && state.patientGroups.byId[payload]
                    ? payload
                    : selectedMedicGroup?.manages[0] || '';
            return {
                ...state,
                selectedPatientGroupId
            };
        }
        case SET_SELECTED_MEDIC_GROUP: {
            const { groupId, userId } = payload;

            const allGroupIds = state.medicGroups.allIds;
            const medicGroupsForCurrentUser =
                state.medicUsers[userId].medicGroups;

            const remainingGroups = allGroupIds?.filter((allGroupId) =>
                medicGroupsForCurrentUser?.includes(allGroupId)
            );

            return {
                ...state,
                selectedMedicGroupId:
                    groupId && state.medicGroups.byId[groupId]
                        ? groupId
                        : remainingGroups[0] || ''
            };
        }
        case INIT_GROUPS_PENDING:
            return { ...state, loaded: false };
        case INIT_GROUPS_FULFILLED: {
            const {
                medicGroups,
                patientGroups,
                medicUsers
            }: GroupsNormalizedResponse = payload;
            return {
                ...state,
                medicGroups: {
                    byId: medicGroups,
                    allIds: Object.keys(medicGroups)
                },
                patientGroups: {
                    byId: patientGroups,
                    allIds: Object.keys(patientGroups)
                },
                medicUsers,
                loaded: true
            };
        }
        case FETCH_GROUPS_FULFILLED: {
            const {
                medicGroups,
                patientGroups,
                medicUsers
            }: GroupsNormalizedResponse = payload;
            return {
                ...state,
                medicGroups: {
                    byId: medicGroups,
                    allIds: Object.keys(medicGroups)
                },
                patientGroups: {
                    byId: patientGroups,
                    allIds: Object.keys(patientGroups)
                },
                medicUsers
            };
        }
        case CREATE_PATIENT_FULFILLED: {
            const { externalId, group }: BaseUserResponse = formatUser(payload);

            return {
                ...state,
                patientGroups: {
                    ...state.patientGroups,
                    byId: group.reduce((byId, id) => {
                        const userIds = byId[id].users;
                        return {
                            ...byId,
                            [id]: {
                                ...byId[id],
                                users: [externalId, ...userIds]
                            }
                        };
                    }, state.patientGroups.byId)
                }
            };
        }
        case UPDATE_MEDIC_FULFILLED: {
            return {
                ...state,
                medicUsers: {
                    ...state.medicUsers,
                    [payload.externalId]: {
                        ...state.medicUsers[payload.externalId],
                        meta: payload.meta
                    }
                }
            };
        }
        case TOGGLE_MEDIC_PENDING: {
            return {
                ...state,
                medicUsers: {
                    ...state.medicUsers,
                    [action.meta.medicId]: {
                        ...state.medicUsers[action.meta.medicId],
                        loading: true
                    }
                }
            };
        }
        case TOGGLE_MEDIC_REJECTED: {
            return {
                ...state,
                medicUsers: {
                    ...state.medicUsers,
                    [action.meta.medicId]: {
                        ...state.medicUsers[action.meta.medicId],
                        loading: false
                    }
                }
            };
        }
        case TOGGLE_MEDIC_FULFILLED: {
            return {
                ...state,
                medicUsers: {
                    ...state.medicUsers,
                    [payload.externalId]: {
                        ...state.medicUsers[payload.externalId],
                        status: payload.status,
                        loading: false
                    }
                }
            };
        }
        case UPDATE_MEDIC_GROUPS_FULFILLED: {
            const { medicId, groupId, data } = action.payload;
            const selectedMedic = state.medicUsers[medicId];

            const pendingGroups = updateGroups({
                medicId,
                groupId,
                roles: data.roles,
                selectedMedic: selectedMedic
            });

            return {
                ...state,
                medicUsers: {
                    ...state.medicUsers,
                    [medicId]: {
                        ...state.medicUsers[medicId],
                        groups: pendingGroups.groups,
                        adminGroups: pendingGroups.adminGroups,
                        medicGroups: pendingGroups.medicGroups
                    }
                }
            };
        }
        case DELETE_USERS_FROM_GROUP_FULFILLED: {
            const { medicId, groupId } = action.payload;
            const selectedMedic = state.medicUsers[medicId];
            const pendingGroups = removeGroups({
                medicId,
                groupId,
                selectedMedic: selectedMedic
            });

            return {
                ...state,
                medicUsers: {
                    ...state.medicUsers,
                    [medicId]: {
                        ...state.medicUsers[medicId],
                        groups: pendingGroups.groups,
                        adminGroups: pendingGroups.adminGroups,
                        medicGroups: pendingGroups.medicGroups
                    }
                }
            };
        }
        default:
            return state;
    }
}

function updateGroups(params: {
    medicId: string;
    groupId: string;
    roles: string[];
    selectedMedic: any;
}) {
    const pendingGroups = {
        groups: [...params.selectedMedic.groups],
        medicGroups: params.selectedMedic.medicGroups
            ? [...params.selectedMedic.medicGroups]
            : [],
        adminGroups: params.selectedMedic.adminGroups
            ? [...params.selectedMedic.adminGroups]
            : []
    };

    if (params.roles.includes(Roles.admin)) {
        if (!pendingGroups.adminGroups.includes(params.groupId)) {
            pendingGroups.adminGroups = [
                ...pendingGroups.adminGroups,
                params.groupId
            ];
        }
    } else {
        if (pendingGroups.adminGroups.includes(params.groupId)) {
            pendingGroups.adminGroups = pendingGroups.adminGroups.filter(
                (groupId) => groupId !== params.groupId
            );
        }
    }

    if (params.roles.includes(Roles.medic)) {
        if (!pendingGroups.medicGroups.includes(params.groupId)) {
            pendingGroups.medicGroups = [
                ...pendingGroups.medicGroups,
                params.groupId
            ];
        }
    } else {
        if (pendingGroups.medicGroups.includes(params.groupId)) {
            pendingGroups.medicGroups = pendingGroups.medicGroups.filter(
                (groupId) => groupId !== params.groupId
            );
        }
    }

    pendingGroups.groups = uniq([
        ...pendingGroups.adminGroups,
        ...pendingGroups.medicGroups
    ]);

    return pendingGroups;
}

function removeGroups(params: {
    medicId: string;
    groupId: string;
    selectedMedic: any;
}) {
    const pendingGroups = {
        groups: [...params.selectedMedic.groups],
        medicGroups: [...params.selectedMedic.medicGroups],
        adminGroups: [...params.selectedMedic.adminGroups]
    };

    pendingGroups.adminGroups = pendingGroups.adminGroups.filter(
        (groupId) => groupId !== params.groupId
    );

    if (pendingGroups.medicGroups.includes(params.groupId)) {
        pendingGroups.medicGroups = pendingGroups.medicGroups.filter(
            (groupId) => groupId !== params.groupId
        );
    }

    pendingGroups.groups = uniq([
        ...pendingGroups.adminGroups,
        ...pendingGroups.medicGroups
    ]);

    return pendingGroups;
}
