import {
    hasRequiredFields,
    isEveryFieldValid,
    isUnrequiredFieldsValid
} from '../../../../../components/form/inputs/validation';
import { Fields } from '../../../../../components/form/inputs/types';

export function isFormValid(fields: Fields): boolean {
    if (hasRequiredFields(fields)) {
        return isEveryFieldValid(fields);
    }

    return isUnrequiredFieldsValid(fields);
}
