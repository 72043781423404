import React, { PropsWithChildren, useRef } from 'react';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/interface';
import moment from 'moment';
import styled from 'styled-components';
import { Filters } from '../../../../../../../redux/domains/values/values.type';

export const DateFilter = (
    props: PropsWithChildren<{
        defaultValue: Filters;
        onDateChange: (value: Filters) => void;
    }>
) => {
    const { defaultValue, onDateChange } = props;
    const dateRef = useRef(defaultValue);

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return !!current && current > moment().endOf('day');
    };

    return (
        <StyledFilterPanel>
            <StyledPanelFilters>
                <DatePicker
                    allowClear={true}
                    placeholder={moment(defaultValue.from).format('YYYY-MM-DD')}
                    defaultValue={moment(defaultValue.from)}
                    disabledDate={disabledDate}
                    onChange={(date) => {
                        dateRef.current = {
                            ...dateRef.current,
                            from: date?.valueOf()
                        };

                        onDateChange(dateRef.current);
                    }}
                />
                <StyledSpan>-</StyledSpan>
                <DatePicker
                    allowClear={true}
                    placeholder={moment(defaultValue.to).format('YYYY-MM-DD')}
                    defaultValue={moment(defaultValue.to)}
                    disabledDate={disabledDate}
                    onChange={(date) => {
                        dateRef.current = {
                            ...dateRef.current,
                            to: date?.valueOf()
                        };

                        onDateChange(dateRef.current);
                    }}
                />
                {props.children}
            </StyledPanelFilters>
        </StyledFilterPanel>
    );
};

export const StyledFilterPanel = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
`;

export const StyledPanelFilters = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: linear-gradient(90deg, #fff, rgba(221, 234, 255, 0.5));
    padding: 15px;

    > button,
    > .ant-calendar-picker,
    > span {
        margin-left: 20px;
    }
`;

const StyledSpan = styled.span`
    font-size: 20px;
`;
