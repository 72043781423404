import { colors } from '../../../theme/colors/commonColors';
import { ChartType } from '../../../types/indicator/chart.type';
import { IndicatorChartGraphRules } from '../../../types/indicator/indicator-rules.type';
import { BloodPressureProperties } from './blood-pressure-properties';

export const rules: IndicatorChartGraphRules<BloodPressureProperties> = {
    step: 1,
    chartTypes: [ChartType.Spline],
    lineColor: {
        name: colors.chart.graph.blue.name,
        default: colors.chart.graph.blue.primary,
        systolic: colors.chart.graph.blue.primary,
        diastolic: colors.chart.graph.blue.primaryDark
    },
    shouldNotRenderPlotbands: true
};
