import styled from 'styled-components';

export const StyledPieWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

export const StyledPieContent = styled.div`
    border-right: 1px dotted grey;
    padding-bottom: 1em;
`;

export const StyledPatientsInfo = styled.div`
    border-right: 1px dotted grey;
    padding: 0 1em;
    width: 220px;
`;

export const StyledBarContent = styled.div`
    padding-left: 1em;
    width: 500px;
`;

export const StyledPatientsInfoContent = styled.div`
    display: flex;
    border-bottom: 1px dotted grey;
`;

export const StyledDateWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 577px;
`;

export const StyledSelectedDateTitle = styled.div`
    text-align: center;
    > h3 {
        margin-top: 21px;
    }
`;
