import { HTTPClient } from '../../../services/http-client/http-client';
import { errorHandler } from '../../../services/http-client/handle-error';
import { PatientMeta } from './patient-meta.type';

export const fetchPatientMetaApi = (ssn: string) => {
    const response = HTTPClient.get<PatientMeta>('users/external', {
        params: { ssn }
    }).catch((error) => errorHandler(error, 'Error fetching PatientInfo'));
    return response;
};
