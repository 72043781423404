import React from 'react';

import styled from 'styled-components';
import {
    APPETITE,
    BLOOD_PRESSURE,
    BMI,
    BODY_LENGTH,
    BREATHLESSNESS,
    CAT,
    DIZZINESS,
    EHFSCBS,
    EHRA,
    EQ5D3L,
    MEDICATION_CI,
    MEDICATION_HS,
    MMRC,
    ORTHOSIS_NIGHT_HS,
    PAIN_CI,
    PHLEGM,
    PHYSACT,
    PRNMED,
    PRNMED_AF,
    PRNMED_CI,
    PRNMED_COPD,
    PRNMED_LVAD,
    PUMP_FLOW_LVAD,
    PUMP_POWER_LVAD,
    PUMP_PULSE_INDEX_LVAD,
    PUMP_SELF_TEST_LVAD,
    PUMP_SPEED_LVAD,
    SLEEP_HS,
    SMOKE,
    SWELLING,
    TIREDNESS
} from '../../../../../../../conditions/indicator-meta';
import { Strings } from '../../../../../../../locale/messagesDescriptors';
import {
    formatMessage,
    formatMessageById
} from '../../../../../../../locale/format/format-message';
import { IndicatorConfigurationState } from '../../../../../../../redux/domains/indicators/indicator.type';

const PreviewRow = styled.div`
    font-style: italic;
`;

type Limits = {
    min: number;
    max: number;
    type: string;
    customTextId: string | undefined;
};

export const getMessage = (limits: Limits, code: string) => {
    const { min, max, customTextId } = limits;

    if (code === BMI.code) {
        const bmiMin = formatMessageById(`indicator.${'bmi'}.value`, {
            value: min
        });
        const bmiMax = formatMessageById(`indicator.${'bmi'}.value`, {
            value: max
        });

        return formatMessageById(
            `${Strings.limits.rootId}${customTextId || 'general'}`,
            {
                min: bmiMin,
                max: bmiMax,
                unit: formatMessageById(
                    `${Strings.indicators.rootId}${code.toLowerCase()}.unit`,
                    { value: max }
                )
            }
        );
    }
    return formatMessageById(
        `${Strings.limits.rootId}${customTextId || 'general'}`,
        {
            min: min,
            max: max,
            unit: formatMessageById(
                `${Strings.indicators.rootId}${code.toLowerCase()}.unit`,
                { value: max }
            )
        }
    );
};

const PreviewRecommendedReportInterval = ({
    indicatorCode,
    indicatorConfiguration
}: {
    indicatorCode: string;
    indicatorConfiguration: IndicatorConfigurationState;
}) => {
    const configuration = indicatorConfiguration.configuration;
    const inUse = configuration.in_use || false;

    if (!inUse) return <>{formatMessage(Strings.common.general.inactive)}</>;

    const recommendedIntervals = getRecommendedIntervals(
        indicatorCode,
        configuration
    );

    return (
        <div>
            {recommendedIntervals.map((limits, index) => {
                if (limits.type === 'boolean') return null;

                const message = getMessage(limits, indicatorCode);

                return (
                    <PreviewRow key={`${indicatorCode}-${index}`}>
                        {message}
                    </PreviewRow>
                );
            })}
        </div>
    );
};

export default PreviewRecommendedReportInterval;
export const getMinAndMax = (config: any, customTextId?: string) => {
    const {
        report_min,
        lower_red,
        lower_yellow,
        upper_yellow,
        upper_red,
        report_max,
        type
    } = config;

    return {
        min: lower_yellow || lower_red || report_min,
        max: upper_yellow || upper_red || report_max,
        type,
        customTextId
    };
};

export const getRecommendedIntervals = (code: string, configuration: any) => {
    switch (code) {
        case BLOOD_PRESSURE.code: {
            const systolic = getMinAndMax(
                configuration.systolic,
                `${BLOOD_PRESSURE.code.toLowerCase()}.${
                    BLOOD_PRESSURE.properties.systolic
                }`
            );
            const diastolic = getMinAndMax(
                configuration.diastolic,
                `${BLOOD_PRESSURE.code.toLowerCase()}.${
                    BLOOD_PRESSURE.properties.diastolic
                }`
            );
            return [systolic, diastolic];
        }
        case PHYSACT.code: {
            const duration = getMinAndMax(
                configuration.duration,
                `${PHYSACT.code.toLowerCase()}.${PHYSACT.properties.duration}`
            );
            const intensity = getMinAndMax(
                configuration.intensity,
                `${PHYSACT.code.toLowerCase()}.${PHYSACT.properties.intensity}`
            );
            return [duration, intensity];
        }
        case CAT.code: {
            const limits = getMinAndMax(configuration.sum);
            return [limits];
        }
        case BMI.code: {
            const limits = getMinAndMax(configuration.bmi);
            return [limits];
        }
        case PAIN_CI.code: {
            const rest = getMinAndMax(
                configuration.rest,
                `${PAIN_CI.code.toLowerCase()}.${PAIN_CI.properties.rest}`
            );
            const active = getMinAndMax(
                configuration.active,
                `${PAIN_CI.code.toLowerCase()}.${PAIN_CI.properties.active}`
            );
            return [rest, active];
        }
        case APPETITE.code:
        case BODY_LENGTH.code:
        case BREATHLESSNESS.code:
        case DIZZINESS.code:
        case EHFSCBS.code:
        case EHRA.code:
        case EQ5D3L.code:
        case MEDICATION_CI.code:
        case MEDICATION_HS.code:
        case MMRC.code:
        case ORTHOSIS_NIGHT_HS.code:
        case PHLEGM.code:
        case PRNMED.code:
        case PRNMED_AF.code:
        case PRNMED_CI.code:
        case PRNMED_COPD.code:
        case PRNMED_LVAD.code:
        case PUMP_FLOW_LVAD.code:
        case PUMP_POWER_LVAD.code:
        case PUMP_PULSE_INDEX_LVAD.code:
        case PUMP_SELF_TEST_LVAD.code:
        case PUMP_SPEED_LVAD.code:
        case SLEEP_HS.code:
        case SMOKE.code:
        case SWELLING.code:
        case TIREDNESS.code:
            return [];
        default: {
            return [getMinAndMax(configuration)];
        }
    }
};
