import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Icon, Input } from 'antd';
import SimpleForm from '../../../components/form/SimpleForm';
import { testSelectors } from '../../../constants/test-id';
import {
    LoginEmailButton,
    StyledLargeButton
} from './components/LoginEmailButton';
import { loginWithCredentials } from '../../../redux/domains/authentication/authentication.actions';
import { LoginWrapper } from '../login/components/LoginWrapper';
import { titleLoginEmailPage } from './title';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';

const FormItem = Form.Item;

type EmailCredentials = {
    username: string;
    password: string;
};

export const LoginEmailPage = () => {
    const dispatch = useDispatch<any>();

    const onSubmit = (form: any) => {
        form.validateFields((errors: any, values: EmailCredentials) => {
            if (!errors) {
                const { username, password } = values;
                dispatch(
                    loginWithCredentials(username.toLowerCase(), password)
                ).catch(() => {
                    form.setFields({
                        password: {
                            value: values.password,
                            errors: [
                                new Error(
                                    formatMessage(
                                        Strings.authText.main.notAuthorised
                                    )
                                )
                            ]
                        }
                    });
                });
            }
        });
    };

    return (
        <LoginWrapper title={titleLoginEmailPage}>
            <SimpleForm
                onSubmit={onSubmit}
                render={(form: any) => {
                    const { getFieldDecorator } = form;
                    return (
                        <div>
                            <FormItem>
                                {getFieldDecorator('username', {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: formatMessage(
                                                Strings.form.general.validEmail
                                            )
                                        },
                                        {
                                            required: true,
                                            message: formatMessage(
                                                Strings.form.general
                                                    .requiredEmail
                                            )
                                        }
                                    ]
                                })(
                                    <Input
                                        style={{ height: '40px', fontSize: 16 }}
                                        data-test-id={
                                            testSelectors.auth.userField
                                        }
                                        prefix={
                                            <Icon
                                                type="user"
                                                style={{ fontSize: 16 }}
                                            />
                                        }
                                        placeholder={formatMessage(
                                            Strings.labels.label.email
                                        )}
                                        tabIndex={1}
                                    />
                                )}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            required: true,
                                            message: formatMessage(
                                                Strings.form.general
                                                    .requiredPassword
                                            )
                                        }
                                    ]
                                })(
                                    <Input
                                        style={{ height: '40px', fontSize: 16 }}
                                        data-test-id={
                                            testSelectors.auth.passwordField
                                        }
                                        prefix={
                                            <Icon
                                                type="lock"
                                                style={{ fontSize: 16 }}
                                            />
                                        }
                                        type="password"
                                        placeholder={formatMessage(
                                            Strings.form.general
                                                .passwordPlaceholder
                                        )}
                                        tabIndex={2}
                                    />
                                )}
                            </FormItem>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <StyledLargeButton
                                    onClick={() => window.history.back()}
                                >
                                    <Icon type="arrow-left" />
                                    {formatMessage(
                                        Strings.navigation.general.back
                                    )}
                                </StyledLargeButton>
                                <LoginEmailButton />
                            </div>
                        </div>
                    );
                }}
            />
        </LoginWrapper>
    );
};
