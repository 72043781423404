import React, { Component } from 'react';
// @ts-ignore
import queryString from 'query-string';
import { connect } from 'react-redux';

import { CrossSpinner } from '../../../../../components/ui/loading/loading';
import { createSingleSignOnURL } from '../../../../../redux/domains/authentication/authentication.actions';
import { CREATE_SINGLE_SIGN_ON_URL } from '../../../../../redux/domains/authentication/authentication.constants';
import { createLoadingSelector } from '../../../../../redux/domains/requests/requests.selectors';
import { History, LocationState } from 'history';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';

type Props = {
    createSingleSignOnURL: any;
    history: History<LocationState>;
    loading: boolean;
};

class Launch extends Component<Props> {
    componentDidMount() {
        const { createSingleSignOnURL } = this.props;
        const params = queryString.parse(this.props.history.location.search);
        const { iss, launch } = params;

        const decoded = decodeURI(iss);

        if (decoded && launch) {
            createSingleSignOnURL({ iss: decoded, launch });
        }
    }

    render() {
        const { loading } = this.props;

        if (!loading) {
            return (
                <div>
                    <span>
                        <strong>
                            {formatMessage(Strings.authText.main.resolved)}
                        </strong>
                    </span>
                </div>
            );
        }

        return (
            <div>
                <CrossSpinner position="left" display="inline-block" />
                <span style={{ marginLeft: 10 }}>
                    <strong>
                        {formatMessage(Strings.authText.main.resolving)}
                    </strong>
                </span>
            </div>
        );
    }
}

const loadingSelector = createLoadingSelector([CREATE_SINGLE_SIGN_ON_URL]);

function mapStateToProps(state: any) {
    return {
        loading: loadingSelector(state)
    };
}

export default connect(mapStateToProps, { createSingleSignOnURL })(Launch);
