import { getLanguageWithoutRegionCode } from '../get-language-without-region-code';
import { createIntlFromLocale } from './create-intl-from-locale';

const getIntl = () => {
    const locale = getLanguageWithoutRegionCode();
    return createIntlFromLocale(locale);
};

const intl = getIntl();

export { intl };
