import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPatientMetaApi } from './patient-meta.api';
import {
    RESET_PATIENT_META,
    FETCH_PATIENT_META
} from './patient-meta.constants';
import { initialState } from './patient-meta.initial-state';

export const fetchPatientMeta = createAsyncThunk(
    FETCH_PATIENT_META,
    (ssn: string) => fetchPatientMetaApi(ssn)
);

export const resetPatientMeta = createAsyncThunk(
    RESET_PATIENT_META,
    () => initialState
);
