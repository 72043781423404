import { defineMessages } from 'react-intl';

const charts = defineMessages({
    title: {
        id: 'shared.ui.tab.charts',
        defaultMessage: 'Charts'
    }
});

const history = defineMessages({
    title: {
        id: 'shared.ui.tab.history',
        defaultMessage: 'History'
    }
});

const limits = defineMessages({
    title: {
        id: 'shared.ui.tab.limits',
        defaultMessage: 'Limits'
    }
});

export const tab = {
    charts,
    history,
    limits
};
