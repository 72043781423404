import React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
    color: lightgray;
    font-size: 16px;
`;

export const StyledArrow = () => <StyledIcon type="right" />;
