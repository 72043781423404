import React from 'react';
import { AppNeutralButton } from '../../../../components/ui/buttons/AppNeutralButton';
import { AppPrimaryButton } from '../../../../components/ui/buttons/AppPrimaryButton';
import { formatMessage } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';
import {
    StyledTableRow,
    StyledTableCell,
    StyledTableCellContent
} from '../styled';

export const CreateFormActions = (props: {
    onBack: () => void;
    isFormValid: boolean;
}) => {
    const { onBack, isFormValid } = props;

    return (
        <StyledTableRow>
            <StyledTableCell>
                <StyledTableCellContent>
                    <AppNeutralButton onClick={onBack}>
                        {formatMessage(Strings.navigation.general.back)}
                    </AppNeutralButton>
                </StyledTableCellContent>
            </StyledTableCell>
            <StyledTableCell>
                <StyledTableCellContent
                    style={{
                        textAlign: 'right',
                        marginLeft: '152px'
                    }}
                >
                    <AppPrimaryButton htmlType="submit" disabled={!isFormValid}>
                        {formatMessage(Strings.ui.buttons.general.next)}
                    </AppPrimaryButton>
                </StyledTableCellContent>
            </StyledTableCell>
        </StyledTableRow>
    );
};
