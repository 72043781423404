import React, { createRef, useState } from 'react';
import { message, Modal } from 'antd';
import { ResetStatus } from './ResetStatus';
import { WrappedResetForm } from './ResetForm';
import { reset } from './message-descriptors';
import { formatMessage } from '../../../../../../../../locale/format/format-message';
import { FormComponentProps } from 'antd/lib/form/Form';
import { setActionPanelLatest } from '../../../../../../../../redux/domains/action-panel/action-panel.actions';
import { useDispatch } from 'react-redux';
import { LatestResetType } from './latest-reset.type';
import { errorMessages } from '../../../../../../../../locale/messagesDescriptors/errors';

export const ResetPanelItem = (props: {
    latestDateForReset: number;
    resetPriority: (params: { reason: string; note: string }) => Promise<any>;
    latestReset: LatestResetType;
    reset_reasons: string[];
    disabled: boolean;
}): JSX.Element => {
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const formRef = createRef<FormComponentProps>();

    return showForm ? (
        <WrappedResetForm
            wrappedComponentRef={formRef}
            loading={loading}
            toggleResetForm={() => setShowForm(!showForm)}
            reset_reasons={props.reset_reasons}
            onSubmit={handleSubmitForm}
        />
    ) : (
        <ResetStatus
            latestDateForReset={props.latestDateForReset}
            latestReset={props.latestReset}
            toggleResetForm={() => setShowForm(!showForm)}
            disabled={props.disabled}
        />
    );

    function handleSubmitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        formRef.current?.form.validateFields(
            (errors: any, values: { reason: string; note: string }) => {
                if (!errors) {
                    const hideMessage = message.loading(
                        formatMessage(reset.general.registerReset),
                        0
                    );
                    setLoading(true);
                    const { reason, note } = values;

                    props
                        .resetPriority({ reason, note })
                        .then(hideMessage)
                        .then(() => {
                            message.success(
                                formatMessage(reset.general.registeredReset),
                                2
                            );
                            setLoading(false);
                            setShowForm(!showForm);
                            dispatch(
                                setActionPanelLatest({
                                    latestDateForReset: null,
                                    latestDateForWatchover: null
                                })
                            );
                        })
                        .catch(() => {
                            Modal.error({
                                content: formatMessage(
                                    errorMessages.general.message
                                )
                            });
                            setLoading(false);
                            message.destroy();
                        });
                }
            }
        );
    }
};
