import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    SET_SELECTED_DATE,
    SHARED_CHART_RESET
} from './chart.shared.constants';
import { sharedGetInitialState } from './chart.shared.state';
import { SharedState } from './chart.shared.types';

export const sharedReset = createAsyncThunk(
    SHARED_CHART_RESET,
    (): SharedState => sharedGetInitialState()
);

export const setSelectedDate = createAsyncThunk(
    SET_SELECTED_DATE,
    (date: number | null): null | number => date
);
