import React, { useState } from 'react';
import { IndicatorConfigurationState } from '../../../../../../../../redux/domains/indicators/indicator.type';
import { ActivePatient } from '../../../../../../../../redux/domains/active-patient/active-patient.type';
import UpdateConfigurationForm from '../../forms/UpdateConfigurationForm';
import ConfirmIndicatorConfigurationModal from './ConfirmIndicatorConfigurationModal';
import Switch from './Switch';

type Props = {
    patient: ActivePatient;
    indicatorConfiguration: IndicatorConfigurationState;
    indicator: any;
    indicatorSettings: any;
    conditionId: string;
};

const ToggleIndicator = (props: Props) => {
    const {
        patient,
        indicatorConfiguration,
        indicator,
        indicatorSettings,
        conditionId
    } = props;
    const [showConfirmLimitsModal, setShowConfirmLimitsModal] = useState(false);

    return (
        <UpdateConfigurationForm
            configuration={indicatorConfiguration}
            patient={patient}
            name="toggle-indicator-form"
            conditionId={conditionId}
        >
            <Switch
                disabled={indicatorSettings.disableToggle}
                setShowConfirmLimitsModal={setShowConfirmLimitsModal}
                shouldShowConfirmLimitsModal={indicatorSettings.allowEditLimits}
            />
            <ConfirmIndicatorConfigurationModal
                showConfirmLimitsModal={showConfirmLimitsModal}
                setShowConfirmLimitsModal={setShowConfirmLimitsModal}
                indicator={indicator}
            />
        </UpdateConfigurationForm>
    );
};

export default ToggleIndicator;
