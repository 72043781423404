import { defineMessages } from 'react-intl';

const general = defineMessages({
    ok: {
        id: 'app.ui.buttons.ok.label',
        defaultMessage: 'Ok',
        description: 'ok button'
    },
    save: {
        id: 'app.ui.buttons.save',
        defaultMessage: 'Save'
    },
    next: {
        id: 'app.ui.buttons.next',
        defaultMessage: 'Next'
    },
    cancel: {
        id: 'app.ui.buttons.cancel',
        defaultMessage: 'Cancel'
    },
    yes: {
        id: 'app.ui.buttons.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'app.ui.buttons.no',
        defaultMessage: 'No'
    },
    true: {
        id: 'app.ui.buttons.true',
        defaultMessage: 'Yes'
    },
    false: {
        id: 'app.ui.buttons.false',
        defaultMessage: 'No'
    },
    remove: {
        id: 'app.ui.buttons.remove',
        defaultMessage: 'Remove'
    },
    areYouSure: {
        id: 'app.ui.buttons.are-you-sure',
        defaultMessage: 'Are you sure you want to delete the value?'
    },
    submitted: {
        id: 'app.ui.buttons.submitted',
        defaultMessage: 'Submitted'
    },
    submit: {
        id: 'app.ui.buttons.submit',
        defaultMessage: 'Submit'
    },
    forgotPassword: {
        id: 'app.ui.buttons.forgot-password',
        defaultMessage: 'Forgot password?'
    },
    signInShort: {
        id: 'app.ui.buttons.login.short',
        defaultMessage: 'Sign in'
    },
    noHistory: {
        id: 'app.ui.buttons.no-history',
        defaultMessage: 'No history'
    },
    lphfDownloadTitle: {
        id: 'app.patient.screen.manual.button.lphf.download.title',
        defaultMessage: 'Manual'
    },
    copdDownloadTitle: {
        id: 'app.patient.screen.manual.button.copd.download.title',
        defaultMessage: 'Manual'
    },
    send: {
        id: 'shared.ui.button.send',
        defaultMessage: 'Send'
    },
    refreshPriorityTitle: {
        id: 'shared.ui.button.priorityList.refreshButton',
        defaultMessage: 'Refresh priority list'
    },
    createPatient: {
        id: 'shared.ui.button.createPatient',
        defaultMessage: 'Register patient'
    },
    edit: {
        id: 'shared.ui.button.edit',
        defaultMessage: 'Edit'
    },
    viewMore: {
        id: 'shared.ui.button.viewMore',
        defaultMessage: 'View more'
    },
    clear: {
        id: 'shared.ui.button.clear',
        defaultMessage: 'Clear'
    },
    all: {
        id: 'shared.ui.button.all',
        defaultMessage: 'All'
    },
    details: {
        id: 'shared.ui.button.details',
        defaultMessage: 'Details'
    }
});

export const buttons = {
    general
};
