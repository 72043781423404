import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from '../../../shared-indicators/medication/images/medication.svg';
import { rules } from './medication-hs-rules';
import { messagesMedicationHs } from './messages/messages';

export const parameterMedicationHs = {
    code: 'MEDICATION-HS'
};

export const medication: Indicator = {
    code: parameterMedicationHs.code,
    icon,
    messages: messagesMedicationHs,
    rules
};
