import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Pump pulse index',
        id: 'indicator.pump-pulse-index-lvad.title'
    },
    value: {
        defaultMessage: '{value, number, ::.0}',
        id: 'indicator.pump-pulse-index-lvad.value'
    }
});

export const messagesPumpPulseIndexLvad = {
    general
};
