export enum FieldNamesBasic {
    firstName = 'firstName',
    lastName = 'lastName',
    phoneNumber = 'phoneNumber',
    email = 'email',
    password = 'password',
    medicTitle = 'medicTitle',
    ssn = 'ssn'
}

enum FieldNamesSiths {
    sithsNumber = 'sithsNumber'
}

export const SithsFieldNames = {
    ...FieldNamesBasic,
    ...FieldNamesSiths
};
