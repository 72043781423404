import { createSelector } from 'reselect';
import { getSelectedPatientGroupId } from '../groups/groups.selectors';
import { getOverviewFilter } from '../ui/ui.selectors';
import { getWatchoversForPatientGroup } from '../watchovers/watchovers.selectors';
import { toLower, sortBy } from 'lodash';
import { Notifications } from '../notifications/notifications.type';
import { ActionPanelStateType } from '../action-panel/action-panel.type';
import { ReduxState } from '../../redux-state.type';
import { PriorityPatient, Watchover } from './priority.type';

type Filter = {
    searchTerm: string;
    newMessages: boolean;
};

/* Patients */
const getPatientsByGroupId = (state: ReduxState, groupId: string) =>
    state.priority.byGroupId[groupId];

const getPatientsBySelectedGroupId = (state: ReduxState) =>
    getPatientsByGroupId(state, getSelectedPatientGroupId(state));

export const getIsPriorityListLoaded = createSelector(
    getPatientsBySelectedGroupId,
    (patients) => patients != null
);

const addWatchover = (patient: PriorityPatient, watchover?: Watchover) =>
    patient.watchover === watchover ? patient : { ...patient, watchover };

const getSortedPatientsWithWatchovers = createSelector(
    [getPatientsBySelectedGroupId, getWatchoversForPatientGroup],
    (patientsById, watchovers) =>
        sortBy(patientsById, 'order').map((patient) =>
            addWatchover(patient, watchovers[patient.meta.externalId])
        )
);

const getNotifications = (state: ReduxState) => state.notifications;

const filterPatientsBySearchterm = (
    searchterm: string,
    patients: PriorityPatient[]
) => {
    if (!searchterm) return patients;

    return patients.filter((patient) => {
        const {
            meta: { personal_id = '' }
        } = patient;
        let {
            meta: { name = '', family_name = '' }
        } = patient;

        name = toLower(name);
        family_name = toLower(family_name);
        searchterm = toLower(searchterm);

        return (
            name.includes(searchterm) ||
            family_name.includes(searchterm) ||
            personal_id.replace('-', '').includes(searchterm.replace('-', ''))
        );
    });
};

const filterPatientsByNewMessage = (
    notifications: Notifications,
    patients: PriorityPatient[],
    actionPanelState?: ActionPanelStateType
) =>
    patients.filter((patient) => {
        const patientId = patient.meta.externalId;
        const patientNotifications = notifications.bySubjectId[patientId];

        if (
            actionPanelState?.isOpen &&
            actionPanelState.patientExternalId === patientId
        ) {
            return true;
        }

        if (!patientNotifications || !patientNotifications.allIds) return false;

        return patientNotifications.allIds.some(
            (id: string) =>
                patientNotifications.byId[id].notificationType === 'message' &&
                patientNotifications.byId[id].tags.includes('sent')
        );
    });

function getFilteredPatients(
    patients: PriorityPatient[],
    notifications: Notifications,
    filter: Filter,
    actionPanel?: ActionPanelStateType
) {
    const searchedPatients = filterPatientsBySearchterm(
        filter.searchTerm,
        patients
    );

    if (filter.newMessages) {
        return filterPatientsByNewMessage(
            notifications,
            searchedPatients,
            actionPanel
        );
    }

    return searchedPatients;
}

export const getFilteredPriorityPatients = createSelector(
    [
        getSortedPatientsWithWatchovers,
        getNotifications,
        getOverviewFilter,
        (state: ReduxState) => state.actionPanel
    ],
    (sortedPatients, notifications, filter, actionPanel) =>
        getFilteredPatients(sortedPatients, notifications, filter, actionPanel)
);

/* Used only for displaying search results as a badge in group tabs at overview page */
export const makeGetFilterResultsCount = () =>
    createSelector(
        [
            getPatientsByGroupId,
            getNotifications,
            getOverviewFilter,
            (state: ReduxState, groupId: string) =>
                getSelectedPatientGroupId(state) === groupId
                    ? getFilteredPriorityPatients(state)
                    : null
        ],
        (patientsById, notifications, filter, filteredPatients) => {
            if (!filter.searchTerm && !filter.newMessages) return null;

            if (!filteredPatients && patientsById) {
                filteredPatients = getFilteredPatients(
                    Object.values(patientsById),
                    notifications,
                    filter
                );
            }

            return filteredPatients?.length;
        }
    );

/* Last updated */
export const getLastUpdated = (state: ReduxState, props: { groupId: string }) =>
    state.priority.lastUpdated[props.groupId];
