import { IndicatorWithSettings } from '../../../../../../redux/domains/indicators/indicator.type';
import { DataPoint } from '../../../chart-types';
import moment from 'moment';
import { Duration, DurationValues, HeatmapLeftSpace } from '../../../duration';
import { useTheme } from '../../../../../../hooks/use-theme';
import React from 'react';
import { HeatmapPiece } from '../../../../../../types/indicator/heatmap-piece.type';
import { isHeatmapRules } from '../../../../../../redux/domains/settings/settings.type';

export function useEchartOptions(props: {
    duration: Duration;
    indicator: IndicatorWithSettings;
    values: DataPoint[];
}): Options {
    const duration = DurationValues[props.duration];

    const startDate = moment().subtract(duration).format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');
    const theme = useTheme();
    const heatmapPieces =
        isHeatmapRules(props.indicator.settings.rules) &&
        props.indicator.settings.rules.heatmapPieces;

    return {
        visualMap: {
            pieces: heatmapPieces as HeatmapPiece<number, number>[],
            show: false,
            dimension: 1
        },
        series: [
            {
                type: 'heatmap',
                coordinateSystem: 'calendar',
                data: props.values,
                name: props.indicator.code
            }
        ],
        calendar: [
            {
                range: [startDate, endDate],
                cellSize: [15, 15],
                dayLabel: {
                    firstDay: moment.localeData().firstDayOfWeek(),
                    nameMap: moment
                        .localeData()
                        .weekdaysMin()
                        .map((day) => day.charAt(0))
                },
                monthLabel: {
                    nameMap: moment.localeData().monthsShort()
                },
                yearLabel: {
                    show: false,
                    margin: 0
                },
                itemStyle: {
                    borderColor: theme.profile.base.extraBright,
                    color: theme.profile.base.brightBackground
                },
                top: 50,
                right: 10,
                left: HeatmapLeftSpace[props.duration],
                bottom: 10,
                splitLine: {
                    lineStyle: {
                        width: 2,
                        color: theme.profile.base.extraBright
                    }
                },
                silent: true
            }
        ]
    };
}

type CalendarOption = {
    range: [string, string];
    cellSize: [number, number];
    dayLabel: {
        firstDay: number;
        nameMap: string[];
    };
    monthLabel: {
        nameMap: string[];
    };
    yearLabel: {
        show: boolean;
        margin: number;
    };
    itemStyle: React.CSSProperties;
    top: number;
    right: number;
    left: number;
    bottom: number;
    splitLine: {
        lineStyle: React.CSSProperties;
    };
    silent: boolean;
};

type SeriesOption = {
    type: 'heatmap';
    coordinateSystem: 'calendar';
    data: DataPoint[];
    name: string;
};

type VisualMap = {
    pieces: HeatmapPiece<number, number>[];
    show: boolean;
    dimension: number;
};

type Options = {
    visualMap: VisualMap;
    series: [SeriesOption];
    calendar: [CalendarOption];
};
