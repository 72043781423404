import React from 'react';
import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import { IndicatorCode } from '../../../../types/indicator/indicator.type';
import { SinglePropertySplineSeries } from './SingleValueSplineSeries';
import { mapSingleValueIndicatorData } from './utils/single-map-single-value-indicator-data';
import { parseSingleValueIndicator } from './utils/single-single-value-indicator';
import {
    GraphValuesSorted,
    IndicatorValue
} from '../../../../types/indicator/indicator-value.type';
import { IndicatorGraphSettings } from '../../../../redux/domains/chart/graph/chart.graph.types';
import { IndicatorSettingsMapping } from '../../../../conditions/condition.type';

export function SingleIndicators(props: {
    indicator: IndicatorWithSettings;
    sortedGraphValuesByDate: Record<
        IndicatorCode,
        GraphValuesSorted<IndicatorValue>[]
    >;
    chartIndicators: Record<IndicatorCode, IndicatorWithSettings>;
    settingsMapping: IndicatorSettingsMapping;
    activeGraph: IndicatorGraphSettings | null;
    sharedTooltips: (value: boolean) => void;
}) {
    const indicator = props.indicator;
    const sortedGraphValuesByDate = props.sortedGraphValuesByDate;
    const chartIndicators = props.chartIndicators;
    const settingsMapping = props.settingsMapping;
    const activeGraph = props.activeGraph;
    const singleHiddenCode = activeGraph?.code;
    const sharedTooltips = props.sharedTooltips;

    if (Object.keys(sortedGraphValuesByDate).length === 0) {
        return null;
    }
    const code = indicator.code;

    const mapped = mapSingleValueIndicatorData(
        sortedGraphValuesByDate[code],
        chartIndicators[code],
        settingsMapping,
        singleHiddenCode || null
    );

    const parsed = parseSingleValueIndicator(code, mapped);

    return (
        <SinglePropertySplineSeries
            key={code}
            chartIndicator={chartIndicators[code]}
            data={parsed}
            settingsMapping={settingsMapping}
            activeGraph={activeGraph}
            sharedTooltips={sharedTooltips}
        />
    );
}
