import { Indicator } from '../../../types/indicator/indicator.type';

import icon from './images/pain.svg';
import { messagesPainCi } from './messages/messages';
import { PainCIProperties } from './pain-ci-properties';
import { rules } from './pain-ci-rules';

export const parameterPainCi = {
    code: 'PAIN-CI',
    properties: PainCIProperties
};

export const pain: Indicator = {
    code: parameterPainCi.code,
    icon,
    messages: messagesPainCi,
    rules
};
