import { defineMessages } from 'react-intl';

const video01 = defineMessages({
    title: {
        id: 'education.video-01.title',
        defaultMessage: 'Film 1 (2 min 39 s)'
    },
    desc: {
        id: 'education.video-01.desc',
        defaultMessage:
            '- Hitta LifePod\n' +
            '- Logga in i LifePod\n' +
            '- Skapa en länk eller ett bokmärke till LifePod \n' +
            '- Logga ut ur LifePod'
    }
});

const video02 = defineMessages({
    title: {
        id: 'education.video-02.title',
        defaultMessage: 'Film 2 (6 min 39 s)'
    },
    desc: {
        id: 'education.video-02.desc',
        defaultMessage:
            '- Registrera ny patient i LifePod\n' +
            '   - Information om patienten\n' +
            '   - Initiala värden\n' +
            '   - Parametrar, gränser och påminnelser  \n\n\n' +
            '- Välkomst-sms med länken till LifePod skickas automatiskt till patienten om det finns ett mobilnummer\n\n' +
            '- Välkomst-mail med länken och en patientmanual skickas automatiskt till patienten om det finns en mailadress'
    }
});

const video03 = defineMessages({
    title: {
        id: 'education.video-03.title',
        defaultMessage: 'Film 3 (2 min 53 s)'
    },
    desc: {
        id: 'education.video-03.desc',
        defaultMessage:
            '- Prioriteringsöversikten  \n' +
            '   - Olika färger\n' +
            '   - Trendvyer\n' +
            '   - Utropstecken\n' +
            '   - Sökning'
    }
});

const video04 = defineMessages({
    title: {
        id: 'education.video-04.title',
        defaultMessage: 'Film 4 (4 min 13 s)'
    },
    desc: {
        id: 'education.video-04.desc',
        defaultMessage:
            '- Åtgärdspanelen  \n' +
            '   - Meddelanden\n' +
            '   - Granskning\n' +
            '   - Nollställning\n' +
            '   - Uppmuntran'
    }
});

const video05 = defineMessages({
    title: {
        id: 'education.video-05.title',
        defaultMessage: 'Film 5 (2 min 38 s)'
    },
    desc: {
        id: 'education.video-05.desc',
        defaultMessage:
            '- Inaktivera och aktivera patient  \n' +
            '   - Välja anledning\n' +
            '   - Bekräfta gränser'
    }
});

const video06 = defineMessages({
    title: {
        id: 'education.video-06.title',
        defaultMessage: 'Film 6 (5 min 39 s)'
    },
    desc: {
        id: 'education.video-06.desc',
        defaultMessage:
            '- Detaljerad patientinfo  \n' +
            '   - Grafer (zooma, tända/släcka kurvor, visa gränser)\n' +
            '   - Datumdiagram (hoovra över värde, visa legend)\n' +
            '   - Historik (se alla värden i formulär, söka specifika värden)\n' +
            '   - Gränser\n' +
            '   - Påminnelser'
    }
});

const video07 = defineMessages({
    title: {
        id: 'education.video-07.title',
        defaultMessage: 'Film 7 (6 min 27 s)'
    },
    desc: {
        id: 'education.video-07.desc',
        defaultMessage:
            '- Sök patient i aktuell grupp\n' +
            '- Sök patient i alla mina grupper\n' +
            '- Sök patient utanför mina grupper\n' +
            '- Se och uppdatera mina medarbetare\n' +
            '- Exportera data\n' +
            '- Byta grupp för patient\n' +
            '- Menyn uppe till höger \n' +
            '   - Min Profil\n' +
            '   - Viktig information\n' +
            '   - Utbildningsmodul\n' +
            '   - Om LifePod\n' +
            '   - Logga ut'
    }
});

const module = defineMessages({
    title: {
        id: 'instruction.education.module.title',
        defaultMessage: 'Video'
    }
});

export const videos = {
    module,
    introduction: {
        video01,
        video02,
        video03,
        video04,
        video05,
        video06,
        video07
    }
};
