import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts';
import HeatmapAvatar from './HeatmapAvatar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/calendar';
import 'echarts/lib/chart/heatmap';
import './heatmap.css';
import { getOption } from './getOptions';

import {
    IndicatorConfigurationState,
    IndicatorWithSettings
} from '../../../redux/domains/indicators/indicator.type';
import { Values } from './heatmap.type';
import {
    isHeatmapRules,
    Settings
} from '../../../redux/domains/settings/settings.type';
import { Tooltip } from 'antd';
import { formatMessageById } from '../../../locale/format/format-message';
import { useDispatch } from 'react-redux';
import { setSelectedDate } from '../../../redux/domains/chart/shared/chart.shared.actions';
import { useReduxSelector } from '../../../hooks/use-redux-selector';

export const EchartHeatmap = (props: {
    indicator: IndicatorWithSettings;
    values: Values;
    dateRange: { from: number; to: number };
    indicatorSettings: Settings;
    indicatorConfiguration: IndicatorConfigurationState;
}) => {
    const dispatch = useDispatch();
    const { indicatorSettings, indicator } = props;
    const heatmapLegendItems =
        isHeatmapRules(indicatorSettings.rules) &&
        indicatorSettings.rules.heatmapLegendItems;

    const selectedDate = useReduxSelector(
        (state) => state.charts.shared.selectedDate
    );

    return (
        <div className="heatmap-container">
            <HeatmapAvatar code={indicator.code} />

            <div className="heatmap-wrapper">
                <div className="legend">
                    {heatmapLegendItems &&
                        heatmapLegendItems.map((item, idx: number) => {
                            return (
                                <Tooltip
                                    key={idx}
                                    title={formatMessageById(`${item.textId}`, {
                                        ...item.textValues
                                    })}
                                >
                                    <svg width="14" height="14">
                                        <rect
                                            width="14"
                                            height="14"
                                            style={{ fill: item.color }}
                                        />
                                    </svg>
                                </Tooltip>
                            );
                        })}
                </div>
                <ReactEchartsCore
                    echarts={echarts}
                    option={getOption({ ...props, selectedDate })}
                    opts={{ renderer: 'svg' }}
                    style={{ width: '100%', height: 175 }}
                    lazyUpdate={true}
                    onEvents={{
                        click: (event: EchartsOnEvent<number>) => {
                            const currentDateInMillisec = event.data[0];
                            dispatch(setSelectedDate(currentDateInMillisec));
                        }
                    }}
                />
            </div>
        </div>
    );
};

type EchartsOnEvent<T> = Event & {
    // 2022-10-21 Type is copied from Echarts webpage (https://echarts.apache.org/en/api.html#events)
    componentType: string;
    seriesType: string;
    seriesIndex: number;
    seriesName: string;
    name: string;
    dataIndex: number;
    data: Record<string, T>;
    dataType: string;
    value: number | number[];
    color: string;
    info: any;
};
