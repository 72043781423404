import { defineMessages } from 'react-intl';

const general = defineMessages({
    description: {
        id: 'reset-priority.general.description',
        defaultMessage: 'To reset a patient you have to provide a reason'
    },
    required: {
        defaultMessage: 'A reason is required',
        id: 'reset-priority.general.required'
    }
});

export const resetPriority = { general };
