import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/liquid-measurement.svg';

import { messagesLiquidMeasurement } from './messages/messages';
import { rules } from './liquid-measurement-rules';

export const parameterLiquidMeasurement = {
    code: 'LIQUID-MEASUREMENT'
};

export const liquidMeasurement: Indicator = {
    code: parameterLiquidMeasurement.code,
    icon,
    messages: messagesLiquidMeasurement,
    rules
};
