import React, { useEffect, useRef } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PatientGroups } from '../../../redux/domains/groups/groups.type';
import { ContentCardLayout } from '../../../components/content/ContentLayout';
import { CrossSpinner } from '../../../components/ui/loading/loading';
import { usePageTitle } from '../../../hooks/use-page-title';
import { useInterval } from '../../../hooks/use-interval';
import { setSelectedCondition } from '../../../redux/domains/conditions/conditions.actions';
import {
    setSelectedMedicGroup,
    setSelectedPatientGroup
} from '../../../redux/domains/groups/groups.actions';
import {
    getPatientGroupsForCurrentSelectedMedicGroup,
    getSelectedMedicGroupId,
    getSelectedPatientGroupId
} from '../../../redux/domains/groups/groups.selectors';
import { fetchNotificationsBySelectedMedicId } from '../../../redux/domains/notifications/notifications.actions';
import {
    fetchPriorityList,
    filterPriorityListsBySearchTerm,
    filterPriorityListsByUnreadMessages,
    resetFilter
} from '../../../redux/domains/priority/priority.actions';
import { getIsPriorityListLoaded } from '../../../redux/domains/priority/priority.selectors';
import { fetchMissedReports } from '../../../redux/domains/reminders/reminders.actions';
import { fetchWatchoversForGroup } from '../../../redux/domains/watchovers/watchovers.actions';

import CustomTabTitle from './components/CustomTabTitle';
import './overview.css';
import PriorityTable from './components/priority-table/PriorityTable';
import { titlePatientOverview } from './title';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { getPatient } from '../../../redux/domains/patient-search/patient-search.api';
import { findMedicGroup } from './find-medic-group';
import { setSelectedPrioPatient } from '../../../redux/domains/app-state/app-state.actions';

enum Behavior {
    smooth = 'smooth'
}

export const PatientOverviewPage = (): JSX.Element => {
    usePageTitle(titlePatientOverview);

    const dispatch = useDispatch();
    const hasScrolled = useRef(false);

    const isPriorityListLoaded = useSelector(getIsPriorityListLoaded);

    const selectedPatientGroupId = useSelector(getSelectedPatientGroupId);
    const selectedMedicGroupId = useSelector(getSelectedMedicGroupId);

    const groups = useSelector(getPatientGroupsForCurrentSelectedMedicGroup);
    const conditionId =
        selectedPatientGroupId &&
        groups.byId[selectedPatientGroupId]?.condition;

    const selectedPrioPatient = useReduxSelector(
        (state) => state.appState.selectedPrioPatient
    );

    const allPatientGroups = useReduxSelector(
        (state) => state.groups.patientGroups.byId
    );

    useEffect(() => {
        if (selectedPrioPatient) {
            void getPrioPatientData(selectedPrioPatient).then((data) => {
                if (data.length) {
                    const user = data[0];

                    const groupIds = user.groupIds;

                    if (groupIds.length) {
                        const prioPatientGroup = groupIds[0];

                        const findPrioMedicGroup = findMedicGroup(
                            allPatientGroups,
                            prioPatientGroup
                        );

                        if (prioPatientGroup !== selectedPatientGroupId) {
                            dispatch(setSelectedMedicGroup(findPrioMedicGroup));

                            if (prioPatientGroup) {
                                dispatch(
                                    setSelectedPatientGroup(prioPatientGroup)
                                );
                            }
                        }
                    }
                }
            });
        }
        return () => {
            if (hasScrolled.current && selectedPrioPatient) {
                dispatch(setSelectedPrioPatient(''));
            }
        };
    }, [selectedPrioPatient]);

    useInterval(() => {
        dispatch(fetchNotificationsBySelectedMedicId());
        dispatch(fetchWatchoversForGroup());
    }, 60000);

    useEffect(() => {
        return () => {
            dispatch(resetFilter());
        };
    }, [dispatch]);

    useEffect(() => {
        if (conditionId) {
            dispatch(setSelectedCondition(conditionId));
            dispatch(fetchMissedReports(selectedPatientGroupId, conditionId));
        }
    }, [conditionId, dispatch, selectedPatientGroupId]);

    useEffect(() => {
        if (selectedPatientGroupId) {
            dispatch(fetchPriorityList());
            dispatch(fetchWatchoversForGroup());
        }
    }, [dispatch, selectedPatientGroupId]);

    useEffect(() => {
        if (selectedMedicGroupId) {
            dispatch(fetchNotificationsBySelectedMedicId());
        }

        return () => {
            if (hasScrolled.current) {
                dispatch(setSelectedPrioPatient(''));
            }
        };
    }, [dispatch, selectedMedicGroupId]);

    useEffect(() => {
        if (selectedPrioPatient && isPriorityListLoaded === true) {
            void scrollToPatientInPrio(selectedPrioPatient);
        }
    }, [isPriorityListLoaded, selectedPrioPatient]);

    return !isPriorityListLoaded ? (
        <CrossSpinner
            card={true}
            text={formatMessage(Strings.events.general.fetchingInformation)}
        />
    ) : (
        <ContentCardLayout>
            <Tabs
                type="card"
                onChange={(groupId: string) => {
                    onTabChange(groupId);
                    dispatch(setSelectedPrioPatient(''));
                }}
                defaultActiveKey={selectedPatientGroupId}
            >
                {getTabItems(groups).map((pane) => (
                    <Tabs.TabPane
                        key={pane.groupId}
                        tab={
                            <CustomTabTitle
                                title={pane.title}
                                groupId={pane.groupId}
                            />
                        }
                    >
                        <PriorityTable
                            conditionId={pane.conditionId}
                            groupId={pane.groupId}
                            ssn={selectedPrioPatient}
                            unreadMessagesFilter={(newMessages: boolean) =>
                                dispatch(
                                    filterPriorityListsByUnreadMessages(
                                        newMessages
                                    )
                                )
                            }
                            searchFilter={(searchTerm: string) =>
                                dispatch(
                                    filterPriorityListsBySearchTerm(searchTerm)
                                )
                            }
                        />
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </ContentCardLayout>
    );

    function onTabChange(newPatientGroupId: string) {
        dispatch(setSelectedPatientGroup(newPatientGroupId));
    }

    async function scrollToPatientInPrio(ssn: string) {
        const selectedPatientData = await getPatient(ssn);
        const selectedPatientId = selectedPatientData.users[0].externalId;

        const selectedColumn = document.querySelectorAll(
            `[data-test-id='${selectedPatientId}']`
        )[0];

        if (selectedColumn) {
            const offset = 300;
            const elementPosition = selectedColumn.getBoundingClientRect().top;
            const offsetPosition = elementPosition - offset;
            const tableWrap =
                document.getElementsByClassName('ant-table-body')[0];

            tableWrap.scrollTo({
                top: offsetPosition,
                behavior: Behavior.smooth
            });

            hasScrolled.current = true;
        }
    }
};

type PaneItem = { title: string; groupId: string; conditionId: string };

function getTabItems(groups: PatientGroups): PaneItem[] {
    return groups.allIds.map((groupId) => {
        const { name, id, condition } = groups.byId[groupId];
        return { title: name, groupId: id, conditionId: condition };
    });
}

async function getPrioPatientData(ssn: string) {
    const data = await getPatient(ssn);
    return data.users;
}
