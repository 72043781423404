import {
    FETCH_PATIENT_FULFILLED,
    UPDATE_INDICATORCONFIG_FULFILLED,
    UPDATE_INDICATORCONFIG_PENDING
} from './active-patient.constants';
import { ActivePatient } from './active-patient.type';

export interface FetchPatientFulfilledAction {
    type: 'FETCH_PATIENT_FULFILLED';
    payload: ActivePatient;
}

export interface UpdateIndicatorConfigPendingAction {
    type: 'UPDATE_INDICATORCONFIG_PENDING';
    payload: any;
    meta: any;
}

export interface UpdateIndicatorConfigFulfilledAction {
    type: 'UPDATE_INDICATORCONFIG_FULFILLED';
    payload: any;
}

type PatientAction =
    | FetchPatientFulfilledAction
    | UpdateIndicatorConfigPendingAction
    | UpdateIndicatorConfigFulfilledAction;

export const initialState: ActivePatient = {
    externalId: '',
    meta: {
        email: '',
        family_name: '',
        name: '',
        personal_id: '',
        phone_number: '',
        username: ''
    },
    conditionConfiguration: {},
    conditionIds: [],
    groupIds: []
};

export default function patientReducer(
    state: ActivePatient = initialState,
    action: PatientAction | undefined = undefined
) {
    if (action === undefined) {
        return state;
    }

    switch (action.type) {
        case FETCH_PATIENT_FULFILLED: {
            const { payload } = action;
            return {
                ...initialState,
                ...payload
            };
        }
        case UPDATE_INDICATORCONFIG_PENDING:
            if (action.meta) {
                const { conditionId, indicatorId } = action.meta;

                const indicators = state.conditionConfiguration[conditionId];
                const {
                    // @ts-ignore
                    configuration: { in_use }
                } = indicators[indicatorId];

                return {
                    ...state,
                    conditionConfiguration: {
                        ...state.conditionConfiguration,
                        [conditionId]: {
                            ...indicators,
                            [indicatorId]: {
                                indicatorId,
                                configuration: {
                                    ...indicators[indicatorId].configuration,
                                    in_use: !in_use
                                },
                                loading: true
                            }
                        }
                    }
                };
            }
            return state;
        case UPDATE_INDICATORCONFIG_FULFILLED: {
            const { payload } = action;
            const { conditionId, indicatorId, configuration } = payload;
            const indicators = state.conditionConfiguration[conditionId];
            if (!indicators || !indicators[indicatorId]) return state;

            return {
                ...state,
                conditionConfiguration: {
                    ...state.conditionConfiguration,
                    [conditionId]: {
                        ...state.conditionConfiguration[conditionId],
                        [indicatorId]: {
                            indicatorId,
                            configuration: {
                                ...indicators[indicatorId].configuration,
                                ...configuration
                            }
                        }
                    }
                }
            };
        }
        default:
            return state;
    }
}
