import { intl } from '../setup/intl/get-intl';

type ReactIntlDateValue = string | number | Date;

export const formatDate = (date: ReactIntlDateValue): string => {
    if (date == null) {
        throw Error(`${formatDate.name}: date cannot be null or undefined`);
    }

    return intl.formatDate(date);
};
