import indicatorRules from '../../../utils/create-condition-settings/indicator-rules';
import { indicatorItem } from '../../../utils/create-condition-settings/items';
import { reasonsStatusChange } from '../../../utils/create-condition-settings/reasons-status-change';
import { ConditionRules } from '../../condition.type';
import { IndicatorMeta } from '../../indicator-meta';

export const createHypertension01ConditionConfiguration =
    (): ConditionRules => {
        return {
            indicatorSettings: {
                preventFromEditLimits: [
                    IndicatorMeta.VAS.code,
                    IndicatorMeta.SMOKE.code
                ],
                preventFromEditReminders: [],
                preventFromToggle: [IndicatorMeta.VAS.code]
            },
            initialObservationIndicators: [IndicatorMeta.BODY_WEIGHT.code],
            priorityTable: {
                hidden: [
                    IndicatorMeta.VAS.code,
                    IndicatorMeta.PHYSACT.code,
                    IndicatorMeta.SMOKE.code
                ],
                order: [
                    IndicatorMeta.BLOOD_PRESSURE.code,
                    IndicatorMeta.PULSE.code,
                    IndicatorMeta.DIZZINESS.code,
                    IndicatorMeta.BODY_WEIGHT.code,
                    IndicatorMeta.SWELLING.code,
                    IndicatorMeta.TIREDNESS.code,
                    IndicatorMeta.BREATHLESSNESS.code,
                    IndicatorMeta.PHYSACT.code,
                    IndicatorMeta.VAS.code,
                    IndicatorMeta.SMOKE.code
                ]
            },
            reasons: reasonsStatusChange(),
            indicators: {
                [IndicatorMeta.BLOOD_PRESSURE.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.BLOOD_PRESSURE.code]
                ),
                [IndicatorMeta.PULSE.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.PULSE.code]
                ),
                [IndicatorMeta.DIZZINESS.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.DIZZINESS.code]
                ),
                [IndicatorMeta.BODY_WEIGHT.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.BODY_WEIGHT.code]
                ),
                [IndicatorMeta.SWELLING.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.SWELLING.code]
                ),
                [IndicatorMeta.TIREDNESS.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.TIREDNESS.code]
                ),
                [IndicatorMeta.BREATHLESSNESS.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.BREATHLESSNESS.code]
                ),
                [IndicatorMeta.PHYSACT.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.PHYSACT.code]
                ),
                [IndicatorMeta.VAS.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.VAS.code]
                ),
                [IndicatorMeta.SMOKE.code]: indicatorItem(
                    indicatorRules[IndicatorMeta.SMOKE.code]
                )
            }
        };
    };
