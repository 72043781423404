import React, { useEffect } from 'react';
import { Icon, Modal } from 'antd';
import {
    fetchUnreadMessagesOfTheDay,
    markMessagesOfTheDayAsRead
} from '../../../redux/domains/motd/motd.actions';
import { useReduxDispatch } from '../../../hooks/use-redux-dispatch';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { AppPrimaryButton } from '../../ui/buttons/AppPrimaryButton';
import { hasMotds, noMotds } from './messageOfTheDayUtils';
import { buildMotdMessages } from './motdShared';
import { useInterval } from '../../../hooks/use-interval';
import { OnClick } from '../../../types/custom-types';
import { MarkdownFromDescriptor } from '../../../locale/format/format-markdown';
import { styled } from '../../../theme/styled';
import { Strings } from '../../../locale/messagesDescriptors';
import { formatMessage } from '../../../locale/format/format-message';

const buildTitle = () => {
    return (
        <IconWrapper>
            <StyledIcon type="mail" />
            {formatMessage(Strings.communication.general.messageOfTheDayTitle)}
        </IconWrapper>
    );
};

const buildFooter = (handleOk: OnClick) => {
    return (
        <StyledFooterContainer>
            <StyledOKMessage>
                <MarkdownFromDescriptor
                    messageDescriptor={
                        Strings.communication.general.messageOfTheDayOKInfoMD
                    }
                />
            </StyledOKMessage>
            <AppPrimaryButton
                size="large"
                onClick={handleOk}
                title={formatMessage(Strings.common.general.ok)}
            />
        </StyledFooterContainer>
    );
};

export const MessageOfTheDayModal = () => {
    const dispatch = useReduxDispatch();

    useEffect(() => {
        dispatch(fetchUnreadMessagesOfTheDay());
    }, [dispatch]);

    useInterval(() => {
        dispatch(fetchUnreadMessagesOfTheDay());
    }, 60000);

    const motds = useReduxSelector((state) => state.motd.messages);
    const visible = hasMotds(motds);

    if (noMotds(motds)) {
        return null;
    }

    const handleOk = async () => {
        try {
            await dispatch(markMessagesOfTheDayAsRead(motds)).unwrap();
        } catch (error) {
            console.warn(`Could not markAsRead: \n${error}`);
        }
    };

    return (
        <StyledModal
            title={buildTitle()}
            visible={visible}
            closable={false}
            footer={false}
        >
            {buildMotdMessages(motds)}
            {buildFooter(handleOk)}
        </StyledModal>
    );
};

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledIcon = styled(Icon)`
    margin-right: 10px;
`;

const StyledOKMessage = styled.div`
    width: 100%;
    padding-bottom: 0.5rem;
`;

const StyledFooterContainer = styled.div`
    display: block;
    text-align: center;
    padding: 25px 0;
    border-top: 1px solid #ccc;
    margin-top: auto;
`;

const StyledModal = styled(Modal)`
    height: 70%;

    @media (max-width: ${(props) =>
            props.theme.layout.mediaQuerySize.width.desktop}) {
        && {
            top: 0;
            padding: 0 !important;
            margin-top: 0px;
            margin-bottom: 0px;
            max-width: 100%;
            overflow: hidden;
            z-index: 2000;
            height: 100%;
            width: 100% !important;
            flex: 1;
            -webkit-user-select: none;
        }
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        border-radius: 0;
        border-top: 0;
        -webkit-overflow-scrolling: touch;
    }

    .ant-modal-header {
        position: relative;
    }

    .ant-modal-body {
        padding: 30px 30px 0 30px;
        background-color: ${(props) => props.theme.backgroundColor};
        display: block;
        position: relative;
        overflow: auto;
        height: 100%;
    }
`;
