import moment from 'moment';

export enum Duration {
    Week,
    OneMonth,
    TwoMonths
}

export const DurationValues: Record<Duration, moment.Duration> = {
    [Duration.Week]: moment.duration(14, 'days'),
    [Duration.OneMonth]: moment.duration(1, 'months'),
    [Duration.TwoMonths]: moment.duration(2, 'months')
};

export const GraphDurationSteps: Record<Duration, number> = {
    [Duration.Week]: 2,
    [Duration.OneMonth]: 4,
    [Duration.TwoMonths]: 8
};

export const HeatmapLeftSpace: Record<Duration, number> = {
    [Duration.Week]: 40,
    [Duration.OneMonth]: 30,
    [Duration.TwoMonths]: 20
};
