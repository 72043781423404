import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Appetite',
        id: 'indicator.appetite.title'
    }
});

const choices = defineMessages({
    betterThanUsual: {
        defaultMessage: 'Better appetite than usual',
        id: 'indicator.appetite.choices.1'
    },
    sameAsUsual: {
        defaultMessage: 'Same appetite as usual',
        id: 'indicator.appetite.choices.2'
    },
    worseThanUsual: {
        defaultMessage: 'Worse appetite than usual',
        id: 'indicator.appetite.choices.3'
    }
});

export const messagesAppetite = {
    general,
    choices
};
