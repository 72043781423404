import React from 'react';
import { formatMessage } from '../../../locale/format/format-message';
import { Strings } from '../../../locale/messagesDescriptors';
import { AppNeutralButton } from './AppNeutralButton';
import { Icon } from 'antd';
import { ButtonProps } from 'antd/lib/button';

export const BackButtonWithArrow = (props: ButtonProps) => {
    return (
        <AppNeutralButton {...props}>
            <Icon type="arrow-left" />
            {formatMessage(Strings.navigation.general.back)}
        </AppNeutralButton>
    );
};
