import { medicProfile } from './messages';
import { MedicExternalId } from '../../../redux/domains/user/user.type';
import { getMedicIdPath, pathProfile, pathProfileMedicId } from './path';
import { RouteItemType } from '../../../utils/route/switch-routes';
import { ProfilePage } from './ProfilePage';
import { formatMessage } from '../../../locale/format/format-message';

export const routeProfile: RouteItemType & {
    medicLink: (medicId: MedicExternalId) => string;
} = {
    name: formatMessage(medicProfile.general.title),
    link: pathProfile,
    links: [pathProfileMedicId, pathProfile],
    icon: '',
    medicLink: getMedicIdPath,
    component: ProfilePage
};
