import {
    PatientGroup,
    PatientGroups
} from '../../../../redux/domains/groups/groups.type';
import { PatientExternalId } from '../../../../redux/domains/user/user.type';

export function getPatientGroupId(
    groups: PatientGroups,
    patientId: PatientExternalId
): PatientGroup | undefined {
    const matchedPatientGroup = Object.values(groups.byId).find((group) =>
        group.users.includes(patientId)
    );

    if (matchedPatientGroup) {
        return matchedPatientGroup;
    }

    return undefined;
}
