import { Indicator } from '../../../types/indicator/indicator.type';
import { BloodPressureProperties } from './blood-pressure-properties';
import { rules } from './blood-pressure-rules';

import icon from './images/blood-pressure.svg';
import { messagesBloodPressure } from './messages/messages';

export const parameterBloodPressure = {
    code: 'BLOOD-PRESSURE',
    properties: BloodPressureProperties
};

export const bloodPressure: Indicator = {
    code: parameterBloodPressure.code,
    icon,
    messages: messagesBloodPressure,
    rules
};
