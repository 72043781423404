import React, { PropsWithChildren, useState } from 'react';
import { ThemeProfileContext } from './provider/provider.context';

import { profileDark, profileLight, ThemeEnum } from './profile/profiles';
import { ThemeProvider } from 'styled-components';
import { ProfileType } from './profile/profile.types';

export const getThemeProfile = (profileKey: string): ProfileType => {
    if (profileKey === ThemeEnum.DARK) {
        return profileDark;
    }
    return profileLight;
};

export const ThemeProfileProvider = (
    props: PropsWithChildren<unknown>
): JSX.Element => {
    const [themeProfile, setThemeProfile] = useState(ThemeEnum.LIGHT);

    return (
        <ThemeProfileContext.Provider
            value={{
                theme: themeProfile,
                setThemeProfile
            }}
        >
            <ThemeProvider
                theme={
                    themeProfile === ThemeEnum.DARK ? profileDark : profileLight
                }
            >
                {props.children}
            </ThemeProvider>
        </ThemeProfileContext.Provider>
    );
};
