import styled from 'styled-components';
import { browserIsIE11 } from '../../../utils/browser/browser-is-ie11';
import { inputFieldWidth } from './style-options';

export const StyledField = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledLabel = styled.label<{ $required: boolean }>`
    display: table-cell;
    padding-right: 20px;
    white-space: nowrap;
    font-size: 13px;
    vertical-align: ${browserIsIE11() ? 'middle' : 'unset'};

    ${({ $required }) =>
        $required
            ? `
    ::after {
        content: '*';
        color: #f5222d;
        font-family: SimSun, sans-serif;
        margin-left: 2px;
    }`
            : ''}
`;

export const StyledInputWrapper = styled.div`
    display: table-cell;
    margin: 0 0 5px 0;
    padding: 4px 0;
    width: ${inputFieldWidth}px;
`;

export const StyledValidationMessage = styled.p`
    display: table-cell;
    margin-bottom: 0;
    padding-left: 20px;
    white-space: nowrap;
    color: red;
    vertical-align: ${browserIsIE11() ? 'middle' : 'unset'};
`;
