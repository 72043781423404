import React from 'react';
import { ColumnRangeSeries, SplineSeries } from 'react-jsx-highcharts';
import { IndicatorWithSettings } from '../../../../redux/domains/indicators/indicator.type';
import { IndicatorProperty } from '../../../../types/indicator/indicator-properties';
import {
    GraphValuesSorted,
    IndicatorValue
} from '../../../../types/indicator/indicator-value.type';
import { IndicatorCode } from '../../../../types/indicator/indicator.type';
import { RangeSeriesData } from '../types/range-series-data.type';
import defaultColorObj from '../../../../utils/create-condition-settings/indicator-rules/defaults/colors/default';
import { getLineColor } from '../utils/graph';
import _ from 'lodash';
import { multiCreateSerieData } from './utils/multi-create-serie-data';
import { formatMessageById } from '../../../../locale/format/format-message';
import { Strings } from '../../../../locale/messagesDescriptors';
import { IndicatorSettingsMapping } from '../../../../conditions/condition.type';
import {
    graphSetHiddenindicator,
    graphSetShowIndicator
} from '../../../../redux/domains/chart/graph/chart.graph.actions';
import { setSelectedDate } from '../../../../redux/domains/chart/shared/chart.shared.actions';
import { useDispatch } from 'react-redux';
import { HighchartJSXTarget } from '../types/graph.type';
import { SeriesClickEventObject } from 'highcharts';

export function MultiPropertySplineSeries(props: {
    chartIndicator: IndicatorWithSettings;
    data: RangeSeriesData[];
    settingsMapping: IndicatorSettingsMapping;
    sortedValues?: GraphValuesSorted<IndicatorValue>[];
    sharedTooltips: (value: boolean) => void;
}) {
    const dispatch = useDispatch();
    const chartIndicator = props.chartIndicator;
    const properties = getRequiredAsProperties(props.chartIndicator);
    const indicatorCode = chartIndicator.code;
    const indicatorId = chartIndicator.id;
    const indicatorTitle = chartIndicator.title;
    const settingsMapping = props.settingsMapping;
    const sortedValues = props.sortedValues;
    const sharedTooltips = props.sharedTooltips;

    const connectedLineColor = getVerticalLineColor({
        code: indicatorCode,
        length: properties.length,
        settingsMapping: settingsMapping,
        properties: properties
    });

    return (
        <ColumnRangeSeries
            key={indicatorCode}
            id={indicatorId}
            name={indicatorTitle}
            data={props.data}
            color={connectedLineColor?.toString()}
            indicatorCode={indicatorCode}
            enableMouseTracking={false}
            onHide={onHideHandler}
            onShow={onShowHandler}
            onClick={onClickHandler}
        >
            {properties.map((property) => {
                const serieData =
                    (sortedValues &&
                        _.flatten(
                            sortedValues.map((event) =>
                                multiCreateSerieData(
                                    event,
                                    property,
                                    getLineColor(settingsMapping[indicatorCode])
                                )
                            )
                        )) ||
                    [];

                const lineColor = getLineColor(settingsMapping[indicatorCode])[
                    property
                ];

                const propertyTitle = getPropertyTitle({
                    indicatorCode,
                    property
                });

                return (
                    <SplineSeries
                        key={property}
                        name={propertyTitle}
                        linkedTo={indicatorId}
                        data={serieData}
                        findNearestPointBy="xy"
                        color={lineColor?.toString()}
                        marker={{
                            symbol: 'circle'
                        }}
                        stickyTracking={false}
                        onMouseOver={() => sharedTooltips(true)}
                        onMouseOut={() => sharedTooltips(false)}
                        tooltip={{
                            valueSuffix:
                                ' ' +
                                formatMessageById(
                                    `indicator.${indicatorCode.toLowerCase()}.${property}.unit`
                                )
                        }}
                        // UserOptions
                        property={property}
                        properties={properties}
                        indicatorCode={indicatorCode}
                    />
                );
            })}
        </ColumnRangeSeries>
    );

    function onHideHandler(event: Event) {
        const target = event.target as HighchartJSXTarget;
        const userOptions = target.userOptions;

        dispatch(
            graphSetHiddenindicator({
                code: userOptions.indicatorCode
            })
        );
    }

    function onShowHandler(event: Event) {
        const target = event.target as HighchartJSXTarget;
        const userOptions = target.userOptions;

        dispatch(
            graphSetShowIndicator({
                code: userOptions.indicatorCode
            })
        );
    }

    function onClickHandler(event: SeriesClickEventObject) {
        return (
            event.point.options.x &&
            dispatch(setSelectedDate(event.point.options.x))
        );
    }
}

function getRequiredAsProperties(
    chartIndicator: IndicatorWithSettings
): IndicatorProperty[] {
    return chartIndicator.data.jsonSchema.required!;
}

function getVerticalLineColor(params: {
    code: IndicatorCode;
    length: number;
    settingsMapping: IndicatorSettingsMapping;
    properties: IndicatorProperty[];
}) {
    return params.length > 0
        ? getLineColor(params.settingsMapping[params.code])[
              params.properties[0]
          ]
        : defaultColorObj.default;
}

function getPropertyTitle(params: {
    indicatorCode: IndicatorCode;
    property: IndicatorProperty;
}) {
    const indicatorCode = params.indicatorCode;
    const property = params.property;
    const rootId = Strings.indicators.rootId;
    const localeId = `${indicatorCode.toLowerCase()}.${property}`;
    return formatMessageById(`${rootId}${localeId}.title`);
}
