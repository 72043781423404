import { Indicator } from '../../../../types/indicator/indicator.type';

import icon from './images/pump-flow.svg';
import { messagesPumpFlowLvad } from './messages/messages';
import { rules } from './pump-flow-lvad-rules';

export const parameterPumpFlowLvad = {
    code: 'PUMP-FLOW-LVAD'
};

export const pumpFlow: Indicator = {
    code: parameterPumpFlowLvad.code,
    icon,
    messages: messagesPumpFlowLvad,
    rules
};
