import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ContentCardLayout } from '../../../components/content/ContentLayout';
import { CrossSpinner } from '../../../components/ui/loading/loading';
import { usePageTitle } from '../../../hooks/use-page-title';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { getPatient } from '../../../redux/domains/active-patient/active-patient.selectors';
import { getConditions } from '../../../redux/domains/conditions/conditions.selectors';
import { getPatientGroups } from '../../../redux/domains/patient-data/patient-data.selectors';
import { PatientDataHeader } from './components/header/PatientDataHeader';
import { PatientDataContent } from './PatientDataContent';
import { RouteMatchType } from './match-type';
import { titlePatientDataPage } from './title';
import { PatientTabs } from './components/tabs/tabs';
import { ReduxState } from '../../../redux/redux-state.type';
import { useDispatch } from 'react-redux';
import { fetchPatient } from '../../../redux/domains/active-patient/active-patient.actions';
import { fetchValuesForPastSixMonths } from '../../../redux/domains/values/values.actions';
import { fetchPatientDataGroups } from '../../../redux/domains/patient-data/patient-data.actions';
import { fetchConditionsByIds } from '../../../redux/domains/conditions/conditions.actions';
import { fetchReportReminders } from '../../../redux/domains/reminders/reminders.actions';
import { getIndicatorsForConditionWithSettings } from '../../../redux/domains/indicators/indicators.selectors';

export const PatientDataPage = (): JSX.Element => {
    usePageTitle(titlePatientDataPage);
    const dispatch = useDispatch<any>();
    const routeMatch = useRouteMatch<RouteMatchType>();

    const [isReportRemindersReady, setReportRemindersReady] = useState(false);

    const patientGroupId = routeMatch.params.patientGroupId;
    const externalId = routeMatch.params.externalId;
    const tab = routeMatch.params.tab;

    const isLoading = useReduxSelector(patientLoadingSelector);
    const isReadonly = useReduxSelector(isReadonlySelector);
    const patient = useReduxSelector(getPatient);
    const conditions = useReduxSelector(getConditions);
    const patientGroups = useReduxSelector(getPatientGroups);
    const indicatorsWithSettings = useReduxSelector(
        getIndicatorsForConditionWithSettings
    );

    const patientId = patient.externalId;
    const selectedPatientGroup = patientGroups
        ? patientGroups[patientGroupId]
        : null;

    const isPatientReady = patientId === externalId;
    const isPendingGroupsReady = patientGroups && selectedPatientGroup;
    const isValuesReady = useReduxSelector(isValuesReadySelector);
    const isConditionsReady = patient.conditionIds.every(
        (conditionId) => conditions[conditionId]
    );

    useEffect(() => {
        dispatch(fetchPatient(externalId));
        dispatch(fetchValuesForPastSixMonths(externalId));
    }, [dispatch, externalId]);

    useEffect(() => {
        if (isPatientReady) {
            dispatch(fetchPatientDataGroups(patient.groupIds));
        }
    }, [dispatch, isPatientReady, patient.groupIds]);

    useEffect(() => {
        if (isPatientReady) {
            dispatch(fetchConditionsByIds(patient.conditionIds));
        }
    }, [dispatch, isPatientReady, patient.conditionIds]);

    useEffect(() => {
        if (isPatientReady && selectedPatientGroup) {
            dispatch(
                fetchReportReminders(
                    [externalId],
                    selectedPatientGroup.condition
                )
            ).then(() => setReportRemindersReady(true));
        }
    }, [dispatch, isPatientReady, externalId, selectedPatientGroup]);

    if (
        !isLoading &&
        isPatientReady &&
        isReportRemindersReady &&
        isValuesReady &&
        isPendingGroupsReady &&
        isConditionsReady
    ) {
        const activeCondition = conditions[selectedPatientGroup.condition];

        return (
            <ContentCardLayout>
                <PatientDataHeader
                    patient={patient}
                    patientGroups={patientGroups}
                    selectedPatientGroupId={patientGroupId}
                    tab={tab || PatientTabs.charts}
                    indicatorsWithSettings={indicatorsWithSettings}
                />
                <PatientDataContent
                    patient={patient}
                    patientGroup={selectedPatientGroup}
                    condition={activeCondition}
                    isReadonly={isReadonly}
                />
            </ContentCardLayout>
        );
    }

    return (
        <ContentCardLayout>
            <CrossSpinner />
        </ContentCardLayout>
    );

    function isReadonlySelector(state: ReduxState) {
        return !state.groups.patientGroups.byId[patientGroupId];
    }

    function patientLoadingSelector(state: ReduxState) {
        const loading = state.requests.loading;

        return (
            loading.FETCH_REPORT_REMINDERS !== false ||
            loading.FETCH_PATIENT_DATA_GROUPS !== false ||
            loading.FETCH_PATIENT !== false ||
            loading.FETCH_CONDITIONS_WITH_INDICATORS !== false
        );
    }

    function isValuesReadySelector(state: ReduxState) {
        return (
            state.values.params.subjectId === externalId &&
            !state.values.params.indicatorIds &&
            state.values.params.from != null
        );
    }
};
