import { createSelector } from 'reselect';
import { isEmpty, keyBy, mapValues } from 'lodash';
import moment from 'moment';
import {
    Filters,
    Value,
    ValuesByIndicatorId,
    ValuesIndicator,
    ValuesParams
} from './values.type';
import { IndicatorId } from '../indicators/indicator.type';
import { ReduxState } from '../../redux-state.type';
import { IndicatorReport } from '../../../types/indicators/Indicator';
import { KeyValues } from '../../../types/indicator/indicator-value.type';
import { IndicatorCode } from '../../../types/indicator/indicator.type';

export const getLatestReports = (state: ReduxState): IndicatorReport<any>[] =>
    state.values.latestReports;

export const getValuesByIndicator = (
    state: ReduxState
): Record<IndicatorCode, ValuesIndicator> =>
    keyBy(state.values.indicators, 'indicatorCode');

const valuesByIndicator = (
    state: ReduxState,
    indicatorId: IndicatorId
): Value[] => state.values.indicators[indicatorId]?.values;

type TrendValue = [number, string | number | boolean | KeyValues];
export const getTrendValues = createSelector(
    [valuesByIndicator],
    (values): TrendValue[] => {
        if (!values) return [];

        return values.map((item) => {
            const sum = typeof item.value === 'object' ? item.value.sum : null;
            const value = sum != null ? sum : item.value;
            return [item.date, value];
        });
    }
);

export const getAllValues = createSelector(
    [(state: ReduxState) => state.values.indicators],
    (indicators) => mapValues(indicators, 'values')
);

export const getValuesParams = (state: ReduxState): ValuesParams =>
    state.values.params;

export const getDateFilters = (state: ReduxState): Filters =>
    state.values.filters;

export type ChartDateRange = {
    from: number;
    to: number;
};

export const getChartDateFilters = createSelector(
    getValuesParams,
    ({
        from = 0,
        to = timestampInMilliseconds(moment().endOf('day').valueOf())
    }) => ({
        from: Math.max(
            from,
            timestampInMilliseconds(
                moment(to)
                    .subtract(6, 'months')
                    .add(1, 'week')
                    .startOf('week')
                    .valueOf()
            )
        ),
        to
    })
);

export const getChartValues = createSelector(
    [getAllValues, getChartDateFilters],
    filterValues
);

export const getFilteredValues = createSelector(
    [getAllValues, getDateFilters],
    (values, filters) => {
        if (isEmpty(filters)) return values;

        return filterValues(values, filters);
    }
);

function filterValues(
    values: ValuesByIndicatorId,
    { from = 0, to = Date.now() }
) {
    return mapValues(values, (indicatorReports) =>
        indicatorReports.filter(({ date }) => date > from && date < to)
    );
}

export function timestampInMilliseconds(timestamp: number) {
    const timestampLength = timestamp.toString().length;

    if (timestampLength !== 13) {
        throw new Error('Timestamp is not in milliseconds');
    }

    return timestamp;
}
