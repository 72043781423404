import React from 'react';
import { Badge, Icon, Tabs } from 'antd';
import styled from 'styled-components';
import { useInterval } from '../../../../hooks/use-interval';
import { useReduxDispatch } from '../../../../hooks/use-redux-dispatch';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { fetchMessages } from '../../../../redux/domains/chat/chat.actions';
import { tabKeys } from '../../tabKeys';
import { ConnectedGeneralTab } from '../action-modal-tabs/general/GeneralTab';
import { ConnectedMessageTab } from '../action-modal-tabs/messages/MessagesTab';
import { ValueCommentsTab } from '../action-modal-tabs/value-comments/ValueCommentsTab';
import { ActionPanelProps } from '../actionPanelProps';
import { Strings } from '../../../../locale/messagesDescriptors';
import { formatMessage } from '../../../../locale/format/format-message';

export const ActionPanelBody = (props: ActionPanelProps) => {
    const {
        externalId,
        defaultTab,
        unreadMessagesCount,
        unreadValueCommentsCount,
        isMedicAdmin,
        patientGroup
    } = props;
    const tabKey = defaultTab || tabKeys.general;

    const conversationId = useReduxSelector(
        (state) => state.chat.activeConversation
    );
    const dispatch = useReduxDispatch();

    useInterval(() => {
        if (conversationId) {
            dispatch(fetchMessages(conversationId, externalId));
        }
    }, 10000);

    const generalTab = (
        <StyledSpan>
            <Icon type="home" />
            {formatMessage(Strings.common.general.general)}
        </StyledSpan>
    );
    const mailTab = (
        <StyledBadge offset={[10, 14]} count={unreadMessagesCount}>
            <Icon type="mail" />
            {formatMessage(Strings.common.general.messages)}
        </StyledBadge>
    );
    const valueCommentsTab = (
        <StyledBadge offset={[10, 14]} count={unreadValueCommentsCount}>
            <Icon type="message" />
            {formatMessage(Strings.common.general.comments)}
        </StyledBadge>
    );

    return (
        <StyledTabs type="card" defaultActiveKey={tabKey}>
            <StyledTabsPane tab={generalTab} key={tabKeys.general}>
                <ConnectedGeneralTab {...props} />
            </StyledTabsPane>
            <StyledTabsPane
                disabled={isMedicAdmin}
                tab={mailTab}
                key={tabKeys.messages}
            >
                <ConnectedMessageTab
                    patientId={externalId}
                    patientGroup={patientGroup}
                />
            </StyledTabsPane>
            {__APP_CONFIG__.FEATURE_VALUE_COMMENTS && (
                <StyledTabsPane tab={valueCommentsTab} key={tabKeys.comments}>
                    <ValueCommentsTab {...props} />
                </StyledTabsPane>
            )}
        </StyledTabs>
    );
};

const StyledSpan = styled.span`
    font-size: 15px;
`;

const StyledBadge = styled(Badge)`
    && {
        font-size: 15px;
    }
`;

const StyledTabs = styled(Tabs)`
    && .ant-tabs-bar {
        margin: 0;

        .ant-tabs-tab {
            border-color: transparent;
            background: transparent;
        }
    }
`;

const StyledTabsPane = styled(Tabs.TabPane)`
    height: 500px;
    padding: 8px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #f1f1f1;
`;
