import styled from 'styled-components';
import { theme } from '../../../../theme/colors/commonColors';
import { Divider } from 'antd';

export const StyledActionTabHeader = styled.h3<{
    borderBottom?: any;
}>`
    color: ${theme.layout.primary};
    text-transform: uppercase;
    margin: 0px 4px 8px 4px;
    border-bottom: ${(props) => props.borderBottom || 'none'};
`;

export const StyledActionPanelBox = styled.div`
    border-radius: 4px;
    background-color: ${theme.base.extraBright};
    height: 100%;
    border: 1px solid ${theme.base.bright};
    padding: 16px;
    overflow: hidden;
`;

export const StyledActionPanelFullSizeBox = styled(StyledActionPanelBox)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledDivider = styled(Divider)`
    && {
        margin: 8px 0 24px 0;
    }
`;
