import React, { Component } from 'react';
import { Button, Checkbox, message } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { CrossSpinner } from '../../../../../components/ui/loading/loading';
import { activate } from '../../../../../constants/status-change';
import {
    fetchPatient,
    updateIndicatorConfiguration
} from '../../../../../redux/domains/active-patient/active-patient.actions';
import { getIndicatorConfigurations } from '../../../../../redux/domains/active-patient/active-patient.selectors';
import { setSelectedCondition } from '../../../../../redux/domains/conditions/conditions.actions';
import { clearRegisterFormFields } from '../../../../../redux/domains/forms/forms.actions';
import { getPendingRegisterPatient } from '../../../../../redux/domains/forms/forms.selectors';
import { getIndicatorsForConditionWithSettings } from '../../../../../redux/domains/indicators/indicators.selectors';
import { togglePatient } from '../../../../../redux/domains/patient-administration/patient-administration.actions';
import {
    fetchReportReminders,
    updateReportReminders
} from '../../../../../redux/domains/reminders/reminders.actions';
import { getReportRemindersForPatient } from '../../../../../redux/domains/reminders/reminders.selectors';
import { theme } from '../../../../../theme/colors/commonColors';
import IndicatorList from '../../../data/components/tabs/limits/indicator-list/IndicatorList';
import { StyledActionBar } from '../styled';
import { IndicatorConfigurations } from '../../../../../redux/domains/conditions/conditions.type';
import { pathCreatePatientStep1 } from '../step-1/path';
import { pathPatientAdministrationSwitch } from '../../../administration/path';
import { formatMessage } from '../../../../../locale/format/format-message';
import { Strings } from '../../../../../locale/messagesDescriptors';

type Props = {
    patient: any;
    history: any;
    fetchPatient: any;
    setSelectedCondition: any;
    fetchReportReminders: any;
    indicators: any;
    indicatorConfigurations: IndicatorConfigurations;
    reportReminders: any;
    togglePatient: any;
    clearRegisterFormFields: any;
    updateIndicatorConfiguration: any;
    updateReportReminders: any;
};

class Step3 extends Component<Props> {
    state = {
        accept: false,
        loading: false,
        isFetchingData: false
    };

    componentDidMount() {
        const { patient } = this.props;

        if (isEmpty(patient)) {
            this.props.history.push(pathCreatePatientStep1);
        } else {
            this.setState({ isFetchingData: true });
            this.props
                .fetchPatient(patient.externalId)
                .then(({ value }: any) => {
                    const { conditionIds } = value;
                    const conditionId = conditionIds[0];

                    this.props.setSelectedCondition(conditionId);
                    this.props
                        .fetchReportReminders([patient.externalId], conditionId)
                        .then(() => this.setState({ isFetchingData: false }))
                        .catch((err: any) => {
                            console.log('Error', err);
                            this.setState({ isFetchingData: false });
                        });
                });
        }
    }

    render() {
        const {
            patient = {},
            indicators,
            indicatorConfigurations,
            reportReminders
        } = this.props;
        const { meta: { name = null, family_name = null } = {} } = patient;
        const patientName = `${name} ${family_name}`;

        return (
            <div>
                {isEmpty(patient) ? (
                    <p>No patient in context</p>
                ) : (
                    <div>
                        {patientName && (
                            <h3
                                style={{
                                    textAlign: 'center',
                                    marginBottom: 20
                                }}
                            >
                                {patientName}
                            </h3>
                        )}
                        {this.state.isFetchingData ? (
                            <CrossSpinner />
                        ) : (
                            <>
                                <div
                                    style={{
                                        borderTop: `2px solid ${theme.layout.primary}`,
                                        padding: 20
                                    }}
                                >
                                    <IndicatorList
                                        indicators={indicators}
                                        indicatorConfigurations={
                                            indicatorConfigurations
                                        }
                                        reportReminders={reportReminders}
                                    />
                                </div>
                                <StyledActionBar>
                                    <div style={{ marginBottom: 15 }}>
                                        <Checkbox
                                            onChange={this.onCheckboxChange}
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {formatMessage(
                                                Strings.screen
                                                    .patientAdministration.step3
                                                    .acceptLimits
                                            )}
                                        </Checkbox>
                                    </div>
                                    <Button onClick={this.onCancel}>
                                        {formatMessage(
                                            Strings.common.general.cancel
                                        )}
                                    </Button>
                                    <Button
                                        style={{ marginLeft: 8 }}
                                        type="primary"
                                        onClick={this.onSubmit}
                                        loading={this.state.loading}
                                        disabled={
                                            isEmpty(patient) ||
                                            !this.state.accept
                                        }
                                    >
                                        {formatMessage(
                                            Strings.common.general.save
                                        )}
                                    </Button>
                                </StyledActionBar>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    }

    onSubmit = () => {
        const {
            patient: { externalId, reason = activate.INITIAL_ACTIVATION, note },
            togglePatient
        } = this.props;
        this.setState({ loading: true });
        const data: any = {
            status: 'active',
            reason: reason
        };
        if (reason === 'other') {
            data.note = note;
        }
        togglePatient(externalId, {
            status: 'active',
            ...data
        })
            .then(() => {
                this.setState({ loading: false });
                message.success(
                    formatMessage(Strings.other.general.activationSuccess)
                );
                this.props.clearRegisterFormFields();
                this.props.history.push(pathPatientAdministrationSwitch);
            })
            .catch((e: any) => {
                console.log('e', e);
                this.setState({ loading: false });
            });
    };

    onCancel = () => {
        this.props.clearRegisterFormFields();
        this.props.history.push(pathPatientAdministrationSwitch);
    };

    onCheckboxChange = (e: any) => this.setState({ accept: e.target.checked });

    handleUpdateReportReminders = (values: any, indicatorId: any) => {
        const {
            patient: { externalId }
        } = this.props;
        const { id: reminderId } = values;
        const data = {
            subjectId: externalId,
            indicatorReportConfigurations: [values]
        };

        return this.props.updateReportReminders(
            data,
            indicatorId,
            externalId,
            reminderId
        );
    };
}

const mapStateToProps = (state: any, props: any) => {
    return {
        patient: getPendingRegisterPatient(state),
        indicators: getIndicatorsForConditionWithSettings(state),
        indicatorConfigurations: getIndicatorConfigurations(state),
        reportReminders: getReportRemindersForPatient(state, props)
    };
};
export default connect(mapStateToProps, {
    togglePatient,
    clearRegisterFormFields,
    fetchPatient,
    setSelectedCondition,
    updateIndicatorConfiguration,
    fetchReportReminders,
    updateReportReminders
})(Step3);
