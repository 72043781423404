export const FETCH_NOTIFICATIONS_FOR_AUTH_USER =
    'FETCH_NOTIFICATIONS_FOR_AUTH_USER';
export const FETCH_NOTIFICATIONS_FOR_AUTH_USER_PENDING =
    'FETCH_NOTIFICATIONS_FOR_AUTH_USER_PENDING';
export const FETCH_NOTIFICATIONS_FOR_AUTH_USER_FULFILLED =
    'FETCH_NOTIFICATIONS_FOR_AUTH_USER_FULFILLED';
export const FETCH_NOTIFICATIONS_FOR_AUTH_USER_REJECTED =
    'FETCH_NOTIFICATIONS_FOR_AUTH_USER_REJECTED';

export const UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS';
export const UPDATE_NOTIFICATION_STATUS_PENDING =
    'UPDATE_NOTIFICATION_STATUS_PENDING';
export const UPDATE_NOTIFICATION_STATUS_FULFILLED =
    'UPDATE_NOTIFICATION_STATUS_FULFILLED';
export const UPDATE_NOTIFICATION_STATUS_REJECTED =
    'UPDATE_NOTIFICATION_STATUS_REJECTED';
