import indicatorRules from '../../utils/create-condition-settings/indicator-rules';
import { indicatorItem } from '../../utils/create-condition-settings/items';
import { reasonsStatusChange } from '../../utils/create-condition-settings/reasons-status-change';
import { ConditionRules } from '../condition.type';
import { IndicatorMeta } from '../indicator-meta';

export const createHsRsConfiguration = (): ConditionRules => {
    return {
        initialObservationIndicators: [],
        priorityTable: {
            hidden: [
                IndicatorMeta.DAILY_ACTIVITY_HS.code,
                IndicatorMeta.GRIP_HS.code,
                IndicatorMeta.MEDICATION_HS.code,
                IndicatorMeta.ORTHOSIS_DAY_HS.code,
                IndicatorMeta.ORTHOSIS_NIGHT_HS.code,
                IndicatorMeta.PAIN_AFTER_ACTIVITY_HS.code,
                IndicatorMeta.VAS.code,
                IndicatorMeta.VOCATIONAL_ACTIVITY_HS.code
            ],
            order: [
                IndicatorMeta.EXERCISES_HS.code,
                IndicatorMeta.SWELLING_HS.code,
                IndicatorMeta.ACTIVE_MOTION_HS.code,
                IndicatorMeta.PAIN_AT_REST_HS.code,
                IndicatorMeta.PAIN_DURING_ACTIVITY_HS.code,
                IndicatorMeta.SLEEP_HS.code
            ]
        },
        indicatorSettings: {
            preventFromEditLimits: [
                IndicatorMeta.MEDICATION_HS.code,
                IndicatorMeta.ORTHOSIS_NIGHT_HS.code,
                IndicatorMeta.VAS.code
            ],
            preventFromEditReminders: [IndicatorMeta.MEDICATION_HS.code],
            preventFromToggle: [IndicatorMeta.VAS.code]
        },
        reasons: reasonsStatusChange(),
        indicators: {
            [IndicatorMeta.EXERCISES_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.EXERCISES_HS.code]
            ),
            [IndicatorMeta.SWELLING_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.SWELLING_HS.code]
            ),
            [IndicatorMeta.ACTIVE_MOTION_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.ACTIVE_MOTION_HS.code]
            ),
            [IndicatorMeta.PAIN_AT_REST_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PAIN_AT_REST_HS.code]
            ),
            [IndicatorMeta.PAIN_DURING_ACTIVITY_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PAIN_DURING_ACTIVITY_HS.code]
            ),
            [IndicatorMeta.PAIN_AFTER_ACTIVITY_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.PAIN_AFTER_ACTIVITY_HS.code]
            ),
            [IndicatorMeta.MEDICATION_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.MEDICATION_HS.code]
            ),
            [IndicatorMeta.ORTHOSIS_NIGHT_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.ORTHOSIS_NIGHT_HS.code]
            ),
            [IndicatorMeta.ORTHOSIS_DAY_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.ORTHOSIS_DAY_HS.code]
            ),
            [IndicatorMeta.SLEEP_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.SLEEP_HS.code]
            ),
            [IndicatorMeta.GRIP_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.GRIP_HS.code]
            ),
            [IndicatorMeta.DAILY_ACTIVITY_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.DAILY_ACTIVITY_HS.code]
            ),
            [IndicatorMeta.VOCATIONAL_ACTIVITY_HS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.VOCATIONAL_ACTIVITY_HS.code]
            ),
            [IndicatorMeta.VAS.code]: indicatorItem(
                indicatorRules[IndicatorMeta.VAS.code]
            )
        }
    };
};
