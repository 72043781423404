import React from 'react';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import { typeConfig } from './type-config';
import './exception.css';
import { getTheme } from '../../theme/profile/profiles';

Icon.setTwoToneColor(getTheme().profile.bright);

const renderSMARTErrorMessage = (message: any) => {
    if (typeof message === 'object') {
        const { error_uri } = message;

        return <a href={error_uri}>Error details</a>;
    }
    return null;
};

type Props = {
    match: any;
    location: {
        state?: {
            code?: any;
            message?: any;
        };
    };
    description: any;
};

const Exception = ({ match, location = {}, description }: Props) => {
    const { type } = match.params;
    const { state: { code = null, message = null } = {} } = location;
    const pageType = type in typeConfig ? type : '404';

    return (
        <div className="exception-wrapper">
            <div className="exception-img">
                <Icon type="frown" theme="twoTone" />
            </div>
            <div className="exception-content">
                <h1 className="status">{pageType}</h1>

                <p className="message">{code}</p>
                <p className="message">
                    {/* {description || config[pageType].description} */}
                    {renderSMARTErrorMessage(message)}
                </p>
                <Link to="/">Back to home</Link>
            </div>
        </div>
    );
};

export default Exception;
