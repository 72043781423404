import React from 'react';
import styled from 'styled-components';
import { Slider as AntSlider } from 'antd';
import { formatNumber } from '../../../../../../../../../../locale/format/format-number';

const RED = 'red';
const YELLOW = 'yellow';
const GREEN = '#00ff00';

const colors: { [key: string]: string } = {
    lower_red: RED,
    lower_yellow: YELLOW,
    accepted_range: GREEN,
    upper_yellow: YELLOW,
    upper_red: RED
};

const RangeSlider = ({
    min,
    max,
    step,
    values,
    onChange,
    configuration
}: any) => {
    const renderMarks = () => {
        return values.reduce((acc: any, curr: any) => {
            acc[curr] = formatNumber(curr);
            return acc;
        }, {});
    };

    const renderTrackStyle = () => {
        const { lower_red, lower_yellow, upper_yellow, upper_red } =
            configuration;

        const configurationArray = [
            { lower_red },
            { lower_yellow },
            { upper_yellow },
            { upper_red }
        ].filter((item) => Object.values(item)[0] != null);

        const greenIndex = configurationArray.reduce((count, currentConfig) => {
            const key = Object.keys(currentConfig)[0];
            const value = Object.values(currentConfig)[0];
            if (value != null && key.includes('lower')) {
                count++;
            }
            return count;
        }, 0);

        const trackStyle = configurationArray.map((config) => {
            const key = Object.keys(config)[0];
            return {
                backgroundColor: colors[key]
            };
        });

        if (trackStyle.length > 0) {
            trackStyle.splice(greenIndex, 0, {
                backgroundColor: colors.accepted_range
            });
        }

        return trackStyle;
    };

    return (
        <Slider
            range={true}
            min={min}
            max={max}
            step={step}
            pushable={true}
            value={values}
            onChange={onChange}
            tipFormatter={null} // IE11 got issues with tipFormatter combined with scrolls.
            marks={renderMarks()}
            trackStyle={renderTrackStyle()}
        />
    );
};

export default RangeSlider;

const Slider = styled<any>(AntSlider)`
    user-select: none;

    .ant-slider-mark {
        top: -25px;
    }
`;
