import { RouteItemType } from '../../../utils/route/switch-routes';
import { EducationFormPassed } from './EducationFormPassed';
import { pathEducationFormPassed } from './path';
import { titleEducationFormPassed } from './title';

export const routeEducationFormPassed: RouteItemType = {
    name: titleEducationFormPassed,
    link: pathEducationFormPassed,
    icon: 'error',
    component: EducationFormPassed
};
