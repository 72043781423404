const black = {
    name: 'black',
    primary: '#000000'
};

const blue = {
    name: 'blue',
    primary: '#2f7ed8',
    primaryBright: '#1aadce',
    primaryDark: '#1a538e',
    secondary: '#77a1e5'
};

const brown = {
    name: 'brown',
    primary: '#A0522D',
    primaryBright: '#c69781'
};

const green = {
    name: 'green',
    primary: '#8bbc21',
    primaryBright: '#aadd3c',
    primaryDark: '#608217',
    secondary: '#ccff66',
    olive: '#445626',
    forest: '#228B22',
    forestBright: '#4ea24e',
    forestDark: '#1e7d1e',
    forestDarker: '#176117',
    forestDarkest: '#114511'
};

const orange = {
    name: 'orange',
    primary: '#f28f43',
    primaryBright: '#f7b888',
    primaryDark: '#ee7111',
    secondary: '#ff9900'
};

const red = {
    name: 'red',
    primary: '#c42525',
    primaryBright: '#de5454',
    primaryDark: '#961d1d',
    secondary: '#ff0000'
};

const purple = {
    name: 'purple',
    primary: '#6d3caf',
    primaryBright: '#a76bf4',
    primaryDark: '#59318e',
    secondary: '#7436b3'
};

const gray = {
    name: 'gray',
    primary: '#929292'
};

const yellow = {
    name: 'yellow',
    primary: '#E2EE22',
    dark: '#B7A533'
};

const graphColors = {
    black,
    blue,
    brown,
    gray,
    green,
    orange,
    purple,
    red,
    yellow
};

export default graphColors;
