export const convertDate = (obj: Record<string, number>) => {
    const dateFrom = new Date(obj.from)
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, '');
    const dateTo = new Date(obj.to)
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, '');
    return {
        from: dateFrom,
        to: dateTo
    };
};

export function getPastMonth() {
    const date = new Date();
    date.setDate(0);
    date.setMonth(date.getMonth() + 1);
    const to = date.valueOf();
    date.setMonth(date.getMonth(), 1);
    return {
        from: date.valueOf(),
        to
    };
}
