import { bloodPressure } from '../shared-indicators/blood-pressure/blood-pressure';
import { pulse } from '../shared-indicators/pulse/pulse';
import { bodyWeight } from '../shared-indicators/body-weight/body-weight';
import { medication } from '../intermittent-claudication/indicators/medication/medication';
import { pain } from '../shared-indicators/pain/pain';
import { physact } from '../shared-indicators/physact/physact';
import { smoke } from '../shared-indicators/smoke/smoke';
import { vas } from '../shared-indicators/vas/vas';
import { eq5d3l } from '../shared-indicators/eq5d3l/eq5d3l';
import { conditions } from '../messages';
import { ConditionIndicator } from '../condition.type';
import { formatMessage } from '../../locale/format/format-message';

const code = 'LP_2_0_AO_RS';
const publicCode = 'ao';

const indicators: ConditionIndicator[] = [
    bloodPressure,
    pulse,
    bodyWeight,
    medication,
    pain,
    physact,
    smoke,
    vas,
    eq5d3l
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(conditions.rs.ao.name),
        nameLong: formatMessage(conditions.rs.ao.nameLong)
    }
};

export default condition;
