import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Body length',
        id: 'indicator.body-length.title'
    },
    unit: {
        id: 'indicator.body-length.unit',
        defaultMessage: 'cm'
    }
});

export const messagesBodyLength = {
    general
};
