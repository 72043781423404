import { getWatchoversForGroup, postWatchover } from './watchovers.api';
import { FETCH_WATCHOVERS, REGISTER_WATCHOVER } from './watchovers.constants';
import { ReduxState } from '../../redux-state.type';
import { getSelectedPatientGroupId } from '../groups/groups.selectors';

export const fetchWatchoversForGroup =
    () => (dispatch: any, getState: () => ReduxState) => {
        const groupId = getSelectedPatientGroupId(getState());

        if (groupId) {
            return dispatch({
                type: FETCH_WATCHOVERS,
                payload: getWatchoversForGroup(groupId)
            });
        }
    };

export const registerWatchover =
    (externalId: string) => (dispatch: any, getState: any) =>
        dispatch({
            type: REGISTER_WATCHOVER,
            payload: postWatchover(
                externalId,
                getSelectedPatientGroupId(getState())
            )
        });
