import { createSelector } from 'reselect';
import { ReduxState } from '../../redux-state.type';
import { ConditionId } from './conditions.type';

export const getCondition = (
    state: ReduxState,
    props?: { conditionId: ConditionId }
) => props && state.conditions.byId[props.conditionId];

export const getConditions = (state: ReduxState) => state.conditions.byId;

export const getSelectedConditionId = (state: ReduxState) =>
    state.conditions.selectedCondition;

export const getSelectedCondition = createSelector(
    [getConditions, getSelectedConditionId],
    (conditions, id) => conditions[id]
);

export const getSelectedConditionCode = createSelector(
    [getSelectedCondition],
    (condition) => {
        return condition ? condition.code : null;
    }
);
