import _ from 'lodash';
import { IndicatorWithSettings } from '../../../../../redux/domains/indicators/indicator.type';
import { keyByCode } from '../../../../../components/charts/graph/use-setup-graph';
import { IndicatorSettingsMapping } from '../../../../../conditions/condition.type';
import {
    isChartRules,
    isHeatmapRules
} from '../../../../../redux/domains/settings/settings.type';

export function splitChartIndicators(props: {
    indicators: IndicatorWithSettings[];
    settingsMapping: IndicatorSettingsMapping;
}) {
    const graphIndicators: IndicatorWithSettings[] = [];
    const heatmapIndicators: IndicatorWithSettings[] = [];

    _.forEach(props.indicators, (indicator) => {
        const settingsMapping = props.settingsMapping[indicator.code];

        const { hideChart } = settingsMapping.rules;

        if (isChartRules(settingsMapping.rules) && !hideChart) {
            graphIndicators.push(indicator);
        }
        if (isHeatmapRules(settingsMapping.rules) && !hideChart) {
            heatmapIndicators.push(indicator);
        }
    });

    return {
        graph: keyByCode({
            chartIndicatorsWithSettings: graphIndicators
        }),
        heatmap: heatmapIndicators
    };
}
