import React, { Component } from 'react';
import { Form } from 'antd';

type Props = {
    onSubmit: Function;
    layout: 'inline' | 'horizontal' | 'vertical' | undefined;
    className: string;
    render: Function;
    form: any;
    hideRequiredMark: any;
};

class SimpleForm extends Component<Props> {
    render() {
        const { render, form, className, layout, hideRequiredMark } =
            this.props;

        return (
            <Form
                onSubmit={this.onSubmit}
                className={className}
                layout={layout}
                hideRequiredMark={hideRequiredMark}
            >
                {render(form)}
            </Form>
        );
    }

    onSubmit = (e: any) => {
        const { onSubmit, form } = this.props;
        e.preventDefault();
        if (!onSubmit) return;

        onSubmit(form);
    };
}

export default Form.create<any>()(SimpleForm);
