export const INIT_GROUPS = 'INIT_GROUPS';
export const INIT_GROUPS_PENDING = 'INIT_GROUPS_PENDING';
export const INIT_GROUPS_FULFILLED = 'INIT_GROUPS_FULFILLED';
export const INIT_GROUPS_REJECTED = 'INIT_GROUPS_REJECTED';

export const FETCH_GROUPS = 'FETCH_GROUPS';
export const FETCH_GROUPS_PENDING = 'FETCH_GROUPS_PENDING';
export const FETCH_GROUPS_FULFILLED = 'FETCH_GROUPS_FULFILLED';
export const FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_REJECTED';

export const SET_SELECTED_MEDIC_GROUP = 'SET_SELECTED_MEDIC_GROUP';
export const SET_SELECTED_PATIENT_GROUP = 'SET_SELECTED_PATIENT_GROUP';

export const DELETE_USERS_FROM_GROUP = 'DELETE_USERS_FROM_GROUP';
export const DELETE_USERS_FROM_GROUP_FULFILLED =
    'DELETE_USERS_FROM_GROUP_FULFILLED';
