import { createIntl, createIntlCache } from 'react-intl';
import { getMessages } from '../get-messages';

const cache = createIntlCache();

export function createIntlFromLocale(locale: string) {
    return createIntl(
        {
            locale: locale,
            defaultLocale: 'en',
            messages: getMessages()
        },
        cache
    );
}
