import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        defaultMessage: 'Use of orthosis during the night',
        id: 'indicator.orthosis-night-hs.title'
    }
});

const choices = defineMessages({
    yes: {
        defaultMessage: 'Yes, all night',
        id: 'indicator.orthosis-night-hs.choices.1'
    },
    partially: {
        defaultMessage: 'Part of the night',
        id: 'indicator.orthosis-night-hs.choices.2'
    },
    no: {
        defaultMessage: 'No',
        id: 'indicator.orthosis-night-hs.choices.3'
    }
});

export const messagesOrthosisNight = {
    general,
    choices
};
